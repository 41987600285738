import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectSourceFilesPath } from 'selectors'
import { SOURCE_FILE_TYPES, MEDIA_FILE_FILTERS } from 'enums'
import useFoldersChildren from 'components/AssetPanels/Media/FoldersTree/useFoldersChildren'
import { fetchMedia, fetchMediaType } from 'components/AssetPanels/Media/FoldersTree/fetchMedia'
import * as FolderTreeTypes from 'components/AssetPanels/Media/FoldersTree/foldersTree.types'
import { ReactComponent as FolderExpand } from 'assets/media/menu_folder_expand.svg'
import { ReactComponent as FolderCollapse } from 'assets/media/menu_folder_collapse.svg'
import { ReactComponent as FolderIcon } from 'assets/media/folder.svg'
import cx from 'classnames'
import styles from './folderTree.module.scss'

function findDirInDirHistory(
  dirHistory: FolderTreeTypes.DirectoryPathHistory,
  foldersChildren: FolderTreeTypes.FoldersChildren
) {
  return dirHistory.find(
    directory => Object.values(foldersChildren).find(
      arr => arr.find(item => directory.id === item.id)
    )
  )
}

const Tree = ({ onInit, fetchMedia, selectedFolder, onSelect, selectedMediaItems }: {
  fetchMedia: fetchMediaType,
  selectedFolder: string | null,
  onSelect: (id: string) => void,
  onInit: (state: boolean) => void,
  selectedMediaItems: string[] }) => {
  const sourceFilesPath = useSelector(
    (state: RootState) => selectSourceFilesPath(state, SOURCE_FILE_TYPES.MEDIA)
  ) as FolderTreeTypes.DirectoryPathHistory

  const [ items, setItems ] = useState<FolderTreeTypes.FolderChildren>([])

  useEffect(() => {
    async function fn() {
      onInit(true)
      const rootFolders = await fetchMedia() as FolderTreeTypes.FolderChildren
      setItems(rootFolders)
      onInit(false)
    }
    fn()
  }, [ fetchMedia, onInit ])

  const openedFolder = sourceFilesPath.find(
    filesPathItem => items.find(item => filesPathItem.id === item.id)
  )

  return (
    <div className={styles.tree}>
      <TreeRecursive
        onInit={onInit}
        data={items}
        sourceFilesPath={sourceFilesPath}
        selectedFolder={selectedFolder}
        selectedMediaItems={selectedMediaItems}
        onSelect={onSelect}
        openedFolder={openedFolder ? openedFolder.id : undefined}
        isTopLayer
      />
    </div>
  )
}

// const File = ({ name }: { name: string }) => (
//   <div className={styles.file}>
//     <span>{name}</span>
//   </div>
// )

const Folder = ({ children, name, handleClick, isOpened, isEmpty, selectFolder, isSelected }:
  { isEmpty: boolean,
    children: React.ReactElement | null,
    name: string,
    handleClick: () => void,
    selectFolder: () => void,
    isSelected?: boolean,
    isOpened?: boolean }) => {
  const [ isOpen, setIsOpen ] = useState(isOpened)

  useEffect(() => {
    setIsOpen(isOpened)
  }, [ isOpened ])

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault()
    handleClick()
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={styles.folderLabel}>
        <div onClick={handleToggle} className={cx(styles.treeIcons, styles.expand)}>
          {!isEmpty && (
            isOpen ? <FolderCollapse /> : <FolderExpand />
          )}
        </div>
        <div
          className={cx(styles.folderSelectable, {
            [styles.folderSelected]: isSelected,
          })}
          onClick={selectFolder}
        >
          <div className={cx(styles.treeIcons, styles.folderIcon)}><FolderIcon /></div>
          <span>{name}</span>
        </div>
      </div>
      <div style={{ height: `${isOpen ? 'auto' : '0'}`, overflow: 'hidden' }}>
        {children}
      </div>
    </>
  )
}

// openedFolder prop is for auto folders expanding
const TreeRecursive = ({ data,
  onInit,
  sourceFilesPath,
  openedFolder,
  selectedFolder,
  selectedMediaItems,
  onSelect,
  isTopLayer }:
  { data: FolderTreeTypes.FolderChildren,
    sourceFilesPath: FolderTreeTypes.DirectoryPathHistory,
    openedFolder?: string,
    selectedFolder: string | null,
    selectedMediaItems: string[],
    isTopLayer?: boolean,
    onSelect: (id: string) => void,
    onInit: (state: boolean) => void }) => {
  const [ manual, setManual ] = useState(false) // flag for manual opened folders
  const foldersChildren = useFoldersChildren(data, onInit)

  function handleFolderClick() {
    setManual(true)
  }

  function selectFolder(id: string) {
    onSelect(id)
  }

  const autoOpenedFolder = React.useMemo(() => manual
    ? undefined
    : findDirInDirHistory(sourceFilesPath, foldersChildren),
  [ foldersChildren, manual, sourceFilesPath ])

  return (
    <>
      {data && data.map(item => {
        if (item.filetype === MEDIA_FILE_FILTERS.Folder) {
          return (
            <React.Fragment key={item.id}>
              {
            !selectedMediaItems.includes(item.id) ? (
              <div className={cx({ [styles.folder]: !isTopLayer })}>
                <Folder
                  isEmpty={foldersChildren[item.id] ? foldersChildren[item.id].length === 0 : true}
                  name={item.name}
                  isOpened={openedFolder === item.id}
                  isSelected={selectedFolder === item.id}
                  handleClick={() => handleFolderClick()}
                  selectFolder={() => selectFolder(item.id)}
                >
                  <TreeRecursive
                    onInit={onInit}
                    data={foldersChildren[item.id]}
                    sourceFilesPath={sourceFilesPath}
                    openedFolder={autoOpenedFolder ? autoOpenedFolder.id : undefined}
                    selectedFolder={selectedFolder}
                    selectedMediaItems={selectedMediaItems}
                    onSelect={onSelect}
                  />
                </Folder>
              </div>
            ) : null
            }
            </React.Fragment>
          )
        }
        return <></>
      })}
    </>
  )
}

function FoldersTree({ onInit, selectedFolder, onSelect, selectedMediaItems }: {
  selectedFolder: string | null,
  onSelect: (id: string) => void
  onInit: (state: boolean) => void,
  selectedMediaItems: string[]
}) {
  return (
    <Tree
      fetchMedia={fetchMedia}
      onInit={onInit}
      selectedMediaItems={selectedMediaItems}
      selectedFolder={selectedFolder}
      onSelect={onSelect}
    />
  )
}

export default React.memo(FoldersTree)
