import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { copyToClipboard } from '~/Util/clipboard'
import './default.scss'

export default function DownloadLink({ downloadUrl }) {
  const onClickCopy = () => copyToClipboard(downloadUrl)
  const { t } = useContext(TranslationContext)

  return (
    <If condition={downloadUrl !== ''}>
      <div className="save-settings__link">
        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">{downloadUrl}</a>
      </div>
      <div className="render-result-solveig__btn-copy" title={t('SAVE_SETTINGS_BTN_COPY_TOOLTIP')}>
        <Button onClick={onClickCopy} border data-lang-id="SAVE_SETTINGS_BTN_COPY">{t('SAVE_SETTINGS_BTN_COPY')}</Button>
      </div>
    </If>
  )
}
