const MIN_PLACEHOLDER_DURATION = 15000000
const PLACEHOLDER_EXPANDER = 10000000

export const normalizePlaceholderBorders = placeholder => {
  const placeholderEndTime = placeholder.duration > MIN_PLACEHOLDER_DURATION
    ? placeholder.endTime : placeholder.endTime + PLACEHOLDER_EXPANDER
  const placeholderStartTime = (placeholder.duration > MIN_PLACEHOLDER_DURATION
    || placeholder.startTime <= PLACEHOLDER_EXPANDER)
    ? placeholder.startTime : placeholder.startTime - PLACEHOLDER_EXPANDER

  return { placeholderEndTime, placeholderStartTime }
}
