import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'

import * as Actions from 'actions'
import { ReactComponent as AddIcon } from 'assets/media/audio_add.svg'
import { PLAYBACK_STATE } from 'enums'
import { useAction } from 'hooks'
import PropTypes from 'prop-types'
import * as PT from '~/PropTypes'
import { refTimeToHHMMSS } from '~/Util'
import { ReactComponent as PlayIcon } from '~/assets/video-player/ic_play.svg'
import { ReactComponent as StopIcon } from '~/assets/video-player/ic_stop.svg'
import useDraggable from '~/components/AssetPanels/hooks/useDraggable'
import { selectSelectedClipIsBuffering } from '~/selectors'
import styles from './listItem.module.scss'

export default function AudioAssetsListItem(props) {
  const {
    /** @type {Assets.AudioAsset} */
    asset,
    onGenreClick,
    getCategory,
    // eslint-disable-next-line no-unused-vars
    ...rest
  } = props
  const setPlaybackClip = useAction(Actions.playback.setPlaybackClip, asset)
  const setPlayPause = useAction(Actions.playback.togglePlaybackPlaying)
  const onAddToTimeline = useAction(Actions.timeline.addSourceFileAtSliderPosition, asset.id)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const clipPlaybackState = useSelector(state => state.playback.clipPlaybackState)
  const isBuffering = useSelector(state => selectSelectedClipIsBuffering(state, asset.id))

  const [ , setDraggable ] = useDraggable(asset, {})

  const playing = clipPlaybackState === PLAYBACK_STATE.PLAY && selectedClipId === asset.id

  const category = getCategory(asset) || {}

  const onSelectItem = useCallback(
    () => {
      if (playing) {
        setPlayPause()
      } else { setPlaybackClip() }
    },
    [ setPlaybackClip, playing, setPlayPause ]
  )

  const handleSelectGenre = () => {
    onGenreClick(category.id)
  }

  return (
    <>
      <div
        ref={setDraggable}
        className={cx(styles.container,
          { [styles.playing]: playing })}
      >
        <div className={cx(styles.track, { [styles.buffering]: isBuffering })}>
          <IconButton className={cx(styles.btn, styles.play)} onClick={onSelectItem}>
            {playing ? <StopIcon /> : <PlayIcon />}
          </IconButton>
          <div className={styles.meta}>
            <Tooltip title={asset.name} classes={{ popper: 'tooltip media-tooltip' }}>
              <div className={styles.title}>{asset.name}</div>
            </Tooltip>
            <div className={styles.genre} onClick={handleSelectGenre}>{category ? category.name : ''}</div>
          </div>
          <IconButton className={cx(styles.btn, styles.add)} onClick={onAddToTimeline}>
            <AddIcon />
          </IconButton>
          <div className={styles.duration}>{refTimeToHHMMSS(asset.duration, true)}</div>
        </div>
        <div className={cx({ [styles.progress]: isBuffering })} />
      </div>
    </>
  )
}

AudioAssetsListItem.propTypes = {
  asset: PT.AudioAsset.isRequired,
  onGenreClick: PropTypes.func.isRequired,
  getCategory: PropTypes.func.isRequired,
}
