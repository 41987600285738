import { TranslationContext } from 'contexts/TranslationContext'
import React from 'react'
import { UnControlled as CodeMirror } from 'react-codemirror2'
import Button from '~/components/base/Button'
import { Dialog, DialogContent, DialogTitle } from '~/components/base/Dialog/Dialog'
import './TextAreaModal.scss'

require('codemirror/mode/css/css')
require('codemirror/mode/javascript/javascript')
require('codemirror/addon/search/search')
require('codemirror/addon/search/searchcursor')

export const TextAreaModal = ({ modalDialog, title, text, mode = 'text' }) => {
  const { t } = React.useContext(TranslationContext)
  const [ changedValue, setChangedValue ] = React.useState(text)
  return (
    <If condition={modalDialog.active}>
      <Dialog
        className="textarea-modal"
        open
        modalDialog={modalDialog}
        onClose={modalDialog.cancel}
      >
        <DialogTitle className="textarea-modal__header">{title}</DialogTitle>
        <DialogContent className="textarea-modal__content">
          <CodeMirror
            value={text}
            options={{
              mode,
              theme: 'material',
              lineNumbers: true,
              search: 'search',
            }}
            onChange={(_, __, v) => setChangedValue(v)}
          />

          <div className="textarea-modal__buttons">
            <Button
              className="textarea-modal__button"
              border
              autoFocus
              onClick={modalDialog.close}
              title={t('CUSTOMIZATION_BTN_CANCEL_TOOLTIP')}
              data-lang-id="CUSTOMIZATION_BTN_CANCEL"
            >
              {t('CUSTOMIZATION_BTN_CANCEL')}
            </Button>
            <Button
              type="submit"
              className="textarea-modal__button"
              primary
              autoFocus
              onClick={() => modalDialog.confirmWithoutClose(changedValue)}
              title={t('CUSTOMIZATION_BTN_SAVE_TOOLTIP')}
              data-lang-id="CUSTOMIZATION_BTN_SAVE"
            >
              {t('CUSTOMIZATION_BTN_SAVE')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </If>
  )
}
