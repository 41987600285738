import classNames from 'classnames'
import React, { useRef } from 'react'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'
import { endDragLayer as endDragLayerAction, startDragLayer as startDragLayerAction } from '~/actions/timeline'
import { DRAGNDROP_TYPE } from '~/enums'
import { useAction } from '~/hooks'
import { useDragSource } from '~/hooks/useDragSource'
import { getLayerAssets, getLayers } from '~/selectors'
import Layer from './Layer'

export const LayerDraggable = props => {
  const { layer } = props
  const dragRef = useRef()
  const layerAssets = useSelector(state => getLayerAssets(state, layer.id))
  const layers = useSelector(getLayers)

  const startDragLayer = useAction(startDragLayerAction)
  const endDragLayer = useAction(endDragLayerAction)
  const index = layers.findIndex(l => l.id === layer.id)

  const isLatestEmptyLayer = !layerAssets.length && index === layers.length - 1

  const [{ isDragging }, drag, preview ] = useDragSource({
    id: layer.id,
    type: DRAGNDROP_TYPE.LAYER,
    onDragStarted: startDragLayer,
    onDragEnded: endDragLayer,
    draggable: !isLatestEmptyLayer,
  })

  // Suppress default generated preview, because we need custom drag preview behavior.
  // See components/Timeline/DragAndDrop/CustomDragLayer.jsx
  React.useEffect(
    () => { preview(getEmptyImage()) },
    [ preview ]
  )

  drag(dragRef)

  return (
    <Layer
      className={classNames({ 'layer--hidden': isDragging })}
      dragRef={dragRef}
      layerIndex={index}
      {...props}
    />
  )
}
