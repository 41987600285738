import { cloneInstanceClass } from 'helpers/cloneInstanceClass'
import { TransitionAsset } from '~/models/Asset'
import { ASSET_SIZE_STATUS } from '~/config/constants/asset'
import { TRANSITIONS } from '~/enums'
import * as Selectors from '~/selectors'
import { isAvailableAttachTransition } from '~/helpers/assets/isAvailableAttachTransition'

export function onVideoAssetMoved(
  state,
  videoAssetId,
  videoAssetStartTime,
  newStartTime,
  newLayerId
) {
  const [ fadeInOutTransitions,
    transitionsToRemove ] = Selectors.getTransitionsOfMovedAsset(state, videoAssetId)

  // correct start time of fade in/out transitions after moving asset
  fadeInOutTransitions.forEach(tr => {
    // eslint-disable-next-line no-param-reassign
    tr.startTime -= (videoAssetStartTime - newStartTime)

    const dissolveTransitionOnLeftVideo = transitionsToRemove.find(
      t => t.type === TRANSITIONS.DISSOLVE && t.rightVideoAssetId === videoAssetId
    )

    // NOTE: this is for fix fadeout position after removing dissolve asset
    if (dissolveTransitionOnLeftVideo && tr.leftVideoAssetId === videoAssetId) {
      // eslint-disable-next-line no-param-reassign
      tr.startTime -= dissolveTransitionOnLeftVideo.duration
    }

    // eslint-disable-next-line no-param-reassign
    tr.layerId = newLayerId
  })
}

export function adjustVideoAssetsByTransition(state, transition, isDeleteTransition) {
  switch (transition?.type) {
    case TRANSITIONS.DISSOLVE: {
      const { leftVideoAsset, rightVideoAsset } = getDissolveTransitionAssets(state, transition)
      const halfTransition = getHalfTransition(transition.duration)
      const transitionAssetsStatus = getDissolveAssetsCutStatus(state,
        leftVideoAsset, rightVideoAsset, transition)

      switch (transitionAssetsStatus) {
        case ASSET_SIZE_STATUS.NOT_CUT:
          if (isDeleteTransition) {
            leftVideoAsset.duration -= halfTransition
            rightVideoAsset.duration -= halfTransition
            rightVideoAsset.startTime = leftVideoAsset.endTime
            rightVideoAsset.mediaStart += halfTransition
          } else {
            rightVideoAsset.startTime -= transition.duration
            moveConnectedAssetsWithDissolve(transition.id, rightVideoAsset,
              rightVideoAsset.layerId, state, transition.duration)
          }
          break
        case ASSET_SIZE_STATUS.LEFT_CUT_RIGHT_NORMAL:
          if (isDeleteTransition) {
            leftVideoAsset.duration -= transition.duration
            leftVideoAsset.endTime = leftVideoAsset.startTime + leftVideoAsset.duration
          } else {
            leftVideoAsset.duration += transition.duration
            leftVideoAsset.endTime = leftVideoAsset.startTime + leftVideoAsset.duration
          }
          break
        case ASSET_SIZE_STATUS.RIGHT_CUT_LEFT_NORMAL:
          if (isDeleteTransition) {
            rightVideoAsset.duration -= transition.duration
            rightVideoAsset.startTime += transition.duration
            rightVideoAsset.mediaStart = rightVideoAsset.mediaStart > 0
              ? rightVideoAsset.mediaStart += transition.duration : rightVideoAsset.mediaStart
          } else {
            rightVideoAsset.duration += transition.duration
            rightVideoAsset.startTime -= transition.duration
            rightVideoAsset.mediaStart = rightVideoAsset.mediaStart > 0
              ? rightVideoAsset.mediaStart -= transition.duration : rightVideoAsset.mediaStart
          }
          break
        case ASSET_SIZE_STATUS.LEFT_CUT_RIGHT_CUT:
          if (isDeleteTransition) {
            leftVideoAsset.duration -= halfTransition
            rightVideoAsset.duration -= halfTransition
            rightVideoAsset.startTime += halfTransition
            rightVideoAsset.mediaStart = rightVideoAsset.mediaStart > 0
              ? rightVideoAsset.mediaStart += transition.duration : rightVideoAsset.mediaStart
          } else {
            rightVideoAsset.duration += halfTransition
            rightVideoAsset.startTime -= halfTransition
            leftVideoAsset.duration += halfTransition
            rightVideoAsset.mediaStart = rightVideoAsset.mediaStart > 0
              ? rightVideoAsset.mediaStart -= transition.duration : rightVideoAsset.mediaStart
          }
          break
        default:
          break
      }
      state.assets = state.assets.map(asset => {
        if (asset.id === leftVideoAsset.id) return leftVideoAsset
        if (asset.id === rightVideoAsset.id) return rightVideoAsset
        return asset
      })
    } break
    default:
      break
  }
}

export function deleteTransitions(state, transitions) {
  const { uniqTransitionAssets } = getUniqTransitions(transitions)
  state.assets = state.assets
    .filter(asset => !uniqTransitionAssets.find(transition => transition.id === asset.id))
  uniqTransitionAssets
    .forEach(transition => adjustVideoAssetsByTransition(state, transition, true))
}

// TODO Bug #1303
export function onTransitionDurationChange(state, transition, transitionDuration, applyToAll) {
  // const delta = transitionDuration - transition.duration
  // adjustVideoStartByTransition(state, transition.id, false, delta)
  if (applyToAll) {
    state.assets.forEach(tr => {
      if (transition.type === tr.type) {
        // eslint-disable-next-line no-param-reassign
        tr.duration = transitionDuration
      }
    })
  } else {
    // eslint-disable-next-line no-param-reassign
    transition.duration = transitionDuration
  }
}

export function attachTransitionToVideo(state, {
  transitionId,
  leftAssetId,
  rightAssetId,
}) {
  const transition = state.assets.find(el => el.id === transitionId)
  const rightAsset = state.assets.find(el => el.id === rightAssetId)
  const leftAsset = state.assets.find(el => el.id === leftAssetId)

  if (leftAsset && isAvailableAttachTransition(leftAsset)) {
    transition.attachTo(leftAssetId, 'left')
  }

  if (rightAsset && isAvailableAttachTransition(rightAsset)) {
    if (transition && !transition.isAttachedTo(rightAssetId, 'right')) {
      transition.attachTo(rightAssetId, 'right')
    }
  }

  const videoRef = state.assets.find(el => el.id === (leftAssetId || rightAssetId))

  if (videoRef) {
    transition.layerId = videoRef.layerId
  }
  adjustVideoAssetsByTransition(state, transition)
}

function getDissolveTransitionAssets(state, transition) {
  const leftVideoAsset = state.assets.find(el => transition.isAttachedTo(el.id, 'left'))
  const rightVideoAsset = state.assets.find(el => transition.isAttachedTo(el.id, 'right'))
  return { leftVideoAsset, rightVideoAsset }
}

function getUniqTransitions(transitions) {
  return transitions.reduce((result, transition) => {
    if (!result.uniqTransitionIds.includes(transition.id)) {
      result.uniqTransitionIds.push(transition.id)
      result.uniqTransitionAssets.push(cloneInstanceClass(transition))
    }
    return result
  }, { uniqTransitionAssets: [], uniqTransitionIds: [] })
}

function getDissolveAssetsCutStatus(state, leftVideoAsset, rightVideoAsset, transition) {
  const trimPartOfTheLeftAsset = leftVideoAsset.originDuration - leftVideoAsset.duration
  const trimPartOfTheRightAsset = rightVideoAsset.originDuration - rightVideoAsset.duration
  const halfTransition = getHalfTransition(transition.duration)
  const isTransitionExists = transition.id

  // set default assetsSizeStatus
  state.assetsSizeStatus = ASSET_SIZE_STATUS.NOT_CUT

  if (isTransitionExists
    && (trimPartOfTheLeftAsset >= transition.duration)
    && (trimPartOfTheRightAsset < halfTransition)) {
    state.assetsSizeStatus = ASSET_SIZE_STATUS.LEFT_CUT_RIGHT_NORMAL
  }

  if (isTransitionExists
    && (trimPartOfTheLeftAsset < halfTransition)
    && (trimPartOfTheRightAsset >= transition.duration)) {
    state.assetsSizeStatus = ASSET_SIZE_STATUS.RIGHT_CUT_LEFT_NORMAL
  }

  if (isTransitionExists
    && (trimPartOfTheLeftAsset >= halfTransition)
    && (trimPartOfTheRightAsset >= halfTransition)) {
    state.assetsSizeStatus = ASSET_SIZE_STATUS.LEFT_CUT_RIGHT_CUT
  }

  return state.assetsSizeStatus
}

function getHalfTransition(transitionDuration) {
  return Math.ceil(Number(transitionDuration) / 2)
}

function moveConnectedAssetsWithDissolve(transitionId, connectedAsset, layerId, state, offset) {
  let rightConnectedDissolveAsset = connectedAsset
  const restTransitions = state.assets.filter(asset => (asset instanceof TransitionAsset)
      && (asset.id !== transitionId) && (layerId === asset.layerId))
    .sort((a, b) => a.startTime - b.startTime)

  if (rightConnectedDissolveAsset) {
    restTransitions.forEach(transition => {
      if (transition.leftVideoAssetId === rightConnectedDissolveAsset.id) {
        const { rightVideoAsset } = getDissolveTransitionAssets(state, transition)
        if (rightVideoAsset) {
          rightVideoAsset.startTime -= offset
          rightConnectedDissolveAsset = rightVideoAsset
        }
        // eslint-disable-next-line no-param-reassign
        transition.startTime -= offset
      }
    })
  }
}
