import { immerable } from 'immer'
import { MIN_TIMELINE_ITEM_DURATION } from 'constant'
import { RangeTools } from '~/Util'
import { isTimelineItemsIntersected } from '~/Util/RangeTools'

/**
 * This class is responsible for all time-related manipulations:
 * item position on timeline, it's duration, etc.
 *
 * @memberOf Assets
 * @implements {IRange}
 */
class TimelineItem {

  [immerable] = true

  constructor(data = {}) {
    const {
      duration = 0,
      startTime = 0,
    } = data

    this._duration = duration
    this._startTime = startTime

    // Generic timeline item can be of any size.
    // Intended to be overridden in descendants.
    this._maxDuration = Infinity
    this._originDuration = duration
    this._isModified = false

    this._isTrim = false
    this._isRestored = false
  }

  // ---

  /**
   * @returns {number}
   */
  get duration() {
    return this._duration
  }

  /**
   * @param {number} time
   */
  set duration(time) {
    this._duration = Math.min(this._maxDuration, Math.max(time, MIN_TIMELINE_ITEM_DURATION))
    if (!this._originDuration) {
      this._originDuration = this._duration
    }
    this._setIsTrim()
  }

  // ---

  /**
   * @returns {number}
   */
  get originDuration() {
    return this._originDuration
  }

  /**
   * @param {number} originDuration
   */
  set originDuration(originDuration) {
    this._originDuration = originDuration
  }

  // ---

  /**
   * Item position on timeline.
   *
   * @returns {number}
   */
  get startTime() {
    return this._startTime
  }

  /**
   * @param {number} time
   */
  set startTime(time) {
    this._startTime = Math.max(time, 0)
  }

  // ---

  /**
   * @returns {number}
   */
  get endTime() {
    // In JS 101165771.48 + 100000000 === 201165771.48000002
    return parseFloat((this.startTime + this.duration).toFixed(4))
  }

  /**
   * @param {number} time
   */
  set endTime(time) {
    this.duration = time - this.startTime
  }

  // ---

  /**
   * @returns {number[]}
   */
  get timeRange() {
    return [ this.startTime, this.endTime ]
  }

  [RangeTools.SymbolRangeProtocol]() {
    return this.timeRange
  }

  /**
   * Shifts `startTime` by given offset while keeping `duration` unchanged.
   * (so that `endTime` adjusts respectively)
   *
   * @param {number} offset
   * @returns {this}
   */
  moveBy(offset) {
    this.startTime += offset
    return this
  }

  /**
   * Set `startTime` to given value while keeping `duration` unchanged.
   * (so that `endTime` adjusts respectively)
   *
   * @param {number} startTime
   * @returns {this}
   */
  moveTo(startTime) {
    this.startTime = startTime
    return this
  }

  /**
   * Increase `duration` by given offset
   * by shifting `startTime` to left (if offset < 0)
   * or `endTime` to right (if offset > 0)
   *
   * @param {number} offset
   * @returns {this}
   */
  growBy(offset) {
    this._adjust(offset, offset < 0)
    return this
  }

  /**
   * Decrease `duration` by given offset
   * by shifting `startTime` to right (if offset > 0)
   * or `endTime` to left (if offset < 0)
   *
   * @param {number} offset
   * @returns {this}
   */
  shrinkBy(offset) {
    this._adjust(offset, offset > 0)
    return this
  }

  /**
   * Set `startTime` to specified value in asset time range,
   * while decreasing `duration` respectively.
   * (equivalent to `shrinkBy(at - startTime)`)
   * AND also adjusting `mediaStart` field
   * (so that truncated part is effectively removed from video file).
   *
   * If position is outside of asset, do nothing.
   *
   * @param {number} at
   * @returns {this}
   */
  truncateLeftAt(at) {
    if (this._canTruncateAt(at)) {
      this._truncate(at, at - this.startTime, true)
    }
    return this
  }

  /**
   * Set `endTime` to specified value in asset time range,
   * while decreasing `duration` respectively.
   * Equivalent to `shrinkBy(at - endTime)`.
   *
   * If position is outside of asset, do nothing.
   *
   * @param {number} at
   * @returns {this}
   */
  truncateRightAt(at) {
    if (this._canTruncateAt(at)) {
      this._truncate(at, this.endTime - at, false)
    }
    return this
  }

  /**
   * @param {NumbersRange} range
   * @returns {this}
   */
  truncateByRange(range) {
    const [ rangeStart, rangeEnd ] = RangeTools.ensureValidRange(range)
    this.truncateLeftAt(rangeStart)
    this.truncateRightAt(rangeEnd)
    return this
  }

  // ---

  /**
   * @param {number} timelineTime
   * @return {boolean}
   */
  matchesPlayingTime(timelineTime) {
    return timelineTime >= this.startTime && timelineTime < this.endTime
  }

  /**
  * @returns {boolean}
  */
  get isTrim() {
    return this._isTrim
  }

  /**
  * @param {boolean} isTrim
  */
  set isTrim(isTrim) {
    this._isTrim = isTrim
  }

  // ---

  /**
  * @returns {boolean}
  */
  get isRestored() {
    return this._isRestored
  }

  /**
  * @param {boolean} isRestored
  */
  set isRestored(isRestored) {
    this._isRestored = isRestored
  }

  /**
   * @returns {boolean}
  */
  get isModified() {
    return this._isModified
  }

  /**
   * @param {boolean} isModified
  */
  set isModified(isModified) {
    this._isModified = isModified
  }

  /**
   * @param {TimelineItem} item
   * @return {boolean}
   */
  intersectsWithTimelineItem(item) {
    return isTimelineItemsIntersected(this.startTime, this.endTime, item.startTime, item.endTime)
  }

  // ---

  /**
   * @param {number} at
   * @returns {boolean}
   * @protected
   */
  _canTruncateAt(at) {
    // strict compare, because truncating at very edge of clip makes no sense
    return at > this.startTime && at < this.endTime
  }

  /**
   * Shifts given edge of asset by given offset,
   * adjusting `duration` respectively.
   *
   * @param {number} offset
   * @param {boolean} leftSide
   * @protected
   */
  _adjust(offset, leftSide) {
    if (leftSide) {
      this.startTime += offset
      this.duration -= offset
    } else {
      this.endTime += offset
    }
  }

  /**
   * @param {number} at
   * @param {number} delta
   * @param {boolean} leftSide
   * @protected
   */
  _truncate(at, delta, leftSide) {
    // For generic timeline item, truncating is exactly the same as shrink –
    // it just decreases duration by adjusting edge.
    // This method is intended to be extended in descendants.
    this.shrinkBy(leftSide ? delta : -delta)
  }

  _setIsTrim() {
    if (this._duration < this._originDuration && (this._originDuration - this._duration) > 10000) {
      this._isTrim = true
    } else {
      this._isTrim = false
    }
    return this
  }

}

export default TimelineItem
