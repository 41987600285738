import React, { createContext, ReactNode, useRef } from 'react'

// TODO: use jotai or smth to store such atomic state

export const CustomDragLayerContainerContext = createContext({
  getContainer: () => null,
  setContainer: () => {},
} as State)

type State = {
  getContainer: () => HTMLElement | null,
  setContainer: (node: HTMLElement) => void
}

type Props = {
  children: ReactNode | ReactNode[]
}

function DndContext({ children }: Props) {
  const container = useRef<HTMLElement | null>(null)

  const value = {
    getContainer: () => container.current,
    setContainer: (node: HTMLElement) => {
      container.current = node
    },
  }

  return (
    <CustomDragLayerContainerContext.Provider value={value}>{children}</CustomDragLayerContainerContext.Provider>
  )
}

export default DndContext
