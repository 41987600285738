import { moveAsset } from '~/actions/timeline'
import { PlaceholderAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import { getObjectFields } from '~/Util'
import { HistoryAction } from './HistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class PlaceholderDeletionAction extends HistoryAction {

  constructor(payload, ...args) {
    const { placeholders } = payload
    super(HistoryActionType.PlaceholderDeletionAction, payload, ...args)
    this.placeholders = placeholders.every(p => p instanceof PlaceholderAsset) ? placeholders
      : placeholders.map(p => new PlaceholderAsset(getObjectFields(p)))
  }

  get placeholders() {
    return this._placeholders
  }

  set placeholders(placeholders) {
    this._placeholders = placeholders
  }

  undo() {
    return this.moveAssets()
  }

  redo() {
    return this.moveAssets(-1)
  }

  * moveAssets(direction = 1) {
    const { getState, placeholders } = this
    for (const placeholder of placeholders.sort((a, b) => a.startTime - b.startTime)) {
      const { layerId, duration: placeholderDuration } = placeholder
      const state = getState()
      const layerAssets = Selectors.getLayerAssets(state, layerId)
      const assetsAfterPlaceholder = layerAssets.filter(a => a.startTime >= placeholder.startTime)
      for (const asset of assetsAfterPlaceholder.sort((a, b) => a.startTime - b.startTime)) {
        const { id: assetId, startTime } = asset
        yield moveAsset(
          assetId,
          { layerId, startTime: startTime + placeholderDuration * direction }
        )
      }
    }
  }

  getOptimizedObject() {
    return { ...this,
      placeholders: this.placeholders.map(placeholder => {
        const optimizedPlaceHolder = { ...placeholder }
        for (const key in optimizedPlaceHolder) {
          if (optimizedPlaceHolder[key] === null || optimizedPlaceHolder[key] === undefined) {
            delete optimizedPlaceHolder[key]
          }
        }
        return optimizedPlaceHolder
      }) }
  }

}
