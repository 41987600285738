export class AmazonForm {

  static initialValues = {
    bucketName: '',
    accessKeyId: '',
    secretKey: '',
  }

  constructor({ bucketName, accessKeyId, secretKey } = AmazonForm.initialValues) {
    this.bucketName = bucketName
    this.accessKeyId = accessKeyId
    this.secretKey = secretKey
  }

}
