import React, { useContext } from 'react'

import InfoView from 'components/views/InfoView/InfoView'
import { TranslationContext } from 'contexts/TranslationContext'

type Props = {
  onClose: () => void
}

function Webcam({ onClose }: Props) {
  const { t } = useContext(TranslationContext)

  return (
    <InfoView header={t('WEBCAM_TEXT_RECORD_VIDEO')} onHide={onClose}>
      <div />
    </InfoView>
  )
}

export default Webcam
