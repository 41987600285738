import { CHROMA_MAX_OPACITY } from 'constant'
import * as xmlEscape from 'xml-escape'
import { MEDIA_TYPE, TRANSITIONS } from '~/enums'
import { XTL_TRANSITION_TYPES, TRANSITION_TRACK_TYPE, CLIP_FLAGS, TRACK_TYPE } from './enums'

const ALPHA_MAX = 255
const ALPHA_MIN = 0

function num(value, max = true) {
  return (max ? Math.max(value, 0) : value).toFixed(0)
}

function cond(condition, value) {
  return condition === true ? value : ''
}

/**
 * @param {number} alphaStart
 * @param {number} alphaStop
 * @param {number} duration
 * @returns {string}
 */
function getTransparancyEffectParams(alphaStart, alphaStop, duration) {
  return `
    <param name="bkcolor" value="black"/>
    <param name="alpha" value="${num(alphaStart)}">
       <linear
          length="${num(duration)}"
          value="${num(alphaStop)}"
          timeFormat="100ns_units"
        />
    </param>
  `
}

/**
 * @param {TRANSITION_TRACK_TYPE} trackType
 * @param {number} duration
 * @returns {string}
 */
function getDissolveTransitionEffectParams(trackType, duration) {
  const originalTrack = trackType === TRANSITION_TRACK_TYPE.ORIGINAL
  const alphaStart = originalTrack ? ALPHA_MAX : ALPHA_MIN
  const alphaStop = originalTrack ? ALPHA_MIN : ALPHA_MAX
  return getTransparancyEffectParams(alphaStart, alphaStop, duration)
}

/**
 * @param {TRANSITION_TRACK_TYPE} transitionType
 * @param {number} duration
 * @returns {string}
 */
function getFadeEffectParams(transitionType, duration) {
  const fadeOut = transitionType === TRANSITIONS.FADEOUT
  const alphaStart = fadeOut ? ALPHA_MAX : ALPHA_MIN
  const alphaStop = fadeOut ? ALPHA_MIN : ALPHA_MAX
  return getTransparancyEffectParams(alphaStart, alphaStop, duration)
}

/**
 * @param {TRANSITION_TRACK_TYPE} trackType
 * @param {TRANSITIONS} transitionType
 * @param {number} duration
 * @returns {string}
 */
function getTransitionEffectParams(trackType, transitionType, duration) {
  switch (transitionType) {
    case TRANSITIONS.DISSOLVE: {
      return getDissolveTransitionEffectParams(trackType, duration)
    }
    case TRANSITIONS.FADEOUT:
    case TRANSITIONS.FADEIN: {
      return getFadeEffectParams(transitionType, duration)
    }
    default:
      return ''
  }
}

/**
 * @param {Assets.TransitionAsset} transition
 * @param {TRANSITION_TRACK_TYPE} trackType
 * @returns {string}
 */
export function transitionEffectOnTrack(transition, trackType) {
  const { type, duration } = transition
  const effectParamsXTL = getTransitionEffectParams(trackType, type, duration)
  return `
    <effect
      type="transparency"
      start="${num(transition.startTime)}"
      stop="${num(transition.endTime)}"
      timeFormat="100ns_units"
    >
      ${effectParamsXTL}
    </effect>
  `
}

export const getChromaKeyEffectParams = settings => {
  const { smoothness, similarity, chromaKeyColor, spillReduction, opacity } = settings

  // xtl needs opacity in 0-255 diapason
  const xtlOpacity = Math.round(opacity * 255 / CHROMA_MAX_OPACITY)

  return `
    <param name="r" value="${chromaKeyColor.r}" />
    <param name="g" value="${chromaKeyColor.g}" />
    <param name="b" value="${chromaKeyColor.b}" />
    <param name="similarity" value="${similarity}"/>
    <param name="smoothness" value="${smoothness}"/>
    <param name="spill" value="${spillReduction}"/>
    <param name="opacity" value="${xtlOpacity}"/>
  `
}

/**
 * @param {Assets.Asset} asset
 * @param {TRANSITION_TRACK_TYPE} trackType
 * @returns {string}
 */
export function chromaKeyEffectOnTrack(asset) {
  const { startTime, endTime } = asset
  const effectParamsXTL = getChromaKeyEffectParams(asset.settings)
  return `
    <effect
      type="chroma_key"
      start="${num(startTime)}"
      stop="${num(endTime)}"
      timeFormat="100ns_units"
    >
      ${effectParamsXTL}
    </effect>
  `
}

/**
 * @param {string} fileId
 * @param {number} mediaStart
 * @param {number} mediaEnd
 * @return {string}
 */
function clipWithStartStop({ fileId, mediaStart, mediaEnd }) {
  return `
    <clip
      src="${fileId}"
      start="${num(mediaStart)}"
      stop="${num(mediaEnd)}"
      timeFormat="100ns_units"
    />
  `
}

/**
 * @param {string} fileId
 * @param {number} duration
 * @return {string}
 */
function clipWithLength({ fileId, duration }) {
  return `
    <clip
      src="${fileId}"
      length="${num(duration)}"
      timeFormat="100ns_units"
    />
  `
}

/**
 * @param {string} fileId
 * @param {string} clipXTL
 * @param {string} flags
 * @param {object} [padding]
 * @param {number} [padding.left=0]
 * @param {number} [padding.right=0]
 * @return {string}
 */
function paddedClip({ fileId, clipXTL, padding = {}, flags = CLIP_FLAGS.BLANK }) {
  const { left = 0, right = 0 } = padding
  return `
    ${cond(left > 0, `
      <clip
        src="${fileId}"
        length="${num(left)}"
        timeFormat="100ns_units"
        flags="${flags}"
      />
    `)}
    ${clipXTL}
    ${cond(right > 0, `
      <clip
        src="${fileId}"
        length="${num(right)}"
        timeFormat="100ns_units"
        flags="${flags}"
      />
    `)}
  `
}
/**
 * @param {TRACK_TYPE} trackType
 * @param {MEDIA_TYPE} trackType
 * @returns {Number}
 */
function getAudioTrackNum(format) {
  return (format === MEDIA_TYPE.MXF) ? -1 : 1
}

/**
 * @param {Assets.AudioAsset} [asset]
 * @return {string}
 */
export function volumeEffect(asset) {
  return (asset.volume !== 1) ? `
  <effect type="volume" start="${num(asset.startTime)}" stop="${num(asset.endTime)}" timeFormat="100ns_units">
  <param name="volume" value="${num(asset.volume * 100)}"/>
  </effect>
` : ''
}
/**
 * @param {Assets.AudioAsset} [asset]
 * @param {object} [padding]
 * @param {number} [padding.left]
 * @param {number} [padding.right]
 * @return {string}
 */
export function audioTrack({ asset, padding }) {
  const { fileId, mediaStart, mediaEnd, filetype } = asset
  const clipXTL = clipWithStartStop({ fileId, mediaStart, mediaEnd })
  const volumeEffectXTL = volumeEffect(asset)
  const clipsXTL = paddedClip({
    fileId,
    clipXTL,
    padding,
    flags: CLIP_FLAGS.AUDIO_SILENCE,
  })
  return `
    <track audio="${getAudioTrackNum(filetype)}">
      ${clipsXTL}
      ${volumeEffectXTL}
      <transition
        type="${XTL_TRANSITION_TYPES.MIX}"
        start="${num(asset.startTime)}"
        stop="${num(asset.endTime)}"
        timeFormat="100ns_units"
      >
      </transition>
    </track>
  `
}

/**
 * @param {Assets.VideoAsset} [asset]
 * @param {object} [padding]
 * @param {number} [padding.left]
 * @param {number} [padding.right]
 * @param {string} effects
 * @return {string}
 */
export function videoMixTrack({ asset, padding, effects }) {
  const { fileId, mediaStart, mediaEnd } = asset
  const clipXTL = clipWithStartStop({ fileId, mediaStart, mediaEnd })
  const clipsXTL = paddedClip({
    fileId,
    clipXTL,
    padding,
    flags: CLIP_FLAGS.BLANK,
  })
  return `
    <track video="1">
      ${clipsXTL}
      ${effects}
      <transition
        type="${XTL_TRANSITION_TYPES.MIX}"
        start="${num(asset.startTime)}"
        stop="${num(asset.endTime)}"
        timeFormat="100ns_units"
      >
      </transition>
    </track>
  `
}

/**
 * @param {Assets.MediaAsset} asset
 * @param {object} [padding]
 * @param {number} [padding.left]
 * @param {number} [padding.right]
 * @param {string} [effects]
 * @return {string}
 */
export function pipOverlayTrack({ asset, videoPosition, fileId, padding, effects }) {
  const { duration, mediaStart, mediaEnd } = asset
  const clipXTL = mediaStart ? clipWithStartStop({
    fileId,
    mediaStart,
    mediaEnd,
  }) : clipWithLength({ duration, fileId })
  const clipsXTL = paddedClip({
    fileId,
    clipXTL,
    padding,
  })
  return `
    <track video="1">
      ${clipsXTL}
      ${effects}
      <transition
        type="${XTL_TRANSITION_TYPES.PIP}"
        start="${num(asset.startTime)}"
        stop="${num(asset.endTime)}"
        timeFormat="100ns_units"
      >
        ${videoPositionParams(videoPosition)}
      </transition>
    </track>
  `
}

/**
 * @param {object} videoPosition
 * @param {number} videoPosition.x
 * @param {number} videoPosition.y
 * @param {number} videoPosition.width
 * @param {number} videoPosition.height
*/
export function videoPositionParams(videoPosition) {
  const { x, y, width, height } = videoPosition
  const params = [
    x ? { name: 'x', value: num(x, false) } : null,
    y ? { name: 'y', value: num(y, false) } : null,
    width ? { name: 'width', value: num(width, false) } : null,
    height ? { name: 'height', value: num(height, false) } : null,
  ]
  return params.map(param => param ? `<param name="${param.name}" value="${param.value}"/>` : '').join('\n')
}

/**
 * @param {Assets.TransitionAsset} asset
 * @param {object} options
 * @param {string} options.fileId
 * @param {number} options.mediaStart
 * @param {number} options.mediaEnd
 * @param {XTL_TRANSITION_TYPES} options.type
 * @param {object} [options.padding]
 * @param {number} [options.padding.left]
 * @param {number} [options.padding.right]
 * @return {string}
 */
export function transitionTrack(transition, options) {
  const { padding, fileId, mediaStart, mediaEnd, type } = options
  const clipStop = Math.min(mediaStart + transition.duration, mediaEnd)
  const clipXTL = clipWithStartStop({
    fileId,
    mediaStart,
    mediaEnd: clipStop,
  })
  const effectXTL = transitionEffectOnTrack(transition, TRANSITION_TRACK_TYPE.OVERLAY)
  const clipsXTL = paddedClip({
    fileId,
    clipXTL,
    padding,
  })
  return `
    <track video="1">
      ${clipsXTL}
      ${effectXTL}
      <transition
        type="${type}"
        start="${num(transition.startTime)}"
        stop="${num(transition.endTime)}"
        timeFormat="100ns_units"
      >
      </transition>
    </track>
  `
}

/**
 * @param {Assets.VideoAsset} clip
 * @returns {string}
 */
export function mediaClip(clip, flags = '') {
  if (Math.floor(clip.duration) === 0) {
    return ''
  }
  return `
    <clip
      src="${clip.fileId}"
      start="${num(clip.mediaStart)}"
      stop="${num(clip.mediaEnd)}"
      timeFormat="100ns_units"
      flags="${flags}"
    />
  `
}
/**
 * @param {number} duration
 * @param {TRACK_TYPE} trackType
 * @returns {string}
 */
export function blankClip(duration, trackType) {
  if (Math.floor(duration) === 0) {
    return ''
  }
  const templateName = trackType === TRACK_TYPE.VIDEO ? 'template_blank.png' : 'template_audio_mute.mp4'
  const flags = trackType === TRACK_TYPE.VIDEO ? CLIP_FLAGS.BLANK : CLIP_FLAGS.AUDIO_SILENCE
  return `
    <clip
      src="${templateName}"
      start="0"
      stop="${num(duration)}"
      timeFormat="100ns_units"
      flags="${flags}"
    />
  `
}

/**
 * @param {Assets.VideoAsset[]} mediaClips
 * @param {string} effects
 * @param {TRACK_TYPE} trackType
 * @returns {string}
 */
export function mediaTrack(format, mediaClips, effects, trackType) {
  return `
    <track
      video="${(trackType === TRACK_TYPE.VIDEO || trackType === TRACK_TYPE.VIDEO_AND_AUDIO) ? 1 : 0}"
      audio="${(trackType === TRACK_TYPE.AUDIO || trackType === TRACK_TYPE.VIDEO_AND_AUDIO) ? getAudioTrackNum(format) : 0}"
      accuracy="frame">
      ${mediaClips}
      ${effects}
    </track>
  `
}

/**
 * @param {string} taskParams
 * @returns {string}
 */
export function taskTranscoding(taskParams) {
  return `
    <task type="transcoding">
      ${taskParams}
    </task>
`
}

const defaultMXFStreamsCount = 8

function defaultACodecParamsMXF() {
  return Array(defaultMXFStreamsCount).fill('<param name="acodec" value="type=pcm,rate=48000,channels=1,bits=16"/>').join('\n')
}

export function defaultACodecParams(format) {
  switch (format) {
    case MEDIA_TYPE.MXF:
      return defaultACodecParamsMXF()
    default:
      return '<param name="acodec" value="type=aac,profile=LC,bitrate=128000,rate=44100,channels=2"/>'
  }
}

export function defaultVCodecParams({ width, height }) {
  return `<param name="vcodec" value="type=h264,fps_n=25,fps_d=1,width=${width},height=${height},profile=baseline,bitrate=5000,keyint=10"/>`
}

/**
 * @param {string} referenceFileId
 * @returns {string}
 */
export function referenceFileParam(referenceFileId) {
  return `<param name="reference_file" value="${referenceFileId}"/>`
}

function format2EngineOutType(format) {
  switch (format) {
    case MEDIA_TYPE.TS:
      return 'mpg_ts'
    default:
      break
  }
  return format
}

/**
 * @param {string} outputName
 * @param {string} transcodingTask
 * @param {string} tracks
 * @returns {string}
 */
export function timelines({
  outputName,
  format,
  transcodingTask,
  tracks,
}) {
  return `
    <timelines version="3">
      <timeline>
        <group output="${xmlEscape(outputName)}.${format}" out_type="${format2EngineOutType(format)}">
          ${transcodingTask}
          ${tracks}
        </group>
      </timeline>
    </timelines>
  `
}
