import React, { useRef } from 'react'
import cx from 'classnames'

import Input from 'components/base/Input'
import { IMaskInput } from 'react-imask'
import './SettingsInput.scss'

// eslint-disable-next-line no-unused-vars
export default function SettingsInput({ defaultValue = null, icon = null, onChange = e => {},
  min = 0, max = 100, disabled = false, inputProps = {}, className = '', variant = 'primary' }) {
  const inputRef = useRef()

  const handleBlur = e => {
    const { value } = e.target
    if (parseInt(value) !== defaultValue) {
      onChange(e)
    }
  }

  const blurInput = newValue => {
    // NOTE: this for the Material Input
    const children = inputRef?.current?.getElementsByTagName('input')
    if (children.length) {
      if (newValue) {
        children[0].value = newValue
      }
      children[0].blur()
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      blurInput()
    } else if (e.key === 'Escape') {
      blurInput(defaultValue)
    }
  }

  return (
    <div className={cx('settings-input', { 'settings-input--disabled': disabled,
      'settings-input--secondary': variant === 'secondary' }, className)}
    >
      <div className={cx('settings-input__box', {
        'settings-input__box--iconDisabled': !icon,
      })}
      >
        <Input
          ref={inputRef}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          startAdornment={icon}
          inputComponent={IMaskInput}
          inputProps={{
            mask: Number,
            min,
            max,
            value: `${defaultValue}`,
            radix: '.',
            placeholder: `${min}`,
            scale: 0,
            ...inputProps,
          }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
