/* eslint-disable no-unused-vars */
import { getRotatedSize } from 'helpers/getRotatedSize'
import { maxBy } from 'lodash'
import { TEXT_POSITION as POSITION } from '~/enums'
import { useChanged } from '~/hooks'

/**
 * @param {Geometry.Rect} rect
 * @param {Geometry.Rect} viewport
 * @returns {Geometry.Point}
 */
function centerRectInViewport(rect, viewport) {
  return {
    x: Math.floor((viewport.width - rect.width) / 2),
    y: Math.floor((viewport.height - rect.height) / 2),
  }
}

/**
 * Decrease dimensions of `rect1` by dimensions of `rect2`.
 *
 * @template {Geometry.Rect} T
 * @param {T} rect1
 * @param {Geometry.Rect} rect2
 * @returns {T}
 */
function subtractRect(rect1, rect2) {
  return {
    ...rect1,
    width: rect1.width - rect2.width,
    height: rect1.height - rect2.height,
  }
}

// ---

/**
 * @typedef {object} TextAssetPreviewGeometry
 * @property {Geometry.PositionedRect} boundingRect
 * @property {Geometry.Rect} assetKonvaSize
 */

/**
 * @param {{ anchor: string, offset: TextAssetPositionOffset, rotation: number }} params
 * @param {TextAssetPreviewGeometry} geometry
 * @returns {[ Geometry.Point, Geometry.Point ]}
 */
// DEPRECATED
export function useAssetKonvaCoords(params, geometry) {
  const { anchor, offset, rotation = 0, flip } = params
  const { boundingRect, assetKonvaSize } = geometry

  const anchorCoords = getAnchorPointCoords(anchor, geometry)
  const offsetCoords = getCoordsFromOffset(offset, anchorCoords, geometry)
  const fitCoords = fitRectInViewport(offsetCoords, assetKonvaSize, boundingRect, rotation, true)

  let scaleX = 1
  let scaleY = 1

  if (flip) {
    const { vertical, horizontal } = flip
    if (vertical && !horizontal) {
      scaleY = -1
    } else if (horizontal && !vertical) {
      scaleX = -1
    } else if (horizontal && vertical) {
      scaleY = -1
      scaleX = -1
    }
  }

  const x = fitCoords.x + boundingRect.x
  const y = fitCoords.y + boundingRect.y

  const res = [
    // relative to entire layer
    useChanged({
      x,
      y,
      rotation,
      scaleY,
      scaleX,
    }),

    // relative to bounding rect inside layer
    useChanged(fitCoords),
  ]

  return res
}

/**
 * @param {string} position
 * @param {TextAssetPreviewGeometry} geometry
 * @returns {Geometry.Point}
 */
function getAnchorPointCoords(position, geometry) {
  const { boundingRect, assetKonvaSize } = geometry
  const rect = subtractRect(boundingRect, assetKonvaSize)

  const {
    x: xLeft,
    y: yTop,
    width: xRight,
    height: yBottom,
  } = rect

  const {
    x: xCenter,
    y: yCenter,
  } = centerRectInViewport(assetKonvaSize, boundingRect)

  let x
  let y

  switch (position) {
    case POSITION.TOP_LEFT:
      x = xLeft
      y = yTop
      break
    case POSITION.TOP:
      x = xCenter
      y = yTop
      break
    case POSITION.TOP_RIGHT:
      x = xRight
      y = yTop
      break
    case POSITION.LEFT:
      x = xLeft
      y = yCenter
      break
    case POSITION.MIDDLE_CENTER:
      x = xCenter
      y = yCenter
      break
    case POSITION.RIGHT:
      x = xRight
      y = yCenter
      break
    case POSITION.BOTTOM_LEFT:
      x = xLeft
      y = yBottom
      break
    case POSITION.BOTTOM:
      x = xCenter
      y = yBottom
      break
    case POSITION.BOTTOM_RIGHT:
      x = xRight
      y = yBottom
      break
    default:
      throw new Error(`Unknown anchor point: ${position}`)
  }

  const res = {
    x: Math.round(x),
    y: Math.round(y),
  }

  return res
}

/**
 * @param {TextAssetPositionOffset} offset
 * @param {Geometry.Point} coordsOffset
 * @param {TextAssetPreviewGeometry} geometry
 * @returns {Geometry.Point}
 */
export function getCoordsFromOffset(offset, coordsOffset = { x: 0, y: 0 }, geometry) {
  const { top, bottom, left, right } = offset
  const { boundingRect, assetKonvaSize, rotation,
    outlineWidth = 0, scale = { x: 1, y: 1 } } = geometry

  const sizeWithOutline = {
    width: assetKonvaSize.width + outlineWidth,
    height: assetKonvaSize.height + outlineWidth,
  }

  const { width, height } = getRotatedSize(sizeWithOutline, rotation)

  let x = 0
  let y = 0

  if (top !== null) {
    y = (top * boundingRect.height / 100) + Number(height) / 2
  }

  if (bottom !== null) {
    y = (100 - bottom) * boundingRect.height / 100 - height / 2
  }

  if (left !== null) {
    x = (left * boundingRect.width / 100) + Number(width) / 2
  }

  if (right !== null) {
    x = (100 - right) * boundingRect.width / 100 - width / 2
  }

  if (top === null && bottom === null) {
    y = boundingRect.height / 2
  }

  if (left === null && right === null) {
    x = boundingRect.width / 2
  }

  return { x, y, rotatedWidth: width, rotatedHeight: height }
}

/**
 * Shift given coords left-top, to don't let given rect run out of viewport boundaries
 *
 * @param {Geometry.Point} rectPosition
 * @param {Geometry.Rect} rect
 * @param {Geometry.Rect} viewport
 * @return {Geometry.Point}
 */
function fitRectInViewport(rectPosition, rect, viewport, rotation, allowOutboundPos = false) {
  const { x, y } = rectPosition

  const sin = Math.sin(toRadians(rotation))
  const cos = Math.cos(toRadians(rotation))

  const rotatedWidth = rect.width * sin
  const rotatedHeight = rect.height * cos

  const isWrongWidth = Math.floor(x + rotatedWidth) > viewport.width
  const isWrongHeight = Math.floor(y + rotatedHeight) > viewport.height

  return {
    // shift text left if its new width is out of viewport region on the right
    x: isWrongWidth && !allowOutboundPos
      ? Math.max(0, viewport.width - rotatedWidth)
      : x,
    // shift text up if its new height is out of viewport region on the bottom
    y: isWrongHeight && !allowOutboundPos
      ? Math.max(0, viewport.height - rotatedHeight)
      : y,
  }
}

// ---

/**
 * This is different from `textNode.getClientRect()`.
 * clientRect is restricted by canvas boundaries. If text exceeds viewport, clientRect won't grow.
 * This helper, in difference, measures full text size regardless to boundaries.
 *
 * @param {Konva.Text} textNode
 * @param {string} text
 * @return {Geometry.Rect}
 */
export function measureTextSize(textNode, text) {
  // measureSize method can't handle multiline text. So calculate true text size
  const lines = text.split('\n')
  let { width, height } = maxBy(
    lines.map(line => textNode.measureSize(line)),
    size => size.width
  )
  height *= lines.length
  return { width, height }
}

// ---

/**
 * @param {TextAssetPreviewGeometry} geometry
 * @param {string} anchor
 * @param {Geometry.Point} coords
 * @return {Geometry.Point}
 */
export function getDragBoundCoords(
  geometry,
  anchor,
  rotation,
  allowOutboundPos,
  coords,
  applyOffset = true
) {
  const { boundingRect, assetKonvaSize } = geometry
  const { width, height } = assetKonvaSize

  let boundingRectX = boundingRect.x
  let boundingRectY = boundingRect.y

  const { x: offsetX, y: offsetY, rotationAdditiveX, rotationAdditiveY } = getCoordOffsetOfRotation(
    { rotation, width, height }
  )

  if (applyOffset) {
    boundingRectX += offsetX
    boundingRectY += offsetY
  }

  const boundPos = {
    x: allowOutboundPos ? coords.x : Math.round(Math.min(
      Math.max(boundingRectX, coords.x),
      boundingRectX + boundingRect.width - rotationAdditiveX
    )),
    y: allowOutboundPos ? coords.y : Math.round(Math.min(
      Math.max(boundingRectY, coords.y),
      boundingRectY + boundingRect.height - rotationAdditiveY
    )),
  }

  // ---

  const centerCoords = centerRectInViewport(assetKonvaSize, boundingRect)
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.miaovpglsfel
  // When vertical or horizontal anchor-point is active, lock changes for opposite coordinate
  if (anchor === POSITION.TOP || anchor === POSITION.BOTTOM) {
    boundPos.x = centerCoords.x + boundingRectX
  } else if (anchor === POSITION.LEFT || anchor === POSITION.RIGHT) {
    boundPos.y = centerCoords.y + boundingRectY
  }

  // ---
  return boundPos
}

export const getDragBoundingFunc = (position, geometry) => pos => {
  let { x, y } = pos
  const { assetKonvaSize, boundingRect } = geometry
  const centerCoords = centerRectInViewport(assetKonvaSize, boundingRect)
  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.miaovpglsfel
  // When vertical or horizontal anchor-point is active, lock changes for opposite coordinate
  if (position === POSITION.TOP || position === POSITION.BOTTOM) {
    x = centerCoords.x + boundingRect.offsetX + assetKonvaSize.width / 2
  } else if (position === POSITION.LEFT || position === POSITION.RIGHT) {
    y = centerCoords.y + boundingRect.offsetY + assetKonvaSize.height / 2
  }
  return { x, y }
}

export function toRadians(angle) {
  return angle * (Math.PI / 180)
}

function toDegrees(angle) {
  return angle / (Math.PI / 180)
}

export function getCoordOffsetOfRotation({ rotation, width, height, correction = true }) {
  const rotationRadians = toRadians(rotation)
  const cos = Math.cos(rotationRadians)
  const sin = Math.sin(rotationRadians)
  const rotationAdditiveX = Math.abs(cos * width) + Math.abs(sin * height)
  // TODO: refactor this
  const rotationAdditiveY = correction ? Math.abs(cos * height) + Math.abs(sin * width)
    : Math.sqrt(Math.pow(cos * height, 2) + Math.pow(sin * width, 2))

  let x = 0
  let y = 0
  if (rotation > 90) {
    x += rotationAdditiveX
    y += Math.abs(cos * height)
  } else if (rotation > 0) {
    x += Math.abs(sin * height)
  } else if (rotation > -90) {
    y += Math.abs(sin * width)
  } else if (rotation < -90) {
    x += Math.abs(cos * width)
    y += rotationAdditiveY
  }

  return { x, y, rotationAdditiveX, rotationAdditiveY }
}

export const getTransformerBoundFunc = (anchor, boundingRect) => (oldBox, newBox) => {
  const { x, y, width, height, rotation: rotationRadians } = newBox
  const rotation = toDegrees(rotationRadians)

  const { x: offsetX, y: offsetY } = getCoordOffsetOfRotation({ rotation, width, height })

  const modifiedX = x - offsetX
  const modifiedY = y - offsetY

  const modifiedBoundingRect = { ...boundingRect, height: boundingRect.height }

  const { x: boundX, y: boundY } = getDragBoundCoords({
    boundingRect: modifiedBoundingRect,
    assetKonvaSize: { width, height },
  }, anchor, rotation, true, { x: modifiedX, y: modifiedY }, false)

  if ((modifiedY + 1 < boundY || modifiedX + 1 < boundX)
      || (modifiedY - 1 > boundY || modifiedX - 1 > boundX)) {
    return oldBox
  }

  return newBox
}

/**
 * @param {TextAssetPreviewGeometry} geometry
 * @param {string} anchor
 * @param {Geometry.Point} coords
 * @return {{offset: TextAssetPositionOffset, position: string?}}
 */
export function getDragEndPatch(params) {
  const { x, y, rotation, boundingRect,
    anchor: originalAnchor, size, assetKonvaSize = size } = params

  // const size = params.size || assetKonvaSize
  // const { width, height } = size

  // const centerX = x - boundingRect.x + width / 2
  // const centerY = y - boundingRect.y + height / 2

  // const widthPercentage = centerX * 100 / boundingRect.width
  // const heightPercentage = centerY * 100 / boundingRect.height

  const r = toRadians(rotation)
  const width = assetKonvaSize.width * Math.abs(Math.cos(r))
    + assetKonvaSize.height * Math.abs(Math.sin(r))
  const height = assetKonvaSize.width * Math.abs(Math.sin(r))
    + assetKonvaSize.height * Math.abs(Math.cos(r))

  const percentWidth = boundingRect.width / 100
  const percentHeight = boundingRect.height / 100
  const leftPercentOffset = (x - boundingRect.offsetX) / percentWidth
  const rightPercentOffset = (x + width
    - boundingRect.offsetX) / percentWidth
  const topPercentOffset = (y - boundingRect.offsetY) / percentHeight
  const bottomPercentOffset = (y + height
    - boundingRect.offsetY) / percentHeight

  let offset = {
    [POSITION.BOTTOM]: null,
    [POSITION.RIGHT]: null,
    [POSITION.LEFT]: null,
    [POSITION.TOP]: null,
  }

  let anchor = originalAnchor

  // const sidePositions = [ POSITION.TOP, POSITION.RIGHT, POSITION.BOTTOM, POSITION.LEFT ]

  // @link https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.3pt8q09ne9qe
  if (originalAnchor === POSITION.MIDDLE_CENTER || size) {
    anchor = POSITION.TOP_LEFT
  }

  const patch = { position: anchor }

  const patchOffset = v => ({ ...offset, ...v })

  if (anchor === POSITION.TOP || anchor === POSITION.TOP_LEFT || anchor === POSITION.TOP_RIGHT
    || anchor === POSITION.MIDDLE_CENTER) {
    offset = patchOffset({
      [POSITION.TOP]: topPercentOffset,
    })
  }

  if (anchor === POSITION.BOTTOM_LEFT
    || anchor === POSITION.TOP_LEFT || anchor === POSITION.LEFT
    || anchor === POSITION.MIDDLE_CENTER) {
    offset = patchOffset({
      [POSITION.LEFT]: leftPercentOffset,
    })
  }

  if (anchor === POSITION.BOTTOM_RIGHT
    || anchor === POSITION.TOP_RIGHT || anchor === POSITION.RIGHT) {
    offset = patchOffset({
      [POSITION.RIGHT]: 100 - rightPercentOffset,
    })
  }

  if (anchor === POSITION.BOTTOM
    || anchor === POSITION.BOTTOM_LEFT || anchor === POSITION.BOTTOM_RIGHT) {
    offset = patchOffset({
      [POSITION.BOTTOM]: 100 - bottomPercentOffset,
    })
  }

  patch.offset = offset

  if (rotation) {
    patch.rotation = rotation
  }

  if (params.size) {
    patch.size = params.size
  }

  return patch
}

/**
 * @param {object} params
 * @param {boolean} params.isMeasured
 * @param {Geometry.Rect} params.textSize
 * @param {Geometry.Rect} params.boundingRect
 * @param {Geometry.Point} params.scale
 * @return {Geometry.Rect|{ width: 'auto', height: 'auto' }}
 */
export function normalizeTextSize(params) {
  const {
    isMeasured,
    textSize,
    // boundingRect,
  } = params

  if (!isMeasured) {
    return {
      width: 'auto',
      height: 'auto',
    }
  }

  // <Text/> element must always receive a REAL text width (without scaling), otherwise it will be truncated with "...".
  // So that, bounding rect must be scaled up, to determine text max width.
  return {
    // http://18.184.210.86/issues/1988
    width: textSize.width,
    height: textSize.height,

    // width: Math.min(textSize.width, boundingRect.width),
    // height: Math.min(textSize.height, boundingRect.height),
  }
}

const rotatePoint = ({ x, y }, rad) => {
  const rcos = Math.cos(rad)
  const rsin = Math.sin(rad)
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin }
}

export function rotateAroundCenter(node, rotation) {
  if (!node) {
    return {}
  }
  const topLeft = { x: -node.width() / 2, y: -node.height() / 2 }
  const current = rotatePoint(topLeft, toRadians(node.rotation()))
  const rotated = rotatePoint(topLeft, toRadians(rotation))
  const dx = rotated.x - current.x
  const dy = rotated.y - current.y

  const x = node.x() + dx
  const y = node.y() + dy

  node.rotation(rotation)
  node.x(x)
  node.y(y)

  return { x, y, rotation }
}
