export const BASE_AUTH_URL = 'https://www.solveigmm.com/ls/api'
export const WSS_AUTH_URL = 'www.solveigmm.com/ls/api/ws'
export const LOCAL_STORAGE_TOKEN_VAR = 'authTokenForLicenceKeys'
export const LOCAL_STORAGE_KEY_VAR = 'activeLicenseKey'
export const LOCAL_STORAGE_ACTIVATION_KEY_DATE_VAR = 'activationKeyDate'
export const LOCAL_STORAGE_LAST_CHECK_KEY_DATE_VAR = 'lastCheckKeyDate'
export const LOCAL_STORAGE_FAILED_CHECK_DATE_VAR = 'failedCheckDate'
export const LOCAL_STORAGE_EDITION_VAR = 'licenseEdition'
export const PRODUCT_NAME = 'html5_video_editor_desktop'
export const FREE_KEY = 'Free'
export const LICENSE_PING_INTERVAL = 10_000
export const LONG_KEY_CHECK_PERIOD = 1210000000 // 2 weeks
export const SHORT_KEY_CHECK_PERIOD = 259200000 // 3 days
export const PERIOD_WITH_FAILED_AND_WITH_ERROR = 2592000000 // 1 month
export const MAX_PERIOD_WITH_FAILED_CHECK = (
  PERIOD_WITH_FAILED_AND_WITH_ERROR + LONG_KEY_CHECK_PERIOD) // 1 month + 2 weeks
export type ERROR_TYPE = 'ACTIVATION_NO_CONNECTION'
