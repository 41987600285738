import { isEmpty } from 'lodash'

export const setNewLayerId = (dragAssetsByLayerIndex,
  newLayersArray, layers, ignoredLayerIndexes) => {
  const cloneDragAssetsByLayerIndex = JSON.parse(JSON.stringify(dragAssetsByLayerIndex))
  const newLayerIds = newLayersArray.map(layer => layer.id)
  const currentLayers = layers?.filter(layer => newLayerIds.includes(layer.id))
  Object.values(cloneDragAssetsByLayerIndex ?? {}).forEach((assets, index) => {
    assets.forEach(asset => {
      if (asset && (!ignoredLayerIndexes
        || isEmpty(ignoredLayerIndexes) || !ignoredLayerIndexes.includes(index))) {
        // eslint-disable-next-line no-param-reassign
        asset.newLayerId = currentLayers[index]?.id
      }
    })
  })
  return cloneDragAssetsByLayerIndex
}
