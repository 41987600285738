import { saveProjectOnExit as saveProjectOnExitAction } from 'actions/projectData/saveProject'
import { AudioPanel, MediaPanel, TextPanel, TransitionPanel } from 'components/AssetPanels'
import ImageAssetsPanel from 'components/AssetPanels/Images/Panel'
import CustomDragLayerContainerProvider from 'components/CustomDragLayerContainerProvider'
import ImportFromUrl from 'components/ImportFromUrl/ImportFromUrl'
import WebEditorMenu from 'components/Menu/Menu'
import Preview from 'components/Preview'
import SaveSettings from 'components/SaveSettings/SaveSettings'
import SectionPlaceholder from 'components/SectionPlaceholder/SectionPlaceholder'
import Timeline from 'components/Timeline/Timeline'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { AppRoutes } from '~/AppRoutes'
import * as Actions from '~/actions'
import { historyRedo as historyRedoAction, historyUndo as historyUndoAction, clearActionsHistoryError as clearActionsHistoryErrorAction } from '~/actions/historyActions'
import { clearActiveProjectError } from '~/actions/projectData/clearActiveProjectError'
import AmazonImport from '~/components/AmazonImport/AmazonImport'
import CustomDragLayerProvider from '~/components/CustomDragLayerContext'
import MessageBox from '~/components/base/MessageBox'
import { newProjectId } from '~/constant'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import { HOTKEYS, MENU_ITEMS } from '~/enums'
import { ERROR_TYPE } from '~/errors/ErrorType'
import { useAction, useKeyCodeListener, useWindowUnload } from '~/hooks'
import { selectActiveProjectError, selectActiveProjectId, selectProjectDataError } from '~/selectors/projectData'
import { selectActionHistoryError } from '~/selectors/historyActions'

export const Editor = () => {
  const [ isMounted, setIsMount ] = useState(false)
  const [ , _rerender ] = useState(false)
  const [ redirectToProjects, setRedirectToProjects ] = useState(false)
  const rerender = () => _rerender(x => !x)

  const history = useHistory()

  const showBlankProjectInList = useAction(Actions.showBlankProjectInList.showBlankProjectInList)
  const saveProjectOnExit = useAction(saveProjectOnExitAction)
  const saveProject = useAction(Actions.saveProject.saveProject)
  const initNewProject = useAction(Actions.initNewProject.initNewProject)
  const allowSaveEmpty = useAction(Actions.allowSaveEmpty.allowSaveEmpty)
  const loadProject = useAction(Actions.loadProject.loadProject)
  const { id: paramId } = useParams()

  const isShowSaveSettings = useSelector(state => state.mainView.showSaveSettings)

  const isShowImportFromUrl = useSelector(state => state.mainView.showImportFromUrl)
  const isShowAmazonImport = useSelector(state => state.mainView.showAmazonImport)

  const hideSaveSettings = useAction(Actions.mainView.showSaveSettings, false)
  const hideImportFromUrl = useAction(Actions.mainView.showImportFromUrl, false)
  const hideAmazonImport = useAction(Actions.mainView.showAmazonImport, false)

  const clearActionHistoryError = useAction(clearActionsHistoryErrorAction)
  const clearProjectError = useAction(clearActiveProjectError)

  const activeProjectId = useSelector(state => selectActiveProjectId(state))
  const activeProjectError = useSelector(selectActiveProjectError)
  const projectDataError = useSelector(selectProjectDataError)
  const actionHistoryError = useSelector(selectActionHistoryError)

  const saveOnExitCallback = useCallback(() => {
    saveProjectOnExit()
  }, [ saveProjectOnExit ])

  useWindowUnload(saveOnExitCallback)

  useEffect(() => {
    if (isMounted) {
      if (activeProjectId && paramId === newProjectId && activeProjectId !== newProjectId) {
        history.push(`${AppRoutes.Projects}/${activeProjectId}`)
      }
    } else {
      setIsMount(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeProjectId ])

  useEffect(() => {
    if (paramId === newProjectId) {
      initNewProject()
      if (!LatestProjectId.get() || LatestProjectId.get() === newProjectId) {
        showBlankProjectInList()
      }
    } else if (paramId !== activeProjectId && paramId !== newProjectId) {
      loadProject(paramId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paramId, initNewProject, loadProject, allowSaveEmpty ])

  useEffect(() => () => {
    saveProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPressEscape = () => {
    hideSaveSettings()
    hideImportFromUrl()
    hideAmazonImport()
  }

  useKeyCodeListener(React.useRef(document), 'Escape', onPressEscape)

  const handleActiveProjectError = error => {
    switch (error.type) {
      case ERROR_TYPE.PROJECT_LOAD_FAILED:
        setRedirectToProjects(true)
        break
      case ERROR_TYPE.PROJECT_IN_USE:
        window.location.reload()
        break
      default:
        clearProjectError()
    }
  }
  if (redirectToProjects) {
    return <Redirect to={AppRoutes.Projects} />
  }
  return (
    <>
      <If condition={__CFG__.TIMELINE.UNDO_REDO}>
        <UndoRedoKeysHandlers />
      </If>
      <If condition={actionHistoryError}>
        <MessageBox
          message={actionHistoryError}
          onClose={() => clearActionHistoryError()}
        />
      </If>
      <If condition={activeProjectError}>
        <MessageBox
          message={activeProjectError.message}
          onClose={() => handleActiveProjectError(activeProjectError)}
        />
      </If>
      <If condition={projectDataError}>
        <MessageBox
          message={projectDataError}
          onClose={() => clearProjectError()}
        />
      </If>
      <CustomDragLayerProvider>
        <CustomDragLayerContainerProvider>
          <SplitPane split="horizontal" defaultSize="45%" minSize={290} maxSize={610} primary="second" onDragFinished={rerender}>
            <SplitPane split="vertical" defaultSize={714} minSize={510} maxSize={918} step={1}>
              <Choose>
                <When condition={isShowSaveSettings}>
                  <SaveSettings />
                </When>

                <When condition={isShowImportFromUrl}>
                  <ImportFromUrl />
                </When>

                <When condition={isShowAmazonImport}>
                  <AmazonImport />
                </When>

                <Otherwise>
                  <SplitPane split="vertical" minSize={80} maxSize={80} resizerStyle={{ display: 'none' }}>
                    <WebEditorMenu />
                    <MenuPanel />
                  </SplitPane>
                </Otherwise>
              </Choose>

              <Preview controls />
            </SplitPane>
            <Timeline />
          </SplitPane>
        </CustomDragLayerContainerProvider>
      </CustomDragLayerProvider>
    </>
  )
}

function UndoRedoKeysHandlers() {
  const historyUndo = useAction(historyUndoAction)
  const historyRedo = useAction(historyRedoAction)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const onPressUndo = () => historyUndo()
  const onPressRedo = () => historyRedo()

  // const documentRef = useRef(document)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.UNDO], onPressUndo)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.REDO], onPressRedo)
  // useKeyCodeListener(documentRef, KEY_Z, onPressUndo, { ctrlKey: true, ignoreInputHistory: false })
  // useKeyCodeListener(documentRef, KEY_Y, onPressRedo, { ctrlKey: true, ignoreInputHistory: false })

  return null
}

function MenuPanel() {
  const { menuItem } = useSelector(state => state.mainView)
  const { t } = useContext(TranslationContext)
  switch (menuItem) {
    case MENU_ITEMS.MEDIA:
      return <MediaPanel />

    case MENU_ITEMS.TRANSITIONS:
      return <TransitionPanel />

    case MENU_ITEMS.TEXT:
      return <TextPanel />

    case MENU_ITEMS.AUDIO:
      return <AudioPanel />

    case MENU_ITEMS.FILTERS:
      return <SectionPlaceholder title={t('FILTERS')} />

    case MENU_ITEMS.DESIGN:
      return <SectionPlaceholder title={t('DESIGN')} />

    case MENU_ITEMS.IMAGES:
      return <ImageAssetsPanel />

    default:
      throw new Error(`Can't render ${menuItem} component`)
  }
}
