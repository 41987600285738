import { isEmpty } from 'lodash'

export const getStartNTopDraggableLayers = dragItem => (dragItem.selectedAssets ?? [])
  .reduce((result, asset) => {
    const currentResult = { ...result }
    if (isEmpty(currentResult.topLayer) || asset.layerIndex < currentResult.topLayer.index) {
      currentResult.topLayer = { index: asset.layerIndex, id: asset.layerId }
    }
    if (isEmpty(currentResult.startLayer) && dragItem.id === asset.id) {
      currentResult.startLayer = { index: asset.layerIndex, id: asset.layerId }
    }
    return currentResult
  }, { topLayer: {}, startLayer: {} })
