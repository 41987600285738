import React from 'react'
import produce from 'immer'
import { useChanged } from '~/hooks'

// ---

export const Context = React.createContext({
  time: 0,
  isDraggingItemOverLayer: false,
  setLayerHovered: () => {},
  setTime: () => {},
})

// ---

/**
 * This is responsible for two things:
 * 1. Hold local state for timeline slider time, which will be used while dragging item.
 *  So that we don't have to update store and re-render entire app to just move slider for a few pixels.
 *  This provides significant performance boost.
 *
 * 2. Manage 'is item dragged over some layer' aggregated state.
 *  When dragged item crosses border between two layers, layers will emit their states in *successive* order.
 *  This causes flickering of aggregated state (first it resets to false, then immediately back to true).
 *  This component removes this bad effect.
 */
export default function TimelineDnDContextProvider({ children }) {
  const [ localTime, setTime ] = React.useState(0)

  const [ isSomeLayerHovered, setIsSomeLayerHovered ] = React.useState(false)
  const [ hoveredLayersState, updateHoveredLayersState ] = React.useReducer(
    layersHoveredStateReducer,
    null,
    initLayersHoveredState
  )
  const refTimer = React.useRef()

  // Whenever any layer hover state changes, *schedule* an aggregated state update.
  // Cancel previous scheduled update if any new update comes.
  // By *waiting* for updates from all layers, we can avoid flickering of aggregated state.
  React.useEffect(
    () => {
      refTimer.current = setTimeout(
        setIsSomeLayerHovered,
        0,
        hoveredLayersState.size > 0
      )
      return () => {
        clearTimeout(refTimer.current)
      }
    },
    [ hoveredLayersState.size ]
  )

  // ---

  const context = useChanged({
    time: localTime,
    isDraggingItemOverLayer: isSomeLayerHovered,

    setLayerHovered: updateHoveredLayersState,
    setTime,
  })

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

// ---

function layersHoveredStateReducer(state, patch) {
  return produce(state, draft => {
    const { layerId, isHovered } = patch
    if (isHovered) {
      draft.add(layerId)
    } else {
      draft.delete(layerId)
    }
  })
}

function initLayersHoveredState() {
  return new Set()
}
