import * as ActionTypes from 'actions/ActionTypes'
import produce from 'immer'

const getInitialState = () => ({
  actions: [],
  currentActionIndex: -1,
  isLatestActionIsHistory: false,
  latestAction: {},
  ignoreLatestActionInHistory: false,
  // TODO: null is important for new project action creating
  isLockedHistory: false,
  error: undefined,
  blockAddHistoryAction: false,
})

const actionsHistory = (state, action) => {
  const isPointerAtLastAction = state.currentActionIndex === state.actions.length - 1

  const { type, payload } = action

  state.latestAction = { type, payload }

  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.ACTIONS_UNDO: {
      if (state.currentActionIndex !== undefined && state.currentActionIndex !== -1) {
        state.currentActionIndex -= 1
        state.isLatestActionIsHistory = true
      }
      state.isLockedHistory = true
      break
    }
    case ActionTypes.ACTIONS_REDO: {
      if (!isPointerAtLastAction) {
        state.currentActionIndex += 1
        state.isLatestActionIsHistory = true
      }
      state.isLockedHistory = true
      break
    }
    case ActionTypes.ACTIONS_UNLOCK_ACTIONS_HISTORY: {
      state.isLockedHistory = false
      break
    }
    case ActionTypes.ACTIONS_SET_BLOCK_ADD_HISTORY_ACTION: {
      state.blockAddHistoryAction = action.payload.block
      break
    }
    case ActionTypes.ACTIONS_ADD_HISTORY_ACTION: {
      const { historyAction, shiftIndex, ignoreActionIndex } = action.payload
      if (ignoreActionIndex) {
        state.actions.splice(state.actions.length + shiftIndex, 0, historyAction)
      } else {
        if (!isPointerAtLastAction) {
          state.actions.splice(state.currentActionIndex + 1)
        }
        state.actions.splice(state.actions.length + shiftIndex, 0, historyAction)
        state.currentActionIndex = state.actions.length - 1
      }
      break
    }
    case ActionTypes.ACTIONS_CLEAR_ACTIONS_HISTORY: {
      Object.assign(state, getInitialState())
      break
    }
    case ActionTypes.ACTIONS_PATCH_HISTORY_ACTION: {
      const currentAction = state.actions[state.currentActionIndex]
      if (currentAction) {
        state.actions[state.currentActionIndex] = Object.assign(currentAction,
          { ...payload, modifTime: Date.now() })
      }
      break
    }
    case ActionTypes.ACTIONS_CLEAR_IGNORE_HISTORY: {
      clearIgnoreLatestActionInHistory()
      break
    }
    case ActionTypes.ACTIONS_HISTORY_ERROR: {
      state.error = action.payload
      break
    }
    default: {
      if (state.isLatestActionIsHistory) {
        state.ignoreLatestActionInHistory = true
      }
      state.isLatestActionIsHistory = false
      break
    }
  }

  function clearIgnoreLatestActionInHistory() {
    if (state.ignoreLatestActionInHistory) {
      state.ignoreLatestActionInHistory = false
    }
  }
}

export default produce(actionsHistory, getInitialState())
