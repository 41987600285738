import React, { useCallback, useContext, useState } from 'react'
import TransitionsSwitch from './TransitionsSwitch'
import TransitionDurationField from '../../FormFields/TransitionDurationField'
import Form from '../../BaseAssetSettingsForm'
import { TranslationContext } from '~/contexts/TranslationContext'

export default function TransitionAssetSettingsForm(props) {
  const {
    /** @type {Assets.TransitionAsset} */
    asset,
    onChange,
    ...rest
  } = props

  // TODO: implement applyToAll logic for transitions. Probably
  // it will require to move applyToAll to global store
  const [ applyToAll, setApplyToAll ] = useState(false)
  const onChangeApplyToAll = useCallback(
    e => {
      setApplyToAll(e.target.checked)
    },
    []
  )

  const { t } = useContext(TranslationContext)

  const {
    onChangeDuration,
  } = useTransitionAssetFormHandlers(asset, onChange, applyToAll)

  return (
    <Form
      {...rest}
      asset={asset}
      title={t('TRANSITION_FORM_TITLE_SETTINGS_TRANSITION')}
      description={asset.name}
    >
      <TransitionsSwitch checked={applyToAll} onChange={onChangeApplyToAll} />
      <TransitionDurationField label={t('TRANSITION_FORM_LABEL_TRANSITION_DURATION')} value={asset.duration} onChange={onChangeDuration} />
    </Form>
  )
}

function useTransitionAssetFormHandlers(asset, update, applyToAll) {
  return {
    onChangeDuration: useCallback(duration => update({ duration, applyToAll }),
      [ update, applyToAll ]),
  }
}
