import * as ActionTypes from '~/actions/ActionTypes'
import * as Selectors from '~/selectors'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'
import { RESTORED_ASSET_PROPERTIES } from '~/config/constants/asset'
import { closeEditingFormOfDeletion } from '~/helpers/actions/closeEditingFormOfDeletion'

export const undoCreateTransition = (transition,
  savedParams = []) => async (dispatch, getState) => {
  const restoredAssetIds = savedParams.map(asset => asset.id)
  const currentAssets = Selectors.selectAssetById(getState(), restoredAssetIds)
  const newAssets = currentAssets.map(asset => {
    const newAsset = cloneInstanceClass(asset)
    const prevFindAsset = savedParams.find(
      savedAssetParams => savedAssetParams.id === newAsset.id
    )
    RESTORED_ASSET_PROPERTIES.forEach(prop => {
      newAsset[prop] = prevFindAsset[prop]
    })
    return newAsset
  })
  closeEditingFormOfDeletion(dispatch, getState(), [ transition ])
  dispatch({
    type: ActionTypes.UNDO_CREATE_TRANSITION,
    payload: { undoTransition: transition, newAssets },
  })
}

export const undoDeleteTransition = (transition,
  restoredAssets = []) => async (dispatch, _) => {
  dispatch({
    type: ActionTypes.UNDO_DELETE_TRANSITION,
    payload: { transition, restoredAssets },
  })
}
