/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import NotFoundMediaImage from '~/assets/projects/not_found_media.png'
import { NotFoundError } from '~/errors/NotFoundError'
import ProjectAsset from '~/models/ProjectData/ProjectAsset'
import ProjectTextAsset from '~/models/ProjectData/ProjectTextAsset'
import ProjectTransitionAsset from '~/models/ProjectData/ProjectTransitionAsset'
import * as API from '~/ServerAPI'
import { fetchAudioFile } from '~/services/AudioLibraryService'
import { SOURCE_FILE_TYPES } from '~/enums'
import ProjectVideoAsset from '~/models/ProjectData/ProjectVideoAsset'

export const prepareProjectAsset = async asset => {
  const { fileId } = asset
  const handleDeletedMedia = errorMessage => {
    asset.thumbnail = NotFoundMediaImage
    asset.mediaDeleted = true
    asset.errorMessage = errorMessage
    asset.sourceFileType = SOURCE_FILE_TYPES.MEDIA
  }

  if (!(asset instanceof ProjectTextAsset || asset instanceof ProjectTransitionAsset)
    && asset instanceof ProjectAsset) {
    if (fileId) {
      try {
        const { type } = asset
        const getMedia = asset.sourceFileType === SOURCE_FILE_TYPES.AUDIO
          ? fetchAudioFile
          : API.getMediaFile
        const { thumbnail, previewUrl } = (await getMedia(
          fileId, type, asset.sourceFileType
        )).data
        const { mediaStart } = asset
        if (!(asset instanceof ProjectVideoAsset) || !mediaStart) {
          // eslint-disable-next-line no-param-reassign
          asset.thumbnail = thumbnail // Isn't saved in project json
        }
        if (previewUrl) {
          asset.url = previewUrl
        } else {
          handleDeletedMedia('No video url')
        }
      } catch (e) {
        if (e instanceof NotFoundError) {
          handleDeletedMedia(e.message)
        } else {
          console.error(e)
        }
      }
    } else {
      handleDeletedMedia('No file')
    }
  }
  return asset
}
