import classNames from 'classnames'
import { SolveigStyledLogo } from 'components/SolveigStyledLogo/SolveigStyledLogo'
import React from 'react'
import { useSelector } from 'react-redux'
import '../base.scss'
import { Link } from 'react-router-dom'

function TitleComponent({ children }) {
  const { svgLogo, hideSVGLogo } = useSelector(state => state.user)
  const logoUrl = svgLogo || __CFG__.LOGO_PATH
  const isStudioHost = window.location.host === __CFG__.STUDIO_HOST

  return (
    <div className={classNames('header__logo', { 'header__logo--hidden': !__CFG__.HEADER.TITLE_VISIBLE })}>
      <If condition={!!logoUrl && logoUrl !== 'none' && hideSVGLogo !== true}>
        <If condition={!!__CFG__.LOGO_LINK && isStudioHost}>
          <Link to={{ pathname: __CFG__.LOGO_LINK }} className="header__link" target="_blank">
            <SolveigStyledLogo url={logoUrl} imgTag={!svgLogo} />
          </Link>
        </If>
        <If condition={!__CFG__.LOGO_LINK || !isStudioHost}>
          <SolveigStyledLogo style={{ height: '100%' }} url={logoUrl} imgTag={!svgLogo} />
        </If>
      </If>
      {children}
    </div>
  )
}

export default TitleComponent
