import { isOverlapAssets, isTimelineItemsIntersected } from '~/Util/RangeTools'

export const getIntersectionData = ({
  staticAssets,
  dragItem,
  layers,
  draggableOffset,
  offsetLayerIndex,
}) => staticAssets.reduce((sum, staticAsset) => {
  let result = { ...sum }
  const draggableAssets = dragItem.selectedAssets ?? []
  const dragFirstItem = draggableAssets[0]
  const dragLastItem = draggableAssets[draggableAssets.length - 1]
  const draggableBlockDuration = dragLastItem?.startTime
    + dragLastItem?.duration - dragFirstItem?.startTime

  // If first draggable asset startTime less then 0, define offset shifting to zero
  const offsetShiftingToZero = draggableAssets?.[0]?.startTime + draggableOffset < 0
    ? (draggableAssets?.[0]?.startTime + draggableOffset) * -1
    : 0

  draggableAssets.forEach(selectedAsset => {
    // OffsetLayerIndex - moving the dragged block up or down
    const dragItemLayerId = layers[selectedAsset.layerIndex + offsetLayerIndex]?.id
    const dragItemStartTime = selectedAsset.startTime + draggableOffset + offsetShiftingToZero
    const dragItemEndTime = dragItemStartTime + selectedAsset.duration
    const dragFirstItemStartTime = dragFirstItem?.startTime + draggableOffset + offsetShiftingToZero
    const dragLastItemEndTime = dragLastItem?.startTime
      + dragLastItem?.duration + draggableOffset + offsetShiftingToZero

    // Current layer of draggable block
    const dragRowAssets = draggableAssets
      .filter(da => da.layerId === dragItemLayerId)
    // Current layer EndTime
    const dragRowEndTime = (dragRowAssets[dragRowAssets.length - 1]?.startTime ?? 0)
      + (dragRowAssets[dragRowAssets.length - 1]?.duration ?? 0)
    const dragRowStartTime = dragRowAssets[0]?.startTime ?? 0
    const dragRowDuration = dragRowStartTime + dragRowEndTime

    const staticStartTime = staticAsset.startTime
    const staticEndTime = staticAsset.startTime + staticAsset.duration
    const staticDuration = staticAsset.duration

    const isIntersected = (isTimelineItemsIntersected(
      staticStartTime, staticEndTime, dragItemStartTime, dragItemEndTime
    ) && (dragItemLayerId === staticAsset.layerId))

    const isOverlapped = isOverlapAssets(
      staticStartTime, staticEndTime, dragItemStartTime, dragItemEndTime
    ) && (dragItemLayerId === staticAsset.layerId)

    // The dragged block may have jagged edges.
    // The distance between the start of the dragged block and the start of the current dragged element in the block
    const leftInsideBlockItemOffset = dragFirstItem.startTime + draggableOffset
      + offsetShiftingToZero - dragItemStartTime
    // The distance between the end of the dragged block and the end of the current dragged element in the block
    const rightInsideBlockItemOffset = dragLastItem.startTime + dragLastItem.duration
      + draggableOffset + offsetShiftingToZero - dragItemEndTime

    const data = {
      staticAsset,
      draggableAsset: selectedAsset,
      staticEndTime,
      staticStartTime,
      staticDuration,
      dragItemStartTime,
      dragItemEndTime,
      dragFirstItemStartTime,
      dragLastItemEndTime,
      draggableBlockDuration,
      draggableOffset,
      dragRowDuration,
      leftInsideBlockItemOffset,
      rightInsideBlockItemOffset,
      dragRowEndTime,
      dragRowStartTime,
    }

    if (isIntersected && !result.isIntersected) {
      result = {
        ...result,
        isIntersected,
        intersectedData: { ...data },
      }
    }

    if (isOverlapped && !result.isOverlapped) {
      result = {
        ...result,
        isOverlapped,
        overlappedData: { ...data },
      }
    }
  })
  return result
}, {
  isOverlapped: false,
  isIntersected: false,
  intersectedData: null,
  overlappedData: null,
})
