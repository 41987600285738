import cx from 'classnames'
import { IMAGES_SOURCES } from 'enums'
import { useInterval } from 'hooks/useInterval'
import React, { useRef, useState } from 'react'
import { createSelectable } from 'react-selectable-fast-control'
import { normalizeThumbnail } from '~/Util'
import PexelsIcon from '~/assets/icons/pexels.svg'
import UnsplashIcon from '~/assets/icons/unsplash.svg'
import BaseListItem from '../BaseAssetsListItem'
import MediaControls from '../Media/MediaControls'
import './ListItem.scss'

function ImageAssetsListItem(props) {
  const {
    /** @type {Assets.ImageAsset} */
    asset,
    ...rest
  } = props

  const imgRef = useRef()

  const [ isLoaded, setIsLoaded ] = useState(false)

  const { cancel: stopSkeleton } = useInterval(() => {
    const imgNaturalHeight = imgRef?.current?.naturalHeight
    const imgComplete = imgRef?.current?.complete
    if (imgComplete && imgNaturalHeight) {
      setIsLoaded(true)
      stopSkeleton()
    }
  }, 500)

  return (
    <BaseListItem
      {...rest}
      className={cx('media-file', { 'media-file__prevent-dragging': false })}
      asset={asset}
      // onClickContextMenu={handleClickContextMenu}
    >
      <div
        className={cx('media-file__img', { 'img-skeleton': !isLoaded })}
      >
        {/* do not show thumbnail if error happened */}
        <If condition={asset.thumbnail && asset.error === undefined}>
          <img
            style={{ opacity: isLoaded ? 1 : 0 }}
            ref={imgRef}
            src={normalizeThumbnail(asset.thumbnail)}
            className="thumbnail"
            alt={asset.name}
          />
        </If>
        <MediaControls
          item={asset}
          useRemove={false}
          useMenu={false}
        />
        <a className="author-link" target="_blank" rel="noopener noreferrer" href={asset.author.url}>
          <span className="author-link_icon">
            {asset.id.includes(IMAGES_SOURCES.Pexels)
              && <img src={PexelsIcon} width={24} alt={IMAGES_SOURCES.Pexels} />}
            {asset.id.includes(IMAGES_SOURCES.Unsplash)
              && <img src={UnsplashIcon} width={20} alt={IMAGES_SOURCES.Unsplash} />}
          </span>
          <span className="author-link_name">{asset.author.name}</span>
        </a>
      </div>
    </BaseListItem>
  )
}

export default createSelectable(ImageAssetsListItem)
