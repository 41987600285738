export const ERROR_TYPE = {
  // these parsed from backend errors
  USED_IN_PROJECTS: 'used_in_projects',
  FOLDER_NOT_EMPTY: 'folder_not_empty',
  NOT_FOUND: 'not_found',
  PROJECT_IN_USE: 'PROJECT_IN_USE',
  // these created in frontend
  PROJECT_LOAD_FAILED: 'PROJECT_LOAD_FAILED',
  PROJECT_SIZE_LIMIT_EXCEEDED: 'PROJECT_SIZE_LIMIT_EXCEEDED',
}
