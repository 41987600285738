import { useCallback } from 'react'
import { useCompose, useLazyComposeConst } from '~/hooks'

const patchToSettings = patch => ({ settings: patch })

export default function useTextAssetFormHandlers(asset, update) {
  const updateSettings = useCompose(update, patchToSettings)
  const useHandler = useLazyComposeConst(updateSettings)
  return {
    onTextChanged: useHandler(text => ({
      text,
    })),

    onTextTransformChanged: useHandler(textTransform => ({
      textTransform,
    })),

    onColorChanged: useHandler(({ rgb }) => ({
      color: rgb,
    })),
    onBackgroundChanged: useHandler(({ rgb }) => ({
      background: rgb,
    })),

    onFontChanged: useHandler(e => ({
      font: e.target.value,
    })),
    onFontSizeChanged: useHandler(fontSize => ({
      fontSize,
    })),

    onChangeOutlineColor: useHandler(({ rgb }) => ({
      outlineColor: rgb,
    })),
    onChangeOutlineWidth: useHandler(x => ({
      outlineWidth: x,
    })),

    onChangeShadowColor: useHandler(({ rgb }) => ({
      shadowColor: rgb,
    })),
    onChangeShadowBlur: useHandler(x => ({
      shadowBlur: x,
    })),

    onChangeTextAlign: useHandler(e => ({
      align: e.target.value,
    })),

    onFontStyleChanged: useCallback(
      style => {
        const fontStyle = { ...asset.settings.fontStyle, ...style }
        updateSettings({ fontStyle })
      },
      [ asset.settings, updateSettings ]
    ),

    onChangePosition: useHandler(
      (offset, position) => {
        const patch = { offset }
        if (position !== undefined) {
          patch.position = position
        }
        return patch
      }
    ),

    onToggleOutline: useCallback(() => {
      const { disableOutline } = asset.settings
      updateSettings({ disableOutline: !disableOutline })
    }, [ asset.settings, updateSettings ]),

    onToggleShadow: useCallback(() => {
      const { disableShadow } = asset.settings
      updateSettings({ disableShadow: !disableShadow })
    }, [ asset.settings, updateSettings ]),

    onChangeOffsetX: useCallback(e => {
      const { shadowOffset } = asset.settings
      const number = offsetClamp(e.target.value)
      updateSettings({ shadowOffset: { ...shadowOffset, x: number } })
    }, [ asset.settings, updateSettings ]),

    onChangeOffsetY: useCallback(e => {
      const { shadowOffset } = asset.settings
      const number = offsetClamp(e.target.value)
      updateSettings({ shadowOffset: { ...shadowOffset, y: number } })
    }, [ asset.settings, updateSettings ]),
  }
}

function offsetClamp(string) {
  let number = parseFloat(string)
  if (isNaN(number)) {
    return 0
  }
  if (number >= 120) { number = 120 }
  if (number <= 0) { number = 0 }
  return number
}
