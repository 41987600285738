import { loadProjectTransitionAsset } from '~/actions/timeline'
import { getObjectFields } from '~/Util'
import { getAssetFromData } from './mappings/getAssetMapping'

export const addTransitionToTimeline = (originalAsset,
  layerId, pregeneratedId) => async dispatch => {
  const asset = getAssetFromData(getObjectFields(originalAsset))
  const { id } = asset
  const { leftVideoAssetId, rightVideoAssetId } = asset
  await dispatch(loadProjectTransitionAsset(asset,
    leftVideoAssetId,
    rightVideoAssetId,
    {
      pregeneratedId: pregeneratedId || id,
      layerId,
    }))
}
