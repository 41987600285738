import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CustomizationHotkeys } from 'views/CustomizationHotkeys/CustomizationHotkeys'
import * as PT from '~/PropTypes'
import * as Actions from '~/actions'
import Scrollbars from '~/components/base/Scrollbars'
import { useAction } from '~/hooks'
import * as Selectors from '~/selectors'

import { CustomizationView } from '~/views/CustomizationView/CustomizationView'
import './BaseAssetsPanel.scss'
import CustomDragLayer from './CustomDragLayer'
import Voiceover from './Voiceover'
import Webcam from './Webcam'
// ---

function getEditingAsset(state, type) {
  const panelState = Selectors.getSourceFilesGroup(state, type)
  const { editingAssetBackup } = panelState

  if (editingAssetBackup === null) {
    return null
  }
  const asset = Selectors.getAssets(state).find(x => x.id === editingAssetBackup.id)

  if (asset === undefined) {
    throw new Error(`Can't find asset#${editingAssetBackup.id}, although backup for it exists.`)
  }

  return asset
}

// ---

export default function BaseAssetsPanel(props) {
  const {
    type,
    SettingsComponent, ListComponent,
  } = props

  // ---

  const asset = useSelector(state => getEditingAsset(state, type))

  const mediaRecordingOpened = useSelector(Selectors.recording.selectMediaRecordingSettingsState)

  const mediaRecordingClose = useAction(Actions.mainView.closeMediaRecordingSettings)
  const customizationView = useSelector(state => state.mainView.showCustomizeView)

  const onUpdateAsset = useAction(Actions.layer.updateEditingAsset, type)
  const {
    items,
    allFilesFetched,
    totalCount,
  } = useSelector(state => Selectors.getSourceFilesGroup(state, type))
  // ---

  // TODO: pass request params somehow
  const fetchFiles = useAction(Actions.sourceFiles.loadSourceFiles, type)
  React.useEffect(
    () => {
      if (!allFilesFetched && items.length === 0) {
        fetchFiles()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ fetchFiles, allFilesFetched ]
  )

  const onScroll = useCallback(
    values => {
      // when we reach end of the scroll area, load next portion of files
      // we can use onUpdate of Scrollbars if we need to start loading
      // earlier, before we reach the bottom
      if (values.top >= 0.9 && (!allFilesFetched || !totalCount)) {
        fetchFiles({ start: items.length, count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ allFilesFetched, fetchFiles, items.length ]
  )
  // ---

  return (
    <Scrollbars className="vertical-scrollbar">
      <Choose>
        <When condition={asset !== null && !mediaRecordingOpened}>
          <SettingsComponent
            type={type}
            asset={asset}
            onChange={onUpdateAsset}
            // ensure form is re-created and it's internal state, if any, is resetted
            key={asset.id}
          />
        </When>
        <When condition={mediaRecordingOpened === 'audio'}>
          <Voiceover onClose={mediaRecordingClose} />
        </When>
        <When condition={mediaRecordingOpened === 'video'}>
          <Webcam onClose={mediaRecordingClose} />
        </When>
        <When condition={customizationView === 'theme'}>
          <CustomizationView />
        </When>
        <When condition={customizationView === 'hotkeys'}>
          <CustomizationHotkeys />
        </When>
        <Otherwise>
          <CustomDragLayer />
          <ListComponent onScroll={onScroll} type={type} />
        </Otherwise>
      </Choose>

    </Scrollbars>
  )
}

BaseAssetsPanel.propTypes = {
  type: PT.SourceFileType.isRequired,
  SettingsComponent: PropTypes.elementType.isRequired,
  ListComponent: PropTypes.elementType.isRequired,
}
