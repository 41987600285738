import Cookies from 'universal-cookie'
import { defaultPlayerVolume } from '~/constant'

const cookies = new Cookies()
const PLAYER_VOLUME = 'playerVolume'

export function set(playerVolume) {
  const currentValue = get()
  if (currentValue !== playerVolume) {
    cookies.set(PLAYER_VOLUME, playerVolume, {
      path: '/',
      expires: new Date(86_400_000 + +new Date()),
    })
  }
}

export function get() {
  return Number(cookies.get(PLAYER_VOLUME) || defaultPlayerVolume)
}
