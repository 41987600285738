import { addAssetToTimeline } from '~/actions/projectData/addAssetToTimeline'
import { deleteAssets, deleteLayer, insertLayer } from '~/actions/timeline'
import { shiftItemsByAssetOnDrop } from '~/actions/layer'
import TimelineLayer from '../Layer'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'


export class AssetCreationAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.AssetCreationAction, payload, ...params)
    const { isNewLayer, newLayer, newLayerIndex, deltaOnDrop } = payload
    this.isNewLayer = isNewLayer
    this._deltaOnDrop = deltaOnDrop
    if (isNewLayer) {
      this.newLayer = newLayer instanceof TimelineLayer ? newLayer : new TimelineLayer(newLayer)
      this.newLayerIndex = newLayerIndex
    }
  }

  get isNewLayer() {
    return this._isNewLayer
  }

  set isNewLayer(v) {
    this._isNewLayer = v
  }

  get newLayer() {
    return this._newLayer
  }

  set newLayer(v) {
    this._newLayer = v
  }

  get newLayerIndex() {
    return this._newLayerIndex
  }

  set newLayerIndex(v) {
    this._newLayerIndex = v
  }

  get deltaOnDrop() {
    return this._deltaOnDrop
  }

  set deltaOnDrop(v) {
    this._deltaOnDrop = v
  }

  * undo() {
    const { asset, isNewLayer, newLayer, deltaOnDrop } = this
    // yield shiftItemsByAsset(asset, delta * -1)
    if (deltaOnDrop) {
      yield shiftItemsByAssetOnDrop({ id: asset.id,
        layerId: asset.layerId,
        time: asset.startTime,
        delta: -1 * deltaOnDrop })
    }

    yield deleteAssets([ asset ])
    if (isNewLayer) {
      yield deleteLayer(newLayer.id)
    }
  }

  * redo() {
    const { asset, isNewLayer, newLayerIndex, newLayer, deltaOnDrop } = this

    if (isNewLayer) {
      yield insertLayer(newLayerIndex, newLayer)
    }

    if (deltaOnDrop) {
      yield shiftItemsByAssetOnDrop({ id: asset.id,
        layerId: asset.layerId,
        time: asset.startTime,
        delta: deltaOnDrop })
    }

    yield addAssetToTimeline(this.asset, this.asset.layerId)
  }

}
