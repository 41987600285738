import React from 'react'

import Checkbox, { CustomCheckboxProps } from 'components/base/Checkbox'
import UncheckedIcon from 'components/base/Checkbox/UnchekedIcon'
import CheckedIcon from 'components/base/Checkbox/CheckedIcon'
import styles from './checkbox.module.scss'

function CustomCheckbox<T extends boolean | undefined = undefined>(props: CustomCheckboxProps<T>) {
  return (
    <Checkbox<T>
      {...props}
      className={styles.volumeCheckbox}
      checkedIcon={<CheckedIcon />}
      icon={<UncheckedIcon stroke="#A2A2A2" />}
      disableRipple
    />
  )
}

export default CustomCheckbox
