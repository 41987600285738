import { isNil } from 'lodash'
import { DIRECTION } from '~/enums'
import { time2Pixel } from '~/Util'

// Сalculation of the X coordinates of the current dragged asset (under the mouse pointer)
export const setDropX = ({
  historyX,
  draggableBlockStartTime,
  leftXOffset,
  currentDraggableAsset,
  draggbleAssets,
  intersectionPlaceholder,
  scale,
  sourceOffset,
  isIntersected,
  isOverlapped,
  overNewLayerIndex,
  intersectionEqualSegment,
  diffBetweenCurrentDragItemNFirstDragItem,
}) => {
  let { dropX, intersectedData } = historyX.current
  const deltaBetweenAssets = currentDraggableAsset?.startTime
  - draggbleAssets?.[0]?.startTime

  if (draggableBlockStartTime <= 0
    && (!isOverlapped || (intersectionPlaceholder && isNil(overNewLayerIndex)))) {
    dropX = time2Pixel(deltaBetweenAssets, scale)
  } else if (intersectionPlaceholder && isNil(overNewLayerIndex)) {
    dropX = time2Pixel((intersectionPlaceholder?.startTime), scale)
  } else if (intersectionEqualSegment && historyX.current.readyForDrop) {
    dropX = time2Pixel((intersectionEqualSegment?.startTime), scale)
  } else if (historyX.current.readyForDrop) {
    dropX = historyX.current.leftSideX - leftXOffset
      + time2Pixel(diffBetweenCurrentDragItemNFirstDragItem, scale)
  } else if (isIntersected && isNil(overNewLayerIndex)) {
    if (historyX.current.moveDirection === DIRECTION.LEFT) {
      const delta = currentDraggableAsset.startTime
        - (intersectedData?.draggableAsset.startTime ?? 0)
      const stopTime = (intersectedData?.staticAsset.startTime ?? 0)
        + (intersectedData?.staticAsset.duration ?? 0)
      dropX = time2Pixel((stopTime + delta), scale)
    } else {
      const delta = currentDraggableAsset.startTime
        - (intersectedData?.draggableAsset.startTime ?? 0)
        - (intersectedData?.draggableAsset.duration ?? 0)
      const stopTime = (intersectedData?.staticAsset.startTime ?? 0)
      dropX = time2Pixel((stopTime + delta), scale)
    }
  } else {
    dropX = sourceOffset.x - leftXOffset
  }
  return { dropX }
}
