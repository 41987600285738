import React, { HTMLAttributes, useContext } from 'react'
import { useSelector } from 'react-redux'
import ReactDOM from 'react-dom'

import MediaItem from 'components/AssetPanels/Media/ListItem'
import TextItem from 'components/AssetPanels/Text/ListItem'
import TransitionItem from 'components/AssetPanels/Transition/ListItem'
import { DRAGNDROP_TYPE } from 'enums'
import DraggingGroup from 'components/AssetPanels/components/DraggingGroup'
import * as Selectors from 'selectors'
import { CustomDragLayerContext } from 'components/CustomDragLayerContext'

const layerStyles: HTMLAttributes<HTMLDivElement>['style'] = {
  position: 'absolute',
  pointerEvents: 'none',
  zIndex: 2,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const CustomDragLayer = () => {
  const dndDropTarget = useSelector((state: RootState) => state.mainView.dndDropTarget)

  const { isDragging, clientOffset, item, itemType } = useContext(CustomDragLayerContext)
  if (!isDragging || clientOffset?.x === undefined) {
    return null
  }

  const draggingItem = itemType === DRAGNDROP_TYPE.GROUP ? item.selectedItems.length : item
  const hide = dndDropTarget !== 'root'

  const getItem = () => {
    switch (itemType) {
      case DRAGNDROP_TYPE.GROUP:
        return (
          <DraggingGroup
            item={item.selectedItems.length}
            clientOffset={clientOffset}
          />
        )
      case DRAGNDROP_TYPE.MEDIA_ITEM:
      case DRAGNDROP_TYPE.FOLDER:
      case DRAGNDROP_TYPE.TEXT_ITEM:
      case DRAGNDROP_TYPE.AUDIO_ITEM:
      case DRAGNDROP_TYPE.IMAGE_ITEM:
      case DRAGNDROP_TYPE.TRANSITION_ITEM: {
        return (
          <div style={{ transform: `translate(${clientOffset?.x - 0.5 * 184}px, ${clientOffset?.y - 0.5 * 112}px)` }}>
            <MediaDraggingItem item={item} itemType={itemType} />
          </div>
        ) }
      default: return null
    }
  }

  // If AssetsList context or smth is needed
  //
  // const test = dndDropTarget === 'timeline' && itemType === 'transition-item'
  // if (test) {
  //   const container = getContainer()
  //   if (container) {
  //     return ReactDOM.createPortal(<MediaDraggingItem item={item} itemType={itemType} />, container)
  //   }
  //   return null
  // }

  return (
    <>
      {draggingItem ? ReactDOM.createPortal(
        <div style={{ ...layerStyles,
          display: hide ? 'none' : 'block' }}
        >
          {getItem()}
        </div>, document.body
      ) : null}

    </>
  )
}

function MediaDraggingItem({ item, itemType }: { item: { id: string }, itemType: string }) {
  const draggingFile = useSelector(state => Selectors.getSourceFileById(state, item.id))

  const getItem = () => {
    switch (itemType) {
      case DRAGNDROP_TYPE.MEDIA_ITEM:
      case DRAGNDROP_TYPE.FOLDER:
        return <MediaItem asset={draggingFile} asDragPreview />
      case DRAGNDROP_TYPE.TEXT_ITEM:
        return <TextItem asset={draggingFile} asDragPreview />

      case DRAGNDROP_TYPE.AUDIO_ITEM:
        return <MediaItem asset={draggingFile} asDragPreview />

      case DRAGNDROP_TYPE.IMAGE_ITEM:
        return <MediaItem asset={draggingFile} asDragPreview />

      case DRAGNDROP_TYPE.TRANSITION_ITEM:
        return <TransitionItem asset={draggingFile} asDragPreview />

      default: return null
    }
  }

  return getItem()
}

export default CustomDragLayer
