import { isFSA } from 'flux-standard-action'
import reducers from 'reducers'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { rootSaga } from '~/sagas/rootSaga'

// @link https://github.com/zalmoxisus/redux-devtools-extension#13-use-redux-devtools-extension-package-from-npm
const composeEnhancers = composeWithDevTools({
  // @link https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#windowdevtoolsextensionconfig
  trace: true,
})

const requireFSA = () => next => action => {
  if (!isFSA(action)) {
    const error = 'An action must not include properties other than `type`, `payload`, `error`, and `meta`'
    // eslint-disable-next-line no-console
    console.error(`${error}\nGot this:`, action)
    throw new Error(error)
  }
  return next(action)
}

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk, requireFSA, sagaMiddleware))
)
sagaMiddleware.run(rootSaga)


export default store
