/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import React from 'react'
import './SettingsButton.scss'

export const SettingsButton = ({ children, icon, className, isActive = false, ...rest }) => (
  <button
    className={classNames('settings-button', className, { 'settings-button__active': isActive })}
    type="button"
    {...rest}
  >
    <span className="settings-button__text">{children}</span>
    <span className={classNames('settings-button__icon', { 'settings-button__icon__active': isActive })}>{icon}</span>
  </button>
)
