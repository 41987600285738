import { pickBy } from 'lodash'

export const PLAYBACK_STATE = {
  PLAY: 1,
  PAUSE: 2,
  STOP: 3,
}

export const DRAGNDROP_TYPE = {
  MEDIA_ITEM: 'media-item',
  IMAGE_ITEM: 'image-item',
  LAYER_ASSET: 'layer-asset',
  TEXT_ITEM: 'text-item',
  AUDIO_ITEM: 'audio-item',
  TRANSITION_ITEM: 'transition-item',
  LAYER_TRANSITION_ASSET: 'transition-asset',
  LAYER: 'layer',
  FOLDER: 'folder',
  GROUP: 'group',
} as const

export const TRANSITIONS = {
  DISSOLVE: 'dissolve',
  FADEIN: 'fadein',
  FADEOUT: 'fadeout',
}

export const TEXTS = {
  DEFAULT_TEXT: 'text',
}

export const MENU_ITEMS = {
  MEDIA: 'Media',
  TRANSITIONS: 'Transitions',
  TEXT: 'Text',
  AUDIO: 'Audio',
  FILTERS: 'Filters',
  DESIGN: 'Design',
  IMAGES: 'Images',
  VIDEOS: 'Videos',
} as const

export const TASK_STATUS = {
  NEW: 'new',
  RUNNING: 'running',
  COMPLETED: 'completed',
  DOWNLOADING: 'downloading',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
}

export const SOURCE_FILE_TYPES = {
  MEDIA: 'Media', // collection of any user's files: video/audio/image
  TRANSITIONS: 'Transitions',
  TEXT: 'Text',
  AUDIO: 'Audio',
  FILTERS: 'Filters',
  DESIGN: 'Design',
  IMAGES: 'Images',
} as const

export const MEDIA_FILE_FILTERS = {
  All: 'all',
  Video: 'video',
  Image: 'image',
  Audio: __APP_PROFILE__ === 'vrspot' ? 'audio' : 'audio,voiceover',
  Folder: 'folder',
  Voiceover: 'voiceover',
} as const

const getFilterValue = (vrspotValue: string, nonVrspotValue: string = vrspotValue) => __APP_PROFILE__ === 'vrspot'
  ? vrspotValue
  : nonVrspotValue

export const MEDIA_FILE_FILTERS_ORDERED: Partial<Record<keyof typeof MEDIA_FILE_FILTERS, string | boolean>> = pickBy({
  All: __CFG__.SOURCE_FILES_FILTERS.ALL && getFilterValue('All'),
  Video: __CFG__.SOURCE_FILES_FILTERS.VIDEO && getFilterValue('Videos', 'Video'),
  Image: __CFG__.SOURCE_FILES_FILTERS.IMAGES && getFilterValue('Images', 'Image'),
  Audio: __CFG__.SOURCE_FILES_FILTERS.AUDIO && getFilterValue('Audio Files', 'Audio'),
  Voiceover: __CFG__.SOURCE_FILES_FILTERS.MEDIA_RECORDING.VOICEOVER
    && __APP_PROFILE__ !== 'vrspot' && getFilterValue('Voiceover'),
  Folder: __CFG__.SOURCE_FILES_FILTERS.FOLDERS && getFilterValue('Folders', 'Folder'),
}, Boolean)

export const MEDIA_FILES_VIEW = {
  List: 'List',
  Icons: 'Icons',
}

export const MEDIA_FILES_ORDER = {
  Ascending: 'asc',
  Descending: 'desc',
} as const

export const MEDIA_FILES_SORT = {
  Name: 'name',
  Date: 'createTime',
  Size: 'size',
  Duration: 'duration',
} as const

export const TEXT_POSITION = {
  TOP_LEFT: 'topLeft',
  TOP: 'top',
  TOP_RIGHT: 'topRight',
  RIGHT: 'right',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottomLeft',
  LEFT: 'left',
  MIDDLE_CENTER: 'middleCenter',
}

export const TEXT_TRANSFORM = {
  NONE: 'none',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  CAPITALIZE: 'capitalize',
}

export const FILE_TYPE = {
  UPLOADED: 'uploaded',
  TEST: 'test',
  OUTPUT: 'output',
  URL: 'url',
  AWS: 'aws',
  DEVMEDIA: 'devmedia',
  JSONMEDIA: 'jsonmedia',
  VOICEOVER: 'voiceover',
  FOLDER: 'folder',
}

export const MEDIA_TYPE = {
  MP4: 'mp4',
  MOV: 'mov',
  MP3: 'mp3',
  PNG: 'png',
  M4A: 'm4a',
  JPG: 'jpg',
  MXF: 'mxf',
  HLS: 'm3u8',
  TS: 'ts',
  WEBM: 'webm',
  FOLDER: 'folder',
} as const

export const HOTKEY_CATEGORY = {
  All: 'ALL',
  Timeline: 'TIMELINE',
  Tools: 'TOOLS',
  Preview: 'PREVIEW',
}

export const HOTKEYS = {
  UNDO: 'TIMELINE_BTN_UNDO',
  REDO: 'TIMELINE_BTN_REDO',
  ZOOM_IN: 'TIMELINE_BTN_ZOOM_IN',
  ZOOM_OUT: 'TIMELINE_BTN_ZOOM_OUT',
  PREV_FRAME: 'PREVIEW_BTN_PREV_FRAME',
  NEXT_FRAME: 'PREVIEW_BTN_NEXT_FRAME',
  ADD_TO_TIMELINE: 'BTN_ADD_TO_TIMELINE',
  INCREASE_CLIP_VOLUME: 'INCREASE_CLIP_VOLUME',
  DECREASE_CLIP_VOLUME: 'DECREASE_CLIP_VOLUME',
  RIPPLE_DELETE: 'RIPPLE_DELETE',
  RIPPLE_TRIM_PREV: 'RIPPLE_TRIM_PREV',
  RIPPLE_TRIM_NEXT: 'RIPPLE_TRIM_NEXT',
  SELECT_CLIPS_AT_CURRENT_POSITION: 'SELECT_CLIPS_AT_CURRENT_POSITION',
  CUT_CLIPS: 'TIMELINE_BTN_CUT_CLIPS',
  PLAY_PAUSE: 'PREVIEW_BTN_PLAY_PAUSE',
  DELETE: 'BTN_DELETE',
  COPY: 'COPY',
  PASTE: 'PASTE',
}

export const HOTKEY_SETTINGS_LABELS = {
  [HOTKEYS.UNDO]: 'HOTKEY_SETTINGS_UNDO',
  [HOTKEYS.REDO]: 'HOTKEY_SETTINGS_REDO',
  [HOTKEYS.ZOOM_IN]: 'HOTKEY_SETTINGS_ZOOM_IN',
  [HOTKEYS.ZOOM_OUT]: 'HOTKEY_SETTINGS_ZOOM_OUT',
  [HOTKEYS.PREV_FRAME]: 'HOTKEY_SETTINGS_PREV_FRAME',
  [HOTKEYS.NEXT_FRAME]: 'HOTKEY_SETTINGS_NEXT_FRAME',
  [HOTKEYS.ADD_TO_TIMELINE]: 'HOTKEY_SETTINGS_ADD_TO_TIMELINE',
  [HOTKEYS.INCREASE_CLIP_VOLUME]: 'HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME',
  [HOTKEYS.DECREASE_CLIP_VOLUME]: 'HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME',
  [HOTKEYS.RIPPLE_DELETE]: 'HOTKEY_SETTINGS_RIPPLE_DELETE',
  [HOTKEYS.RIPPLE_TRIM_PREV]: 'HOTKEY_SETTINGS_RIPPLE_TRIM_PREV',
  [HOTKEYS.RIPPLE_TRIM_NEXT]: 'HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT',
  [HOTKEYS.SELECT_CLIPS_AT_CURRENT_POSITION]: 'HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION',
  [HOTKEYS.CUT_CLIPS]: 'HOTKEY_SETTINGS_CUT_CLIPS',
  [HOTKEYS.PLAY_PAUSE]: 'HOTKEY_SETTINGS_PLAY_PAUSE',
  [HOTKEYS.DELETE]: 'HOTKEY_SETTINGS_DELETE',
  [HOTKEYS.COPY]: 'HOTKEY_SETTINGS_COPY',
  [HOTKEYS.PASTE]: 'HOTKEY_SETTINGS_PASTE',
}

export const FILE_STATUS = {
  UPLOAD: 'upload',
  IMPORT: 'import',
  S3_UPLOAD: 's3_upload',
  MEDIAINFO: 'mediainfo',
  PREPARE_THUMBNAILS: 'prepare_thumbnails',
  READY: 'ready',
  RENDER: 'render',
  CONVERT: 'convert',
}

export const UPLOAD_ERROR = {
  BUILD_THUMBNAILS: 'build_thumbnails_error',
  UNSUPPORTED_MEDIA: 'unsupported_media',
  EXCEEDED_FILE_SIZE: 'exceeded_file_size',
  EXCEEDED_PER_USER_SIZE: 'exceeded_per_user_size',
  LOW_DISK_SPACE: 'low_disk_space',
  INTERNAL_SERVER_ERROR: 'internal_server_error',
  NETWORK_ERROR: 'network_error',
}

export const HISTORY_EVENT_TYPE = {
  UNDO: 'undo',
  REDO: 'redo',
}

export const MEDIA_RECORDING_TYPE = { Audio: 'audio', Video: 'video' } as const
export type TMEDIA_RECORDING_TYPE = typeof MEDIA_RECORDING_TYPE[keyof typeof MEDIA_RECORDING_TYPE]

export const PROJECT_TYPE = {
  edlZip: '.edl.zip',
  // edl: '.edl',
  // jsonZip: '.json.zip',
  // json: '.json',
  // xtl: '.xtl',
} as const

export const RENDER_BACKEND = {
  SOLVEIG: 'solveig',
  SHOTSTACK: 'shotstack',
}

export const IMAGES_SOURCES = {
  Native: 'json',
  Pexels: 'pexels',
  Unsplash: 'unsplash',
}

export const DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
}
