import cx from 'classnames'
import FileInput from 'components/base/FileInputLegacy/FileInput'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useLocalFileImport } from '~/hooks/useLocalFileImport'
import * as Selectors from '~/selectors'

export default function Uploader({ type }) {
  const items = useSelector(state => Selectors.getSourceFiles(state, type))

  const isFull = items.length === 0

  const {
    getRootProps,
    getInputProps,
  } = useLocalFileImport({ noDrag: true })

  const { t } = useContext(TranslationContext)

  const getInputTitle = () => {
    const text = t('MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA')
    return (
      <>
        <span>{text}</span>
        {' '}
        {t('MEDIA_UPLOADER_TEXT_OR')}
        {' '}
        <span
          className="import-anchor"
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps()}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          {t('MEDIA_UPLOADER_TEXT_IMPORT_MEDIA')}
        </span>
      </>
    )
  }

  return (
    <div className={cx('assets-list__uploader', {
      'assets-list__uploader--full': isFull,
      'assets-list__uploader--file': items.length > 0,
    })}
    >
      <FileInput title={getInputTitle()} />
    </div>
  )
}
