import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { changeLayerPosition as changeLayerPositionAction } from '~/actions/timeline'
import Layer from '~/components/Timeline/Layer'
import { TimelineTopOffsetContext } from '~/components/Timeline/TimelineTopOffsetContext'
import { useAction } from '~/hooks'
import Asset from '~/models/Asset'
import * as Selectors from '~/selectors'
import { getLayerIndexFromY } from '~/Util/timeline-coords'
import { Context as TimelineGeometryContext } from '../GeometryContextProvider'
import { TimelineScrollPositionContext } from '../ScrollPositionContext'
import { getItemStyles } from './getItemStyles'
import { LAYER_HEIGHT_WITH_BORDER } from '~/constant'

const BORDER_HEIGHT = 4

export const DraggingLayer = ({ item, clientOffset, sourceOffset }) => {
  const assets = useSelector(Selectors.getAssets)
  const layers = useSelector(Selectors.getLayers)
  const layerIndex = layers.findIndex(l => l.id === item.id)
  const layer = layers[layerIndex]

  const [ draggingLayerIndex, setDraggingLayerIndex ] = useState(layerIndex)

  const changeLayerPosition = useAction(changeLayerPositionAction, layer.id)

  const { y: clientY } = clientOffset
  const { timelineWidth } = React.useContext(TimelineGeometryContext)
  const { scrollTop } = React.useContext(TimelineScrollPositionContext)

  const timelineTopOffset = useContext(TimelineTopOffsetContext)
  const y = sourceOffset.y - timelineTopOffset
  // const topOffset = timelineTopOffset - scrollTop

  useEffect(() => {
    if (clientY) {
      const i = getLayerIndexFromY({
        y: clientY + scrollTop,
        topOffset: timelineTopOffset,
        layersLength: layers.length,
      })
      // eslint-disable-next-line no-param-reassign
      setDraggingLayerIndex(i)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ clientY ])

  useEffect(() => {
    if (draggingLayerIndex !== layerIndex && draggingLayerIndex !== layers.length - 1) {
      changeLayerPosition(draggingLayerIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ draggingLayerIndex ])

  return (
    <div style={getItemStyles({
      clientOffset,
      x: 0,
      // TODO: investigate how to fix sourceOffsetY
      // and initialClientOffsetY incompliance (by ~15)
      y: Math.min(Math.max(y - 15, 0),
        (layers.length - 2) * LAYER_HEIGHT_WITH_BORDER) + BORDER_HEIGHT,
      height: `${LAYER_HEIGHT_WITH_BORDER}px`,
      width: timelineWidth,
    })}
    >
      <Layer
        style={{ opacity: 0.7 }}
        layerIndex={layerIndex}
        assets={Asset.getLayerAssets(assets, layer.id)}
        layer={layer}
      />
    </div>
  )
}
