import { isArray, isEmpty } from 'lodash'
import { LAYER_HEIGHT_WITH_BORDER } from 'constant'

export const getDragElementsContainerHeight = ({ draggingAsset }) => {
  if (isArray(draggingAsset)) {
    const layerIds = []
    draggingAsset.forEach(asset => {
      if (!layerIds.includes(asset.layerId)) {
        layerIds.push(asset.layerId)
      }
    })
    return !isEmpty(layerIds)
      ? LAYER_HEIGHT_WITH_BORDER * layerIds.length
      : LAYER_HEIGHT_WITH_BORDER
  }

  return LAYER_HEIGHT_WITH_BORDER
}
