import { frontendVersion } from 'helpers/versionRoutines'
import { useEffect } from 'react'
import { setInstallVersion } from 'actions/app'
import { useAction } from '~/hooks'
import { getVersion } from '~/ServerAPI'
import versionInfo from './version.json'
import * as AuthToken from '~/cookieServices/AuthToken'

function formatConsoleVersion(data) {
  const { hash, date: isoDateStr } = data

  return `${frontendVersion()} | ${hash} | ${isoDateStr}`
}

const checkUrlAuthToken = ({ externalLoginUrl }) => {
  const params = new URLSearchParams(window.location.search)
  let authToken = params.get('auth_token') || params.get('session_id')
  if (!authToken) {
    authToken = AuthToken.get()
    const atSmartediting = window.location.host === __CFG__.SMARTEDITING_HOST
    if (!authToken) {
      if (externalLoginUrl && !atSmartediting) {
        // in case of externalLoginUrl redirect to login page if no token specified
        window.location.href = externalLoginUrl
      } else if (__CFG__.GENERATE_AUTH_TOKEN) {
        authToken = Date.now().toString()
      }
    }
  }
  if (authToken) {
    AuthToken.set(authToken)
    window.userId = authToken
  }
}


export default function AppVersion({ onSuccess }) {
  const setInstallVersionAction = useAction(setInstallVersion)
  useEffect(() => {
    async function showVersion() {
      try {
        const response = await getVersion()
        if (response && response.data) {
          const { installVer, version } = response.data
          if (installVer) {
            // eslint-disable-next-line no-console
            console.log(`Installation version ${installVer}`)
          }
          // eslint-disable-next-line no-console
          console.log(`Backend version ${version}`)
          // eslint-disable-next-line no-console
          console.log(`Frontend version ${formatConsoleVersion(versionInfo)}`)
          checkUrlAuthToken(response.data)
          setInstallVersionAction(response.data)
          onSuccess()
        }
      } catch (e) {
        console.error('Failed to fetch backend version\n', e)
      }
    }
    showVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])// Intentionally left dependencies array empty to run only once

  return null
}
