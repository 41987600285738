import * as ActionTypes from '~/actions/ActionTypes'

export const undoMultipleSplitted = (assets,
  assetIDsBeforeSplitting, transitionsBeforeSplitting) => async dispatch => {
  dispatch({
    type: ActionTypes.UNDO_MULTIPLE_SPLIT,
    payload: {
      assets,
      assetIDsBeforeSplitting,
      transitionsBeforeSplitting,
    },
  })
}
