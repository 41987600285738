import React from 'react'
import cx from 'classnames'

import { Stage } from 'components/Projects/components/Import/types'
import { bytesToSize } from 'Util'

import { CircularProgress } from 'components/base/CircularProgress/CircularProgress'
import { textOverflowHandler } from 'components/Projects/components/Import/utils'
import styles from './progress.module.scss'

type Props = {
  stage: Stage,
  progress: number,
  file: File,
  desc: string,
  action: {
    text: string,
    handler: () => void
  }
}

function Progress({ stage, progress, file, action, desc }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.name}>
          {textOverflowHandler(file.name, 40)}
        </div>
        <div className={styles.size}>{bytesToSize(file.size)}</div>
      </div>
      <CircularProgress
        text={stage === 'upload' ? progress : null}
        progress={progress}
        className={cx(styles.circular, {
          [styles.ready]: stage === 'ready',
          [styles.error]: stage === 'error',
        })}
        variant={stage === 'upload' || stage === 'ready' || stage === 'error' ? 'static' : 'indeterminate'}
        classes={{ svg: stage !== 'ready' ? styles.svg : '' }}
      />
      <div className={styles.stage}>
        {desc}
      </div>
      <div className={styles.action} onClick={action.handler}>{action.text}</div>
    </div>
  )
}

export default Progress
