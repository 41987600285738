/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react'

export type ColorClient = {
  clientId: ColorClientId;
  cb: (color: any) => void,
  params: any,
}
type Provider = (enabled: boolean) => void
type ColorClientId = string
type ProviderId = string

export class ColorProvider {

  private refreshProviders() {
    Object.values(this.colorProviders).forEach(cb => cb(Object.values(this.colorClients).length > 0))
  }

  private colorClients: {[key: ColorClientId]: ColorClient} = {}
  private colorProviders: {[key: ProviderId]: Provider} = {}


  registerColorClient(colorClient: ColorClient) {
    this.colorClients = { ...this.colorClients, [colorClient.clientId]: colorClient }
    this.refreshProviders()
  }

  unregisterColorClient(clientId: ColorClientId) {
    delete this.colorClients[clientId]
    this.refreshProviders()
  }


  registerColorProvider(id: ProviderId, cb: Provider) {
    this.colorProviders = { ...this.colorProviders, [id]: cb }
    this.refreshProviders()
  }

  unregisterColorProvider(id: ProviderId) {
    delete this.colorProviders[id]
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provideColor(color: any) {
    Object.values(this.colorClients).forEach(client => client.cb(color))
  }

  get colorClientsArray() {
    return Object.values(this.colorClients)
  }

}

export const ColorProviderContext = createContext(new ColorProvider())
