const context = new AudioContext()

export const connectAudioContext = (elem: HTMLMediaElement) => {
  const gainNode = context.createGain()
  const source = context.createMediaElementSource(elem)

  source.connect(gainNode).connect(context.destination)
  return { source, gainNode }
}

// https://developer.chrome.com/blog/autoplay/
document.onclick = () => {
  context.resume()
  document.onclick = null
}

document.onkeydown = () => {
  context.resume()
  document.onkeydown = null
}
