import { keyframes } from 'styled-components'
import ConnectionIcon1 from '~/assets/icons/connection/connection1.svg'
import ConnectionIcon2 from '~/assets/icons/connection/connection2.svg'
import ConnectionIcon3 from '~/assets/icons/connection/connection3.svg'
import ConnectionIcon4 from '~/assets/icons/connection/connection4.svg'

export const ConnectionIconAnimation = keyframes`
  0% {
    background: url(${ConnectionIcon1});
    background-repeat: no-repeat;
    background-position-y: center;
  }
  25% {
    background: url(${ConnectionIcon2});
    background-repeat: no-repeat;
    background-position-y: center;
  }
  50% {
    background: url(${ConnectionIcon3});
    background-repeat: no-repeat;
    background-position-y: center;
  }
  100% {
    background: url(${ConnectionIcon4});
    background-repeat: no-repeat;
    background-position-y: center;
  }
`
