import * as ActionTypes from 'actions/ActionTypes'
import * as API from 'ServerAPI'
import { selectProjects } from 'selectors/projectData'

export const addProject = (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch({ type: ActionTypes.PROJECTS_REQUESTED })

  try {
    const state = getState()
    const existingProjects = selectProjects(state)

    const project = await API.getProject(id)

    dispatch({
      type: ActionTypes.PROJECTS_LOADED,
      payload: { projects: [ project, ...existingProjects ], totalCount: existingProjects.length + 1 },
    })
  } catch (error) {
    console.error(`Failed to fetch projects ${error}`)

    dispatch({
      type: ActionTypes.PROJECTS_LOADED,
      payload: error,
      error: true,
    })
  }
}
