import React from 'react'

import MaterialCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox'

import './Checkbox.scss'
import UncheckedIcon from 'components/base/Checkbox/UnchekedIcon'
import CheckedIcon from 'components/base/Checkbox/CheckedIcon'
import classNames from 'classnames'

type OnChangeValue<T> = Omit<CheckboxProps, 'onChange'> & {
  emitValue?: T,
  onChange?: T extends false ? ((e: React.ChangeEvent<HTMLInputElement>) => void)
  : ((value: boolean) => void)
}

export type CustomCheckboxProps<T extends boolean | undefined = undefined> = T extends undefined
  ? OnChangeValue<false> : OnChangeValue<T>

function CustomCheckbox<T extends boolean | undefined = undefined>({ emitValue = false, ...props }:
  CustomCheckboxProps<T>) {
  const {
    onChange = () => {},
    color = 'default',
    disableRipple = true,
    className,
    ...rest
  } = props


  const handleChange = React.useCallback(
    e => {
      if (emitValue) {
        onChange(e.target.checked)
      } else {
        onChange(e)
      }
    },
    [ onChange, emitValue ]
  )
  return (
    <MaterialCheckbox
      color={color}
      disableRipple={disableRipple}
      onChange={handleChange}
      icon={<UncheckedIcon stroke="#A2A2A2" />}
      checkedIcon={<CheckedIcon />}
      className={classNames(className)}
      {...rest}
    />
  )
}

// const defaultProps = {
//   color: 'default',
//   disableRipple: true,
// }

// CustomCheckbox.defaultProps = defaultProps

export default CustomCheckbox
