import React from 'react'
import './FormField.scss'
import classNames from 'classnames'

export const FormField = ({ className, caption, children, description }) => (
  <div className={classNames('base-form-field')}>
    <div className="base-form-field_caption">
      {caption}
    </div>
    <div className="base-form-field_description">
      {description}
    </div>
    <div className={className}>{children}</div>
  </div>
)
