import { httpClient } from 'ServerAPI'
import { handleError } from '~/errors/handleError'

const FOLDERS_ROOT = '/api/folders'

/**
 *
 * @param {object} folder
 * @param {string} folder.name
 * @param {number|string} folder.folder parent folder id
 * @returns {Promise}
 */
export function createFolder(folder) {
  const body = { ...folder }
  if (!folder.folder) {
    delete body.folder
  }
  return httpClient.post(FOLDERS_ROOT, body).then(handleError).then(res => res?.data)
}

/**
 *
 * @param {number|string} folderId parent folder id
 * @param {string} name
 * @returns {Promise}
 */
export function renameFolder(folderId, name) {
  const body = { name }
  return httpClient.patch(`${FOLDERS_ROOT}/${folderId}`, body).then(handleError).then(res => res?.data)
}

/**
 *
 * @param {number|string} folderId parent folder id
 * @returns {Promise}
 */
export function deleteFolder(folderId, force = false) {
  let params = {}

  if (force) {
    params = { ...params, force }
  }

  return httpClient.delete(`${FOLDERS_ROOT}/${folderId}`, { crossDomain: true, params }).then(handleError)
}

/**
 *
 * @param {(number|string)[]} folderIds moving folder ids
 * @param {(number|string)[]} fileIds moving file ids
 * @param {number|string} folderId new parent folder id (root is default)
 * @returns {Promise}
 */
export function moveItemsToFolder(folderIds = [], fileIds = [], folderId = 'root') {
  const body = { files: fileIds, folders: folderIds }

  return httpClient.put(`${FOLDERS_ROOT}/${folderId}/entries`, body).then(handleError).then(res => res?.data)
}
