import { clearAssetsSelection } from 'actions/layer'
import { createText } from 'features/konva-shapes/createText'
import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import { isOverlayHiddenByAnother } from 'helpers/isOverlayHiddenByAnother'
import Konva from 'konva'
import { TextAsset, VideoAsset } from 'models/Asset'
import React, { useEffect, useRef, useState } from 'react'
import { Layer, Stage } from 'react-konva'
import { Provider, ReactReduxContext, useSelector } from 'react-redux'
import { getLayersIndexes, getReferenceVideoAsset, getSelectedAsset } from 'selectors'
import * as Actions from '~/actions'
import Catch from '~/components/base/Catch'
import { useAction, useChanged } from '~/hooks'
import { AssetOverlay } from '../custom-konva-elems/AssetOverlay'
import { EditingTransformer } from '../custom-konva-elems/EditingTransformer'
import { useCanvasParams } from '../lib'

// ---

/**
 * @see https://github.com/konvajs/react-konva/issues/311#issuecomment-454411007
 *
 * DO NOT use this component inside of a component with store connections.
 * I.e. don't do this:
 *
 * <code>
 *   const state = useSelector()
 *   <Stage>
 *     {state.some}
 *   </Stage>
 * </code>
 *
 * Instead, it MUST be like this:
 *
 * <code>
 *   function Child() {
*      const state = useSelector()
 *     ...
 *   }
 *
 *   <Stage>
 *     <Child />
 *   </Stage>
 * </code>
 */
function PatchedStage(props) {
  const { children, ...rest } = props
  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Stage {...rest}>
          <Provider store={store}>
            {children}
          </Provider>
        </Stage>
      )}
    </ReactReduxContext.Consumer>
  )
}

function PreviewAssetsOverlay({
  width,
  height,
  overlayAssets,
  composedOverlayAssets,
  overlayTransitions,
  videoRefs,
}) {
  const params = useCanvasParams({ width, height })
  const boundingRect = useChanged(params.rect)
  const scale = useChanged(params.scale)
  const viewport = useChanged(params.viewport)
  const layerRef = useRef()
  const editingLayerRef = useRef()

  const selectedAsset = useSelector(getSelectedAsset)
  const layersIndexes = useSelector(getLayersIndexes)
  const referenceAsset = useSelector(getReferenceVideoAsset)

  const clearSelection = useAction(clearAssetsSelection)

  const [ extSource, setExtSource ] = useState({ id: null, pos: null, size: null, rotation: null })

  useEffect(() => {
    setExtSource({ id: null, pos: null, size: null, rotation: null })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedAsset ])

  // Cancel selection if clicked out of the overlay on the preview
  const handleStageClick = e => {
    if (!e.target?.attrs?.id && selectedAsset) {
      clearSelection()
    }
  }

  const handleDragging = (id, pos) => {
    setExtSource(v => ({ ...v, id, pos }))
  }

  const handleTransforming = (id, { size, pos, rotation }) => {
    setExtSource({ id, pos, size, rotation })
  }

  const updatePreviewVideoSize = useAction(Actions.sourceFiles.updatePreviewVideoSize)
  useEffect(() => {
    updatePreviewVideoSize(boundingRect)
  }, [ updatePreviewVideoSize, boundingRect ])

  useEffect(() => {
    if (!layerRef?.current) return undefined

    const anim = new Konva.Animation(() => {}, layerRef.current)
    anim.start()

    return () => anim.stop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ layerRef.current ])

  const renderingOverlayAssets = overlayAssets.concat(composedOverlayAssets)

  // if canvas is invisible to user,
  // it's makes no sense to render anything
  if (width === 0 || height === 0) {
    return null
  }

  const viewPortWidth = viewport.width
  const viewPortHeight = viewport.height

  return (
    <Catch>
      <PatchedStage
        key="view-stage"
        style={{ background: 'black' }}
        onClick={handleStageClick}
        width={params.rect.width}
        height={params.rect.height}
      >
        <Layer key="view-layer" ref={layerRef}>
          {renderingOverlayAssets
            .sort((a, b) => layersIndexes.get(b.layerId) - layersIndexes.get(a.layerId))
            .map(asset => (
              <AssetOverlay
                videoRefs={videoRefs}
                key={`view-${asset.id}`}
                id={asset.id}
                size={asset instanceof TextAsset
                  ? createText(asset, undefined, { boundingRect, scale: { x: 1, y: 1 } }).attrs
                  : getAbsoluteAssetSize(asset, referenceAsset, boundingRect)}
                extSourcePos={extSource?.id === asset.id ? extSource.pos : undefined}
                extSourceSize={extSource?.id === asset.id ? extSource.size : undefined}
                extSourceRotation={extSource?.id === asset.id ? extSource.rotation : undefined}
                transition={overlayTransitions.find(
                  t => t.leftVideoAssetId === asset.id || t.rightVideoAssetId === asset.id
                )}
                isComposed={composedOverlayAssets.some(c => c.id === asset.id)}
                composedAsset={composedOverlayAssets[0]}
                asset={asset}
                layerRef={layerRef}
                params={params}
                boundingRect={boundingRect}
              />
            ))}
        </Layer>
      </PatchedStage>
      <PatchedStage
        key="editing-stage"
        style={{ background: 'transparent', position: 'absolute' }}
        onClick={handleStageClick}
        width={viewPortWidth}
        height={viewPortHeight}
        offsetY={(viewPortHeight - params.rect.height) / -2}
        offsetX={(viewPortWidth - params.rect.width) / -2}
      >
        <Layer key="editing-layer" ref={editingLayerRef}>
          {/* THE EDITING TRANSFORMERS FOR THE ASSETS */}
          {renderingOverlayAssets.slice()
            // eslint-disable-next-line arrow-body-style
            .sort((a, b) => {
              if (a.selected || b.selected) {
                const a1 = a.selected ? a : b
                const a2 = a.selected ? b : a
                return isOverlayHiddenByAnother(
                  a1, a2, referenceAsset, boundingRect, scale, layersIndexes
                ) ? -1 : 0
              }
              return layersIndexes.get(b.layerId) - layersIndexes.get(a.layerId)
            })
            .map(asset => (
              <EditingTransformer
                id={asset.id}
                key={`editing-${asset.id}`}
                asset={asset}
                size={asset instanceof TextAsset
                  ? createText(asset, undefined, { boundingRect, scale: { x: 1, y: 1 } }).attrs
                  : getAbsoluteAssetSize(asset, referenceAsset, boundingRect)}
                onlyMove={asset instanceof TextAsset}
                layerRef={editingLayerRef}
                scale={params.scale}
                boundingRect={boundingRect}
                onDragging={handleDragging}
                onTransforming={handleTransforming}
                rotateDisabled={asset instanceof VideoAsset}
              />
            ))}
        </Layer>
      </PatchedStage>
    </Catch>
  )
}

export default React.memo(PreviewAssetsOverlay)
