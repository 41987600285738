export const textOverflowHandler = (name: string, maxLength = 30) => {
  if (name.length > maxLength) {
    const extension = getExtension(name)
    return `${name.slice(0, maxLength - extension.length)}...${extension}`
  }

  return name
}

export const getExtension = (name: string) => name.slice(name.indexOf('.'))
