import { TRANSITIONS } from '~/enums'

export function getTransitionStartTime(transition, leftAsset, rightAsset) {
  let startTime = 0
  switch (transition.type) {
    case TRANSITIONS.DISSOLVE:
      startTime = rightAsset.startTime
      break
    case TRANSITIONS.FADEOUT:
      startTime = leftAsset?.endTime - transition.duration
      break
    case TRANSITIONS.FADEIN:
      startTime = rightAsset?.startTime
      break
    default: break
  }

  return startTime
}
