import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import Button from 'components/base/Button'
import React from 'react'
import './MessageBox.scss'

function MessageBox({ onClose, title, message, className }) {
  return (
    <Dialog className={classNames('message-box', className)} disableBackdropClick open onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message.split('\n').map(line => (
            <React.Fragment key={uuidv4()}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button primary onClick={onClose} autoFocus>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageBox
