import Cookies from 'universal-cookie'

const cookies = new Cookies()
const SHOW_BLANK_PROJECT_IN_LIST = 'showBlankProjectInList'

export function set(value) {
  cookies.set(SHOW_BLANK_PROJECT_IN_LIST, Number(value), {
    path: '/',
    expires: new Date(86_400_000 + +new Date()),
  })
}

export function get() {
  return Boolean(Number(cookies.get(SHOW_BLANK_PROJECT_IN_LIST)))
}
