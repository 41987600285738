import React from 'react'
import { DRAGNDROP_TYPE } from '~/enums'
import MediaItemPreview from '~/components/Timeline/DragAndDrop/MediaItemPreview'
import { LAYER_ASSET_HEIGHT } from '~/constant'

export const getExternalSourceElement = (dragItem, draggingAsset, styles) => {
  switch (dragItem.type) {
    case DRAGNDROP_TYPE.MEDIA_ITEM:
    case DRAGNDROP_TYPE.AUDIO_ITEM:
    case DRAGNDROP_TYPE.IMAGE_ITEM:
    case DRAGNDROP_TYPE.TEXT_ITEM:
    case DRAGNDROP_TYPE.TRANSITION_ITEM:
      return (
        <MediaItemPreview
          asset={draggingAsset}
          className={styles.mediaItem}
          style={{ height: LAYER_ASSET_HEIGHT }}
        />
      )
    default: return null
  }
}
