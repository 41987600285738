/* eslint-disable require-yield */
import { all, takeEvery, select } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import { sdkService } from '~/services/SDKService/SDKService'
import * as EventTypes from '~/services/SDKService/EventTypes'
import { TASK_STATUS } from '~/enums'

function* onProjectCreate({ payload }) {
  const { project } = payload
  const { id, thumbnail } = project
  sdkService.sendEvent({
    type: EventTypes.PROJECT_CREATE,
    payload: {
      projectId: id,
      thumbnail,
    },
  })
}

function* onProjectUpdated({ payload }) {
  const { id, thumbnail } = payload
  sdkService.sendEvent({
    type: EventTypes.PROJECT_UPDATE,
    payload: {
      projectId: id,
      thumbnail,
    },
  })
}

function* onRenderStarted({ payload }) {
  const projectId = payload.task?.projectId
  if (projectId) {
    sdkService.sendEvent({
      type: EventTypes.RENDER_START,
      payload: {
        projectId,
      },
    })
  }
}

function* onUpdateRenderTaskStatus({ payload, error }) {
  const id = error ? payload.id : payload.task.id
  if (error || payload.task?.status === TASK_STATUS.COMPLETED) {
    const editTask = yield select(state => state.project.editingTasks.find(t => t.id === id))
    if (editTask) {
      const { projectId, outputUrls } = editTask
      sdkService.sendEvent({
        type: EventTypes.RENDER_COMPLETE,
        payload: {
          projectId,
          downloadUrl: outputUrls[0],
          error: payload.message,
        },
      })
    }
  }
}

function* watchAll() {
  yield all([
    takeEvery([ ActionTypes.PROJECTS_CREATE ], onProjectCreate),
    takeEvery([ ActionTypes.PROJECTS_REFRESH_THUMBNAIL ], onProjectUpdated),
    takeEvery([ ActionTypes.ADD_EDITING_TASK ], onRenderStarted),
    takeEvery([ ActionTypes.UPDATE_EDITING_TASK_STATUS ], onUpdateRenderTaskStatus),
  ])
}

export default watchAll
