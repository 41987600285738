import React from 'react'
import { useAction } from '~/hooks'
import * as Actions from '~/actions'

export default function AmazonImport({ children }) {
  const showAmazonImport = useAction(Actions.mainView.showAmazonImport, true)

  return (
    <div onClick={showAmazonImport}>
      {children}
    </div>
  )
}
