import Hls from 'hls.js'


export const initHLSPlayer = (ref, options) => {
  const { src, hlsConfig, autoPlay } = options
  const newHls = new Hls({ enableWorker: true, debug: false, ...hlsConfig })
  if (ref.current != null) {
    newHls.loadSource(src)
    newHls.attachMedia(ref.current)
  }
  newHls.on(Hls.Events.MANIFEST_PARSED, () => {
    if (autoPlay && ref.current.play) {
      try {
        ref.current.play()
      } catch (e) {
        console.error(e)
      }
    }
  })
  newHls.on(Hls.Events.ERROR, (_, data) => {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          newHls.startLoad()
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
          newHls.recoverMediaError()
          break
        default:
          newHls.destroy()
          break
      }
    }
  })
  return newHls
}
