import { newProjectId } from '~/constant'
import * as ActionTypes from '~/actions/ActionTypes'
import * as API from '~/ServerAPI'
import * as LatestProjectName from '~/cookieServices/LatestProjectName'
import { selectActiveProjectId } from '~/selectors/projectData'
import { saveProject } from './saveProject'

export const renameProject = (id, name, createProject = false) => async (dispatch, getState) => {
  try {
    await dispatch({
      type: ActionTypes.PROJECTS_RENAME,
      payload: { id, name },
    })
    const activeProjectId = selectActiveProjectId(getState())
    if (activeProjectId === id) {
      LatestProjectName.set(name)
    }
    if (id !== newProjectId) {
      await API.renameProject(id, name)
    } else if (createProject) {
      await dispatch(saveProject())
    }
  } catch (e) {
    console.error(`Failed to rename project ${id}`)
  }
}
