import React from 'react'
// eslint-disable-next-line no-unused-vars
import MaterialCircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import './CircularProgress.scss'
import classNames from 'classnames'

/**
 *
 * @param {Partial<CircularProgressProps> & { text: ReactNode | null,
 * size?: number,
 * progress: number,
 * thickness?: number,
 * endless?: boolean,
 * percents?: number,
 * transparent?: boolean,
 * className?: string,}} param0
 * @returns
 */
export const CircularProgress = ({
  text,
  size = 60,
  progress,
  thickness = 2,
  endless = false,
  percents = 15,
  transparent = false,
  className = '',
  ...rest
}) => (
  <div
    className={classNames('progress', {
      'progress--white': !transparent,
      'progress--transparent': transparent,
    }, className)}
    style={{ width: `${size}px`, height: `${size}px` }}
  >
    <div className="progress__text">{text}</div>
    <MaterialCircularProgress
      className={endless ? 'spin-animation' : ''}
      size={size}
      thickness={thickness}
      variant="static"
      value={endless ? percents : progress}
      {...rest}
    />
  </div>
)
