/* eslint-disable class-methods-use-this */
import { isEmpty } from 'lodash'
import { undoMultipleSplitted } from 'actions/undoRedo/multipleSplittedUndoRedoActions'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'
import { split } from '~/actions/layer'
import { rebuildAssets } from '~/actions/projectData/mappings/getAssetMapping'


export class AssetsMultipleSplittedAction extends AssetHistoryAction {

  constructor(payload, dispatch, getState) {
    const { sliderTime, assetIDsBeforeSplitting, transitionsBeforeSplitting } = payload
    super(HistoryActionType.AssetsMultipleSplittedAction, payload, dispatch, getState)
    this._sliderTime = sliderTime
    this._assetIDsBeforeSplitting = assetIDsBeforeSplitting
    this._transitionsBeforeSplitting = transitionsBeforeSplitting
  }

  get sliderTime() {
    return this._sliderTime
  }

  set sliderTime(sliderTime) {
    this._sliderTime = sliderTime
  }

  get transitionsBeforeSplitting() {
    return this._transitionsBeforeSplitting
  }

  set transitionsBeforeSplitting(transitionsBeforeSplitting) {
    this._transitionsBeforeSplitting = transitionsBeforeSplitting
  }

  get assetIDsBeforeSplitting() {
    return this._assetIDsBeforeSplitting
  }

  set assetIDsBeforeSplitting(assetIDsBeforeSplitting) {
    this._assetIDsBeforeSplitting = assetIDsBeforeSplitting
  }

  * undo() {
    const { assets, assetIDsBeforeSplitting, transitionsBeforeSplitting } = this
    const prepareAssets = rebuildAssets(assets)
    yield undoMultipleSplitted(prepareAssets, assetIDsBeforeSplitting, transitionsBeforeSplitting)
  }

  * redo() {
    const { assets } = this
    if (!isEmpty(assets)) {
      const assetsForSplitted = rebuildAssets(assets)
      yield split(assetsForSplitted)
    }
  }

  getOptimizedObject() {
    return { ...this }
  }

}
