import { getVideoPosition } from 'Util/assets'
import { TextAsset } from 'models/Asset'
import { createText } from 'features/konva-shapes/createText'
import { getAbsoluteAssetSize } from './getAbsoluteAssetSize'

export const isAssetFitsPreviewFrame = (asset, previewParams, refAsset) => {
  const { offset, rotation } = asset.settings
  const { boundingRect, scale } = previewParams
  const size = asset instanceof TextAsset
    ? createText(asset, undefined, { boundingRect, scale: { x: 1, y: 1 } }).attrs
    : getAbsoluteAssetSize(asset, refAsset)
  const { width, height } = size
  let { width: bWidth, height: bHeight } = boundingRect
  bWidth = Math.round(bWidth / scale.x)
  bHeight = Math.round(bHeight / scale.y)
  const { x, y } = getVideoPosition({ boundingRect, scale, offset, size, rotation })
  return x === 0 && y === 0 && width === bWidth && height === bHeight
}
