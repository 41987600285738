import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelectable } from 'react-selectable-fast-control'
import * as PT from '~/PropTypes'
import { normalizeThumbnail, refTimeToHHMMSSMSMS } from '~/Util'
import { FILE_STATUS, UPLOAD_ERROR } from '~/enums'
import { useOnClickWithoutPropagation } from '~/hooks'
import * as Assets from '~/models/Asset'
import { Folder } from '~/models/Folder'
import ListItem from '../BaseAssetsListItem'
import GroupMediaControls from './GroupMediaControls'
import './ListItem.scss'
import MediaControls from './MediaControls'
import MediaProgress from './MediaProgress'
import ThumbnailsProgress from './ThumbnailsProgress'
import { FolderMediaPreview } from './media-previews/FolderMediaPreview'
// ---
import { TranslationContext } from '~/contexts/TranslationContext'
import { isVoiceover } from '~/Util/recording'
import styles from './listItem.module.scss'

function getThumbnail(asset) {
  if (asset instanceof Assets.AudioAsset) {
    if (isVoiceover(asset)) {
      return (
        <>
          <div className={cx(styles.thumbnailBackground, styles.voiceBackground)} />
          <div className={cx(styles.thumbnailIcon, styles.voiceIcon)} />
        </>
      )
    }

    return (
      <>
        <div className={cx(styles.thumbnailBackground, styles.audioBackground)} />
        <div className={cx(styles.thumbnailIcon, styles.audioIcon)} />
      </>
    )
  }

  return null
}

function MediaAssetsListItem(props) {
  const {
    /** @type {Assets.VideoAsset|Assets.ImageAsset|Assets.AudioAsset} */
    asset,
    // eslint-disable-next-line react/prop-types
    isSelected,
    ...rest
  } = props

  // const isLocalFileDragging = useSelector(state => state.mainView.isLocalFileDragging)
  const selectedClipId = useSelector(state => state.playback.selectedClipId)
  const groupContextMenuRef = useRef(null)
  const [ isOpenContextMenu, setIsOpenContextMenu ] = useState(false)

  const handleClickContextMenu = useOnClickWithoutPropagation(() => {
    setIsOpenContextMenu(true)
  })

  const handleCloseContextMenu = useOnClickWithoutPropagation(() => {
    setIsOpenContextMenu(false)
  })

  const isFolder = asset instanceof Folder

  const { t } = useContext(TranslationContext)

  return (
    <ListItem
      {...rest}
      className={cx('media-file', { 'media-file__prevent-dragging': false })}
      isFolder={isFolder}
      isSelected={isSelected}
      renaming={isFolder}
      asset={asset}
      onClickContextMenu={handleClickContextMenu}
      ref={groupContextMenuRef}
    >
      <div
        className={cx('media-file__img', { 'media-file__selected': selectedClipId === asset.id })}
      >
        {/* do not show thumbnail if error happened */}
        <If condition={asset.thumbnail && asset.error === undefined}>
          {getThumbnail(asset) || (
          <img
            src={normalizeThumbnail(asset.thumbnail)}
            className="thumbnail"
            alt={`${t('MEDIA_TEXT_LOADING')}...`}
          />
          )}
        </If>

        <If condition={asset instanceof Folder}>
          <FolderMediaPreview />
        </If>

        <If condition={!(asset instanceof Assets.ImageAsset) && asset.status === FILE_STATUS.READY}>
          <div className="media-file__duration">
            {refTimeToHHMMSSMSMS(asset.duration)}
          </div>
        </If>

        <Choose>
          <When condition={asset.error !== undefined}>
            <div className="media-file__error">
              <div className="media-file__error-title">
                {/* preparing of thumbnails can be failed, but it is not upload issue */}
                {asset.error.type !== UPLOAD_ERROR.BUILD_THUMBNAILS && asset.error.title}
              </div>
              <div className="media-file__error-message">
                {asset.error.message}
              </div>
            </div>
          </When>
          <When condition={asset.status === undefined || asset.status === FILE_STATUS.READY}>
            {/* no progress should be displayed */}
          </When>
          <When condition={asset.status === FILE_STATUS.PREPARE_THUMBNAILS}>
            <ThumbnailsProgress progress={asset.progress} />
          </When>
          <Otherwise>
            <MediaProgress
              progress={asset.progress}
              status={asset.status}
              endless={(asset.status === FILE_STATUS.IMPORT && asset.progress === 0)
                || (asset.status === FILE_STATUS.MEDIAINFO)}
            />
          </Otherwise>
        </Choose>
        <If condition={isSelected}>
          <GroupMediaControls
            open={isOpenContextMenu}
            anchorEl={groupContextMenuRef.current}
            onClose={handleCloseContextMenu}
          />
        </If>
        <MediaControls
          item={asset}
          isFolder={isFolder}
        />
      </div>
    </ListItem>
  )
}

export default createSelectable(MediaAssetsListItem)

MediaAssetsListItem.propTypes = {
  asset: PropTypes.oneOfType([ PT.VideoAsset, PT.ImageAsset, PT.AudioAsset, PT.Folder ]).isRequired,
}
