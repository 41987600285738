/**
 * @param {Asset[]} assets
 * @returns {number}
 */
function calcProjectDuration(assets) {
  let totalDuration = 0
  for (const asset of assets) {
    totalDuration = Math.max(totalDuration, asset.endTime)
  }
  return totalDuration
}

export default calcProjectDuration
