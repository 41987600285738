import React from 'react'
import classNames from 'classnames'
import Button from '~/components/base/Button'
import './FloatButton.scss'

function FloatButton(props) {
  const {
    className,
    forwardRef,
    bottomRight,
    bottomLeft,
    topRight,
    topLeft,
    children,
    onClick,
    title,
    ...rest
  } = props
  return (
    <div
      className={classNames('float-button-container', className, {
        'float-button-container--bottom-right': bottomRight,
        'float-button-container--bottom-left': bottomLeft,
        'float-button-container--top-right': topRight,
        'float-button-container--top-left': topLeft,
      })}
      title={title}
      onClick={onClick}
    >
      <Button
        ref={forwardRef}
        className={classNames('float-button')}
        type="button"
        // eslint-disable-next-line react/no-children-prop
        children=""
        {...rest}
      />
      <div className="float-button-content">
        <span>{children}</span>
      </div>
    </div>
  )
}

export default React.forwardRef((props, ref) => (
  <FloatButton {...props} forwardRef={ref} />
))
