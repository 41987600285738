import { patchAsset } from '~/actions/layer'
import { continiousChangingSettings } from '~/constant'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

// Images and Texts settings
export class AssetSettingsUpdatedAction extends AssetHistoryAction {

  constructor(payload) {
    const { settings } = payload
    super(HistoryActionType.AssetSettingsUpdatedAction, payload)
    this.settings = settings
  }

  get settings() {
    return this._settings
  }

  set settings(settings) {
    this._settings = settings
  }

  * undo() {
    const { asset } = this
    yield patchAsset(asset, { settings: asset.settings })
  }

  * redo() {
    const { asset, settings } = this
    yield patchAsset(asset, { settings })
  }

  isContinuously() {
    return Object.keys(this.settings).some(k => continiousChangingSettings.includes(k))
  }

  getOptimizedObject() {
    return { ...this,
      asset: this.getOptimizedAsset(({ settings }) => ({ settings })) }
  }

}
