
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Group, Image } from 'react-konva'
import { formatRBGColor } from '~/Util'
import { getCoordsFromOffset } from '../AssetsOverlay/lib'

export const Shape = React.forwardRef((props, ref) => {
  const {
    id,
    image,
    videoRefs,
    shape,
    shapeWithOutline,
    // canvas is used to force provide transition frames
    canvas,
    flip,
    rotation: savedRotation,
    groupRef,
    offset,
    boundingRect,
    scale,
    hidden,
    visible = true,
    extSourcePos,
    extSourceSize = {},
    extSourceRotation = null,
    settings = {},
  } = props

  const [ pos, setPos ] = useState({ x: 0, y: 0 })
  const [ rotation, setRotation ] = useState(savedRotation)

  const { opacity, outlineWidth = 0, outlineColor } = settings

  useEffect(() => {
    setRotation(savedRotation)
  }, [ savedRotation ])

  useEffect(() => {
    if (extSourceRotation !== null) {
      setRotation(extSourceRotation)
    }
  }, [ extSourceRotation ])

  const shapeWithOutlineRect = shapeWithOutline?.getClientRect() || {}

  const [ size, setSize ] = useState(() => ({
    width: shapeWithOutlineRect.width || shape?.width() || 0,
    height: shapeWithOutlineRect.height || shape?.height() || 0,
  }))
  const { width, height } = size

  useEffect(() => {
    if (!isEmpty(extSourceSize)) {
      setSize(extSourceSize)
    }
  }, [ extSourceSize ])

  useEffect(() => {
    setSize({
      width: shapeWithOutlineRect.width || shape?.width() || 0,
      height: shapeWithOutlineRect.height || shape?.height() || 0,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ shape, shapeWithOutline ])

  const offsetX = width / 2
  const offsetY = height / 2

  useEffect(() => {
    if (ref?.current) {
      // settimeout is important for WAITING until size will be setted
      setTimeout(() => {
        if (ref?.current) {
          // Box dimensions includes outline
          // Sometimes getClientRect gets inaccurate digits in some renderings (example: 1 <-> 1.00000001). Use toFixed(2)
          const boxRect = ref?.current.getClientRect()
          const boxWidth = boxRect ? ref?.current.width() : width
          const boxHeight = boxRect ? ref?.current.height() : height
          const newPos = getCoordsFromOffset(offset, { x: 0, y: 0 },
            { boundingRect,
              assetKonvaSize: { width: boxWidth, height: boxHeight },
              rotation,
              outlineWidth,
              scale })
          setPos(newPos)
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ shape, boundingRect, ref ])

  useEffect(() => {
    if (extSourcePos) {
      setPos(extSourcePos)
    }
  }, [ extSourcePos ])

  const params = {
    ...pos,
    width,
    height,
    offsetX,
    offsetY,
    rotation,
    ...(flip?.horizontal ? { scaleX: -1, offsetX } : {}),
    ...(flip?.vertical ? { scaleY: -1, offsetY } : {}),
  }

  return (
    <Group
      visible={visible}
      ref={groupRef}
      clip={{ ...boundingRect }}
    >
      <Group
        {...params}
        ref={ref}
      >
        <Group visible={!hidden}>
          <Image
            id={id}
            width={width}
            height={height}
            opacity={image ? (opacity / 100) : undefined}
            strokeWidth={(image && !canvas) ? outlineWidth : undefined}
            stroke={(image && !canvas) ? formatRBGColor(outlineColor) : undefined}
            image={canvas || image || videoRefs?.[id]?.current || shape.toCanvas()}
          />
        </Group>
      </Group>
    </Group>
  )
})
