import * as Actions from 'actions'
import { HOTKEYS } from 'enums'
import { useAction } from 'hooks'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'

export default function PlaybackKeyHandler() {
  const onPlayPause = useAction(Actions.playback.togglePlaybackPlaying)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.PLAY_PAUSE], () => onPlayPause())
  // const documentRef = useRef(document)
  // useKeyCodeListener(documentRef, KEY_SPACE, onPlayPause, { preventDefault: true })
  return null
}
