import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { ConnectionStatus } from 'components/AssetPanels/Voiceover/types'
import { selectRecordingStatus } from 'selectors/recording'
import * as Selectors from 'selectors'
import { handleRestoreOnConnect, handleStopOnDisconnect } from 'actions/recording'
import { useAction } from 'hooks'

type Props = {
  createConnection: () => void,
  closeConnection: () => void,
  connectStatus: ConnectionStatus,
  recordingStatus: ReturnType<typeof selectRecordingStatus>
}

function useDisconnected({ createConnection, closeConnection, recordingStatus, connectStatus }: Props) {
  const isOffline = useSelector(Selectors.selectIsOffline)
  const isOfflineRef = useRef(isOffline)
  const recordingStatusRef = useRef(recordingStatus)
  const stopOnDisconnect = useAction(handleStopOnDisconnect)
  const restoreRecord = useAction(handleRestoreOnConnect)

  useEffect(() => {
    recordingStatusRef.current = recordingStatus
  }, [ recordingStatus ])

  useEffect(() => {
    if (connectStatus === 'disconnected' || connectStatus === 'failed') {
      // GET request tests offline
      // and triggers recordingSaga with SET_IS_OFFLINE action
      axios.get('/api/version').then(x => {
        // in case server close connection while recording
        if (recordingStatusRef.current === 'progress') {
          stopOnDisconnect()
          restoreRecord()
        }

        closeConnection()
        createConnection()
      }).catch(e => {
        isOfflineRef.current = true
        closeConnection()
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ connectStatus ])

  useEffect(() => {
    if (!isOffline && isOfflineRef.current === true) {
      isOfflineRef.current = false
      // wait until websocket got connected with server
      // and user get online on server
      setTimeout(() => createConnection(), 500)
    }
  }, [ isOffline, createConnection ])
}

export default useDisconnected
