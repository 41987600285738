import { createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  createAction } from '@reduxjs/toolkit'
import { MEDIA_RECORDING_TYPE, TMEDIA_RECORDING_TYPE } from 'enums'
import { MediaRecordingAsset, MediaAsset } from 'models/Asset'

type AudioOptions = {
  countdown: boolean
  rewindSlider: boolean,
  muted: boolean,
}

type VideoOptions = {
  countdown: boolean
  rewindSlider: boolean,
  muted: boolean,
}

type RecordingStatus = 'stop' | 'progress' | 'waiting' | 'error' | 'saving'

type Asset = MediaRecordingAsset | MediaAsset

type InitialState = {
  assets: EntityState<Asset>,
  showCountdown: boolean,
  recordingStatus: RecordingStatus,
  [MEDIA_RECORDING_TYPE.Audio]: AudioOptions;
  [MEDIA_RECORDING_TYPE.Video]: VideoOptions
}

const mediaRecordingAdapter = createEntityAdapter<Asset>()

const initialState: InitialState = {
  assets: mediaRecordingAdapter.getInitialState() as EntityState<Asset>,
  showCountdown: false,
  recordingStatus: 'stop',
  audio: {
    countdown: false,
    rewindSlider: true,
    muted: true,
  },
  video: {
    countdown: false,
    rewindSlider: true,
    muted: true,
  },
}

export const addRecord = createAction<Asset>('addRecord')
export const deleteRecord = createAction<string>('deleteRecord')
export const replaceRecord = createAction<Asset>('replaceRecord')

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    setShowCountdown: (state, action: PayloadAction<boolean>) => {
      state.showCountdown = action.payload
    },
    setCountdown: (state, action: PayloadAction<{ value: boolean, type: TMEDIA_RECORDING_TYPE }>) => {
      const { type, value } = action.payload
      state[type].countdown = value
    },
    setMuted: (state, action: PayloadAction<{ value: boolean, type: TMEDIA_RECORDING_TYPE }>) => {
      const { type, value } = action.payload
      state[type].muted = value
    },
    setRecordingStatus: (state, action: PayloadAction<RecordingStatus>) => {
      state.recordingStatus = action.payload
    },
    setRewindSlider: (state, action: PayloadAction<{ value: boolean, type: TMEDIA_RECORDING_TYPE }>) => {
      const { type, value } = action.payload
      state[type].rewindSlider = value
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(addRecord, (state, action) => {
      state.assets = mediaRecordingAdapter.addOne(state.assets as EntityState<Asset>,
        action.payload)
    })
    builder.addCase(deleteRecord, (state, action) => {
      state.assets = mediaRecordingAdapter.removeOne(state.assets as EntityState<Asset>,
        action.payload)
    })
    builder.addCase(replaceRecord, (state, action) => {
      state.assets = mediaRecordingAdapter.setOne(state.assets as EntityState<Asset>, action.payload)
    })
  },
})

export const { actions } = recordingSlice
export const { setShowCountdown, setCountdown, setRecordingStatus,
  setRewindSlider, setMuted, reset } = recordingSlice.actions

export default recordingSlice.reducer
