import { getCoordsFromOffset } from 'components/Preview/AssetsOverlay/lib'
import { TextAsset } from 'models/Asset'
import { createText } from 'features/konva-shapes/createText'
import { getAbsoluteAssetSize } from './getAbsoluteAssetSize'
import { getRotatedSize } from './getRotatedSize'

export const isOverlayHiddenByAnother = (
  asset1, asset2, refAsset, boundingRect, scale, layersIndexes
) => {
  const size = asset1 instanceof TextAsset
    ? createText(asset1, undefined, { boundingRect, scale: { x: 1, y: 1 } }).attrs
    : getAbsoluteAssetSize(asset1, refAsset)
  const size2 = asset2 instanceof TextAsset
    ? createText(asset2, undefined, { boundingRect, scale: { x: 1, y: 1 } }).attrs
    : getAbsoluteAssetSize(asset2, refAsset)


  let { width: width1, height: height1 } = size
  let { width: width2, height: height2 } = size2

  width1 *= scale.x
  width2 *= scale.x
  height1 *= scale.y
  height2 *= scale.y

  const scaledSize = { width: width1, height: height1 }
  const scaledSize2 = { width: width2, height: height2 }

  let { x: x1, y: y1 } = getCoordsFromOffset(asset1.settings.offset, { x: 0, y: 0 },
    { boundingRect,
      assetKonvaSize: scaledSize,
      rotation: asset1.settings.rotation,
      outlineWidth: asset1.settings.outlineWidth,
      scale })
  let { x: x2, y: y2 } = getCoordsFromOffset(asset2.settings.offset, { x: 0, y: 0 },
    { boundingRect,
      assetKonvaSize: scaledSize2,
      rotation: asset2.settings.rotation,
      outlineWidth: asset2.settings.outlineWidth,
      scale })

  const { width: rWidth1, height: rHeight1 } = getRotatedSize(scaledSize, asset1.settings.rotation)
  const { width: rWidth2, height: rHeight2 } = getRotatedSize(scaledSize2, asset2.settings.rotation)

  x1 -= rWidth1 / 2
  y1 -= rHeight1 / 2
  x2 -= rWidth2 / 2
  y2 -= rHeight2 / 2

  const xOverlap = Math.max(0, Math.min(x1 + rWidth1, x2 + rWidth2)
  - Math.max(x1, x2))
  const yOverlap = Math.max(0, Math.min(y1 + rHeight1, y2 + rHeight2)
  - Math.max(y1, y2))
  const overlapArea = xOverlap * yOverlap

  const asset1Area = Math.round(scaledSize.width * scaledSize.height)
  const asset2Area = Math.round(scaledSize2.width * scaledSize2.height)

  return overlapArea > 0 && layersIndexes.get(asset1.layerId) > layersIndexes.get(asset2.layerId)
    && (Math.round(overlapArea) !== Math.round(rWidth2 * rHeight2)
    || asset2Area >= asset1Area // issue 3121: the selection was reseted when moved rotated image
    )
}
