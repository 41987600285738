const mouseClickEvents = [ 'mousedown', 'click', 'mouseup' ]

export function simulateMouseClick(element, params) {
  mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(
    new MouseEvent(mouseEventType, {
      view: window,
      bubbles: true,
      cancelable: true,
      ...params,
    })
  ))
}
