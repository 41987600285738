import { takeEvery, all, select, put, takeLeading } from 'redux-saga/effects'
import * as ActionTypes from 'actions/ActionTypes'
import { MediaRecordingRestoreService } from 'services/MediaRecordingRestoreService'
import { handleRestoreOnConnect, handleStopOnDisconnect } from 'actions/recording'
import { selectActiveProjectId } from 'selectors/projectData'
import { selectRecordingStatus } from 'selectors/recording'
import { take } from 'lodash'

function* restoreMediaRecordingAsset() {
  const { pendingRecord: assetToRestore, projectId } = MediaRecordingRestoreService
  if (!assetToRestore || !projectId) return

  const activeProjectId = yield select(selectActiveProjectId)
  if (projectId === activeProjectId) {
    yield put(handleRestoreOnConnect(true))
  } else {
    MediaRecordingRestoreService.pop()
  }
}

function* handleOffline({ payload }) {
  const { isOffline } = payload
  const recordingStatus = yield select(selectRecordingStatus)
  if (isOffline && recordingStatus === 'progress') {
    yield put(handleStopOnDisconnect())
  }
  if (!isOffline) {
    const { pendingRecord: assetToRestore, projectId } = MediaRecordingRestoreService
    const activeProjectId = yield select(selectActiveProjectId)

    if (activeProjectId === null) {
      yield take(ActionTypes.PROJECTS_END_PROJECT_LOAING)
    }

    if (!assetToRestore || projectId !== activeProjectId) return
    yield put(handleRestoreOnConnect())
  }
}

function* watchAll() {
  yield all([ takeEvery([ ActionTypes.PROJECTS_END_PROJECT_LOAING ], restoreMediaRecordingAsset),
    takeLeading([ ActionTypes.SET_IS_OFFLINE ], handleOffline) ])
}

export default watchAll
