import hexToHsl from 'hex-to-hsl'

export const colorsIntoCss = (css, { background, primary, secondary, audio, voiceover }) => {
  let modifiedCss = css

  function replace(varName, newValue) {
    // eslint-disable-next-line no-useless-escape
    const reg = `--${varName}:.*;`
    modifiedCss = modifiedCss.replace(new RegExp(reg), `--${varName}: ${newValue};`)
  }

  const bgHsl = hexToHsl(background)
  const primaryHsl = hexToHsl(primary)

  const direction = bgHsl[2] > 50 ? -1 : 1

  replace('color-pallet-direction', direction)
  replace('bg-h', bgHsl[0])
  replace('bg-s', `${bgHsl[1]}%`)
  replace('bg-l', `${bgHsl[2]}%`)
  replace('act-h', primaryHsl[0])
  replace('act-s', `${primaryHsl[1]}%`)
  replace('act-l', `${primaryHsl[2]}%`)
  replace('slider-color', secondary)
  replace('audio-wave-color', audio)
  replace('voice-wave-color', voiceover)

  return modifiedCss
}
