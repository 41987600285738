import { isEmpty } from 'lodash'
import { getOffset } from './getOffset'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'

// Move all selected assets if the selected asset is located before the slider
export const adjustAssetsStartTime = (copiedAssetsByLayerIndex, sliderTime, copiedAssets) => {
  const offset = getOffset(copiedAssets, sliderTime)
  return copiedAssetsByLayerIndex.reduce((result, copiedLayerAssets, index) => {
    let newLayerAssets
    if (!isEmpty(copiedLayerAssets)) {
      newLayerAssets = copiedLayerAssets.map(asset => {
        const cloneAsset = cloneInstanceClass(asset)
        cloneAsset.startTime += offset
        cloneAsset.endTime = cloneAsset.startTime + cloneAsset.duration
        return cloneAsset
      })
    }
    // eslint-disable-next-line no-param-reassign
    result[index] = newLayerAssets ?? copiedLayerAssets
    return result
  }, {})
}
