/* eslint-disable no-nested-ternary */
import { Surface } from 'gl-react-dom'
import GLTransitions from 'gl-transitions'
import React, { useEffect, useState } from 'react'
import GLTransition from 'react-gl-transition'
import { TRANSITIONS } from '~/enums'
import { useChanged } from '~/hooks'
import { GLImage } from '~/Util/shaders/GLImage'

function getGLTransitionConfig(type) {
  switch (type) {
    case TRANSITIONS.DISSOLVE:
      return GLTransitions.find(x => x.name === 'displacement')
    case TRANSITIONS.FADEIN:
    case TRANSITIONS.FADEOUT:
      return GLTransitions.find(x => x.name === 'fade')
    default: throw new Error(`Transition ${type} is not supported`)
  }
}

export function useTransitionProgress(sliderTime, duration, startTime) {
  if (duration === undefined || startTime === undefined) {
    return null
  }

  const transitionProgressStep = 1 / (duration / 1e4) // 0..1 - is transition progress
  const currentTransitionPosition = (sliderTime - startTime) / 1e4
  return currentTransitionPosition * transitionProgressStep
}

const Transition = React.forwardRef((props, ref) => {
  const { transition, params, style, onProgress = () => {}, sliderTime,
    background, resizeMode } = props
  let { to, from } = props
  const [ glTransition, setGLTransition ] = React.useState(GLTransitions[0])
  const progress = useTransitionProgress(sliderTime,
    transition.duration, transition.startTime)
  const [ isInitialized, setIsInitialized ] = useState(false)

  if (background) {
    if (transition.type === TRANSITIONS.FADEOUT) {
      to = background
    } else if (transition.type === TRANSITIONS.FADEIN) {
      from = background
    } else if (transition.type === TRANSITIONS.DISSOLVE) {
      to = to || background
      from = from || background
    }
  }

  useEffect(() => {
    // NOTE: this fix is used for initialize transition (issue 1683)
    const id = setTimeout(() => {
      setIsInitialized(true)
    }, 300)
    setGLTransition(getGLTransitionConfig(transition.type))
    return () => id && clearTimeout(id)
  }, [ transition ])

  const isProgressing = v => v >= 0 && v < 1

  const [ resetTransition, setResetTransition ] = useState(false)
  useEffect(() => setResetTransition(!(isProgressing(progress))), [ progress ])

  const rect = useChanged(params.rect)

  useEffect(
    () => {
      const { canvas } = ref.current.gl
      const canvasStyle = canvas.style
      canvasStyle.left = `${rect.x}px`
      canvasStyle.top = `${rect.y}px`
      // NOTE: Without this setting width and height there will be incorrect size of Surface canvas
      canvas.width = rect.width
      canvas.height = rect.height
    }, [ rect, ref ]
  )

  useEffect(() => {
    let id = null
    if (isProgressing(progress)) {
      // NOTE: fixed image flickering whe dissolve is starting
      id = setTimeout(() => {
        onProgress(progress)
      })
    }
    return () => id && clearTimeout(id)
  }, [ onProgress, progress ])

  return (
    <>
      <Surface
        webglContextAttributes={{ preserveDrawingBuffer: true, premultipliedAlpha: false }}
        ref={ref}
        width={rect.width}
        height={rect.height}
        style={{
          ...style,
          top: `calc(50% - ${rect.height / 2}px)`,
          left: `calc(50% - ${rect.width / 2}px)`,
        }}
        className="gltransition"
      >
        <GLTransition
          from={<GLImage resizeMode={resizeMode} source={resetTransition ? null : from} />}
          to={<GLImage resizeMode={resizeMode} source={resetTransition ? null : to} />}
          progress={progress}
          transition={glTransition}
        />
      </Surface>
      {/* NOTE: this fix is used for initialize transition (issue 1683) */}
      <If condition={!isInitialized && transition.type === TRANSITIONS.FADEIN}>
        <div className="initializing-transition-plug" />
      </If>
    </>
  )
})

export default Transition
