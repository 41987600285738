import React, { forwardRef, RefObject } from 'react'
import { MenuItemProps } from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import styled from '@material-ui/core/styles/styled'
import MenuItem from 'components/base/MenuItem'

const StyledMenuItem = styled(MenuItem)({
  '& *:last-child': {
    marginLeft: 'auto',
  },
})

const StyledTypography = styled(Typography)({
  fontSize: '12px',
  paddingRight: '8px',
  textAlign: 'left',
})

const FlexBox = styled(Box)({
  display: 'flex',
})

interface IconMenuItemProps {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onClick?: () => void
  label?: string
  className?: string
  MenuItemProps?: MenuItemProps
  ref?: RefObject<HTMLLIElement>
}

const IconMenuItem = forwardRef<HTMLLIElement, IconMenuItemProps>(
  ({ leftIcon, rightIcon, label, MenuItemProps, className, ...props }, ref) => (
    <StyledMenuItem
      {...MenuItemProps}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      button={MenuItemProps?.button as any}
      ref={ref}
      className={className}
      {...props}
    >
      <FlexBox>
        {leftIcon}
        <StyledTypography>{label}</StyledTypography>
      </FlexBox>
      {rightIcon}
    </StyledMenuItem>
  )
)

IconMenuItem.displayName = 'IconMenuItem'
export { IconMenuItem }
