import { pixel2Time } from '~/Util'
import { getIntersectionData } from './getIntersectionData'

export const checkIntersectionStaticAssets = ({
  staticAssets,
  dragItem,
  layers,
  draggableOffset,
  offsetLayerIndex,
  scale,
  leftXOffset,
  clientOffset,
}) => {
  const clientMousePosition = pixel2Time(clientOffset.x - leftXOffset, scale)
  const checkedLayerIds = dragItem
    .selectedAssets?.map(dragAsset => layers[dragAsset.layerIndex + offsetLayerIndex]?.id ?? false)
    .filter(Boolean) ?? []

  const isMouseOver = staticAssets.some(staticAsset => {
    const staticStartTime = staticAsset.startTime
    const staticEndTime = staticStartTime + staticAsset.duration
    return (staticStartTime <= clientMousePosition
    && clientMousePosition <= staticEndTime
    && checkedLayerIds.includes(staticAsset.layerId))
  })

  const result = getIntersectionData({
    staticAssets,
    dragItem,
    layers,
    draggableOffset,
    offsetLayerIndex,
  })

  return {
    ...result,
    isMouseOver,
  }
}
