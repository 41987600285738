import React from 'react'
import Button from 'components/base/Button/Button'
import { Dialog, DialogTitle, DialogContent } from 'components/base/Dialog/Dialog'
import styles from './selectDialog.module.scss'

type Props = {
  title: string,
  description?: string,
  confirmBtnText?: string,
  closeBtnText?: string,
  onCancel: () => void,
  onConfirm: () => void,
  onClick: () => void,
  children: React.ReactNode,
  dataLangConfirmId?: string,
  dataLangCloseId?: string,
  buttonConfirmTitle?: string,
  buttonCloseTitle?: string,
  [x: string]: unknown
}

export const SelectDialog = (
  { title,
    description,
    confirmBtnText,
    closeBtnText = 'Cancel',
    onCancel,
    onClick = () => {},
    onConfirm,
    children,
    dataLangConfirmId,
    buttonConfirmTitle,
    dataLangCloseId = 'CANCEL',
    buttonCloseTitle = 'Cancel',
    ...rest }: Props
) => {
  const [ open, setOpen ] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
    onCancel()
  }

  const onConfirmClick = () => {
    onConfirm()
  }

  return (
    <Dialog onClick={onClick} open={open} onClose={handleClose} className={styles.dialog} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          {description && <div className={styles.description}>{description}</div>}
          {children}
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.buttonCancel}
            onClick={handleClose}
            data-lang-id={dataLangCloseId}
            title={buttonCloseTitle}
          >
            {closeBtnText}
          </Button>

          {confirmBtnText
            && (
            <Button
              className="select-dialog__buttons__confirm"
              primary
              onClick={onConfirmClick}
              title={buttonConfirmTitle}
              data-lang-id={dataLangConfirmId}
            >
              {confirmBtnText}
            </Button>
            )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
