import axios from 'axios'
import { Subject } from 'rxjs'

class SDKServiceImplementaton {

  eventsQueue$ = new Subject()

  postEvent(data) {
    const event = {
      ...data,
      userData: this.userData,
      apiAuthToken: this.apiAuthToken,
    }
    return this.axiosInst.post('', event)
      .then()
      .catch(e => console.error(`SDK Service Error postEvent ${e}`))
  }

  start({ callbackUrl, userData, customApiAuthToken }) {
    if (this.callbackUrl) {
      return
    }
    this.apiAuthToken = customApiAuthToken
    this.userData = userData
    this.callbackUrl = callbackUrl
    this.eventsQueue$.subscribe({
      next: this.postEvent.bind(this),
    })
    this.axiosInst = axios.create({
      baseURL: callbackUrl,
    })
  }

  sendEvent(e) {
    if (this.callbackUrl) {
      this.eventsQueue$.next(e)
    }
  }

}

export const sdkService = new SDKServiceImplementaton()
