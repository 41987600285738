import React, { useContext, useEffect, useState } from 'react'

import OpacityTransition from 'components/base/animation/OpacityTrasition'

import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import { useInterval } from 'hooks/useInterval'
import styles from './countdown.module.scss'


type Props = {
  items: Array<number | string> | React.ReactNodeArray,
  onCancel: () => void
}

function Countdown({ items, onCancel = () => {} }: Props) {
  const [ index, setIndex ] = useState(0)
  const { cancel } = useInterval(() => setIndex(x => x + 1), 1000)

  useEffect(() => {
    if (index === items.length - 2 || items.length <= 1) {
      cancel()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ index ])

  const { t } = useContext(TranslationContext)

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div style={{ position: 'relative' }}>
          <div className={styles.ring}>
            <div className={styles.circle} />
          </div>
          <OpacityTransition items={items} index={index} delay={700} duration={300} className={styles.content} />
        </div>
        <Button
          onClick={onCancel}
          className={styles.cancel}
          title={t('TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP')}
          data-lang-id="TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL"
        >
          {t('TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL')}
        </Button>
      </div>
    </div>
  )
}

export default Countdown
