import React from 'react'
import cx from 'classnames'
import { IMaskInput } from 'react-imask'
import { IMaskInputProps } from 'react-imask/dist/mixin'

import SelectMenu, { SelectMenuEntry, SelectMenuProps } from 'components/base/SelectMenu'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import { SearchInput } from 'components/base/SearchInput/SearchInput'

import styles from './inputDropdownMenu.module.scss'

// interface Entry {
//   readonly id: string,
//   readonly name: string,
//   readonly entriesCount?: number
// }

type Props<T> = {
  onChange?: (value: string) => void,
  inputValue: string,
  placeholder?: string,
  inputProps?: Partial<IMaskInputProps>,
  disableInputIcon?: boolean,
  classes?: {
    input?: string,
    dropdown?: string,
    menu?: string,
    item?: string,
    paper?: string,
  }
} & SelectMenuProps<T>

function InputDropdownMenu<T extends SelectMenuEntry>({ placeholder = '', onChange = () => {},
  inputValue, classes = {}, inputProps = {}, disableInputIcon = false, ...props }: Props<T>) {
  return (
    <SelectMenu classes={classes} {...props}>
      <SearchInput
        onChange={onChange}
        onSearch={onChange}
        inputComponent={IMaskInput as unknown as React.ElementType<InputBaseComponentProps>}
        inputProps={{
          mask: Number,
          min: 0,
          value: `${inputValue}`,
          radix: '.',
          placeholder,
          scale: 1,
          ...inputProps,
        }}
        className={cx('search_input', styles.search, classes.input)}
        disableIcon={disableInputIcon}
      />
    </SelectMenu>
  )
}

export default InputDropdownMenu
