import { groupBy } from 'lodash'
import { PlaceholderAsset, TransitionAsset } from 'models/Asset'

export const splitAssetsByType = (allAssets, assetsToRemove, layers) => {
  const {
    placeholders = [],
    transitions = [],
    others = [],
  } = groupBy(
    allAssets
      .filter(asset => [].concat(assetsToRemove).map(a => a.id).includes(asset.id))
      // NOTE: sorting by layer index is important for UNDO of deletion multiple assets
      .sort((a, b) => {
        const layerIndexA = layers.findIndex(l => l.id === a.layerId)
        const layerIndexB = layers.findIndex(l => l.id === b.layerId)
        return layerIndexA - layerIndexB
      }),
    asset => {
      if (asset instanceof PlaceholderAsset) {
        return 'placeholders'
      }
      if (asset instanceof TransitionAsset) {
        return 'transitions'
      }
      return 'others'
    }
  )
  return { placeholders, transitions, others }
}
