import { assetToTimelineDragElement } from './assetToTimelineDragElement'

export const getMaxWidthOfMultipleDragRect = (scale, draggingAssets) => {
  const sortedDraggingAssets = draggingAssets.sort((a, b) => a.startTime - b.startTime)
  const minStartTime = sortedDraggingAssets?.[0]?.startTime
  const maxEndTime = sortedDraggingAssets?.[sortedDraggingAssets.length - 1]?.startTime
  + sortedDraggingAssets?.[sortedDraggingAssets.length - 1]?.duration

  return assetToTimelineDragElement(scale)({
    startTime: minStartTime,
    duration: maxEndTime - minStartTime,
  }).width
}
