import { clearAssetsSelection, selectAsset as selectAssetAction, updateAssetInPreview } from 'actions/layer'
import { MIN_IMAGE_SIDE_SIZE } from 'constant'
import { simulateMouseClick } from 'helpers/simulateMouseClick'
import { isEmpty } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Rect, Transformer } from 'react-konva'
import { approximateEqual } from '~/Util'
import { useAction, useBind, useChanged, useComposeConst } from '~/hooks'
import { getCoordsFromOffset, getDragBoundingFunc, getDragEndPatch, toRadians } from '../AssetsOverlay/lib'


export const EditingTransformer = ({
  asset,
  onlyMove,
  scale,
  boundingRect,
  size,
  rotateDisabled,
  layerRef,
  onTransformStart = () => { },
  onDragging = () => {},
  onTransforming = () => {},
}) => {
  let { width, height } = size

  width *= scale.x
  height *= scale.y
  const { settings, id, selected } = asset
  const { offset, rotation, position, flip, keepAspectRatio, position: anchor,
    outlineWidth = 0 } = settings
  const transformerRef = useRef()

  const singleClickTimeoutRef = useRef(false)

  const ref = useRef()

  const geometry = useChanged({ boundingRect, assetKonvaSize: { width, height } })

  const updateAsset = useAction(updateAssetInPreview, id)
  const updateAssetSettings = useComposeConst(updateAsset, value => ({
    settings: {
      ...value,
    },
  }))

  const onUpdateOverlay = useComposeConst(
    updateAssetSettings,
    useBind(getDragEndPatch)
  )
  const dragBoundFunc = getDragBoundingFunc(position, geometry)

  const selectAsset = useAction(selectAssetAction, asset.id)
  const clearSelection = useAction(clearAssetsSelection)

  const onOverlaySelect = () => {
    selectAsset()
  }

  const handleOverlayClick = e => {
    if (e.evt.detail === 1) {
      // Timeout for the waiting for doubleclick debounce
      singleClickTimeoutRef.current = setTimeout(() => {
        onOverlaySelect()
      }, 150)
    // Synthetic event
    } else if (e.evt.detail === 0) {
      onOverlaySelect()
    }
  }

  const handleOverlayDbClick = e => {
    // This check is important to prevent self-calling by the synthetic event
    if (e.evt.detail === 2) {
      clearTimeout(singleClickTimeoutRef.current)
      clearSelection({ closeSettings: false })
      // Timeout is useful to wait for the updating after clearing the selection
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        simulateMouseClick(layerRef.current.canvas._canvas, {
          clientX: e.evt.clientX,
          clientY: e.evt.clientY,
        })
      })
    }
  }

  useEffect(() => {
    if (selected && !onlyMove && ref?.current) {
      transformerRef.current.nodes([ ref.current ])
      transformerRef.current.getLayer().batchDraw()
    } else if (transformerRef.current) {
      transformerRef.current.nodes([])
      transformerRef.current.getLayer().batchDraw()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onlyMove, ref, selected, document.fullscreenElement ])

  const [ pos, setPos ] = useState({ x: 0, y: 0 })

  const handleDragEnd = e => {
    const { rotation } = e.currentTarget.attrs
    const { x, y } = e.currentTarget.getClientRect()

    onUpdateOverlay({
      x,
      y,
      rotation,
      scale,
      boundingRect,
      anchor,
      assetKonvaSize: { width: width + outlineWidth, height: height + outlineWidth },
    })
  }

  const offsetX = width ? width / 2 : undefined
  const offsetY = height ? height / 2 : undefined

  const hasTransformedRef = useRef(false)

  useEffect(() => {
    if (ref?.current && !isEmpty(size)) {
      // TODO: consider boxWidth for the correct rotation
      const newPos = getCoordsFromOffset(offset, { x: 0, y: 0 },
        { boundingRect, assetKonvaSize: { width, height }, rotation, outlineWidth, scale })
      setPos(newPos)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ boundingRect, settings, ref ])

  const getSizeAfterTransform = ({ target: node }) => {
    const { width, height, x, y, rotation } = node.attrs
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    return { size: {
      width: Math.abs(width * scaleX),
      height: Math.abs(height * scaleY),
    },
    pos: { x, y },
    rotation }
  }

  const handleTransformEnd = e => {
    hasTransformedRef.current = true
    const scaleX = e.target.scaleX()
    const scaleY = e.target.scaleY()
    const { size } = getSizeAfterTransform(e)
    const { width, height } = e.target.getClientRect({ skipStroke: true })
    let { x, y } = e.target.attrs

    x -= width / 2 - boundingRect.offsetX
    y -= height / 2 - boundingRect.offsetY

    const outline = outlineWidth / 2

    const r = e.target.attrs.rotation
    const rad = toRadians(r)
    x -= Math.abs(Math.cos(rad) * outline) + Math.abs(Math.sin(rad) * outline)
    y -= Math.abs(Math.cos(rad) * outline) + Math.abs(Math.sin(rad) * outline)

    e.target.scaleX(Math.sign(scaleX))
    e.target.scaleY(Math.sign(scaleY))

    // eslint-disable-next-line no-shadow
    const rotation = Math.round(e.target.rotation())

    const changedParams = { x, y, rotation, boundingRect }
    if (!approximateEqual(scaleX, 1) || !approximateEqual(scaleY, 1)) {
      changedParams.size = size
      // consider canvas scale
      changedParams.size.width = Math.round(changedParams.size.width / scale.x)
      changedParams.size.height = Math.round(changedParams.size.height / scale.y)
    }

    onUpdateOverlay({ ...changedParams, anchor, assetKonvaSize: { width, height } })
  }

  const handleDragMove = e => {
    // fix moving text
    const offset = onlyMove && selected ? 1 : 0

    onDragging(asset.id, { x: e.target.x() - offset, y: e.target.y() - offset })
    if (!asset.selected) {
      onOverlaySelect()
    }
  }

  const params = {
    ...pos,
    width,
    height,
    offsetX,
    offsetY,
    rotation,
    ...(flip?.horizontal ? { scaleX: -1, offsetX } : {}),
    ...(flip?.vertical ? { scaleY: -1, offsetY } : {}),
  }

  return (
    <If condition={!document.fullscreenElement}>
      <Rect
        {...params}
        ref={ref}
        id={id}
        strokeWidth={onlyMove && selected ? 2 : outlineWidth}
        stroke={onlyMove && selected ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 255, 0, 0)'}
        dragBoundFunc={dragBoundFunc}
        onDragEnd={handleDragEnd}
        draggable
        strokeScaleEnabled={false}
        onClick={handleOverlayClick}
        onDblClick={handleOverlayDbClick}
        onDragMove={handleDragMove}
      />

      <If condition={selected && !onlyMove}>
        <Transformer
          ref={transformerRef}
          rotationSnaps={[ 90, 180, 270, 360 ]}
          anchorFill="white"
          anchorStroke="dimgray"
          borderStroke="white"
          anchorCornerRadius={10}
          ignoreStroke
          padding={outlineWidth / 2}
          enabledAnchors={keepAspectRatio ? [
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ] : null}
          rotateAnchorOffset={15}
          rotateEnabled={!rotateDisabled}
          onTransformEnd={handleTransformEnd}
          onTransform={e => {
            onTransforming(asset.id, getSizeAfterTransform(e))
          }}
          onTransformStart={onTransformStart}
          boundBoxFunc={(oldBoundBox, newBoundBox) => {
            if (Math.abs(newBoundBox.width) < MIN_IMAGE_SIDE_SIZE
            || Math.abs(newBoundBox.height) < MIN_IMAGE_SIDE_SIZE) {
              return oldBoundBox
            }
            return newBoundBox
          }}
        />
      </If>
    </If>
  )
}
