// eslint-disable-next-line no-unused-vars
import Axios from 'axios'
import { TASK_STATUS } from '~/enums'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-api-key': 'eGSzWnAmpI2DTGtouoh1j63AlejMJGoV3KUdd4fM',
}

const version = 'stage'
const baseURL = `https://api.shotstack.io/edit/${version}`
const crossDomain = true
const config = { headers, baseURL, crossDomain }

export function createEditingTaskShotStack(task) {
  return Axios.post('/render', JSON.stringify(task), config)
}


function shotStackStatus2Solveig(status) {
  switch (status) {
    case 'queued':
      return TASK_STATUS.NEW
    case 'fetching':
      return TASK_STATUS.DOWNLOADING
    case 'rendering':
      return TASK_STATUS.RUNNING
    case 'saving':
      return TASK_STATUS.UPLOADING
    case 'done':
      return TASK_STATUS.COMPLETED
    case 'failed':
      return TASK_STATUS.ABORTED
    default:
      throw Error(`shotStackStatus2Solveig unknown status: ${status}`)
  }
}

export async function getEditingTaskStatusShotStack(id) {
  const { data } = await Axios.get(`/render/${id}`, config)
  if (data.message !== 'OK') {
    return {
      status: TASK_STATUS.ABORTED,
      error: { message: data.message },
    }
  }
  const { response } = data
  const status = shotStackStatus2Solveig(response.status)
  let error
  let outputUrls
  if (response.error) {
    error = { message: response.error }
  }
  if (response.url) {
    outputUrls = [ response.url ]
  }
  const progress = 0
  return { status, progress, error, outputUrls }
}
