import { createRef, useEffect, useState } from 'react'

const useTimelineVideoPlayerAssetsRefs = ({ renderingAssets }) => {
  const [ videoRefs, setVideoRefs ] = useState({})

  useEffect(() => {
    setVideoRefs(prevRefs => (renderingAssets
      .reduce((acc, asset) => ({ ...acc, [asset.id]: prevRefs[asset.id] ?? createRef() }), {})))
  }, [ renderingAssets ])

  return { videoRefs }
}

export default useTimelineVideoPlayerAssetsRefs
