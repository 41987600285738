import { changeLayerPosition } from '~/actions/timeline'
import { HistoryAction } from './HistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class LayerMovingAction extends HistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.LayerMovingAction, payload, ...params)
    this.index = payload.index
    this.layerId = payload.layerId
    this.prevIndex = payload.prevIndex
  }

  get layerId() {
    return this._layerId
  }

  set layerId(v) {
    this._layerId = v
  }

  get index() {
    return this._index
  }

  set index(v) {
    this._index = v
  }

  get prevIndex() {
    return this._prevIndex
  }

  set prevIndex(v) {
    this._prevIndex = v
  }

  * undo() {
    const { prevIndex, layerId } = this
    yield changeLayerPosition(layerId, prevIndex)
  }

  * redo() {
    const { index, layerId } = this
    yield changeLayerPosition(layerId, index)
  }

}
