import { TEXT_POSITION } from 'enums'
import { getFilledSize } from 'features/konva-shapes/getFilledSize'
import { getFramedSize } from 'features/konva-shapes/getFramedSize'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { useCompose, useLazyComposeConst } from '~/hooks'
import { getAspectRatedImageSize } from '~/Util/sourceFile'

// const EMPTY_OFFSET = { top: null, bottom: null, left: null, right: null }
const patchToSettings = patch => ({ settings: patch })

export default function useImageAssetFormHandlers(settings, update) {
  const updateSettings = useCompose(update, patchToSettings)
  const useHandler = useLazyComposeConst(updateSettings)

  const { width, height } = useSelector(getReferenceVideoAsset)
  const videoSize = { width, height }

  return {
    onChangeOutlineColor: useHandler(({ rgb }) => ({
      outlineColor: rgb,
    })),

    onChangeOutlineWidth: useHandler(x => ({
      outlineWidth: x,
    })),

    onChangeShadowColor: useHandler(({ rgb }) => ({
      shadowColor: rgb,
    })),

    onChangeShadowBlur: useHandler(x => ({
      shadowBlur: x,
    })),

    onChangeOpacity: useHandler(x => ({
      opacity: x,
    })),

    onChangeFlip: useHandler(({ vertical, horizontal }) => ({
      flip: { vertical, horizontal },
    })),

    onChangeRotation: useHandler(x => ({
      rotation: x,
    })),

    onChangePosition: useHandler(
      (offset, position) => {
        const patch = { offset }
        if (position !== undefined) {
          patch.position = position
        }
        return patch
      }
    ),

    onChangeSize: useHandler(size => ({ size })),

    onChangeKeepAspectRatio: useHandler((x, size) => {
      let patch = { keepAspectRatio: x }
      if (x) {
        const { aspectRatio } = settings
        patch = { ...patch, size: getAspectRatedImageSize({ ...size, aspectRatio }) }
      }
      return patch
    }),

    onClickFillToFrame: useHandler(rotation => ({
      position: TEXT_POSITION.MIDDLE_CENTER,
      offset: {
        top: null,
        left: null,
        bottom: null,
        right: null,
      },
      size: getFilledSize(settings.originalSize, rotation, videoSize),
    })),

    onClickFitToFrame: useHandler(rotation => ({
      position: TEXT_POSITION.MIDDLE_CENTER,
      offset: {
        top: null,
        left: null,
        bottom: null,
        right: null,
      },
      size: getFramedSize(
        settings.originalSize,
        rotation,
        videoSize
      ),
    })),
  }
}
