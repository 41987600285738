export const CLIP_FLAGS = {
  BLANK: 'blank',
  AUDIO_SILENCE: 'audio_silence',
}

export const XTL_TRANSITION_TYPES = {
  PIP: 'pip',
  MIX: 'mix',
}

export const TRANSITION_TRACK_TYPE = {
  ORIGINAL: 'original',
  OVERLAY: 'overlay',
}

export const TRACK_TYPE = {
  VIDEO: 'video',
  AUDIO: 'audio',
  VIDEO_AND_AUDIO: 'video_and_audio',
}

export const SHOTSTACK_CLIP_TYPE = {
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
}

export const SHOTSTACK_FIT_TYPE = {
  CROP: 'crop',
  COVER: 'cover',
  CONTAIN: 'contain',
  NONE: 'none',
}
