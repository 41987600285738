import React from 'react'
import produce from 'immer'
import { useLazyComposeConst } from '~/hooks'

function initState() {
  return {
    progress: 0,
    duration: 0,
  }
}

const reducer = produce(Object.assign)

export default function useMediaPreviewState() {
  const [ state, dispatch ] = React.useReducer(reducer, null, initState)
  const useHandler = useLazyComposeConst(dispatch)
  return [
    state,
    {
      onChangeProgress: useHandler(player => {
        const progress = player.getCurrentTime()
        return { progress }
      }),
      onChangeDuration: useHandler(duration => ({ duration })),
    },
  ]
}
