import Button from 'components/base/Button'
import Scrollbars from 'components/base/Scrollbars'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction, useDOMEvent, useModalDialog } from 'hooks'
import { useEditorActions } from 'hooks/useEditorActions'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  clearActiveProjectError as clearActiveProjectErrorAction,
  getProjects as getProjectsAction,
  setSearchValue as setSearchValueAction
} from '~/actions'
import { ReactComponent as ImageCreateArrow } from '~/assets/projects/create_arrow.svg'
import { ReactComponent as ImageCreateNewProject } from '~/assets/projects/create_new_project.svg'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { newProjectId, newProjectName } from '~/constant'

import { useRerenderInterval } from '~/hooks/useRerenderInterval'
import { selectIsLoading, selectProjects, selectProjectsTotalCount, selectSearchValue, selectShowBlankProjectInList } from '~/selectors/projectData'
import './Projects.scss'
import Import from './components/Import'
import UploadProvider from './components/Import/uploadContext'
import { ProjectItem } from './components/ProjectItem/ProjectItem'

export const Projects = () => {
  const getProjects = useAction(getProjectsAction.getProjects)
  const clearActiveProjectError = useAction(clearActiveProjectErrorAction.clearActiveProjectError)
  const setSearchValue = useAction(setSearchValueAction.setSearchValue)

  const projects = useSelector(state => selectProjects(state))
  const isLoading = useSelector(state => selectIsLoading(state))
  const searchValue = useSelector(state => selectSearchValue(state))
  const totalCount = useSelector(state => selectProjectsTotalCount(state))
  const [ activeProjectId, setActiveProjectId ] = useState(null)
  const showBlankProjectInList = useSelector(selectShowBlankProjectInList)
  const documentRef = useRef(document)

  const blankProject = { name: newProjectName, id: newProjectId, modifTime: Date.now() }

  const { onClickNewProject } = useEditorActions()

  const { active: importActive, open: importOpen, close: importClose } = useModalDialog()

  useEffect(() => {
    if (projects.length === 0) {
      getProjects(searchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ getProjects, searchValue ])

  useEffect(() => {
    clearActiveProjectError()
  }, [ clearActiveProjectError ])

  const onScrollProjectsList = values => {
    if (values.top === 1 && (totalCount === undefined || projects.length < totalCount)) {
      getProjects(searchValue)
    }
  }

  const onRemoveSelection = id => {
    if (activeProjectId === id) {
      setActiveProjectId(null)
    }
  }

  // NOTE: refresh time on projects
  useRerenderInterval(60_000)

  // NOTE: removes current selection by click on any place
  const onDocumentClick = () => {
    setActiveProjectId(null)
  }

  const onProjectClick = projectId => {
    if (activeProjectId !== projectId) {
      // NOTE: timeout for waiting onDocumentClick
      setTimeout(() => setActiveProjectId(projectId))
    }
  }

  const { t } = useContext(TranslationContext)

  useDOMEvent(documentRef, 'click', onDocumentClick)

  return (
    <div className="projects">
      <div className="projects__top-bar">
        <span className="projects__caption">{t('PROJECTS_TEXT_CAPTION')}</span>
        <div className="projects__top-bar__actions">
          <SearchInput className="projects__top-bar__actions__search" value={searchValue} onSearch={setSearchValue} />
          <If condition={__CFG__.PROJECT_IMPORT}>
            <Button
              className="projects__top-bar__actions__create-btn"
              border
              onClick={importOpen}
              title={t('PROJECTS_BTN_IMPORT_TOOLTIP')}
              data-lang-id="PROJECTS_BTN_IMPORT"
            >
              {t('PROJECTS_BTN_IMPORT')}
            </Button>
          </If>
          <Button
            className="projects__top-bar__actions__create-btn"
            onClick={onClickNewProject}
            title={t('PROJECTS_BTN_NEW_PROJECTS_TOOLTIP')}
            data-lang-id="PROJECTS_BTN_NEW_PROJECTS"
            primary
          >
            {t('PROJECTS_BTN_NEW_PROJECTS')}
          </Button>
        </div>
      </div>
      <Choose>
        <When condition={isLoading && !projects.length}>
          <div className="projects__central-block">
            <CircularProgress
              size={100}
              endless
              percents={80}
              text={searchValue ? `${t('PROJECTS_PROGRESS_TEXT_SEARCHING')}..` : `${t('PROJECTS_PROGRESS_TEXT_LOADING')}..`}
              transparent
            />
          </div>
        </When>
        <When condition={projects.length || showBlankProjectInList}>
          <Scrollbars trackHorizontalSize={0} onScrollFrame={onScrollProjectsList}>
            <If condition={showBlankProjectInList}>
              <ProjectItem
                key={blankProject.id}
                project={blankProject}
                selected={activeProjectId === blankProject.id}
                onClick={() => onProjectClick(blankProject.id)}
                removeSelection={() => onRemoveSelection(blankProject.id)}
              />
            </If>
            {projects.map(project => (
              <ProjectItem
                key={project.id}
                project={project}
                selected={activeProjectId === project.id}
                onClick={() => onProjectClick(project.id)}
                removeSelection={() => onRemoveSelection(project.id)}
              />
            ))}
          </Scrollbars>
        </When>
        <When condition={!projects.length}>
          <div className="projects__central-block">
            <Choose>
              <When condition={!!searchValue}>
                <div className="projects__nothing-found-text">{t('PROJECTS_TEXT_NOTHING_FOUND')}</div>
              </When>
              <Otherwise>

                <ImageCreateNewProject />
                <div className="projects__create-text">
                  {t('PROJECTS_TEXT_CREATE_A_NEW_PROJECT')}

                  <ImageCreateArrow className="projects__create-text__arrow" />
                </div>
                <Button
                  className="projects__central-create-btn"
                  onClick={onClickNewProject}
                  title={t('PROJECTS_BTN_NEW_PROJECT')}
                  data-lang-id="PROJECTS_BTN_NEW_PROJECT"
                  primary
                >
                  {t('PROJECTS_BTN_NEW_PROJECT')}
                </Button>

              </Otherwise>
            </Choose>
          </div>
        </When>
      </Choose>
      <If condition={importActive}>
        <UploadProvider>
          <Import onClose={importClose} />
        </UploadProvider>
      </If>
    </div>
  )
}
