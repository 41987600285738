import { filterHistoryAsset } from '~/helpers/filterHistoryAsset'
import { HistoryAction } from './HistoryAction'

export class AssetHistoryAction extends HistoryAction {

  constructor(name, actionPayload, ...params) {
    super(name, actionPayload, ...params)
    const { asset, assets } = actionPayload
    this._asset = asset ? filterHistoryAsset(asset) : null
    this._assets = assets?.map(filterHistoryAsset) || []
  }

  get asset() {
    return this._asset
  }

  set asset(asset) {
    this._asset = asset
  }

  get assets() {
    return this._assets
  }

  set assets(assets) {
    this._assets = assets
  }

  getOptimizedAsset(selectingCallback) {
    const { id } = this.asset
    return { id, ...selectingCallback(this.asset) }
  }

}
