import ProjectAsset from '~/models/ProjectData/ProjectAsset'

/**
 * @memberOf ProjectData
 * @extends ProjectData.ProjectAsset
 *
 */

class ProjectTransitionAsset extends ProjectAsset {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, version) {
    super(jsonData)

    const { leftVideoAssetId, rightVideoAssetId } = jsonData

    this._leftVideoAssetId = leftVideoAssetId
    this._rightVideoAssetId = rightVideoAssetId
  }

  /**
  * @returns {string}
  */
  get leftVideoAssetId() {
    return this._leftVideoAssetId
  }

  /**
   * @returns {string}
   */
  get rightVideoAssetId() {
    return this._rightVideoAssetId
  }

}

export default ProjectTransitionAsset
