import { TranslationContext } from 'contexts/TranslationContext'
import { get1LevelUrl } from 'helpers/get1LevelUrl'
import React, { useContext } from 'react'
import laptop from '../../assets/laptop.png'
import logo from '../../assets/logo.png'
import './MobilePlug.scss'

export default function MobilePlug() {
  const { t } = useContext(TranslationContext)
  return (
    <div className="plug-wrapper">
      <div className="plug">
        <img src={logo} alt="logo" draggable="false" className="plug__logo" />
        <h1 className="plug__head">{t('MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR')}</h1>

        <h2 className="plug__subhead">
          {t('MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED')}
        </h2>

        <h2 className="plug__subhead">
          Tablets and mobile versions are on the way!
        </h2>
        <p className="plug__info">
          Right now you can try our TriMP4 app for Android.
        </p>
        <a
          href="https://www.solveigmm.com/en/products/trimp4/"
          className="plug__btn"
        >
          Try TriMP4
        </a>
        <p className="plug__info" />
        <img
          src={laptop}
          alt="laptop"
          draggable="false"
          className="plug__image"
        />
        <p className="plug__info">{t('MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC')}</p>
        <a href={get1LevelUrl()} className="plug__btn">
          {t('MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB')}
        </a>
      </div>
    </div>
  )
}
