import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TimelineScrollPositionContext } from '~/components/Timeline/ScrollPositionContext'
import { TimelineLeftOffsetContext } from '~/components/Timeline/TimelineLeftOffsetContext'
import { TimelineTopOffsetContext } from '~/components/Timeline/TimelineTopOffsetContext'
import { useLatestRef, useStatic } from '~/hooks'
import { getLayers, selectTimelineScale } from '~/selectors'
import { pixel2Time } from '~/Util'
import { getLayerIndexFromY } from '~/Util/timeline-coords'

export const useMouseItemEvents = ({
  onMouseMove = () => {},
  onMouseUp = () => {},
  onMouseDown = () => {},
  isPressedInitial = false,
}) => {
  const [ isPressed, setIsPressed ] = useState(isPressedInitial)
  const [ mouseDownTimePosition, setMouseDownTimePosition ] = useState(null)

  const refMouseDownTimePosition = useStatic(mouseDownTimePosition)

  const layers = useSelector(getLayers)
  const scale = useSelector(selectTimelineScale)
  const timelineLeftOffset = useContext(TimelineLeftOffsetContext)
  const timelineTopOffset = useContext(TimelineTopOffsetContext)
  const scroll = React.useContext(TimelineScrollPositionContext)
  const refTimelineScrollPosition = useLatestRef(scroll)

  const getTimeFromMouseEvent = React.useCallback(
    event => {
      const position = event.clientX
      - timelineLeftOffset
      + refTimelineScrollPosition.current.scrollLeft
      return Math.max(0, pixel2Time(position, scale))
    },
    [ scale, refTimelineScrollPosition, timelineLeftOffset ]
  )

  const handleMouseDown = e => {
    // e.stopPropagation()
    // NOTE: preventDefault is usefl to prevent default browser mouse dragging (images/links/texts dragging)
    e.preventDefault()
    setIsPressed(true)
    setMouseDownTimePosition(getTimeFromMouseEvent(e))
    onMouseDown(e)
  }

  useEffect(() => {
    const mouseMoveListener = e => {
      if (isPressed && refMouseDownTimePosition.current !== null) {
        const timePosition = getTimeFromMouseEvent(e)
        const layerIndex = getLayerIndexFromY({
          y: e.clientY + refTimelineScrollPosition,
          topOffset: timelineTopOffset,
          layersLength: layers.length,
        })
        onMouseMove({
          timePosition,
          layerIndex,
          mouseDownTimePosition: refMouseDownTimePosition.current,
        })
      }
      if (refMouseDownTimePosition.current === null) {
        setMouseDownTimePosition(getTimeFromMouseEvent(e))
      }
    }

    const onMouseUpListener = e => {
      const timePosition = getTimeFromMouseEvent(e)
      setIsPressed(false)
      onMouseUp({ timePosition })
    }

    if (isPressed) {
      document.body.addEventListener('mousemove', mouseMoveListener)
      document.body.addEventListener('mouseup', onMouseUpListener)
    }
    return () => {
      if (isPressed) {
        document.body.removeEventListener('mousemove', mouseMoveListener)
        document.body.removeEventListener('mouseup', onMouseUpListener)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isPressed ])

  return { onMouseDown: handleMouseDown }
}
