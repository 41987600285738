import * as ActionTypes from '~/actions/ActionTypes'

/**
 * @param {boolean} saveEmpty
 */
export const allowSaveEmpty = (saveEmpty = true) => async dispatch => {
  await dispatch({
    type: ActionTypes.PROJECTS_ALLOW_SAVE_EMPTY,
    payload: { saveEmpty },
  })
}
