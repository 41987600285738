class GoogleAnalyticsService {

  gaSendPageView = () => {
    this.gaCondition('event', 'page_view')
  }

  gaSetPage = page => {
    this.gaCondition('set', 'page_path', page)
  }

  gaSendEvent = (...args) => {
    this.gaCondition('event', ...args)
  }

  gaCondition = (...params) => {
    if (window.gtag) window.gtag(...params)
  }

}

export const AnalyticsService = new GoogleAnalyticsService()
