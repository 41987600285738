import React from 'react'
import { useDrag } from 'react-dnd'
import { DRAGNDROP_TYPE } from '~/enums'
import TransitionAsset from './TransitionAsset'
import './LayerItem.scss'

const DragTransitionContainer = props => {
  const { asset } = props

  const [{ isDragging }, drag ] = useDrag({
    canDrag: () => asset.draggable,
    item: {
      type: DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET,
      transitionType: asset.type,
      id: asset.id,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <TransitionAsset {...props} connectDragSource={drag} isDragging={isDragging} />
  )
}

export default DragTransitionContainer
