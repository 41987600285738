import Menu from '@material-ui/core/Menu'
import { ReactComponent as ExpandIcon } from 'assets/settings/dropdown_expand.svg'
import { ReactComponent as ResetIcon } from 'assets/settings/dropdown_reset.svg'
import cx from 'classnames'
import MenuItem from 'components/base/MenuItem'
import Scrollbars from 'components/base/Scrollbars'
import React, { MouseEvent, ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react'

import Divider from 'components/base/Divider/Divider'

import styles from './selectMenu.module.scss'

export interface SelectMenuEntry {
  id: string,
  name: string,
  entriesCount?: number
}

export type SelectMenuProps<T> = {
  items: Readonly<Array<T>>,
  onSelect?: (item: string) => void,
  value: string,
  defaultValue?: string,
  disableAll?: boolean,
  autoWidth?: boolean,
  classes?: {
    dropdown?: string,
    menu?: string,
    item?: string,
    paper?: string,
  },
  children?: ReactNode
}

const noop = () => {}

const allEntry: SelectMenuEntry = { id: 'all', name: 'All', entriesCount: 0 }

function SearchMenu<T extends SelectMenuEntry>({ items = [],
  onSelect = noop,
  value,
  defaultValue = 'all',
  disableAll = false,
  autoWidth = false,
  classes = {},
  children }: SelectMenuProps<T>) {
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement| null>(null)

  const [ inputWidth, setInputWidth ] = useState(0)

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      setInputWidth(dropdownRef.current.clientWidth)
    }
  }, [])

  function handleSelect(state: string) {
    onSelect(state)
    handleClose()
  }

  function handleClose() {
    // setSearch('')
    setAnchorEl(null)
  }

  const allItems = useMemo(() => disableAll ? items
  // eslint-disable-next-line react-hooks/exhaustive-deps
    : [ (allEntry as T), ...items ], [ items, disableAll ])

  const selectedName = useMemo(() => allItems.find(item => item.id === value)?.name || '',
    [ allItems, value ])


  const handleOpenClick = (e: MouseEvent<HTMLDivElement>) => {
    // setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <div
        className={cx(styles.dropdown, classes.dropdown)}
        onClick={handleOpenClick}
        ref={dropdownRef}
      >
        <span>{selectedName}</span>
        <div className={styles.controls}>
          {value !== defaultValue
          && (
          <div
            onClick={() => { handleSelect(defaultValue) }}
            className={styles.reset}
          >
            <ResetIcon />
          </div>
          )}
          <div
            className={cx(styles.arrow, { [styles.opened]: anchorEl })}
          >
            <ExpandIcon />
          </div>
        </div>
      </div>
      <Menu
        anchorEl={dropdownRef.current}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ list: cx(styles.menuContainer, classes.menu), paper: classes.paper }}
        getContentAnchorEl={null}
        PaperProps={autoWidth ? {
          style: {
            width: inputWidth,
          },
        } : {}}
      >
        {children}
        <Divider classes={{ root: styles.divider }} />
        <Scrollbars renderTrackVerticalClassName={styles.verticalTrack}>
          {items.length !== 0 ? items.map(item => (
            <React.Fragment key={item.id}>
              {item.id !== 'null' && (
              <MenuItem
                className={cx(styles.menuItem, classes.item)}
                selected={value === item.id}
                onClick={() => handleSelect(item.id)}
              >
                <div>
                  {item.name}
                </div>
                <div className={styles.count}>
                  {item.entriesCount === 0 ? '' : item.entriesCount}
                </div>
              </MenuItem>
              )}
            </React.Fragment>
          ))
            : (
              <MenuItem
                className={cx(styles.menuItem, styles.noResults)}
              >
                No results found
              </MenuItem>
            )}
        </Scrollbars>
      </Menu>
    </>
  )
}

export default SearchMenu
