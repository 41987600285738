import { format, differenceInHours, differenceInMinutes } from 'date-fns'
import * as locale from 'date-fns/locale'

export const FormattedLatestDate = ({ date = new Date(), prefix = '' }) => {
  const dateObj = new Date(date)
  const hoursDifference = differenceInHours(new Date(), dateObj)

  let formatted = format(dateObj, 'P p', { locale: locale[navigator.language.replace('-', '') || navigator.userLanguage] })
  if (hoursDifference < 24) {
    if (hoursDifference >= 1) {
      formatted = `${hoursDifference} hours ago`
    } else {
      const minutesDifference = differenceInMinutes(new Date(), dateObj)
      formatted = minutesDifference === 0 ? 'just now' : `${minutesDifference} minutes ago`
    }
  }
  return `${prefix} ${formatted}`.trim()
}
