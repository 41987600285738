/* eslint-disable max-len */
import React from 'react'

function CheckedIcon(props: React.SVGProps<SVGSVGElement>) {
  const { stroke = 'white', strokeWidth = '2', width = '16', height = '16', fill = '#576FE9',
    viewBox = '0 0 16 16' } = props

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 3.2C0 2.07989 0 1.51984 0.217987 1.09202C0.409734 0.715695 0.715695 0.409734 1.09202 0.217987C1.51984 0 2.07989 0 3.2 0H12.8C13.9201 0 14.4802 0 14.908 0.217987C15.2843 0.409734 15.5903 0.715695 15.782 1.09202C16 1.51984 16 2.07989 16 3.2V12.8C16 13.9201 16 14.4802 15.782 14.908C15.5903 15.2843 15.2843 15.5903 14.908 15.782C14.4802 16 13.9201 16 12.8 16H3.2C2.07989 16 1.51984 16 1.09202 15.782C0.715695 15.5903 0.409734 15.2843 0.217987 14.908C0 14.4802 0 13.9201 0 12.8V3.2Z" fill={fill} />
      <path d="M2.6665 7.49206L6.33317 11.3333L13.3332 4" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>

  )
}

export default CheckedIcon
