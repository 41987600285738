let audioContext: AudioContext | null
let gainNode: GainNode | null
let audioSource: MediaStreamAudioSourceNode | null

export const createAudioContext = () => {
  audioContext = new AudioContext()
}

export const closeAudioContext = () => {
  audioContext?.close()
  gainNode = null
  audioContext = null
  audioSource = null
}

export const connectAudioContext = async (inputStream: MediaStream, agc = true) => {
  if (!audioContext) throw Error()
  audioSource = audioContext.createMediaStreamSource(inputStream)
  const audioDestination = audioContext.createMediaStreamDestination()

  if (!agc) {
    gainNode = audioContext.createGain()
    audioSource.connect(gainNode).connect(audioDestination)
  } else {
    gainNode?.disconnect()
    gainNode = null
    audioSource.connect(audioDestination)
  }

  // https://developer.chrome.com/blog/autoplay/
  await audioContext.resume()
  return audioDestination.stream
}

export const setGain = (value: number) => {
  if (!gainNode) return
  gainNode.gain.value = value
}

export const getGain = () => gainNode?.gain.value || 1

/**
 * https://github.com/common-tater/volume-meter/blob/master/index.js
 * https://www.oreilly.com/library/view/web-audio-api/9781449332679/
 * @return {number} currentOriginalVolumeLevelInPercents
 */
export const volumeLevelGraph = (handleChange: (value: number) => void) => {
  const analyserNode = audioContext?.createAnalyser()
  if (!analyserNode || !audioSource) return () => {}
  audioSource.connect(analyserNode)

  const pcmData = new Uint8Array(analyserNode.fftSize)
  let last = 0
  const onFrame = () => {
    analyserNode.getByteTimeDomainData(pcmData)

    const range = getDynamicRange(pcmData) * (Math.E - 1)
    let next = Math.floor(Math.log1p(range) * 100)
    const tween = next > last ? 1.618 : 1.618 * 3
    last += (next - last) / tween
    next = last

    // next is measured in percents 0 - 100
    handleChange(next)
  }

  const id = window.setInterval(onFrame, 200)
  return () => window.clearInterval(id)
}

function getDynamicRange(buffer: Uint8Array) {
  const len = buffer.length
  let min = 128
  let max = 128

  for (let i = 0; i < len; i++) {
    const sample = buffer[i]
    if (sample < min) min = sample
    else if (sample > max) max = sample
  }

  return (max - min) / 255
}
