import { isArray } from 'lodash'

/**
 * @param {TransitionAsset | TransitionAsset[]} transition
 * @param {Asset[]} allAssets
 * @returns {string}
 */
export const addRelatedAssets = (transition, allAssets, others) => {
  const relatedAssets = []
  if (!isArray(transition)) {
    const { leftVideoAssetId, rightVideoAssetId } = transition
    relatedAssets.push(leftVideoAssetId)
    relatedAssets.push(rightVideoAssetId)
  } else {
    transition.forEach(trans => {
      const { leftVideoAssetId, rightVideoAssetId } = trans
      if (!relatedAssets.includes(leftVideoAssetId)) {
        relatedAssets.push(leftVideoAssetId)
      }
      if (!relatedAssets.includes(rightVideoAssetId)) {
        relatedAssets.push(rightVideoAssetId)
      }
    })
  }
  const othersId = others.map(asset => asset.id)
  const finalAssets = Array.from(new Set([ ...relatedAssets, ...othersId ]))
  return allAssets.filter(asset => finalAssets.includes(asset.id))
}
