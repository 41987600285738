export class ActivationError {

  constructor(title: string, message: string, canRetry = false) {
    this.title = title
    this.message = message
    this.canRetry = canRetry
  }

  message: string;
  title: string;
  canRetry?: boolean;
  retry?: () => void;

}

export class ConnectionActivationError extends ActivationError {}
export class MultipleUsageActivationError extends ActivationError {}
