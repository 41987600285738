/* eslint-disable require-yield */
import { all, takeEvery } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import { AnalyticsService } from '~/services/AnalyticsService/AnalyticsService'

function* addHistoryAction({ payload }) {
  const { historyAction, sync } = payload
  if (sync) {
    AnalyticsService.gaSendEvent(historyAction.name)
  }
}

function* watchAll() {
  yield all(__CFG__.TIMELINE.UNDO_REDO ? [
    takeEvery([ ActionTypes.ACTIONS_ADD_HISTORY_ACTION ], addHistoryAction),
  ] : [])
}

export default watchAll
