import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSliderOverlayAssets, getLayersIndexes } from 'selectors'
import useTimelineVideoPlayerAssetsProps from './useTimelineVideoPlayerAssetsProps'
import useTimelineVideoPlayerAssetsRefs from './useTimelineVideoPlayerAssetsRefs'
import { VideoAsset } from '~/models/Asset'

const useTimelineVideoPlayerAssets = ({
  playingMediaAssets,
  backgroundAssets,
  composedMediaAssets,
  assetToPlayerId,
  onReady,
  onMounted,
  onBufferingProgress,
  transitionVideoAsset,
  isPlaying,
  rect,
  restAggregatedPlayerProps,
}) => {
  const sliderOverlayAssets = useSelector(selectSliderOverlayAssets)
  const layersIndexes = useSelector(getLayersIndexes)

  const renderingAssets = useMemo(() => (
    playingMediaAssets.concat(backgroundAssets).concat(composedMediaAssets)
      .sort((a, b) => a.startTime - b.startTime)),
  [ playingMediaAssets, backgroundAssets, composedMediaAssets ])

  const overlayAssets = sliderOverlayAssets
    .concat(playingMediaAssets.filter(a => a instanceof VideoAsset))
    .filter(asset => asset.width !== 0 && asset.height !== 0)
    .sort((a, b) => layersIndexes.get(b.layerId) - layersIndexes.get(a.layerId))

  const { videoRefs } = useTimelineVideoPlayerAssetsRefs({ renderingAssets })

  const { getTimelineAssetVideoPlayerProps } = useTimelineVideoPlayerAssetsProps({
    playingMediaAssets,
    assetToPlayerId,
    rect,
    onReady,
    onMounted,
    onBufferingProgress,
    transitionVideoAsset,
    videoRefs,
    isPlaying,
    restAggregatedPlayerProps,
  })

  return { renderingAssets, videoRefs, getTimelineAssetVideoPlayerProps, overlayAssets }
}

export default useTimelineVideoPlayerAssets
