export const keyCodeToKey = code => {
  const keyCodes = {
    Semicolon: ';',
    Equal: '=',
    Comma: ',',
    Minus: '-',
    Period: '.',
    Slash: '/',
    Backquote: '`',
    BracketLeft: '[',
    Backslash: '\\',
    BracketRight: ']',
    Quote: '\'',
    ArrowRight: '→',
    ArrowLeft: '←',
    ArrowDown: '↓',
    ArrowUp: '↑',
  }

  return code.split(' + ').map(el => keyCodes[el] || el.replace('Key', '').replace('Digit', '')).join(' + ')
}
