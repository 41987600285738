import { TEXT_POSITION, TEXT_TRANSFORM } from '~/enums'
import { assignPatch } from '~/hooks'
import MediaAsset from './MediaAsset'

const DEFAULT_TEXT_DURATION = 100_000_000

/**
 * @typedef {object} RGBColor
 * @property {number} r
 * @property {number} g
 * @property {number} b
 * @property {number} a
 */

/**
 * @typedef {object} FontStyle
 * @property {boolean} bold
 * @property {boolean} italic
 * @property {boolean} underline
 */

/**
 * @typedef {object} TextAssetPositionOffset
 * @property {null|number} left
 * @property {null|number} top
 * @property {null|number} right
 * @property {null|number} bottom
*/

/**
 * @typedef {object} TextAssetSettings
 * @property {string} text
 * @property {RGBColor} color
 * @property {RGBColor} background
 * @property {RGBColor} outlineColor
 * @property {string} font
 * @property {number} fontSize
 * @property {FontStyle} fontStyle
 * @property {TextTransform} textTransform
 * @property {string} align
 * @property {string} position
 * @property {TextAssetPositionOffset} offset
 * @property {number} outlineWidth
 * @property {number} shadowBlur
 * @property {RGBColor} shadowColor
 */

/**
 * @memberOf Assets
 * @extends {MediaAsset}
 *
 * Extend MediaAsset, because text can be animated and thus behave like video.
 */
class TextAsset extends MediaAsset {

  constructor(data = {}) {
    const {
      duration = DEFAULT_TEXT_DURATION,
      startTime = 0,

      text = 'TEXT',
      description = 'Text',
      settings = null,
      type = 'text',
      ...rest
    } = data

    super({
      duration,
      startTime,
      name: description,

      ...rest,
    })

    this._description = description
    this._type = type

    const {
      color = { r: 255, g: 255, b: 255, a: 1 },
      background = { r: 255, g: 255, b: 255, a: 0 },
      outlineColor = { r: 87, g: 111, b: 233, a: 1 },
      font = 'Arial',
      fontSize = 24,
      fontStyle = { bold: false, italic: false, underline: false },
      textTransform = TEXT_TRANSFORM.NONE,
      position = TEXT_POSITION.MIDDLE_CENTER,
      offset = { top: null, bottom: null, left: null, right: null },
      outlineWidth = 2,
      shadowBlur = 4,
      shadowColor = { r: 255, g: 255, b: 255, a: 0.4 },
      shadowOffset = { x: 2, y: 2 },
      shadowOpacity = 0.4,
      align = 'center',
      disableOutline = true,
      disableShadow = true,
      rotation = 0,
    } = settings || {}

    this._settings = {
      text,
      color,
      background,
      outlineColor,
      font,
      fontSize,
      fontStyle,
      textTransform,
      position,
      offset,
      outlineWidth,
      shadowBlur,
      shadowColor,
      shadowOffset,
      shadowOpacity,
      align,
      disableOutline,
      disableShadow,
      rotation,
    }

    this._maxDuration = Infinity
  }

  /**
   * @returns {string}
   */
  get text() {
    const { text, textTransform } = this._settings
    switch (textTransform) {
      case TEXT_TRANSFORM.NONE:
        return text

      case TEXT_TRANSFORM.UPPERCASE:
        return text.toUpperCase()

      case TEXT_TRANSFORM.LOWERCASE:
        return text.toLowerCase()

      case TEXT_TRANSFORM.CAPITALIZE:
        return text.replace(
          /(^|\s)([a-z])/igm,
          (_, prefix, letter) => `${prefix}${letter.toUpperCase()}`
        )

      default:
        throw new Error(`Unknown textTransform: ${textTransform}`)
    }
  }

  /**
   * @returns {string}
   */
  get type() {
    return this._type
  }

  /**
   * @param {string} type
   */
  set type(type) {
    this._type = type
  }

  // ---

  /**
   * @returns {string}
   */
  get description() {
    return this._description
  }

  /**
   * @param {string} description
   */
  set description(description) {
    this._description = description
  }

  // ---

  /**
   * @returns {TextAssetSettings}
   */
  get settings() {
    return this._settings
  }

  /**
   * Same behavior  as `setState` in React:
   * assigned value is merged over current one.
   *
   * @param {object} patch
   */
  set settings(patch) {
    this._settings = assignPatch(this._settings, patch)
  }

  // ---

  /**
   * @returns {string|null}
   */
  get fileId() {
    return super.fileId
  }

  /**
   *
   * @param {string} id
   */
  set fileId(id) {
    this._fileId = id
  }

}

export default TextAsset
