import MSP from 'components/ExtendedReactPlayer/MediaServerPlayer/msp'

export const initMediaServerPlayer = (ref, options) => {
  const { src, onDuration } = options
  const player = new MSP()
  if (ref.current != null) {
    player.attachMedia(ref.current, src)
  }
  player.on(MSP.Events.DURATION, duration => {
    onDuration(duration)
  })
  player.on(MSP.Events.ERROR, msg => {
    console.error(msg)
  })
  return player
}
