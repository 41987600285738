/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'
import { Tooltip } from '@material-ui/core'
import { mediaDirectoryMoveBackward, mediaDirectoryMoveForward, openMediaDirectory } from '~/actions/folders'
import { ReactComponent as ArrowLeft } from '~/assets/media/nav_arr_left.svg'
import { ReactComponent as ArrowRight } from '~/assets/media/nav_arr_right.svg'
import { useAction } from '~/hooks'
import { useSize } from '~/hooks/useSize'
import { SOURCE_FILE_TYPES } from '~/enums'
import './MediaNavigation.scss'
import { NavigationArrow } from './NavigationArrow'
import { NavigationDir } from './NavigationDir'
import { useCollapsedDirs } from './useCollapsedDirs'

const ROOT_PATH = 'Media'

export const MediaNavigation = ({ navigationDirs = [], isForwardDisabled, isBackwardDisabled }) => {
  const pathRef = useRef()

  const size = useSize(pathRef)
  const pathTotalWidth = size?.width

  const filteredNavDirs = useCollapsedDirs({ navigationDirs, pathTotalWidth, rootPath: ROOT_PATH })

  const moveForward = useAction(mediaDirectoryMoveForward, SOURCE_FILE_TYPES.MEDIA)
  const moveBackward = useAction(mediaDirectoryMoveBackward, SOURCE_FILE_TYPES.MEDIA)
  const switchDirectory = useAction(openMediaDirectory, SOURCE_FILE_TYPES.MEDIA)

  const handleClickMediaNavigation = () => {
    switchDirectory(null)
  }

  const divider = <span className="media-navigation__divider">/</span>

  const fullPath = ([ ROOT_PATH ].concat(navigationDirs.map(dir => dir.name)).join(' / '))

  const renderDirPath = () => (
    <>
      {/* Root path */}
      <NavigationDir
        directory={{ name: ROOT_PATH }}
        onClick={handleClickMediaNavigation}
      />
      {divider}
      {/* First path */}
      <NavigationDir
        directory={navigationDirs[0]}
        onClick={switchDirectory}
        disabled={!filteredNavDirs.length}
      />
      {/* Three dots if more or simple divider */}
      <Choose>
        <When condition={navigationDirs.length !== filteredNavDirs.length + 1}>
          <Tooltip
            placement="bottom-start"
            classes={{
              popper: 'tooltip',
            }}
            title={fullPath}
          >
            <span className="media-navigation__divider">...</span>
          </Tooltip>
        </When>
        <Otherwise>
          <If condition={filteredNavDirs.length}>
            {divider}
          </If>
        </Otherwise>
      </Choose>
      {/* Rest paths */}
      {filteredNavDirs.map((dir, index, arr) => (
        <span key={`dir-${index}`}>
          <NavigationDir
            directory={dir}
            onClick={switchDirectory}
            disabled={index === arr.length - 1}
          />
          <If condition={index !== arr.length - 1}>
            {divider}
          </If>
        </span>
      ))}
    </>
  )

  return (
    <div className="media-navigation">
      <div className="media-navigation__arrows">
        <NavigationArrow disabled={isBackwardDisabled} onClick={moveBackward}>
          <ArrowLeft />
        </NavigationArrow>
        <NavigationArrow disabled={isForwardDisabled} onClick={moveForward}>
          <ArrowRight />
        </NavigationArrow>
      </div>
      <div className="media-navigation__path" ref={pathRef}>
        {renderDirPath()}
      </div>
    </div>
  )
}
