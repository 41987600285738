import { calculateBufferedSecondsInDiapasons } from './calculateBufferedSecondsInDiapasons'
import { getParsedBufferedDiapasons } from './getParsedBufferedDiapasons'

export const getVideoPlayerProgress = (videoPlayer, expectedBufferTimeS = 10) => {
  const requiredS = Math.min(
    expectedBufferTimeS,
    videoPlayer.duration - videoPlayer.currentTime
  )

  const boundBufferS = Math.min(videoPlayer.currentTime + requiredS, videoPlayer.duration)
  const bufferedDiapasons = getParsedBufferedDiapasons(videoPlayer)
  const bufferedS = calculateBufferedSecondsInDiapasons(
    bufferedDiapasons, videoPlayer.currentTime, boundBufferS
  )

  return { progress: bufferedS / requiredS * 100, bufferedDiapasons }
}
