import React from 'react'
import Input from 'components/base/Input'
import { XIcon } from 'components/base/XIcon/XIcon'
import './InputClearable.scss'
import classNames from 'classnames'

type props = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInput?: () => void;
  value?: string;
  placeholder?: string;
  className?:string;
}

export const InputClearable = (
  { onChange = () => { }, value, onClearInput = () => { }, placeholder, className }: props
) => (
  <div className={classNames('input-clearable__input', className)}>
    <Input placeholder={placeholder} onChange={onChange} value={value} />
    {!!value && (
      <div className="input-clearable__x-icon--input" onClick={onClearInput}>
        <XIcon />
      </div>
    )}
  </div>
)
