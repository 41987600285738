/* eslint-disable no-unused-vars */
import { isAssetFitsPreviewFrame } from 'helpers/isAssetFitsPreviewFrame'
import produce from 'immer'
import { flatten, groupBy, sortBy } from 'lodash'
import { RangeTools } from '~/Util'
import Asset, { AudioAsset, ImageAsset, MediaAsset, TextAsset, TransitionAsset, VideoAsset } from '~/models/Asset'

/**
 * @param {Asset} lowerClip
 * @param {Asset} upperClip
 * @returns {Asset[]}
 */
function handleClipsIntersection(lowerClip, upperClip) {
  const ret = []
  if (RangeTools.isInside(lowerClip, upperClip)) {
    // Before:
    // |     upper     |
    //     | lower |
    // After:
    // |     upper     |
    if (lowerClip.hasAudio) {
      ret.push(Object.assign(
        new AudioAsset(),
        lowerClip.clone(undefined, { keepSourceId: true })
      ))
    }
  } else if (RangeTools.isExceedsBoth(lowerClip, upperClip)) {
    // Before:
    //            | upper |
    // |          ! lower !           |
    // After:
    // | low-left | upper | low-right |
    ret.push(lowerClip.clone(clip => clip.truncateRightAt(upperClip.startTime),
      { keepSourceId: true }))
    if (lowerClip.hasAudio) {
      ret.push(Object.assign(new AudioAsset(),
        lowerClip.clone(clip => clip.truncateLeftAt(upperClip.startTime)
          .truncateRightAt(upperClip.endTime), { keepSourceId: true })))
    }
    ret.push(lowerClip.clone(clip => clip.truncateLeftAt(upperClip.endTime),
      { keepSourceId: true }))
  } else if (RangeTools.isExceedsLeft(lowerClip, upperClip)) {
    // Before:
    //           |  upper  |
    // |  lower  !xxx|
    // After:
    // |  lower  |  upper  |

    ret.push(lowerClip.clone(clip => clip.truncateRightAt(upperClip.startTime),
      { keepSourceId: true }))
    if (lowerClip.hasAudio) {
      ret.push(Object.assign(new AudioAsset(),
        lowerClip.clone(clip => clip.truncateLeftAt(upperClip.startTime), { keepSourceId: true })))
    }
  } else if (RangeTools.isExceedsRight(lowerClip, upperClip)) {
    // Before:
    // |  upper  |
    //       |xxx!  lower  |
    // After:
    // |  upper  |  lower  |

    ret.push(lowerClip.clone(clip => clip.truncateLeftAt(upperClip.endTime),
      { keepSourceId: true }))
    if (lowerClip.hasAudio) {
      ret.push(Object.assign(new AudioAsset(),
        lowerClip.clone(clip => clip.truncateRightAt(upperClip.endTime), { keepSourceId: true })))
    }
  } else {
    ret.push(lowerClip)
  }
  return ret
}

/**
 * @param {Asset[]} layer
 * @param {Asset} upperAsset
 */
function iterateLowerLayer(layer, upperAsset, previewParams, refAsset) {
  let i = 0
  // VideoAsset, TextAsset, ImageAsset can't be used by rendering engine if it under VideoAsset
  const assetTypesToHandle = [ VideoAsset, TextAsset, ImageAsset ]
  while (i < layer.length) {
    const lowerAsset = layer[i]
    if (!assetTypesToHandle.some(klass => lowerAsset instanceof klass)) {
      i += 1
      continue
    }
    const result = upperAsset.settings.isChromaKeyEnabled
      || !isAssetFitsPreviewFrame(upperAsset, previewParams, refAsset) ? [ lowerAsset ]
      : handleClipsIntersection(lowerAsset, upperAsset)

    // Lower clip is invisible and should be removed from layer.
    if (result.length === 0) {
      layer.splice(i, 1)
      // Don't increase counter here, so next item shifts in place of removed one.
      continue
    }

    // Lower clip has been split apart and should be replaced with new clips.
    if (Array.isArray(result)) {
      layer.splice(i, 1, ...result)
      // Jump over all new clips: assume they are all parts of original lower clip,
      // so no need to again check intersection between each one of them and upper clip.
      i += result.length
      continue
    }
  }
}

/**
 * @param {Array<Asset[]>} layers
 */
function removeAssetsUnderVideo(previewParams, refAsset) {
  return layers => {
  // eslint-disable-next-line no-shadow
    layers.forEach((upperLayer, i, layers) => {
      const lowerLayers = layers.slice(i + 1)
      VideoAsset.filter(upperLayer)
        .forEach(upperAsset => {
          lowerLayers.forEach(lowerLayer => {
            iterateLowerLayer(lowerLayer, upperAsset, previewParams, refAsset)
          })
        })
    })
  }
}

/**
 * @param {TimelineLayer} layer
 * @param {Array<Asset[]>} assets
 */
function applyLayerAttributes(layer, assets) {
  return produce(assets, draft => {
    draft.forEach(asset => {
      if (layer.muted && (asset instanceof MediaAsset)) {
        // eslint-disable-next-line no-param-reassign
        asset.muted = true
      }
    })
  })
}

// ---
/**
 * @param {Array<[ typeof Asset, string ]>} config
 * @return {function(Asset[]): TimelineAssetGroups}
 */
const createGroupAssets = config => assets => {
  const typesMap = new Map(config)
  const unknownType = 'unknown'

  const defaults = Array.from(typesMap.values())
    .reduce((m, type) => ({ ...m, [type]: [] }), {})

  const {
    // eslint-disable-next-line no-unused-vars
    [unknownType]: unused,
    ...groups
  } = groupBy(assets, asset => {
    for (const [ klass, groupName ] of typesMap) {
      if (asset instanceof klass) {
        return groupName
      }
    }
    return unknownType
  })
  return Object.assign(defaults, groups)
}

/**
 * @typedef {object} TimelineAssetGroups
 * @property {Assets.VideoAsset[]} video
 * @property {Assets.AudioAsset[]} audio
 * @property {Assets.TextAsset[]} text
 * @property {Assets.TransitionAsset[]} transition
 */
const groupAssetsByType = createGroupAssets([

  [ VideoAsset, 'video' ],
  [ TransitionAsset, 'transition' ],
  [ AudioAsset, 'audio' ],
  [ ImageAsset, 'image' ],
  [ TextAsset, 'text' ],
  // TODO: more asset types in future
])

/**
 * Squash all layers into one, top-to-bottom.
 * Fill blanks between video clips in upper layers with visible parts of video clips from lower layers.
 * Remove all assets which are completely covered by video clips above them.
 *
 * Before:
 * |  video1  |.......|  video2  |
 * .|  any  |....| video3 |...| video4 |
 * After:
 * |  video1  |..| v3 |  video2  |  v4  |
 *
 * @param {Asset[]} assets
 * @param {TimelineLayer[]} layers
 * @returns {TimelineAssetGroups}
 */
function buildFlatTimeline(assets, layers, previewParams, refAsset) {
  // Sort everything, so each layer then gets sorted
  const sorted = sortBy(assets, asset => asset.startTime)
  // Layers order matters here. It's not the same as just `groupBy(layerId)`.
  const assetsByLayers = layers
    .map(layer => applyLayerAttributes(layer, Asset.getLayerAssets(sorted, layer.id)))
    .filter(layer => layer.length > 0)
  let flatTimeline = flatten(produce(assetsByLayers, removeAssetsUnderVideo(
    previewParams, refAsset
  )))
  // Sort concatenated layers again, because it's possible that upper layer starts later then lower one
  flatTimeline = sortBy(flatTimeline, asset => asset.startTime)
  const groups = groupAssetsByType(flatTimeline)
  return groups
}

export default buildFlatTimeline
