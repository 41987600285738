/* eslint-disable react/jsx-no-target-blank */
import ButtonBase from 'components/base/Button'

import { TranslationContext } from 'contexts/TranslationContext'
import { useModalDialog } from 'hooks'
import React, { useEffect, useState } from 'react'
import './CustomizationView.scss'
import { TextAreaModal } from './TextAreaModal/TextAreaModal'

export const CustomizationText = ({ onChange, text }) => {
  const { t } = React.useContext(TranslationContext)
  const [ modalText, setModalText ] = useState(text)

  const editModal = useModalDialog({
    onConfirm: newText => {
      setModalText(newText)
    },
  })

  useEffect(() => {
    setModalText(text)
    editModal.close()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ text ])

  const handleOpenEdit = () => {
    editModal.open()
  }

  useEffect(() => {
    onChange(modalText)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ modalText ])

  return (
    <>
      <div className="customization_edit-btns">
        <ButtonBase
          className="customization_edit-btn"
          border
          onClick={handleOpenEdit}
          data-lang-id="CUSTOMIZATION_BTN_EDIT_TEXT_VALUES"
          title={t('CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP')}
        >
          {t('CUSTOMIZATION_BTN_EDIT_TEXT_VALUES')}
        </ButtonBase>
        <a target="_blank" href="https://www.solveigmm.com/en/support/doc/html-5-video-editor/api-reference.html#language_format">
          <ButtonBase
            color="primary"
            text
            size="small"
            style={{ textTransform: 'unset' }}
            data-lang-id="CUSTOMIZATION_BTN_FORMAT_DESCRIPTION"
            title={t('CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP')}
          >
            {t('CUSTOMIZATION_BTN_FORMAT_DESCRIPTION')}
          </ButtonBase>
        </a>
      </div>
      <TextAreaModal title={t('CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES')} mode="text" text={modalText} modalDialog={editModal} />
    </>
  )
}
