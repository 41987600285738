import React, { useContext } from 'react'

import { DummyHeader } from 'components/AssetPanels/components/DummyHeader'
import { TranslationContext } from 'contexts/TranslationContext'
import List from '../BaseAssetsList'
import Item from './ListItem'

export default function TransitionAssetsList(props) {
  const { t } = useContext(TranslationContext)

  return (
    <List
      {...props}
      header={<DummyHeader caption={t('TRANSITION_LIST_CAPTION_TRANSITION')} />}
      ItemComponent={Item}
    />
  )
}
