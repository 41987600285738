import axios from 'axios'
import { ResizeSensor } from 'css-element-queries'
import { debounce, throttle } from 'lodash'
import React, { useCallback, useEffect } from 'react'
import { KEY_Y, KEY_Z } from '~/constant'
import { isTextInput } from '~/Util'
import { useChanged } from './changes'

// ---

/**
 * @param {React.MutableRefObject} ref
 * @param {string} eventName
 * @param {function} fn
 * @param {object} [options]
 */
export function useDOMEvent(ref, eventName, fn, options) {
  // eslint-disable-next-line no-param-reassign
  options = useChanged(options)
  React.useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener(eventName, fn, options)
      }
      return () => {
        if (node) {
          node.removeEventListener(eventName, fn, options)
        }
      }
    },
    [ ref, eventName, fn, options ]
  )
}

/**
 * @param {React.MutableRefObject} ref
 * @param {function} fn
 * @param {object} [options={}]
 * @param {number} [options.debounce]
 * @param {number} [options.throttle]
 * @param {boolean} [options.leading]
 * @param {boolean} [options.trailing]
 */
export function useResizeSensor(ref, fn, options = {}) {
  if (options.debounce > 0 && options.throttle > 0) {
    throw new Error('Applying both debounce and throttle to the same function is not allowed')
  }

  const opts = useChanged(options)
  React.useLayoutEffect(
    () => {
      const $node = ref.current

      // It will be called automatically on initialization too:
      let onResize = () => {
        fn($node.getBoundingClientRect(), $node)
      }

      // ---

      const params = {}
      if (opts.leading !== undefined) {
        params.leading = opts.leading
      }
      if (opts.trailing !== undefined) {
        params.trailing = opts.trailing
      }
      if (opts.debounce > 0) {
        onResize = debounce(onResize, opts.debounce, params)
      } else if (opts.throttle > 0) {
        onResize = throttle(onResize, opts.throttle, params)
      }

      // ---

      const sensor = new ResizeSensor($node, onResize)
      return () => {
        sensor.detach(onResize)
      }
    },
    [ ref, fn, opts ]
  )
}

// ---

/**
 * @param {React.MutableRefObject} ref
 * @param {string|string[]} code
 * @param {function} fn
 * @param {object} params
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code/code_values#Code_values
 */
export function useKeyCodeListener(
  ref, code, fn, { ctrlKey = false, ignoreInputHistory = true, preventDefault = false } =
  { ctrlKey: false, ignoreInputHistory: true, preventDefault: false }
) {
  const codes = React.useMemo(() => [].concat(code), [ code ])
  const callback = React.useCallback(
    e => {
      const { target } = e
      const inputTextKeys = [ 'Delete', 'Backspace', 'Space', 'Escape' ]
      const inputTextHistoryKeys = [ KEY_Y, KEY_Z ]

      const isPreventDefault = preventDefault && codes.includes(e.code) && !isTextInput(target)

      const isInputTextHistoryKeys = inputTextHistoryKeys.some(key => key === e.code)

      const isPreventDefaultHistoryOnLatestInput = !ignoreInputHistory && !isTextInput(target)
        && isInputTextHistoryKeys && e.ctrlKey === ctrlKey


      if (isPreventDefault || isPreventDefaultHistoryOnLatestInput) {
        e.preventDefault()
      }

      const isInputTextKeys = inputTextKeys.some(key => key === e.code)
      if (codes.includes(e.code) && !isTextInput(target)
        && e.ctrlKey === ctrlKey && (isInputTextKeys || isInputTextHistoryKeys)) {
        fn(e)
      }
    },
    [ codes, ctrlKey, fn, ignoreInputHistory, preventDefault ]
  )
  return useDOMEvent(ref, 'keydown', callback)
}

// ---

const EMPTY_RECT = {
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
}

/**
 * @param {React.MutableRefObject} ref
 * @param {object} [options={}]
 * @param {number} [options.debounce]
 * @param {number} [options.throttle]
 * @param {boolean} [options.leading]
 * @param {boolean} [options.trailing]
 * @return {{top: number, left: number, bottom: number, width: number, x: number, y: number, right: number, height: number}}
 */
export function useBoundingRect(ref, options) {
  const [ rect, setRect ] = React.useState(EMPTY_RECT)
  useResizeSensor(ref, setRect, options)
  return rect
}

// ---

/**
 * @param {string} src
 * @param {string} id
 * @param {string} text
 */
export function useScript({ src = '', id, text, sources = [] }) {
  const sourcesToLoad = sources.concat(src)
  const filteredSourcesToLoad = sourcesToLoad.filter(source => !!source)
  // eslint-disable-next-line no-shadow
  const addScript = useCallback(src => {
    const script = document.createElement('script')
    script.async = 'true'
    script.defer = 'true'
    script.id = id

    if (text) {
      script.type = 'text/javascript'
      script.text = text
    }

    if (src) {
      script.src = addTimeToUrl(src)
      script.type = 'module'
      document.body.appendChild(script)
    }
    return script
  }, [ id, text ])

  useEffect(() => {
    const loadedScripts = filteredSourcesToLoad.map(source => addScript(source))

    return () => {
      loadedScripts.forEach(script => document.body.removeChild(script))
    }
  }, [ id, filteredSourcesToLoad, text, addScript ])
}

const setInnerHTML = (elm, html) => {
  // eslint-disable-next-line no-param-reassign
  elm.innerHTML = html
  Array.from(elm.querySelectorAll('script')).forEach(oldScript => {
    const newScript = document.createElement('script')
    Array.from(oldScript.attributes)
      .forEach(attr => newScript.setAttribute(attr.name, attr.value))
    newScript.appendChild(document.createTextNode(oldScript.innerHTML))
    oldScript.parentNode.replaceChild(newScript, oldScript)
  })
}

/**
 * @param {string} src
 */
export function useTemplate({ sources, src }) {
  const sourcesToLoad = sources.concat(src)
  const filteredSourcesToLoad = sourcesToLoad.filter(source => !!source)

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    async function fetchTemplateAndInsert(src) {
      try {
        const response = await axios.get(src, { baseURL: null })
        const template = response.data
        const scriptContainer = document.createElement('div')
        scriptContainer.innerHTML = template
        document.body.appendChild(scriptContainer)
        setInnerHTML(scriptContainer, template)
      } catch (e) { /* NOP */ }
    }

    filteredSourcesToLoad.forEach(fetchTemplateAndInsert)
  }, [ filteredSourcesToLoad ])
}

/**
 * @param {string} src
 * @param {string} onComplete
 */
export function useCssStyle({ src, onComplete, stringified }) {
  const handleComplete = useCallback(() => {
    onComplete(null)
  }, [ onComplete ])

  const handleError = useCallback(() => {
    console.error('Error: invalid external custom css url')
  }, [])

  useEffect(() => {
    (async function fn() {
      const oldStyle = document.getElementById('custom-style')
      if (oldStyle) {
        document.head.removeChild(oldStyle)
      }
      if (stringified) {
        const style = document.createElement('style')
        style.id = 'custom-style'
        style.textContent = stringified
        document.head.append(style)
      } else {
        const cssLink = document.createElement('link')
        cssLink.id = 'custom-style'
        cssLink.href = addTimeToUrl(src)
        cssLink.type = 'text/css'
        cssLink.rel = 'stylesheet'
        cssLink.media = 'screen,print'
        const promise = new Promise(resolve => {
          setTimeout(() => { resolve(null) }, 10000)
          cssLink.addEventListener('error', handleError)
          cssLink.addEventListener('abort', handleError)
          cssLink.addEventListener('load', resolve)
        })
        document.getElementsByTagName('head')[0].appendChild(cssLink)
        await promise
      }
      handleComplete()
    }())
  }, [ src, stringified, onComplete, handleComplete, handleError ])
}

export function addTimeToUrl(url) {
  return `${url}?${Date.now()}`
}

export const useOnClickWithoutPropagation = (f = () => {}) => useCallback(e => {
  e.stopPropagation()
  f(e)
}, [ f ])
