import { time2Pixel } from '~/Util'
import { LAYER_BORDER, LAYER_HEIGHT_WITH_BORDER } from '~/constant'

export const getMinLeftNMinTopDraggableCoords = (dragItem,
  timelineTopOffset, sourceOffset, layerIndexOffset, scale, leftXOffset) => {
  const minLeftX = time2Pixel(dragItem?.selectedAssets?.[0]?.startTime, scale) + leftXOffset
  const topY = (sourceOffset.y - timelineTopOffset)
  - (layerIndexOffset * LAYER_HEIGHT_WITH_BORDER + LAYER_BORDER)

  return { minLeftX, topY }
}
