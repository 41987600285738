import { time2Pixel } from '~/Util'

// It's used when image collapsed to 0 duration and has only 2 borders by 3 pxls
const MINIMAL_ASSET_WIDTH = 6

export const assetToTimelineDragElement = scale => ({ startTime, duration, id }) => {
  const x = time2Pixel(startTime, scale)
  const width = Math.max(time2Pixel(duration, scale), MINIMAL_ASSET_WIDTH)
  return { x, width, assetId: id }
}
