import { frontendVersion } from 'helpers/versionRoutines'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectInstallVersion } from 'selectors/app'
import './styles.scss'

export const ActivationBarComponent = () => {
  const installVersion = useSelector(selectInstallVersion)
  return (
    <div className="activation-bar">
      <img className="activation-bar_logo" width={91} src={__CFG__.LOGO_PATH} alt="logo" />
      <div className="activation-bar_app">{__CFG__.ACTIVATION_BAR_APP}</div>
      <div className="activation-bar_author">{__CFG__.COMPANY_NAME}</div>
      <div className="activation-bar_version">{installVersion || frontendVersion()}</div>
    </div>
  )
}
