/* eslint-disable no-nested-ternary */
import { isEmpty } from 'lodash'
import { undoDeleteTransition } from '~/actions/undoRedo/transitionUndoRedoActions'
import { deleteAssets } from '~/actions/timeline'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'
import { addAssetToTimeline } from '~/actions/projectData/addAssetToTimeline'
import { getObjectFields } from '~/Util'

export class AssetDeletionAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.AssetDeletionAction, payload, ...params)
    this._transitions = payload.transitions
    this._relatedAssets = payload.relatedAssets
  }

  get transitions() {
    return this._transitions || []
  }

  set transitions(transitions) {
    this._transitions = transitions
  }

  get relatedAssets() {
    return this._relatedAssets || []
  }

  set relatedAssets(relatedAssets) {
    this._relatedAssets = relatedAssets
  }

  * undo() {
    if (!isEmpty(this.transitions)) {
      for (const transition of this.transitions) {
        const { leftVideoAssetId, rightVideoAssetId } = transition
        const transitionAssets = this.relatedAssets
          .filter(asset => [ leftVideoAssetId, rightVideoAssetId ].includes(asset.id))
        yield undoDeleteTransition(transition, transitionAssets)
      }
    } else {
      for (const asset of this.assets) {
        yield addAssetToTimeline(asset, asset.layerId)
      }
    }
  }

  * redo() {
    yield deleteAssets(this.assets.concat(this.transitions).map(getObjectFields))
  }

}
