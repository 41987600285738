import * as ActionTypes from '~/actions/ActionTypes'
import { activateProject } from '~/actions/projectData/activateProject'
import { newProjectName } from '~/constant'
import * as API from '~/ServerAPI'

export const createProject = (
  data,
  name = newProjectName,
  thumbnail,
  createTime = Date.now()
) => async dispatch => {
  let id = null
  try {
    // const createTime = Date.now()
    const modifTime = createTime
    const projectParams = { name, data, thumbnail, createTime, modifTime }
    id = (await API.createProject(projectParams)).id
    await dispatch({
      type: ActionTypes.PROJECTS_CREATE,
      payload: { project: { id, ...projectParams } },
    })
    await dispatch(activateProject({ id, name, activeProject: JSON.parse(data) }))
  } catch (e) {
    console.error('Failed to create new project')
  }
  return id
}
