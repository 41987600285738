import React, { ReactNode } from 'react'
import { useDispatch } from 'react-redux'
import { DropzoneRootProps, DropzoneOptions } from 'react-dropzone'
import cx from 'classnames'

import * as Actions from 'actions'
import { ReactComponent as ImportIcon } from 'assets/media/ic_import.svg'
import { useOnChange } from 'hooks'
import { useLocalFileImport } from 'hooks/useLocalFileImport'
import styles from './fileInput.module.scss'


const EMPTY = (props: DropzoneRootProps) => props

type Props = {
  title?: React.ReactNode,
  disabled?: boolean,
  classes?: {
    root?: string,
    desc?: string,
    icon?: string,
    content?: string
  },
  dropzoneParams?: DropzoneOptions,
  uploadService?: (file: File) => Promise<unknown>,
  uploadOnDrop?: boolean,
  enableAreaClick?: boolean,
  children: ReactNode | ReactNode[],
  error?: ReactNode
}

export default function FileInput({ title,
  disabled = false,
  classes = {},
  dropzoneParams = {},
  uploadService,
  uploadOnDrop,
  enableAreaClick = false,
  error,
  children }: Props) {
  const {
    getRootProps,
    isDragActive,
    getInputProps,
    // isDragAccept,
    // isDragReject,
  } = useLocalFileImport(dropzoneParams, uploadService, uploadOnDrop)

  const getDropProps = !disabled ? getRootProps : EMPTY

  const dispatch = useDispatch()
  useOnChange(isDragActive, isDragging => {
    dispatch(Actions.mainView.setLocalFileDragging(isDragging))
  })

  return (
    <div className={styles.fileInput}>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getDropProps({
          className: cx(styles.wrapper, {
            [styles.wrapperActive]: isDragActive,
            // 'file-input__wrap--accept': isDragAccept,
            // TODO: for some weird reason, isDragReject is true during
            // uploading is in a progress and draging next supported file.
            // If uploading is completed isDragReject is false again.
            // 'file-input__wrap--reject': isDragReject,
          }, classes.root),
        })}
      >
        <div className={cx(styles.inputContainer, classes.content)}>
          <div>
            <ImportIcon className={cx(styles.icon, classes.icon)} />
            <p className={cx(styles.desc, classes.desc)}>{title}</p>
          </div>
        </div>
        {error}
        {children}
        {enableAreaClick && <input {...getInputProps()} />}
      </div>
    </div>
  )
}
