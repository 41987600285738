import React from 'react'
import PropTypes from 'prop-types'
import './SliderPointer.scss'
import cx from 'classnames'
import { time2Pixel, refTimeToHHMMSSMSMS } from '~/Util'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import { Context as TimelineGeometryContext } from './GeometryContextProvider'
import { TranslationContext } from '~/contexts/TranslationContext'

function SliderPointer(props) {
  const { time, scale, isSticky } = props
  const { scrollLeft } = React.useContext(TimelineScrollPositionContext)
  const { timelineHeight } = React.useContext(TimelineGeometryContext)
  const { t } = React.useContext(TranslationContext)

  return (
    <div
      className="slider-pointer"
      style={{
        transform: `translateX(${time2Pixel(time, scale) - scrollLeft}px)`,
      }}
      data-lang-id="TIMELINE_SLIDER_POINTER"
      title={t('TIMELINE_SLIDER_POINTER_TOOLTIP')}
    >
      <div>
        <div className={cx('slider-pointer__head', {
          'slider-pointer__sticky': isSticky,
        })}
        />
        <input
          type="text"
          className="slider-pointer__input"
          readOnly
          value={refTimeToHHMMSSMSMS(time)}
        />
      </div>
      <div
        className={cx('slider-pointer__body', {
          'slider-pointer__sticky': isSticky,
        })}
        style={{ height: timelineHeight }}
      />
    </div>
  )
}

SliderPointer.defaultProps = {
  time: 0,
  isSticky: false,
}

SliderPointer.propTypes = {
  scale: PropTypes.number.isRequired,
  time: PropTypes.number,
  isSticky: PropTypes.bool,
}

export default React.memo(SliderPointer)
