import React, { useContext } from 'react'
import Radio from 'components/base/Radio'
import FontOptionsRadioGroup from '../FontOptionsRadioGroup/FontOptionsRadioGroup'
import { ReactComponent as AlignLeftIcon } from '~/assets/settings/align_left.svg'
import { ReactComponent as AlignCenterIcon } from '~/assets/settings/align_center.svg'
import { ReactComponent as AlignRightIcon } from '~/assets/settings/align_right.svg'
import { ReactComponent as AlignJustifyIcon } from '~/assets/settings/align_justify.svg'
import { TranslationContext } from '~/contexts/TranslationContext'

export default function TextAlignSelector({ value, onChange }) {
  const { t } = useContext(TranslationContext)
  return (
    <FontOptionsRadioGroup
      name="textAlign"
      value={value}
      onChange={onChange}
    >
      <Radio
        value="left"
        icon={<AlignLeftIcon />}
        checkedIcon={<AlignLeftIcon />}
        data-lang-id="SETTINGS_TEXT_ALIGN_BTN_LEFT"
        title={t('SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP')}
      />
      <Radio
        value="center"
        icon={<AlignCenterIcon />}
        checkedIcon={<AlignCenterIcon />}
        data-lang-id="SETTINGS_TEXT_ALIGN_BTN_CENTER"
        title={t('SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP')}
      />
      <Radio
        value="right"
        icon={<AlignRightIcon />}
        checkedIcon={<AlignRightIcon />}
        data-lang-id="SETTINGS_TEXT_ALIGN_BTN_RIGHT"
        title={t('SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP')}
      />
      <Radio
        value="justify"
        icon={<AlignJustifyIcon />}
        checkedIcon={<AlignJustifyIcon />}
        data-lang-id="SETTINGS_TEXT_ALIGN_BTN_JUSTIFY"
        title={t('SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP')}
      />
    </FontOptionsRadioGroup>
  )
}
