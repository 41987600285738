import React from 'react'
import { IMaskMixin } from 'react-imask'
import Input from 'components/base/Input'
import { isNumber } from 'lodash'

const MaskedInput = IMaskMixin(Input)

function MaskedInputWrapper(props) {
  const {
    onChange,
    inputRef,
    autoselect, onFocus,
    startSelect,
    endSelect,
    noAccepted,
    format,
    value,
    ...rest
  } = props

  // ---

  const ref = React.useRef()
  const setRef = React.useCallback(
    x => {
      ref.current = x

      // react-imask requires inputRef strictly as function, which is quite inconvenient
      // lets make it standard
      if (typeof inputRef === 'function') {
        inputRef(x)
      } else if (inputRef !== undefined) {
        inputRef.current = x
      }
    },
    [ inputRef ]
  )

  // ---

  const handleFocus = React.useCallback(
    (...args) => {
      if (autoselect) {
        if (isNumber(startSelect) && isNumber(endSelect)) {
          ref.current.setSelectionRange(startSelect, endSelect)
        } else {
          ref.current.select()
        }
      }
      if (onFocus) {
        onFocus(...args)
      }
    },
    [ onFocus, autoselect, startSelect, endSelect ]
  )

  // ---

  const handleAccept = e => {
    if (e !== rest.value) {
      if (onChange) {
        onChange(e)
      }
    }
  }

  return (
    <MaskedInput
      {...rest}
      value={format(value)}
      // @link https://github.com/uNmAnNeR/imaskjs/blob/master/packages/react-imask/README.md#mask-input-example
      onAccept={noAccepted ? null : handleAccept}
      inputRef={setRef}
      onFocus={handleFocus}
    />
  )
}

MaskedInputWrapper.defaultProps = {
  // show placeholder chars initially, don't wait for first user input
  lazy: false,
  // this enables usage of parse/format function props
  unmask: 'typed',

  // masked inputs requires a string value
  format: x => `${x}`,
  noAccepted: false,
}

export default MaskedInputWrapper
