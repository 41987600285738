import { startRecording as startRecordingService,
  stopRecording as stopRecordingService,
  deleteRecording as deleteRecordingService,
  closeConnection as closeConnectionService } from 'services/MediaRecordingService'

import { getMediaFile, getMediaFileStatus } from 'ServerAPI'
import { parseLoadedFile } from 'Util/sourceFile'
import { get as getPlayerVolume } from 'cookieServices/PlayerVolume'

import { sourceFiles as sourceFilesSelector,
  layer as layerSelectors,
  recording as recordingSelectors,
  mainView as mainViewSelectors,
  getLayerAssets, selectSourceFilesParams } from 'selectors'

import { selectActiveProjectId } from 'selectors/projectData'

import { MediaRecordingAsset } from 'models/Asset'

import { setShowCountdown, setRecordingStatus, addRecord, deleteRecord, replaceRecord } from 'reducers/recording'
import { FILE_TYPE, MEDIA_FILE_FILTERS, PLAYBACK_STATE, SOURCE_FILE_TYPES } from 'enums'
import { time2Pixel } from 'Util'
import { MediaRecordingRestoreService } from 'services/MediaRecordingRestoreService'
import { updateSlider,
  deleteTempAssets,
  loadProjectAsset,
  forceDeleteEmptyLayer, deleteAssets, setScrollLeft, rewind, addAssetFromFileAtSliderPosition } from './timeline'
import { requestDeleteFile } from './sourceFiles'
import { changeTimelinePlaybackState, setPlaybackClip } from './playback'
import { updateAssetInPreview } from './layer'
import { endOfflineRestoration, setPlayerVolume, startOfflineRestoration } from './mainView'
import * as ActionTypes from './ActionTypes'

function generateAsset({ id, recordingType, name, duration = 0 }: Partial<MediaRecordingAsset>) {
  return new MediaRecordingAsset({ id,
    duration,
    isRecording: true,
    recordingType,
    name })
}

function tempName(time: number) {
  const voiceFileExt = '.webm'
  const dateTime = new Date(time).toISOString().replace(/T/, '_').replace(/\..+/, '')
  return `Voice_${dateTime}${voiceFileExt}`
}

function muteTimeline(dispatch: AppDispatch, getState: () => RootState) {
  dispatch(setPlayerVolume(0, false))
}

let intervalId: number
let startDuration: number
let startDate: number
let type: ReturnType<typeof mainViewSelectors.selectMediaRecordingType> = 'audio'

export const startRecording = (id: string | null) => async (
  dispatch: AppDispatch, getState: () => RootState) => {
  if (!id) return

  let state = getState()
  const { sliderTime: startTime, duration } = state.timeline
  const muted = recordingSelectors.selectMuted(state)
  type = mainViewSelectors.selectMediaRecordingType(state)

  try {
    startDuration = duration
    startDate = Date.now()
    await dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.STOP))
    // TODO: handle errors

    await dispatch(setRecordingStatus('progress'))
    await dispatch(setShowCountdown(false))
    const waitBeforeStart = performance.now()
    await startRecordingService(id)

    let asset = generateAsset({ id, name: tempName(startDate), recordingType: type || 'audio' })
    await dispatch(addAssetFromFileAtSliderPosition(asset))
    intervalId = window.setInterval(() => dispatch(handleDurationUpdate(id, startTime)), 100)

    if (muted) {
      await dispatch(muteTimeline)
    }

    await dispatch(updateSlider(startTime + (performance.now() - waitBeforeStart) * 10000))
    await dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.PLAY))

    state = getState()
    const activeProjectId = selectActiveProjectId(state)
    asset = sourceFilesSelector.getMediaRecordingAsset(state)
    MediaRecordingRestoreService.push(asset, activeProjectId)
  } catch (e) {
    clearInterval(intervalId)
    await dispatch(setRecordingStatus('error'))
    await dispatch(updateSlider(startTime))
    await dispatch(setShowCountdown(false))
    MediaRecordingRestoreService.pop()
    throw Error(e as string || 'Start Recording Error')
  }
}

const handleDurationUpdate = (id: string, startTime: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  const { sliderTime } = getState().timeline
  const duration = sliderTime - startTime

  dispatch(updateAssetInPreview(id, { duration }))
}

export const stopRecording = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  window.clearInterval(intervalId)
  let asset = sourceFilesSelector.getMediaRecordingAsset(getState())
  dispatch(handleDurationUpdate(asset.id, asset.startTime))
  asset = sourceFilesSelector.getMediaRecordingAsset(getState())

  const tempRecord = generateAsset({ id: asset.id,
    recordingType: asset.recordingType,
    name: tempName(startDate),
    duration: asset.duration })

  try {
    closeConnectionService(asset.id)
    dispatch(setRecordingStatus('saving'))
    dispatch(addRecord(tempRecord))
    dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.STOP))

    await stopRecordingService(asset.id)
    MediaRecordingRestoreService.pop()
    if (!asset) throw Error('Asset not found')
    await dispatch(onRecordingStop(asset))
    dispatch(stopRecordingEffects(asset))
    dispatch(setRecordingStatus('stop'))
  } catch (e) {
    await dispatch(handleStopRecordingError(asset))
    throw Error('Stop Recording Error')
  }
}

const onRecordingStop = (asset: InstanceType<typeof MediaRecordingAsset>) => async (
  dispatch: AppDispatch, getState: () => RootState) => {
  let sourceFileData
  try {
    await checkStatus(asset.id)
    const { data } = await getMediaFile(asset.id, FILE_TYPE.VOICEOVER)
    sourceFileData = data

    const file = parseLoadedFile(SOURCE_FILE_TYPES.MEDIA, data)

    await dispatch(deleteTempAssets([ asset.id ]))
    await dispatch(loadProjectAsset(file,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { layerId: asset.layerId!,
        startTime: asset.startTime,
        pregeneratedId: asset.id }))

    const { filter, folder } = selectSourceFilesParams(getState(), SOURCE_FILE_TYPES.MEDIA)

    if (isVoiceoverFilter(filter) && (!file.folder || folder === file.folder)) {
      dispatch({
        type: ActionTypes.SOURCE_FILE_CREATED,
        payload: { item: sourceFileData },
      })
    }
  } catch (e) {
    await dispatch(handleStopRecordingError(asset))
    throw Error(e as string || 'Stop Recording Error')
  }
}

const handleStopRecordingError = (asset: MediaRecordingAsset) => async (
  dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.STOP))
  dispatch(setRecordingStatus('error'))
  await dispatch(deleteTempAssets([ asset.id ]))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  dispatch(deleteEmptyLayer(asset.layerId!))
  dispatch(deleteRecord(asset.id))
}

const stopRecordingEffects = (asset: MediaRecordingAsset) => async (
  dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const timelineAsset = layerSelectors.getTimelineAssetById(state, asset.id)
  // const showFeatures = state.mainView.showFeatures.mediaRecordingSettings
  // if (!showFeatures) return

  const rewindSlider = recordingSelectors.selectRewindSliderByType(type)(state)
  const { duration, scale } = state.timeline

  if (rewindSlider) {
    dispatch(rewind(asset.startTime))
    if (startDuration !== duration) {
      dispatch(setScrollLeft(time2Pixel(asset.startTime, scale)))
    }
  } else {
    dispatch(rewind(sliderTime))
  }

  dispatch(setPlayerVolume(getPlayerVolume()))

  dispatch(replaceRecord(timelineAsset))
}

export const deleteRecording = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const asset = sourceFilesSelector.getMediaRecordingAsset(getState())
  MediaRecordingRestoreService.pop()
  closeConnectionService(asset.id)
  window.clearInterval(intervalId)
  await dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.STOP))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  await dispatch(deleteEmptyLayer(asset.layerId!))
  await dispatch(rewind(asset.startTime))
  await dispatch(setRecordingStatus('stop'))
  deleteRecordingService(asset.id)
  await dispatch(deleteTempAssets([ asset.id ]))
}

export const retakeRecording = (sessionId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const lastRecord = recordingSelectors.selectLastMediaRecording(getState())
  if (!lastRecord || !sessionId) return
  const { startTime, id } = lastRecord
  await dispatch(rewind(startTime))
  await dispatch(deleteAssets([ lastRecord ]))
  await dispatch(deleteRecord(id))
  await dispatch(requestDeleteFile(id, SOURCE_FILE_TYPES.MEDIA, true))
  await dispatch(startRecording(sessionId))
}

const deleteEmptyLayer = (layerId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const assets = getLayerAssets(getState(), layerId)
  if (assets.length === 0) {
    dispatch(forceDeleteEmptyLayer([ layerId ]))
  }
}

export const playLastRecord = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const lastRecord = recordingSelectors.selectLastMediaRecording(getState())
  dispatch(setPlaybackClip(lastRecord))
}

export const handleStopOnDisconnect = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  window.clearInterval(intervalId)
  let asset = sourceFilesSelector.getMediaRecordingAsset(getState())
  dispatch(handleDurationUpdate(asset.id, asset.startTime))
  asset = sourceFilesSelector.getMediaRecordingAsset(getState())

  const tempRecord = generateAsset({ id: asset.id,
    recordingType: asset.recordingType,
    name: tempName(startDate) })

  closeConnectionService(asset.id)
  dispatch(setRecordingStatus('stop'))
  dispatch(addRecord(tempRecord))
  dispatch(changeTimelinePlaybackState(PLAYBACK_STATE.STOP))
}

export const handleRestoreOnConnect = (onInit = false) => async (dispatch: AppDispatch, getState: () => RootState) => {
  if (!MediaRecordingRestoreService.pendingRecord) return
  try {
    dispatch(startOfflineRestoration())

    await dispatch(onRecordingStop(MediaRecordingRestoreService.pendingRecord))
    if (!onInit) {
      dispatch(stopRecordingEffects(MediaRecordingRestoreService.pendingRecord))
    }
  } catch (e) {
    console.error(e)
  } finally {
    MediaRecordingRestoreService.pop()
    dispatch(endOfflineRestoration())
  }
}

async function checkStatus(id: string) {
  async function getStatus() {
    const { data } = await getMediaFileStatus(id)
    return data.status
  }
  try {
    // without timeout status gets MediaInfo value and Ready after delay
    const status = await new Promise((resolve, reject) => {
      setTimeout(async () => {
        getStatus().then(resolve).catch(reject)
      }, 500)
    })

    if (status !== 'ready') throw Error()
  } catch (e) {
    await new Promise<void>((resolve, reject) => {
      window.setTimeout(() => {
        getStatus()
          .then(status => {
            if (status === 'ready') {
              resolve()
            } else {
              reject()
            }
          })
          .catch(() => reject())
      }, 5000)
    })
  }
}

const isVoiceoverFilter = (filter: string | undefined) => __APP_PROFILE__ === 'vrspot'
  ? filter === MEDIA_FILE_FILTERS.Voiceover
  : filter === MEDIA_FILE_FILTERS.Voiceover || filter === MEDIA_FILE_FILTERS.Audio || filter === MEDIA_FILE_FILTERS.All
