import { isHLS } from './isHLS'
import { isMediaServerUrl } from './isMediaServerUrl'


const excludeNativeHTML5PlayerProps = [ 'onDuration' ]

export const getPlayerProps = (src, props) => {
  const currentProps = { ...props }
  excludeNativeHTML5PlayerProps.forEach(key => delete currentProps[key])
  if (isHLS(src) || isMediaServerUrl(src)) {
    delete currentProps.src
  }
  return currentProps
}
