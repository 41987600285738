import { openChapter } from 'actions/mainView'
import { setScrollLeft, deleteAssets } from 'actions/timeline'
import { MENU_ITEMS } from 'enums'
import * as ActionTypes from '~/actions/ActionTypes'
import { clearActionsHistory, setBlockAddHistoryAction } from '~/actions/historyActions'

import * as Selectors from '~/selectors'

export const clearEditor = () => async (dispatch, getState) => {
  const state = getState()
  const layers = Selectors.getLayers(state)

  const layersToClear = layers.reduce((acc, layer) => {
    const assets = Selectors.getLayerAssets(state, layer.id)
    return acc.concat(assets)
  }, [])
  await dispatch(setBlockAddHistoryAction(true))
  if (layersToClear.length) {
    await dispatch(deleteAssets(layersToClear))
  }
  await dispatch({ type: ActionTypes.LAYER_REMOVE_ALL })
  await dispatch(clearActionsHistory())
  await dispatch(setScrollLeft(0))
  await dispatch(setBlockAddHistoryAction(false))
  await dispatch(openChapter(MENU_ITEMS.MEDIA))
}
