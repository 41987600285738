import { FILE_TYPE, FILE_STATUS, UPLOAD_ERROR } from '~/enums'
import MediaAsset from './MediaAsset'

/**
 * @typedef {object} UploadError
 * @property {string} type
 * @property {string} message
 */

/**
 * Represents upload media files – like mp4 video, mp3 audio or png.
 *
 * @memberOf Assets
 * @extends {MediaAsset}
 */
class UploadableAsset extends MediaAsset {

  constructor(data) {
    if (new.target === UploadableAsset) {
      throw new Error(`${new.target.name} can't be instantiated`)
    }

    const {
      progress = 100,
      status = '',
      type = '',
      error = undefined,
      currentFilePos = undefined,
      fileHandle = undefined,
      ...rest
    } = data

    super(rest)

    this._progress = progress
    // this field for retry functionality
    this._currentFilePos = currentFilePos
    this._fileHandle = fileHandle
    this._error = error
    this._status = status
    this._type = type
  }

  // ---

  /**
   * Allow dragging 'ready' item, item without upload errors
   * and item which is in preparing thumbnail stage
   *
   * @returns {boolean}
   */
  get draggable() {
    return this._isUploadedSuccessfully
  }

  // ---

  /**
   *
   * @returns {boolean}
   */
  get hasError() {
    const { error } = this
    return error !== undefined && error.type !== UPLOAD_ERROR.BUILD_THUMBNAILS
  }

  // ---

  /**
   * @returns {number}
   */
  get progress() {
    return this._progress
  }

  /**
   * @param {number} progress
   */
  set progress(progress) {
    this._progress = progress
  }

  // ---

  /**
   * @returns {string}
   */
  get status() {
    return this._status
  }

  /**
   * @param {string} status
   */
  set status(status) {
    this._status = status
  }
  // ---

  /**
   * @returns {string}
   */
  get type() {
    return this._type
  }

  /**
   * @param {string} type
   */
  set type(type) {
    this._type = type
  }
  // ---

  /**
   * @returns {UploadError}
   */
  get error() {
    return this._error
  }

  /**
   * @param {object} error
   */
  set error(error) {
    this._error = error
  }
  // ---

  /**
   * @returns {number}
   */
  get currentFilePos() {
    return this._currentFilePos
  }

  /**
   * @param {number} currentFilePos
   */
  set currentFilePos(currentFilePos) {
    this._currentFilePos = currentFilePos
  }
  // ---

  /**
   * @returns {object}
   */
  get fileHandle() {
    return this._fileHandle
  }

  /**
   * @param {object} fileHandle
   */
  set fileHandle(fileHandle) {
    this._fileHandle = fileHandle
  }
  // ---

  get canRemove() {
    return (this._type !== FILE_TYPE.TEST
        && this._type !== FILE_TYPE.DEVMEDIA
        && this._type !== FILE_TYPE.JSONMEDIA)
  }

  get canPlay() {
    return this._isUploadedSuccessfully
  }

  get uploading() {
    return this._status === FILE_STATUS.UPLOAD
  }

  // ---

  get _isUploadedSuccessfully() {
    return (this._status === FILE_STATUS.READY
      || this._status === FILE_STATUS.PREPARE_THUMBNAILS)
      && !this.hasError
  }

}

export default UploadableAsset
