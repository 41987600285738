import { useState, useEffect } from 'react'
import { AxiosError } from 'axios'

import * as UploadService from 'services/uploadService'
import useImportProgress from 'components/Projects/components/Import/hooks/useImportProgress'
import { projectData, sourceFiles } from 'actions'
import { useAction } from 'hooks/utils'
import useAbortController from 'components/Projects/components/Import/hooks/useAbortController'
import { SOURCE_FILE_TYPES } from 'enums'

export type Options = {
  createFolder?: boolean
  fps?: number
}

type Props = {
  onError: (message: string) => void
}

function useUpload({ onError }: Props) {
  const [ sessionId, setSessionId ] = useState('')
  const [ settings, _setSettings ] = useState<Options>({ createFolder: false })
  const addProject = useAction(projectData.addProject)
  const updateSourceFiles = useAction(sourceFiles.loadSourceFiles, SOURCE_FILE_TYPES.MEDIA)

  const controller = useAbortController()
  const [ progress, setProgress, stage, setStage ] = useImportProgress(sessionId, controller, onError)

  useEffect(() => {
    if (stage === 'ready') {
      addProject(sessionId)
      updateSourceFiles({ force: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ stage ])

  const setSettings = (settings: Options) => {
    _setSettings(x => ({ ...x, ...settings }))
  }

  const stopImport = () => {
    if (stage === 'stop' || stage === 'ready') return
    setStage('stop')
    controller.abort()

    UploadService.cancelImport(`api/upload/project?id=${sessionId}`)
  }

  const uploadService = async (file: File) => {
    let response = null

    try {
      response = await UploadService.createUploadSession(file.name,
        file.size, 'project', { ...settings })
    } catch (err) {
      // in case of attempt to load file larger than 2Gb, creation of upload
      // session will fail with code 507, so axios throws an exception
      response = (err as AxiosError).response
      setStage('stop')
    }

    if (response?.data.id !== undefined) {
      const { id } = response.data
      setSessionId(id)
      const request = (options: UploadService.RequestParams) => UploadService
        .uploadFileRequest({ ...options, type: 'project' })

      const onProgress = async () => {
        try {
          const { progress, stage } = await UploadService.getProjectProgress(id, controller)
          setProgress(progress)
          setStage(stage)
        } catch (e) {
          if (e instanceof Error) {
            if (e.name.includes('AbortError')) {
              setStage('stop')
            }
            if (e.message.includes('ImportProjectManager')) {
              setStage('error')
              onError(e.message)
              controller.abort()
              console.error('Import error', e.message)
            }
          }
        }
      }

      const onUploadSuccess = () => {
        setStage(x => x === 'upload' ? 'unpack' : x)
      }

      UploadService.uploadFile({ id,
        file,
        request,
        onChunkUpload: onProgress,
        onFileUpload: onUploadSuccess,
        onError: () => { setStage('stop') },
        controller })

      setStage('upload')
    }
  }

  return { sessionId,
    settings,
    setSettings,
    progress,
    stage,
    setStage,
    uploadCancel: stopImport,
    uploadService }
}

export default useUpload
