import { PLAYBACK_STATE } from '~/enums'
import * as ActionTypes from './ActionTypes'

export const changeClipPlaybackState = state => ({
  type: ActionTypes.CHANGE_PLAYBACK_STATE,
  payload: { state },
})

export const changeTimelinePlaybackState = state => ({
  type: ActionTypes.CHANGE_PREVIEW_STATE,
  payload: { state },
})

export const setPlaybackClip = asset => ({
  type: ActionTypes.SET_PLAYBACK_MEDIA,
  payload: { asset },
})

export const togglePlaybackPlaying = () => (dispatch, getState) => {
  const { clipPlaybackState, timelinePlaybackState, selectedClipId } = getState().playback

  const hasSelectedClip = selectedClipId !== null
  const currentPlaybackState = hasSelectedClip ? clipPlaybackState : timelinePlaybackState
  const newPlaybackState = currentPlaybackState === PLAYBACK_STATE.PLAY
    ? PLAYBACK_STATE.PAUSE
    : PLAYBACK_STATE.PLAY

  if (hasSelectedClip) {
    dispatch(changeClipPlaybackState(newPlaybackState))
  } else {
    dispatch(changeTimelinePlaybackState(newPlaybackState))
  }
}

export const setIsBuffering = isBuffering => ({
  type: 'SET_PLAYBACK_BUFFERING',
  payload: { isBuffering },
})
