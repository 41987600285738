import { cloneInstanceClass } from './cloneInstanceClass'

export const filterHistoryAsset = asset => {
  const historyAsset = cloneInstanceClass(asset)
  if (historyAsset.clearThumbnail) {
    historyAsset.clearThumbnail()
  } else {
    delete historyAsset.thumbnail
  }
  return historyAsset
}
