import { isNil } from 'lodash'

export const getDragAssetsByLayerIndexId = (draggingAsset, dragItem) => {
  const assetsByLayerIndex = Object.values(draggingAsset ?? {}).reduce((result, asset) => {
    const currentResult = { ...result }
    const selectedAssetData = dragItem
      .selectedAssets?.find(assetData => [ assetData?.layerId,
      assetData?.newLayerId ].includes(asset?.layerId))
    if (selectedAssetData) {
      if (!currentResult[selectedAssetData?.layerIndex]) {
        currentResult[selectedAssetData.layerIndex] = []
      }
      currentResult[selectedAssetData.layerIndex].push(asset)
    }
    return currentResult
  }, {})
  return addEmptyDraggableLayers(assetsByLayerIndex)
}

function addEmptyDraggableLayers(assetsByLayerIndex) {
  let prevLayerIndex = null
  return Object.entries(assetsByLayerIndex).reduce((sum, [ layerIndex, assetsArr ]) => {
    const result = { ...sum }
    if (!result[layerIndex]) result[layerIndex] = assetsArr
    if (!isNil(prevLayerIndex)) {
      const countLayers = layerIndex - prevLayerIndex
      if (countLayers > 1) {
        Array(countLayers - 1).fill('').forEach((_, i) => {
          result[prevLayerIndex + (i + 1)] = []
        })
      }
    }
    prevLayerIndex = Number(layerIndex)
    return result
  }, {})
}
