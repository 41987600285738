import { TranslationContext } from 'contexts/TranslationContext'
import { orderBy, uniqBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { fetchImagesCategories } from 'services/ImagesService'
import { ImageCategory } from '../types/ImageCategory'

export function useCategoryFetch(source: string) {
  const [ categories, setCategories ] = useState<ImageCategory[]>([])
  const { t } = useContext(TranslationContext)

  useEffect(() => {
    async function fn() {
      const entries = await fetchImagesCategories(source)
      setCategories([ { id: 'all', name: t('IMAGES_TEXT_ALL_CATEGORIES'), level: 0 } as ImageCategory ]
        .concat(orderBy(uniqBy(entries, c => c.id), [ 'name' ], [ 'asc' ])))
    }
    fn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ source ])

  return categories
}
