import React from 'react'
import './SaveSettings.scss'

function ErrorText({ text }) {
  return (
    <div className="save-settings__text">
      <p>{text}</p>
      {/* TODO: calculate left time
        <p>Approximate time left: 13 min. You may close this window.</p>
          <p>Video will be added directly to your library when finished.</p>
    */}
    </div>
  )
}

export default ErrorText
