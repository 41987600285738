import { all, takeEvery, put } from 'redux-saga/effects'

import { closeFeatures } from 'actions/mainView'
import * as ActionTypes from '~/actions/ActionTypes'

function* handleCloseView() {
  yield put(closeFeatures())
}

function* watchAll() {
  yield all([ takeEvery([
    ActionTypes.OPEN_CHAPTER,
    ActionTypes.ASSET_EDIT_STARTED,
  ], handleCloseView),
  ])
}

export default watchAll
