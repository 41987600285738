import { useEffect } from 'react'
import { useAction } from 'hooks'
import * as Actions from 'actions'

type Props = () => {
    isReady: boolean,
    isPlaying: boolean,
    isLoading: boolean,
    isBuffering: boolean,
    progress: number,
    paused: boolean,
    duration: number,
    currentTime: number,
}

function usePlayerState(getState: Props) {
  const { isBuffering } = getState()
  const setIsBuffering = useAction(Actions.playback.setIsBuffering, isBuffering)

  useEffect(() => {
    setIsBuffering()
  }, [ setIsBuffering, isBuffering ])
}

export default usePlayerState
