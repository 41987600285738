import produce from 'immer'
import { isEqual, isPlainObject } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { getObjectFields } from '~/Util'
import TimelineItem from './TimelineItem'
import { ASSET_ID_STARTWITH_LITERAL } from '~/config/constants/asset'

export const generateClientId = () => `${ASSET_ID_STARTWITH_LITERAL}-${uuidv4()}`

const ASSET_WITH_TRANSITION_MIN_DURATION = 5000000

/**
 * @memberOf Assets
 * @extends TimelineItem
 */
class AbstractAsset extends TimelineItem {

  /**
   * @param {Asset[]} assets
   * @param {string | string[]} layerId
   * @returns {Asset[]}
   */
  static getLayerAssets(assets, layerId) {
    return this.filter(assets
      .filter(asset => [].concat(layerId).find(id => id === asset.layerId)))
  }

  /**
   * @param {Asset[]} assets
   * @returns {Asset[]}
   */
  static filter(assets) {
    const klass = this
    return assets.filter(asset => asset instanceof klass)
  }

  /**
   * @param {Asset[]} assets
   * @returns {Asset[]}
   */
  static reject(assets) {
    const klass = this
    return assets.filter(asset => !(asset instanceof klass))
  }

  // ---

  constructor(data) {
    if (new.target === AbstractAsset) {
      throw new Error(`${new.target.name} can't be instantiated`)
    }

    super(data)

    const {
      id = generateClientId(),
      name = '',
      layerId = null,
      fileId = null,
      taskId = null,
      folder = null,
      selected = false,
      filetype = null,
      thumbnail = null,
      sourceFileType = null,
      mediaDeleted = false,
      errorMessage = null,
      originAssetId = null,
    } = data

    this._id = id
    this._name = name
    this._thumbnail = thumbnail

    this._fileId = fileId
    this._taskId = taskId
    this._folder = folder
    this._filetype = filetype

    this._layerId = layerId
    this._selected = selected
    this._sourceFileType = sourceFileType
    this._mediaDeleted = mediaDeleted
    this._errorMessage = errorMessage
    this._originAssetId = originAssetId
  }

  // eslint-disable-next-line class-methods-use-this
  get draggable() {
    return true
  }

  // indicates if transition can be applied to media asset
  // eslint-disable-next-line class-methods-use-this
  get canHaveTransition() {
    return false
  }

  // eslint-disable-next-line class-methods-use-this
  get canPlay() {
    return true
  }

  // ---

  /**
   * @returns {string}
   */
  get id() {
    return this._id
  }

  /**
  * @param {string} id
  */
  set id(id) {
    this._id = id
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name
  }

  /**
   * @returns {string}
   */
  get errorMessage() {
    return this._errorMessage
  }

  /**
   * @param {string} errorMessage
   */
  set errorMessage(errorMessage) {
    this._errorMessage = errorMessage
  }

  /**
   * @returns {string|null}
   */
  get fileId() {
    return this._fileId
  }


  /**
   * @returns {string|null}
   */
  get taskId() {
    return this._taskId
  }

  /**
   * @returns {string|null}
   */
  get folder() {
    return this._folder
  }

  /**
   * @returns {string|null}
   */
  get layerId() {
    return this._layerId
  }

  /**
   * @param {string|null} id
   */
  set layerId(id) {
    this._layerId = id
  }

  // ---

  /**
   * @returns {boolean}
   */
  get selected() {
    return this._selected
  }

  /**
   * @param {boolean} selected
   */
  set selected(selected) {
    this._selected = selected
  }

  /**
   * @returns {boolean}
   */
  get mediaDeleted() {
    return this._mediaDeleted
  }

  /**
   * @param {boolean} selected
   */
  set mediaDeleted(mediaDeleted) {
    this._mediaDeleted = mediaDeleted
  }

  // ---

  /**
   * @returns {string|null}
   */
  get filetype() {
    return this._filetype
  }

  /**
   * @param {string|null} filetype
   */
  set filetype(filetype) {
    this._filetype = filetype
  }

  // ---

  /**
   * @returns {string|null}
   */
  get thumbnail() {
    return this._thumbnail
  }

  /**
   * @param {string|null} thumbnail
   */
  set thumbnail(thumbnail) {
    this._thumbnail = thumbnail
  }

  /**
   * @returns {string}
   */
  get sourceFileType() {
    return this._sourceFileType
  }

  /**
   * @returns {string|null}
  */
  get originAssetId() {
    return this._originAssetId
  }

  /**
   * @param {string|null} originAssetId
  */
  set originAssetId(originAssetId) {
    this._originAssetId = originAssetId
  }

  // ---

  /**
   * @param {object|function(Assets.AbstractAsset): object|void} [producer]
   * @param {object} [options]
   * @param {boolean} [options.keepSourceId=false]
   * @returns {this}
   */
  clone(producer, options = {}) {
    const { keepSourceId = false, pregeneratedId } = options
    return produce(this, draft => {
      if (producer !== undefined) {
        const patch = typeof producer === 'function' ? producer(draft) : producer
        if (patch !== undefined) {
          if (isPlainObject(patch)) {
            Object.assign(draft, patch)
          } else if (patch !== draft) {
            throw new Error('`Asset::clone` must return either modified asset itself, or a plain object with changed props')
          }
        }
      }

      // In general, we always want copy to be a *new* asset, with a new uniq id.
      // In rare cases, though, it may be needed to keep id, to maintain some relationships between copy and source.
      if (keepSourceId !== true) {
        // eslint-disable-next-line no-param-reassign,no-underscore-dangle
        draft._id = pregeneratedId || generateClientId()
      }
    })
  }

  clearThumbnail() {
    this._thumbnail = ''
  }

  canBeTrimmed(startTrim, endTrim, hasTransitions) {
    const trimDuration = endTrim - startTrim
    const isEqualStart = Math.floor(this.startTime / 10000) === Math.floor(startTrim / 10000)
    const isEqualEnd = Math.floor(this.endTime / 10000) === Math.floor(endTrim / 10000)

    if (this.startTime <= startTrim && endTrim <= this.endTime) {
      if (!hasTransitions) {
        return true
      }

      if (isEqualStart && isEqualEnd) {
        return true
      }

      if (isEqualStart || isEqualEnd) {
        return this.duration - trimDuration >= ASSET_WITH_TRANSITION_MIN_DURATION
      }

      return startTrim - this.startTime >= ASSET_WITH_TRANSITION_MIN_DURATION
        && this.endTime - endTrim >= ASSET_WITH_TRANSITION_MIN_DURATION
    }

    return false
  }

  getDifference(asset) {
    if (this.id !== asset.id) {
      return null
    }

    const currentAsset = getObjectFields(this)
    const comparedAsset = getObjectFields(asset)
    return Object.keys(currentAsset).reduce((res, key) => {
      if (!isEqual(currentAsset[key], comparedAsset[key])) {
        // eslint-disable-next-line no-param-reassign
        res[key] = currentAsset[key]
      }
      return res
    }, {})
  }

}

export default AbstractAsset
