import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import * as Actions from '~/actions'
import { AppRoutes } from '~/AppRoutes'
import { useAction } from '~/hooks'

export const useOpenProject = project => {
  const setActiveProjectName = useAction(Actions.setActiveProjectName.setActiveProjectName)
  const resetEditor = useAction(Actions.resetEditorBeforeProjectActivated)
  const history = useHistory()

  const openProject = useCallback(() => {
    // NOTE: The project.id won't be passed, because it will be handled from route (history.push)
    // name just used for showing while project loading
    resetEditor()
    setActiveProjectName(project.name)
    history.push(`${AppRoutes.Projects}/${project.id}`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ project ])

  return openProject
}
