export const getStaticAssetsByLayerIndex = (
  staticAssets, layerIds
) => staticAssets.reduce((result, asset) => {
  const current = { ...result }
  const index = layerIds.indexOf(asset.layerId)
  if (index >= 0) {
    if (!current[index]) current[index] = []
    current[index].push(asset)
  }
  return current
}, {})
