import * as Actions from 'actions'
import { maxBy, minBy, partition, throttle } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { DRAGNDROP_TYPE, TRANSITIONS } from '~/enums'
import Asset from '~/models/Asset'
import * as Selectors from '~/selectors'
import { pixel2Time } from '~/Util'

const TRANSITION_PLACEHOLDER_OFFSET = 8

function getNeighborAssets(assets, asset) {
  if (asset === undefined) {
    return { right: null, left: null }
  }
  const transitionCapableLayerAssets = Asset.getLayerAssets(assets, asset.layerId)
    .filter(layerAsset => layerAsset.canHaveTransition)
  const getStartTime = x => x.startTime
  const [ left, right ] = partition(
    transitionCapableLayerAssets.filter(el => el.id !== asset.id), x => (
      getStartTime(x) < getStartTime(asset)
    )
  )
  return { left: maxBy(left, getStartTime), right: minBy(right, getStartTime) }
}

function getStrictNeighborAssets(assets, asset) {
  if (asset === undefined) {
    return { right: null, left: null }
  }

  const { left, right } = getNeighborAssets(assets, asset)
  return {
    left: left?.endTime === asset.startTime ? left : null,
    right: right?.startTime === asset.endTime ? right : null,
  }
}

function useDropTransitionItemHandler(assetRef, targetAsset, scale) {
  const assetId = targetAsset.id
  const dispatch = useDispatch()
  const assets = useSelector(Selectors.getAssets)
  const transitions = useSelector(state => Selectors.getTransitionAssets(state))
  const asset = assets.find(el => el.id === assetId)
  const { left, right } = getStrictNeighborAssets(assets, asset)

  return (dropItem, clientOffset) => {
    // drop of transition won't happen if cursor is not in start or
    // end of a media clip, so time < DELTA means start of the clip
    // otherwise we are in the end
    const assetRect = assetRef.current.getBoundingClientRect()
    const time = pixel2Time(clientOffset.x - assetRect.x, scale)

    const midTime = asset.duration / 2
    const isFirstHalf = time < midTime

    const leftVideoAssetId = (!isFirstHalf && right) || (isFirstHalf && right && !left)
      || dropItem.transitionType === TRANSITIONS.FADEOUT ? assetId : left?.id
    const rightVideoAssetId = (isFirstHalf && left) || (!isFirstHalf && left && !right)
      || dropItem.transitionType === TRANSITIONS.FADEIN ? assetId : right?.id

    const { layerId } = asset
    switch (dropItem.type) {
      case DRAGNDROP_TYPE.TRANSITION_ITEM: {
        dispatch(Actions.timeline.createNewTransitionForAsset(
          dropItem,
          dropItem.transitionType !== TRANSITIONS.FADEIN ? leftVideoAssetId : null,
          dropItem.transitionType !== TRANSITIONS.FADEOUT ? rightVideoAssetId : null,
          { layerId }
        ))
        break
      }
      case DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET: {
        const transition = transitions.find(tr => tr.id === dropItem.id)
        dispatch(Actions.timeline.reattachTransition(
          transition.id,
          dropItem.transitionType !== TRANSITIONS.FADEIN ? leftVideoAssetId : null,
          dropItem.transitionType !== TRANSITIONS.FADEOUT ? rightVideoAssetId : null,
          transition.leftVideoAssetId,
          transition.rightVideoAssetId
        ))
        break
      }
      default:
        break
    }
  }
}

function useTransitionPlaceholderPosition(assetRef, asset, scale) {
  const assets = useSelector(Selectors.getAssets)
  const { left, right } = getStrictNeighborAssets(assets, asset)
  const transitions = useSelector(state => Selectors.getTransitionAssets(state))

  const assetLeftTransition = transitions.find(tr => tr.isAttachedTo(asset.id, 'right'))
  const assetRightTransition = transitions.find(tr => tr.isAttachedTo(asset.id, 'left'))
  const leftNeighborTransition = transitions.find(tr => tr.isAttachedTo(left?.id, 'left'))
  const rightNeighborTransition = transitions.find(tr => tr.isAttachedTo(right?.id, 'right'))

  return (item, clientOffset) => {
    // current asset already has 2 transition
    if (assetLeftTransition && assetRightTransition) {
      return 0
    }

    const assetRect = assetRef.current.getBoundingClientRect()
    let transitionType
    switch (item.type) {
      case DRAGNDROP_TYPE.TRANSITION_ITEM:
        transitionType = item.transitionType
        break
      case DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET: {
        // eslint-disable-next-line no-shadow
        const transition = assets.find(el => el.id === item.id)
        transitionType = transition.type
        break
      }
      default:
        break
    }

    const time = pixel2Time(clientOffset.x - assetRect.x, scale)
    const midTime = asset.duration / 2
    const isFirstHalf = time < midTime
    // drag in start of media
    const applyToStart = isFirstHalf
    // drag in end of media
    const applyToEnd = !isFirstHalf

    // any other transitions except fadein and fadeout will have similar behaviour
    if (transitionType !== TRANSITIONS.FADEOUT && transitionType !== TRANSITIONS.FADEIN) {
      if (!left && !right) {
        return 0
      }
      if (!left && right) {
        if (right.startTime !== asset.endTime || assetRightTransition || rightNeighborTransition) {
          return 0
        }
        return assetRect.width - TRANSITION_PLACEHOLDER_OFFSET
      }

      if (left && !right) {
        if (left.endTime !== asset.startTime || assetLeftTransition || leftNeighborTransition) {
          return 0
        }
        return TRANSITION_PLACEHOLDER_OFFSET * (-1)
      }
      // transition can be applied to the start only if there is no another transition, left video
      // asset doesn't have transition at the end and there is some video asset at left
      if (applyToStart && (assetLeftTransition || leftNeighborTransition || left === undefined)) {
        return 0
      }

      // transition can be applied to the end only if there is no another transition, right video
      // asset doesn't have transition at the beginning and there is some video asset at right
      if (applyToEnd && (assetRightTransition || rightNeighborTransition || right === undefined)) {
        return 0
      }
    }

    if (transitionType === TRANSITIONS.FADEOUT) {
      // fadeout transition can be applied in the end of the clip if there is no another transition
      if (assetRightTransition) {
        return 0
      }

      // can't apply fadeout, if right video asset has transition in the end other than fadein
      if (rightNeighborTransition && rightNeighborTransition.type !== TRANSITIONS.FADEIN) {
        return 0
      }

      return assetRect.width - TRANSITION_PLACEHOLDER_OFFSET
    }

    if (transitionType === TRANSITIONS.FADEIN) {
      // fadein transition can be applied in the start of the clip if there is no another transition
      if (assetLeftTransition) {
        return 0
      }

      // can't apply fadein, if left video asset has transition in the end other than fadeout
      if (leftNeighborTransition && leftNeighborTransition.type !== TRANSITIONS.FADEOUT) {
        return 0
      }

      return TRANSITION_PLACEHOLDER_OFFSET * (-1)
    }

    if (applyToStart) {
      return TRANSITION_PLACEHOLDER_OFFSET * (-1)
    }

    if (applyToEnd) {
      return assetRect.width - TRANSITION_PLACEHOLDER_OFFSET
    }

    return 0
  }
}

export default function useAssetDropTarget(asset, assetRef) {
  const scale = useSelector(state => state.timeline.scale)
  const onDropTransitionItem = useDropTransitionItemHandler(assetRef, asset, scale)
  const getTransitionPlaceholderPosition = useTransitionPlaceholderPosition(assetRef, asset, scale)
  const transitionPlaceholderPosition = useMemo(
    () => throttle(getTransitionPlaceholderPosition, 200),
    [ getTransitionPlaceholderPosition ]
  )

  const [ placeholderPosition, setPlaceholderPosition ] = useState(0)

  const [{ isDragOver }, drop ] = useDrop({
    accept: [
      DRAGNDROP_TYPE.TRANSITION_ITEM,
      DRAGNDROP_TYPE.LAYER_TRANSITION_ASSET,
    ],
    collect: monitor => ({
      isDragOver: monitor.isOver(),
    }),
    drop(item, monitor) {
      // if cursor is not near the end or start of the clip
      // don't allow to drop transition
      if (monitor.didDrop() || placeholderPosition === 0) {
        return
      }

      const clientOffset = monitor.getClientOffset()
      onDropTransitionItem(item, clientOffset)
    },
    hover(item, monitor) {
      const clientOffset = monitor.getClientOffset()
      setPlaceholderPosition(transitionPlaceholderPosition(item, clientOffset))
    },
  })

  useEffect(() => {
    if (!isDragOver) {
      setPlaceholderPosition(0)
    }
  }, [ isDragOver ])

  drop(assetRef)

  return { isDragOver, placeholderPosition }
}
