import { MediaRecordingAsset } from 'models/Asset'
import { createSelector } from 'reselect'

// export const getSourceFilesGroup = (state: RootState, type: SourceFilesTypes) => {
//   const group = state.sourceFiles[type]
//   if (group === undefined) {
//     throw new Error(`Unknown source files type: ${type}`)
//   }
//   return group
// }

export const getMediaRecordingAsset = (state: RootState) => state.layer.assets
  .find(asset => asset instanceof MediaRecordingAsset && asset.isRecording) as MediaRecordingAsset

/**
* @return {number}
*/
export const selectPreviewVideoSize = createSelector(
  [ state => state.sourceFiles ],
  sourceFiles => sourceFiles.previewVideoSize
)
