import ProjectAsset from '~/models/ProjectData/ProjectAsset'

/**
 * @memberOf ProjectData
 * @extends ProjectData.ProjectAudioAsset
 *
 */

class ProjectAudioAsset extends ProjectAsset {

  constructor(jsonData) {
    const { volume, muted, mediaFileDuration } = jsonData
    super(jsonData)

    this._volume = volume
    this._muted = muted
    this._mediaFileDuration = mediaFileDuration
  }

  get volume() {
    return this._volume
  }

  /**
   * @param {number} volume
   */
  set volume(volume) {
    this._volume = volume
  }

  get muted() {
    return this._muted
  }

  /**
   * @param {boolean} muted
   */
  set muted(muted) {
    this._muted = muted
  }

  get mediaFileDuration() {
    return this._mediaFileDuration
  }

  /**
   * @param {number} mediaFileDuration
   */
  set mediaFileDuration(mediaFileDuration) {
    this._mediaFileDuration = mediaFileDuration
  }

}

export default ProjectAudioAsset
