import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectEdition, selectInstallVersion } from 'selectors/app'
import { formatVersionMajorMinor } from 'helpers/versionRoutines'

function AppWindowTitle() {
  const installVersion = useSelector(selectInstallVersion)
  const edition = useSelector(selectEdition)
  const { favIcon: userFavIcon, appTitle, appDescription } = useSelector(state => state.user)
  const titleText = appTitle || (edition ? `${__CFG__.TITLE} ${formatVersionMajorMinor(installVersion)} ${edition} Edition` : __CFG__.TITLE)
  const favIcon = userFavIcon || __CFG__.FAVICON
  const descriptionText = appDescription || __CFG__.DESCRIPTION
  return (
    <Helmet>
      <link rel="icon" href={favIcon} />
      <title>{titleText}</title>
      <meta name="description" content={descriptionText} />
      {/* for HTTP content (HLS-streams) in HTTPS-page */}
      {__API_HTTPS__ && __API_HTTPS__ === 'true' && <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />}
    </Helmet>

  )
}

export default AppWindowTitle
