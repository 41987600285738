export const getParsedBufferedDiapasons = videoPlayer => {
  let bufferedDiapasons = []
  for (let i = 0; i < videoPlayer.buffered.length; i++) {
    bufferedDiapasons = [
      ...bufferedDiapasons,
      [ videoPlayer.buffered.start(i), videoPlayer.buffered.end(i) ],
    ]
  }
  return bufferedDiapasons
}
