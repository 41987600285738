export const HistoryActionType = {
  AssetMovedAction: 'AssetMovedAction',
  AssetCreationAction: 'AssetCreationAction',
  AssetDeletionAction: 'AssetDeletionAction',
  AssetExpandedAction: 'AssetExpandedAction',
  AssetParamsUpdatedAction: 'AssetParamsUpdatedAction',
  AssetSettingsUpdatedAction: 'AssetSettingsUpdatedAction',
  AssetSplittedAction: 'AssetSplittedAction',
  LayerCreationAction: 'LayerCreationAction',
  LayersCreationAction: 'LayersCreationAction',
  LayerDeletionAction: 'LayerDeletionAction',
  MultipleAssetsMovedAction: 'MultipleAssetsMovedAction',
  PlaceholderDeletionAction: 'PlaceholderDeletionAction',
  LayerMovingAction: 'LayerMovingAction',
  ReattachTransitionAction: 'ReattachTransitionAction',
  RippleDeleteAction: 'RippleDeleteAction',
  RippleTrimAction: 'RippleTrimAction',
  TransitionCreationAction: 'TransitionCreationAction',
  CopiedAssetCreationAction: 'CopiedAssetCreationAction',
  AssetsMultipleSplittedAction: 'AssetsMultipleSplittedAction',
}
