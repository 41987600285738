export const calculateBufferedSecondsInDiapasons = (
  bufferedDiapasons, leftBoundS, rightBoundS
) => !bufferedDiapasons.length ? 0 : bufferedDiapasons.reduce((sum, [ startS, endS ]) => {
  let res = 0
  if (leftBoundS <= startS && rightBoundS >= endS) {
    res = (endS - startS)
  } else if (leftBoundS > startS && leftBoundS < endS && rightBoundS >= endS) {
    res = endS - leftBoundS
  } else if (leftBoundS <= startS && rightBoundS > startS && rightBoundS < endS) {
    res = rightBoundS - startS
  } else if (leftBoundS >= startS && rightBoundS <= endS) {
    res = rightBoundS - leftBoundS
  }
  return sum + res
}, 0)
