import * as ActionTypes from '~/actions/ActionTypes'
import { TASK_STATUS, RENDER_BACKEND } from '~/enums'
import { getEditingTaskStatus } from '~/ServerAPI'
import { getEditingTaskStatusShotStack } from '~/ShotStackServerAPI'

const CHECK_STATUS_INTERVAL = 2000

export const updateEditingTaskStatus = task => ({
  type: ActionTypes.UPDATE_EDITING_TASK_STATUS,
  payload: { task },
})

export const updateEditingTaskStatusError = err => ({
  type: ActionTypes.UPDATE_EDITING_TASK_STATUS,
  payload: err,
  error: true,
})

function getTaskStatus(id) {
  switch (__CFG__.RENDER_BACKEND) {
    case RENDER_BACKEND.SOLVEIG:
      return getEditingTaskStatus(id)
    case RENDER_BACKEND.SHOTSTACK:
      return getEditingTaskStatusShotStack(id)
    default:
      return undefined
  }
}

export async function checkStatusLongPolling(dispatch, taskId, fileId) {
  try {
    const data = await getTaskStatus(taskId)
    const { status, progress, error, outputUrls } = data
    if (error) {
      dispatch(updateEditingTaskStatusError({
        id: taskId,
        message: error.message,
      }))
      return
    }
    const task = {
      id: taskId,
      status,
      progress,
    }

    if (fileId) {
      task.fileId = fileId
    }

    if (outputUrls) {
      task.outputUrls = outputUrls
    }
    dispatch(updateEditingTaskStatus(task))
    if (status !== TASK_STATUS.COMPLETED && status !== TASK_STATUS.ABORTED) {
      setTimeout(checkStatusLongPolling, CHECK_STATUS_INTERVAL, dispatch, taskId, fileId)
    }
  } catch (error) {
    dispatch(updateEditingTaskStatusError({
      id: taskId,
      message: error.toString(),
    }))
  }
}
