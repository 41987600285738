/* eslint-disable react/no-danger */
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

export const SvgInline = ({ url, className, style }) => {
  const [ svg, setSvg ] = useState(null)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isErrored, setIsErrored ] = useState(false)

  useEffect(() => {
    fetch(url)
      .then(res => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
  }, [ url ])

  return (
    <div
      style={style}
      className={classNames(className, `svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`)}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  )
}
