import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext } from 'react'
import { IMaskInput } from 'react-imask'
import { ReactComponent as FillToFrame } from '~/assets/settings/fill_to_frame.svg'
import { ReactComponent as FitToFrame } from '~/assets/settings/fit_to_frame.svg'
import Input from '~/components/base/Input'
import { TEXT_POSITION as POSITION } from '~/enums'
import PositionSelector from '../PositionSelector/PositionSelector'
import { SettingsButton } from '../components'
import './Position.scss'

const OFFSET_POSITIONS = [ POSITION.LEFT, POSITION.TOP, POSITION.RIGHT, POSITION.BOTTOM ]
const EMPTY_OFFSET = { top: null, bottom: null, left: null, right: null }

function getInitialOffset(pos) {
  let patch
  switch (pos) {
    case POSITION.LEFT:
    case POSITION.TOP:
    case POSITION.BOTTOM:
    case POSITION.RIGHT:
      patch = { [pos]: 0 }
      break
    case POSITION.TOP_LEFT:
      patch = { [POSITION.TOP]: 0, [POSITION.LEFT]: 0 }
      break
    case POSITION.BOTTOM_LEFT:
      patch = { [POSITION.BOTTOM]: 0, [POSITION.LEFT]: 0 }
      break
    case POSITION.TOP_RIGHT:
      patch = { [POSITION.TOP]: 0, [POSITION.RIGHT]: 0 }
      break
    case POSITION.BOTTOM_RIGHT:
      patch = { [POSITION.RIGHT]: 0, [POSITION.BOTTOM]: 0 }
      break
    default:
      patch = {}
  }

  return { ...EMPTY_OFFSET, ...patch }
}

function MaskInput(props) {
  const { value } = props
  return (
    <IMaskInput
      {...props}
      // 0-100%
      mask={/^[0-9][0-9]?%$|^100%$|^[%]$|^[0-9][0-9]?$|^100$/}
      value={value === null ? '' : `${parseInt(value)}%`}
    />
  )
}

function OffsetInput(props) {
  const [ isFocused, setIsFocused ] = React.useState(false)
  const onBlur = useCallback(
    () => {
      setIsFocused(false)
    },
    []
  )
  const onFocus = useCallback(
    e => {
      setIsFocused(true)

      // Select text including '%' symbol, for convenient editing
      e.target.select()
    },
    []
  )

  const { t } = useContext(TranslationContext)


  const { value, ...rest } = props

  return (
    <Input
      {...rest}
      onFocus={onFocus}
      onBlur={onBlur}
      autoComplete="off"
      inputComponent={MaskInput}
      inputProps={{
        value,
        placeholder: isFocused ? '0%' : t('SETTINGS_TEXT_AUTO'),
      }}
    />
  )
}

export default function Position({
  onChangePosition,
  position,
  offset,
  rotation,
  onClickFitToFrame,
  onClickFillToFrame,
  showFit = false,
  fitToFrame = false,
  fillFrame = false,
}) {
  const { t } = useContext(TranslationContext)

  const handleChange = useCallback(
    e => {
      const { value, name } = e.target
      onChangePosition({
        ...offset,
        [name]: value === '' ? null : parseInt(value),
      })
    },
    [ onChangePosition, offset ]
  )

  const onChangePositionChecked = useCallback(
    e => {
      const pos = e.target.value
      onChangePosition(getInitialOffset(pos), pos)
    },
    [ onChangePosition ]
  )

  return (
    <div className="position">
      <div className="position__control">
        <PositionSelector
          value={position}
          onChange={onChangePositionChecked}
        />
      </div>
      <div className="position__box">
        {OFFSET_POSITIONS.map(pos => (
          <div className={`position__item position__item--${pos}`} key={pos}>
            <OffsetInput
              name={pos}
              onChange={handleChange}
              value={offset[pos]}
            />
          </div>
        ))}
      </div>
      <If condition={showFit}>
        <div className="position__frame">
          <SettingsButton
            isActive={fitToFrame}
            onClick={() => onClickFitToFrame(rotation)}
            icon={<FitToFrame />}
            title={t('SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP')}
            data-lang-id="SETTINGS_BTN_FIT_TO_FRAME"
          >
            {t('SAVE_SETTINGS_BTN_FIT_TO_FRAME')}
          </SettingsButton>
          <SettingsButton
            isActive={fillFrame}
            onClick={() => onClickFillToFrame(rotation)}
            icon={<FillToFrame />}
            title={t('SETTINGS_BTN_FILL_FRAME_TOOLTIP')}
            data-lang-id="SETTINGS_BTN_FILL_FRAME"
          >
            {t('SETTINGS_BTN_FILL_FRAME')}
          </SettingsButton>
        </div>
      </If>
    </div>
  )
}
