import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext, useEffect, useState } from 'react'
import Button from '~/components/base/Button'
import {
  Dialog,
  DialogContent,
  DialogTitle
} from '~/components/base/Dialog/Dialog'
import { InputClearable } from '~/components/base/InputClearable/InputClearable'
import { DUPLICATED_PROJECT_NAME_POSTFIX } from '~/constant'
import './ProjectSaveAsDialog.scss'

export const ProjectSaveAsDialog = ({ modalDialog, projectName }) => {
  const [ name, setName ] = useState(
    () => `${projectName}${DUPLICATED_PROJECT_NAME_POSTFIX}`
  )

  const handleChangeName = ({ target: { value } }) => {
    const trimmedName = value.trim()
    setName(trimmedName)
  }

  const handleClearName = () => {
    setName('')
  }

  const handleSave = () => {
    modalDialog.confirm(name)
  }

  useEffect(() => {
    setName(`${projectName}${DUPLICATED_PROJECT_NAME_POSTFIX}`)
  }, [ projectName ])

  const { t } = useContext(TranslationContext)

  return (
    <If condition={modalDialog.active}>
      <Dialog
        className="save-as-dialog"
        open
        modalDialog={modalDialog}
        onClose={modalDialog.cancel}
      >
        <DialogTitle className="save-as-dialog__header">
          {t('PROJECTS_TITLE_SAVE_PROJECT_AS')}
          ..
        </DialogTitle>
        <DialogContent className="save-as-dialog__content">
          <InputClearable
            placeholder={t('PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME')}
            onChange={handleChangeName}
            value={name}
            onClearInput={handleClearName}
          />

          <Button
            type="submit"
            className="save-as-dialog__button"
            primary
            onClick={handleSave}
            autoFocus
          >
            {t('PROJECTS_BTN_SAVE')}
          </Button>
        </DialogContent>
      </Dialog>
    </If>
  )
}
