/**
 * @namespace Assets
 */

/**
 * @typedef {Assets.VideoAsset|Assets.TextAsset|Assets.PlaceholderAsset|Assets.AudioAsset|Assets.TransitionAsset|Assets.ImageAsset|Assets.MediaAsset|Assets.UploadableAsset} Asset
 */

import AbstractAsset from './Asset'
import MediaAsset from './MediaAsset'

import VideoAsset from './VideoAsset'
import TextAsset from './TextAsset'
import AudioAsset from './AudioAsset'
import ImageAsset from './ImageAsset'
import PlaceholderAsset from './PlaceholderAsset'
import TransitionAsset from './TransitionAsset'
import UploadableAsset from './UploadableAsset'
import MediaRecordingAsset from './MediaRecordingAsset'
import TimelineCommonAsset from './TimelineCommonAsset'

export {
  VideoAsset,
  TextAsset,
  AudioAsset,
  ImageAsset,
  TransitionAsset,
  PlaceholderAsset,
  MediaAsset,
  UploadableAsset,
  MediaRecordingAsset,
  TimelineCommonAsset
}

export default AbstractAsset
