import * as API from '~/ServerAPI'

export const importMediafileFromAmazon = (
  { secretKey, bucketName, accessKeyId }
) => async () => {
  try {
    const { id, error } = await API.importFromAmazon({ secretKey, bucketName, accessKeyId })
    return { id, error }
  } catch (err) {
    return { error: err }
  }
}
