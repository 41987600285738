/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { httpClient } from 'ServerAPI'
import { ImageCategory } from 'components/AssetPanels/Images/types/ImageCategory'
import { FILE_STATUS } from 'enums'
import { orderBy } from 'lodash'
import { ImageAsset } from 'models/Asset'
import { BaseQueryParams } from 'types/base-query-params'

export type ImagesQueryParams = BaseQueryParams & {
  category: ImageCategory;
  source: string;
}

type ApiCategory = {
  name: string;
  parent: string;
  id: string;
}

export const fetchImagesFiles = (params: ImagesQueryParams) => {
  const { category, count, start, order, search, sort, source = 'json' } = params
  return httpClient.get<{ entries: [ImageAsset] }>(`/api/imagelib_files/${source}`, { params: {
    page: Math.floor(start / count),
    count,
    category_id: category.id !== 'all' ? category.id : undefined,
    sort_field: sort,
    order_field: order,
    search,
  } }).then(({ data }) => ({ data:
      { ...data, entries: data.entries.map(i => ({ ...i, status: FILE_STATUS.READY })) } })).catch(() => [])
}

export async function fetchImagesCategories(source = 'json') {
  const response = await httpClient.get<{ entries: Array<ApiCategory> }>(`api/imagelib_files/${source}/categories`,
    { params: { page: 0, count: 10_000 } })

  const entries = orderBy(response.data.entries, [ 'name' ], [ 'asc' ])

  const mapCategory = (level = 0) => (category: ApiCategory): ImageCategory => {
    const { name, id } = category
    const childernCategories = entries.filter(c => c.parent === id)
    if (!childernCategories.length) {
      return {
        name,
        id,
        level,
      }
    }
    return {
      name,
      id,
      level,
      children: childernCategories.map(mapCategory(level + 1)),
    }
  }

  return entries.map(mapCategory(0))
    // remove cildren from 0 level
    .filter((c, _, a) => !(a.filter(p => !!p.children?.length)
      .find(p => p.children!.find(child => child.id === c.id))))
}
