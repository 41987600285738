import { clearAssetsSelection } from 'actions/layer'
import { checkIntersectRect } from 'helpers/checkIntersectRect'
import { useAction } from 'hooks/utils'
import { isEmpty, isEqual } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLayers } from '~/selectors'
import { selectPreviewVideoSize } from '~/selectors/sourceFiles'
import * as ActionTypes from '~/actions/ActionTypes'
import { LAYER_ITEM_CONTAINER_CLASSNAME,
  LAYERS_CONTROL_RIGHT_X, SCROLLBAR_RIGHT_X_REDUCING_DELTA,
  LAYERS_Y_SCROLL_SPEED, LAYERS_MAX_X_SCROLL_SPEED, LAYERS_MIN_X_SCROLL_SPEED } from '~/config/constants/layer'

export const useAllocationAria = (layersRef, scrollbarRef) => {
  const allocationAriaRef = useRef()
  const isMouseDown = useRef(false)
  const allocationAriaStartCoords = useRef({ startX: 0, startY: 0 })
  const scrollTopInterval = useRef(false)
  const scrollLeftInterval = useRef(false)
  const intersectedAssetIds = useRef([])

  const dispatch = useDispatch()
  const previewSize = useSelector(selectPreviewVideoSize)
  const clearSelection = useAction(clearAssetsSelection)
  const allLayers = useSelector(getLayers)

  const onSelectElements = useCallback(intersectedAssetIds => {
    if (!isEmpty(intersectedAssetIds)) {
      dispatch({
        type: ActionTypes.TOGGLE_SELECTED_ASSETS,
        payload: { assetsIds: intersectedAssetIds },
      })
    } else {
      clearSelection()
    }
  }, [ dispatch, clearSelection ])

  const getIntersectedAssets = useCallback((assets, allocationAriaElement) => {
    const intersectedAssetIds = new Set()
    assets.forEach(asset => {
      const assetId = asset.getAttribute('data-id')
      if (checkIntersectRect(asset.getBoundingClientRect(),
        allocationAriaElement.getBoundingClientRect())) {
        intersectedAssetIds.add(assetId)
      }
    })
    return Array.from(intersectedAssetIds)
  }, [])

  const createAllocationAria = useCallback((event, containerX, containerY, scrollHeight) => {
    const scrollTop = scrollbarRef.current.getScrollTop()
    const scrollLeft = scrollbarRef.current.getScrollLeft()
    const mouseX = event.clientX + scrollLeft
    const mouseY = event.clientY + scrollTop
    const { startX, startY } = allocationAriaStartCoords.current

    const left = startX > mouseX ? mouseX - containerX : startX - containerX
    const right = startX > mouseX
      ? startX - containerX : mouseX - containerX
    allocationAriaRef.current.style.left = `${left}px`
    allocationAriaRef.current.style.right = `${right}px`
    allocationAriaRef.current.style.width = `${right - left}px`

    const top = startY > mouseY
      ? mouseY - containerY : startY - containerY
    const bottom = startY > mouseY
      ? startY - containerY : mouseY - containerY
    const height = bottom - top
    allocationAriaRef.current.style.top = `${top}px`
    allocationAriaRef.current.style.bottom = `${bottom}px`
    allocationAriaRef.current.style.height = `${height > scrollHeight ? scrollHeight : height}px`
  }, [ scrollbarRef ])

  useEffect(() => {
    isMouseDown.current = false
    const scrollBarLayersContaner = scrollbarRef.current.container
    const allAssets = document.getElementsByClassName(LAYER_ITEM_CONTAINER_CLASSNAME)
    const scrollHeight = scrollbarRef.current.getScrollHeight()

    function onMouseDown(event) {
      isMouseDown.current = false
      allocationAriaRef.current.style.display = 'none'
      window.getSelection().removeAllRanges()
      if ([ 'layer', 'layer__items', 'layer__control', 'scroll-container__content' ]
        .includes(event.target.className)) {
        isMouseDown.current = true
        allocationAriaStartCoords.current.startX = event.clientX
        + scrollbarRef.current.getScrollLeft()
        allocationAriaStartCoords.current.startY = event.clientY
        + scrollbarRef.current.getScrollTop()
        allocationAriaRef.current.style.display = 'block'
      }
    }

    function onMouseUp() {
      if (isMouseDown.current) {
        allocationAriaRef.current.style.width = 0
        allocationAriaRef.current.style.height = 0
        allocationAriaRef.current.style.left = 0
        allocationAriaRef.current.style.top = 0
        allocationAriaRef.current.style.display = 'none'
        isMouseDown.current = false
        window.getSelection().removeAllRanges()
        intersectedAssetIds.current = []
      }
      clearInterval(scrollTopInterval.current)
      scrollTopInterval.current = false
      clearInterval(scrollLeftInterval.current)
      scrollLeftInterval.current = false
    }

    function onMouseMove(event) {
      const { top, bottom, left, right,
        x: containerX,
        y: containerY, width, height } = scrollBarLayersContaner.getBoundingClientRect()
      if (isMouseDown.current && allocationAriaRef.current) {
        onScrollY(event, bottom, top, height)
        onScrollX(event, left, right, width)
        window.getSelection().removeAllRanges()
        createAllocationAria(event, containerX, containerY, scrollHeight)
        const newIntersectedAssetIds = getIntersectedAssets(Array.from(allAssets),
          allocationAriaRef.current, event.target.dataset.id)
        if (!isEqual(newIntersectedAssetIds, intersectedAssetIds.current)) {
          onSelectElements(newIntersectedAssetIds)
          intersectedAssetIds.current = newIntersectedAssetIds
        }
      }
    }

    function onScrollY(event, bottom, top, height) {
      clearInterval(scrollTopInterval.current)
      scrollTopInterval.current = false

      const maxScroll = Math.ceil(scrollbarRef.current.getScrollHeight() - height)
      if (bottom <= event.clientY && !scrollTopInterval.current) {
        scrollTopInterval.current = setInterval(() => {
          const newData = scrollbarRef.current.getScrollTop() + LAYERS_Y_SCROLL_SPEED
          scrollbarRef.current.scrollTop(newData > maxScroll ? maxScroll : newData)
        })
      }

      if (top >= event.clientY && !scrollTopInterval.current) {
        scrollTopInterval.current = setInterval(() => {
          const newData = scrollbarRef.current.getScrollTop() - LAYERS_Y_SCROLL_SPEED
          scrollbarRef.current.scrollTop(newData < 0 ? 0 : newData)
        })
      }
    }

    function onScrollX(event, left, right, width) {
      clearInterval(scrollLeftInterval.current)
      scrollLeftInterval.current = false

      const maxLeftScroll = scrollbarRef.current.getScrollWidth() - width
      const correctlyRightX = right - SCROLLBAR_RIGHT_X_REDUCING_DELTA

      if (correctlyRightX <= (event.clientX + 5)
        && !scrollLeftInterval.current) {
        scrollLeftInterval.current = setInterval(() => {
          const scrollSpeed = (event.clientX + 5) - correctlyRightX > 10
            ? LAYERS_MAX_X_SCROLL_SPEED : LAYERS_MIN_X_SCROLL_SPEED

          const newData = scrollbarRef.current.getScrollLeft() + scrollSpeed
          scrollbarRef.current.scrollLeft(newData > maxLeftScroll ? maxLeftScroll : newData)
        })
      }

      if (event.clientX <= LAYERS_CONTROL_RIGHT_X && !scrollLeftInterval.current) {
        scrollLeftInterval.current = setInterval(() => {
          const scrollSpeed = LAYERS_CONTROL_RIGHT_X - event.clientX > 10
            ? LAYERS_MAX_X_SCROLL_SPEED : LAYERS_MIN_X_SCROLL_SPEED

          const newData = scrollbarRef.current.getScrollLeft() - scrollSpeed
          scrollbarRef.current.scrollLeft(newData < 0 ? 0 : newData)
        })
      }
    }

    scrollBarLayersContaner.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('mousemove', onMouseMove)
    return () => {
      scrollBarLayersContaner.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mouseup', onMouseUp)
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [ getIntersectedAssets, createAllocationAria,
    onSelectElements, previewSize, layersRef, dispatch, scrollbarRef, allLayers ])
  return { allocationAriaRef }
}
