import { getGreaterEndTime } from '~/helpers/getGreaterEndTime'

const useTimelineVideoPlayerAssetsProps = ({
  playingMediaAssets,
  assetToPlayerId,
  rect,
  onReady,
  onMounted,
  onBufferingProgress,
  transitionVideoAsset,
  videoRefs,
  isPlaying,
  restAggregatedPlayerProps,
  mode,
}) => {
  const getTimelineAssetVideoPlayerProps = (asset, isBackground = false) => {
    const { id, endTime, width, height } = asset
    const assetIndex = playingMediaAssets.findIndex(a => a.id === asset.id)
    const aspectRatio = width / height // determine the original aspect ratio
    let playerAssetProps = {
      ...restAggregatedPlayerProps,
      asset,
      key: id,
      id: assetToPlayerId(asset),
      playing: isPlaying,
      width: rect.width,
      height: rect.width / aspectRatio, // sizing preview height to original ratio
      // play only audio track from video assets placed under the top video
      playAudioOnly: playingMediaAssets.filter((_, i) => i < assetIndex)
        .some(a => !a?.settings?.isChromaKeyEnabled),
      onReady,
      onMounted,
      onBufferingProgress,
      // NOTE: Listen progress callback from longest active asset (including transition asset) after slider time
      shouldTrackProgress: (
        getGreaterEndTime(playingMediaAssets.concat(transitionVideoAsset || [])) === endTime
      ),
      isBackground: false,
      active: !!playingMediaAssets.find(a => a.id === id),
      mode,
      videoRef: videoRefs[id],
    }
    if (isBackground) {
      playerAssetProps = {
        ...playerAssetProps,
        playing: false,
        style: { opacity: 0 },
        isBackground: true,
      }
    }
    // NOTE: hidding both videos while transition
    if (transitionVideoAsset?.id) {
      playerAssetProps = {
        ...playerAssetProps,
        style: { opacity: 0 },
      }
    }

    return playerAssetProps
  }

  return { getTimelineAssetVideoPlayerProps }
}

export default useTimelineVideoPlayerAssetsProps
