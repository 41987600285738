import React from 'react'
import Menu from '@material-ui/core/Menu'
import RadioGroup from '@material-ui/core/RadioGroup'
import { MEDIA_FILE_FILTERS, MEDIA_FILE_FILTERS_ORDERED } from '~/enums'
import { MenuOption } from '../../base/MenuSection/MenuSection'
import './MediaSort.scss'

export default function MediaFilter({ anchorEl, handleClose, value = '', onChange, open }) {
  return (
    <Menu
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: 'media-sort',
      }}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
    >
      <RadioGroup
        className="media-sort__radio-group"
        value={value}
        onChange={onChange}
        name="sort"
      >
        {Object.entries(MEDIA_FILE_FILTERS_ORDERED).map(([ key, value ], i) => (
          <MenuOption key={key} value={MEDIA_FILE_FILTERS[key]} label={value} underline={i === 0} />
        ))}
      </RadioGroup>
    </Menu>
  )
}
