import { useEffect, useRef } from 'react'

export const useDebounceWithRef = (ref, cb, eventType, time = 1000) => {
  const timeOut = useRef()

  useEffect(() => {
    const node = ref.current
    const fn = () => {
      clearInterval(timeOut.current)
      timeOut.current = setTimeout(() => {
        cb()
      }, time)
    }
    if (node) {
      node.addEventListener(eventType, fn)
    }
    return () => {
      if (node) {
        node.removeEventListener(eventType, fn)
      }
    }
  }, [ ref, cb, eventType, time ])
}
