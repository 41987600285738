import * as generatedRecordingActions from 'reducers/recording'
import * as recordingActionCreators from './recording'
import * as timeline from './timeline'
import * as layer from './layer'
import * as project from './project'
import * as playback from './playback'
import * as user from './user'
import * as mainView from './mainView'
import * as sourceFiles from './sourceFiles'
import * as upload from './upload'
import * as history from './historyActions'
import * as importFromUrl from './importFromUrl'
import * as loadProject from './projectData/loadProject'
import * as saveProject from './projectData/saveProject'
import * as getProjects from './projectData/getProjects'
import * as deleteProject from './projectData/deleteProject'
import * as renameProject from './projectData/renameProject'
import * as clearEditor from './projectData/clearEditor'
import * as setSearchValue from './projectData/setSearchValue'
import * as allowSaveEmpty from './projectData/allowSaveEmpty'
import * as initNewProject from './projectData/initNewProject'
import * as activateProject from './projectData/activateProject'
import * as setActiveProjectName from './projectData/setActiveProjectName'
import * as endProjectLoading from './projectData/endProjectLoading'
import * as clearActiveProjectError from './projectData/clearActiveProjectError'
import * as showBlankProjectInList from './projectData/showBlankProjectInList'
import * as hideBlankProjectFromList from './projectData/hideBlankProjectFromList'
import * as setLatestSavedTime from './projectData/setLatestSavedTime'
import * as saveProjectAs from './projectData/saveProjectAs'
import * as duplicateProject from './projectData/duplicateProject'
import * as projectData from './projectData'
import { resetEditorBeforeProjectActivated } from './projectData/resetEditorBeforeProjectActivated'
import * as importFromAmazon from './AmazonImport'
import * as preview from './preview'
import * as folders from './folders'
import * as importLocalFile from './importLocalFile'
import * as transitionUndoRedoActions from './undoRedo/transitionUndoRedoActions'
import * as app from './app'

const recording = { ...generatedRecordingActions, ...recordingActionCreators }

export {
  app,
  timeline,
  layer,
  project,
  playback,
  user,
  mainView,
  sourceFiles,
  upload,
  importFromUrl,
  loadProject,
  saveProject,
  getProjects,
  deleteProject,
  renameProject,
  clearEditor,
  setSearchValue,
  allowSaveEmpty,
  initNewProject,
  activateProject,
  setActiveProjectName,
  endProjectLoading,
  clearActiveProjectError,
  showBlankProjectInList,
  hideBlankProjectFromList,
  setLatestSavedTime,
  saveProjectAs,
  duplicateProject,
  resetEditorBeforeProjectActivated,
  importFromAmazon,
  preview,
  folders,
  importLocalFile,
  history,
  recording,
  projectData,
  transitionUndoRedoActions
}
