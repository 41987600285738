import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import classNames from 'classnames'
import React from 'react'
import { ReactComponent as ExpandMoreIcon } from '~/assets/settings/navigation_expand.svg'
import './Expansion.scss'

export default function CustomizedExpansionPanel({ className, caption, children }) {
  return (
    <div className={classNames(className, 'expansion-panel')}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          {caption}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}
