import { selectProjectForSaving, selectProjectThumbnail } from '~/selectors/projectData'
import { createProject } from './createProject'

export const saveProjectAs = projectName => async (dispatch, getState) => {
  const state = getState()
  const project = selectProjectForSaving(state)
  const thumbnail = selectProjectThumbnail(state)

  const projectStringified = JSON.stringify(project)
  const createdProjectId = await dispatch(
    createProject(projectStringified, projectName, thumbnail, project.createTime)
  )

  return createdProjectId
}
