import { useEffect, useRef } from 'react'

export const useInterval = (cb, intervalMs) => {
  const intervalId = useRef(undefined)

  function cancel() {
    clearInterval(intervalId.current)
  }

  useEffect(() => {
    intervalId.current = setInterval(cb, intervalMs)
    return () => clearInterval(intervalId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { cancel }
}
