import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'

import Asset, { TransitionAsset } from 'models/Asset'
import { assetToTimelineDragElement } from 'components/Timeline/DragAndDrop/lib/assetToTimelineDragElement'
import { DraggingItemContext } from 'components/Timeline/DraggingItemProvider'
import { TimelineScrollPositionContext } from 'components/Timeline/ScrollPositionContext'
import { TimelineLeftOffsetContext } from 'components/Timeline/TimelineLeftOffsetContext'
import { TimelineTopOffsetContext } from 'components/Timeline/TimelineTopOffsetContext'
import * as Selectors from 'selectors'
import { XYCoord } from 'react-dnd'
import { getLayerIndexFromY } from 'Util/timeline-coords'
import { snapToLayer } from 'components/Timeline/DragAndDrop/lib/snapToLayer'

import styles from './dropHover.module.scss'

type Props = {
  item: { id: string, type: string }
  clientOffset: XYCoord,
  sourceOffset: XYCoord,
  initialClientOffset: XYCoord,
  initialSourceOffset: XYCoord,
  hoveredAssetId: string,
  ignoredTypes?: string[],
  isOverNewLayer: boolean,
}

function DropHoverOnAssets({ item, ignoredTypes = [],
  clientOffset: { x: clientX, y: clientY }, hoveredAssetId, isOverNewLayer }: Props) {
  const timelineTopOffset = useContext(TimelineTopOffsetContext)
  const timelineLeftOffset = useContext(TimelineLeftOffsetContext)
  const { scrollLeft, scrollTop } = useContext(TimelineScrollPositionContext)
  const { setProps: setDraggingItemProps } = useContext(DraggingItemContext)

  const scale = useSelector((state: RootState) => state.timeline.scale)
  const draggingAsset = useSelector(state => Selectors.selectAssetById(state, item.id))
  const draggingFile = useSelector(state => Selectors.getSourceFileById(state, item.id))
  const asset = draggingAsset || draggingFile

  const transitions = useSelector(Selectors.getTransitionAssets)

  const width = asset ? assetToTimelineDragElement(scale)(asset).width : 0
  const assetsByLayers = useSelector(Selectors.getAssetsByLayers)

  const timelineDragElementsLayers = useMemo(() => Array.from(
    assetsByLayers,
    ([ , value ]) => (value as Asset[])
      .filter(a => a.id !== item?.id)
      .filter(a => !(a instanceof TransitionAsset))
      .map(a => ({ id: a.id, ...assetToTimelineDragElement(scale)(a) }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [ assetsByLayers ])

  if (ignoredTypes.includes(item.type) || isOverNewLayer) return null

  const layerIndex = getLayerIndexFromY({
    y: clientY + scrollTop,
    topOffset: timelineTopOffset,
    layersLength: timelineDragElementsLayers.length,
  })

  const hoveredAssetIndex = timelineDragElementsLayers[layerIndex].findIndex(asset => asset.id === hoveredAssetId)
  const hoveredAsset = hoveredAssetIndex !== -1 ? timelineDragElementsLayers[layerIndex][hoveredAssetIndex] : null

  const offset = timelineLeftOffset - scrollLeft
  const x = clientX - offset

  if (!hoveredAsset) return null

  let defaultActiveOffset = Math.min(0.5 * hoveredAsset.width, 10)
  const hoveredAssetX = hoveredAsset.x + offset
  const hoveredAssetY = snapToLayer({ layerIndex, timelineScrollTop: scrollTop }) + 1
  let showBoth = false

  const leftSideAsset = timelineDragElementsLayers[layerIndex][hoveredAssetIndex - 1]
  const rightSideAsset = timelineDragElementsLayers[layerIndex][hoveredAssetIndex + 1]

  defaultActiveOffset = Math.min(defaultActiveOffset, 0.5 * (leftSideAsset?.width || 20),
    0.5 * (rightSideAsset?.width || 20))


  if (x < (hoveredAsset.x + defaultActiveOffset)) {
    if (leftSideAsset) {
      showBoth = Math.round(hoveredAsset.x - (leftSideAsset.x + leftSideAsset.width)) === 0

      if (showBoth) {
        const leftAssetRightTransition = transitions.find(tr => tr.isAttachedTo(leftSideAsset.id, 'left'))
        const rightAssetLeftTransition = transitions.find(tr => tr.isAttachedTo(hoveredAssetId, 'right'))

        if (leftAssetRightTransition || rightAssetLeftTransition) {
          setDraggingItemProps({ allowDropOnHover: false })
          return null
        }

        setDraggingItemProps({ allowDropOnHover: true,
          detachedMode: true,
          normalizedSourceX: hoveredAsset.x,
          deltaOnDrop: width })

        return (
          <OverlayBoth
            x={hoveredAssetX}
            y={hoveredAssetY}
            width={defaultActiveOffset}
          />
        )
      }
    }
    if (hoveredAsset.x === 0) {
      setDraggingItemProps({ allowDropOnHover: true,
        detachedMode: true,
        normalizedSourceX: hoveredAsset.x,
        deltaOnDrop: width })

      return (
        <Overlay
          x={hoveredAssetX}
          y={hoveredAssetY}
          width={defaultActiveOffset}
          direction="left"
        />
      )
    }
  } else if (x > (hoveredAsset.x + hoveredAsset.width - defaultActiveOffset)) {
    if (rightSideAsset) {
      showBoth = Math.round(rightSideAsset.x - (hoveredAsset.x + hoveredAsset.width)) === 0

      if (showBoth) {
        const leftAssetRightTransition = transitions.find(tr => tr.isAttachedTo(hoveredAssetId, 'left'))
        const rightAssetLeftTransition = transitions.find(tr => tr.isAttachedTo(rightSideAsset.id, 'right'))

        if (leftAssetRightTransition || rightAssetLeftTransition) {
          setDraggingItemProps({ allowDropOnHover: false })
          return null
        }

        setDraggingItemProps({ allowDropOnHover: true,
          detachedMode: true,
          normalizedSourceX: hoveredAsset.x + hoveredAsset.width,
          deltaOnDrop: rightSideAsset.x - (hoveredAsset.x + hoveredAsset.width) >= width
            ? 0 : width - (rightSideAsset.x - (hoveredAsset.x + hoveredAsset.width)) })

        return (
          <OverlayBoth
            x={hoveredAssetX + hoveredAsset.width}
            y={hoveredAssetY}
            width={defaultActiveOffset}
          />
        )
      }
    }

    return null
  }

  setDraggingItemProps({ allowDropOnHover: true })
  return null
}

function OverlayBoth({ x, y, width }: { x: number, y: number, width: number }) {
  return (
    <div style={{ display: 'flex',
      position: 'absolute',
      transform: `translate(${x - width}px, ${y}px)` }}
    >
      <div
        className={cx(styles.item, styles.rightItem, styles.both)}
        style={{ width: `${width}px` }}
      />
      <div
        className={cx(styles.item, styles.leftItem, styles.both)}
        style={{ width: `${width}px` }}
      />
    </div>
  )
}

function Overlay({ x, y, width, direction }: { x: number, y: number, width: number, direction: 'left' | 'right' }) {
  return (
    <div
      className={cx(styles.item, { [styles.leftItem]: direction === 'left',
        [styles.rightItem]: direction === 'right' })}
      style={{ position: 'absolute',
        width: `${width}px`,
        transform: `translate(${x}px, ${y}px)` }}
    />
  )
}

export default DropHoverOnAssets
