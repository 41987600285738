import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { ConfirmationDialog } from '~/components/base/ConfirmationDialog/ConfirmationDialog'

export const DeleteHotkeyProfilePresetModal = ({ modalDialog, name }) => {
  const { t } = useContext(TranslationContext)
  return (
    <If condition={modalDialog.active}>
      <ConfirmationDialog
        onCancel={modalDialog.cancel}
        onConfirm={modalDialog.confirm}
        title={`${t('DELETE')} ${name}?`}
        description={t('THIS_PRESET_WILL_BE_DELETED')}
        confirmBtnText={t('DELETE')}
      />
    </If>
  )
}
