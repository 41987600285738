import Axios from 'axios'
import { useEffect, useState } from 'react'

export const useScriptsList = () => {
  const [ scriptsList, setScriptsList ] = useState([])

  useEffect(() => {
    const initScripts = async () => {
      try {
        const { data: config } = await Axios.get('scripts/scripts-list.json', { baseURL: null })
        setScriptsList(config?.scriptsList || [])
      } catch (e) { /* NOP */ }
    }

    initScripts()
  }, [])

  return [ scriptsList ]
}
