import { difference } from 'lodash'
import React, { useCallback } from 'react'
import { useDiff } from '~/hooks'
import { getPlayerState } from './usePlayer'

function useRefWithInitializer(fn) {
  const refIsReady = React.useRef(false)
  const refValue = React.useRef()

  if (!refIsReady.current) {
    refIsReady.current = true
    refValue.current = fn()
  }

  return refValue
}

function usePlayersCollection(playerIds) {
  const { current: players } = useRefWithInitializer(() => new Map())

  const removedIds = useDiff(
    playerIds,
    (next, prev) => difference(prev, next)
  )

  removedIds.forEach(id => {
    players.delete(id)
  })

  return {
    map: players,
    list: Array.from(players.values()),
  }
}

/**
 * @param {string[]} playerIds
 * @return {{ isLoading: boolean, isReady: boolean, onReady: function(object) }}
 */
export default function useAggregatedPreviewPlayer(
  playingPlayerIds, backgroundPlayerIds, isSliderOnBufferingPos, basePlaying, mode
) {
  const playerIds = playingPlayerIds.concat(backgroundPlayerIds)
  const { map: players, list: playersList } = usePlayersCollection(playerIds)
  const [ , forceUpdate ] = React.useReducer(x => x + 1, 0)

  const filterPlayersByIds = ids => (
    playersList.filter(pl => ids.includes(pl.getId()))
  )

  const bufferablePlayerIds = isSliderOnBufferingPos ? playerIds : playingPlayerIds
  const bufferablePlayersList = filterPlayersByIds(bufferablePlayerIds)

  const isReady = players.size === 0
    ? false
    : bufferablePlayersList.every(player => getPlayerState(player).isReady)
  const isLoading = bufferablePlayersList.some(player => getPlayerState(player).isLoading)
  const isBuffering = bufferablePlayersList.some(player => getPlayerState(player).isBuffering)
  const isReadyToPlaying = !isLoading && isReady
  const isPlaying = basePlaying && (mode === 'test' ? true : (isReadyToPlaying && !isBuffering))

  const bufferingProgresses = bufferablePlayersList
    .map(pl => getPlayerState(pl).progress)
    .filter(progress => progress !== null)
  const bufferingProgressesSum = bufferingProgresses.reduce((sum, progress) => sum + progress, 0)
  const bufferingProgress = bufferingProgressesSum / bufferingProgresses.length

  const onMounted = React.useCallback(
    player => {
      players.set(player.getInternalPlayer().id, player)
      forceUpdate()
    },
    [ players ]
  )

  const onBufferingProgress = React.useCallback(forceUpdate, [ players ])

  const getPlayerById = useCallback(playerId => (
    players.get(playerId)?.getInternalPlayer()
  ), [ players ])

  return {
    isReady,
    isLoading,
    onReady: () => {},
    onMounted,
    onBufferingProgress,
    isBuffering,
    bufferingProgress,
    getPlayerById,
    isReadyToPlaying,
    isPlaying,
  }
}
