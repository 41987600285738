import React from 'react'
import MaterialSwitch from '@material-ui/core/Switch'
import './Switch.scss'
import classNames from 'classnames'

export default function Switch(props) {
  const { className } = props
  return (
    <MaterialSwitch className={classNames('switch', className)} {...props} />
  )
}

Switch.defaultProps = {
  color: 'default',
  disableRipple: true,
}
