import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import Switch from '~/components/base/Switch'
import './TransitionsSwitch.scss'

export default function TransitionsSwitch({ onChange, checked }) {
  const { t } = useContext(TranslationContext)

  if (__APP_PROFILE__ !== 'default') return null

  return (
    <div className="transitions-switch">
      <div className="transitions-switch__text">
        {t('TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS')}
        :
      </div>
      <div className="transitions-switch__control">
        <Switch onChange={onChange} checked={checked} />
      </div>
    </div>
  )
}
