import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from 'components/base/RawButton'
import Input from '../Input'
import { useBind } from '~/hooks'
import { ReactComponent as ArrowIcon } from '~/assets/settings/expand_more.svg'
import './NumberInput.scss'

const preventDefault = e => { e.preventDefault() }

export default function NumberInput(props) {
  const {
    format,
    onChange,
    value,
    step, min, max,
    className,
    display,
  } = props

  const onIncrease = useBind(onChange, Math.min(value + step, max))
  const onDecrease = useBind(onChange, Math.max(value - step, min))
  const formattedValue = format(value)

  return (
    <div className={cx('number-input', className)}>
      <Choose>
        <When condition={React.isValidElement(display)}>
          {display}
        </When>

        <When condition={typeof display === 'function'}>
          {display(formattedValue)}
        </When>

        <Otherwise>
          <Input disabled value={formattedValue} />
        </Otherwise>
      </Choose>

      <div
        className="number-input__buttons"
        // prevent underlying input from getting focus when button clicked
        onClick={preventDefault}
      >
        <Button
          className={cx(
            'number-input__btn',
            'number-input__btn--plus'
          )}
          disabled={max <= value}
          onClick={onIncrease}
        >
          <ArrowIcon />
        </Button>

        <Button
          className={cx(
            'number-input__btn',
            'number-input__btn--minus'
          )}
          disabled={min >= value}
          onClick={onDecrease}
        >
          <ArrowIcon />
        </Button>
      </div>
    </div>
  )
}

NumberInput.defaultProps = {
  format: x => x,
  step: 1,
  value: 1,
  min: 1,
  max: 180,
  display: undefined,
}

NumberInput.propTypes = {
  format: PropTypes.func,
  step: PropTypes.number,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  display: PropTypes.element,
}
