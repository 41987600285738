import React, { CSSProperties } from 'react'
import cx from 'classnames'
import { MediaAsset, AudioAsset, TextAsset, TransitionAsset } from 'models/Asset'
import { normalizeThumbnail } from 'Util'
import { isVoiceover } from 'Util/recording'
import styles from 'components/AssetPanels/Media/listItem.module.scss'
import { getTransitionItem } from 'components/AssetPanels/Transition/ListItem'

type Asset = typeof MediaAsset | typeof TextAsset | typeof AudioAsset

type Props = {
  asset: Asset,
  className?: string,
  style?: CSSProperties
}

function getThumbnail(asset: Asset) {
  if (asset instanceof AudioAsset) {
    if (isVoiceover(asset)) {
      return (
        <>
          <div className={cx(styles.thumbnailBackground, styles.voiceBackground)} />
          <div className={cx(styles.thumbnailIcon, styles.voiceIcon)} style={{ width: '20px', height: '20px' }} />
        </>
      )
    }

    return (
      <>
        <div className={cx(styles.thumbnailBackground, styles.audioBackground)} />
        <div className={cx(styles.thumbnailIcon, styles.audioIcon)} style={{ width: '20px', height: '20px' }} />
      </>
    )
  }

  return null
}

function MediaItemPreview({ asset, className = '', style = {} }: Props) {
  return (
    <div className={cx(className)} style={style}>
      {getContent(asset)}
    </div>
  )
}

function getContent(asset: Asset) {
  if (asset instanceof TextAsset) {
    return asset.text
  }
  if (asset instanceof TransitionAsset) {
    return getTransitionItem(asset.type)
  }
  if (asset instanceof MediaAsset) {
    return getThumbnail(asset) || (
      <img
        src={normalizeThumbnail(asset.thumbnail || undefined)}
        className="thumbnail"
        alt="Loading..."
      />
    )
  }
  return null
}

export default MediaItemPreview
