import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext, useState } from 'react'
import * as Actions from '~/actions'
import { CircularProgress } from '~/components/base/CircularProgress/CircularProgress'
import { InputClearable } from '~/components/base/InputClearable/InputClearable'
import InfoView from '~/components/views/InfoView/InfoView'
import { SOURCE_FILE_TYPES } from '~/enums'
import { useAction } from '~/hooks'
import './AmazonImport.scss'
import { AmazonForm } from './models/AmazonForm'

export default function AmazonImport() {
  const startImport = useAction(Actions.importFromAmazon.importMediafileFromAmazon)
  const hideAmazonImport = useAction(Actions.mainView.showAmazonImport, false)
  const fetchFiles = useAction(Actions.sourceFiles.loadSourceFiles, SOURCE_FILE_TYPES.MEDIA)
  const [ isLoading, setIsLoading ] = useState(null)
  const [ importError, setImportError ] = useState(null)

  const [ values, setValues ] = React.useState(() => new AmazonForm())

  const handleChange = ({ target: { value } }, mapFn) => {
    setValues(new AmazonForm({ ...values, ...mapFn(value) }))
  }

  const onSecretKeyChange = e => {
    handleChange(e, v => ({ secretKey: v }))
  }

  const onBucketNameChange = e => {
    handleChange(e, v => ({ bucketName: v }))
  }

  const onAccessKeyChange = e => {
    handleChange(e, v => ({ accessKeyId: v }))
  }

  const handleClear = mapFn => {
    setValues(new AmazonForm({ ...values, ...mapFn() }))
  }

  const onClearSecretKey = () => {
    handleClear(() => ({ secretKey: AmazonForm.initialValues.secretKey }))
  }

  const onClearBucketName = () => {
    handleClear(() => ({ bucketName: AmazonForm.initialValues.secretKey }))
  }

  const onClearAccessKey = () => {
    handleClear(() => ({ accessKeyId: AmazonForm.initialValues.accessKeyId }))
  }

  const onImportClick = async () => {
    setIsLoading(true)
    setImportError(null)
    const { error } = await startImport(values)
    if (error) {
      setImportError(error)
    }
    setIsLoading(false)
    fetchFiles({ force: true })
    if (!error) {
      hideAmazonImport()
    }
  }

  const { t } = useContext(TranslationContext)

  const { bucketName, accessKeyId, secretKey } = values

  const isFormValid = bucketName && accessKeyId && secretKey

  return (
    <>
      <InfoView header={t('AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS')} onHide={hideAmazonImport}>
        <Choose>
          <When condition={isLoading}>
            <CircularProgress
              text={`${t('AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING')}..`}
              size={100}
              percents={80}
              endless
              transparent
            />
          </When>
          <Otherwise>
            <form className="amazon-import__form">
              <InputClearable
                placeholder={t('AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME')}
                onChange={onBucketNameChange}
                value={bucketName}
                onClearInput={onClearBucketName}
              />

              <InputClearable
                placeholder={t('AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY')}
                onChange={onAccessKeyChange}
                value={accessKeyId}
                onClearInput={onClearAccessKey}
              />

              <InputClearable
                placeholder={t('AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY')}
                onChange={onSecretKeyChange}
                value={secretKey}
                onClearInput={onClearSecretKey}
              />

              <div className="amazon-import__actions">
                <div>
                  <If condition={isLoading === false}>
                    <p className="amazon-import__actions__text">
                      <Choose>
                        <When condition={importError}>
                          {t('AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR')}
                          :
                          {' '}
                          {importError}
                        </When>
                        <Otherwise>
                          {t('AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY')}
                        </Otherwise>
                      </Choose>
                    </p>
                  </If>
                </div>
                <Button
                  type="submit"
                  disabled={!isFormValid}
                  className="amazon-import__actions__btn-import"
                  onClick={onImportClick}
                  autoFocus
                  primary
                  data-lang-id="AMAZON_IMPORT_BTN_IMPORT"
                  title={t('AMAZON_IMPORT_BTN_IMPORT_TOOLTIP')}
                >
                  {t('AMAZON_IMPORT_BTN_IMPORT')}
                </Button>
              </div>

            </form>
          </Otherwise>
        </Choose>
      </InfoView>
    </>
  )
}
