import Scrollbars from 'components/base/Scrollbars'
import React from 'react'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import './View.scss'

export const View = ({ children, title, onClose, footer }) => (
  <div style={{ height: '100%', paddingBottom: '16px' }}>
    <Scrollbars>
      <div className="base-view">
        <div className="base-view_top">
          <span className="base-view_caption">{title}</span>
          <div className="base-view_close" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        {children}
      </div>
    </Scrollbars>
    {!!footer && <div className="base-view_footer">{footer}</div>}
  </div>
)
