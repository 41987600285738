import React, { forwardRef } from 'react'
import cx from 'classnames'
import MenuItem from 'components/base/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from 'components/base/Radio'

export function MenuOption(props) {
  const { value, label, underline, className, disabled = false } = props
  return (
    <FormControlLabel
      disabled={disabled}
      value={value}
      label={label}
      className={cx(className, {
        'media-sort__radio-first': underline,
      })}
      control={(
        <Radio
          icon={<div />}
          checkedIcon={<div className="media-sort__radio-checked" />}
        />
      )}
    />
  )
}

export const MenuSection = forwardRef((props, ref) => {
  const {
    children,
    name, title, value, onChange,
    underline,
    classNameMenu,
    isRadio = false,
  } = props
  return (
    <MenuItem
      ref={ref}
      disableRipple
      className={cx({
        'media-sort__item-first': underline,
      })}
    >
      <div className="media-sort__btn">{title}</div>
      <div className={cx('media-sort__list', classNameMenu)}>
        <div className="media-sort__inner">
          <Choose>
            <When condition={isRadio}>
              <RadioGroup
                className="media-sort__radio-group"
                value={value}
                onChange={onChange}
                name={name}
              >
                {children}
              </RadioGroup>
            </When>

            <Otherwise>
              <div className="media-sort__radio-group">{children}</div>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </MenuItem>
  )
})

export const MenuGroup = React.forwardRef((props, ref) => {
  const {
    children,
    name, title, value, onChange,
  } = props

  return (
    <>
      <RadioGroup
        ref={ref}
        style={{ padding: 0 }}
        className="media-sort__radio-group media-sort__item-first"
        value={value}
        onChange={onChange}
        name={name}
      >
        <MenuOption disabled className="menu-group__title" label={title} />
        {children}
      </RadioGroup>
    </>
  )
})
