import { isEmpty } from 'lodash'
import { generateClientId } from '~/models/Asset/Asset'
import { selectCopiedAssets } from '~/selectors/select-copied-assets'
import * as Selectors from '~/selectors'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'
import { PlaceholderAsset } from '~/models/Asset'
import { addCopiedAssetsToTimeLine } from '~/actions/projectData/addAssetToTimeline'
import { createNewLayers } from '~/actions/createNewLayers'
import { getAvailableLayers } from '~/actions/assetsPasteHelpers/getAvailableLayers'
import { getCopiedAssetsTransitions } from '~/actions/assetsPasteHelpers/getCopiedAssetsTransitions'
import { AVAILABLE_ALL,
  AVAILABLE_FIRST_LAYER,
  AVAILABLE_NEXT_LAYER_FOR_ONE_ASSET,
  NOT_AVAILABLE, checkAvailableLayerSpaces } from '~/actions/assetsPasteHelpers/checkAvailableLayerSpaces'
import { setNewTransitionProperties } from '~/actions/assetsPasteHelpers/setNewTransitionProperties'
import { getCopiedAssetsLayers } from '~/actions/assetsPasteHelpers/getCopiedAssetsLayers'
import { splitCopiedAssetsByExistsLayerIndex } from '~/actions/assetsPasteHelpers/splitCopiedAssetsByExistsLayerIndex'
import { splitCopiedAssetsBySelfLayerIndex } from '~/actions/assetsPasteHelpers/splitCopiedAssetsBySelfLayerIndex'
import { adjustAssetsStartTime } from '~/actions/assetsPasteHelpers/adjustAssetsStartTime'


export const assetsPaste = () => (dispatch, getState) => {
  const state = getState()
  const { sliderTime } = state.timeline
  const copiedAssets = selectCopiedAssets(state)
  const allAssets = Selectors.getAssets(state).filter(a => !(a instanceof PlaceholderAsset))

  const layers = Selectors.getLayers(state)
  const availableLayers = getAvailableLayers(sliderTime, layers, allAssets, copiedAssets)

  const copiedAssetsByExistsLayerIndex = splitCopiedAssetsByExistsLayerIndex(copiedAssets, layers)
  const copiedAssetsBySelfLayerIndex = splitCopiedAssetsBySelfLayerIndex(copiedAssets)
  const copiedAssetsTransitions = getCopiedAssetsTransitions(copiedAssets, allAssets)

  // Сheck that the copied assets belong to the current layers (isBelongToExistsLayers)
  const isBelongToExistsLayers = copiedAssetsByExistsLayerIndex.some(a => !isEmpty(a))
  const currentCopiedAssetsByLayerIndex = isBelongToExistsLayers
    ? copiedAssetsByExistsLayerIndex : copiedAssetsBySelfLayerIndex
  const copiedAssetsLayers = getCopiedAssetsLayers(availableLayers,
    copiedAssets, isBelongToExistsLayers)

  const availableSpaceStatus = checkAvailableLayerSpaces(copiedAssetsLayers,
    availableLayers, currentCopiedAssetsByLayerIndex, isBelongToExistsLayers)

  const adjustedAssetsByExistsLayerIndex = adjustAssetsStartTime(currentCopiedAssetsByLayerIndex,
    sliderTime, copiedAssets)

  const assetsToTimeLine = []
  const prevSelectedAssetIds = []

  switch (availableSpaceStatus.resolution) {
    case AVAILABLE_ALL:
      Object.values(adjustedAssetsByExistsLayerIndex).forEach((copiedAssetsArray, index) => {
        copiedAssetsArray.forEach(copiedAsset => {
          const rebuildAsset = cloneInstanceClass(copiedAsset)
          const newAssetID = generateClientId()
          setNewTransitionProperties(copiedAssetsTransitions,
            rebuildAsset, newAssetID)
          prevSelectedAssetIds.push(rebuildAsset.id)
          if (!isBelongToExistsLayers && !availableLayers[copiedAsset.layerId]) {
            const currentAvailableLayerByIndex = Object.values(availableLayers)
              .find(l => l.layerIndex === index)
            if (currentAvailableLayerByIndex) {
              rebuildAsset.layerId = currentAvailableLayerByIndex.layerId
            }
          }
          rebuildAsset.id = newAssetID
          assetsToTimeLine.push(rebuildAsset)
        })
      })
      dispatch(addCopiedAssetsToTimeLine(assetsToTimeLine,
        copiedAssetsTransitions, { sliderTime, prevSelectedAssetIds }))
      break
    case NOT_AVAILABLE: {
      const assetsByLayerIndex = removeEmptyLayers(adjustedAssetsByExistsLayerIndex)
      const newLayers = createNewLayers(dispatch, assetsByLayerIndex.length)
      const newLayersIds = newLayers.map(layer => layer.id)
      const currentLayers = Selectors.getLayers(getState())
      currentLayers.forEach((layer, index) => {
        if (assetsByLayerIndex[index]) {
          assetsByLayerIndex[index].forEach(asset => {
            const rebuildAsset = cloneInstanceClass(asset)
            const newAssetID = generateClientId()
            setNewTransitionProperties(copiedAssetsTransitions,
              rebuildAsset, newAssetID, layer.id)
            prevSelectedAssetIds.push(rebuildAsset.id)
            rebuildAsset.id = newAssetID
            rebuildAsset.layerId = layer.id
            assetsToTimeLine.push(rebuildAsset)
          })
        }
      })
      dispatch(addCopiedAssetsToTimeLine(assetsToTimeLine, copiedAssetsTransitions,
        { sliderTime, newLayersIds, prevSelectedAssetIds }))
    }
      break
    case AVAILABLE_FIRST_LAYER: {
      const insertionLayerIndex = 1
      const assetsByLayerIndex = removeEmptyLayers(adjustedAssetsByExistsLayerIndex)
      const newLayers = createNewLayers(dispatch,
        assetsByLayerIndex.length - 1, insertionLayerIndex)
      const newLayersIds = newLayers.map(layer => layer.id)
      const currentLayers = Selectors.getLayers(getState())
      currentLayers.forEach((layer, index) => {
        if (assetsByLayerIndex[index]) {
          assetsByLayerIndex[index].forEach(asset => {
            const rebuildAsset = cloneInstanceClass(asset)
            const newAssetID = generateClientId()
            setNewTransitionProperties(copiedAssetsTransitions,
              rebuildAsset, newAssetID, layer.id)
            prevSelectedAssetIds.push(rebuildAsset.id)
            rebuildAsset.id = newAssetID
            rebuildAsset.layerId = layer.id
            assetsToTimeLine.push(rebuildAsset)
          })
        }
      })
      dispatch(addCopiedAssetsToTimeLine(assetsToTimeLine, copiedAssetsTransitions,
        { sliderTime, newLayersIds, prevSelectedAssetIds, insertionLayerIndex }))
    }
      break
    case AVAILABLE_NEXT_LAYER_FOR_ONE_ASSET:
      Object.values(adjustedAssetsByExistsLayerIndex).forEach(copiedAssetsArray => {
        copiedAssetsArray.forEach(copiedAsset => {
          const rebuildAsset = cloneInstanceClass(copiedAsset)
          const newAssetID = generateClientId()
          setNewTransitionProperties(copiedAssetsTransitions,
            rebuildAsset, newAssetID)
          prevSelectedAssetIds.push(rebuildAsset.id)
          rebuildAsset.id = newAssetID
          if (availableSpaceStatus.data.nextLayerForPaste.id) {
            rebuildAsset.layerId = availableSpaceStatus.data.nextLayerForPaste.id
            assetsToTimeLine.push(rebuildAsset)
          }
        })
      })
      dispatch(addCopiedAssetsToTimeLine(assetsToTimeLine,
        copiedAssetsTransitions, { sliderTime, prevSelectedAssetIds }))
      break
    default: break
  }
}

// Delete layers that have no selected assets and reset layer index. The layer index starts from zero
function removeEmptyLayers(layers) {
  return Object.values(layers)
    .filter(assetsArr => !isEmpty(assetsArr))
}
