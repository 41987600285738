import { useDrop } from 'react-dnd'
import { useDropTargetLocation } from 'hooks/useDnd'
import { DRAGNDROP_TYPE } from '~/enums'
import { useLayerDropHandler } from './Layer/useLayerDropHandler'

export const useTimelineDropTarget = (ref, {
  scale,
  onDeniedDrop,
  layers,
}) => {
  const handleDrop = useLayerDropHandler()

  const lastLayerIndex = layers.length - 1
  const lastLayerId = layers[lastLayerIndex]?.id

  const [ collectedProps, drop ] = useDrop({
    accept: [ DRAGNDROP_TYPE.LAYER, ...Object.values(DRAGNDROP_TYPE) ],
    drop(item, monitor) {
      const didDrop = monitor.didDrop()
      // If hasn't dropped on layer
      if (!didDrop && lastLayerId) {
        handleDrop(item, monitor, {
          onDenied: onDeniedDrop,
          scale,
          layerIndex: lastLayerIndex,
          layerId: lastLayerId,
        })
      }
    },
  })

  const [ , dropOver ] = useDropTargetLocation({ location: 'timeline' })

  drop(ref)
  dropOver(ref)

  return collectedProps
}
