/* eslint-disable camelcase */
import { CategoryEntry } from 'components/AssetPanels/Audio/audioTypes'
import { handleError } from 'errors/handleError'
import { httpClient } from 'ServerAPI'
import { BaseQueryParams } from 'types/base-query-params'

type AudioType = typeof __CFG__.AUDIO_CATEGORIES[number]

type Parameters = BaseQueryParams & {
  currentAudioType: AudioType,
  audioFilter?: Partial<Record<AudioType, { category_id: string, mood_id: string }>>
}

const defaultParameters: Parameters = {
  start: 0,
  count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER,
  currentAudioType: 'music',
}

export function fetchAudioFiles(options: Parameters = defaultParameters) {
  const {
    start = 0,
    count = __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER,
    currentAudioType = 'music',
    audioFilter = { },
    // If it's ever needed,
    // you need to force localStorage refresh (set params action) in upcoming update
    // and then delete in next update
    // sort = MEDIA_FILES_SORT.Name,
    // order = MEDIA_FILES_ORDER.Ascending,
    search = undefined,
  } = options

  const optionalParams = { category_id: audioFilter[currentAudioType] ? audioFilter[currentAudioType].category_id : '',
    mood_id: audioFilter[currentAudioType] ? audioFilter[currentAudioType].mood_id : '',
    // sort_field: sort,
    // sort_order: order,
    search }

  type OptionalParamsKeys = keyof typeof optionalParams

  const params = {
    start_number: start,
    count,
    type: currentAudioType === 'mood' ? 'music' : currentAudioType,
    ...(Object.keys(optionalParams) as OptionalParamsKeys[]).filter(k => !!optionalParams[k])
      .reduce((acc, k) => ({ ...acc, [k]: optionalParams[k] }), {}),
  }

  return httpClient.get('/api/audiolib_files', { params }).catch(() => [])
}

export async function fetchAudioFile(id: string) {
  return httpClient.get(`/api/audiolib_files/${id}`).catch(() => [])
}

export async function fetchAudioCategories(options: { type: AudioType} = { type: 'music' }) {
  const { type = 'music' } = options
  const params = { type: type === 'mood' ? 'music' : type }
  const url = type === 'mood' ? '/api/audiolib_files/moods' : '/api/audiolib_files/categories'
  const response = await httpClient.get(url, { params })
  try {
    const res = await handleError(response)
    return res.data.entries as CategoryEntry[]
  } catch (e) {
    return [] as CategoryEntry[]
  }
}
