import classNames from 'classnames'
import React from 'react'

const EMPTY = () => {}

export const NavigationArrow = ({ onClick, children, disabled }) => (
  <span
    className={classNames('media-navigation__arrow', { 'media-navigation__arrow--disabled': disabled })}
    onClick={disabled ? EMPTY : onClick}
  >
    {children}
  </span>
)
