import Cookies from 'universal-cookie'

const cookies = new Cookies()
const LATEST_PROJECT_ID = 'latestProjectId'

export function set(latestProjectId) {
  const currentValue = get()
  if (currentValue !== latestProjectId) {
    cookies.set(LATEST_PROJECT_ID, latestProjectId, {
      path: '/',
      expires: new Date(86_400_000 + +new Date()),
    })
  }
}

export function get() {
  return cookies.get(LATEST_PROJECT_ID) || null
}
