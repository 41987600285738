import React from 'react'
import { ImageAsset, TextAsset, VideoAsset } from '~/models/Asset'
import { ImageAssetOverlay } from './ImageAssetOverlay'
import { TextAssetOverlay } from './TextAssetOverlay'
import { VideoAssetOverlay } from './VideoAssetOverlay'

export const AssetOverlay = props => {
  const { asset, videoRefs } = props

  return (
    <Choose>
      <When condition={asset instanceof TextAsset}>
        <TextAssetOverlay
          {...props}
        />
      </When>
      <When condition={asset instanceof ImageAsset}>
        <ImageAssetOverlay
          {...props}
        />
      </When>
      <When condition={asset instanceof VideoAsset}>
        <VideoAssetOverlay
          videoRefs={videoRefs}
          {...props}
        />
      </When>
    </Choose>
  )
}
