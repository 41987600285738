import { useDrag } from 'react-dnd'
import { isEmpty } from 'lodash'
import * as Actions from 'actions'
import { useAction } from './utils'
import { TRANSITIONS } from '~/enums'

export function useDragSource({
  id,
  startTime,
  duration,
  onDragStarted = () => {},
  onDragEnded = () => {},
  draggable = true,
  type,
  layerId,
  selectedAssetsData,
  selectedAssetsByLayerId,
  selectedAssetsByLayerIndex,
  dragTransitionIds,
  allTransitions,
  allAssets,
  timelineMouseDown,
}) {
  const setDndDropTarget = useAction(Actions.mainView.setDndDropTarget)
  const deleteTransition = useAction(Actions.timeline.deleteTransitionAction)
  const setDnDPatchAssets = useAction(Actions.timeline.setDnDPatchAssets)
  const resetDnDPatchAssets = useAction(Actions.timeline.resetDnDPatchAssets)

  const item = {
    type,
    id,
    startTime,
    duration,
    layerId,
    selectedAssets: selectedAssetsData?.sort((a, b) => a.startTime - b.startTime),
    selectedDragIds: selectedAssetsData?.map(asset => asset.id) ?? [],
    selectedAssetsByLayerId,
    selectedAssetsByLayerIndex,
    dragTransitionIds,
  }

  const getCrashDissolveTransition = () => allTransitions.reduce((result, transition) => {
    if (transition.type === TRANSITIONS.DISSOLVE) {
      const { leftVideoAssetId, rightVideoAssetId } = transition
      if (leftVideoAssetId && rightVideoAssetId) {
        const leftAsset = allAssets?.find(asset => asset.id === leftVideoAssetId)
        const rightAsset = allAssets?.find(asset => asset.id === rightVideoAssetId)
        if (leftAsset && rightAsset) {
          result.relatedAssets.push(leftAsset, rightAsset)
        }
        const checkLeft = item.selectedDragIds.includes(leftVideoAssetId)
        const checkRight = item.selectedDragIds.includes(rightVideoAssetId)
        if ((checkLeft && !checkRight) || (!checkLeft && checkRight)) {
          result.crashDissolve.push(transition)
          const asset = checkLeft ? leftVideoAssetId : rightVideoAssetId
          result.assetIds.push(asset)
        }
      }
    }
    return result
  }, { crashDissolve: [], assetIds: [], relatedAssets: [] })

  return useDrag({
    canDrag: () => draggable,
    item,
    begin: () => {
      // eslint-disable-next-line no-param-reassign
      timelineMouseDown.current = true
      resetDnDPatchAssets()
      const { crashDissolve, assetIds, relatedAssets } = getCrashDissolveTransition()
      if (!isEmpty(crashDissolve)) {
        deleteTransition(crashDissolve, relatedAssets ?? [], [], true)
        // For correct transition assets properties in DraggingAsset component (for Preview)
        setDnDPatchAssets(assetIds)
      }
      setDndDropTarget('timeline')
      onDragStarted(id)
    },
    end: () => {
      // eslint-disable-next-line no-param-reassign
      timelineMouseDown.current = false
      onDragEnded(id)
    },

    collect: monitor => {
      let isMultipleDrag = true
      if ((monitor.getItem()?.selectedAssets?.length ?? 0) <= 1) {
        isMultipleDrag = false
      }

      return {
        isDragging: !isMultipleDrag ? monitor.isDragging()
          : monitor.getItem()?.selectedAssets
        .find(asset => asset.id === id || asset.transition?.includes(id)),
      }
    },
  }, [ item ])
}
