import { toRadians } from 'components/Preview/AssetsOverlay/lib'

export const getFilledSize = (originalSize, rotation, videoSize) => {
  let { width, height } = originalSize
  const r = toRadians(rotation)

  const { width: vWidth, height: vHeight } = videoSize
  width = vWidth * Math.abs(Math.cos(r)) + vHeight * Math.abs(Math.sin(r))
  height = vWidth * Math.abs(Math.sin(r)) + vHeight * Math.abs(Math.cos(r))

  width = Math.round(width)
  height = Math.round(height)

  return { width, height }
}
