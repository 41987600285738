import { useContext } from 'react'
import { DraggingItemContext } from 'components/Timeline/DraggingItemProvider'
import { DRAGNDROP_TYPE } from '~/enums'
import { snapToTimelineGrid } from './snapToTimeline'

export const useTimelineSnapping = ({
  item,
  clientOffset: { x: clientX, y: clientY },
  initialClientOffset,
  sourceOffset: { x: sourceX, y: sourceY },
  width,
  ignoredTypes,
  offset: baseOffset = 0,
  isDraggingItemOverLayer,
  overNewLayerIndex,
  targetLayerIndex,
  restElementsByLayers,
  timelineTopOffset,
  timelineLeftOffset,
  scrollLeft,
  scrollTop,
  isMultipleDrag,
  sliderTimeX,
  dragAssetsWidth,
  setSliderSticky,
  timelineMouseDown,
}) => {
  const { getProps: getDraggingItemProps,
    setProps: setDraggingItemProps } = useContext(DraggingItemContext)
  const { prevLayerIndex, prevSnappedY,
    prevSnappedX, isOverItem, detachedMode } = getDraggingItemProps()

  const isExternalSource = item?.type !== DRAGNDROP_TYPE.LAYER_ASSET

  if (!item || isMultipleDrag) {
    return { x: 0, y: 0 }
  }

  if (clientY >= timelineTopOffset
     && ignoredTypes.every(t => t !== item?.type)) {
    const { snappedX: x, snappedY: y, intersection,
      snappedPreview,
      normalizedSourceX } = snapToTimelineGrid(
      clientX,
      clientY,
      {
        sourceX,
        sourceY,
        width,
        isExternalSource,
        item,
        offset: (timelineLeftOffset - scrollLeft),
        leftTransitionWidth: baseOffset,
        timelineScrollTop: scrollTop,
        initialClientOffset,
        topOffset: timelineTopOffset,
        timelineDragElementsLayers: restElementsByLayers,
        prevLayerIndex,
        prevSnappedY,
        prevSnappedX,
        setDraggingItemProps,
        isDraggingItemOverLayer,
        isOverItem: isOverItem.size !== 0,
        layerIndex: targetLayerIndex,
        overNewLayerIndex,
        detachedMode,
        sliderTimeX,
        dragAssetsWidth,
        setSliderSticky,
        timelineMouseDown,
      }
    )

    return { x,
      y,
      intersection,
      layerIndex: targetLayerIndex,
      snappedPreview,
      normalizedSourceX }
  }

  return { x: sourceX - baseOffset,
    y: sourceY - timelineTopOffset }
}
