import {
  CHROMA_DEFAULT_KEY_COLOR,
  CHROMA_DEFAULT_OPACITY,
  CHROMA_DEFAULT_SIMILARITY,
  CHROMA_DEFAULT_SMOOTHNESS,
  CHROMA_DEFAULT_SPILL_REDUCTION
} from 'constant'
import { TEXT_POSITION } from 'enums'
import { assignPatch } from 'hooks'
import TimelineCommonAsset from './UploadableAsset'

/**
 * @typedef {object} VideoAssetSettings
 * @property {boolean} isChromaKeyEnabled
 * @property {RGBColor} chromaKeyColor
 * @property {number} similarity
 * @property {number} smoothness
 * @property {number} spillReduction
 * @property {number} opacity
 */

/**
 * @memberOf Assets
 * @extends {TimelineCommonAsset}
 */
class VideoAsset extends TimelineCommonAsset {

  constructor(data = {}) {
    super(data)

    const {
      url = null,
      width = 0,
      height = 0,
      // TODO: set audioTrack according to data from server
      audioTracks = [ 'Track 1' ],
      settings = {},
    } = data

    this._url = url
    this._width = width
    this._height = height
    this._hasAudio = audioTracks.length !== 0
    this._audioTracks = audioTracks

    const {
      size = {
        width,
        height,
      },
      rotation = 0,
      originalSize = { width, height },
      offset = { top: null, bottom: null, left: null, right: null },
      keepAspectRatio = true,
      aspectRatio = 1,
      position = TEXT_POSITION.MIDDLE_CENTER,
      isChromaKeyEnabled = false,
      chromaKeyColor = CHROMA_DEFAULT_KEY_COLOR,
      similarity = CHROMA_DEFAULT_SIMILARITY,
      smoothness = CHROMA_DEFAULT_SMOOTHNESS,
      spillReduction = CHROMA_DEFAULT_SPILL_REDUCTION,
      opacity = CHROMA_DEFAULT_OPACITY,
      relativeSize = { width: 100, height: 100 },
    } = settings


    this._settings = {
      size,
      rotation,
      originalSize,
      offset,
      position,
      keepAspectRatio,
      aspectRatio,
      isChromaKeyEnabled,
      chromaKeyColor,
      similarity,
      smoothness,
      spillReduction,
      opacity,
      relativeSize,
    }
  }

  // ---

  /**
   * @returns {string|null}
   */
  get url() {
    return this._url
  }

  // ---

  // eslint-disable-next-line class-methods-use-this
  get canHaveTransition() {
    return true
  }

  // ---

  /**
   * @returns {boolean}
   */
  get hasAudio() {
    return this._hasAudio
  }

  // ---

  /**
   * @returns {number}
   */
  get width() {
    return this._width
  }

  /**
   * @param {number} width
   */
  set width(width) {
    this._width = width
  }

  // ---

  /**
   * @returns {number}
   */
  get height() {
    return this._height
  }

  /**
   * @param {number} height
   */
  set height(height) {
    this._height = height
  }

  // ---

  /**
   * @returns {string[]}
   */
  get audioTracks() {
    return this._audioTracks
  }

  /**
   * @param {string[]} audioTracks
   */
  set audioTracks(audioTracks) {
    this._audioTracks = audioTracks
  }

  /**
   * @returns {VideoAssetSettings}
   */
  get settings() {
    return this._settings
  }

  /**
   * Same behavior  as `setState` in React:
   * assigned value is merged over current one.
   *
   * @param {object} patch
   */
  set settings(patch) {
    this._settings = assignPatch(this._settings, patch)
  }

}

export default VideoAsset
