import produce from 'immer'
import * as ActionTypes from 'actions/ActionTypes'
import { PLAYBACK_STATE } from '~/enums'

const initialState = {
  clipPlaybackState: PLAYBACK_STATE.STOP,
  timelinePlaybackState: PLAYBACK_STATE.STOP,
  selectedClipId: null,
  playingAsset: undefined,
  isBuffering: false,
}

const playback = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.CHANGE_PLAYBACK_STATE:
      setClipPlaybackState(state, action.payload.state)
      break
    case ActionTypes.CHANGE_PREVIEW_STATE:
      setTimelinePlaybackState(state, action.payload.state)
      break

    case ActionTypes.TIMELINE_RECEIVED_FOCUS: {
      handleTimelineFocus(state)
      break
    }

    case ActionTypes.ASSET_ADDED_TO_TIMELINE || ActionTypes.TRANSITION_ADDED_TO_TIMELINE: {
      // Playing source clip isn't resetted when *empty* timeline is hovered by dragged asset.
      // But if then we did drop that asset, timeline is empty no more, and playing clip must be resetted.
      handleTimelineFocus(state)
      break
    }

    case ActionTypes.DRAG_OVER_TIMELINE_STARTED:
      setClipPlaybackState(state, PLAYBACK_STATE.PAUSE)
      setTimelinePlaybackState(state, PLAYBACK_STATE.PAUSE)
      break

    case ActionTypes.SET_PLAYBACK_MEDIA: {
      const { asset } = action.payload
      setPlaybackClip(state, asset)
      break
    }

    case 'SET_PLAYBACK_BUFFERING': {
      const { isBuffering } = action.payload
      state.isBuffering = isBuffering
      break
    }
  }
}

export default produce(playback, initialState)

// ---

function handleTimelineFocus(state) {
  // Reset playback of media clip from media if timeline got active.
  setPlaybackClip(state, null)
}

function setClipPlaybackState(state, clipPlaybackState) {
  state.clipPlaybackState = clipPlaybackState

  if (state.clipPlaybackState === PLAYBACK_STATE.STOP) {
    state.selectedClipId = null
    state.playingAsset = undefined
  }

  if (state.clipPlaybackState === PLAYBACK_STATE.PLAY) {
    state.timelinePlaybackState = PLAYBACK_STATE.PAUSE
  }
}

function setTimelinePlaybackState(state, timelinePlaybackState) {
  state.timelinePlaybackState = timelinePlaybackState
  if (state.timelinePlaybackState === PLAYBACK_STATE.PLAY) {
    state.clipPlaybackState = PLAYBACK_STATE.PAUSE
    state.selectedClipId = null
    state.playingAsset = undefined
  }
}

function setPlaybackClip(state, asset) {
  if (asset === null) {
    state.selectedClipId = null
    state.playingAsset = undefined
    setClipPlaybackState(state, PLAYBACK_STATE.PAUSE)
  } else {
    state.selectedClipId = asset.id
    state.playingAsset = asset
    setClipPlaybackState(state, PLAYBACK_STATE.PLAY)
  }
}
