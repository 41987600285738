import { isNil } from 'lodash'
import { getCopiedAssetsDurationArea } from './getCopiedAssetsDurationArea'

// We get the free space between the slider and the nearest asset and the duration of the selected assets for each layer
export const getAvailableLayers = (sliderTime, layers, allAssets, copiedAssets) => (
  layers.reduce((result, layer, layerIndex) => {
    const currentResult = { ...result }
    let availableSpace
    allAssets.forEach(asset => {
      if (asset.layerId === layer.id) {
        if (asset.startTime >= sliderTime || asset.endTime > sliderTime) {
          let newAvailableSpace = asset.startTime - sliderTime
          if (newAvailableSpace < 0) {
            newAvailableSpace = 0
          }
          if (isNil(availableSpace) || (newAvailableSpace < availableSpace)) {
            availableSpace = newAvailableSpace
          }
        } else {
          availableSpace = null
        }
        currentResult[layer.id] = {
          availableSpace,
          layerIndex,
          layerId: layer.id,
          countCopiedAssets: copiedAssets.length,
          durationCopiedAssets: getCopiedAssetsDurationArea(copiedAssets, layer.id),
        }
      }
    })
    return currentResult
  }, {})
)
