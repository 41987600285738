import React from 'react'
import PropTypes from 'prop-types'
import './ErrorBoundary.scss'

// ---

function DefaultError({
  error = 'Something went wrong',
}) {
  return (
    <div className="error-boundary-overlay">
      <span className="error-boundary-error">
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Error: {error}
      </span>
    </div>
  )
}

// ---

export default class ErrorBoundary extends React.Component {

  static getDerivedStateFromError(error) {
    return { error: error.message }
  }

  state = {}

  render() {
    const { children, fallback } = this.props
    const { error } = this.state

    if (error) {
      if (typeof fallback === 'function') {
        return React.createElement(fallback, { error })
      }
      return fallback
    }

    return children
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary did catch exception:\n', error)
    console.error(errorInfo.componentStack)
  }

}

ErrorBoundary.propTypes = {
  fallback: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
}

ErrorBoundary.defaultProps = {
  fallback: DefaultError,
}
