/**
 * @memberOf ProjectData
 */
class ProjectAsset {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, version) {
    const {
      startTime,
      type,
      fileId,
      id,
      creationOrder,
      duration,
      filetype,
      height,
      width,
      name,
      // eslint-disable-next-line camelcase
      url,
      size,
      sourceFileType,
      status,
      selected,
      thumbnail,
      mediaDeleted,
      errorMessage,
      mediaStart,
      audioTracks,
      layerId,
      originAssetId,
    } = jsonData

    this._id = id
    this._layerId = layerId
    this._startTime = startTime
    this._type = type
    this._mediaStart = mediaStart
    this._fileId = fileId
    this._creationOrder = creationOrder
    this._duration = duration
    this._filetype = filetype
    this._height = height
    this._width = width
    this._name = name
    // eslint-disable-next-line camelcase
    this._url = url
    this._size = size
    this._sourceFileType = sourceFileType
    this._status = status
    this._selected = selected
    this._thumbnail = thumbnail
    this._mediaDeleted = mediaDeleted
    this._errorMessage = errorMessage
    this._audioTracks = audioTracks
    this._originAssetId = originAssetId
  }

  /**
   * @returns {string[]}
   */
  get audioTracks() {
    return this._audioTracks
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id
  }

  /**
   * @returns {number}
   */
  get creationOrder() {
    return this._creationOrder
  }

  /**
   * @returns {number}
   */
  get startTime() {
    return this._startTime
  }

  set startTime(value) {
    this._startTime = value
  }

  /**
   * @returns {number}
   */
  get mediaStart() {
    return this._mediaStart
  }

  /**
   * @returns {string}
   */
  get type() {
    return this._type
  }

  /**
   * @returns {string}
   */
  get errorMessage() {
    return this._errorMessage
  }

  /**
   * @param {string} errorMessage
   */
  set errorMessage(errorMessage) {
    this._errorMessage = errorMessage
  }

  /**
   * @returns {string}
   */
  get fileId() {
    return this._fileId
  }

  /**
   * @param {number|null} id
   */
  set fileId(id) {
    this._fileId = id
  }

  /**
   * @returns {boolean}
   */
  get mediaDeleted() {
    return this._mediaDeleted
  }

  /**
   * @param {boolean} selected
   */
  set mediaDeleted(mediaDeleted) {
    this._mediaDeleted = mediaDeleted
  }

  /**
   * @returns {number}
   */
  get duration() {
    return this._duration
  }

  /**
   * @returns {string}
   */
  get fileType() {
    return this._filetype
  }

  /**
   * @returns {number}
   */
  get height() {
    return this._height
  }

  /**
   * @returns {number}
   */
  get width() {
    return this._width
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name
  }

  /**
   * @returns {string}
   */
  // eslint-disable-next-line camelcase
  get url() {
    return this._url
  }

  /**
   * @param {string} url
   */
  set url(url) {
    this._url = url
  }

  /**
   * @returns {number}
   */
  get size() {
    return this._size
  }

  /**
   * @returns {string}
   */
  get sourceFileType() {
    return this._sourceFileType
  }

  /**
   * @param {string} sourceFileType
   */
  set sourceFileType(sourceFileType) {
    this._sourceFileType = sourceFileType
  }

  /**
   * @returns {string}
   */
  get status() {
    return this._status
  }

  /**
   * @return {boolean}
   */
  get selected() {
    return this._selected
  }

  set selected(selected) {
    this._selected = selected
  }

  /**
   * @returns {string}
   */
  get thumbnail() {
    return this._thumbnail
  }

  set thumbnail(value) {
    this._thumbnail = value
  }

  get layerId() {
    return this._layerId
  }

  set layerId(value) {
    this._layerId = value
  }

  get originAssetId() {
    return this._originAssetId
  }

  set originAssetId(id) {
    this._originAssetId = id
  }

  clearThumbnail() {
    this._thumbnail = ''
  }

}

export default ProjectAsset
