import React, { useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import './LayerItem.scss'
import { ReactComponent as FlipIcon } from '~/assets/timeline/ic_flip.svg'
import { ReactComponent as FlipIconBlack } from '~/assets/timeline/ic_flip_black.svg'
import { time2Pixel, isCtrlKeyPressed } from '~/Util'
import { TRANSITIONS } from '~/enums'

export const TRANSITION_MIN_WIDTH = 10

function TransitionAsset({
  asset,
  connectDragSource,
  onClick,
  isDragging,
  assetWidth,
  borderLeftWidth,
}) {
  const scale = useSelector(state => state.timeline.scale)
  const transitionRef = useRef()
  const { selected, duration, type } = asset

  const width = Math.max(TRANSITION_MIN_WIDTH, time2Pixel(duration, scale))
  const left = type === TRANSITIONS.FADEIN ? -(width + borderLeftWidth)
    : (assetWidth - borderLeftWidth)

  connectDragSource(transitionRef)

  const onMouseDownHandle = useCallback(
    e => {
      e.stopPropagation()
      onClick(asset.id, {
        ctrlKey: isCtrlKeyPressed(e),
        shiftKey: e.shiftKey,
      })
    },
    [ asset, onClick ]
  )

  return (
    <div
      className={cx('layer-item', 'layer-item__transition', {
        'layer-item--selected': selected,
        'layer-item--dragging': isDragging,
      })}
      ref={transitionRef}
      onMouseDown={onMouseDownHandle}
      style={{ left, width }}
    >
      <div className="layer-item__transition-icon">
        {selected ? <FlipIcon /> : <FlipIconBlack />}
      </div>
    </div>
  )
}

export default TransitionAsset
