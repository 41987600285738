import PropTypes from 'prop-types'
import { PLAYBACK_STATE, SOURCE_FILE_TYPES } from '~/enums'
import AbstractAsset, * as AssetModels from '~/models/Asset'
import { Folder as AbstractFolder } from '~/models/Folder'
import TimelineLayer from '~/models/Layer'

export const LayerAsset = PropTypes.shape({
  duration: PropTypes.number.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  name: PropTypes.string,
  url: PropTypes.string,
  mediaStart: PropTypes.number,
  selected: PropTypes.bool,
  fileId: PropTypes.string,
})

export const ClipPlaybackState = PropTypes.oneOf(Object.values(PLAYBACK_STATE))
export const SourceFileType = PropTypes.oneOf(Object.values(SOURCE_FILE_TYPES))

export const Layer = PropTypes.instanceOf(TimelineLayer)

export const Asset = PropTypes.instanceOf(AbstractAsset)
export const Folder = PropTypes.instanceOf(AbstractFolder)
export const MediaItem = PropTypes.oneOfType([ Asset, Folder ])
export const MediaAsset = PropTypes.instanceOf(AssetModels.MediaAsset)
export const TextAsset = PropTypes.instanceOf(AssetModels.TextAsset)
export const VideoAsset = PropTypes.instanceOf(AssetModels.VideoAsset)
export const AudioAsset = PropTypes.instanceOf(AssetModels.AudioAsset)
export const ImageAsset = PropTypes.instanceOf(AssetModels.ImageAsset)
export const TransitionAsset = PropTypes.instanceOf(AssetModels.TransitionAsset)

export const RefObject = PropTypes.shape({ current: PropTypes.any })
export const Ref = PropTypes.oneOfType([ PropTypes.func, RefObject ])

export const RGBAColor = PropTypes.shape({
  r: PropTypes.number.isRequired,
  g: PropTypes.number.isRequired,
  b: PropTypes.number.isRequired,
  a: PropTypes.number.isRequired,
})

// ---
// Custom validators:

export const Const = constantValue => {
  const type = typeof constantValue
  if (type !== 'string' && type !== 'number') {
    throw new Error('Only primitives allowed to be used as constant prop type')
  }

  const validate = (props, propName, componentName, locationName) => {
    if (props[propName] !== constantValue) {
      return new Error(
        `Invalid ${locationName} '${propName}' supplied to '${componentName}'.\n`
        + `Expected a constant '${constantValue}' of type ${type}`
      )
    }
    return undefined
  }

  const checker = (props, propName, ...rest) => {
    if (props[propName] !== undefined) {
      return validate(props, propName, ...rest)
    }
    return undefined
  }

  checker.isRequired = validate

  return checker
}
