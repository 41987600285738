import { ImageAsset, PlaceholderAsset, VideoAsset, MediaRecordingAsset } from 'models/Asset'
import { createSelector } from 'reselect'
import { getProject } from '~/actions/projectData/mappings/getAssetMapping'
import { SOURCE_FILE_TYPES } from '~/enums'

import * as Selectors from '~/selectors'
import { getObjectFields } from '~/Util'
import versionInfo from '~/version.json'
import { selectReference } from './select-reference'

export const selectProjects = state => {
  const projects = [ ...state.projectData.projects ]

  return projects.sort((a, b) => b.modifTime - a.modifTime)
}

export const selectActiveProjectName = state => state.projectData.activeProjectName

export const selectIsLoading = state => state.projectData.isLoading

export const selectActiveProjectId = state => state.projectData.activeProjectId

export const selectActiveProjectLoading = state => state.projectData.activeProjectLoading

export const selectIsActiveProjectDeleted = state => state.projectData.isActiveProjectDeleted

export const selectSearchValue = state => state.projectData.searchValue

export const selectSaveEmpty = state => state.projectData.saveEmpty

export const selectProjectsTotalCount = state => state.projectData.totalCount

export const selectActiveProjectError = state => state.projectData.activeProjectError

export const selectProjectDataError = state => state.projectData.error

export const selectActiveProject = state => state.projectData.activeProject
  ? getProject(state.projectData.activeProject) : null

export const selectShowBlankProjectInList = state => state.projectData.showBlankProjectInList

export const selectIsProjectSaving = state => state.projectData.activeProjectSaving

export const selectLatestSavedTime = state => state.projectData.latestSavedTime

export const selectProjectForSaving = createSelector([
  state => Selectors.getTimeline(state),
  state => Selectors.getLayers(state),
  selectReference,
  state => state,
], ({ sliderTime, scale, scrollLeft }, layers, reference, state) => {
  const { version } = versionInfo
  const currentTime = Date.now()
  return {
    version,
    timeline: {
      sliderTime,
      scale,
      scrollLeft,
    },
    layers: layers.map(layer => {
      const assets = Selectors.getLayerAssets(state, layer.id)
        .filter(a => !(a instanceof PlaceholderAsset || a instanceof MediaRecordingAsset))
      const preparedAssets = assets.map(asset => {
        const preparedAsset = getObjectFields(asset)
        preparedAsset.thumbnail = undefined
        preparedAsset.mediaDeleted = undefined
        preparedAsset.errorMessage = undefined
        return preparedAsset
      })

      return {
        ...getObjectFields(layer),
        assets: preparedAssets,
      }
    }),
    createTime: state.projectData.activeProject?.createTime || currentTime,
    modifTime: currentTime,
    reference,
  }
})

export const selectProjectThumbnail = createSelector([
  state => Selectors.getLayers(state),
  state => state,
], (layers, state) => {
  const assetsFlattedFromLayers = layers.reduce((acc, layer) => {
    const assets = Selectors.getLayerAssets(state, layer.id)
    return [ ...acc, ...assets ]
  }, [])

  const firstAssetByStartTime = assetsFlattedFromLayers
    .filter(asset => asset.sourceFileType === SOURCE_FILE_TYPES.MEDIA
      && (asset instanceof VideoAsset || asset instanceof ImageAsset) && !asset.mediaDeleted)
    .sort((a, b) => a.startTime - b.startTime)

  return firstAssetByStartTime.reduce((thumbnail, asset) => !thumbnail && asset.thumbnail
    ? asset.thumbnail : thumbnail, '')
})

export const selectRenamingProjectIdInList = state => state.projectData.renamingProjectIdInList
