export const getHotKeyFromKeyDownEvent = event => {
  event.preventDefault()
  event.stopPropagation()
  event.stopImmediatePropagation()
  // not ctrl, shift, alt
  const isKeyDown = event.keyCode !== 16 && event.keyCode !== 17 && event.keyCode !== 18
  const additionalKey = (key, enabled) => (enabled ? `${key.toUpperCase()} + ` : '')

  return `${additionalKey('CTRL', event.ctrlKey)}${additionalKey(
    'ALT',
    event.altKey
  )}${additionalKey('SHIFT', event.shiftKey)}${
    // eslint-disable-next-line no-nested-ternary
    isKeyDown ? event.code : ''
  }`
}
