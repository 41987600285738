import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as Actions from '~/actions'
import * as PT from '~/PropTypes'
import { isCtrlKeyPressed } from '~/Util'

import Asset from '~/models/Asset'
import { LayerDraggable } from './LayerDraggable'

class LayerContainer extends React.PureComponent {

  render() {
    const {
      assets, layer,
      showDropAreaPlaceholder,
      scale, layerIndex,
      onLayerAssetsLoaded,
      onDeniedDrop,
      style,
    } = this.props

    return (
      <LayerDraggable
        style={style}
        layerIndex={layerIndex}
        onLayerAssetsLoaded={onLayerAssetsLoaded}
        assets={Asset.getLayerAssets(assets, layer.id)}
        layer={layer}
        scale={scale}

        showDropAreaPlaceholder={showDropAreaPlaceholder}
        onDeniedDrop={onDeniedDrop}

        onClick={this.onClick}
        onAssetDragStarted={this.onAssetDrag}
        onAssetDragEnded={this.onAssetDragEnded}
      />
    )
  }

  onClick = (e, clickTime) => {
    const { assets, layer, clearSelection, onSelectEmptyArea } = this.props
    if (assets.length === 0) {
      return
    }

    clearSelection({ all: !isCtrlKeyPressed(e), assets: true })

    const layerAssets = Asset.getLayerAssets(assets, layer.id)

    // area must be closed at right side to be selectable
    if (layerAssets.some(el => el.startTime > clickTime)) {
      onSelectEmptyArea({
        time: clickTime,
        layerId: layer.id,
      })
    }
  }

  onAssetDrag = assetId => {
    const { startDragAsset, clearSelection } = this.props
    startDragAsset(assetId)
    clearSelection({ all: false, placeholders: true })
  }

  onAssetDragEnded = () => {
    const { endDragAsset } = this.props
    endDragAsset()
  }

}

LayerContainer.propTypes = {
  layer: PT.Layer.isRequired,
  assets: PropTypes.arrayOf(PT.LayerAsset).isRequired,
  scale: PropTypes.number.isRequired,
  layerIndex: PropTypes.number.isRequired,
  clearSelection: PropTypes.func.isRequired,
  onSelectEmptyArea: PropTypes.func.isRequired,
  showDropAreaPlaceholder: PropTypes.bool,
  onLayerAssetsLoaded: PropTypes.func,
  startDragAsset: PropTypes.func,
  endDragAsset: PropTypes.func,
  onDeniedDrop: PropTypes.func,
}

LayerContainer.defaultProps = {
  showDropAreaPlaceholder: false,
  onLayerAssetsLoaded: () => {},
  startDragAsset: () => {},
  endDragAsset: () => {},
  onDeniedDrop: () => {},
}

const mapStateToProps = state => ({
  assets: state.layer.assets,
  sliderTime: state.timeline.sliderTime,
  scale: state.timeline.scale,
})

const mapDispatchToProps = dispatch => ({
  clearSelection: options => dispatch(Actions.layer.clearAssetsSelection(options)),
  onSelectEmptyArea:
    ({ time, layerId }) => dispatch(Actions.layer.selectEmptyArea({ time, layerId })),
  startDragAsset: assetId => dispatch(Actions.timeline.startDragAsset(assetId)),
  endDragAsset: () => dispatch(Actions.timeline.endDragAsset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayerContainer)
