import { closeAudioContext, createAudioContext } from 'components/AssetPanels/Voiceover/utils/webAudioAPI'
import { useEffect } from 'react'

function useWebAudio() {
  useEffect(() => {
    createAudioContext()
    return closeAudioContext
  }, [])
}

export default useWebAudio
