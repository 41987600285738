import React from 'react'
import { createSelector } from 'reselect'
import { maxBy } from 'lodash'

import { useSelector } from 'react-redux'
import { getAssets } from '~/selectors'
import { useChanged, useRefWithSetter, useBoundingRect } from '~/hooks'
import { time2Pixel } from '~/Util'

// ---

export const Context = React.createContext({
  maxLayerWidth: 0,
  timelineWidth: 0,
  timelineHeight: 0,
})

// ---

const getMaxLayerWidth = createSelector(
  [ getAssets, state => state.timeline.scale ],
  (assets, scale) => {
    if (assets.length === 0) {
      return 0
    }
    const { endTime = 0 } = maxBy(assets, x => x.endTime) || {}
    return time2Pixel(endTime, scale)
  }
)

export default function TimelineGeometryContextProvider({ children }) {
  const maxLayerWidth = useSelector(getMaxLayerWidth)
  const [ ref, setRef ] = useRefWithSetter()
  const rect = useBoundingRect(ref, { throttle: 20 })

  const context = useChanged({
    maxLayerWidth,
    timelineWidth: rect.width,
    timelineHeight: rect.height,
  })

  return (
    <Context.Provider value={context}>
      {children(setRef)}
    </Context.Provider>
  )
}
