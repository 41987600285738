import React from 'react'
import DropboxChooser from 'react-dropbox-chooser'
import * as Actions from 'actions'
import { useAction } from '~/hooks'

export default function DropboxImport({ children }) {
  const uploadFile = useAction(Actions.upload.uploadFile)

  const onDropBoxSelect = React.useCallback(
    files => {
      files.forEach(file => {
        const { bytes: size, name, link } = file
        uploadFile({ name, size, link, dropbox: true })
      })
    },
    [ uploadFile ]
  )

  return (
    <DropboxChooser
      appKey={__CFG__.SOURCE_FILES_MANAGEMENT.DROPBOX_APP_KEY}
      success={onDropBoxSelect}
      multiselect
      linkType="direct"
      // extensions={[ '.mp4' ]}
    >
      {children}
    </DropboxChooser>
  )
}
