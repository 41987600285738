import React, { useState, useCallback, useEffect, useMemo } from 'react'
import MenuItem from 'components/base/MenuItem'
import { gapi } from 'gapi-script'
import * as Actions from 'actions'
import { useAction } from '~/hooks'

function GoogleApiInit({ children }) {
  const [ inited, setInited ] = useState(null)

  const initClient = useCallback(
    () => {
      gapi.client.init({
        apiKey: __CFG__.SOURCE_FILES_MANAGEMENT.GOOGLE_API_KEY,
        clientId: __CFG__.SOURCE_FILES_MANAGEMENT.GOOGLE_CLIENT_ID,
        // Authorization scopes required by the API; multiple scopes can be included, separated by spaces.
        scope: 'https://www.googleapis.com/auth/drive.readonly',
      }).then(
        () => setInited(true),
        err => console.error(err)
      )
    },
    [ ]
  )

  useEffect(
    () => {
      gapi.load('picker', {
        onerror: err => { console.error(err) },
      })
      gapi.load('client:auth2', initClient)
    },
    [ initClient ]
  )

  return (children(inited))
}

function usePicker(token, onClose) {
  const uploadFile = useAction(Actions.upload.uploadFile)

  return useMemo(() => {
    const onFileSelected = data => {
      if (data.docs) {
        const { name, sizeBytes, id } = data.docs[0]
        uploadFile({ name, size: sizeBytes, id, token })
      }
      if (data.action === 'cancel' || data.action === 'picked') {
        onClose()
      }
    }

    const view = new window.google.picker.View(window.google.picker.ViewId.DOCS)// viewId.FOLDERS
    view.setMimeTypes('image/png,image/jpeg,video/mp4,audio/mpeg')
    return new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(token)
      .setDeveloperKey(__CFG__.SOURCE_FILES_MANAGEMENT.GOOGLE_API_KEY)
      .setCallback(onFileSelected)
      .build()
  }, [ token, uploadFile, onClose ])
}

function GoogleDrivePicker({ children }) {
  // if user singed in earlier, we just get already existed token
  const [ token, setToken ] = useState(
    gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token
  )
  const [ pickerVisible, setPickerVisible ] = useState(false)

  const onPickerClosed = useCallback(
    () => {
      setPickerVisible(false)
    },
    [ ]
  )

  const picker = usePicker(token, onPickerClosed)

  const onSignedIn = useCallback(
    signed => {
      setToken(gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token)
      setPickerVisible(signed)
    },
    [ ]
  )

  const onImport = useCallback(
    () => {
      // if no token, sign in first and if success,
      // make picker visible in onSignedIn callback
      if (!token) {
        gapi.auth2.getAuthInstance().isSignedIn.listen(onSignedIn)
        gapi.auth2.getAuthInstance().signIn()
      } else {
        setPickerVisible(true)
      }
    },
    [ onSignedIn, token ]
  )

  const onLogout = useCallback(
    () => {
      gapi.auth2.getAuthInstance().signOut()
      setToken(null)
      setPickerVisible(false)
    },
    [ ]
  )

  useEffect(
    () => {
      picker.setVisible(pickerVisible)
    },
    [ pickerVisible, picker ]
  )

  return (
    <div>
      <div onClick={onImport}>
        {children}
      </div>
      {/* temporary menu item until new place for google logout will be defined */}
      <If condition={token}>
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </If>
    </div>
  )
}

export default function GoogleDriveImport(props) {
  return (
    <GoogleApiInit>
      {inited => (
        <If condition={inited}>
          <GoogleDrivePicker {...props} />
        </If>
      )}
    </GoogleApiInit>
  )
}
