import * as cx from 'classnames'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ConnectionIcon } from '~/components/icons/ConnectionIcon/ConnectionIcon'
import * as Selectors from '~/selectors'
import { selectLatestSavedTime } from '~/selectors/projectData'
import './OfflineModePlug.scss'

export const OfflineModePlug = () => {
  const latestSavedTime = useSelector(selectLatestSavedTime)
  const isOffline = useSelector(Selectors.selectIsOffline)

  const minutes = Math.round((Date.now() - latestSavedTime) / 60_000)
  const { t } = useContext(TranslationContext)

  return (
    <div className={cx('offline-mode-plug', { 'offline-mode-plug--hidden': !isOffline })}>
      <div className="offline-mode-plug__popover">
        <ConnectionIcon className="offline-mode-plug__popover__icon" />
        <div className="offline-mode-plug__popover__text-block">
          <span>{t('OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT')}</span>
          <span className="offline-mode-plug__popover__text-block__status-text">
            {t('OFFLINE_MODE_PLUG_TEXT_LAST_SAVED')}
            <Choose>
              <When condition={minutes}>
                {` ${minutes} `}
                {t('OFFLINE_MODE_PLUG_TEXT_MIN_AGO')}
              </When>
              <Otherwise>
                {` ${t('OFFLINE_MODE_PLUG_TEXT_JUST_NOW')} `}
              </Otherwise>
            </Choose>
          </span>
        </div>
      </div>
    </div>
  )
}
