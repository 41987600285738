import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Radio from 'components/base/Radio'
import FontOptionsRadioGroup from '../FontOptionsRadioGroup/FontOptionsRadioGroup'
import { ReactComponent as TextNoneIcon } from '~/assets/settings/text_none.svg'
import { ReactComponent as TextUppercaseIcon } from '~/assets/settings/text_uppercase.svg'
import { ReactComponent as TextCapitalizeIcon } from '~/assets/settings/text_capitalize.svg'
import { ReactComponent as TextLowercaseIcon } from '~/assets/settings/text_lowercase.svg'
import { TEXT_TRANSFORM } from '~/enums'
import { useComposeConst } from '~/hooks'
import { TranslationContext } from '~/contexts/TranslationContext'

export default function TextTransformSelector({ value, onChange }) {
  const handleChange = useComposeConst(onChange, e => e.target.value)
  const { t } = useContext(TranslationContext)
  return (
    <FontOptionsRadioGroup
      name="textAlign"
      value={value}
      onChange={handleChange}
    >
      <Radio
        value={TEXT_TRANSFORM.NONE}
        icon={<TextNoneIcon />}
        checkedIcon={<TextNoneIcon />}
        data-lang-id="SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE"
        title={t('SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP')}
      />
      <Radio
        value={TEXT_TRANSFORM.UPPERCASE}
        icon={<TextUppercaseIcon />}
        checkedIcon={<TextUppercaseIcon />}
        data-lang-id="SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE"
        title={t('SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP')}
      />
      <Radio
        value={TEXT_TRANSFORM.CAPITALIZE}
        icon={<TextCapitalizeIcon />}
        checkedIcon={<TextCapitalizeIcon />}
        data-lang-id="SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE"
        title={t('SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP')}
      />
      <Radio
        value={TEXT_TRANSFORM.LOWERCASE}
        icon={<TextLowercaseIcon />}
        checkedIcon={<TextLowercaseIcon />}
        data-lang-id="SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE"
        title={t('SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP')}
      />
    </FontOptionsRadioGroup>
  )
}

TextTransformSelector.propTypes = {
  value: PropTypes.oneOf(Object.values(TEXT_TRANSFORM)),
  onChange: PropTypes.func.isRequired,
}

TextTransformSelector.defaultProps = {
  value: TEXT_TRANSFORM.NONE,
}
