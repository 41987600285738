export const googleFonts = [
  'Alegreya Sans SC',
  'Amatic SC',
  'Arial',
  'Arimo',
  'Bubblegum Sans',
  'Bubbler One',
  'Chelsea Market',
  'Comfortaa',
  'Cookie',
  'Darker Grotesque',
  'Delius',
  'Fontdiner Swanky',
  'Fredericka the Great',
  'Happy Monkey',
  'Julius Sans One',
  'Kranky',
  'Life Savers',
  'Love Ya Like A Sister',
  'Luckiest Guy',
  'Mountains of Christmas',
  'Passion One',
  'Quicksand',
  'Ribeye',
  'Roboto',
  // these fonts have been removed in the scope of http://18.184.210.86/issues/549
  // see https://github.com/konvajs/react-konva/issues/526 for details.
  // 'Charm',
  // 'Coming Soon',
  // 'Homemade Apple',
  // 'Pacifico',
]

export const customFonts = [
  'Avenir',
  'Bodoni',
  'Bradley Hand',
  'Brush Script MT',
  'DIN Condensed',
]
