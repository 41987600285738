import { pixel2Time, time2Pixel } from '~/Util'
import { checkIntersectionStaticAssets } from './checkIntersectionStaticAssets'
import { getPlaceHolderIntersection } from './getPlaceholderIntersection'
import { getMinLeftNMinTopDraggableCoords } from './getMinLeftNMinTopDraggableCoords'
import { setInitialHistoryX } from './setInitialHistoryX'
import { getMoveDirection } from './getMoveDirection'
import { setDraggableBlockLeftX } from './setDraggableBlockLeftX'
import { setDropX } from './setDropX'
import { setIntersectedXParams } from './setIntersectedXParams'
import { checkIsNeedResetXIntersectionParams } from './checkIsNeedResetXIntersectionParams'
import { setQuickOffset } from './setQuickOffset'
import { TRANSITIONS } from '~/enums'
import { checkStickySlider } from './checkStickySlider'

export const useMultipleDragRectCoords = ({
  dragItem,
  clientOffset,
  initialClientOffset,
  timelineTopOffset,
  sourceOffset,
  leftXOffset,
  setDraggingItemProps,
  historyX,
  staticAssets,
  scale,
  draggbleAssets,
  overNewLayerIndex,
  layers,
  layerIds,
  targetLayerIndex,
  placeholdersByLayerIndex,
  layerIndexOffset,
  isMultipleDrag,
  transitions,
  sliderTimeX,
  dragAssetsWidth,
  setSliderSticky,
  timelineMouseDown,
}) => {
  if (!isMultipleDrag) return {}
  // Left X coordinates of the leftmost asset
  const {
    minLeftX,
    topY,
  } = getMinLeftNMinTopDraggableCoords(dragItem,
    timelineTopOffset, sourceOffset, layerIndexOffset, scale, leftXOffset)

  // LayerIndex of single draggable asset
  const initialLayerIndex = layerIds.indexOf(dragItem.layerId)

  // If the current and previous layer index are different. Layer index difference
  const offsetLayerIndex = targetLayerIndex - initialLayerIndex

  // Left X coordinate of the dragged block of assets
  const draggableBlockLeftX = clientOffset.x - (initialClientOffset.x - minLeftX)

  // Absolute offset of the moved current dragItem. May be less than zero.
  const draggableOffset = pixel2Time(sourceOffset.x - leftXOffset, scale) - dragItem.startTime

  const draggableBlockStartTime = draggbleAssets?.[0]?.startTime + draggableOffset
  const currentDraggableAsset = draggbleAssets?.find(asset => asset.id === dragItem.id)

  const diffBetweenCurrentDragItemNFirstDragItem = dragItem.startTime
    - (dragItem?.selectedAssets?.[0]?.startTime ?? 0)

  // First draggable elem transition
  const firstDraggableFadeIn = transitions.find(tr => tr.isAttachedTo(draggbleAssets?.[0].id, 'right')
    && tr.type === TRANSITIONS.FADEIN)
  // If the first dragged element is attached to the FadeIn transition, add an offset for draggableBlock
  const transitionOffset = time2Pixel(firstDraggableFadeIn?.duration ?? 0, scale)

  setInitialHistoryX(historyX, draggableBlockLeftX, sourceOffset, leftXOffset, targetLayerIndex)

  // Mouse movement direction
  const currentMoveDirection = getMoveDirection(historyX, draggableBlockLeftX)

  const {
    intersectionPlaceholder,
    intersectionEqualSegment,
    deltaOnDrop,
  } = getPlaceHolderIntersection({
    placeholdersByLayerIndex,
    dragItem,
    layerIds,
    offsetLayerIndex,
    scale,
    draggableBlockStartTime,
    draggbleAssets,
    clientOffset,
    leftXOffset,
    overNewLayerIndex,
  })

  const {
    isOverlapped,
    isIntersected,
    isMouseOver,
    intersectedData,
  } = checkIntersectionStaticAssets({
    staticAssets,
    dragItem,
    layers,
    draggableOffset,
    offsetLayerIndex,
    scale,
    leftXOffset,
    clientOffset,
  })

  // Set historyX leftSideX & intersectedData params
  setIntersectedXParams({
    historyX,
    moveDirection: currentMoveDirection,
    leftXOffset,
    scale,
    targetLayerIndex,
    isIntersected,
    intersectedData,
    isOverlapped,
    isMouseOver,
  })

  // Sets quickOffset and applies it. To quickly move dragged assets when assets overlap (isMouseOver)
  setQuickOffset({
    dragItem,
    historyX,
    moveDirection: currentMoveDirection,
    isMouseOver,
    clientOffset,
    leftXOffset,
    scale,
    overNewLayerIndex,
    staticAssets,
    offsetLayerIndex,
    layers,
    layerIds,
  })

  checkIsNeedResetXIntersectionParams({
    isOverlapped,
    isIntersected,
    isMouseOver,
    historyX,
    moveDirection: currentMoveDirection,
    targetLayerIndex,
  })


  // Sets leftX coord for draggable assets block
  let {
    finalDraggableBlockLeftX,
    dropOffsetX,
  } = setDraggableBlockLeftX({
    dragItem,
    historyX,
    draggableBlockStartTime,
    leftXOffset,
    intersectionPlaceholder,
    scale,
    draggableBlockLeftX,
    initialLayerIndex,
    isMouseOver,
    isOverlapped,
    isIntersected,
    overNewLayerIndex,
    transitionOffset,
  })


  // Sets drop X coord for current dragItem. Other assets are aligned relative to dragItem.
  let {
    dropX,
  } = setDropX({
    historyX,
    draggableBlockStartTime,
    leftXOffset,
    currentDraggableAsset,
    draggbleAssets,
    intersectionPlaceholder,
    scale,
    sourceOffset,
    isIntersected,
    isOverlapped,
    overNewLayerIndex,
    intersectionEqualSegment,
    diffBetweenCurrentDragItemNFirstDragItem,
  })

  const {
    isSliderLeftSticky,
    isSliderRightSticky,
    rightOffset,
    leftOffset,
  } = checkStickySlider({
    sourceX: finalDraggableBlockLeftX,
    dragAssetsWidth,
    sliderTimeX,
  })

  setSliderSticky(false)
  if (timelineMouseDown.current
    && !isIntersected
    && !isOverlapped
    && !intersectionPlaceholder
    && !intersectionEqualSegment) {
    if (isSliderLeftSticky && !isSliderRightSticky) {
      // place a group of assets on the slider position
      finalDraggableBlockLeftX = sliderTimeX
      // aligning the clip insertion
      dropX += leftOffset
      setSliderSticky(true)
    } else if (isSliderRightSticky) {
      // place a group of assets on the slider position
      finalDraggableBlockLeftX = sliderTimeX - dragAssetsWidth
      // aligning the clip insertion
      dropX -= rightOffset
      setSliderSticky(true)
    }
  }

  // eslint-disable-next-line no-param-reassign
  historyX.current = {
    ...historyX.current,
    draggableBlockLeftX: finalDraggableBlockLeftX,
    dropX,
  }

  setDraggingItemProps({
    normalizedSourceX: dropX,
    deltaOnDrop,
    dropOffsetX,
    draggableBlockStartTime,
    intersectionPlaceholder,
    isIntersectionPlaceholder: Boolean(intersectionPlaceholder)
    || Boolean(intersectionEqualSegment),
  })

  return { draggableBlockLeftX: historyX.current.draggableBlockLeftX,
    draggableBlockY: topY,
    intersectionPlaceholder }
}
