import React, { ReactNode, useContext } from 'react'
import { DropzoneOptions } from 'react-dropzone'

import FileInput from 'components/base/FileInput'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './fileInput.module.scss'

type Props = {
  dropzoneParams: DropzoneOptions,
  children: ReactNode | ReactNode[],
  error?: ReactNode
}

function Upload({ dropzoneParams, children, error }: Props) {
  const { t } = useContext(TranslationContext)

  const getInputTitle = () => (
    <>
      <span>{t('PROJECTS_UPLOAD_TEXT_CLICK_CLICK_TO_UPLOAD_DND_FILE')}</span>
      <br />
      <span>{t('PROJECTS_UPLOAD_TEXT_CLICK_YOU_CAN_UPLOAD_ONE_FILE')}</span>
    </>
  )

  return (
    <FileInput
      title={getInputTitle()}
      classes={{ content: styles.content, root: styles.root }}
      dropzoneParams={dropzoneParams}
      uploadOnDrop={false}
      error={error}
      enableAreaClick
    >
      {children}
    </FileInput>
  )
}

export default Upload
