/* eslint-disable class-methods-use-this */
import { v4 as uuidv4 } from 'uuid'

export class HistoryAction {

  constructor(name, payload, dispatch, getState) {
    this._payload = payload
    const { isTransit, id = uuidv4() } = payload
    this._isTransit = isTransit

    this._dispatch = dispatch
    this._getState = getState
    this._createTime = Date.now()
    this._modifTime = Date.now()
    this._id = id
    this._name = name
  }

  get name() {
    return this._name
  }

  get id() {
    return this._id
  }

  get createTime() {
    return this._createTime
  }

  set createTime(createTime) {
    this._createTime = createTime
  }

  get modifTime() {
    return this._modifTime
  }

  set modifTime(modifTime) {
    this._modifTime = modifTime
  }

  get dispatch() {
    return this._dispatch
  }

  get getState() {
    return this._getState
  }

  get payload() {
    return this._payload
  }

  set payload(payload) {
    this._payload = payload
  }

  get isTransit() {
    return this._isTransit
  }

  undo() {
    throw new Error('Abstract class HistoryAction: implement undo method')
  }

  redo() {
    throw new Error('Abstract class HistoryAction: implement redo method')
  }

  isContinuously() {
    return false
  }

  getOptimizedObject() {
    return this
  }

}
