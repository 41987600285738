import { SolveigStyledLogo } from 'components/SolveigStyledLogo/SolveigStyledLogo'
import React from 'react'
import { useSelector } from 'react-redux'
import './default.scss'
import { isNil } from 'lodash'

export default function DefaultPreviewPlaceholder() {
  const { svgLogo, previewPlayerLogo, companyName, hideSVGLogo } = useSelector(state => state.user)
  const logoUrl = previewPlayerLogo || svgLogo || __CFG__.LOGO_PATH
  const companyNameText = !isNil(companyName) ? companyName : __CFG__.COMPANY_NAME || ''

  return (
    <div className="preview-placeholder-solveig">
      <If condition={logoUrl !== 'none' && !hideSVGLogo}>
        <SolveigStyledLogo url={logoUrl} className="preview-placeholder-solveig__logo" />
      </If>
      <div className="preview-placeholder-solveig__text">{companyNameText}</div>
    </div>
  )
}
