import * as ActionTypes from '~/actions/ActionTypes'
import { newProjectId } from '~/constant'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import * as API from '~/ServerAPI'
import { activateProject } from './activateProject'
import { hideBlankProjectFromList } from './hideBlankProjectFromList'

export const deleteProject = id => async dispatch => {
  try {
    await API.deleteProject(id)
    if (LatestProjectId.get() === id) {
      dispatch(activateProject(null))
    }
    if (id === newProjectId) {
      dispatch(hideBlankProjectFromList())
    }
    await dispatch({
      type: ActionTypes.PROJECTS_DELETE,
      payload: { id },
    })
  } catch (e) {
    console.error(`Failed to delete project ${id}`)
  }
}
