import ColorPicker from 'components/base/ColorPicker'
import React from 'react'
import { ReactComponent as BackgroundIcon } from '~/assets/settings/editor_format_color_fill.svg'
import styles from './ColorExampleCard.module.scss'

export const ColorExampleCard = (
  { title, description, color, children, onChangeColor, disabled }
) => (
  <div className={styles.card}>
    <div>
      <div>{title}</div>
      <div className={styles.colorSelection}>
        <ColorPicker
          Icon={BackgroundIcon}
          color={color}
          onChangeColor={onChangeColor}
          disabled={disabled}
        />
        <div className={styles.colorSelectionDescription}>{description}</div>
      </div>
    </div>
    <If condition={children}>
      <div className={styles.example}>{children}</div>
    </If>
  </div>
)
