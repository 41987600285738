import { secondsToTimelineTime } from '~/Util'
import { nearTimeBufferingDiapasonS as bufferingS } from '~/constant'

// Take from each layer one asset, placed at near buffering position (except of placeholder assets).
export const getBackgroundAssets = ({
  sliderTime,
  playingMediaAssetIds,
  flattenedVideoAssets,
}) => {
  const isInBufferingDiapason = asset => {
    const bufferingTime = secondsToTimelineTime(bufferingS)
    const bufferingBorderTime = sliderTime + bufferingTime
    return (asset.startTime <= bufferingBorderTime) && (sliderTime < asset.startTime)
  }

  return flattenedVideoAssets
    .filter(asset => playingMediaAssetIds.every(id => id !== asset.id))
    .filter(isInBufferingDiapason)
}
