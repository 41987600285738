import { styled } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

const StyledMenuItem = styled(MenuItem)({
  color: 'var(--text-color)',
  fontSize: '12px',
  transition: 'all .3s',
  position: 'relative',
  padding: '2px 12px 2px 16px',
  height: '25px',
  cursor: 'pointer',

  '&:before': {
    position: 'absolute',
    content: '',
    width: '9px',
    height: '4px',
    borderBottom: '1px solid var(--text-color)',
    borderLeft: '1px solid var(--text-color)',
    transform: 'rotate(-45deg)',
    left: '6px',
    top: '9px',
    opacity: '0',
  },

  '&.Mui-selected': {
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:before': {
      opacity: 1,
    },
  },

  '&:hover': {
    color: 'var(--activity-color)',
    backgroundColor: 'transparent',
  },
})

export default StyledMenuItem
