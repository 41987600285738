import { getPreparedImageSize } from 'Util/sourceFile'
import { toRadians } from 'components/Preview/AssetsOverlay/lib'

export const getFramedSize = (originalSize, rotation, videoSize) => {
  let { width, height } = originalSize

  // ====== CONSIDER ROTATION ========
  const r = toRadians(rotation)
  const origBoundingWidth = width * Math.abs(Math.cos(r)) + height * Math.abs(Math.sin(r))
  const origBoundingHeight = width * Math.abs(Math.sin(r)) + height * Math.abs(Math.cos(r))
  // ====== CONSIDERED ROTATION ======

  const { width: boundingWidth, height: boundingHeight } = getPreparedImageSize(
    ({
      width: origBoundingWidth,
      height: origBoundingHeight,
      videoSize,
      fit: true,
    })
  )

  width *= boundingWidth / origBoundingWidth
  height *= boundingHeight / origBoundingHeight

  width = Math.round(width)
  height = Math.round(height)

  return { width, height }
}
