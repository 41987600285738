export const DROP_STATUS = {
  CREATE_NEW_LAYER: 'CREATE_NEW_LAYERS',
  CHANGE_LAYER: 'CHANGE_LAYER',
  UPDATE_ASSETS_POSITION_OF_CURRENT_LAYER: 'UPDATE_ASSETS_POSITION_OF_CURRENT_LAYER',
  CANNOT_BE_MOVED: 'CANNOT_BE_MOVED',
}

export const getDropStatusPosition = ({
  currentDragItemLayerId,
  currentDragItemLayerIndex,
  targetLayerId,
  targetLayerIndex,
  isNewLayerAction,
}) => {
  switch (true) {
    case isNewLayerAction:
      return DROP_STATUS.CREATE_NEW_LAYER
    case currentDragItemLayerId === targetLayerId:
      return DROP_STATUS.UPDATE_ASSETS_POSITION_OF_CURRENT_LAYER
    case currentDragItemLayerIndex !== targetLayerIndex:
      return DROP_STATUS.CHANGE_LAYER
    default:
      return DROP_STATUS.CANNOT_BE_MOVED
  }
}
