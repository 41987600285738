import Konva from 'konva'
import { formatRBGColor } from '~/Util'

export const createImage = (
  urlOrImg,
  {
    outlineWidth,
    outlineColor,
    opacity,
    size,
  }, callback = () => { }, { scale = { x: 1, y: 1 }, forRender = false } = {}
) => {
  const { width, height } = size

  const promise = new Promise(resolve => {
    function handleImage(image) {
      // Note: Using wrapper as group for include image outline in box dimension
      // const imageWrapper = new Konva.Group({
      //   width: (width + outlineWidth) * scale.x,
      //   height: (height + outlineWidth) * scale.y,
      // })

      if (forRender) {
        image.width(width)
        image.height(height)
        image.strokeWidth(outlineWidth / scale.x)
        image.stroke(formatRBGColor(outlineColor))

        // NOTE: This is used because stroke and image is different konva elements
        // and if opacity < 1, stroke will be visible under image
        const cloned = new Konva.Image({ image: image.toCanvas() })
        cloned.opacity(opacity / 100)

        callback(cloned)
        resolve(cloned)
      } else {
        image.width(width * scale.x)
        image.height(height * scale.y)
        // imageWrapper.add(clonedImage)
        callback(image)
        resolve(image)
      }
    }

    if (urlOrImg instanceof HTMLElement) {
      const image = new Konva.Image({ image: urlOrImg })
      handleImage(image)
    } else {
      Konva.Image.fromURL(urlOrImg, handleImage)
    }
  })

  return promise
}
