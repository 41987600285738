import { isArray } from 'lodash'
import { assetToTimelineDragElement } from './assetToTimelineDragElement'
import { getMaxWidthOfMultipleDragRect } from './getMaxWidthOfMultipleDragRect'

export const getDragElementsContainerWidth = ({ scale, draggingAsset }) => {
  if (isArray(draggingAsset)) return getMaxWidthOfMultipleDragRect(scale, draggingAsset)
  if (draggingAsset && !isArray(draggingAsset)) {
    return assetToTimelineDragElement(scale)(draggingAsset).width
  }

  return '100%'
}
