import React from 'react'
import musicianImg from '~/assets/musician.jpg'
import photographerImg from '~/assets/photographer.jpg'
import { TRANSITIONS } from '~/enums'
import * as PT from '~/PropTypes'
import ListItem from '../BaseAssetsListItem'
import './ListItem.scss'

export function getTransitionItem(type) {
  switch (type) {
    case TRANSITIONS.DISSOLVE:
      return <DissolveTransitionItem />
    case TRANSITIONS.FADEIN:
      return <FadeInTransitionItem />
    case TRANSITIONS.FADEOUT:
      return <FadeOutTransitionItem />
    default:
      throw new Error(`Transition ${type} is not implemented`)
  }
}

/**
 *
 * @param {{ asset: Assets.TransitionAsset, [x:string]: any }} props
 * @returns
 */
export default function TransitionAssetsListItem({ asset, ...rest }) {
  return (
    <ListItem
      {...rest}
      composeDragItem={item => ({ transitionType: item.type })}
      className="transition-asset"
      asset={asset}
    >
      <div className="transition-asset__item">
        {getTransitionItem(asset.type)}
      </div>
    </ListItem>
  )
}

TransitionAssetsListItem.propTypes = {
  asset: PT.TransitionAsset.isRequired,
}

function DissolveTransitionItem() {
  return (
    <div className="transition-asset__box transition-asset__box--dissolve">
      <img src={photographerImg} alt="" />
      <img src={musicianImg} alt="" />
    </div>
  )
}

function FadeInTransitionItem() {
  return (
    <div className="transition-asset__box transition-asset__box--fade-in">
      <img src={musicianImg} alt="" />
    </div>
  )
}

function FadeOutTransitionItem() {
  return (
    <div className="transition-asset__box transition-asset__box--fade-out">
      <img src={photographerImg} alt="" />
    </div>
  )
}
