import { MAX_IMAGE_SIDE_SIZE } from 'constant'

export const getRelativeSize = (refAsset, { width: oWidth, height: oHeight }) => {
  const width = Math.max(Math.min(oWidth, MAX_IMAGE_SIDE_SIZE), 0)
  const height = Math.max(Math.min(oHeight, MAX_IMAGE_SIDE_SIZE), 0)
  const { width: rWidth, height: rHeight } = refAsset

  return {
    width: width * 100 / rWidth,
    height: height * 100 / rHeight,
  }
}
