import { v4 as uuidv4 } from 'uuid'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'


export function patchAssetData(asset, patch, { pregeneratedId, createNewAsset = true }) {
  const newAsset = cloneInstanceClass(asset)
  Object.keys(patch).forEach(patchProperty => {
    newAsset[patchProperty] = patch[patchProperty]
    if (createNewAsset) {
      newAsset.duration = patch.duration || newAsset.duration
      newAsset.originDuration = patch.duration || newAsset.duration
      newAsset.startTime = patch.startTime || newAsset.startTime
      newAsset.endTime = (patch.startTime || newAsset.startTime) + patch.duration
      newAsset.offsetLeft = 0
      newAsset.offsetRight = 0
      newAsset.originAssetId = asset.id
      newAsset.id = pregeneratedId || `asset-${uuidv4()}`
    }
  })
  return newAsset
}
