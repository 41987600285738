import * as ActionTypes from '~/actions/ActionTypes'
import { DUPLICATED_PROJECT_NAME_POSTFIX } from '~/constant'
import * as API from '~/ServerAPI'

export const duplicateProject = projectId => async dispatch => {
  const duplicatingProject = (await API.getProject(projectId))
  const createTime = Date.now()
  const modifTime = createTime
  const { name, data, thumbnail } = duplicatingProject
  const projectParams = {
    name: name + DUPLICATED_PROJECT_NAME_POSTFIX,
    data,
    thumbnail,
    createTime,
    modifTime,
  }
  const { id } = await API.createProject(projectParams)

  await dispatch({
    type: ActionTypes.PROJECTS_CREATE,
    payload: { project: { id, ...projectParams }, isInitialRenaming: true },
  })

  return id
}
