const DIVIDER_WIDTH = 12
const PXLS_IN_LETTER = 7
const DOTS_WIDTH = 25

const getTextWidth = text => text ? text.length * PXLS_IN_LETTER : 0

export const useCollapsedDirs = ({ pathTotalWidth, navigationDirs, rootPath }) => {
  const leftSideWidth = getTextWidth(rootPath)
    + DIVIDER_WIDTH
    + getTextWidth(navigationDirs[0]?.name)
    + DOTS_WIDTH

  const remainingWidth = pathTotalWidth - leftSideWidth

  const reversedNavigationDirs = navigationDirs.slice().filter((_, i) => i !== 0).reverse()
  const latestFitPathIndex = reversedNavigationDirs.reduce(
    (acc, navDir, index) => {
      const newWidthSum = acc.widthSum + getTextWidth(navDir.name)
      if (newWidthSum > remainingWidth) {
        return { ...acc, widthSum: newWidthSum }
      }
      return { index, widthSum: newWidthSum }
    },
    { index: 0, widthSum: 0 }
  ).index

  const filteredNavDirs = reversedNavigationDirs
    .filter((_, i) => i <= latestFitPathIndex).slice().reverse()

  return filteredNavDirs
}
