import Checkbox from 'components/base/Checkbox'
import ColorPicker from 'components/base/ColorPicker'
import Input from 'components/base/Input'
import MenuItem from 'components/base/MenuItem'
import Select from 'components/base/Select'
import { TranslationContext } from 'contexts/TranslationContext'
import { sortBy } from 'lodash'
import React, { useCallback, useContext } from 'react'
import { ReactComponent as BackgroundIcon } from '~/assets/settings/editor_format_color_fill.svg'
import { ReactComponent as TextIcon } from '~/assets/settings/editor_format_color_text.svg'
import { ReactComponent as StyleBoldIcon } from '~/assets/settings/style_bold.svg'
import { ReactComponent as StyleItalicIcon } from '~/assets/settings/style_italic.svg'
import { ReactComponent as StyleUnderlineIcon } from '~/assets/settings/style_underline.svg'
import { customFonts, googleFonts } from '~/fonts'
import TextAlignSelector from '../TextAlignSelector/TextAlignSelector'
import TextTransformSelector from '../TextTransformSelector/TextTransformSelector'
import './Typography.scss'

export const fontsList = sortBy(
  [].concat(
    googleFonts.map((name, index) => ({ name, id: index })),
    customFonts.map((name, index) => ({ name, id: googleFonts.length + index }))
  ), font => font.name
)

const sizeList = [
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '24',
  '36',
  '48',
  '64',
  '72',
  '96',
  '124',
  '144',
  '150',
  '180',
  '220',
  '288',
  '320',
].map((name, index) => ({ name, id: index }))

export default function SettingsTypography(props) {
  const {
    onTextChanged,
    onColorChanged,
    onFontChanged,
    onFontSizeChanged,
    onBackgroundChanged,
    onFontStyleChanged,
    onChangeTextAlign,
    onChangeTextTransform,
    /** @type {TextAssetSettings} */
    settings,
  } = props
  const { text, textTransform, color, font, fontSize, background, fontStyle, align } = settings

  const onChangeStyleChecked = useCallback(
    e => {
      const { name, checked } = e.target
      onFontStyleChanged({ [name]: checked })
    },
    [ onFontStyleChanged ]
  )

  const handleFontSizeChange = useCallback(
    e => {
      const { value } = e.target
      onFontSizeChanged(parseInt(value))
    },
    [ onFontSizeChanged ]
  )

  const { t } = useContext(TranslationContext)

  return (
    <div className="settings-typography">
      <div className="settings-typography__text">
        <Input
          stateful
          emitValue
          placeholder={t('SETTINGS_PLACEHOLDER_ENTER_TEXT')}
          multiline
          value={text}
          onChange={onTextChanged}
        />
      </div>
      <div className="settings-typography__row">
        <div className="settings-typography__item">
          <ColorPicker
            Icon={TextIcon}
            color={color}
            onChangeColor={onColorChanged}
          />
        </div>
        <div className="settings-typography__item">
          <ColorPicker
            Icon={BackgroundIcon}
            color={background}
            onChangeColor={onBackgroundChanged}
          />
        </div>
        <div className="settings-typography__item settings-typography__item--font">
          <Select
            value={font}
            onChange={onFontChanged}
            style={{ fontFamily: font }}
            data-lang-id="SETTINGS_TEXT_BTN_FONT_FAMILY"
            title={t('SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP')}
          >
            {fontsList.map(item => (
              <MenuItem key={item.id} value={item.name} style={{ fontFamily: item.name }}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="settings-typography__item settings-typography__item--size">
          <Select
            value={fontSize}
            onChange={handleFontSizeChange}
            data-lang-id="SETTINGS_TEXT_BTN_FONT_SIZE"
            title={t('SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP')}
          >
            {sizeList.map(item => (
              <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
            ))}
          </Select>
        </div>
        <div className="settings-typography__item settings-typography__item--radio">
          <TextAlignSelector
            value={align}
            onChange={onChangeTextAlign}
          />
        </div>
        <div className="settings-typography__item settings-typography__item--radio">
          <TextTransformSelector
            value={textTransform}
            onChange={onChangeTextTransform}
          />
        </div>
        <div className="settings-typography__item settings-typography__item--checkbox">
          <div className="settings-typography__item">
            <Checkbox
              className="font-style-checkbox"
              name="bold"
              value="bold"
              icon={<StyleBoldIcon />}
              checkedIcon={<StyleBoldIcon />}
              checked={fontStyle.bold}
              onChange={onChangeStyleChecked}
              data-lang-id="SETTINGS_TEXT_BTN_BOLD"
              title={t('SETTINGS_TEXT_BTN_BOLD_TOOLTIP')}
            />
          </div>
          <div className="settings-typography__item">
            <Checkbox
              className="font-style-checkbox"
              name="italic"
              value="italic"
              icon={<StyleItalicIcon />}
              checkedIcon={<StyleItalicIcon />}
              checked={fontStyle.italic}
              onChange={onChangeStyleChecked}
              data-lang-id="SETTINGS_TEXT_BTN_ITALIC"
              title={t('SETTINGS_TEXT_BTN_ITALIC_TOOLTIP')}
            />
          </div>
          <div className="settings-typography__item">
            <Checkbox
              className="font-style-checkbox"
              name="underline"
              value="underline"
              icon={<StyleUnderlineIcon />}
              checkedIcon={<StyleUnderlineIcon />}
              checked={fontStyle.underline}
              onChange={onChangeStyleChecked}
              data-lang-id="SETTINGS_TEXT_BTN_UNDERLINE"
              title={t('SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
