import * as ActionTypes from '~/actions/ActionTypes'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import * as LatestProjectName from '~/cookieServices/LatestProjectName'

/**
 * @param {{id: string, name: string}} payload
 */
export const activateProject = payload => async dispatch => {
  await dispatch({
    type: ActionTypes.PROJECTS_ACTIVATE,
    payload,
  })
  LatestProjectId.set(payload?.id || '')
  LatestProjectName.set(payload?.name || '')
}
