import { isNil } from 'lodash'
import { isOverlapAssets } from 'Util/RangeTools'

export const updateAssetsPosition = ({
  layers,
  dragAssetsByLayerIndex,
  staticAssets,
  shiftTime,
  intersectionPlaceholder,
}) => {
  const layerIds = layers.map(layer => layer.id)
  return staticAssets.reduce((sum, asset) => {
    const result = { ...sum }
    const staticAsset = {
      id: asset.id,
      duration: asset.duration,
      initialEndTime: asset.startTime + asset.duration,
      initialStartTime: asset.startTime,
      shiftStartTime: asset.startTime,
      layerIndex: layerIds.indexOf(asset.layerId),
      layerId: asset.layerId,
    }

    /* init: layersShiftTime & updatedAssets */
    if (!result.layersShiftTime[staticAsset.layerIndex]) {
      result.layersShiftTime[staticAsset.layerIndex] = {
        shiftStaticTime: 0,
        shiftStaticAssetIds: [],
      }
    }
    /* \ init */
    if (!isNil(dragAssetsByLayerIndex[staticAsset.layerIndex])) {
      const lastLayerAssetIndex = dragAssetsByLayerIndex[staticAsset.layerIndex].length - 1
      const lastLayerAsset = dragAssetsByLayerIndex[staticAsset.layerIndex][lastLayerAssetIndex]
      const lastDragEndTime = lastLayerAsset.startTime + lastLayerAsset.duration + shiftTime

      let dragAssetStartTime = 0
      let dragAssetEndTime = 0
      const firstDraggableIntersected = dragAssetsByLayerIndex[staticAsset.layerIndex]
        .find(dragAsset => {
          dragAssetStartTime = dragAsset.startTime + shiftTime
          dragAssetEndTime = dragAssetStartTime + dragAsset.duration

          return isOverlapAssets(
            Math.round(staticAsset.initialStartTime),
            Math.round(staticAsset.initialEndTime),
            Math.round(dragAssetStartTime),
            Math.round(dragAssetEndTime)
          )
        })
      if (firstDraggableIntersected
        && !result.layersShiftTime[staticAsset.layerIndex].shiftStaticTime
      ) {
        if (intersectionPlaceholder?.startTime > 0
          && intersectionPlaceholder.layerId === staticAsset.layerId) {
          result.layersShiftTime[staticAsset.layerIndex] = {
            shiftStaticTime: 0,
            shiftStaticAssetIds: [],
          }
        } else {
          result.layersShiftTime[staticAsset.layerIndex] = {
            shiftStaticTime: lastDragEndTime - staticAsset.initialStartTime,
            shiftStaticAssetIds: staticAssets
              .filter(sa => sa.startTime >= staticAsset.initialStartTime
                && sa.layerId === staticAsset.layerId).map(asset => asset.id),
          }
        }
      }
    }
    return result
  }, { layersShiftTime: {} })
}
