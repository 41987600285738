import { createNewLayersArray } from '~/actions/dragActionHelpers/createNewLayersArray'
import { setNewLayerId } from '~/actions/dragActionHelpers/setNewLayerId'
import * as Selectors from '~/selectors'

export const addNewLayers = ({
  countDragAssetsLayers,
  insertLayers,
  dispatch,
  layerIndex,
  dragAssetsByLayerIndex,
  getState,
}) => {
  const newLayersArray = createNewLayersArray(countDragAssetsLayers)
  dispatch(insertLayers(layerIndex, newLayersArray, { addToHistory: false }))
  const state = getState()
  const layers = Selectors.getLayers(state)
  const dragAssetsWithNewLayerId = setNewLayerId(dragAssetsByLayerIndex, newLayersArray, layers)
  return {
    insertedLayersByStartIndex: {
      [layerIndex]: newLayersArray,
    },
    dragAssetsWithNewLayerId,
  }
}
