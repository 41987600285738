import { cancelAssetSettingsForm } from '~/actions/mainView'

export const closeEditingFormOfDeletion = (dispatch, state, assets) => {
  const deletedAssetsIds = assets.map(a => a.id)
  for (const [ type, group ] of Object.entries(state.sourceFiles)) {
    const backup = group.editingAssetBackup
    if (backup && deletedAssetsIds.includes(backup.id)) {
      dispatch(cancelAssetSettingsForm(type))
    }
  }
}
