
export const getAbsoluteAssetSize = (asset, referenceAsset) => {
  const { width: rWidth, height: rHeight } = referenceAsset
  const { width: oWidth, height: oHeight } = asset.settings.originalSize
  const { width: sWidth, height: sHeight } = asset.settings.size
  const assetOrigAspectRatio = oWidth / oHeight
  const assetAspectRatio = sWidth / sHeight
  const refAspectRatio = rWidth / rHeight
  const { width: pWidth, height: pHeight } = asset.settings.relativeSize

  let width = pWidth ? Math.round((pWidth / 100) * rWidth) : oWidth
  let height = pHeight ? Math.round((pHeight / 100) * rHeight) : oHeight

  if (pWidth && assetOrigAspectRatio.toFixed(2) === assetAspectRatio.toFixed(2)) {
    if (assetOrigAspectRatio > refAspectRatio) {
      height = Math.round(width / assetOrigAspectRatio)
    } else {
      width = Math.round(height * assetOrigAspectRatio)
    }
  }

  const absoluteSize = { width, height }

  return absoluteSize
}
