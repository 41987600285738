import { AppRoutes } from 'AppRoutes'
import { allowSaveEmpty as allowSaveEmptyAction, resetEditorBeforeProjectActivated } from 'actions'
import { isEmpty } from 'lodash'
import { showSaveSettings } from 'actions/mainView'
import { setSelectedTask } from 'actions/project'
import { saveProjectAs as saveProjectAsAction } from 'actions/projectData/saveProjectAs'
import { useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getAssets, getVisibleLayers } from 'selectors'
import { selectActiveProjectName } from 'selectors/projectData'
import { ImageAsset, TextAsset, VideoAsset } from '~/models/Asset'
import { useAction, useModalDialog } from './utils'

export const useEditorActions = ({ inputRef } = {}) => {
  const assets = useSelector(getAssets)
  const visibleLayers = useSelector(getVisibleLayers)
  const history = useHistory()
  const saveProjectAs = useAction(saveProjectAsAction)
  const allowSaveEmpty = useAction(allowSaveEmptyAction.allowSaveEmpty)
  const resetEditor = useAction(resetEditorBeforeProjectActivated)
  const activeProjectName = useSelector(state => selectActiveProjectName(state))
  const resetProjectSelectedTask = useAction(setSelectedTask, null)
  const openSettings = useAction(showSaveSettings, true)

  const isRenderingSupported = useMemo(() => {
    const visibleLayerIds = visibleLayers.map(l => l.id)
    const visibleAssets = assets.filter(asset => visibleLayerIds.includes(asset.layerId))

    if (isEmpty(visibleAssets)) {
      return false
    }

    const assetsShouldPresent = [
      VideoAsset,
      ImageAsset,
      TextAsset,
    ]
    for (const klass of assetsShouldPresent) {
      if (klass.filter(assets).length > 0) {
        return true
      }
    }
    return false
  }, [ assets, visibleLayers ])

  const onClickNewProject = () => {
    history.push(AppRoutes.Projects)
    setTimeout(() => {
      allowSaveEmpty()
      resetEditor()
      history.push(AppRoutes.NewProject)
    })
  }

  const saveAsDialog = useModalDialog({
    onConfirm: async projectName => {
      const duplicatedProjectId = await saveProjectAs(projectName)
      history.push(`${AppRoutes.Projects}/${duplicatedProjectId}`)
    },
  })

  const onPublish = useCallback(() => {
    if (__APP_PROFILE__ === 'desktop') {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        inputRef?.current.click()
      })
    } else {
      resetProjectSelectedTask()
      openSettings()
    }
  }, [ inputRef, resetProjectSelectedTask, openSettings ])

  return { onClickNewProject, saveAsDialog, activeProjectName, isRenderingSupported, onPublish }
}
