/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import cx from 'classnames'
import * as Assets from 'models/Asset'
import { useSelector } from 'react-redux'
import * as Selectors from 'selectors'

import { isVoiceover } from 'Util/recording'
import styles from './thumbnail.module.scss'

const getFileName = (name: string) => {
  const index = name.lastIndexOf('.')
  return name.slice(0, index)
}

type Props = {
  width: number,
  height: number
}

export function GenerateThumbnail(props: Props) {
  const selectedClipId = useSelector((state: RootState) => state.playback.selectedClipId) as string | null
  const asset = useSelector(state => Selectors.getSourceFileById(state, selectedClipId)) as Assets.MediaAsset

  switch (asset && asset.constructor) {
    case Assets.AudioAsset:
      return <GetAudioThumbnail asset={asset} {...props} />

    default:
      return null
  }
}

const GetAudioThumbnail = React.memo(AudioThumbnail)

type AudioProps = {
  width: number,
  height: number,
  asset: Assets.MediaAsset
}

function AudioThumbnail(props: AudioProps) {
  const { asset } = props
  return (
    <div className={styles.container}>
      <div className={cx(styles.icon, { [styles.audioIcon]: !isVoiceover(asset),
        [styles.voiceIcon]: isVoiceover(asset) })}
      />
      <div className={styles.name}>
        {getFileName(asset.name)}
      </div>
    </div>
  )
}

// function AudioThumbnail(props: AudioProps) {
//   const { asset, width, height } = props
//   const [ image ] = useImage(iconSrc)

//   const groupRef = useRef<TGroup>(null)
//   const [ groupPosition, setGroupPosition ] = useState({ x: 0, y: 0 })
//   const [ groupParams, setGroupParams ] = useState({ width: 0, height: 0 })

//   useEffect(() => {
//     if (groupRef.current) {
//       setGroupParams(
//         { width: groupRef.current.getClientRect().width,
//           height: groupRef.current.getClientRect().height }
//       )
//     }
//   }, [ asset ])

//   useLayoutEffect(() => {
//     setGroupPosition(
//       { x: width / 2 - groupParams.width / 2,
//         y: height / 2 - groupParams.height / 2 }
//     )
//   }, [ width, height, groupParams ])

//   return (
//     <Stage
//       width={width}
//       height={height}
//     >
//       <Layer>
//         <Group
//           ref={groupRef}
//           x={groupPosition.x}
//           y={groupPosition.y}
//         >
//           <Text
//             ref={e => {
//               if (!e) return
//               e.x(groupParams.width / 2 - e.width() / 2)
//               e.y(imgParams.height + 30)
//             }}
//             text={getFileName(asset.name)}
//             fill="white"
//             fontSize={24}
//             align="center"
//           />
//           <Image
//             width={imgParams.width}
//             height={imgParams.height}
//             image={image}
//             x={groupParams.width / 2 - imgParams.width / 2}
//             y={0}
//           />
//         </Group>
//       </Layer>
//     </Stage>
//   )
// }
