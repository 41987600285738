import { Button, FormControlLabel } from '@material-ui/core'
import Position from 'components/Settings/Position/Position'
import Size from 'components/Settings/Size/Size'
import ColorPicker from 'components/base/ColorPicker'
import Switch from 'components/base/Switch'
import {
  CHROMA_DEFAULT_KEY_COLOR,
  CHROMA_DEFAULT_OPACITY,
  CHROMA_DEFAULT_SIMILARITY,
  CHROMA_DEFAULT_SMOOTHNESS,
  CHROMA_DEFAULT_SPILL_REDUCTION,
  MAX_ASSET_VOLUME
} from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import { HOTKEYS } from 'enums'
import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import { useDOMEvent, useKeyCodeListener, useLazyComposeConst, useTap } from 'hooks'
import { useColorClient } from 'hooks/useColorClient'
import { isEmpty } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import Accordion from '~/components/base/Expansion'
import Form from '../../BaseAssetSettingsForm'
import VolumeField from '../../FormFields/VolumeFieldAudio'
import SliderSettings from './Fields/SliderSettings'
import { ReactComponent as PipetteIcon } from './Pipette.svg'
import './VideoForm.scss'
import useImageAssetFormHandlers from './useImageFormHandlers'


export default function VideoAssetSettingsForm(props) {
  const {
    /** @type {Assets.VideoAsset} */
    asset,
    onChange,
    ...rest
  } = props

  const { settings } = asset
  const { keepAspectRatio, originalSize } = settings
  const referenceAsset = useSelector(getReferenceVideoAsset)
  const size = getAbsoluteAssetSize(asset, referenceAsset)
  const { width, height } = size

  const {
    onChangeChromaKeyEnabled,
    onChangeVolume: onChangeVolumeCommit,
    onChangeMuted,
    onChangeChromaKeyColor,
    onChangeSimilarity,
    onChangeSmoothness,
    onChangeSpillReduction,
    onChangeOpacity,
    onResetAll,
  } = useVideoAssetFormHandlers(asset, onChange)

  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const handleDecreaseVolume = React.useCallback(() => {
    onChangeVolumeCommit(Math.max(asset.volume * 100 - 10, 0))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset.volume ])

  const handleIncreaseVolume = React.useCallback(() => {
    onChangeVolumeCommit(Math.min(asset.volume * 100 + 10, 100 * MAX_ASSET_VOLUME))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset.volume ])

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.INCREASE_CLIP_VOLUME], handleIncreaseVolume)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.DECREASE_CLIP_VOLUME], handleDecreaseVolume)

  const { isChromaKeyEnabled,
    chromaKeyColor,
    similarity,
    smoothness,
    spillReduction,
    opacity } = asset.settings

  const [ colorClient, setColorClient ] = useState({})

  const destroyColorClient = () => {
    setColorClient({})
  }

  const handlePickColor = useTap(onChangeChromaKeyColor, destroyColorClient)

  const handleClickPipette = () => {
    if (!isEmpty(colorClient)) {
      destroyColorClient()
    } else {
      // NOTE: timeout to prevent useDOMEvent effect
      setTimeout(() => {
        setColorClient({
          clientId: 'Chroma-key',
          cb: handlePickColor,
          params: { assetId: asset.id },
        })
      })
    }
  }

  useKeyCodeListener(React.useRef(document), 'Escape', destroyColorClient)
  useDOMEvent(useRef(document), 'click', e => {
    if (e.target.localName !== 'video' && e.target.className !== 'color-picker__btn') {
      destroyColorClient()
    }
  })
  useColorClient(colorClient)

  const {
    onChangePosition,
    onChangeSize,
    onChangeKeepAspectRatio,
    onClickFitToFrame,
    onClickFillToFrame,
  } = useImageAssetFormHandlers(settings, onChange)

  const { t } = useContext(TranslationContext)

  return (
    <Form
      {...rest}
      asset={asset}
      title={t('MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA')}
      description={asset.name}
    >
      <Accordion caption={t('MEDIA_VIDEO_FORM_CAPTION_SIZE')}>
        <Size
          onChangeSize={onChangeSize}
          onChangeKeepAspectRatio={onChangeKeepAspectRatio}
          width={width}
          height={height}
          originalSize={originalSize}
          keepAspectRatio={keepAspectRatio}
        />
      </Accordion>

      <Accordion caption={t('MEDIA_VIDEO_FORM_CAPTION_POSITION')}>
        <Position
          showFit
          position={settings.position}
          offset={settings.offset}
          rotation={settings.rotation}
          onClickFitToFrame={onClickFitToFrame}
          onClickFillToFrame={onClickFillToFrame}
          onChangePosition={onChangePosition}
          fillFrame={settings.fillFrame}
          fitToFrame={settings.fitToFrame}
        />
      </Accordion>
      <If condition={__CFG__.MEDIA_SETTINGS.VOLUME
        && (asset.audioTracks ? asset.audioTracks.length !== 0 : true)}
      >
        <Accordion caption={t('MEDIA_VIDEO_FORM_CAPTION_SOUND')}>
          <VolumeField
            muted={asset.muted}
            volume={asset.volume * 100}
            onChangeVolumeCommit={onChangeVolumeCommit}
            onChangeMuted={onChangeMuted}
          />
        </Accordion>
      </If>
      {__CFG__.IS_CHROMAKEY_ENABLED && (
      <Accordion caption={t('MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY')}>
        <div className="chroma-key-color">
          <FormControlLabel
            className="chroma-key-color_label"
            control={(
              <div className="chroma-key-color_switch">
                <Switch
                  onChange={e => onChangeChromaKeyEnabled(e.target.checked)}
                  checked={isChromaKeyEnabled}
                />
              </div>
            )}
          />
          <div className="flex j-space-between" style={{ width: '310px' }}>
            <ColorPicker
              Icon={PipetteIcon}
              color={chromaKeyColor}
              onChangeColor={onChangeChromaKeyColor}
              colorClient={colorClient}
              isIconActive={!isEmpty(colorClient)}
              onClickIcon={handleClickPipette}
              iconTooltip={t('MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW')}
            />
            <Button
              disabled={!isChromaKeyEnabled}
              color="primary"
              variant="text"
              size="small"
              onClick={onResetAll}
              style={{ textTransform: 'unset' }}
              title={t('MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP')}
              data-lang-id="MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS"
            >
              {t('MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS')}
            </Button>
          </div>
        </div>
        <SliderSettings
          disabled={!isChromaKeyEnabled}
          initialValue={similarity}
          label={t('MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY')}
          onValueChange={onChangeSimilarity}
          maxValue={1000}
        />
        <SliderSettings
          disabled={!isChromaKeyEnabled}
          initialValue={smoothness}
          label={t('MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS')}
          onValueChange={onChangeSmoothness}
          maxValue={1000}
        />
        <SliderSettings
          disabled={!isChromaKeyEnabled}
          initialValue={spillReduction}
          label={t('MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION')}
          onValueChange={onChangeSpillReduction}
          maxValue={1000}
        />
        <SliderSettings
          disabled={!isChromaKeyEnabled}
          initialValue={opacity}
          label={t('MEDIA_FORM_SLIDER_LABEL_OPACITY')}
          onValueChange={onChangeOpacity}
          maxValue={100}
          mask="NUM%"
        />
      </Accordion>
      )}
      {/* Server doesn't retern Audio tracks info in the current version. */}
      {/* <Accordion caption="Audio streams">
        <AudioTracks asset={asset} />
      </Accordion> */}

      {/* <Accordion caption="Video streams">
        <div>Video track 1</div>
      </Accordion> */}
    </Form>
  )
}

function useVideoAssetFormHandlers(asset, update) {
  const useHandler = useLazyComposeConst(update)

  const asPatchSettings = patch => ({ settings: { ...patch } })

  return {
    onChangeVolume: useHandler(volume => ({ volume: volume / 100 })),
    onChangeMuted: useHandler(muted => ({ muted: !muted })),
    onChangeChromaKeyEnabled: useHandler(v => asPatchSettings({ isChromaKeyEnabled: v })),
    onChangeChromaKeyColor: useHandler(v => asPatchSettings({ chromaKeyColor: v.rgb })),
    onChangeSimilarity: useHandler(similarity => asPatchSettings({ similarity })),
    onChangeSmoothness: useHandler(smoothness => asPatchSettings({ smoothness })),
    onChangeSpillReduction: useHandler(spillReduction => asPatchSettings({ spillReduction })),
    onChangeOpacity: useHandler(opacity => asPatchSettings({ opacity })),
    onResetAll: useHandler(() => asPatchSettings({
      chromaKeyColor: CHROMA_DEFAULT_KEY_COLOR,
      similarity: CHROMA_DEFAULT_SIMILARITY,
      smoothness: CHROMA_DEFAULT_SMOOTHNESS,
      spillReduction: CHROMA_DEFAULT_SPILL_REDUCTION,
      opacity: CHROMA_DEFAULT_OPACITY,
    })),
  }
}
