import hexToHsl from 'hex-to-hsl'

export const getInitialCss = ({ background, primary, secondary, audio, voiceover }) => {
  const bgHsl = hexToHsl(background)
  const primaryHsl = hexToHsl(primary)
  const direction = bgHsl[2] > 50 ? -1 : 1

  return `:root {
  /*
  for a dark theme direction must be 1 (increase brightness)
  for a light theme must be -1 (decrease brightness)
  */
  --color-pallet-direction: ${direction};

  /* BACKGROUND AS HSL: */
  
  --bg-h: ${bgHsl[0]}; 
  --bg-s: ${bgHsl[1]}%;
  --bg-l: ${bgHsl[2]}%;
 
  /* ACTIVITY COLOR AS HSL: */

  --act-h: ${primaryHsl[0]};
  --act-s: ${primaryHsl[1]}%;
  --act-l: ${primaryHsl[2]}%;

  /* OTHER COLORS AS HEX: */

  --slider-color: ${secondary};
  --audio-wave-color: ${audio};
  --voice-wave-color: ${voiceover};
}
`
}
