import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import { addToBox } from '~/features/konva-shapes/addToBox'
import { createImage } from '~/features/konva-shapes/createImage'
import { createText } from '~/features/konva-shapes/createText'
import { ImageAsset, TextAsset } from '~/models/Asset'

export const createKonvaShape = (asset, refAsset, { scale, boundingRect }) => {
  if (asset instanceof ImageAsset) {
    return createImage(asset.url, { ...asset.settings,
      size: getAbsoluteAssetSize(asset, refAsset) }, undefined,
    { scale, forRender: true }).then(img => {
      const { settings } = asset
      const { rotation, flip } = settings
      const box = addToBox(img, { rotation, flip })
      return box
    })
  }
  if (asset instanceof TextAsset) {
    return new Promise(resolve => resolve(createText(asset, undefined, { boundingRect })))
  }
  return null
}
