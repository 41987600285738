import React from 'react'
import './base.scss'
// eslint-disable-next-line
import Placeholder from './{APP_PROFILE}'

const PreviewPlaceholder = () => (
  <div className="preview-placeholder-base">
    <Placeholder />
  </div>
)


export default PreviewPlaceholder
