export const getUrlParams = url => {
  const parts = url.split('/')
  // Extracting fileId without the fragment
  const fileIdWithTime = parts[parts.length - 1]
  const timeTag = '#t='
  const timeIndex = fileIdWithTime.lastIndexOf(timeTag)
  const fileId = timeIndex !== -1 ? fileIdWithTime.substring(0, timeIndex) : fileIdWithTime
  // Extracting fragment identifier
  const timeSec = timeIndex !== -1 ? fileIdWithTime.substring(timeIndex + timeTag.length) : '0'
  return { fileId, timeSec }
}
