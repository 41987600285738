export const setInitialHistoryX = (historyX, draggableBlockLeftX,
  sourceOffset, leftXOffset, targetLayerIndex) => {
  if (!historyX.current.initialDropX && !historyX.current.initialDraggableBlockLeftX) {
    // eslint-disable-next-line no-param-reassign
    historyX.current = {
      ...historyX.current,
      draggableBlockLeftX,
      leftSideX: draggableBlockLeftX,
      dropX: sourceOffset.x - leftXOffset,
      initialDropX: sourceOffset.x - leftXOffset,
      initialDraggableBlockLeftX: draggableBlockLeftX,
      currentLayerIndex: targetLayerIndex,
    }
  }
}
