export const checkIsNeedResetXIntersectionParams = ({
  isIntersected,
  isMouseOver,
  historyX,
  intersectionPlaceholder,
}) => {
  if (!isIntersected && !isMouseOver) {
    // eslint-disable-next-line no-param-reassign
    historyX.current = {
      ...historyX.current,
      isIntersected: false,
      leftSideX: 0,
      moveDirection: null,
      intersectedData: null,
      readyForDrop: false,
    }
  }

  // Reset quickOffset
  if ((isIntersected && isMouseOver) || intersectionPlaceholder) {
    // eslint-disable-next-line no-param-reassign
    historyX.current = {
      ...historyX.current,
      quickOffset: 0,
      readyForDrop: false,
    }
  }
}
