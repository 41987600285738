import { mergeWith, isPlainObject } from 'lodash'

import base from './__presets__/base'
// see /config-overrides.js
// eslint-disable-next-line
import cfg from './__presets__/{APP_PROFILE}'

// Make sure config is a CONFIG, and does not contain functions or non-serializable data structures
const isValidValue = x => (
  typeof x === 'string'
  || typeof x === 'number'
  || typeof x === 'boolean'
  || x === null
)

function ensureValidValue(x, key) {
  if (!isValidValue(x)) {
    throw new Error(`App config values must be either primitives, or arrays, or plain objects\nGot ${JSON.stringify(x)} at key '${key}'`)
  }
}

/**
 * @type {__CFG__}
 */
const projectConfig = mergeWith(base, cfg, (a, b, key) => {
  if (key === 'CUSTOM') {
    if (b === undefined) {
      return {}
    }

    if (isPlainObject(b)) {
      return b
    }

    throw new Error('custom options must be a plain object')
  }

  // Keep structure consistent, don't allow to replace objects with arrays
  if (
    (isPlainObject(a) && Array.isArray(b))
    || (isPlainObject(b) && Array.isArray(a))
  ) {
    throw new Error(`'${key}': replacing object with array (or vice versa) is not allowed`)
  }

  // Make arrays to completely replace each other.
  // Because managing replacement of non-named parameters is quite hard and confusing.
  if (Array.isArray(a) && Array.isArray(b)) {
    return b
  }

  if (isPlainObject(a) && isPlainObject(b)) {
    if (a == null && b != null) {
      throw new Error(`Attempt to add unknown option, not defined in base config, at key '${key}': ${JSON.stringify(b)}`)
    }

    if (b == null && a != null) {
      throw new Error(`Attempt to erase config at key '${key}':\nSource:${JSON.stringify(a)}\nReplacement:${b}`)
    }

    // let lodash do deep merge for us
    return undefined
  }

  ensureValidValue(a, key)
  ensureValidValue(b, key)

  return undefined
})

export default projectConfig
