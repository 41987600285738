import { isOverlapAssets } from 'Util/RangeTools'

export const changeLayers = ({
  layers,
  targetLayerIndex,
  currentDragItemLayerIndex,
  dragAssetsByLayerIndex,
  staticAssets,
  insertLayers,
  dispatch,
  Layer,
  shiftTime,
  isIntersectionPlaceholder,
}) => {
  const cloneDragAssetsByLayerIndex = { ...dragAssetsByLayerIndex }

  const shiftLayerIndex = targetLayerIndex - currentDragItemLayerIndex
  const maxLayerIndex = layers.length - 1

  const newLayersByStartIndex = {}

  Object.keys(cloneDragAssetsByLayerIndex).forEach(key => {
    const newIndex = Number(key) + shiftLayerIndex
    const newLayersStartPasteIndex = newIndex < 0 ? 0 : maxLayerIndex

    if (newIndex < 0 || newIndex > maxLayerIndex) {
      const newLayer = new Layer()
      dispatch(insertLayers(newLayersStartPasteIndex, [ newLayer ], { addToHistory: false }))
      cloneDragAssetsByLayerIndex[
        key
      ] = cloneDragAssetsByLayerIndex[key]
        .map(asset => ({
          ...asset,
          newLayerId: newLayer.id,
        }))
    } else {
      const currentLayerId = layers[newIndex]?.id
      const isOverlapped = staticAssets
        .filter(asset => asset.layerId === currentLayerId)
        .find(staticAsset => cloneDragAssetsByLayerIndex[key].find(dragAsset => {
          const dragAssetStartTime = dragAsset.startTime + shiftTime
          const dragAssetEndTime = dragAssetStartTime + dragAsset.duration
          const staticAssetEndTime = staticAsset.startTime + staticAsset.duration

          return isOverlapAssets(dragAssetStartTime,
            dragAssetEndTime, staticAsset.startTime, staticAssetEndTime)
        }))

      if (isOverlapped && targetLayerIndex !== newIndex && !isIntersectionPlaceholder) {
        const newLayer = new Layer()
        dispatch(insertLayers(newIndex, [ newLayer ], { addToHistory: false, insertBefore: false }))
        cloneDragAssetsByLayerIndex[
          key
        ] = cloneDragAssetsByLayerIndex[key]
          .map(asset => ({
            ...asset,
            newLayerId: newLayer.id,
          }))
      } else {
        cloneDragAssetsByLayerIndex[
          key
        ] = cloneDragAssetsByLayerIndex[key]
          .map(asset => ({
            ...asset,
            newLayerId: currentLayerId,
          }))
      }
    }
  })

  return { newLayersByStartIndex, dragAssetsWithNewLayerId: cloneDragAssetsByLayerIndex }
}
