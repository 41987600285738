import { TRANSITIONS } from '~/enums'

export const getComposedAssets = ({
  allAssets,
  transitions,
  playingMediaAssetIds,
}) => transitions.filter(transition => transition?.type === TRANSITIONS.DISSOLVE)
  .reduce((acc, transition) => [
    ...acc,
    ...([ allAssets.find(el => transition.isAttachedTo(el.id, 'right')) ]
      .filter(asset => playingMediaAssetIds.every(id => id !== asset.id))),
  ], [])
