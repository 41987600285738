/* eslint-disable class-methods-use-this */
export class Folder {

  constructor({ name, id, permanent, isNew }) {
    this.name = name
    this.id = id
    this.isNew = isNew
    this.permanent = permanent
  }

  get canRemove() {
    return !this.permanent
  }

}
