import { isNil } from 'lodash'
import { pixel2Time, time2Pixel } from '~/Util'
import { DIRECTION } from '~/enums'
import { getAssetsAfterStopIntersectingMouse } from './getAssetsAfterStopIntersectingMouse'
import { getIntersectionData } from './getIntersectionData'

// When moving the mouse pointer away from static assets, the draggable assets are moved forward
export const setQuickOffset = ({
  dragItem,
  historyX,
  moveDirection,
  isMouseOver,
  clientOffset,
  leftXOffset,
  scale,
  overNewLayerIndex,
  staticAssets,
  offsetLayerIndex,
  layers,
  layerIds,
}) => {
  const clientMousePosition = pixel2Time(clientOffset.x - leftXOffset, scale)
  const { intersectedData: historyIntersectedData } = historyX.current

  if (!isMouseOver
      && isNil(overNewLayerIndex)
      && historyIntersectedData
  ) {
    // quickOffset - offset moves the block of dragged assets to a place where the mouse does not overlap with the assets
    let quickOffset = 0
    let { leftSideX } = historyX.current
    let hasChanges = false

    if (moveDirection === DIRECTION.LEFT
        && historyIntersectedData.staticStartTime >= clientMousePosition) {
      quickOffset = clientMousePosition
        - historyIntersectedData.staticEndTime - historyIntersectedData.draggableBlockDuration
      hasChanges = true
    } else if (moveDirection === DIRECTION.RIGHT
        && historyIntersectedData.staticEndTime <= clientMousePosition) {
      quickOffset = clientMousePosition
        - historyIntersectedData.staticStartTime + historyIntersectedData.draggableBlockDuration
      hasChanges = true
    }

    // historyIntersectedData.draggableOffset - offset value at the moment when the draggable assets stopped
    if (hasChanges) {
      const quickDraggableOffset = historyIntersectedData.draggableOffset + quickOffset
      // Min distance to the nearest static asset
      // stopAsset - nearest asset after movement with quickDraggableOffset
      const { stopAsset, dragAsset } = getAssetsAfterStopIntersectingMouse({
        moveDirection,
        staticAssets,
        dragItem,
        clientMousePosition,
        draggableOffset: quickDraggableOffset,
        layerIds,
        offsetLayerIndex,
      })
      const lastDraggableElemInitialStartTime = dragItem?.selectedAssets?.[
        dragItem?.selectedAssets?.length - 1]?.startTime
      const firstDraggableElemInitialStartTime = dragItem?.selectedAssets?.[0]?.startTime
      const currentDragElemInitialStartTime = dragAsset?.startTime

      // Return intersection point with the additional offset, since the draggable asset block is not smooth
      const resultXTime = moveDirection === DIRECTION.LEFT
        ? (stopAsset?.startTime ?? 0) - historyIntersectedData.draggableBlockDuration
          + (lastDraggableElemInitialStartTime - currentDragElemInitialStartTime)
        : (stopAsset?.startTime ?? 0) + (stopAsset?.duration ?? 0)
          - (currentDragElemInitialStartTime - firstDraggableElemInitialStartTime)

      // check if assets intersect with new offset
      const { isOverlapped } = getIntersectionData({
        staticAssets,
        dragItem,
        layers,
        draggableOffset: resultXTime - firstDraggableElemInitialStartTime,
        offsetLayerIndex,
      })

      leftSideX = time2Pixel(resultXTime, scale)

      if (!isOverlapped && (leftSideX >= 0)) {
        // eslint-disable-next-line no-param-reassign
        historyX.current = {
          ...historyX.current,
          readyForDrop: true,
          quickOffset,
          leftSideX: leftSideX + leftXOffset,
        }
      }
    }
  }
}
