import cx from 'classnames'
import React, { useContext } from 'react'


import { TranslationContext } from 'contexts/TranslationContext'
import SettingsInput from '~/components/Settings/SettingsInput'
import Accordion from '~/components/base/Expansion'

// TODO: move all text form controls into this folder
import Position from '~/components/Settings/Position'
import SwitchColorStyle from '~/components/Settings/SwitchColorStyle'
import Typography from '~/components/Settings/Typography'

import { ReactComponent as LineWeightIcon } from '~/assets/settings/line_weight.svg'

import Form from '../../BaseAssetSettingsForm'
import styles from './form.module.scss'
import useTextAssetFormHandlers from './useFormHandlers'

export default function TextAssetSettingsForm(props) {
  const {
    /** @type {Assets.TextAsset} */
    asset,
    onChange,
    ...rest
  } = props

  const {
    /** @type {TextAssetSettings} */
    settings,
  } = asset

  const { disableShadow = true, disableOutline = true, shadowOffset = { x: 2, y: 2 } } = settings

  const {
    onBackgroundChanged,
    onChangeOutlineColor,
    onChangeOutlineWidth,
    onChangePosition,
    onChangeShadowBlur,
    onChangeShadowColor,
    onChangeTextAlign,
    onColorChanged,
    onFontChanged,
    onFontSizeChanged,
    onFontStyleChanged,
    onTextChanged,
    onTextTransformChanged,
    onToggleOutline,
    onToggleShadow,
    onChangeOffsetX,
    onChangeOffsetY,
  } = useTextAssetFormHandlers(asset, onChange)

  const { t } = useContext(TranslationContext)

  return (
    <Form
      {...rest}
      asset={asset}
      title={t('TEXT_FORM_TEXT_TITLE_SETTINGS')}
      description={asset.description}
    >
      <Accordion caption={t('TEXT_FORM_CAPTION_FONT')}>
        <Typography
          onTextChanged={onTextChanged}
          onChangeTextTransform={onTextTransformChanged}
          onColorChanged={onColorChanged}
          onBackgroundChanged={onBackgroundChanged}
          onFontChanged={onFontChanged}
          onFontSizeChanged={onFontSizeChanged}
          onFontStyleChanged={onFontStyleChanged}
          onChangeTextAlign={onChangeTextAlign}
          settings={settings}
        />
      </Accordion>

      <Accordion caption={t('TEXT_FORM_CAPTION_SIZE')}>
        <Position
          position={settings.position}
          offset={settings.offset}
          onChangePosition={onChangePosition}
        />
      </Accordion>

      <Accordion caption={t('TEXT_FORM_CAPTION_OUTLINE')}>
        <SwitchColorStyle
          defaultValue={settings.outlineWidth}
          icon={<LineWeightIcon className="settings-input__icon" />}
          color={settings.outlineColor}
          onChangeColor={onChangeOutlineColor}
          onChangeValue={onChangeOutlineWidth}
          onSwitch={onToggleOutline}
          checked={!disableOutline}
        />
      </Accordion>

      <Accordion caption={t('TEXT_FORM_CAPTION_SHADOW')}>
        <div className={styles.shadowContainer}>
          <SwitchColorStyle
            defaultValue={settings.shadowBlur}
            icon={<div className="settings-input__icon">{t('TEXT_FORM_TEXT_BLUR')}</div>}
            color={settings.shadowColor}
            onChangeColor={onChangeShadowColor}
            onChangeValue={onChangeShadowBlur}
            onSwitch={onToggleShadow}
            checked={!disableShadow}
          />
          <div className={styles.shadowOffset}>
            <div className={cx(styles.shadowOffsetTitle,
              { [styles.disabled]: disableShadow })}
            >
              {t('TEXT_FORM_TEXT_OFFSET')}
            </div>
            <SettingsInput
              defaultValue={shadowOffset.x}
              onChange={onChangeOffsetX}
              icon={<div className="settings-input__icon">X</div>}
              min={0}
              max={120}
              disabled={disableShadow}
            />
            <SettingsInput
              defaultValue={shadowOffset.y}
              onChange={onChangeOffsetY}
              icon={<div className="settings-input__icon">Y</div>}
              min={0}
              max={120}
              disabled={disableShadow}
            />
          </div>
        </div>
      </Accordion>
    </Form>
  )
}
