import { DummyHeader } from 'components/AssetPanels/components/DummyHeader'
import React, { useContext } from 'react'

import { TranslationContext } from 'contexts/TranslationContext'
import List from '../BaseAssetsList'
import Item from './ListItem'

export default function TextAssetsList(props) {
  const { t } = useContext(TranslationContext)

  return (
    <List
      {...props}
      header={(
        <DummyHeader
          caption={t('TEXT_HEADER_TEXT_CAPTION_TEXT')}
        />
      )}
      ItemComponent={props => <Item {...props} />}
    />
  )
}
