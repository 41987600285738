import React, { useEffect, useState } from 'react'
import { InferProps } from 'prop-types'

import ColorStyle from 'components/Settings/ColorStyle'
import Switch from 'components/base/Switch'
import styles from './switchColorStyle.module.scss'

type Props = InferProps<typeof ColorStyle.propTypes> & { checked?: boolean, onSwitch?: () => void }

function SwitchColorStyle(props: Props) {
  const { onSwitch = () => {}, checked = false, ...rest } = props
  const [ _checked, setChecked ] = useState(checked)

  useEffect(() => {
    setChecked(checked)
  }, [ checked ])

  function handleChange() {
    onSwitch()
    setChecked(x => !x)
  }

  return (
    <div className={styles.container}>
      <Switch onChange={handleChange} checked={_checked} />
      <ColorStyle
        {...rest}
        disabled={!_checked}
      />
    </div>
  )
}

export default SwitchColorStyle
