import { selectMediaRecordingType } from './mainView'

export const selectCountdown = (state: RootState) => {
  const mediaType = selectMediaRecordingType(state)
  if (!mediaType) return false
  return state.recording[mediaType].countdown
}

export const selectShowCountdown = (state: RootState) => state.recording.showCountdown

export const selectMediaRecordingSettingsState = (state: RootState) => state.mainView
  .showFeatures.mediaRecordingSettings

export const selectRecordingStatus = (state: RootState) => state.recording.recordingStatus

export const isRecordingStarted = (state: RootState) => state.recording.recordingStatus === 'progress'
  || state.recording.showCountdown

export const isRecordingSaving = (state: RootState) => state.recording.recordingStatus === 'saving'

export const selectLastMediaRecording = (state: RootState) => {
  const { ids, entities } = state.recording.assets
  const lastId = ids.at(-1)
  return lastId ? entities[lastId] : null
}

export const selectRewindSlider = (state: RootState) => {
  const mediaType = selectMediaRecordingType(state)
  if (!mediaType) return false
  return state.recording[mediaType].rewindSlider
}

export const selectRewindSliderByType = (mediaType: ReturnType<typeof selectMediaRecordingType>) => (
  state: RootState
) => {
  if (!mediaType) return false
  return state.recording[mediaType].rewindSlider
}

export const selectMuted = (state: RootState) => {
  const mediaType = selectMediaRecordingType(state)
  if (!mediaType) return false
  return state.recording[mediaType].muted
}
