import Button from 'components/base/Button'
import { Dialog } from 'components/base/Dialog/Dialog'
import { TranslationContext } from 'contexts/TranslationContext'
import { useModalDialog } from 'hooks'
import React, { useContext, useEffect } from 'react'
import { AddLicenseModal } from '../add-license-modal/AddLicenseModal'
import './activation-banner-modal.scss'
import { ReactComponent as BannerImage } from './banner-img.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let nw: any

export const ActivationBannerModal = () => {
  const modal = useModalDialog()
  const addLicenseModal = useModalDialog()

  useEffect(() => {
    modal.open()
    return () => modal.close()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickContinue = () => {
    modal.close()
  }

  const handleClickHaveKey = () => {
    modal.close()
    addLicenseModal.open()
  }

  const handleClickViewTariffs = () => {
    nw.Shell.openExternal(__CFG__.ACTIVATION_TARIFFS_URL)
  }

  const { t } = useContext(TranslationContext)

  return (
    <>
      <Dialog className="banner" open={modal.active} onClose={modal.close}>
        <div className="banner_title">
          {t('DESKTOP_ACTIVATION_FEATURE_TEXT_UPGRADE_EDITOR_TO_PAID_VER')}
        </div>
        <div className="banner_point">
          {t('DESKTOP_ACTIVATION_FEATURE_TEXT_NO_WATERMARK')}
          <div className="banner_point_description">
            {t('DESKTOP_ACTIVATION_FEATURE_TEXT_REMOVE_WATERMARK')}
          </div>
        </div>
        <Button
          className="banner_button"
          primary
          onClick={handleClickViewTariffs}
          title={t('DESKTOP_ACTIVATION_FEATURE_BTN_VIEW_ALL_TARIFFS_TOOLTIP')}
          data-lang-id="DESKTOP_ACTIVATION_FEATURE_BTN_VIEW_ALL_TARIFFS"
        >
          {t('DESKTOP_ACTIVATION_FEATURE_BTN_VIEW_ALL_TARIFFS')}
        </Button>
        <div className="banner_link" onClick={handleClickContinue}>
          {t('DESKTOP_ACTIVATION_FEATURE_TEXT_CONTINUE_WITH_FREE')}
        </div>

        <div className="banner_link" onClick={handleClickHaveKey}>
          {t('DESKTOP_ACTIVATION_FEATURE_TEXT_I_HAVE_A_KEY')}
        </div>
        <BannerImage className="banner_image" />
      </Dialog>
      <AddLicenseModal modal={addLicenseModal} />
    </>
  )
}
