import FormControlLabel from '@material-ui/core/FormControlLabel'
import React, { useContext, useEffect, useState } from 'react'

import SettingsInput from 'components/Settings/SettingsInput'
import Checkbox from 'components/base/Checkbox'
import Slider from 'components/base/Slider'

import { MAX_ASSET_VOLUME } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import styles from './volumeField.module.scss'
// ---

const maxAssetVolume = 100 * MAX_ASSET_VOLUME

type Props = {
  muted: boolean,
  onChangeMuted: (x: boolean) => void,
  volume: number,
  onChangeVolumeCommit: (x: number) => void
}

// TODO: refactor to SliderSettings component
export default function VolumeFieldAudio(props: Props) {
  const {
    muted = false,
    onChangeMuted = () => {},
    volume = 0,
    onChangeVolumeCommit = () => {},
  } = props

  const { t } = useContext(TranslationContext)

  const [ value, setValue ] = useState(volume)

  useEffect(() => {
    setValue(volume)
  }, [ volume ])

  const stringValue = value.toString()

  return (
    <div className={styles.container}>
      <FormControlLabel
        label={t('FORM_FIELD_LABEL_VOLUME')}
        classes={{
          root: styles.labelRoot,
        }}
        control={(
          <Checkbox
            className={styles.checkbox}
            checked={!muted}
            onChange={onChangeMuted}
            emitValue
          />
        )}
      />
      <Slider
        min={0}
        max={maxAssetVolume}
        step={1}
        disabled={muted}
        value={value}
        marks={[{ value: maxAssetVolume / 4 },
          { value: 2 * maxAssetVolume / 4 },
          { value: 3 * maxAssetVolume / 4 }]}
        classes={{
          root: styles.sliderRoot,
          track: styles.track,
          mark: styles.mark,
          markActive: styles.markActive,
          thumb: styles.thumb,
        }}
        scale={x => 0.25 * x}
        onChange={setValue}
        onChangeCommitted={onChangeVolumeCommit}
      />
      <SettingsInput
        onChange={() => {
          // eslint-disable-next-line no-nested-ternary
          const num = Math.min(maxAssetVolume, Math.max(0, value))
          setValue(num)
          onChangeVolumeCommit(num)
        }}
        inputProps={{
          mask: 'NUM%',
          onComplete: (x: string) => {
            if (x === ' ') return
            const str = x.includes('%') ? x.slice(0, -1) : x
            const num = parseInt(str)
            const value = isNaN(num) ? 0 : num
            setValue(value)
          },
          lazy: stringValue === '',
          blocks: {
            NUM: {
              mask: Number,
              min: 0,
              max: 100000,
              radix: '.',
              scale: 1,
            },
          },
          placeholder: `${maxAssetVolume}%`,
          value: stringValue,
        }}
        className={styles.input}
        variant="secondary"
        disabled={muted}
      />
    </div>
  )
}
