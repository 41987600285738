import React, { useContext, useEffect, useState } from 'react'
import { DropzoneOptions } from 'react-dropzone'
import { useHistory } from 'react-router-dom'

import Upload from 'components/Projects/components/Import/components/Upload'
import { Dialog, DialogContent, DialogTitle } from 'components/base/Dialog/Dialog'
import { PROJECT_TYPE } from 'enums'

import Error from 'components/Projects/components/Import/components/Error'
import Progress from 'components/Projects/components/Import/components/Progress'
import useUpload from 'components/Projects/components/Import/hooks/useUpload'
import { Stage } from 'components/Projects/components/Import/types'
import { UploadContext } from 'components/Projects/components/Import/uploadContext'

import { FormControlLabel } from '@material-ui/core'
import { AppRoutes } from 'AppRoutes'
import FramerateSelect from 'components/Projects/components/Import/components/FramerateSelect'
import { getExtension, textOverflowHandler } from 'components/Projects/components/Import/utils'
import Checkbox from 'components/base/Checkbox'
import CheckedIcon from 'components/base/Checkbox/CheckedIcon'
import UncheckedIcon from 'components/base/Checkbox/UnchekedIcon'
import { ConfirmationDialog } from 'components/base/ConfirmationDialog/ConfirmationDialog'
import { TranslationContext } from 'contexts/TranslationContext'
import styles from './import.module.scss'

type ProjectTypes = typeof PROJECT_TYPE[keyof typeof PROJECT_TYPE]
const acceptedTypes: Array<ProjectTypes> = Object.values(PROJECT_TYPE)

let dropzoneParams: DropzoneOptions = { accept: acceptedTypes,
  multiple: false,
  preventDropOnDocument: true }

type Props = {
  onClose: () => void
}

const stageTextMap: Omit<Record<Stage, string>, 'stop' | 'error'> = {
  import_media: 'Import media files',
  unpack: 'Unpacking',
  upload: 'Uploading file',
  ready: 'File succesfully uploaded!',
}

function Import({ onClose }: Props) {
  const history = useHistory()
  const { setFile, resetFile, state } = useContext(UploadContext)
  const [ fileError, setFileError ] = useState('')
  const [ uploadError, setUploadError ] = useState('')

  const [ settingsOpened, setSettingsOpened ] = useState(false)
  const [ handleCloseClicked, setHandleCloseClicked ] = useState(false)
  const [ confirmCancel, setConfirmCancel ] = useState(false)

  const { uploadCancel, uploadService, progress,
    stage, setStage, sessionId, settings, setSettings } = useUpload({ onError: setUploadError })

  const acceptedFile = state.files.accepted[0]

  useEffect(() => {
    setSettingsOpened(!!acceptedFile)
  }, [ acceptedFile ])

  useEffect(() => {
    if (stage !== 'stop') setSettingsOpened(false)
    if (stage === 'stop') { setConfirmCancel(false); resetFile() }
    if (stage === 'ready' || stage === 'error') setConfirmCancel(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ stage ])

  dropzoneParams = {
    ...dropzoneParams,
    onDrop: (accepted, rejected) => {
      setFile({ accepted, rejected })
    },
  }

  const { t } = useContext(TranslationContext)

  const handleClose = () => {
    setHandleCloseClicked(true)
    if (stage !== 'stop' && stage !== 'ready' && stage !== 'error') {
      setConfirmCancel(true)
    } else {
      resetFile()
      onClose()
    }
  }

  const cancel = () => {
    resetFile()
    uploadCancel()
    if (handleCloseClicked && stage !== 'stop' && stage !== 'ready') {
      onClose()
    }
  }

  const uploadComponent = (
    <Upload
      dropzoneParams={dropzoneParams}
      error={<Error message={fileError} className={styles.error} disabled={fileError === ''} />}
    >
      <div className={styles.checkboxContainer}>
        <FormControlLabel
          label={t('PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA')}
          classes={{ label: styles.checkboxLabel }}
          control={(
            <Checkbox
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon stroke="#A2A2A2" />}
              classes={{ root: styles.checkbox }}
              checked={settings.createFolder}
              onChange={(value: boolean) => setSettings({ createFolder: value })}
              emitValue
            />
          )}
          onClick={e => e.stopPropagation()}
        />

      </div>
    </Upload>
  )

  const dialogContentChild = () => {
    // if (!acceptedFile) {
    //   return uploadComponent
    // }
    switch (stage) {
      case 'stop':
        return uploadComponent
      case 'upload':
      case 'import_media':
      case 'unpack':
      case 'ready':
      case 'error':
      {
        let action = {
          text: t('PROJECTS_IMPORT_TEXT_CANCEL'),
          handler: () => setConfirmCancel(true),
        }
        if (stage === 'ready') {
          action = { text: t('PROJECTS_IMPORT_TEXT_OPEN_EDITOR'),
            handler: () => {
              history.push(`${AppRoutes.Projects}/${sessionId}`)
            } }
        }
        if (stage === 'error') {
          action = {
            text: t('PROJECTS_IMPORT_TEXT_TRY_AGAIN'),
            handler: () => setStage('stop'),
          }
        }
        return (
          <Progress
            stage={stage}
            desc={stage === 'error' ? uploadError : stageTextMap[stage]}
            progress={progress}
            file={acceptedFile}
            action={action}
          />
        ) }
      default:
        return null
    }
  }

  useEffect(() => {
    let id: number
    const ms = 3000
    const { rejected, accepted } = state.files

    if ((rejected.length > 1 && accepted.length === 0)
      || rejected.some(item => item.errors.some(item => item.code === 'too-many-files'))) {
      setFileError(t('PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD'))
      id = window.setTimeout(() => { setFileError('') }, ms)
    }

    if (rejected.length === 1
      && accepted.length === 0
      && rejected[0].errors.some(item => item.code === 'file-invalid-type')) {
      setFileError(t('PROJECTS_IMPORT_INVALID_FILE_EXTENSION'))
      id = window.setTimeout(() => { setFileError('') }, ms)
    }

    return () => { setFileError(''); window.clearTimeout(id) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state.files ])

  const handleSettingsClosed = () => {
    setSettingsOpened(false)
    resetFile()
  }

  const onConfirm = () => uploadService(acceptedFile)

  const acceptedFileSettings = () => {
    const extension = getExtension(acceptedFile.name) as ProjectTypes
    switch (extension) {
      case '.edl.zip':
        return (
          <FramerateSelect
            onClose={handleSettingsClosed}
            onConfirm={onConfirm}
            onChange={setSettings}
            name={textOverflowHandler(acceptedFile.name)}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <Dialog onClose={handleClose} classes={{ paper: styles.dialog }} open>
        <DialogTitle disableTypography>
          <span className={styles.title}>Import project file</span>
        </DialogTitle>
        <DialogContent className={styles.inputContainer}>
          {dialogContentChild()}
        </DialogContent>
      </Dialog>
      {settingsOpened && acceptedFileSettings()}
      {confirmCancel && (
      <ConfirmationDialog
        title={t('PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT')}
        confirmBtnText={t('PROJECTS_IMPORT_CONFIRM_TEXT_YES')}
        description=""
        onCancel={() => { setConfirmCancel(false); setHandleCloseClicked(false) }}
        onConfirm={cancel}
      />
      )}
    </>
  )
}

export default Import
