import React from 'react'

import Panel from 'components/AssetPanels/BaseAssetsPanel'
import { SOURCE_FILE_TYPES } from '~/enums'

import Settings from './Settings'
import List from './List'

export default function TextAssetsPanel() {
  return (
    <Panel
      type={SOURCE_FILE_TYPES.TEXT}
      ListComponent={List}
      SettingsComponent={Settings}
    />
  )
}
