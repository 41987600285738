import React from 'react'
import MaterialRadio from '@material-ui/core/Radio'
import './Radio.scss'
import classNames from 'classnames'

const BpIcon = () => (<span className="base-radio-icon" />)
const BpCheckedIcon = () => (<span className="base-radio-icon base-radio-icon_checked" />)

export default function Radio({ className, ...rest }) {
  return (
    <MaterialRadio
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      className={classNames(className)}
      {...rest}
    />
  )
}

Radio.defaultProps = {
  color: 'default',
  disableRipple: true,
}
