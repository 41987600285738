import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import * as Actions from '~/actions'
import { ReactComponent as IconAddFolder } from '~/assets/media/ic_add_folder.svg'
import FloatButton from '~/components/base/FloatButton'
import { DEFAULT_FOLDER_NAME } from '~/constant'
import { SOURCE_FILE_TYPES } from '~/enums'
import { useAction } from '~/hooks'
import { selectCurrentFolderId } from '~/selectors'
import './AddFolderButton.scss'

export const AddFolderButton = props => {
  const currentFolderId = useSelector(
    state => selectCurrentFolderId(state, SOURCE_FILE_TYPES.MEDIA)
  )
  const { t } = useContext(TranslationContext)

  const createFolder = useAction(
    Actions.folders.createFolder,
    SOURCE_FILE_TYPES.MEDIA,
    DEFAULT_FOLDER_NAME,
    currentFolderId
  )
  return (
    <FloatButton
      bottomRight
      type="button"
      className="add-folder-button"
      onClick={createFolder}
      {...props}
      data-lang-id="ADD_FOLDER_BTN"
      title={t('ADD_FOLDER_BTN_TOOLTIP')}
    >
      {t('ADD_FOLDER_BTN')}
      <IconAddFolder />
    </FloatButton>
  )
}
