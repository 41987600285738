import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import * as Actions from '~/actions'
import { useAction } from '~/hooks'
import * as Selectors from '~/selectors'

export default function LoadButton({ type }) {
  const { t } = useContext(TranslationContext)

  const {
    items,
    allFilesFetched,
    loading,
  } = useSelector(state => Selectors.getSourceFilesGroup(state, type))
  const fetchFiles = useAction(Actions.sourceFiles.loadSourceFiles, type)

  const onLoadClick = useCallback(() => {
    fetchFiles({ start: items.length, count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER })
  }, [ fetchFiles, items.length ])

  const buttonText = loading ? `${t('MEDIA_BTN_LOADING')}...` : t('MEDIA_BTN_LOAD_MORE_MEDIA')

  return (
    allFilesFetched || items.length === 0 ? null : (
      <Button
        primary
        onClick={onLoadClick}
        className="load-button"
        title={loading ? `${t('MEDIA_BTN_LOADING_TOOLTIP')}...` : t('MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP')}
        data-lang-id={loading ? 'MEDIA_BTN_LOADING' : 'MEDIA_BTN_LOAD_MORE_MEDIA'}
      >
        {buttonText}
      </Button>
    )
  )
}
