import { Tooltip } from '@material-ui/core'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectExternalLoginUrl } from 'selectors/app'
import { TranslationContext } from 'contexts/TranslationContext'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import Avatar from '../Avatar'
import * as AuthToken from '~/cookieServices/AuthToken'

export default function Profile() {
  const { t } = useContext(TranslationContext)
  const externalLoginUrl = useSelector(selectExternalLoginUrl)
  const profileMenuItems = []
  if (externalLoginUrl) {
    profileMenuItems.push({
      text: t('HEADER_PROFILE_MENU_ITEM_LOG_OUT'),
      onClick: () => {
        localStorage.clear()
        AuthToken.removeAllCookies()
        window.location.href = externalLoginUrl
      },
    })
  }

  const user = useSelector(state => state.user)
  const src = user.avatar || user.avatarUrl || user.defaultUserAvatar
  return (
    // {/* <span className="header__status">Pro</span> */}
    <DropdownMenu menuItems={profileMenuItems} emitFromContent>
      <Tooltip enterDelay={500} title={user.displayName}>
        <div className="header__user">
          <Avatar hideDefault={user.hideDefaultUserAvatar} src={src} />
          <span className="header__user-name">{user.displayName}</span>
        </div>
      </Tooltip>
    </DropdownMenu>
  )
}
