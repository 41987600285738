import * as ActionTypes from 'actions/ActionTypes'
import { deleteEditingTask } from '~/ServerAPI'

export const deleteTask = taskId => ({
  type: ActionTypes.DELETE_EDITING_TASK,
  payload: { taskId },
})

export const deleteTaskError = err => ({
  type: ActionTypes.DELETE_EDITING_TASK,
  payload: { err },
  error: true,
})

export const requestDeleteTask = taskId => async dispatch => {
  try {
    const response = await deleteEditingTask(taskId)
    dispatch(deleteTask({ taskId }))
    if (response.data.status !== '') { // error in editing engine
      const err = new Error(response.data.status)
      dispatch(deleteTaskError({ err }))
      return
    }
  } catch (error) {
    const err = new Error(error.toString())
    dispatch(deleteTaskError({ err }))
  }
}
