import { time2Pixel } from '~/Util'

export const getDragAssetsLeftOffset = (draggingAssets, scale, transitions) => {
  const sortingAssets = draggingAssets.sort((a, b) => a.startTime - b.startTime)
  const offset = sortingAssets?.[0]?.startTime
  return sortingAssets.reduce((result, asset) => {
    const dissolveORFadeIn = transitions.find(tr => tr.isAttachedTo(asset.id, 'right'))
    const transitionOffset = (dissolveORFadeIn?.duration ?? 0) * -1
    const leftOffset = time2Pixel(asset.startTime - offset - transitionOffset, scale)
    // eslint-disable-next-line no-param-reassign
    result[asset.id] = leftOffset
    return result
  }, {})
}
