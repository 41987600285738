import * as ActionTypes from 'actions/ActionTypes'
import produce from 'immer'

const initialState = {
  scale: {
    x: 1,
    y: 1,
  },
}

const preview = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.SET_PREVIEW_PARAMS: {
      const { scale, boundingRect } = action.payload
      state.scale = scale
      state.boundingRect = boundingRect
    }
  }
}

export default produce(preview, initialState)
