import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import ColorPicker from 'components/base/ColorPicker'
import SettingsInput from '~/components/Settings/SettingsInput'
import './ColorStyle.scss'
import { ReactComponent as BackgroundIcon } from '~/assets/settings/editor_format_color_fill.svg'

export default function ColorStyle({ defaultValue, icon, color, onChangeColor, onChangeValue,
  disabled }) {
  const onChange = useCallback(e => {
    const { value } = e.target
    let number = parseFloat(value)
    if (isNaN(number)) {
      onChangeValue(0)
      return
    }
    if (number >= 350) { number = 350 }
    if (number <= 0) { number = 0 }
    onChangeValue(value === '' ? 0 : number)
  }, [ onChangeValue ])

  return (
    <div className={cx('color-style', { 'color-style--disabled': disabled })}>
      <div className="color-style__picker">
        <ColorPicker
          Icon={BackgroundIcon}
          color={color}
          onChangeColor={onChangeColor}
          disabled={disabled}
        />
      </div>
      <SettingsInput
        defaultValue={defaultValue}
        onChange={onChange}
        icon={icon}
        min={0}
        max={350}
        disabled={disabled}
      />
    </div>
  )
}

ColorStyle.propTypes = {
  defaultValue: PropTypes.number,
  icon: PropTypes.element,
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  onChangeColor: PropTypes.func,
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
}

ColorStyle.defaultProps = {
  defaultValue: 0,
  icon: null,
  color: PropTypes.shape({
    r: 255,
    g: 255,
    b: 255,
    a: 1,
  }),
  onChangeColor: () => {},
  onChangeValue: () => {},
  disabled: false,
}
