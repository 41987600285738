import { time2Pixel } from '~/Util'
import { DIRECTION } from '~/enums'

export const setIntersectedXParams = ({
  historyX,
  moveDirection,
  leftXOffset,
  scale,
  targetLayerIndex,
  isIntersected,
  intersectedData,
  isOverlapped,
  isMouseOver,
}) => {
  let leftSideX = 0

  if (intersectedData) {
    if (moveDirection === DIRECTION.LEFT) {
      leftSideX = time2Pixel(intersectedData.staticEndTime
        + intersectedData.leftInsideBlockItemOffset, scale) + leftXOffset
    } else {
      leftSideX = time2Pixel(intersectedData.staticStartTime
        + intersectedData.rightInsideBlockItemOffset
        - intersectedData.draggableBlockDuration, scale) + leftXOffset
    }
  }

  if (leftSideX < leftXOffset) {
    return
  }

  if (!historyX.current.leftSideX
    || (!isMouseOver && !isOverlapped)
    || (targetLayerIndex !== historyX.current.currentLayerIndex)) {
    // eslint-disable-next-line no-param-reassign
    historyX.current = {
      ...historyX.current,
      isIntersected,
      leftSideX,
      intersectedData,
      currentLayerIndex: targetLayerIndex,
      moveDirection,
    }
  }
}
