export const getRelativeAssetSize = (size, videoSize) => {
  const { width, height } = size
  const { width: vWidth, height: vHeight } = videoSize
  if (width >= vWidth || height >= vHeight) {
    return {
      width: 100,
      height: 100,
    }
  } return {
    width: null,
    height: null,
  }
}
