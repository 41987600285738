import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// eslint-disable-next-line no-unused-vars
import { ButtonProps } from '@material-ui/core/Button'
import './Button.scss'

/**
 *
 * @param {ButtonProps} props
 * @returns
 */
export default function Button(props) {
  const {
    className,
    primary,
    secondary,
    third,
    border,
    large,
    children,
    text,
    startIcon,
    disabled,
    ...rest
  } = props
  const classNames = cx('btn', {
    'btn--primary': primary,
    'btn--secondary': secondary,
    'btn--third': third,
    'btn--border': border,
    'btn--text': text,
    'btn--large-size': large,
    'btn--disabled': disabled,
  }, className)

  if (rest.href) {
    return (
      <a
        className={classNames}
        {...rest}
      >
        {startIcon}
        {' '}
        {children}
      </a>
    )
  }

  return (
    <div
      className={classNames}
      {...rest}
    >
      {startIcon}
      {' '}
      {children}
    </div>
  )
}

Button.defaultProps = {
  color: 'default',
  large: false,
  text: false,
  primary: false,
  secondary: false,
  third: false,
  border: false,
  children: '',
  className: '',
  startIcon: null,
  disabled: false,
  onClick: () => {},
}

Button.propTypes = {
  primary: PropTypes.bool,
  text: PropTypes.bool,
  large: PropTypes.bool,
  secondary: PropTypes.bool,
  third: PropTypes.bool,
  border: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  startIcon: PropTypes.element,
  disabled: PropTypes.bool,
}
