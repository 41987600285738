import React from 'react'
import { compose } from 'redux'
import { debounce } from 'lodash'
import { useChanged, useConst } from './changes'

// ---

/**
 * @param {function} fn
 * @param {...*} boundArgs
 * @returns {function}
 */
export function useBind(fn, ...boundArgs) {
  return React.useCallback(
    (...args) => fn(...boundArgs, ...args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ fn, ...boundArgs ]
  )
}

/**
 * @param {...function} funcs
 * @returns {function}
 */
export function useCompose(...funcs) {
  return React.useMemo(
    () => compose(...funcs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ ...funcs ]
  )
}

/**
 * @template T
 * @param {...function(T): *} funcs
 * @returns {function(T): T}
 */
export function useTap(...funcs) {
  return React.useMemo(
    () => {
      if (funcs.length === 0) {
        return x => x
      }

      if (funcs.length === 1) {
        return funcs[0]
      }

      return funcs.reduce(
        (chain, fn) => x => {
          fn(x)
          chain(x)
          return x
        }
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ ...funcs ]
  )
}

// ---

/**
 * @template {function(...*): *} T
 * @param {T} fn
 * @param {number} delay
 * @param {object} [options]
 * @param {boolean} [options.persist=false]
 * When `false`, debounced func will be re-created whenever source func changes, as normal.
 * When `true`, debounced func will access source func via ref, and only re-created when delay/options change
 * (and thus, source func doesn't have to be memoized).
 * Use persistent version for very frequently-changed functions (like smth tied to mouseover/resize coordinates).
 * @return {T}
 */
export function useDebounce(fn, delay, options = {}) {
  const { persist = false, ...rest } = options
  const opts = useChanged(rest)

  // ---

  const refPersist = React.useRef(persist)
  if (persist !== refPersist.current) {
    throw new Error('Option `persist` must not change during component lifecycle')
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  if (persist) {
    const refFn = React.useRef()
    refFn.current = fn

    return React.useMemo(
      () => debounce((...args) => refFn.current(...args), delay, opts),
      [ delay, opts ]
    )
  }

  return React.useMemo(
    () => debounce(fn, delay, opts),
    [ fn, delay, opts ]
  )
  /* eslint-enable */
}

// ---

/**
 * composes given funcs with a pure function – which allows to reduce noise of `useCallback(..., [])`
 *
 * Before:
 * <code>
 *   const { onChange } = props
 *   const handleChange = useCallback(e => {
 *     onChange(e.target.value)
 *   }, [ onChange ])
 * </code>
 *
 * After:
 * <code>
 *   const { onChange } = props
 *   // `handleChange` will be memoized with useCallback, despite second argument isn't memoized.
 *   const handleChange = useComposeConst(onChange, e => e.target.value)
 * </code>
 *
 * @param {...function} funcs
 * @returns {function}
 */
export function useComposeConst(...funcs) {
  const last = funcs.pop()
  return useCompose(...funcs, useConst(last))
}

/**
 * Lazy version of `useComposeConst`.
 * Good for usage with `useReducer`, to create named actions:
 *
 * <code>
 *   const [ state, dispatch ] = useReducer(...)
 *   const useAction = useLazyComposeConst(dispatch)
 *   const onChangeProp = useAction(value => state => { state.prop = value })
 * </code>
 *
 * @param {...function} funcs
 * @returns {function(...*): function}
 */
export function useLazyComposeConst(...funcs) {
  return useBind(useComposeConst, ...funcs)
}
