import React, { useContext } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from 'components/base/Radio'
import './PositionSelector.scss'
import cx from 'classnames'
import { TEXT_POSITION as POSITION } from '~/enums'
import { TranslationContext } from '~/contexts/TranslationContext'

function getIconClassName(position) {
  switch (position) {
    case POSITION.TOP_LEFT: return 'square--top-left'
    case POSITION.LEFT: return 'square square--middle-left'
    case POSITION.TOP: return 'square square--top-center'
    case POSITION.BOTTOM: return 'square square--bottom-center'
    case POSITION.RIGHT: return 'square square--middle-right'
    case POSITION.BOTTOM_LEFT: return 'square square--bottom-left'
    case POSITION.TOP_RIGHT: return 'square square--top-right'
    case POSITION.BOTTOM_RIGHT: return 'square square--bottom-right'
    case POSITION.MIDDLE_CENTER: return 'square square--middle-center'
    default: return null
  }
}

function Option({ value, dataLangId, title }) {
  const $icon = (
    <div className={cx('square', getIconClassName(value))} />
  )

  return (
    <Radio value={value} icon={$icon} checkedIcon={$icon} title={title} data-lang-id={dataLangId} />
  )
}

export default function PositionSelector({ value, onChange }) {
  const { t } = useContext(TranslationContext)
  return (
    <RadioGroup
      className="position-options-select"
      name="textPosition"
      value={value}
      onChange={onChange}
    >
      <Option value={POSITION.TOP_LEFT} dataLangId="SETTINGS_TEXT_POSITION_BTN_TOP_LEFT" title={t('SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP')} />
      <Option value={POSITION.TOP} dataLangId="SETTINGS_TEXT_POSITION_BTN_TOP" title={t('SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP')} />
      <Option value={POSITION.TOP_RIGHT} dataLangId="SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT" title={t('SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP')} />
      <Option value={POSITION.LEFT} dataLangId="SETTINGS_TEXT_POSITION_BTN_LEFT" title={t('SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP')} />
      <Option value={POSITION.MIDDLE_CENTER} dataLangId="SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER" title={t('SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP')} />
      <Option value={POSITION.RIGHT} dataLangId="SETTINGS_TEXT_POSITION_BTN_RIGHT" title={t('SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP')} />
      <Option value={POSITION.BOTTOM_LEFT} dataLangId="SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT" title={t('SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP')} />
      <Option value={POSITION.BOTTOM} dataLangId="SETTINGS_TEXT_POSITION_BTN_BOTTOM" title={t('SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP')} />
      <Option value={POSITION.BOTTOM_RIGHT} dataLangId="SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT" title={t('SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP')} />
    </RadioGroup>
  )
}
