import { TranslationContext } from 'contexts/TranslationContext'
import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { ReactComponent as LineWeightIcon } from '~/assets/settings/line_weight.svg'
import ColorStyle from '~/components/Settings/ColorStyle'
import Position from '~/components/Settings/Position'
import Rotation from '~/components/Settings/Rotation'
import Size from '~/components/Settings/Size'
import Accordion from '~/components/base/Expansion'
import Form from '../../BaseAssetSettingsForm'
import SliderSettings from './Fields/SliderSettings'
import useImageAssetFormHandlers from './useImageFormHandlers'

export default function ImageAssetSettingsForm(props) {
  const {
    /** @type {Assets.VideoAsset} */
    asset,
    onChange,
    ...rest
  } = props

  const {
    /** @type {ImageAssetSettings} */
    settings,
  } = asset

  const {
    rotation,
    flip,
    opacity,
    outlineColor,
    outlineWidth,
    shadowColor,
    shadowBlur,
    keepAspectRatio,
    originalSize,
  } = settings

  const referenceAsset = useSelector(getReferenceVideoAsset)
  const size = getAbsoluteAssetSize(asset, referenceAsset)
  const { width, height } = size

  const {
    onChangeOutlineColor,
    onChangeOutlineWidth,
    onChangePosition,
    onChangeShadowBlur,
    onChangeShadowColor,
    onChangeSize,
    onChangeKeepAspectRatio,
    onChangeRotation,
    onChangeFlip,
    onChangeOpacity,
    onClickFitToFrame,
    onClickFillToFrame,
  } = useImageAssetFormHandlers(settings, onChange)

  const { t } = useContext(TranslationContext)

  return (
    <Form
      {...rest}
      asset={asset}
      title={t('MEDIA_FORM_TITLE_SETTINGS_IMAGE')}
      description={asset.name}
    >
      <Accordion caption={t('MEDIA_FORM_CAPTION_SIZE')}>
        <Size
          onChangeSize={onChangeSize}
          onChangeKeepAspectRatio={onChangeKeepAspectRatio}
          width={width}
          height={height}
          originalSize={originalSize}
          keepAspectRatio={keepAspectRatio}
        />
      </Accordion>

      <Accordion caption={t('MEDIA_FORM_CAPTION_POSITION')}>
        <Position
          showFit
          position={settings.position}
          offset={settings.offset}
          rotation={settings.rotation}
          onClickFitToFrame={onClickFitToFrame}
          onClickFillToFrame={onClickFillToFrame}
          onChangePosition={onChangePosition}
          fillFrame={settings.fillFrame}
          fitToFrame={settings.fitToFrame}
        />
      </Accordion>

      <Accordion caption={t('MEDIA_FORM_CAPTION_ROTATION')}>
        <Rotation
          rotation={rotation}
          position={settings.position}
          offset={settings.offset}
          flip={flip}
          onChangeRotation={onChangeRotation}
          onChangePosition={onChangePosition}
          onChangeFlip={onChangeFlip}
        />
      </Accordion>

      <Accordion caption={t('MEDIA_FORM_CAPTION_SETTINGS')}>
        <SliderSettings
          initialValue={opacity}
          label={t('MEDIA_FORM_SLIDER_LABEL_OPACITY')}
          onValueChange={onChangeOpacity}
          maxValue={100}
          mask="NUM%"
        />
      </Accordion>

      <Accordion caption={t('MEDIA_FORM_CAPTION_OUTLINE')}>
        <ColorStyle
          defaultValue={outlineWidth}
          icon={<LineWeightIcon className="settings-input__icon" />}
          color={outlineColor}
          onChangeColor={onChangeOutlineColor}
          onChangeValue={onChangeOutlineWidth}
        />
      </Accordion>

      <If condition={__CFG__.MEDIA_SETTINGS.IMAGE_SHADOW}>
        <Accordion caption={t('MEDIA_FORM_CAPTION_SHADOW')}>
          <ColorStyle
            defaultValue={shadowBlur}
            icon={<div className="settings-input__icon">Blur</div>}
            color={shadowColor}
            onChangeColor={onChangeShadowColor}
            onChangeValue={onChangeShadowBlur}
          />
        </Accordion>
      </If>
    </Form>
  )
}
