import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { CustomizationHotkeys } from 'views/CustomizationHotkeys/CustomizationHotkeys'
import { CustomizationView } from 'views/CustomizationView/CustomizationView'
import { ReactComponent as SectionPlaceholderIcon } from '~/assets/repairing-service.svg'
import './SectionPlaceholder.scss'

export default function SectionPlaceholder({ title }) {
  const { t } = useContext(TranslationContext)
  const customizationView = useSelector(state => state.mainView.showCustomizeView)

  return (
    <>
      <If condition={!customizationView}>
        <div className="section-placeholder">

          <div className="section-placeholder__title">{title}</div>
          <div className="section-placeholder__content">
            <div className="section-placeholder__inner">
              <p className="section-placeholder__text">{t('SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT')}</p>
              <SectionPlaceholderIcon />
            </div>
          </div>


        </div>
      </If>
      <If condition={customizationView === 'theme'}>
        <CustomizationView />
      </If>
      <If condition={customizationView === 'hotkeys'}>
        <CustomizationHotkeys />
      </If>
    </>
  )
}
