import Konva from 'konva'
import { normalizeTextSize } from '~/components/Preview/AssetsOverlay/lib'
import { formatRBGColor } from '~/Util'

export const createText = (asset, callback = () => {}, { boundingRect, scale }) => {
  const { settings, text: textString } = asset
  const {
    align, background: backgroundColor, color, font, fontSize, fontStyle,
    outlineColor, outlineWidth, shadowBlur, shadowColor,
    disableShadow, disableOutline, shadowOffset = { x: 2, y: 2 },
  } = settings

  const text = new Konva.Text({
    text: textString,
    wrap: 'none',
    ellipsis: true,
    fontFamily: font,
    fontSize: fontSize * 3,
    fontStyle: `${fontStyle.bold ? 'bold' : ''} ${fontStyle.italic ? 'italic' : ''}`,
    align,
    verticalAlign: 'middle',
    fill: formatRBGColor(color),
    stroke: formatRBGColor(outlineColor),
    strokeWidth: !disableOutline ? outlineWidth : 0,
    fillAfterStrokeEnabled: true,
  })
  text.scale(scale)

  const shadowStrokeWorkaround = text.clone({
    fill: null,
    stroke: formatRBGColor(outlineColor),
    strokeWidth: !disableOutline ? outlineWidth : 0,
    shadowColor: formatRBGColor({ ...shadowColor, a: 1 }),
    shadowOffset,
    shadowBlur: (fontSize / __CFG__.MAX_FONT_SIZE) * shadowBlur,
    shadowEnabled: !disableShadow,
  })

  const shadowStrokeWorkaroundRect = shadowStrokeWorkaround.getClientRect()
  const image = new Konva.Image({ image: shadowStrokeWorkaround.toCanvas(),
    opacity: shadowColor.a })
  image.x(shadowStrokeWorkaroundRect.x)
  image.y(shadowStrokeWorkaroundRect.y)

  const textClientRect = text.getClientRect()

  const skipStrokeWorkaroundRect = shadowStrokeWorkaround.getClientRect({ skipStroke: true })
  const measuredHeight = textClientRect.height - 2 * skipStrokeWorkaroundRect.y
  const measuredWidth = textClientRect.width - 2 * skipStrokeWorkaroundRect.x

  const normalizedSize = normalizeTextSize({
    isMeasured: true,
    textSize: { width: measuredWidth, height: measuredHeight },
    boundingRect,
  })

  const group = new Konva.Group({ ...normalizedSize,
    y: -shadowStrokeWorkaroundRect.y,
    x: -shadowStrokeWorkaroundRect.x })

  const background = new Konva.Rect({
    ...normalizedSize,
    y: shadowStrokeWorkaroundRect.y,
    x: shadowStrokeWorkaroundRect.x,
    fill: formatRBGColor(backgroundColor),
  })


  group.add(background)
  group.add(image)
  group.add(text)

  if (fontStyle.underline) {
    const underline = new Konva.Rect({
      width: normalizedSize.width,
      height: normalizedSize.height * 0.05,
      fill: formatRBGColor(color),
      position: { y: normalizedSize.height },
      strokeWidth: outlineWidth,
      stroke: formatRBGColor(outlineWidth ? outlineColor : color),
    })
    group.add(underline)
  }

  callback(group)
  return group
}
