import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { getHotKeyFromKeyDownEvent } from 'helpers/getHotKeyFromKeyDownEvent'
import { keyCodeToKey } from 'helpers/keyCodeToKey'
import React, { useCallback, useEffect, useState } from 'react'
import Input from '~/components/base/Input'
import './InputEdit.scss'

export const InputEdit = props => {
  const {
    value: initialValue,
    conflictValues,
    onChanged = () => {},
    onConflict = () => {},
  } = props
  const [ editing, setEditing ] = useState(false)
  const [ value, setValue ] = useState(initialValue)

  const handleKeyDown = useCallback(event => {
    if (!event.code.includes('Shift') && !event.code.includes('Control') && !event.code.includes('Alt')) {
      const combination = getHotKeyFromKeyDownEvent(event)
      setValue(combination)
      finishEditing()
      onChanged(combination)
      setTimeout(() => {
        if (conflictValues.includes(combination)) {
          onConflict(combination)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlur = () => {
    finishEditing()
  }

  const handleBlurClick = useCallback(event => {
    const { id } = event.target
    if (id !== 'editInput' && id !== 'closeBtn') {
      handleBlur()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function finishEditing() {
    document.removeEventListener('keydown', handleKeyDown)
    document.removeEventListener('click', handleBlurClick)
    setEditing(false)
  }

  const startEditing = () => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('click', handleBlurClick)
    setEditing(true)
  }

  useEffect(
    () => () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('click', handleBlurClick)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleEscKey = () => {
    finishEditing()
  }

  const handleFocus = e => {
    e.target.select()
  }

  const handleDelete = e => {
    e.stopPropagation()
    e.preventDefault()
    setValue('')
    onChanged('')
    finishEditing()
  }

  return editing ? (
    <div style={{ position: 'absolute', top: '16%' }}>
      <Input
        id="editInput"
        autoFocus
        onEscKeyDown={handleEscKey}
        onEsc={handleEscKey}
        className="input-edit"
        value={keyCodeToKey(value)}
        onFocus={handleFocus}
        readonly
      />
      <div className="input-edit-close" onClick={handleDelete}>
        <CloseIcon id="closeBtn" />
      </div>
    </div>
  ) : (
    <div onClick={startEditing} style={{ cursor: 'pointer', height: '20px' }}>
      {keyCodeToKey(value)}
    </div>
  )
}
