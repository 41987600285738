/* eslint-disable camelcase */
import cx from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'

import SearchMenu from 'components/AssetPanels/Audio/SearchMenu'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import { SOURCE_FILE_TYPES } from 'enums'
import { useAction } from 'hooks'
import { getIsSourceFilesLoading, selectSourceFilesParams, selectTotalCount } from 'selectors'
import { TranslationContext } from 'contexts/TranslationContext'
import * as Actions from '~/actions'
import LoadButton from '~/components/AssetPanels/Media/LoadButton'
import HeadControlPanel from '~/components/HeadControlPanel'
import Scrollbars from '~/components/base/Scrollbars'
import List from '../BaseAssetsList'
import Item from './ListItem'
import Navbar from './Navbar'
import { useCategoryFetch } from './hooks/useCategoryFetch'

import styles from './list.module.scss'

export default function AudioAssetsList(props) {
  const { t } = useContext(TranslationContext)
  const { onScroll, type } = props
  const { currentAudioType = 'music',
    audioFilter = {},
    search = '' } = useSelector(state => selectSourceFilesParams(state,
    SOURCE_FILE_TYPES.AUDIO))

  const audioCategories = [{ id: 'music', name: t('AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE') },
    { id: 'sound', name: t('AUDIO_LIST_CATEGORIES_SOUND_EFFECTS') },
    { id: 'mood', name: t('AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD') }]
    .filter(item => __CFG__.AUDIO_CATEGORIES.includes(item.id))

  const itemsLoading = useSelector(state => getIsSourceFilesLoading(state, type))
  const totalCount = useSelector(state => selectTotalCount(state, type))

  const currentAudioFilter = audioFilter[currentAudioType] || {}

  const categories = useCategoryFetch({ type: currentAudioType })
  const setSourceFilesParams = useAction(Actions.sourceFiles.setSourceFilesParams,
    SOURCE_FILE_TYPES.AUDIO)

  function handleSearchMenuSelect(item) {
    const name = categories.find(category => category.id === item)?.name
    if (currentAudioType === 'mood') {
      setSourceFilesParams({ audioFilter: { ...audioFilter,
        [currentAudioType]: { mood_id: item === 'all' ? undefined : item,
          name } } })
    } else {
      setSourceFilesParams({ audioFilter: { ...audioFilter,
        [currentAudioType]: { category_id: item === 'all' ? undefined : item,
          name } } })
    }
  }

  function handleNavbarSelect(item) {
    setSourceFilesParams({ currentAudioType: item })
  }

  function getCategory(asset) {
    const category = currentAudioType === 'mood' ? categories.find(category => category.id === asset.moodId)
      : categories.find(category => category.id === asset.categoryId)
    return category
  }

  const handleSearchByTrack = value => {
    setSourceFilesParams({ search: value })
  }
  const handleSearchByTrackDebounced = useCallback(debounce(handleSearchByTrack, 300), [])

  // In terms of business logic mood = category
  const category = currentAudioType === 'mood'
    ? currentAudioFilter.mood_id || 'all'
    : currentAudioFilter.category_id || 'all'

  return (
    <Scrollbars
      renderViewClassName="media-scrollbar"
      // eslint-disable-next-line react/destructuring-assignment
      onScrollFrame={__CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL
        ? onScroll
        : () => {}}
    >
      <List
        {...props}
        fullHeightScrollbar
        className={styles.root}
        classes={{ list: styles.list }}
        header={(
          <>
            <HeadControlPanel
              type={SOURCE_FILE_TYPES.AUDIO}
              caption={t('AUDIO_CAPTION_AUDIO')}
            />
            <Navbar
              items={audioCategories}
              selected={currentAudioType}
              onSelect={handleNavbarSelect}
            />
            <div className={styles.container}>
              <SearchMenu
                items={categories}
                onSelect={handleSearchMenuSelect}
                category={category}
                search=""
                placeholder={t('AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE')}
              />
              <SearchInput
                value={search}
                onChange={handleSearchByTrackDebounced}
                onSearch={handleSearchByTrack}
                className={cx('search_input', styles.musicSearch)}
                placeholder={currentAudioFilter.name ? `${t('AUDIO_INPUT_PLACEHOLDER_SEARCH_IN')} ${currentAudioFilter.name}`
                  : t(currentAudioType === 'sound' ? 'AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS' : 'AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC')}
              />
            </div>
            {search !== '' && !itemsLoading && !!totalCount && (
            <div className={styles.counter}>
              {t('AUDIO_TEXT_FOUND_ITEMS')}
              :
              {' '}
              {totalCount}
            </div>
            )}
          </>
        )}
        ItemComponent={
          props => (
            <Item
              getCategory={getCategory}
              onGenreClick={handleSearchMenuSelect}
              {...props}
            />
          )
        }
        placeholder={(
          <div className={styles.placeholder}>
            <div className={styles.header}>{t('AUDIO_TEXT_NO_RESULTS_FOUND')}</div>
            <div className={styles.desc}>
              {t('AUDIO_TEXT_CANNOT_FIND_RESULTS')}
            </div>
          </div>
        )}
        after={__CFG__.SOURCE_FILES_MANAGEMENT.LOAD_MEDIA_ONSCROLL
          ? null
          : <LoadButton type={type} />}
      />
    </Scrollbars>
  )
}
