import { useEffect, useRef } from 'react'

type UseAudioContextProps = {
  gain: number
}

function useAudioContext({ gain }: UseAudioContextProps) {
  const gainNode = useRef<GainNode | null>(null)
  const audioSourceNode = useRef<AudioNode | null>(null)

  useEffect(() => {
    if (!gainNode.current) return

    gainNode.current.gain.value = gain
  }, [ gain ])

  useEffect(() => () => {
    if (gainNode.current && audioSourceNode.current) {
      gainNode.current.disconnect()
      audioSourceNode.current.disconnect()
      gainNode.current = null
      audioSourceNode.current = null
    }
  }, [])

  const setGainNode = (node: GainNode, gain: number) => {
    gainNode.current = node
    gainNode.current.gain.value = gain
  }

  const setAudioSourceNode = (node: AudioNode) => {
    audioSourceNode.current = node
  }

  return [ setGainNode, setAudioSourceNode ]
}

export default useAudioContext
