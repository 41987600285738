import { isEmpty } from 'lodash'
import { getObjectFields } from '~/Util'
import * as ActionTypes from '~/actions/ActionTypes'
import { updateAssetInPreview } from '~/actions/layer'
import { loadProjectAsset, setScrollX } from '~/actions/timeline'
import { getAssetFromData, rebuildAssets } from '~/actions/projectData/mappings/getAssetMapping'


export const addAssetToTimeline = (originalAsset, layerId, pregeneratedId,
  initialStartTime) => async dispatch => {
  const asset = getAssetFromData(getObjectFields(originalAsset))
  const {
    startTime,
    id,
  } = asset
  await dispatch(loadProjectAsset(asset,
    { layerId: layerId || asset.layerId,
      startTime: initialStartTime || startTime,
      pregeneratedId: pregeneratedId || id }))
  if (asset.settings) {
    const { settings } = asset
    await dispatch(updateAssetInPreview(id, { settings }))
  }
}

export const addCopiedAssetsToTimeLine = (originalAssets, copiedAssetsTransitions,
  { sliderTime, newLayersIds,
    prevSelectedAssetIds, insertionLayerIndex }) => async (dispatch, getState) => {
  const { scale } = getState().timeline
  const preparedAssets = rebuildAssets(originalAssets)
  const newSliderTime = getMaxEndTime(originalAssets)
  if (!isEmpty(preparedAssets)) {
    dispatch({
      type: ActionTypes.COPIED_ASSET_ADDED_TO_TIMELINE,
      payload: {
        assets: preparedAssets,
        transitions: copiedAssetsTransitions,
        prevSliderTime: sliderTime,
        newSliderTime,
        newLayersIds,
        prevSelectedAssetIds,
        insertionLayerIndex,
      },
    })
    if (newSliderTime) {
      dispatch({
        type: ActionTypes.TIMELINE_SET_SLIDER_TIME,
        payload: {
          time: newSliderTime,
        },
      })
      const scrollOffset = window.innerWidth / 2
      dispatch(setScrollX(newSliderTime, scale, scrollOffset))
    }
  }
}


function getMaxEndTime(originalAssets) {
  let maxEndTime = (originalAssets?.[0]?.startTime ?? 0) + (originalAssets?.[0]?.duration ?? 0)
  originalAssets.forEach(asset => {
    const newEndTime = asset.startTime + asset.duration
    if (newEndTime > maxEndTime) {
      maxEndTime = newEndTime
    }
  })
  return maxEndTime
}
