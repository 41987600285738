/* eslint-disable no-nested-ternary */
import { addAssetToTimeline } from '~/actions/projectData/addAssetToTimeline'
import { rippleDelete } from '~/actions/timeline'
import { undoDeleteTransition } from '~/actions/undoRedo/transitionUndoRedoActions'
import { updateAssetInPreview } from '~/actions/layer'
import { PlaceholderAsset, TransitionAsset } from '~/models/Asset'
import { getObjectFields } from '~/Util'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class RippleDeleteAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.RippleDeleteAction, payload, ...params)
    this._patchs = payload.patchs
    this._placeholders = payload.placeholders?.every(p => p instanceof PlaceholderAsset)
      ? payload.placeholders
      : payload.placeholders?.map(p => new PlaceholderAsset(getObjectFields(p))) || []
    this._transitions = payload.transitions?.every(t => t instanceof TransitionAsset)
      ? payload.transitions
      : payload.transitions?.map(t => new TransitionAsset(getObjectFields(t))) || []
  }

  get transitions() {
    return this._transitions || []
  }

  set transitions(transitions) {
    this._transitions = transitions
  }

  get patchs() {
    return this._patchs
  }

  set patchs(patchs) {
    this._patchs = patchs
  }

  get placeholders() {
    return this._placeholders
  }

  set placeholders(placeholders) {
    this._placeholders = placeholders
  }

  * undo() {
    const { assets } = this.getState().layer
    const sortedAssets = assets
      .filter(a => !(a instanceof PlaceholderAsset))
      .sort((a, b) => b.startTime - a.startTime)

    for (const asset of sortedAssets) {
      if (this.patchs[asset.id]) {
        yield updateAssetInPreview(asset.id, this.patchs[asset.id])
      }
    }

    for (const asset of this.assets) {
      yield addAssetToTimeline(asset, asset.layerId)
    }

    for (const transition of this.transitions) {
      yield undoDeleteTransition(transition)
    }
  }

  * redo() {
    yield rippleDelete(this.assets.concat(this.placeholders).map(getObjectFields))
  }

}
