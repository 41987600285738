// eslint-disable-next-line no-unused-vars
import MatDialog, { DialogProps } from '@material-ui/core/Dialog'
// eslint-disable-next-line no-unused-vars
import MatDialogContent, { DialogContentProps } from '@material-ui/core/DialogContent'
// eslint-disable-next-line no-unused-vars
import MatDialogTitle, { DialogTitleProps } from '@material-ui/core/DialogTitle'
import cx from 'classnames'
import React from 'react'
import { XIcon } from '~/components/base/XIcon/XIcon'
import './Dialog.scss'

/**
 *
 * @param {DialogProps & { children: any, open: boolean, className?: string, onClick?: () => void, onClose: () => void }}
 * @returns
 */
export const Dialog = (
  { children, onClose, open, className, onClick = () => {}, ...rest } = { onClose: () => {} }
) => (
  <MatDialog onClick={onClick} open={open} onClose={onClose} className={cx('base-dialog', className)} {...rest}>
    <XIcon className="base-dialog__close-icon" onClick={onClose} />
    {children}
  </MatDialog>
)

/**
 *
 * @param {DialogTitleProps & { children: any, className?: string }}
 * @returns
 */
export const DialogTitle = ({ children, className, ...rest }) => (
  <MatDialogTitle className={className} {...rest}>{children}</MatDialogTitle>
)

/**
 *
 * @param {DialogContentProps & { children: any, className?: string }}
 * @returns
 */
export const DialogContent = ({ children, className, ...rest }) => (
  <MatDialogContent className={className} {...rest}>{children}</MatDialogContent>
)
