import { Tooltip } from '@material-ui/core'
import * as classNames from 'classnames'
import { MAX_IMAGE_SIDE_SIZE } from 'constant'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { ReactComponent as SizeSettingsIcon } from '~/assets/settings/size_settings.svg'
import SettingsInput from '~/components/Settings/SettingsInput'
import './Size.scss'

export default function Size(
  { onChangeSize, onChangeKeepAspectRatio = () => {}, width, height,
    originalSize,
    keepAspectRatio }
) {
  const onChangeWidth = e => {
    const width = Number(e.target.value)
    const originalAspRatio = originalSize.width / originalSize.height
    const aspectedHeight = keepAspectRatio ? Math.round(width / originalAspRatio) : height
    onChangeSize({ width, height: aspectedHeight })
  }

  const onChangeHeight = e => {
    const height = Number(e.target.value)
    const originalAspRatio = originalSize.width / originalSize.height
    const aspectedWidth = keepAspectRatio ? Math.round(height * originalAspRatio) : width
    onChangeSize({ width: aspectedWidth, height })
  }

  const onClickKeepAspectRatio = () => {
    onChangeKeepAspectRatio(!keepAspectRatio, { width, height })
  }

  const { t } = useContext(TranslationContext)

  return (
    <div className="size-container">
      <SettingsInput
        defaultValue={width}
        onChange={onChangeWidth}
        icon={<div className="settings-input__icon">{t('SETTINGS_SIZE_ICON_TEXT_WIDTH')}</div>}
        min={0}
        max={MAX_IMAGE_SIDE_SIZE}
      />

      <Tooltip title={t('SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO')}>
        <SizeSettingsIcon
          className={classNames(
            'size-container__aspect-ratio-icon', { 'size-container__aspect-ratio-icon--active': keepAspectRatio }
          )}
          onClick={onClickKeepAspectRatio}
        />
      </Tooltip>

      <SettingsInput
        defaultValue={height}
        onChange={onChangeHeight}
        icon={<div className="settings-input__icon">{t('SETTINGS_SIZE_ICON_TEXT_HEIGHT')}</div>}
        min={0}
        max={MAX_IMAGE_SIDE_SIZE}
      />
    </div>
  )
}
