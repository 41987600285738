import { isTimelineItemsIntersected } from '~/Util/RangeTools'

/* eslint-disable no-param-reassign */
const getElemsIntersection = (elem1, elem2) => {
  const { x: x1, width: width1 } = elem1
  const { x: x2, width: width2 } = elem2
  const end1 = x1 + width1
  const end2 = x2 + width2

  if (isTimelineItemsIntersected(x1, end1, x2, end2)) {
    const intersection = x2 > x1
      ? x2 - (x1 + width1)
      : (x2 + width2) - x1
    return { intersection }
  }
  return { intersection: 0 }
}

const getElemsAllIntersectedObjects = (draggingElem, layerElements) => layerElements.map(
  (elem, i) => {
    const { intersection } = getElemsIntersection(draggingElem, elem)

    let intersectionOffsetX = 0

    if (intersection !== 0) {
      intersectionOffsetX = intersection * -1
    }

    return { intersectionOffsetX, intersection, index: i }
  }
).filter(({ intersection }) => intersection !== 0)

const getElemsSummaryIntersectionWith = (draggingElem, layerElements) => {
  const allIntersectedObjects = getElemsAllIntersectedObjects(
    draggingElem, layerElements
  )

  const { intersection = 0, intersectionOffsetX = 0,
    index: firstIntersectedItemIndex } = allIntersectedObjects[0] || {}

  // const xOffsetted = draggingElem.x - intersectionOffsetX

  // Get second time with offset applied in the "tolerancy area" (10px)
  // const allIntersectedObjectsWithOffset = getElemsAllIntersectedObjects(
  //   {
  //     ...draggingElem,
  //     x: xOffsetted,
  //   }, layerElements
  // )

  // If intersects with two or more, set full intersection (dragging elem width)
  // Or if intersects with left border as offsetted
  // if (allIntersectedObjects.concat(allIntersectedObjectsWithOffset).length > 1 || xOffsetted < 0) {
  //   intersection = draggingElem.width
  // }
  return {
    intersection,
    intersectionOffsetX,
    intersectedIndex: firstIntersectedItemIndex,
  }
}

function getSnappedPreview({
  clientX, offset, leftTransitionWidth,
  timelineDragElementsLayers, layerIndex, width, normalizedSourceX,
}) {
  const normalizedClientX = clientX - offset - leftTransitionWidth
  const allIntersectedObjects = getElemsAllIntersectedObjects(
    { x: normalizedSourceX, width }, timelineDragElementsLayers[layerIndex]
  )

  const reversed = timelineDragElementsLayers[layerIndex].slice().reverse()
  const reversedLeftAssetIndex = reversed.findIndex(item => item.x < normalizedClientX)
  const leftAsset = reversedLeftAssetIndex !== -1 ? reversed[reversedLeftAssetIndex] : null
  const leftAssetIndex = reversedLeftAssetIndex !== -1
    ? reversed.length - reversedLeftAssetIndex - 1 : -1

  const rightAssetIndex = timelineDragElementsLayers[layerIndex]
    .findIndex(item => item.x > normalizedClientX)
  const rightAsset = rightAssetIndex !== -1
    ? timelineDragElementsLayers[layerIndex][rightAssetIndex] : null

  let previewSnappedSourceX = offset

  const [ leftIntersection, rightIntersection ] = [ allIntersectedObjects
    .find(item => item.index === leftAssetIndex)?.intersection,
    allIntersectedObjects.find(item => item.index === rightAssetIndex)?.intersection ]

  if (leftIntersection && rightIntersection) {
    previewSnappedSourceX += leftAsset.x + leftAsset.width
  } else if (leftIntersection) {
    previewSnappedSourceX += leftAsset.x + leftAsset.width
  } else if (rightIntersection) {
    const distBetweenAssets = leftAsset ? rightAsset.x - (leftAsset.x + leftAsset.width) : width + 1
    previewSnappedSourceX += Math.max(rightAsset.x - Math.min(width, distBetweenAssets), 0)
  }
  let placeholderWidth = width

  if (rightAsset) {
    if (((offset + rightAsset.x) - previewSnappedSourceX) > width) {
      placeholderWidth = width
    } else {
      const apxWidth = (offset + rightAsset.x) - previewSnappedSourceX
      placeholderWidth = Math.abs(apxWidth - width) < 0.001 ? width : apxWidth
    }
  }

  const hidePreviewOnDragStart = Math.max(Math.abs(leftIntersection || 0),
    Math.abs(rightIntersection || 0)) < 0.1

  const snappedPreview = !hidePreviewOnDragStart
    ? { width: placeholderWidth, x: previewSnappedSourceX } : null

  const previewNormalizedSourceX = previewSnappedSourceX - offset
  return { snappedPreview, previewNormalizedSourceX }
}


// -offset for timeline startTime, +offset is for assets positioning
// i.e. normalized for startTime
// leftTransitionWidth is for transtition

export function snapToLayerItems(clientX, params) {
  const {
    width,
    offset,
    leftTransitionWidth,
    sourceX,
    layerIndex,
    timelineDragElementsLayers,
    // initialClientX,
    isExternalSource,
    setDraggingItemProps,
    overNewLayerIndex,
    isOverItem,
    item,
  } = params
  let elemX = isExternalSource ? sourceX : (sourceX - leftTransitionWidth)

  let normalizedSourceX = (isExternalSource ? sourceX : sourceX - leftTransitionWidth) - offset
  // Intersection with left layer border
  if (normalizedSourceX < 0) {
    elemX -= normalizedSourceX
    normalizedSourceX = 0
  }

  const { intersection, intersectionOffsetX, intersectedIndex } = getElemsSummaryIntersectionWith(
    { x: normalizedSourceX, width, ...item }, timelineDragElementsLayers[layerIndex]
  )

  setDraggingItemProps({ intersectedPxls: intersection })

  let snappedPreview = null

  if (intersection !== 0 && !isOverItem && overNewLayerIndex === null) {
    const { snappedPreview: snappedParams,
      previewNormalizedSourceX } = getSnappedPreview({ clientX,
      normalizedSourceX,
      intersection,
      intersectionOffsetX,
      ...params })

    snappedPreview = snappedParams?.width ? snappedParams : null
    normalizedSourceX = previewNormalizedSourceX
  }

  return { x: elemX,
    normalizedSourceX,
    intersection,
    snappedPreview,
    intersectedItem: intersectedIndex !== undefined
      ? timelineDragElementsLayers[layerIndex][intersectedIndex] : null }
}
