import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import AppWindowTitle from 'AppWindowTitle'
import { isLink } from 'helpers/isLink'
import { ActivationComponent } from '~/features/desktop-activation-feature/activation-component/activation-component'
import { useCssStyle, useExternalCustomScript } from '~/hooks'
import MainView from './MainView'
import WebSocket from './WebSocket'

function ExternalCustomScript({ url, stringified, onSuccess }) {
  const handleOnSuccess = () => {
    onSuccess()
  }

  useExternalCustomScript({ src: url,
    stringified,
    onSuccess: handleOnSuccess })
  return null
}

function ExternalCustomStyles({ url, stringified, onComplete }) {
  useCssStyle({ src: url, stringified, onComplete })
  return null
}

function MainViewContainer() {
  const [ customJSFetched, setCustomJSFetched ] = useState(false)
  const [ customCSSFetched, setCustomCSSFetched ] = useState(false)
  const { fetched: userFetched, customCSS, customJS } = useSelector(state => state.user)

  const fetched = (customJS ? customJSFetched : true)
    && (customCSS ? customCSSFetched : true)
    && userFetched
  return (
    <>
      {customJS && (
      <ExternalCustomScript
        stringified={!isLink(customJS) && customJS}
        url={isLink(customJS) && customJS}
        onSuccess={() => setCustomJSFetched(true)}
      />
      )}

      {customCSS && (
        <ExternalCustomStyles
          stringified={!isLink(customCSS) && customCSS}
          url={isLink(customCSS) && customCSS}
          onComplete={() => setCustomCSSFetched(true)}
        />
      )}

      <WebSocket />
      {fetched && (
      <>
        {__APP_PROFILE__ !== 'public' && <AppWindowTitle />}
        <MainView />
        {__APP_PROFILE__ === 'desktop' && <ActivationComponent />}
      </>
      )}
    </>
  )
}

export default MainViewContainer
