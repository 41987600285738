/* eslint-disable no-alert */
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import ButtonBase from 'components/base/Button'
import { FormField } from 'components/base/FormField/FormField'
import Radio from 'components/base/Radio'
import { TranslationContext } from 'contexts/TranslationContext'
import { hexToRgb } from 'helpers/hexToRgb'
import { isLink } from 'helpers/isLink'
import { useModalDialog } from 'hooks'
import { isEqual } from 'lodash'
import React, { useEffect } from 'react'
import { ColorExampleCard } from './ColorExampleCard/ColorExampleCard'
import './CustomizationCss.scss'
import { TextAreaModal } from './TextAreaModal/TextAreaModal'
import { AudioCustomExample } from './examples/AudioCustomExample'
import { PrimaryCustomExample } from './examples/PrimaryCustomExample'
import { SecondaryCustomExample } from './examples/SecondaryCustomExample'
import { VoiceoverCustomExample } from './examples/VoiceoverCustomExample'
import { colorsIntoCss } from './helpers/colorsIntoCss'
import { cssToColors } from './helpers/cssToColors'
import { getInitialCss } from './helpers/getInitialCss'

const initialColors = {
  primary: '#586ee9', //
  secondary: '#da8338',
  audio: '#2E8915',
  voiceover: '#94A7CD',
}

const getBg = theme => theme === 'light' ? '#f2f2f2' : '#303030'

export const CustomizationCss = ({ onChange, customCss }) => {
  const { t } = React.useContext(TranslationContext)
  const CSS_OPTIONS = {
    dark: t('CUSTOMIZATION_CSS_OPTION_DARK_THEME'),
    light: t('CUSTOMIZATION_CSS_OPTION_LIGHT_THEME'),
    custom: t('CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME'),
  }

  const [ css, setCss ] = React.useState(() => customCss || getInitialCss({
    background: getBg('dark'),
    ...initialColors,
  }))

  const getTheme = css => {
    if (!css) {
      return 'dark'
    }
    if (isLink(css)) {
      return 'custom'
    }
    const colors = cssToColors(css)
    if (isEqual({
      ...initialColors,
      background: '#303030',
    }, colors)) {
      return 'dark'
    }
    if (isEqual({
      ...initialColors,
      background: '#f2f2f2',
    }, colors)) {
      return 'light'
    }
    return 'custom'
  }

  const [ theme, setTheme ] = React.useState(
    () => (getTheme(customCss)) || 'dark'
  )

  const editModal = useModalDialog({
    onConfirm: newText => {
      onChange(newText)
    },
  })

  useEffect(() => {
    if (!customCss) {
      setTheme('dark')
      setCss(getInitialCss({
        background: getBg('dark'),
        ...initialColors,
      }))
    } else {
      setCss(customCss)
      setTheme(getTheme(customCss))
    }
    editModal.close()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ customCss ])


  const [ colors, setColors ] = React.useState(() => {
    if (!customCss || isLink(css)) {
      return {
        background: getBg(theme),
        ...initialColors,
      }
    }
    return cssToColors(css)
  })

  useEffect(() => {
    if (!css) {
      setTheme('dark')
      setColors({
        background: getBg('dark'),
        ...initialColors,
      })
    } else if (!isLink(css)) {
      setColors(cssToColors(css))
    }
    onChange(css)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ css ])

  useEffect(() => {
    if (theme !== 'custom') {
      setCss(() => {
        if (isLink(css)) {
          return getInitialCss({
            background: getBg(theme),
            ...initialColors,
          })
        }
        return colorsIntoCss(css, {
          background: getBg(theme),
          ...initialColors,
        })
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ theme ])

  const handleChangeTheme = e => {
    setTheme(e.target.value)
  }

  const colorChangeHandler = key => c => {
    setCss(colorsIntoCss(css, { ...colors, [key]: c.hex }))
  }

  const handleEditCss = () => {
    editModal.open()
  }

  return (
    <>
      <FormField
        className="customization_formfield"
        description={(
          <div className="customization_edit-css">
            <div className="customization_edit-css_text">
              {t('CUSTOMIZATION_TITLE_CHOOSE_THEME')}
            </div>
            <ButtonBase
              className="customization_edit-css_btn"
              border
              onClick={handleEditCss}
              title={t('CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP')}
              data-lang-id="CUSTOMIZATION_BTN_EDIT_CSS"
            >
              {t('CUSTOMIZATION_BTN_EDIT_CSS')}
            </ButtonBase>
          </div>
              )}
      >
        <RadioGroup value={theme} onChange={handleChangeTheme}>
          {Object.entries(CSS_OPTIONS).map(([ value, name ]) => (
            <FormControlLabel key={name} className="custom_css_radio" value={value} control={<Radio color="primary" />} label={name} />
          ))}
        </RadioGroup>
      </FormField>
      <If condition={theme === 'custom'}>
        <ColorExampleCard
          onChangeColor={colorChangeHandler('background')}
          color={hexToRgb(colors.background)}
          description={t('CUSTOMIZATION_DESC_SELECT_BG_COLOR')}
          title={t('CUSTOMIZATION_TITLE_BASIC_BG')}
          disabled={isLink(css)}
        />
        <ColorExampleCard
          onChangeColor={colorChangeHandler('primary')}
          color={hexToRgb(colors.primary)}
          description={t('CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS')}
          title={t('CUSTOMIZATION_TITLE_PRIMARY_BTNS')}
          disabled={isLink(css)}
        >
          <PrimaryCustomExample color={colors.primary} />
        </ColorExampleCard>
        <ColorExampleCard
          onChangeColor={colorChangeHandler('secondary')}
          color={hexToRgb(colors.secondary)}
          description={t('CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS')}
          title={t('CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR')}
          disabled={isLink(css)}
        >
          <SecondaryCustomExample color={colors.secondary} />
        </ColorExampleCard>
        <ColorExampleCard
          onChangeColor={colorChangeHandler('audio')}
          color={hexToRgb(colors.audio)}
          description={t('CUSTOMIZATION_DESC_AUDIO_FILES_STYLE')}
          title={t('CUSTOMIZATION_TITLE_AUDIO')}
          disabled={isLink(css)}
        >
          <AudioCustomExample className="customization_audio-example" color={colors.audio} />
        </ColorExampleCard>
        <ColorExampleCard
          onChangeColor={colorChangeHandler('voiceover')}
          color={hexToRgb(colors.voiceover)}
          description={t('CUSTOMIZATION_DESC_VOICE_OVER_STYLE')}
          title={t('CUSTOMIZATION_TITLE_VOICE_OVER')}
          disabled={isLink(css)}
        >
          <VoiceoverCustomExample className="customization_audio-example" color={colors.voiceover} />
        </ColorExampleCard>
      </If>
      <TextAreaModal title={t('CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME')} text={css} modalDialog={editModal} />
    </>
  )
}
