import { TMEDIA_RECORDING_TYPE } from 'enums'
import Asset from './Asset'
import TimelineItem from './TimelineItem'

type TimelineItemProps = InstanceType<typeof TimelineItem>
type AssetProps = InstanceType<typeof Asset>
type MediaRecordingAssetProps = Partial<AssetProps
  & TimelineItemProps
  & { isRecording: boolean, recordingType: TMEDIA_RECORDING_TYPE }>

class MediaRecordingAsset extends Asset {

  constructor(data: MediaRecordingAssetProps) {
    const {
      fileId,
      selected,
      isRecording = false,
      recordingType = 'audio',
      ...rest
    } = data
    super(rest)

    this._isRecording = isRecording
    this._recordingType = recordingType
  }

  // eslint-disable-next-line class-methods-use-this
  get selected() {
    return this._selected
  }

  set selected(value) {
    this._selected = value
  }

  // eslint-disable-next-line class-methods-use-this
  get draggable() {
    return true
  }

  get isRecording() {
    return this._isRecording
  }

  set isRecording(value: boolean) {
    this._isRecording = value
  }

  get recordingType() {
    return this._recordingType
  }

  private _isRecording = false
  private _recordingType: TMEDIA_RECORDING_TYPE

}

export default MediaRecordingAsset
