import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import * as PT from '~/PropTypes'
import { useLazyComposeConst } from '~/hooks'

import Accordion from '~/components/base/Expansion'
import Form from '../../BaseAssetSettingsForm'
import TransitionDurationField from '../../FormFields/TransitionDurationField'
import VolumeField from '../../FormFields/VolumeFieldAudio'

export default function AudioAssetSettingsForm(props) {
  const {
    /** @type {Assets.AudioAsset} */
    asset,
    onChange,
    ...rest
  } = props

  const {
    onChangeVolume: onChangeVolumeCommit,
    onChangeMuted,
    onChangeFadeInDuration,
    onChangeFadeOutDuration,
  } = useAudioAssetFormHandlers(asset, onChange)

  const { t } = useContext(TranslationContext)

  return (
    <Form
      {...rest}
      asset={asset}
      title={t('AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO')}
      description={asset.name}
    >
      <If condition={__CFG__.AUDIO_SETTINGS.VOLUME}>
        <Accordion caption={t('AUDIO_SETTINGS_FORM_CAPTION_SOUND')}>
          <VolumeField
            muted={asset.muted}
            volume={asset.volume * 100}
            onChangeVolumeCommit={onChangeVolumeCommit}
            onChangeMuted={onChangeMuted}
          />
        </Accordion>
      </If>

      <If condition={__CFG__.AUDIO_SETTINGS.TRANSITIONS}>
        <Accordion caption={t('AUDIO_SETTINGS_FORM_CAPTION_TRANSITIONS')}>
          <TransitionDurationField
            label={t('AUDIO_SETTINGS_FORM_LABEL_FADE_IN')}
            value={asset.fadeInDuration}
            onChange={onChangeFadeInDuration}
          />

          <TransitionDurationField
            label={t('AUDIO_SETTINGS_FORM_LABEL_FADE_OUT')}
            value={asset.fadeOutDuration}
            onChange={onChangeFadeOutDuration}
          />
        </Accordion>
      </If>
    </Form>
  )
}

AudioAssetSettingsForm.propTypes = {
  asset: PT.AudioAsset.isRequired,
  onChange: PropTypes.func.isRequired,
}

// ---

function useAudioAssetFormHandlers(asset, update) {
  const useHandler = useLazyComposeConst(update)
  return {
    // TODO: change after amplification done
    onChangeVolume: useHandler(volume => ({ volume: volume / 100 })),
    onChangeMuted: useHandler(muted => ({ muted: !muted })),
    onChangeFadeInDuration: useHandler(x => ({
      fadeInDuration: x,
    })),
    onChangeFadeOutDuration: useHandler(x => ({
      fadeOutDuration: x,
    })),
  }
}
