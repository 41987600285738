import ProjectAsset from '~/models/ProjectData/ProjectAsset'

/**
 * @memberOf ProjectData
 * @extends ProjectData.ProjectAsset
 *
 */

class ProjectTextAsset extends ProjectAsset {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, version) {
    super(jsonData)

    const { settings } = jsonData

    this._settings = { ...settings }
  }

  /**
   * @returns {number}
   */
  get settings() {
    return this._settings
  }

}

export default ProjectTextAsset
