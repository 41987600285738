import React from 'react'
import { ReactComponent as XIconSvg } from '~/assets/settings/navigation_close.svg'
import './XIcon.scss'

export const XIcon = props => {
  const { className } = props

  return (
    <div className={`${className} x-icon`}>
      <XIconSvg {...props} />
    </div>
  )
}
