import Button from 'components/base/Button'
import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '~/components/base/Dialog/Dialog'
import './ConfirmationDialog.scss'

/**
 *
 * @param {{
 * title?: string,
 * description: string,
 * confirmBtnText: string,
 * closeBtnText?: string,
 * onCancel: () => void,
 * onClick?: () => void,
 * onConfirm: () => void,
 * children?: ReactNode,
 * dataLangConfirmId?: string,
 * dataLangCloseId?: string,
 * buttonConfirmTitle?: string,
 * buttonCloseTitle?: string}} param0
 * @returns
 */
export const ConfirmationDialog = (
  { title = '',
    description,
    confirmBtnText,
    closeBtnText = 'Cancel',
    onCancel,
    onClick = () => {},
    onConfirm,
    dataLangConfirmId,
    buttonConfirmTitle,
    dataLangCloseId,
    buttonCloseTitle,
    children = null }
) => {
  const [ open, setOpen ] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
    onCancel()
  }

  const onConfirmClick = () => {
    onConfirm()
  }

  return (
    <Dialog onClick={onClick} className="confirm-dialog" open={open} onClose={handleClose}>
      <DialogTitle><div className="confirm-dialog__title">{title}</div></DialogTitle>
      <DialogContent>
        <div className="confirm-dialog__description">{description}</div>
        {children}
        <div className="confirm-dialog__buttons">
          {closeBtnText && (
          <Button
            border
            className="confirm-dialog__buttons__cancel"
            onClick={handleClose}
            title={buttonCloseTitle}
            data-lang-id={dataLangCloseId}
          >
            {closeBtnText}
          </Button>
          )}

          <If condition={confirmBtnText}>
            <Button
              className="confirm-dialog__buttons__confirm"
              primary
              onClick={onConfirmClick}
              title={buttonConfirmTitle}
              data-lang-id={dataLangConfirmId}
            >
              {confirmBtnText}
            </Button>
          </If>
        </div>
      </DialogContent>
    </Dialog>
  )
}
