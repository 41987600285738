import { TRANSITIONS } from '~/enums'
import { secondsToTimelineTime } from '~/Util'
import Asset from './Asset'

/**
 * @memberOf Assets
 * @extends {Asset}
 */
class TransitionAsset extends Asset {

  constructor(data = {}) {
    const {
      duration = secondsToTimelineTime(3),
      leftVideoAssetId = null,
      rightVideoAssetId = null,
      type,
      url = null,
      ...rest
    } = data

    super({
      duration,
      ...rest,
    })

    this._type = type
    this._leftVideoAssetId = leftVideoAssetId
    this._rightVideoAssetId = rightVideoAssetId
    this._url = url
  }

  // ---

  /**
   * @returns {string|null}
   */
  get url() {
    return this._url
  }

  // ---

  /**
   * @returns {string}
   */
  get type() {
    return this._type
  }

  set type(type) {
    this._type = type
  }

  // ---

  /*
   * @returns {number|null}
   */
  get leftVideoAssetId() {
    return this._leftVideoAssetId
  }

  set leftVideoAssetId(leftVideoAssetId) {
    this._leftVideoAssetId = leftVideoAssetId
  }

  // ---

  /**
   * @returns {number|null}
   */
  get rightVideoAssetId() {
    return this._rightVideoAssetId
  }

  set rightVideoAssetId(rightVideoAssetId) {
    this._rightVideoAssetId = rightVideoAssetId
  }

  // ---

  /**
   * @returns {number}
   */
  get duration() {
    return this._duration
  }

  /**
   * @param {number} time
   */
  set duration(time) {
    this._duration = time
  }

  // ---

  /**
   * @param {number} assetId
   * @param {string} side
   * @returns {boolean}
   */
  isAttachedTo(assetId, side = 'any') {
    switch (side) {
      case 'any': return this._rightVideoAssetId === assetId || this._leftVideoAssetId === assetId
      case 'left': return this._leftVideoAssetId === assetId
      case 'right': return this._rightVideoAssetId === assetId
      default: throw new Error('Attached side can be \'any\', \'left\' or \'right\'')
    }
  }

  // ---

  /**
   * @param {number} assetId
   * @param {string} side
   */
  attachTo(assetId, side) {
    switch (side) {
      case 'left':
        this._leftVideoAssetId = assetId
        break
      case 'right':
        this._rightVideoAssetId = assetId
        break
      default: throw new Error('Attach side for transition is incorrect. It can be \'left\' or \'right\'')
    }
  }

  // ---

  /**
   * @returns {boolean}
   */
  get isTwoSideTransition() {
    return this.type !== TRANSITIONS.FADEIN && this.type !== TRANSITIONS.FADEOUT
  }

  // ---

  /**
   * @returns {Asset[]}
  */
  relatedAssets(assets) {
    return assets.filter(asset => asset.id === this._leftVideoAssetId
      || asset.id === this._rightVideoAssetId)
  }

}

export default TransitionAsset
