import React from 'react'
import { useSelector } from 'react-redux'
import { ImageAsset, TextAsset, VideoAsset } from '~/models/Asset'
import * as Selectors from '~/selectors'
import { selectActiveProjectLoading } from '~/selectors/projectData'
import Preview from '../Preview'
import AggregatedPlayer from './AggregatedPlayer'
// ---

export default function TimelinePreview(props) {
  const isActiveProjectLoading = useSelector(selectActiveProjectLoading)
  const mediaTransitions = useSelector(Selectors.selectExistingMediaTransitionAssets)
  const playingMediaAssets = useSelector(Selectors.getPlayingMediaAssets)
  const playingOverlayAssets = useSelector(Selectors.getPlayingOverlayAssets)
  const backgroundAssets = useSelector(Selectors.selectBackgroundAssets)
  const composedMediaAssets = useSelector(Selectors.selectComposedMediaAssets)
  const allOverlayAssets = useSelector(Selectors.selectAllExistingOverlayAssets)
  const isSliderOnBufferingPos = useSelector(state => state.timeline.isSliderOnBufferingPos)

  const showPlaceholder = VideoAsset.filter(playingMediaAssets).length === 0
    && ImageAsset.filter(playingOverlayAssets).length === 0
    && TextAsset.filter(playingOverlayAssets).length === 0

  if (isActiveProjectLoading) {
    return null
  }

  return (
    <Preview
      {...props}
      showPlaceholder={showPlaceholder}
      allOverlayAssets={allOverlayAssets}
    >
      {({ playing, muted, volume, width, height }) => (
        <>
          <AggregatedPlayer
            showThumbnail={props.showThumbnail}
            playingMediaAssets={playingMediaAssets}
            backgroundAssets={backgroundAssets}
            composedMediaAssets={composedMediaAssets}
            isSliderOnBufferingPos={isSliderOnBufferingPos}
            mediaTransitions={mediaTransitions}

            playing={playing}
            volume={volume}
            muted={muted}

            width={width}
            height={height}
          />
        </>
      )}
    </Preview>
  )
}
