import { checkStickySlider } from './checkStickySlider'
import { snapToLayer } from './snapToLayer'
import { snapToLayerItems } from './snapToLayerItems'

let intersected = null
let prevIntersectedItem = null

export function snapToTimelineGrid(clientX, clientY, {
  width,
  topOffset,
  offset,
  leftTransitionWidth,
  isExternalSource,
  item,
  timelineScrollTop,
  timelineDragElementsLayers,
  initialClientOffset,
  sourceX,
  sourceY,
  prevLayerIndex,
  prevSnappedY,
  prevSnappedX,
  setDraggingItemProps,
  isDraggingItemOverLayer,
  isOverItem,
  overNewLayerIndex,
  layerIndex,
  detachedMode,
  sliderTimeX,
  dragAssetsWidth,
  setSliderSticky,
  timelineMouseDown,
}) {
  const initialClientX = initialClientOffset?.x || 0
  const initialClientY = initialClientOffset?.y || 0
  const getSnappedY = lIndex => snapToLayer({ topOffset, layerIndex: lIndex, timelineScrollTop })

  const getSnappedX = lIndex => snapToLayerItems(clientX, {
    width,
    sourceX,
    item,
    isExternalSource,
    layerIndex: lIndex,
    offset,
    leftTransitionWidth,
    initialClientX,
    initialClientY,
    timelineDragElementsLayers,
    setDraggingItemProps,
    isDraggingItemOverLayer,
    overNewLayerIndex,
    isOverItem,
  })
  const currentSnappedY = getSnappedY(layerIndex)
  let snappedY = currentSnappedY

  if (prevSnappedY === null) {
    setDraggingItemProps({ prevLayerIndex: layerIndex, prevSnappedY: snappedY })
  }

  let { x: snappedX, normalizedSourceX, intersection,
    snappedPreview, intersectedItem } = getSnappedX(layerIndex)

  if (prevSnappedX === null) {
    setDraggingItemProps({ prevSnappedX: snappedX })
  }

  if (!isOverItem) {
    setDraggingItemProps({ normalizedSourceX })

    if (snappedPreview) {
      if (snappedPreview.width > 0) {
        const deltaOnDrop = Math.max(width - snappedPreview.width, 0)
        setDraggingItemProps({ deltaOnDrop, detachedMode: false })
      }
    } else {
      setDraggingItemProps({ deltaOnDrop: 0 })
    }
  }

  const intersectionAbs = Math.abs(intersection)
  if (intersectionAbs !== 0 && prevLayerIndex !== null) {
    snappedX = prevSnappedX
    snappedY = prevSnappedY
    // if (prevLayerIndex === layerIndex
    //   && clientX >= Math.round(snappedX) && clientX <= Math.round(snappedX + width)) {
    //   snappedPreview = null
    // }
  }

  if (prevSnappedY !== snappedY) {
    setDraggingItemProps({ prevLayerIndex: layerIndex, prevSnappedY: snappedY })
  }

  if (snappedPreview) {
    setDraggingItemProps({ prevSnappedX: snappedPreview.x,
      prevSnappedY: currentSnappedY })
  }

  if (!intersection && !snappedPreview) {
    intersected = null
    prevIntersectedItem = null
    setDraggingItemProps({ prevSnappedX: snappedX })
  }

  if (detachedMode && overNewLayerIndex === null
    && !intersection) {
    setDraggingItemProps({ detachedMode: false })
  }

  // adjust sourceOffsetX in case dragging is too fast
  function adjustOnIntersect() {
    if (prevLayerIndex === layerIndex) {
      snappedX = offset + (intersected < 0
        ? prevIntersectedItem.x - width
        : prevIntersectedItem.x + prevIntersectedItem.width)

      setDraggingItemProps({ prevSnappedX: snappedX })
    }
  }

  if (intersected === null) {
    if (intersection !== 0) {
      prevIntersectedItem = intersectedItem
      intersected = intersection
      adjustOnIntersect()
    }
  }

  const detachedY = sourceY - topOffset
  const detachedX = sourceX - leftTransitionWidth


  /** sticking to slider */
  setSliderSticky(false)

  const { isSliderLeftSticky, isSliderRightSticky } = checkStickySlider({
    sourceX,
    dragAssetsWidth,
    sliderTimeX,
  })

  if (prevSnappedX && timelineMouseDown.current && !isOverItem) {
    if (isSliderRightSticky) {
      snappedX = sliderTimeX - dragAssetsWidth
      setDraggingItemProps({ normalizedSourceX: snappedX - offset })
      setSliderSticky(true)
    } else if (isSliderLeftSticky) {
      snappedX = sliderTimeX
      setDraggingItemProps({ normalizedSourceX: snappedX - offset })
      setSliderSticky(true)
    }
  }

  /** \sticking to slider */

  return { snappedX: detachedMode ? detachedX : snappedX,
    snappedY: detachedMode ? detachedY : snappedY,
    intersection,
    snappedPreview: snappedPreview
      ? { ...snappedPreview,
        y: detachedMode && snappedPreview.width >= width
          ? detachedY : currentSnappedY }
      : null,
    normalizedSourceX,
    intersectedItem,
    layerIndex }
}
