import React, { forwardRef } from 'react'
import cx from 'classnames'

import MaterialSelect from '@material-ui/core/Select'
import { optionHeight } from 'styles/variables.scss'
import Scrollbars from 'components/base/Scrollbars'
import { ReactComponent as ArrowIcon } from '~/assets/settings/expand_more.svg'
import './Select.scss'

const SelectMenu = forwardRef((props, ref) => {
  const { children, dropdownHeight } = props
  return (
    <Scrollbars
      ref={ref}
      className="select-scrollbar"
      style={{ height: dropdownHeight, maxHeight: 200 }}
    >
      {children}
    </Scrollbars>
  )
})

export default function Select(props) {
  const { children, dropdownClassName, className, ...rest } = props
  return (
    <MaterialSelect
      className={cx('select', className)}
      disableUnderline
      IconComponent={ArrowIcon}
      MenuProps={{
        MenuListProps: {
          component: SelectMenu,
          dropdownHeight: React.Children.count(children) * parseInt(optionHeight),
        },
        getContentAnchorEl: null,
        classes: {
          paper: dropdownClassName,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      {...rest}
    >
      {children}
    </MaterialSelect>
  )
}
