import React, { useEffect } from 'react'
import { isHLS } from 'helpers/isHLS'
import { isMediaServerUrl } from 'helpers/isMediaServerUrl'
import Hls from 'hls.js'
import { initHLSPlayer } from 'helpers/initHlsPlayer'
import { getPlayerProps } from 'helpers/getPlayerProps'
import { initMediaServerPlayer } from 'helpers/initMediaServerPlayer'

export const Player = React.forwardRef((props, ref) => {
  const { src, onDuration } = props
  const playerProps = getPlayerProps(src, props)
  useEffect(() => {
    let player
    if (Hls.isSupported() && isHLS(src)) {
      player = initHLSPlayer(ref, { src })
    } else if (isMediaServerUrl(src)) {
      player = initMediaServerPlayer(ref, { src, onDuration })
    }
    return () => player?.destroy()
  }, [ src, ref, onDuration ])
  return <video {...playerProps} ref={ref} />
})
