/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { TRANSITIONS } from '~/enums'
import { AssetOverlayTransition } from './AssetOverlayTransition'
import { Shape } from './Shape'
import { getVisibleLayerIds } from '~/selectors'

export const Overlay = props => {
  const {
    layerRef,
    transition,
    assetId,
    shape: originalShape,
    shapeWithOutline,
    isComposed,
    composedAsset,
    asset,
    composedCanvas,
    composedShape,
    boundingRect,
    scale,
  } = props
  const targetRef = useRef()

  const shape = shapeWithOutline || originalShape

  const transitionRef = useRef()
  const visibleLayerIds = useSelector(getVisibleLayerIds)

  const [ isTransitionInitialized, setIsTransitionInitialized ] = useState(false)
  const [ transitionCanvas, setTransitionCanvas ] = useState(null)

  const handleTransitionProgress = transitionCanvas => {
    if (transition) {
      setTransitionCanvas(transitionCanvas)
      if (!isTransitionInitialized) {
        setIsTransitionInitialized(true)
      }
    }
  }

  const handleTransitionUnmount = () => {
    if (transition) {
      setIsTransitionInitialized(false)
    }
  }

  const shapeRect = shape?.getClientRect() || {}

  const width = shapeRect.width || 0
  const height = shapeRect.height || 0
  const { leftVideoAssetId, rightVideoAssetId } = transition || {}

  const isTransitionAttachedToThisAsset = [ leftVideoAssetId, rightVideoAssetId ].includes(assetId)

  useEffect(() => {
    if (!transition) {
      setTransitionCanvas(null)
    }
  }, [ transition ])

  return (
    <>
      <Shape
        ref={targetRef}
        {...props}
        {...(transitionCanvas ? { canvas: transitionCanvas } : {})}
        // NOTE: Hide overlay if fadeIn or dissolving composed asset
        visible={visibleLayerIds?.includes(asset.layerId) && (!transition || !!transitionCanvas
          || transition?.type === TRANSITIONS.FADEOUT
          || (transition?.type === TRANSITIONS.DISSOLVE
            && !isComposed))}
        scale={scale}
        shapeWithOutline={shapeWithOutline}
      />
      <If condition={(shape || props.canvas) && isTransitionAttachedToThisAsset}>
        <AssetOverlayTransition
          id={assetId}
          asset={asset}
          shape={shape}
          canvas={props.canvas}
          isComposed={isComposed}
          composedAsset={composedAsset}
          composedCanvas={composedCanvas}
          composedShape={composedShape}
          ref={transitionRef}
          targetRef={targetRef}
          layerRef={layerRef}
          transition={transition}
          boundingRect={boundingRect}
          onTransitionProgress={handleTransitionProgress}
          onTransitonUnmount={handleTransitionUnmount}
          width={width}
          height={height}
          // eslint-disable-next-line react/destructuring-assignment
          videoRefs={props.videoRefs}
        />
      </If>
    </>
  )
}

Overlay.propTypes = {
  // onUpdateOverlay: PropTypes.func,
  // onTransformStart: PropTypes.func,
  // boundBoxFunc: PropTypes.func,
  // keepRatio: PropTypes.bool,
  // transformerHidden: PropTypes.bool,
  // onlyMove: PropTypes.bool,
  // groupRef: PropTypes.oneOfType([
  //   PropTypes.func,
  //   PropTypes.shape({ current: PropTypes.any }),
  // ]),
  // rotation: PropTypes.number,
  color: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
}

Overlay.defaultProps = {
  // onlyMove: false,
  // transformerHidden: false,
  // keepRatio: false,
  // onUpdateOverlay: () => {},
  // onTransformStart: () => {},
  // boundBoxFunc: undefined,
  // groupRef: null,
  // rotation: 0,
  color: { r: 255, g: 255, b: 255 },
}
