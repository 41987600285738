import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import Menu, { MenuProps } from '@material-ui/core/Menu'

import { ReactComponent as ArrowIcon } from 'assets/settings/expand_more.svg'

import Divider from 'components/base/Divider/Divider'
import type { Classes, ItemWithLocalization } from 'components/base/Combobox/types'
import styles from './combobox.module.scss'

type Props<E extends React.FunctionComponent | React.ElementType = React.ElementType> = {
  items: { label: string, value: string }[],
  selected: string,
  onClick?: () => void,
  classes?: Classes,
  component?: E,
  children: React.ReactNode,
  openOnIconClick?: boolean,
  divider?: boolean,
  anchorWidth?: boolean,
  MenuProps?: Partial<MenuProps>,
  selectedItem: ItemWithLocalization | undefined
}

const defaultElement = 'div'

function Combobox<E extends React.FunctionComponent | React.ElementType = typeof defaultElement>({
  selected,
  component,
  classes = {},
  openOnIconClick = false,
  onClick = () => {},
  children,
  divider,
  MenuProps,
  anchorWidth,
  items,
  selectedItem,
} : Props<E>) {
  const [ anchorEl, setAnchorEl ] = useState<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRefWidth = useRef<number | undefined>(undefined)

  useEffect(() => {
    dropdownRefWidth.current = dropdownRef.current?.clientWidth
  }, [])

  const Node = component || defaultElement

  function handleClose() {
    setAnchorEl(null)
  }

  function handleSelectClick(e: React.MouseEvent<HTMLDivElement>) {
    if (!openOnIconClick) { setAnchorEl(e.currentTarget) }
    onClick()
  }

  function handleIconClick(elem: HTMLDivElement | null) {
    setAnchorEl(elem)
  }

  return (
    <>
      <div
        ref={dropdownRef}
        className={cx(styles.combobox, classes.select, { [styles.opened]: anchorEl })}
        data-lang-id={selectedItem?.dataLangId}
        title={selectedItem?.title}
      >
        <Node onClick={handleSelectClick}>{selected}</Node>
        {items.length > 1
          ? (
            <>
              {divider && <Divider orientation="vertical" variant="middle" className={styles.divider} />}
              <ArrowIcon
                className={cx(styles.selectedIcon, classes.icon)}
                onClick={() => handleIconClick(dropdownRef.current)}
              />
            </>
          )
          : null}
      </div>

      <Menu
        keepMounted
        getContentAnchorEl={null}
        open={!!anchorEl}
        anchorEl={dropdownRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handleClose}
        onClick={handleClose}
        {...MenuProps}
        PaperProps={{ ...MenuProps?.PaperProps,
          style: anchorWidth ? { width: dropdownRefWidth.current } : undefined }}
      >
        {children}
      </Menu>
    </>
  )
}

export default Combobox
