import { TRANSITIONS } from 'enums'
import { generateClientId } from '~/models/Asset/Asset'

export const setNewTransitionProperties = (transitions, asset, newAssetID, newLayerID) => {
  transitions.forEach(transition => {
    switch (true) {
      case (transition.type === TRANSITIONS.DISSOLVE):
        if (transition.rightVideoAssetId === asset.id) {
          // eslint-disable-next-line no-param-reassign
          transition.startTime = asset.startTime
          // eslint-disable-next-line no-param-reassign
          transition.rightVideoAssetId = newAssetID
          if (newLayerID) {
            // eslint-disable-next-line no-param-reassign
            transition.layerId = newLayerID
          }
          // eslint-disable-next-line no-param-reassign
          transition.id = generateClientId()
        }
        if (transition.leftVideoAssetId === asset.id) {
          // eslint-disable-next-line no-param-reassign
          transition.leftVideoAssetId = newAssetID
        }
        break
      case (transition.type === TRANSITIONS.FADEIN)
        && (transition.rightVideoAssetId === asset.id):
        // eslint-disable-next-line no-param-reassign
        transition.startTime = asset.startTime
        // eslint-disable-next-line no-param-reassign
        transition.rightVideoAssetId = newAssetID
        if (newLayerID) {
          // eslint-disable-next-line no-param-reassign
          transition.layerId = newLayerID
        }
        // eslint-disable-next-line no-param-reassign
        transition.id = generateClientId()
        break
      case (transition.type === TRANSITIONS.FADEOUT)
        && (transition.leftVideoAssetId === asset.id):
        // eslint-disable-next-line no-param-reassign
        transition.startTime = asset.endTime - transition.duration
        // eslint-disable-next-line no-param-reassign
        transition.leftVideoAssetId = newAssetID
        if (newLayerID) {
          // eslint-disable-next-line no-param-reassign
          transition.layerId = newLayerID
        }
        // eslint-disable-next-line no-param-reassign
        transition.id = generateClientId()
        break
      default:
        break
    }
  })
}
