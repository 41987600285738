/* eslint-disable no-alert */
import ButtonBase from 'components/base/Button'
import Switch from 'components/base/Switch'
import { MAX_LOGO_SIZE_BYTES, MAX_LOGO_SIZE_PX } from 'constant'
import { blobToBase64 } from 'helpers/blobToBase64'
import React, { useEffect, useState } from 'react'
import { SolveigStyledLogo } from 'components/SolveigStyledLogo/SolveigStyledLogo'
import { TranslationContext } from 'contexts/TranslationContext'
import './CustomizationView.scss'

export const LogoUploader = ({ logoLimitString, onChange, onChangeHidden = () => {}, hidden, logo, name = 'Logo', btnText = 'Upload', defaultImg, dataLangId, title }) => {
  const [ logoBase64, setLogoBase64 ] = useState(logo)
  const [ logoFileName, setLogoFileName ] = useState()
  const fileInputRef = React.useRef()

  useEffect(() => {
    setLogoBase64(logo)
    if (!logo) {
      setLogoFileName('')
    }
  }, [ logo ])

  useEffect(() => {
    onChange(logoBase64)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ logoBase64 ])

  // const handleDeleteLogo = () => {
  //   setLogoBase64('none')
  // }

  const handleSelectLogo = async e => {
    const file = e.target.files[0]
    const ext = file.name.split('.').pop()
    if (file.size > MAX_LOGO_SIZE_BYTES || (ext !== 'svg' && ext !== 'png')) {
      alert(logoLimitString)
      fileInputRef.current.value = null
      return
    }
    const img = document.createElement('img')
    const objectURL = URL.createObjectURL(file)
    img.onload = async function handleLoad() {
      if ((img.width > MAX_LOGO_SIZE_PX || img.height > MAX_LOGO_SIZE_PX) && ext !== 'svg') {
        alert(logoLimitString)
      } else {
        const logo = await blobToBase64(file)
        setLogoBase64(logo)
        setLogoFileName(file.name)
      }

      fileInputRef.current.value = null
      URL.revokeObjectURL(objectURL)
    }

    img.src = objectURL
  }

  const { t } = React.useContext(TranslationContext)
  return (
    <>
      <div className="customization_logo">
        <If condition={logoBase64 !== 'none'}>
          <div className="customization_logo_example">
            <SolveigStyledLogo style={{ height: '24px' }} imgTag={!logoBase64 && defaultImg} url={logoBase64 || defaultImg || __CFG__.LOGO_PATH} alt="logo-example" />
            <div className="customization_logo_example_name">{logoFileName}</div>
          </div>
        </If>
        <If condition={logoBase64 === 'none'}>
          <div className="customization_no-logo">
            {t('CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO')}
          </div>
        </If>
      </div>
      <div className="customization_logo_buttons">
        <label htmlFor={`${name}-logo-upload`}>
          <ButtonBase
            className="customization_logo_upload"
            primary
            data-lang-id={dataLangId}
            title={title}
          >
            {btnText}
          </ButtonBase>
        </label>
        <input accept=".png,.svg" ref={fileInputRef} style={{ display: 'none' }} id={`${name}-logo-upload`} type="file" onChange={handleSelectLogo} />
        <div>
          <Switch
            id={`${name}-switch`}
            onChange={e => onChangeHidden(!e.target.checked)}
            checked={!hidden}
          />
          <label htmlFor={`${name}-switch`}>{`${name} ${t('CUSTOMIZATION_LABEL_DISPLAYED')}`}</label>
        </div>
        {/* <If condition={logoBase64 !== 'none'}>
          <ButtonBase
            className="customization_logo_delete"
            color="primary"
            text
            size="small"
            onClick={handleDeleteLogo}
            style={{ textTransform: 'unset' }}
          >
            {t('CUSTOMIZATION_BTN_DELETE_LOGO')}
          </ButtonBase>
        </If> */}
      </div>

    </>
  )
}
