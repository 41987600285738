import { createSelector } from 'reselect'

export const selectCurrentHistoryAction = state => {
  const { currentActionIndex, actions } = state.historyActions
  return currentActionIndex !== undefined && actions[currentActionIndex]
}

export const selectBlockAddHistoryAction = state => state.historyActions.blockAddHistoryAction

export const selectNextHistoryAction = state => {
  const { currentActionIndex, actions } = state.historyActions
  return currentActionIndex !== undefined
    && currentActionIndex !== actions.length - 1
      && actions[currentActionIndex + 1]
}

export const selectIsPointerAtLastAction = state => state.historyActions
  .currentActionIndex === state.historyActions.actions.length - 1

export const selectIsPointerAtFirstAction = state => state.historyActions.currentActionIndex === -1

export const selectIsLatestActionIsHistory = state => state.historyActions.isLatestActionIsHistory

export const selectIsIgnoreLatestActionInHistory = state => state
  .historyActions.ignoreLatestActionInHistory

export const selectHistoryActions = createSelector(state => state?.historyActions.actions,
  actions => actions)

export const isHistoryActionExists = (id, state) => state.historyActions.actions
  .some(action => action.id === id)

export const selectLatestAction = state => state?.historyActions.latestAction

export const selectActionHistoryError = state => state.historyActions.error

export const selectIsLockedHistory = state => state.historyActions.isLockedHistory
