import { useCallback } from 'react'
// eslint-disable-next-line no-unused-vars
import { useDropzone, DropzoneState, DropzoneOptions } from 'react-dropzone'
import * as Actions from 'actions'
import { useAction } from './utils'

/**
 * @param {DropzoneOptions} [dropzoneParams]
 * @param {(file: File) => Promise<unknown>} [uploadService]
 * @param {boolean} uploadOnDrop
 * @returns {DropzoneState}
 * @see https://react-dropzone.js.org/#usage
 */
export const useLocalFileImport = (dropzoneParams = {},
  uploadService, uploadOnDrop = true) => {
  let uploadFileService = useAction((__APP_PROFILE__ !== 'desktop') ? Actions.upload.uploadFile
    : Actions.importLocalFile.importLocalFile)

  if (uploadService) uploadFileService = uploadService

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (uploadOnDrop) {
      acceptedFiles.forEach(file => uploadFileService(file))
      fileRejections.forEach(({ file }) => uploadFileService(file))
    }
  }, [ uploadOnDrop, uploadFileService ])

  const {
    acceptedFiles,
    fileRejections,
    ...rest
  } = useDropzone({
    accept: __CFG__.SOURCE_FILES_MANAGEMENT.SUPPORTED_FILES,
    ...dropzoneParams,
    onDrop,
  })
  return { acceptedFiles, fileRejections, ...rest }
}
