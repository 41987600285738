import cx from 'classnames'
import React, { useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import * as Actions from 'actions'
import type { TMEDIA_RECORDING_TYPE } from 'enums'
import { useAction } from 'hooks'
import { selectMediaRecordingSettingsState } from 'selectors/recording'

import { MenuCombobox } from 'components/base/Combobox'
import { ItemWithLocalization } from 'components/base/Combobox/types'
import MenuItem from 'components/base/MenuItem'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './combobox.module.scss'


const mediaRecordingTypeAdapter = (type: TMEDIA_RECORDING_TYPE) => {
  switch (type) {
    case 'audio':
      return __CFG__.SOURCE_FILES_FILTERS.MEDIA_RECORDING.VOICEOVER
    case 'video':
      return __CFG__.SOURCE_FILES_FILTERS.MEDIA_RECORDING.WEBCAM
    default:
      return false
  }
}


const getIcon = (option: TMEDIA_RECORDING_TYPE) => {
  switch (option) {
    case 'audio':
      return styles.iconVoice
    case 'video':
      return styles.iconWebcam
    default:
      return ''
  }
}

function MediaRecordingCombobox() {
  const { t } = useContext(TranslationContext)

  const recordOptions = ([{ value: 'audio',
    label: t('TIMELINE_TOOLBAR_BTN_VOICE_OVER'),
    dataLangId: 'TIMELINE_TOOLBAR_BTN_VOICE_OVER',
    title: t('TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP') },
  { value: 'video',
    label: t('TIMELINE_TOOLBAR_BTN_WEBCAMERA'),
    dataLangId: 'TIMELINE_TOOLBAR_BTN_WEBCAMERA',
    title: t('TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP') }] as Array<ItemWithLocalization<TMEDIA_RECORDING_TYPE>>)
    .filter(item => mediaRecordingTypeAdapter(item.value))
  const [ mediaRecording, setMediaRecording ] = useState<TMEDIA_RECORDING_TYPE>(recordOptions[0]?.value)
  const onMediaRecordingClick = useAction(Actions.mainView.openMediaRecordingSettings)
  const openedMedia = useSelector(selectMediaRecordingSettingsState)

  const onSelectMediaRecording = (value: TMEDIA_RECORDING_TYPE) => {
    setMediaRecording(value)
    onMediaRecordingClick(value)
  }

  const onClickMediaRecording = () => {
    onMediaRecordingClick(mediaRecording)
  }

  const Label = useCallback(({ children, ...props }) => (
    <div {...props} className={cx(styles.selectLabel)}>
      <div className={cx(styles.icon, getIcon(mediaRecording))} />
      <div>{children}</div>
    </div>
  ), [ mediaRecording ])

  if (recordOptions.length === 0) {
    return null
  }

  return (
    <MenuCombobox
      items={recordOptions}
      value={mediaRecording}
      component={Label}
      onClick={onClickMediaRecording}
      classes={{
        select: cx(styles.select, { [styles.active]: openedMedia,
          [styles.only]: recordOptions.length === 1 }),
      }}
      MenuProps={{
        PaperProps: { square: true },
        classes: { paper: styles.menu, list: styles.list },
      }}
      openOnIconClick
      anchorWidth
    >
      {recordOptions.map(item => (
        <MenuItem
          key={item.value}
          onClick={() => onSelectMediaRecording(item.value)}
          className={cx(styles.menuSection,
            { [styles.active]: openedMedia === item.value })}
        >
          <div className={cx(styles.icon, getIcon(item.value))} />
          <div
            data-lang-id={item.dataLangId}
            title={item.title}
          >
            {item.label}
          </div>
        </MenuItem>
      ))}
    </MenuCombobox>
  )
}

export default MediaRecordingCombobox
