import { TransitionAsset } from '~/models/Asset'
import * as ActionTypes from '~/actions/ActionTypes'

/**
 * @param {Asset} asset
 */
export const getActionByAssetType = asset => {
  switch (true) {
    case asset instanceof TransitionAsset:
      return ActionTypes.TRANSITION_ADDED_TO_TIMELINE
    default:
      return ActionTypes.ASSET_ADDED_TO_TIMELINE
  }
}
