import Input from 'components/base/Input'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useEffect } from 'react'
import Button from '~/components/base/Button'
import { Dialog, DialogContent, DialogTitle } from '~/components/base/Dialog/Dialog'
import './InputModal.scss'

export const InputModal = ({ modalDialog, title, value, fieldName }) => {
  const { t } = React.useContext(TranslationContext)
  const [ changedValue, setChangedValue ] = React.useState(value)

  useEffect(() => {
    setChangedValue(value)
  }, [ value ])

  return (
    <If condition={modalDialog.active}>
      <Dialog
        className="textarea-modal"
        open
        modalDialog={modalDialog}
        onClose={modalDialog.cancel}
      >
        <DialogTitle className="textarea-modal__header">{title}</DialogTitle>
        <DialogContent className="textarea-modal__content">
          <div className="textarea-modal__field-name">{fieldName}</div>
          <Input value={changedValue} onChange={e => setChangedValue(e.target.value)} />

          <div className="textarea-modal__buttons">
            <Button
              className="textarea-modal__button"
              border
              autoFocus
              onClick={modalDialog.close}
            >
              {t('CANCEL')}
            </Button>
            <Button
              type="submit"
              className="textarea-modal__button"
              primary
              autoFocus
              onClick={() => modalDialog.confirm(changedValue)}
            >
              {t('SAVE')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </If>
  )
}
