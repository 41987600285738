import React from 'react'
import { useAction } from '~/hooks'
import * as Actions from '~/actions'

export default function UrlImport({ children }) {
  const showImportFromUrl = useAction(Actions.mainView.showImportFromUrl, true)

  return (
    <div onClick={showImportFromUrl}>
      {children}
    </div>
  )
}
