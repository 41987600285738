import React, { useState, useEffect, useRef, forwardRef } from 'react'
import classNames from 'classnames'
import Input from 'components/base/Input'
import './InputRename.scss'

export const InputRename = forwardRef((props, ref) => {
  const {
    activeName,
    onRename,
    maxVisibleLetters = 18,
    letterWidth = 16,
    className,
    selectOnFocus = true,
  } = props

  const baseRef = useRef(null)
  const inputRef = ref || baseRef
  const [ isRenaming, setIsRenaming ] = useState(null)
  const [ name, setName ] = useState(() => activeName)

  const maxVisibleWidth = maxVisibleLetters * letterWidth

  useEffect(() => {
    setName(activeName)
  }, [ activeName ])

  const blurInput = () => {
    // NOTE: this for the Material Input
    if (inputRef?.current?.children?.length) {
      inputRef.current.children[0].blur()
    }
  }

  const onSubmitRename = e => {
    e.preventDefault()
    blurInput()
  }

  const finishRenaming = () => {
    if (isRenaming) {
      const trimmedName = name.trim()
      if (!!trimmedName && activeName !== trimmedName) {
        onRename(trimmedName)
      } else {
        setName(activeName)
      }
      setIsRenaming(false)
    }
  }

  const onInputFocus = e => {
    if (selectOnFocus) {
      e.target.select()
    }
    setIsRenaming(true)
  }

  const onProjectNameChange = e => {
    setName(e.target.value)
  }

  const handleEscKeyDown = () => {
    setName(activeName)
    setTimeout(() => blurInput())
  }

  const isMaxVisibleLength = name.length > maxVisibleLetters

  return (
    <form
      className={classNames('input-rename input-rename--default', { 'input-rename--ellipsis': isMaxVisibleLength })}
      onSubmit={onSubmitRename}
    >
      <Input
        className={classNames(className)}
        style={{ width: `${isMaxVisibleLength ? maxVisibleWidth : letterWidth * name.length}px` }}
        ref={inputRef}
        value={name}
        onFocus={onInputFocus}
        onChange={onProjectNameChange}
        onBlur={finishRenaming}
        onEscKeyDown={handleEscKeyDown}
      />
    </form>
  )
})
