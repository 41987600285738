import React, { createContext, ReactNode, useReducer } from 'react'

type State = {
  state: {
    files: DropFiles
  },
  setFile: (x: DropFiles) => void,
  resetFile: () => void
}

const initialState: State = {
  state: {
    files: {
      accepted: [],
      rejected: [],
    },
  },
  setFile: () => {},
  resetFile: () => {},
}

export const UploadContext = createContext(initialState)

type Props = {
  children: ReactNode | ReactNode[]
}

type Action = {
  type: 'setFile',
  payload: DropFiles
} | { type: 'resetFile' }


function reducer(state: State['state'], action: Action): State['state'] {
  switch (action.type) {
    case 'setFile':
      return {
        ...state,
        files: action.payload,
      }
    case 'resetFile':
      return initialState.state
    default:
      return state
  }
}


function UploadProvider({ children }: Props) {
  const [ state, dispatch ] = useReducer(reducer, initialState.state)

  const value = {
    state,
    setFile: (files: DropFiles) => {
      dispatch({ type: 'setFile', payload: files })
    },
    resetFile: () => dispatch({ type: 'resetFile' }),
  }

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  )
}

export default UploadProvider
