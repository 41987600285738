import * as ActionTypes from './ActionTypes'

export const updateUserProfile = profile => ({
  type: ActionTypes.UPDATE_USER_PROFILE,
  payload: {
    ...profile,
  },
})

export const patchUserProfile = profile => ({
  type: ActionTypes.PATCH_USER_PROFILE,
  payload: {
    ...profile,
  },
})

export const updateUserName = name => ({
  type: ActionTypes.UPDATE_USER_NAME,
  payload: {
    name,
  },
})

export const setHotkey = (hotkey, combination) => ({
  type: ActionTypes.SET_HOTKEY,
  payload: { hotkey, combination },
})

export const addHotkeyProfile = () => async dispatch => dispatch({
  type: ActionTypes.ADD_HOTKEY_PROFILE,
  payload: {},
})

export const resetProfileToDefaults = () => ({
  type: ActionTypes.RESET_PROFILE_TO_DEFAULTS,
  payload: {},
})

export const deleteHotkeyProfile = profile => ({
  type: ActionTypes.DELETE_HOTKEY_PROFILE,
  payload: { profile },
})

export const searchHotkey = text => ({
  type: ActionTypes.SEARCH_HOTKEY,
  payload: { text },
})

export const renameHotkeyProfile = name => ({
  type: ActionTypes.RENAME_HOTKEY_PROFILE,
  payload: { name },
})

export const setActiveHotkeyProfile = profile => ({
  type: ActionTypes.SET_ACTIVE_HOTKEY_PROFILE,
  payload: { profile },
})
