// update layer indexes if a group has been moved up or down
export const updateLayerIndexes = (newDragAssetsByLayerIndex, layers) => {
  const layerIds = layers.map(layer => layer.id)
  return Object.values(newDragAssetsByLayerIndex).reduce((result, assets) => {
    const layerId = assets?.[0]?.newLayerId ?? assets?.[0]?.layerId
    const layerIndex = layerIds.indexOf(layerId)
    // eslint-disable-next-line no-param-reassign
    result[layerIndex] = assets
    return result
  }, {})
}
