import { hslToHex } from 'helpers/hslToHex'

export const cssToColors = text => {
  const varLines = text.match(/--.*/g)
  const keyValPairs = varLines.map(l => l
    .replace(' ', '')
    .replace('%', '')
    .replace('--', '{"')
    .replace(':', '":"')
    .replace(';', '"}'))
  const colors = keyValPairs.reduce((acc, v) => {
    let parsed = {}
    try {
      parsed = JSON.parse(v)
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    return { ...acc, ...parsed }
  }, {})

  const background = hslToHex(colors['bg-h'], colors['bg-s'], colors['bg-l'])
  const primary = hslToHex(colors['act-h'], colors['act-s'], colors['act-l'])

  return {
    background,
    primary,
    secondary: colors['slider-color'],
    audio: colors['audio-wave-color'],
    voiceover: colors['voice-wave-color'],
  }
}
