import { useEffect } from 'react'
import { useDrop } from 'react-dnd'

import { DRAGNDROP_TYPE } from 'enums'
import { useAction } from 'hooks/utils'
import * as Actions from 'actions'

/**
 * Set global dnd state for switching custom drag layers
 * Multiple drag layers are providing SRP and decoupling
 */
export const useDropTargetLocation = (options: {
  location: 'root' | 'timeline',
  shallow: boolean,
  canDrop: () => boolean
  }) => {
  const { canDrop = () => false } = options
  const setDndDropTarget = useAction(Actions.mainView.setDndDropTarget)
  const [ collectedProps, drop ] = useDrop({
    accept: Object.values(DRAGNDROP_TYPE),
    canDrop,
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: options.shallow }),
    }),
  })

  useEffect(() => {
    if (collectedProps.isOver) {
      setDndDropTarget(options.location)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ collectedProps.isOver ])

  return [ collectedProps, drop ]
}
