import classNames from 'classnames'
import { useKeyCodeListener, useResizeSensor } from 'hooks'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { DEFAULT_FPS } from '~/Util'
import Placeholder from './Placeholder'
import './Preview.scss'
import PreviewToolbar from './PreviewToolbar'
import usePreviewState from './usePreviewState'
import { GenerateThumbnail } from './Thumbnail'
// ---

function Preview(props) {
  const {
    showPlaceholder,
    playing,
    onPlayPause,
    children,
    fps,
    onPrevFrame,
    onNextFrame,
    onRewindTimeline,
    progress,
    duration,
    showThumbnail,
  } = props

  const refPlayerBox = React.useRef()

  const [
    {
      fullscreen, volume, muted,
      width, height,
    },
    {
      onChangeVolume,
      onToggleMute,
      onToggleFullscreen,
      onCloseFullscreen,
      onResize,
    },
  ] = usePreviewState()

  const playerRef = useRef()

  useEffect(() => {
    function fullscreenChange() {
      onToggleFullscreen()
    }

    document.addEventListener('fullscreenchange', fullscreenChange, false)
    document.addEventListener('mozfullscreenchange', fullscreenChange, false)
    document.addEventListener('MSFullscreenChange', fullscreenChange, false)
    document.addEventListener('webkitfullscreenchange', fullscreenChange, false)

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChange, false)
      document.removeEventListener('mozfullscreenchange', fullscreenChange, false)
      document.removeEventListener('MSFullscreenChange', fullscreenChange, false)
      document.removeEventListener('webkitfullscreenchange', fullscreenChange, false)
    }
  }, [ onToggleFullscreen ])

  useKeyCodeListener(React.useRef(document), 'Escape', onCloseFullscreen)
  useResizeSensor(refPlayerBox, onResize)

  const handleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      playerRef.current.requestFullscreen()
    }
  }

  return (
    <div ref={playerRef} className={classNames('preview-player', { 'preview-player--full': fullscreen })}>
      <div className="preview-player__video" ref={refPlayerBox}>
        <If condition={showPlaceholder}>
          <Placeholder />
        </If>

        {children({
          playing,
          volume,
          muted,
          width,
          height,
        })}
      </div>

      <If condition={showThumbnail}>
        <GenerateThumbnail width={width} height={height} />
      </If>

      <div className="preview-player__toolbar">
        <PreviewToolbar
          fps={fps}
          playing={playing}
          volume={volume}
          muted={muted}
          progress={progress}
          duration={duration}

          onRewindTimeline={onRewindTimeline}
          onPlayPause={onPlayPause}

          onPrevFrame={onPrevFrame}
          onNextFrame={onNextFrame}

          onToggleMute={onToggleMute}
          onChangeVolume={onChangeVolume}
          onFullscreen={handleFullscreen}
        />
      </div>
    </div>
  )
}

Preview.defaultProps = {
  showPlaceholder: false,
  fps: DEFAULT_FPS,
  progress: 0,
  duration: 0,
  onRewindTimeline: null,
  onPrevFrame: null,
  onNextFrame: null,
  showThumbnail: false,
}

Preview.propTypes = {
  children: PropTypes.func.isRequired,

  playing: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  duration: PropTypes.number,
  showThumbnail: PropTypes.bool,

  showPlaceholder: PropTypes.bool,
  onRewindTimeline: PropTypes.func,

  onPrevFrame: PropTypes.func,
  onNextFrame: PropTypes.func,

  onPlayPause: PropTypes.func.isRequired,
  fps: PropTypes.number,
}

// ---

export default React.memo(Preview)
