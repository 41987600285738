import React from 'react'

import HeadControlPanel from 'components/HeadControlPanel'


type Props = {
  caption: string,
  type: string,
}

function Header({ caption }: Props) {
  return (
    <>
      <HeadControlPanel caption={caption} />
    </>
  )
}

export default Header
