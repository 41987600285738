import { deleteLayer, insertLayers } from '~/actions/timeline'
import { HistoryActionType } from './HistoryActionType'
import { HistoryAction } from './HistoryAction'
import TimelineLayer from '~/models/Layer'

export class LayersCreationAction extends HistoryAction {

  constructor(actionPayload) {
    super(HistoryActionType.LayersCreationAction, actionPayload)
    const { index, layers } = actionPayload
    this.layers = []
    layers.forEach(layer => this.layers
      .push(layer instanceof TimelineLayer ? layer : new TimelineLayer(layer)))
    this.index = index
  }

  * undo() {
    const ids = this.layers.map(layer => layer.id)
    yield deleteLayer(ids)
  }

  * redo() {
    const { index, layers } = this
    yield insertLayers(index, layers)
  }

}
