import Menu from '@material-ui/core/Menu'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { MEDIA_FILES_ORDER, MEDIA_FILES_SORT, MEDIA_FILES_VIEW } from '~/enums'
import { MenuGroup, MenuOption } from '../../base/MenuSection/MenuSection'
import './MediaSort.scss'

export default function MediaMenu({
  anchorEl,
  handleClose,
  view,
  onChangeView,
  sort,
  onChangeSort,
  order,
  onChangeOrder,
  open,
}) {
  const { t } = useContext(TranslationContext)
  return (
    <Menu
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{
        paper: 'media-sort media-sort--menu',
      }}
      open={open}
      onClose={handleClose}
    >
      <If condition={__CFG__.SOURCE_FILES_MANAGEMENT.VIEW_MODE_SETTINGS}>
        <MenuGroup
          underline
          title={t('MEDIA_SORT_GROUP_VIEW_TOOLTIP')}
          value={view}
          onChange={onChangeView}
          name="view"
          classNameMenu="media-sort__list--view"
        >
          <MenuOption
            value={MEDIA_FILES_VIEW.List}
            label={t('MEDIA_SORT_OPTION_LIST')}
          />
          <MenuOption
            value={MEDIA_FILES_VIEW.Icons}
            label={t('MEDIA_SORT_OPTION_LARGE_ICONS')}
          />
        </MenuGroup>
      </If>
      <MenuGroup
        title={t('MEDIA_SORT_GROUP_ORDER_TOOLTIP')}
        value={order}
        onChange={onChangeOrder}
        name="group-by"
        classNameMenu="media-sort__list--sort"
      >
        <MenuOption
          value={MEDIA_FILES_ORDER.Ascending}
          label={t('MEDIA_SORT_OPTION_ASCENDING')}
        />
        <MenuOption
          value={MEDIA_FILES_ORDER.Descending}
          label={t('MEDIA_SORT_OPTION_DESCENDING')}
        />
      </MenuGroup>
      <MenuGroup
        title={t('MEDIA_SORT_GROUP_SORT_BY_TOOLTIP')}
        value={sort}
        onChange={onChangeSort}
        name="sort-by"
        classNameMenu="media-sort__list--sort"
      >
        <If condition={__CFG__.SOURCE_FILES_SORTS.NAME}>
          <MenuOption
            value={MEDIA_FILES_SORT.Name}
            label={t('MEDIA_SORT_OPTION_NAME')}
          />
        </If>
        <If condition={__CFG__.SOURCE_FILES_SORTS.SIZE}>
          <MenuOption
            value={MEDIA_FILES_SORT.Size}
            label={t('MEDIA_SORT_OPTION_SIZE')}
          />
        </If>
        <If condition={__CFG__.SOURCE_FILES_SORTS.DATE}>
          <MenuOption
            value={MEDIA_FILES_SORT.Date}
            label={t('MEDIA_SORT_OPTION_DATE')}
          />
        </If>
        <If condition={__CFG__.SOURCE_FILES_SORTS.DURATION}>
          <MenuOption
            value={MEDIA_FILES_SORT.Duration}
            label={t('MEDIA_SORT_OPTION_DURATION')}
          />
        </If>
      </MenuGroup>
    </Menu>
  )
}
