import { AudioAsset, MediaAsset, MediaRecordingAsset } from 'models/Asset'

export function isVoiceover(asset: MediaAsset) {
  if (asset instanceof AudioAsset) {
    return asset.type === 'voiceover' || asset.fileId?.includes('voiceover') || asset.id?.includes('voiceover')
  }
  if (asset instanceof MediaRecordingAsset) {
    return asset.recordingType === 'audio'
  }
  return false
}
