/* eslint-disable no-param-reassign */
import * as ActionTypes from 'actions/ActionTypes'
import { IMAGES_SOURCES, MEDIA_FILES_ORDER, MEDIA_FILES_SORT, MEDIA_FILES_VIEW, MEDIA_FILE_FILTERS, SOURCE_FILE_TYPES } from 'enums'
import produce from 'immer'
import { parseLoadedFile } from '~/Util/sourceFile'
import { LOCAL_STORAGE_SOURCE_FILES_PARAMS, MAX_NAVIGATION_HISTORY_COUNT, STORAGE_SOURCE_FILES_NAV_HISTORY, STORAGE_SOURCE_FILES_NAV_HISTORY_INDEX } from '~/constant'

import * as Assets from '~/models/Asset'
import { Folder } from '~/models/Folder'
// ---

export const INITIAL_CATEGORY = { id: 'all', name: 'All collections', level: 0 }

/**
 * @typedef {object} SourceFilesRequestParameters
 * @property {number} [start]
 * @property {number} [count]
 * @property {string} [filter]
 * @property {string} [sort]
 * @property {string} [search]
*/

/**
 * @typedef {object} SourceFilesGroup
 * @property {boolean} loading
 * @property {boolean} loaded
 * @property {SourceFilesRequestParameters} params
 * @property {boolean} allFilesFetched
 * @property {null|Error} error
 * @property {Asset[]} items
 * @property {null|Asset} editingAssetBackup
 */

/**
 * @returns {SourceFilesGroup}
 */
function createGroup(type) {
  const sourceFilesParamsSaved = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_SOURCE_FILES_PARAMS)
  )?.[type]

  const navHistorySaved = JSON.parse(
    localStorage.getItem(STORAGE_SOURCE_FILES_NAV_HISTORY)
  )?.[type]

  const navHistoryIndexSaved = JSON.parse(
    localStorage.getItem(STORAGE_SOURCE_FILES_NAV_HISTORY_INDEX)
  )?.[type]

  // ================ INITIAL VALUES

  let params = {
    order: type === SOURCE_FILE_TYPES.AUDIO
      ? MEDIA_FILES_ORDER.Ascending : MEDIA_FILES_ORDER.Descending,
    sort: type === SOURCE_FILE_TYPES.AUDIO
      ? MEDIA_FILES_SORT.Name : MEDIA_FILES_SORT.Date,
    filter: MEDIA_FILE_FILTERS.All,
  }

  if (type === SOURCE_FILE_TYPES.AUDIO) {
    params.audioFilter = {}
  }

  if (type === SOURCE_FILE_TYPES.IMAGES) {
    params.category = INITIAL_CATEGORY
    params.source = IMAGES_SOURCES.Native
    params.imageLibSources = null
  }

  // ================================

  params = { ...params, ...sourceFilesParamsSaved }

  return {
    loading: false,
    loaded: false,
    error: null,
    items: [],
    // Backend filters and sorts
    params,
    directoryPathHistory: navHistorySaved || [[]],
    directoryPathCurrentIndex: navHistoryIndexSaved || 0,
    // Frontend only params
    uiParams: {
      view: MEDIA_FILES_VIEW.Icons,
    },
    allFilesFetched: false,

    editingAssetBackup: null,
    // TODO: put here panel's filters state too
  }
}

// ---

/**
 * @typedef {Record<SourceFilesTypes,SourceFilesGroup>
 * & {previewVideoSize: { width: number, height: number}}} SourceFileState
 */

/**
 * @type {SourceFileState}
 */
const initialState = {
  [SOURCE_FILE_TYPES.MEDIA]: createGroup(SOURCE_FILE_TYPES.MEDIA),
  [SOURCE_FILE_TYPES.TEXT]: createGroup(SOURCE_FILE_TYPES.TEXT),
  [SOURCE_FILE_TYPES.AUDIO]: createGroup(SOURCE_FILE_TYPES.AUDIO),

  [SOURCE_FILE_TYPES.TRANSITIONS]: createGroup(SOURCE_FILE_TYPES.TRANSITIONS),
  [SOURCE_FILE_TYPES.FILTERS]: createGroup(SOURCE_FILE_TYPES.FILTERS),
  [SOURCE_FILE_TYPES.DESIGN]: createGroup(SOURCE_FILE_TYPES.DESIGN),
  [SOURCE_FILE_TYPES.IMAGES]: createGroup(SOURCE_FILE_TYPES.IMAGES),

  // NOTE: this is used to resize image for video size
  previewVideoSize: { width: 0, height: 0 },
}

// ---

function sourceFilesReducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.ACTIONS_MEDIA_DIRECTORY_FORWARD: {
      const { type } = action.payload
      const group = getGroup(state, type)
      group.directoryPathCurrentIndex = Math.min(
        group.directoryPathCurrentIndex + 1,
        group.directoryPathHistory.length - 1
      )
      handleMovedNav(group)
      break
    }
    case ActionTypes.ACTIONS_MEDIA_DIRECTORY_BACKWARD: {
      const { type } = action.payload
      const group = getGroup(state, type)
      group.directoryPathCurrentIndex = Math.max(
        group.directoryPathCurrentIndex - 1,
        0
      )
      handleMovedNav(group)
      break
    }
    case ActionTypes.ACTIONS_MEDIA_DIRECTORY_OPEN: {
      const { type, directory } = action.payload
      const group = getGroup(state, type)
      if (directory) {
        switchToDirectory(group, directory)
      } else {
        opneRootDirectory(group)
      }
      handleMovedNav(group)
      break
    }
    case ActionTypes.SOURCE_FILES_SET_UI_PARAMS: {
      const { type, uiParams = {} } = action.payload
      const group = getGroup(state, type)
      group.uiparams = {
        ...group.uiParams,
        ...uiParams,
      }
      break
    }
    case ActionTypes.SOURCE_FILES_SET_PARAMS:
    case ActionTypes.SOURCE_FILES_SET_PARAMS_NO_RELOAD: {
      const { type, params = {} } = action.payload
      const group = getGroup(state, type)
      const isDifferentFilter = params.filter !== group.params.filter
      group.params = {
        ...group.params,
        ...params,
      }
      if (isDifferentFilter) {
        resetCount(group)
      }
      break
    }

    case ActionTypes.SOURCE_FILES_REQUESTED: {
      const { type, params } = action.payload
      const group = getGroup(state, type)
      group.loading = true
      group.error = null
      group.params = params
      break
    }

    case ActionTypes.CHANGED_PREVIEW_VIDEO_SIZE: {
      const { width, height } = action.payload
      state.previewVideoSize = { width, height }
      break
    }

    case ActionTypes.SOURCE_FILES_LOADED:
      handleFilesLoaded(state, action)
      break

    case ActionTypes.MEDIA_FOLDER_RENAME: {
      const { type, folderId, name } = action.payload
      const group = getGroup(state, type)
      const folderIndex = group.items.findIndex(i => i.id === folderId)
      const folder = group.items[folderIndex]
      folder.name = name
      group.items.splice(folderIndex, 1, new Folder(folder))
      break
    }

    case ActionTypes.MEDIA_FOLDER_CREATE: {
      const { type, folder } = action.payload
      const group = getGroup(state, type)
      group.items.unshift(folder)
      break
    }

    case ActionTypes.ASSET_EDIT_STARTED: {
      const { asset } = action.payload
      openEditForm(state, asset)
      break
    }

    case ActionTypes.ASSET_EDIT_SUBMITTED:
    case ActionTypes.ASSET_EDIT_CANCELED: {
      const { type } = action.payload
      closeEditForm(state, type)
      break
    }

    case ActionTypes.SOURCE_FILE_CREATED: {
      createSourceFileItem(state, action.payload)
      break
    }

    case ActionTypes.SOURCE_FILE_STATUS_UPDATED: {
      updateStatus(state, action.payload)
      break
    }

    case ActionTypes.SOURCE_FILE_DELETED: {
      deleteFile(state, action.payload)
      break
    }

    case ActionTypes.SOURCE_FILE_UPDATED: {
      updateSourceFile(state, action.payload)
      break
    }

    case ActionTypes.SOURCE_FILES_MOVED: {
      updateMediaList(state, action.payload)
      break
    }
  }
}

// ---

function resetCount(group) {
  group.params.start = 0
  group.params.count = __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER
}

function handleMovedNav(group) {
  const currentPath = group.directoryPathHistory[group.directoryPathCurrentIndex]
  if (currentPath.length) {
    group.params.folder = currentPath[currentPath.length - 1].id
  } else {
    group.directoryPathHistory = [[]]
    group.directoryPathCurrentIndex = 0
    delete group.params.folder
  }
  resetCount(group)
}

function opneRootDirectory(group) {
  group.directoryPathHistory.push([])
  group.directoryPathCurrentIndex = group.directoryPathHistory.length - 1
}

function switchToDirectory(group, directory) {
  const currentPath = group.directoryPathHistory[group.directoryPathCurrentIndex] || []
  const switchingDirectoryIndex = currentPath.findIndex(d => d.id === directory.id)
  const newDirectoryPath = switchingDirectoryIndex !== -1
    ? currentPath.filter((_, i) => i <= switchingDirectoryIndex)
    : currentPath.concat(directory)

  if (group.directoryPathHistory.length >= MAX_NAVIGATION_HISTORY_COUNT) {
    group.directoryPathHistory.shift()
  }

  group.directoryPathHistory.splice(
    group.directoryPathCurrentIndex + 1,
    group.directoryPathHistory.length - (group.directoryPathCurrentIndex + 1),
    newDirectoryPath
  )

  group.directoryPathCurrentIndex = group.directoryPathHistory.length - 1
}

function handleFilesLoaded(state, action) {
  if (action.error === true) {
    const error = action.payload
    const group = getGroup(state, error.type)

    group.error = error
    group.loading = false
  } else {
    const { type, items, totalCount, start } = action.payload
    const group = getGroup(state, type)

    group.error = null
    group.loading = false
    group.loaded = true

    const newItems = items
      .map(item => parseLoadedFile(type, item))
      .filter(clip => clip !== null)

    group.items = !start ? newItems : group.items.concat(newItems)
    group.allFilesFetched = totalCount === undefined
    || Number(totalCount) === Number(group.items.length)
    group.totalCount = Number(totalCount || 0)
  }
}

function openEditForm(state, asset) {
  const group = getGroup(state, getFileTypeByAsset(asset))
  group.editingAssetBackup = asset.clone(undefined, { keepSourceId: true })
}

function closeEditForm(state, type) {
  const groups = type !== undefined ? [ getGroup(state, type) ]
    : Object.values(SOURCE_FILE_TYPES).map(t => getGroup(state, t))
  groups.forEach(group => { group.editingAssetBackup = null })
}

function createSourceFileItem(state, { item }) {
  const mediaAsset = parseLoadedFile(SOURCE_FILE_TYPES.MEDIA, item)
  if (mediaAsset !== null) {
    mediaAsset.progress = 0
    const group = getGroup(state, SOURCE_FILE_TYPES.MEDIA)
    group.items.splice(0, 0, mediaAsset)
  }
}

function updateStatus(state, { data }) {
  const { id, progress, error, currentFilePos, file, status, taskInfo } = data
  const group = getGroup(state, SOURCE_FILE_TYPES.MEDIA)
  const mediaItem = group.items.find(item => item.fileId === id)

  // if uploadItem wasn't found, it was removed from the media
  if (mediaItem === undefined) {
    return
  }

  mediaItem.taskInfo = taskInfo

  if (error !== undefined) {
    mediaItem.error = error
  } else if (progress !== undefined) {
    mediaItem.progress = Math.min(Math.round(progress), 100)
    mediaItem.status = status
  } else {
    mediaItem.currentFilePos = currentFilePos
    mediaItem.fileHandle = file
  }
}

function deleteFile(state, { id }) {
  const group = getGroup(state, SOURCE_FILE_TYPES.MEDIA)
  group.items = group.items.filter(item => item.id !== id)
}

function updateMediaList(state, { type, ids }) {
  const group = getGroup(state, type)
  group.items = group.items.filter(item => !ids.includes(item.id))
}

function updateSourceFile(state, { file }) {
  const group = getGroup(state, SOURCE_FILE_TYPES.MEDIA)
  const idx = group.items.findIndex(item => item.fileId === file.id)
  if (idx >= 0) {
    group.items[idx] = parseLoadedFile(SOURCE_FILE_TYPES.MEDIA, file)
  }
}
// ---

/**
 * @param {object} state
 * @param {string} type
 * @returns {SourceFilesGroup}
 */
function getGroup(state, type) {
  const group = state[type]
  if (group === undefined) {
    throw new Error(`Unknown files type: ${type}`)
  }
  return group
}

function getFileTypeByAsset(asset) {
  if (asset instanceof Assets.VideoAsset
    || asset instanceof Assets.ImageAsset
    || asset instanceof Assets.AudioAsset) {
    return SOURCE_FILE_TYPES.MEDIA
  }

  if (asset instanceof Assets.TextAsset) {
    return SOURCE_FILE_TYPES.TEXT
  }

  // http://18.184.210.86/issues/1975
  // if (asset instanceof Assets.AudioAsset) {
  //   return SOURCE_FILE_TYPES.AUDIO
  // }

  if (asset instanceof Assets.TransitionAsset) {
    return SOURCE_FILE_TYPES.TRANSITIONS
  }

  // TODO: filters/design

  throw new Error(`Unsupported asset type ${asset.constructor.name}`)
}

// ---

export default produce(sourceFilesReducer, initialState)
