import Asset from './Asset'

/**
 * @memberOf Assets
 * @extends {Asset}
 *
 * @see https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.oik5zejzo39a
 * @see https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#heading=h.8es5e4j011wb
 */
class PlaceholderAsset extends Asset {

  constructor(data) {
    const {
      // Placeholder can't have an associated file
      // eslint-disable-next-line no-unused-vars
      fileId,

      // see getter below
      // eslint-disable-next-line no-unused-vars
      selected,

      ...rest
    } = data

    super(rest)
  }

  // PlaceholderAsset represents a *selected* area between clips.
  // non-selected placeholder just can not exist.
  // eslint-disable-next-line class-methods-use-this
  get selected() {
    return true
  }

  // eslint-disable-next-line class-methods-use-this
  get draggable() {
    return false
  }

}

export default PlaceholderAsset
