import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRecordingStatus } from 'selectors/recording'

import Button from 'components/base/Button'

import { useMediaRecordLocal } from 'components/AssetPanels/Voiceover/hooks/useMediaRecordLocal'
import { TranslationContext } from 'contexts/TranslationContext'
import { useStatic } from 'hooks'
import styles from './testVolume.module.scss'

type Props = {
  stream: MediaStream | null,
  disable: boolean
}

type RecordingStatus = 'stop' | 'progress' | 'playing'

function TestVolume({ stream, disable }: Props) {
  const { t } = useContext(TranslationContext)

  const [ localStatus, setLocalStatus ] = useState<RecordingStatus>('stop')
  const localStatusRef = useStatic(localStatus)
  const id = useRef<number | null>(null)

  const recordingStatus = useSelector(selectRecordingStatus)

  const [ mediaRecorder, audioElement ] = useMediaRecordLocal(stream)


  const resetPlayer = useCallback(() => {
    audioElement.pause()
    audioElement.removeAttribute('src')
    audioElement.load()
    audioElement.onended = null
    audioElement.oncanplay = null
  }, [ audioElement ])

  useEffect(() => () => {
    if (localStatusRef.current === 'playing') {
      resetPlayer()
    }
    if (id.current) { window.clearTimeout(id.current) }
    setLocalStatus('stop')
  }, [ mediaRecorder, resetPlayer, localStatusRef ])

  useEffect(() => {
    if (recordingStatus !== 'stop') {
      audioElement.onended = null
      audioElement.oncanplay = null
      if (id.current) { window.clearTimeout(id.current) }
      if (mediaRecorder?.state === 'recording') {
        mediaRecorder.stop()
      }
      if (localStatus === 'playing') {
        resetPlayer()
      }
      setLocalStatus('stop')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ recordingStatus, mediaRecorder, localStatus, resetPlayer ])

  function start() {
    if (recordingStatus !== 'stop') return
    if (!audioElement || !mediaRecorder) return

    mediaRecorder.start()
    setLocalStatus('progress')

    audioElement.onended = () => {
      resetPlayer()
      setLocalStatus('stop')
    }

    audioElement.oncanplay = () => {
      audioElement.play()
    }

    id.current = window.setTimeout(() => {
      mediaRecorder.stop()
      setLocalStatus('playing')
    }, 10000)
  }

  function handleStopPlaying() {
    resetPlayer()
    setLocalStatus('stop')
  }

  function handleStopRecording() {
    if (id.current) window.clearTimeout(id.current)
    mediaRecorder?.stop()
    setLocalStatus('playing')
  }

  function getButton() {
    switch (localStatus) {
      case 'stop':
        return (
          <Button
            className={styles.test}
            onClick={start}
            disabled={disable}
            border
            data-lang-id="VOICE_OVER_BTN_TEST_MIC"
            title={t('VOICE_OVER_BTN_TEST_MIC_TOOLTIP')}
          >
            {t('VOICE_OVER_BTN_TEST_MIC')}
          </Button>
        )
      case 'progress':
        return (
          <Button
            className={styles.test}
            onClick={handleStopRecording}
            disabled={disable}
            border
            title={t('VOICE_OVER_BTN_RECORDING_TOOLTIP')}
            data-lang-id="VOICE_OVER_BTN_RECORDING"
          >
            {t('VOICE_OVER_BTN_RECORDING')}
          </Button>
        )
      case 'playing':
        return (
          <Button
            className={styles.test}
            onClick={handleStopPlaying}
            disabled={disable}
            border
            data-lang-id="VOICE_OVER_BTN_PLAYING"
            title={t('VOICE_OVER_BTN_PLAYING_TOOLTIP')}
          >
            {t('VOICE_OVER_BTN_PLAYING')}
          </Button>
        )
      default:
        return null
    }
  }

  return getButton()
}

export default TestVolume
