/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchMedia } from 'components/AssetPanels/Media/FoldersTree/fetchMedia'
import { useEffect, useState } from 'react'
import { FoldersChildren,
  FolderChildren } from 'components/AssetPanels/Media/FoldersTree/foldersTree.types'

function useFoldersChildren(folders: FolderChildren, onFetch: (state: boolean) => void) {
  const [ items, setItems ] = useState<FoldersChildren>({})

  useEffect(() => {
    if (!folders || folders.length === 0) return
    async function fn() {
      onFetch(true)
      const promises = folders.map(item => fetchMedia({
        filter: 'folder', folder: item.id,
      }))

      const data = await Promise.all(promises)
      for (let i = 0; i < data.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setItems((state: any) => ({ ...state, [folders[i].id!]: data[i] }))
      }
      onFetch(false)
    }
    fn()
  }, [ folders, onFetch ])

  return items
}

export default useFoldersChildren
