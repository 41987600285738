import { Tooltip } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'
import classNames from 'classnames'
import React, { useCallback, useContext, useState } from 'react'
import { SketchPicker } from 'react-color'
import { setBlockAddHistoryAction } from '~/actions/historyActions'
import { ReactComponent as TransparentIcon } from '~/assets/settings/transparent.svg'
import { useAction } from '~/hooks'
import './ColorPicker.scss'
import { TranslationContext } from '~/contexts/TranslationContext'

const COLORS = [ '#FF0000', '#FFA500', '#FFFF00', '#008000', '#87CEEB', '#0000FF', '#800080', '#FFFFFF' ]

export default function ColorPicker({
  Icon, color = {}, onClickIcon,
  onChangeColor, disabled, isIconActive,
  iconTooltip,
}) {
  const [ showColorPicker, setShowColorPicker ] = useState(null)
  const [ selectedColor, setSelectedColor ] = useState(null)
  const { t } = useContext(TranslationContext)

  const blockActionHistory = useAction(setBlockAddHistoryAction, true)
  const unblockActionHistory = useAction(setBlockAddHistoryAction, false)

  const onChangeColorInternal = color => {
    setSelectedColor(color)
    onChangeColor(color)
  }

  const onOpen = useCallback(e => {
    blockActionHistory()
    setShowColorPicker(e.currentTarget)
  }, [ blockActionHistory ])

  const onClosePopover = () => {
    setShowColorPicker(null)
    unblockActionHistory()
    if (selectedColor) {
      onChangeColor(selectedColor)
    }
  }

  return (
    <div
      className="color-picker"
      data-lang-id="COLOR_PICKER_BTN_COLOR"
      title={t('COLOR_PICKER_BTN_COLOR')}
    >
      <div className={classNames('color-picker__btn', { 'color-picker__btn--enabled': !disabled })} onClick={onOpen}>
        <div className="color-picker__background">
          <div
            style={{ backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` }}
          />
          <TransparentIcon />
        </div>
        {!onClickIcon && (
        <div style={{ marginLeft: '10px' }} className="color-picker__icon">
          {Icon && <Icon />}
        </div>
        )}
      </div>
      {onClickIcon && (
      <div
        style={{ marginLeft: '10px' }}
        className={classNames('color-picker__btn', { 'color-picker__btn--active': isIconActive })}
        onClick={onClickIcon}
      >
        <Tooltip title={iconTooltip}>
          <div className="color-picker__icon">
            {Icon && <Icon />}
          </div>
        </Tooltip>
      </div>
      )}
      <Popover
        open={!!showColorPicker && !disabled}
        anchorEl={showColorPicker}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ zIndex: '1' }} // for a pipette above the video
      >
        <Picker color={color} onChangeColor={onChangeColorInternal} />
      </Popover>
    </div>
  )
}

const Picker = ({ color, onChangeColor }) => {
  const handleChangeColor = color => {
    onChangeColor(color)
  }

  return (
    <SketchPicker
      color={color}
      onChangeComplete={handleChangeColor}
      presetColors={COLORS}
    />
  )
}
