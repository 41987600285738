import { MEDIA_TYPE, SOURCE_FILE_TYPES } from '~/enums'
import { SHOTSTACK_CLIP_TYPE, SHOTSTACK_FIT_TYPE } from './enums'
import { timelineTimeToSeconds } from '~/Util'

// https://shotstack.io/docs/api/#schemas

const supportedFileTypes = [
  MEDIA_TYPE.MOV,
  MEDIA_TYPE.MP4,
  MEDIA_TYPE.MXF,
  MEDIA_TYPE.M4A,
  MEDIA_TYPE.MP3,
  MEDIA_TYPE.WEBM,
  MEDIA_TYPE.PNG,
  MEDIA_TYPE.JPG ]

const fileType2ShotStackType = filetype => {
  switch (filetype) {
    case MEDIA_TYPE.MOV:
    case MEDIA_TYPE.MP4:
    case MEDIA_TYPE.MXF:
      return SHOTSTACK_CLIP_TYPE.VIDEO
    case MEDIA_TYPE.M4A:
    case MEDIA_TYPE.MP3:
    case MEDIA_TYPE.WEBM:
      return SHOTSTACK_CLIP_TYPE.AUDIO
    case MEDIA_TYPE.JPG:
    case MEDIA_TYPE.PNG:
      return SHOTSTACK_CLIP_TYPE.IMAGE
    default:
      return undefined
  }
}
const isImageOrTextAsset = projectAsset => {
  const { filetype, sourceFileType } = projectAsset
  if (filetype === MEDIA_TYPE.JPG || filetype === MEDIA_TYPE.PNG
      || sourceFileType === SOURCE_FILE_TYPES.TEXT || sourceFileType === SOURCE_FILE_TYPES.IMAGES) {
    return true
  }
  return false
}
const audioFileTypes = [ MEDIA_TYPE.MP3, MEDIA_TYPE.M4A, MEDIA_TYPE.WEBM ]

const isAudioAsset = projectAsset => {
  const { filetype } = projectAsset
  return audioFileTypes.includes(filetype)
}

const assetViewPositionParams = (projectAsset, preparedImages, viewSize) => {
  if (isAudioAsset(projectAsset)) {
    return {}
  }
  const viewParams = {
    fit: SHOTSTACK_FIT_TYPE.CONTAIN,
  }
  if (isImageOrTextAsset(projectAsset)) {
    viewParams.fit = SHOTSTACK_FIT_TYPE.NONE
    const { id } = projectAsset
    const image = preparedImages.find(img => img.assetId === id)
    const { videoPosition } = image
    const { width, height } = viewSize
    const { x, y } = videoPosition
    viewParams.position = 'topLeft'
    viewParams.offset = {
      x: x / width,
      y: -(y / height),
    }
  }
  return viewParams
}
const projectAsset2ShotStackAsset = (projectAsset, preparedImages) => {
  const { filetype, url, mediaStart, id } = projectAsset
  const shotStackAsset = {}
  if (isImageOrTextAsset(projectAsset)) {
    shotStackAsset.type = SHOTSTACK_CLIP_TYPE.IMAGE
    const image = preparedImages.find(img => img.assetId === id)
    shotStackAsset.src = image.url
  } else {
    shotStackAsset.type = fileType2ShotStackType(filetype)
    shotStackAsset.src = url
  }
  if (mediaStart) {
    shotStackAsset.trim = timelineTimeToSeconds(mediaStart)
  }
  return shotStackAsset
}

const isAssetSupported = projectAsset => {
  const { filetype, sourceFileType } = projectAsset
  return supportedFileTypes.includes(filetype) || sourceFileType === SOURCE_FILE_TYPES.TEXT
}

const clipTransition = (projectAsset, transitionProjectAssets) => {
  const { id } = projectAsset
  const tType = 'fadeSlow'
  const transition = {
    in: transitionProjectAssets
      .find(transition => transition.rightVideoAssetId === id) ? tType : undefined,
    out: transitionProjectAssets
      .find(transition => transition.leftVideoAssetId === id) ? tType : undefined,
  }
  return (transition.in || transition.out) ? transition : undefined
}

export const createShotStackTimeline = (
  projectTimeline, refVideo, preparedImages
) => {
  const output = {
    format: 'mp4',
    size: {
      width: refVideo.width,
      height: refVideo.height,
    },
  }
  const tracks = projectTimeline.layers.map(layer => {
    const { assets } = layer
    if (!assets.length) {
      return null
    }
    const transitionProjectAssets = assets
      .filter(asset => asset.sourceFileType === SOURCE_FILE_TYPES.TRANSITIONS)
    const clips = assets.map(projectAsset => {
      const { startTime, duration } = projectAsset
      if (!isAssetSupported(projectAsset)) {
        return null
      }
      const asset = projectAsset2ShotStackAsset(projectAsset, preparedImages)
      const viewParams = assetViewPositionParams(projectAsset, preparedImages, output.size)
      const transition = clipTransition(projectAsset, transitionProjectAssets)
      return {
        start: timelineTimeToSeconds(startTime),
        length: timelineTimeToSeconds(duration),
        asset,
        ...viewParams,
        transition,
      }
    }).filter(clip => clip !== null)
    return clips.length ? { clips } : null
  }).filter(track => track !== null)
  const timeline = {
    tracks,
  }
  return { timeline, output }
}
