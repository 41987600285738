/**
 * @memberOf ProjectData
 */
class ProjectLayer {

  // eslint-disable-next-line no-unused-vars
  constructor(jsonData, version, assets) {
    const { visible, muted, id } = jsonData

    this._muted = muted
    this._visible = visible
    this._assets = assets
    this._id = id
  }

  /**
   * @returns {string}
   */
  get id() {
    return this._id
  }

  /**
   * @returns {boolean}
   */
  get muted() {
    return this._muted
  }

  /**
   * @returns {ProjectData.ProjectAsset[]}
   */
  get assets() {
    return this._assets
  }

  /**
   * @returns {boolean}
   */
  get visible() {
    return this._visible
  }

}

export default ProjectLayer
