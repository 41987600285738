import React from 'react'
// eslint-disable-next-line no-unused-vars
import InputMaterial, { InputProps } from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import { useOnChange, useDebounce, useCompose } from '~/hooks'

import './Input.scss'

function BaseInput(props) {
  const {
    forwardRef, emitValue,
    onChange = () => {},
    onEscKeyDown = () => {},
    onEnterKeyDown = () => {},
    className, ...rest
  } = props

  const handleChange = useCompose(onChange, React.useCallback(
    e => emitValue ? e.target.value : e,
    [ emitValue ]
  ))

  const handleKeyDown = event => {
    event.stopPropagation() // MUI Input in Menu feature fix
    // Pressed ESC
    if (event.keyCode === 27) {
      onEscKeyDown()
    }
    // Pressed ENTER
    if (event.keyCode === 13) {
      onEnterKeyDown()
    }
  }

  return (
    <div className={`${className} input`}>
      <FormControl>
        <InputLabel>
          <InputMaterial
            onKeyDown={handleKeyDown}
            ref={forwardRef}
            disableUnderline
            onChange={handleChange}
            spellCheck="false"
            {...rest}
          />
        </InputLabel>
      </FormControl>
    </div>
  )
}

function StatefulInput(props) {
  const { value, onChange, ...rest } = props
  const { emitValue } = props
  const [ stateValue, setStateValue ] = React.useState(value)

  // ---

  const debouncedSetState = useDebounce(setStateValue, 100)
  const debouncedOnChange = useDebounce(onChange, 100)

  useOnChange(value, debouncedSetState)

  const handleChange = React.useCallback(
    arg => {
      // make sure upstream update won't override local state while we're editing
      debouncedSetState.cancel()

      if (emitValue) {
        // eslint-disable-next-line no-shadow
        const value = arg
        setStateValue(value)
        debouncedOnChange(value)
      } else {
        const event = arg
        setStateValue(event.target.value)
        event.persist()
        debouncedOnChange(event)
      }
    },
    [ debouncedSetState, debouncedOnChange, emitValue ]
  )

  return (
    <BaseInput
      {...rest}
      value={stateValue}
      onChange={handleChange}
      emitValue={emitValue}
    />
  )
}

const Input = React.forwardRef(
  /**
   *
   * @param {Omit<InputProps, 'onChange'> &  { onChange: (e) => void} & { stateful?: boolean, emitValue?: boolean }} props
   * @param {*} ref
   * @returns
   */
  (props, ref) => {
    const { stateful, ...rest } = props
    const Component = stateful ? StatefulInput : BaseInput
    return <Component {...rest} forwardRef={ref} />
  }
)

Input.defaultProps = {
  stateful: false,
  emitValue: false,
}

export default Input
