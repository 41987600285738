import Konva from 'konva'

export const addToBox = (shape, { rotation, flip }) => {
  const width = shape.width()
  const height = shape.height()
  const offsetX = width / 2
  const offsetY = height / 2
  const box = new Konva.Group({
    width,
    height,
    rotation,
    ...(flip?.horizontal ? { scaleX: -1, offsetX } : {}),
    ...(flip?.vertical ? { scaleY: -1, offsetY } : {}),
  })
  box.add(shape)
  return box
}
