import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import * as Actions from '~/actions'
import { InputClearable } from '~/components/base/InputClearable/InputClearable'
import InfoView from '~/components/views/InfoView/InfoView'
import { useAction } from '~/hooks'
import './ImportFromUrl.scss'

export default function ImportFromUrl() {
  const importFromUrl = useAction(Actions.importFromUrl.importMediafileFromUrl)
  const hideImportFromUrl = useAction(Actions.mainView.showImportFromUrl, false)

  const [ sourceUrl, setSourceUrl ] = React.useState('')

  const onInputChange = e => {
    setSourceUrl(e.target.value)
  }

  const onImportClick = () => {
    importFromUrl(sourceUrl)
    hideImportFromUrl()
  }

  const clearInput = () => {
    setSourceUrl('')
  }

  const { t } = useContext(TranslationContext)

  const isFormValid = !!sourceUrl

  return (
    <InfoView header={t('IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL')} onHide={hideImportFromUrl}>
      <form className="import-url__line-form">
        <InputClearable
          placeholder={t('IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE')}
          onChange={onInputChange}
          value={sourceUrl}
          onClearInput={clearInput}
        />

        <Button
          type="submit"
          disabled={!isFormValid}
          className="import-url__btn-import"
          onClick={onImportClick}
          primary
          autoFocus
          title={t('IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP')}
          data-lang-id="IMPORT_FROM_URL_BTN_IMPORT"
        >
          {t('IMPORT_FROM_URL_BTN_IMPORT')}
        </Button>
      </form>
    </InfoView>
  )
}
