function toRadians(angle) {
  return angle * (Math.PI / 180)
}

export const getRotatedSize = (size, rotation) => {
  const r = toRadians(rotation)
  const width = size.width * Math.abs(Math.cos(r))
    + size.height * Math.abs(Math.sin(r))
  const height = size.width * Math.abs(Math.sin(r))
    + size.height * Math.abs(Math.cos(r))

  return { width, height }
}
