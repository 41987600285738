import ButtonBase from 'components/base/Button'

import { TranslationContext } from 'contexts/TranslationContext'
import { useModalDialog } from 'hooks'
import React, { useEffect, useState } from 'react'
import './CustomizationView.scss'
import { TextAreaModal } from './TextAreaModal/TextAreaModal'

export const CustomizationJs = ({ onChange, text }) => {
  const { t } = React.useContext(TranslationContext)
  const [ modalText, setModalText ] = useState(text)

  const editModal = useModalDialog({
    onConfirm: newText => {
      setModalText(newText)
    },
  })

  useEffect(() => {
    setModalText(text)
    editModal.close()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ text ])

  const handleOpenEdit = () => {
    editModal.open()
  }

  useEffect(() => {
    onChange(modalText)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ modalText ])

  return (
    <>
      <ButtonBase
        className="customization_edit-btn"
        border
        onClick={handleOpenEdit}
        title={t('CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP')}
        data-lang-id="CUSTOMIZATION_BTN_EDIT_JS_SCRIPT"
      >
        {t('CUSTOMIZATION_BTN_EDIT_JS_SCRIPT')}
      </ButtonBase>
      <TextAreaModal title={t('CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT')} text={modalText} modalDialog={editModal} />
    </>
  )
}
