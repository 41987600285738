import { isNil } from 'lodash'
import * as Selectors from '~/selectors'
import { PlaceholderAsset } from '~/models/Asset'
import { getFirstLayerIndexOfDragAssets } from './getFirstLayerIndexOfDragAssets'


export const getInitialMoveMultipleAssetsData = ({ state, assetId,
  dragItem, layerId, layerIndex }) => {
  const layers = Selectors.getLayers(state)

  const currentDragItem = dragItem.selectedAssets.find(asset => asset.id === assetId)
  const currentDragItemLayerId = currentDragItem.layerId
  const currentDragItemLayerIndex = layers.reduce((result, layer, index) => {
    if (isNil(result) && layer.id === currentDragItemLayerId) {
      // eslint-disable-next-line no-param-reassign
      result = index
    }

    return result
  }, null)


  const dragAssetIds = dragItem.selectedAssets.map(asset => asset.id)

  const dragAssetsByLayerId = dragItem.selectedAssets.reduce((result, asset) => {
    const current = { ...result }
    if (!current[asset.layerId]) current[asset.layerId] = []
    // eslint-disable-next-line no-param-reassign
    current[asset.layerId].push(asset)
    return current
  }, {})

  const dragAssetsByLayerIndex = dragItem.selectedAssets.reduce((result, asset) => {
    const layerIndex = layers.reduce((res, layer, index) => {
      if (layer.id === asset.layerId && isNil(res)) {
        // eslint-disable-next-line no-param-reassign
        res = index
      }
      return res
    }, null)
    const current = { ...result }
    if (!current[layerIndex]) current[layerIndex] = []
    current[layerIndex].push(asset)
    return current
  }, {})

  const firstLayerIndex = getFirstLayerIndexOfDragAssets(dragItem)
  const topAssetsIds = dragItem.selectedAssets
    .filter(a => a.layerIndex === firstLayerIndex).map(asset => asset.id)

  const allDragAssets = Selectors.selectAssetById(state, dragAssetIds)
    .sort((a, b) => a.startTime - b.startTime)
  const staticAssets = Selectors.getAssets(state).filter(a => !(a instanceof PlaceholderAsset)
  && !dragAssetIds.includes(a.id)).sort((a, b) => a.startTime - b.startTime)
  const targetLayerId = layerId || layers[layerIndex]?.id

  const firstdragAssetStartTime = allDragAssets?.[0]?.startTime
  const lastDragAssetStartTime = allDragAssets?.[allDragAssets.length - 1]?.startTime
  const lastDragAssetDuration = allDragAssets?.[allDragAssets.length - 1]?.duration
  const dragAssetsDuration = (lastDragAssetStartTime
    + lastDragAssetDuration) - firstdragAssetStartTime

  return {
    currentDragItem,
    currentDragItemLayerId,
    currentDragItemLayerIndex,
    dragAssetIds,
    dragAssetsByLayerId,
    dragAssetsByLayerIndex,
    firstLayerIndex,
    topAssetsIds,
    allDragAssets,
    targetLayerId,
    layers,
    staticAssets,
    countDragAssetsLayers: Object.values(dragAssetsByLayerId ?? {}).length,
    firstDragAsset: allDragAssets?.[0],
    dragAssetsDuration,
    targetLayerIndex: layerIndex,
    layerIds: layers.map(l => l.id),
    firstdragAssetStartTime,
  }
}
