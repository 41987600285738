const INTERSECTION_TYPE = {
  NONE: 'NONE',
  TOUCH: 'TOUCH',
  INSIDE: 'INSIDE',
  EXCEEDS_LEFT: 'EXCEEDS_LEFT',
  EXCEEDS_RIGHT: 'EXCEEDS_RIGHT',
  EXCEEDS_BOTH: 'EXCEEDS_BOTH',
}

export const SymbolRangeProtocol = Symbol('SymbolRangeProtocol')

/**
 * @interface IRange
 * @property {function: number[]} [SymbolRangeProtocol]
 */

/**
 * @typedef {IRange|number[]} NumbersRange
 */

/**
 * @param {*} x
 * @returns {number[]}
 * @throws {Error} if value is not range
 */
function toRange(x) {
  let result
  if (Array.isArray(x)) {
    result = x
  } else if (SymbolRangeProtocol in x) {
    result = x[SymbolRangeProtocol]()
  } else {
    throw new Error(`Can't convert to numbers range a given value: ${x}`)
  }

  return result
}

/**
 * @param {NumbersRange} range
 * @returns {number[]}
 * @throws {Error}
 */
export function ensureValidRange(range) {
  // eslint-disable-next-line no-param-reassign
  range = toRange(range)

  if (range.length !== 2) {
    throw new Error(`Range length must be equal to 2\nGot this: [${range}]`)
  }

  const [ start, end ] = range

  if (!(typeof start === 'number' && typeof end === 'number')) {
    throw new Error(`Range values must be numbers\nGot this: [${range}]`)
  }

  if (!(start <= end)) {
    throw new Error(`Range start must be less or equal to range end\nGot this: [${range}]`)
  }

  return range
}

// ---

/**
 * @param {NumbersRange} range
 * @param {NumbersRange} baseRange
 * @returns {string}
 */
function getIntersectionType(range, baseRange) {
  /* eslint-disable no-param-reassign */
  range = ensureValidRange(range)
  baseRange = ensureValidRange(baseRange)
  /* eslint-enable no-param-reassign */

  // ---

  const [ start, end ] = range
  const [ baseStart, baseEnd ] = baseRange

  if (
    end < baseStart
    || start > baseEnd
  ) {
    // | range |                      | range |
    //               | baseRange |
    return INTERSECTION_TYPE.NONE
  }

  // | range |           | range |
  //         | baseRange |
  if (
    start === baseEnd
    || end === baseStart
  ) {
    return INTERSECTION_TYPE.TOUCH
  }

  //     | range |
  // |   baseRange   |
  if (
    start >= baseStart
    && end <= baseEnd
  ) {
    return INTERSECTION_TYPE.INSIDE
  }

  // | range |
  //     | baseRange |
  if (
    start < baseStart
    && end > baseStart
    && end <= baseEnd
  ) {
    return INTERSECTION_TYPE.EXCEEDS_LEFT
  }

  //          | range |
  // | baseRange |
  if (
    start >= baseStart
    && start < baseEnd
    && end > baseEnd
  ) {
    return INTERSECTION_TYPE.EXCEEDS_RIGHT
  }

  // |     range       |
  //    | baseRange |
  if (
    start < baseStart
    && end > baseEnd
  ) {
    return INTERSECTION_TYPE.EXCEEDS_BOTH
  }

  throw new Error(`Unknown intersection type between ranges ${range.toString()} and ${baseRange.toString()}`)
}

// ---

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isIntersecting(a, b) {
  const type = getIntersectionType(a, b)
  return (
    type !== INTERSECTION_TYPE.NONE
    // Touch is not considered intersection.
    // If two clips share a border, it means a flawless transition from one to another.
    && type !== INTERSECTION_TYPE.TOUCH
  )
}

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isInside(a, b) {
  return getIntersectionType(a, b) === INTERSECTION_TYPE.INSIDE
}

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isTouches(a, b) {
  return getIntersectionType(a, b) === INTERSECTION_TYPE.TOUCH
}

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isExceedsLeft(a, b) {
  return getIntersectionType(a, b) === INTERSECTION_TYPE.EXCEEDS_LEFT
}

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isExceedsRight(a, b) {
  return getIntersectionType(a, b) === INTERSECTION_TYPE.EXCEEDS_RIGHT
}

/**
 * @param {NumbersRange} a
 * @param {NumbersRange} b
 * @returns {boolean}
 */
export function isExceedsBoth(a, b) {
  return getIntersectionType(a, b) === INTERSECTION_TYPE.EXCEEDS_BOTH
}

/**
 * @param {NumbersRange} range
 * @param {number} value
 * @return {number}
 */
export function fitTo(range, value) {
  const [ left, right ] = ensureValidRange(range)
  return Math.max(left, Math.min(right, value))
}

export const isTimelineItemsIntersected = (startTime1, endTime1, startTime2, endTime2) => (
  (startTime2 >= startTime1 && startTime2 <= endTime1)
  || (startTime1 >= startTime2 && startTime1 <= endTime2)
)

export const isOverlapAssets = (startTime1, endTime1, startTime2, endTime2) => (
  (startTime2 > startTime1 && startTime2 < endTime1)
  || (startTime1 > startTime2 && startTime1 < endTime2)
)
