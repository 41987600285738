import { isEmpty } from 'lodash'
import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'

export const patchDnDItemAssets = (item, dndPatchAssets) => {
  let dragItem = item
  const isMultiple = item?.selectedDragIds?.length > 1
  if (isEmpty(dndPatchAssets)) {
    return item
  }

  if (isMultiple) {
    const { selectedAssets,
      selectedAssetsByLayerId, selectedAssetsByLayerIndex } = dragItem
    const patchedAssets = selectedAssets.map(asset => {
      const patchAsset = dndPatchAssets[asset.id]
      const cloneAsset = cloneInstanceClass(asset)
      if (patchAsset) {
        cloneAsset.duration = patchAsset.duration
        cloneAsset.startTime = patchAsset.startTime
      }
      return cloneAsset
    })

    const patchedAssetsByLayerId = Object.entries(selectedAssetsByLayerId)
      .reduce((result, [ key, assetsArr ]) => {
        const newData = assetsArr.map(asset => {
          const patchAsset = dndPatchAssets[asset.id]
          const cloneAsset = cloneInstanceClass(asset)
          if (patchAsset) {
            cloneAsset.duration = patchAsset.duration
            cloneAsset.startTime = patchAsset.startTime
          }
          return cloneAsset
        })
        // eslint-disable-next-line no-param-reassign
        result[key] = newData
        return result
      }, {})

    const patchedAssetsByLayerIndex = Object.entries(selectedAssetsByLayerIndex)
      .reduce((result, [ key, assetsArr ]) => {
        const newData = assetsArr.map(asset => {
          const patchAsset = dndPatchAssets[asset.id]
          const cloneAsset = cloneInstanceClass(asset)
          if (patchAsset) {
            cloneAsset.duration = patchAsset.duration
            cloneAsset.startTime = patchAsset.startTime
          }
          return cloneAsset
        })
        // eslint-disable-next-line no-param-reassign
        result[key] = newData
        return result
      }, {})

    dragItem = {
      ...dragItem,
      selectedAssets: patchedAssets,
      selectedAssetsByLayerId: patchedAssetsByLayerId,
      selectedAssetsByLayerIndex: patchedAssetsByLayerIndex,
    }
  }
  return dragItem
}
