import { useState, useEffect } from 'react'

function useAbortController() {
  const [ controller, setController ] = useState(new AbortController())
  useEffect(() => {
    const controllerInstance = controller
    function refresh() {
      setController(new AbortController())
    }
    controllerInstance.signal.addEventListener('abort', refresh)

    return () => controllerInstance.signal.removeEventListener('abort', refresh)
  }, [ controller ])

  return controller
}

export default useAbortController
