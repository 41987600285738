import React, { useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/base/Button'
import './Confirmation.scss'
import { TranslationContext } from 'contexts/TranslationContext'

export default function Confirmation({ onCancel, onConfirm, text, title, okButtonText = 'Ok' }) {
  const [ open, setOpen ] = React.useState(true)
  const handleClose = () => {
    setOpen(false)
    onCancel()
  }
  const { t } = useContext(TranslationContext)

  return (
    <Dialog className="confirmation-dialog" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          border
          large
          data-lang-id="CONFIRMATION_DIALOG_BTN_CANCEL"
          title={t('CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP')}
        >
          {t('CONFIRMATION_DIALOG_BTN_CANCEL')}
        </Button>
        <Button primary large onClick={onConfirm} autoFocus>
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
