import cx from 'classnames'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import * as Actions from 'actions'
import { useAction } from 'hooks'
import { selectLastMediaRecording, selectRecordingStatus } from 'selectors/recording'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import type { ConnectionStatus } from 'components/AssetPanels/Voiceover/types'
import Button from 'components/base/Button'
import Divider from 'components/base/Divider/Divider'

import { ReactComponent as StopIcon } from 'assets/media/audio_stop.svg'
import { ReactComponent as RetakeIcon } from 'assets/media/recording_retake.svg'
import { ReactComponent as DeleteIcon } from 'assets/timeline/ic_delete.svg'
import { ReactComponent as PlayIcon } from 'assets/video-player/ic_play.svg'

import { PLAYBACK_STATE, SOURCE_FILE_TYPES } from 'enums'
import { conditionalRefTimeToHHMMSS } from 'Util'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './retake.module.scss'

type Props = {
  connectStatus: ConnectionStatus,
  retake: () => void,
}

function Retake({ connectStatus, retake }: Props) {
  const lastRecord = useSelector(selectLastMediaRecording)
  const recordingStatus = useSelector(selectRecordingStatus)
  const deleteTimelineAsset = useAction(Actions.timeline.deleteAssets)
  const deleteSourceFile = useAction(Actions.sourceFiles.requestDeleteFile)
  const deleteRecord = useAction(Actions.recording.deleteRecord)
  const playLastRecord = useAction(Actions.recording.playLastRecord)
  const setPlaybackState = useAction(Actions.playback.changeClipPlaybackState)
  const recordPlaybackState = useSelector((state: RootState) => state.playback.clipPlaybackState)
  const [ deleting, setDeleting ] = useState(false)
  const [ showDelete, setShowDelete ] = useState(false)

  const isSaving = recordingStatus === 'saving'
  const { t } = useContext(TranslationContext)

  if (!lastRecord) {
    return null
  }

  const handleDeleteRecord = async () => {
    try {
      setDeleting(true)
      await deleteSourceFile(lastRecord.id, SOURCE_FILE_TYPES.MEDIA, true)
      deleteTimelineAsset([ lastRecord ])
      deleteRecord(lastRecord.id)
    } catch (e) {
      console.error('Try again')
    } finally {
      setDeleting(false)
      setShowDelete(false)
    }
  }

  const disableCtrls = recordingStatus === 'progress' || deleting || isSaving

  const recordPlayingStateIcon = () => {
    let Icon = recordPlaybackState !== PLAYBACK_STATE.PLAY
      ? <PlayIcon className={styles.playIcon} />
      : <StopIcon className={styles.stopIcon} />

    if (isSaving) Icon = <CircularProgress size={12} classes={{ root: styles.circularProgress }} disableShrink />

    const handleClick = recordPlaybackState !== PLAYBACK_STATE.PLAY
      ? () => playLastRecord()
      : () => setPlaybackState(PLAYBACK_STATE.PAUSE)

    return (
      <IconButton
        className={cx(styles.controls, styles.play)}
        classes={{ label: styles.icon }}
        onClick={handleClick}
        disabled={disableCtrls}
      >
        {Icon}
      </IconButton>
    )
  }

  return (
    <div className={styles.container}>
      {isSaving && <div className={styles.progress} />}
      {!showDelete
        ? (
          <>
            <div className={styles.header}>
              <div className={styles.title}>
                {t('VOICE_OVER_TITLE_LAST_RECORDING')}
              </div>
              <Divider className={styles.divider} orientation="vertical" />
              <div className={styles.duration}>
                {conditionalRefTimeToHHMMSS(lastRecord.duration, true)}
              </div>
            </div>
            <div className={styles.track}>
              {recordPlayingStateIcon()}
              <div className={styles.name}>
                {lastRecord.name}
              </div>
              <Button
                className={cx(styles.controls, styles.retake)}
                onClick={retake}
                disabled={disableCtrls || connectStatus !== 'connected'}
                startIcon={<RetakeIcon />}
                title={t('VOICE_OVER_BTN_RETAKE_TOOLTIP')}
                data-lang-id="VOICE_OVER_BTN_RETAKE"
              >
                {t('VOICE_OVER_BTN_RETAKE')}
              </Button>
              <Button
                className={cx(styles.controls, styles.delete)}
                disabled={disableCtrls}
                onClick={() => setShowDelete(true)}
                startIcon={<DeleteIcon />}
                title={t('VOICE_OVER_BTN_DELETE_FILE_TOOLTIP')}
                data-lang-id="VOICE_OVER_BTN_DELETE_FILE"
              >
                {t('VOICE_OVER_BTN_DELETE_FILE')}
              </Button>
            </div>

          </>
        )
        : (
          <div className={styles.track}>
            <div>
              {t('VOICE_OVER_TEXT_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE')}
              <br />
              {t('VOICE_OVER_TEXT_ARE_YOU_SURE')}
            </div>
            <Button
              className={cx(styles.controls, styles.no)}
              onClick={() => setShowDelete(false)}
              disabled={disableCtrls}
              data-lang-id="VOICE_OVER_BTN_NO"
              title={t('VOICE_OVER_BTN_NO_TOOLTIP')}
            >
              {t('VOICE_OVER_BTN_NO')}
            </Button>
            <Button
              className={cx(styles.controls, styles.yes)}
              onClick={handleDeleteRecord}
              disabled={disableCtrls}
              data-lang-id="VOICE_OVER_BTN_YES"
              title={t('VOICE_OVER_BTN_YES_TOOLTIP')}
            >
              {t('VOICE_OVER_BTN_YES')}
            </Button>
          </div>
        )}
    </div>
  )
}

export default Retake
