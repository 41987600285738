import React, { useContext } from 'react'

import { LAYER_HEIGHT_WITH_BORDER } from 'constant'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './newLayer.module.scss'

type Props = {
  layerIndex: number,
  scrollTop: number
}

function NewLayer({ layerIndex, scrollTop }: Props) {
  const { t } = useContext(TranslationContext)

  return (
    <div
      className={styles.item}
      style={{ transform: `translateY(${layerIndex * LAYER_HEIGHT_WITH_BORDER - scrollTop}px)` }}
    >
      <div className={styles.itemBtn}>{t('TIMELINE_TEXT_ADD_LAYER')}</div>
    </div>

  )
}

export default NewLayer
