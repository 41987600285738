import { keyCodeToKey } from 'helpers/keyCodeToKey'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'

export const useHotkeyTooltip = () => {
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const getTooltip = useCallback(
    (tooltip: string, hotkey: string) => `${tooltip} (${keyCodeToKey(activeHotkeyProfile.hotkeys[hotkey])})`,
    [ activeHotkeyProfile ]
  )

  return { getTooltip }
}
