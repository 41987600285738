import { isNil } from 'lodash'
import { time2Pixel, pixel2Time } from '~/Util'
import { normalizePlaceholderBorders } from './normalizePlaceholderBorders'

export const getPlaceHolderIntersection = ({
  placeholdersByLayerIndex,
  dragItem,
  layerIds,
  offsetLayerIndex,
  scale,
  draggableBlockStartTime,
  draggbleAssets,
  clientOffset,
  leftXOffset,
  overNewLayerIndex,
}) => Object.values(placeholdersByLayerIndex ?? {})
  .reduce((result, placeholders) => {
    const startLayerIndex = layerIds.indexOf(dragItem.layerId)
    const currentDragItemLayerId = layerIds[startLayerIndex + offsetLayerIndex]

    const insertableDragAssets = dragItem
      .selectedAssets?.filter(a => a.layerId === dragItem.layerId)
      .sort((a, b) => a.startTime - b.startTime)

    const clientMousePosition = pixel2Time(clientOffset.x - leftXOffset, scale)
    const firstInsertableDragAsset = insertableDragAssets?.[0]
    const lastInsertableDragAsset = insertableDragAssets?.[insertableDragAssets?.length - 1]
    const dragLayerDuration = ((lastInsertableDragAsset?.startTime ?? 0)
    + (lastInsertableDragAsset?.duration ?? 0)) - (firstInsertableDragAsset?.startTime ?? 0)

    const { intersectionPlaceholder, intersectionEqualSegment } = placeholders
      .reduce((res, placeholder) => {
        const result = { ...res }
        const {
          placeholderEndTime,
          placeholderStartTime,
        } = normalizePlaceholderBorders(placeholder)

        if (isNil(overNewLayerIndex) && placeholder.layerId === currentDragItemLayerId) {
          if ((Math.round(placeholder.duration) < Math.round(dragLayerDuration))
            && (clientMousePosition >= placeholderStartTime
              && clientMousePosition <= placeholderEndTime)) {
            result.intersectionPlaceholder = placeholder
          }

          // for equal duration: placeholder & dragLayerDuration
          if ((Math.round(placeholder.duration) === Math.round(dragLayerDuration))
            && (clientMousePosition >= placeholderStartTime
              && clientMousePosition <= placeholderEndTime)) {
            result.intersectionEqualSegment = placeholder
          }
        }

        return result
      }, { intersectionPlaceholder: null, intersectionEqualSegment: null })

    let offsetDragItemFromZero = 0
    if (intersectionPlaceholder?.startTime === 0
      && draggableBlockStartTime <= 0) {
      offsetDragItemFromZero = dragItem.startTime - draggbleAssets?.[0]?.startTime
    }

    if (intersectionPlaceholder) {
      // eslint-disable-next-line no-param-reassign
      result = {
        intersectionPlaceholder,
        deltaOnDrop: time2Pixel(dragLayerDuration
          - intersectionPlaceholder.duration + offsetDragItemFromZero, scale),
      }
    }
    if (intersectionEqualSegment) {
      // eslint-disable-next-line no-param-reassign
      result.intersectionEqualSegment = intersectionEqualSegment
    }
    return result
  }, {
    intersectionPlaceholder: null,
    intersectionEqualSegment: null,
    deltaOnDrop: 0,
  })
