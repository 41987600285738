import React, { useContext, useEffect, useState } from 'react'

import { ConfirmationDialog } from 'components/base/ConfirmationDialog/ConfirmationDialog'
import InputDropdownMenu from 'components/base/InputDropdownMenu'
import { fps as items } from 'components/Projects/components/Import/constants'
import { Options } from 'components/Projects/components/Import/hooks/useUpload'
import { FPS } from 'components/Projects/components/Import/types'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './framerateSelect.module.scss'

type Props = {
  onClose: () => void,
  // value: T,
  // inputValue: T,
  // defaultValue: T,
  onConfirm: () => void,
  // onSelect?: (item: string) => void,
  onChange?: (settings: Options) => void,
  name: string,
}

const defaultValue: FPS = '25'

function FramerateSelect({ onClose, onConfirm, onChange = () => {}, name }: Props) {
  const [ selectValue, setSelectValue ] = useState<FPS | 'null'>(defaultValue)
  const [ inputValue, _setInputValue ] = useState('')

  useEffect(() => {
    const fps = parseFloat(selectValue === 'null' ? inputValue : selectValue)
    onChange({ fps })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectValue, inputValue ])

  const setInputValue = (value: string) => {
    if (value !== '' && parseFloat(value)) {
      setSelectValue('null')
      _setInputValue(value)
    } else {
      setSelectValue(defaultValue)
      _setInputValue('')
    }
  }

  const handleConfirm = () => {
    onConfirm()
  }

  const { t } = useContext(TranslationContext)

  const resItems = selectValue === 'null' ? [ ...items, { id: 'null', name: `${inputValue} fps` }] : items

  return (
    <ConfirmationDialog
      onCancel={onClose}
      title={t('PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE')}
      onConfirm={handleConfirm}
      description={name}
      confirmBtnText={t('PROJECTS_IMPORT_BTN_OK')}
      dataLangConfirmId="PROJECTS_IMPORT_BTN_OK"
      buttonConfirmTitle={t('PROJECTS_IMPORT_BTN_OK_TOOLTIP')}
    >
      <InputDropdownMenu
        items={resItems}
        value={selectValue}
        defaultValue={defaultValue}
        onSelect={a => setSelectValue(a as FPS)}
        onChange={setInputValue}
        inputValue={inputValue}
        inputProps={{ scale: 2 }}
        placeholder={t('PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE')}
        classes={{
          input: styles.input,
          dropdown: styles.dropdown,
          menu: styles.menu,
          paper: styles.paper,
          item: styles.item,
        }}
        autoWidth
        disableInputIcon
        disableAll
      />
    </ConfirmationDialog>
  )
}

export default FramerateSelect
