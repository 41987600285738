import { enableMapSet } from 'immer'
import React from 'react'
import ReactDOM from 'react-dom'
import WebFont from 'webfontloader'
import { customFonts, googleFonts } from '~/fonts'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

enableMapSet()

WebFont.load({
  google: { families: googleFonts },
  custom: { families: customFonts },
})

// The desktop keys registering
if (__APP_PROFILE__ === 'desktop') {
  nw.App.registerGlobalHotKey(new nw.Shortcut({
    key: 'Escape',
    active() {
      nw.Window.get().leaveFullscreen()
    },
  }))
}

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
