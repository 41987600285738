/* eslint-disable class-methods-use-this */
import { updateAssetInPreview } from '~/actions/layer'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class AssetExpandedAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    const { duration, startTime, mediaStart } = payload
    super(HistoryActionType.AssetExpandedAction, payload, ...params)
    this.duration = duration
    this.startTime = startTime
    this.mediaStart = mediaStart
  }

  get duration() {
    return this._duration || this.asset.duration
  }

  set duration(duration) {
    this._duration = duration
  }

  get startTime() {
    return this._startTime || this.asset.startTime
  }

  set startTime(startTime) {
    this._startTime = startTime
  }

  get mediaStart() {
    return this._mediaStart || this.asset.mediaStart
  }

  set mediaStart(mediaStart) {
    this._mediaStart = mediaStart
  }

  * undo() {
    const { asset } = this
    const { duration, startTime, mediaStart } = asset
    yield updateAssetInPreview(asset.id, { duration, startTime, mediaStart })
  }

  * redo() {
    const { asset, duration, startTime, mediaStart } = this
    yield updateAssetInPreview(asset.id, { duration, startTime, mediaStart })
  }

  getOptimizedObject() {
    return { ...this,
      asset: this.getOptimizedAsset(({ duration, startTime, mediaStart }) => (
        { duration, startTime, mediaStart })) }
  }

  isContinuously() {
    return true
  }

}
