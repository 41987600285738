import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { Status2Text } from '~/Util/status'
import { TASK_STATUS } from '~/enums'
import ErrorText from '../ErrorText'
import RenderResult from '../RenderResult'
import './default.scss'

function Status({ task = {}, outputName, sourceFile }) {
  const { progress, status = TASK_STATUS.COMPLETED, error, outputUrls } = task ?? {}
  const showDownload = status === TASK_STATUS.COMPLETED
  const downloadUrl = sourceFile?.originalUrl || (outputUrls?.length ? outputUrls[0] : '') || sourceFile?.url
  const { t } = useContext(TranslationContext)
  const statusText = Status2Text(status, error, t)

  return (
    <>
      <RenderResult status={status} downloadUrl={downloadUrl} />
      <Choose>
        <When condition={showDownload}>
          <div className="save-settings__btn-download" title={t('SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP')}>
            <Button third href={downloadUrl} download={outputName || sourceFile.name} data-lang-id="SAVE_SETTINGS_BTN_DOWNLOAD">
              {t('SAVE_SETTINGS_BTN_DOWNLOAD')}
            </Button>
          </div>
        </When>
        <Otherwise>
          <>
            <ErrorText text={statusText} />
            {/* TODO: calculate left time
                <p>Approximate time left: 13 min. You may close this window.</p>
                <p>Video will be added directly to your library when finished.</p>
              */}
            <div className="save-settings__loader">
              <div className="save-settings__loader-inner" style={{ width: `${Math.min(progress, 100)}%` }} />
            </div>
          </>
        </Otherwise>
      </Choose>
    </>
  )
}

export default Status
