export const getCopiedAssetsLayers = (availableLayers, copiedAssets, isBelongToExistsLayers) => {
  const copiedAssetsLayersIds = copiedAssets.map(asset => asset.layerId)
  return Object.entries(availableLayers).reduce((result, [ layerId, data ]) => {
    if (isBelongToExistsLayers) {
      if (copiedAssetsLayersIds.includes(layerId)) {
        // eslint-disable-next-line no-param-reassign
        result[layerId] = data
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      result[layerId] = data
    }
    return result
  }, {})
}
