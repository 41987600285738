import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import cx from 'classnames'

import { useDOMEvent, useOnChange, useLatestRef, useAction } from '~/hooks'
import { pixel2Time } from '~/Util'
import TimelineRuller from './TimeLineRuller'
import SliderPointer from './SliderPointer'
// import { Context as DnDContext } from './DnDContextProvider'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import * as Actions from '~/actions'

import './TimelineHeader.scss'

// ---

function TimelineHeader(props) {
  const {
    scale,
    time: appTime,
    onMoveSlider,
    disabled,
  } = props

  // const { isDraggingItemOverLayer, time: contextTime } = React.useContext(DnDContext)
  const {
    refIsMouseDown,
    canMove,
    localTime,
    setLocalTime,
    isSticky,
  } = React.useContext(TimelineScrollPositionContext)

  // const time = isDraggingItemOverLayer ? contextTime : appTime
  const time = appTime
  // ---

  const onLocalTimeUpdate = React.useMemo(
    // TODO:
    //  when slider is moved fast out of clip, clip in preview won't be rewinded to its start/end because of debounce
    //  find the logic of preview rewinding
    () => debounce(onMoveSlider, 10, { leading: true, trailing: true }),
    [ onMoveSlider ]
  )

  // ---

  const refRuller = React.useRef()
  const saveProject = useAction(Actions.saveProject.saveProject)
  const setIsMovingSlider = useAction(Actions.timeline.setIsMovingSlider)

  // TODO: same logic used in `useLayerDropTarget`. Need to unify it somehow.
  // use ref for this, to don't re-create `getTimeFromMouseEvent` callback
  // with every scroll update. It's only needed when header is clicked/mousemoved.
  const scrollRef = useLatestRef(React.useContext(TimelineScrollPositionContext))
  const getTimeFromMouseEvent = React.useCallback(
    event => {
      const $ruller = refRuller.current
      const position = event.clientX - $ruller.offsetLeft + scrollRef.current.scrollLeft
      return Math.max(0, pixel2Time(position, scale))
    },
    [ scale, scrollRef ]
  )

  const onMouseDown = React.useCallback(
    e => {
      if (disabled || !canMove) {
        return
      }
      e.preventDefault()
      refIsMouseDown.current = true
      setIsMovingSlider(true)
      setLocalTime(getTimeFromMouseEvent(e))
    },
    [
      getTimeFromMouseEvent,
      disabled,
      setIsMovingSlider,
      refIsMouseDown,
      setLocalTime,
      canMove,
    ]
  )

  const onMouseUp = React.useCallback(
    () => {
      if (refIsMouseDown.current === false || !canMove) {
        return
      }
      refIsMouseDown.current = false
      setIsMovingSlider(false)
      setTimeout(() => saveProject())
    },
    [ saveProject, setIsMovingSlider, refIsMouseDown, canMove ]
  )

  const onMouseMove = React.useCallback(
    e => {
      if (refIsMouseDown.current === false || !canMove) {
        return
      }

      e.preventDefault()
      setLocalTime(getTimeFromMouseEvent(e))
    },
    [ getTimeFromMouseEvent, refIsMouseDown, setLocalTime, canMove ]
  )

  // ---

  const refDocument = React.useRef(document)
  useDOMEvent(refDocument, 'mousemove', onMouseMove)
  useDOMEvent(refDocument, 'mouseup', onMouseUp)

  // ---

  // When dragging is active, notify app about changes in local state
  // eslint-disable-next-line no-shadow
  useOnChange(localTime, time => {
    if (refIsMouseDown.current === true) {
      onLocalTimeUpdate(time)
    }
  })

  // Sync local state with app state, but only when dragging is inactive,
  // to prevent any slider flickering during drag.
  // eslint-disable-next-line no-shadow
  useOnChange(time, time => {
    if (refIsMouseDown.current === false) {
      setLocalTime(time)
    }
  })

  // ---

  return (
    <div className={cx('timeline-header', { 'timeline-header--disabled': disabled })}>
      <div
        className="timeline-header__ruller"
        ref={refRuller}
        onMouseDown={onMouseDown}
      >
        <TimelineRuller />

        <SliderPointer
          scale={scale}
          time={localTime}
          isSticky={isSticky}
        />
      </div>
    </div>
  )
}

TimelineHeader.defaultProps = {
  disabled: false,
  time: 0,
}

TimelineHeader.propTypes = {
  scale: PropTypes.number.isRequired,
  time: PropTypes.number,
  onMoveSlider: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default React.memo(TimelineHeader)
