/* eslint-disable consistent-return */


import { WebRtcPeer } from 'kurento-utils'

// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter'

// eslint-disable-next-line no-unused-vars
import { TMEDIA_RECORDING_TYPE } from 'enums'
import { httpClient } from 'ServerAPI'

// function getopts(args, opts) {
//   const result = opts.default || {}
//   args.replace(
//     new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
//     ($0, $1, $2, $3) => { result[$1] = decodeURI($3) }
//   )

//   return result
// }

// const args = getopts(window.location.search,
//   {
//     default:
//   {
//     // Non-secure WebSocket
//     // Only valid for localhost access! Browsers won't allow using this for
//     // URLs that are not localhost. Also, this matches the default KMS config:
//     ws_uri: `ws://${window.location.hostname}:8888/kurento`,

//     // Secure WebSocket
//     // Valid for localhost and remote access. To use this, you have to edit the
//     // KMS settings file "kurento.conf.json", and configure the section
//     // "mediaServer.net.websocket.secure". Check the docs:
//     // https://doc-kurento.readthedocs.io/en/latest/features/security.html#features-security-kms-wss
//     // ws_uri: "wss://" + location.hostname + ":8433/kurento",

//     file_uri: 'file:////etc/kurento/recorder_demo.mp4', // file to be stored in media server
//     ice_servers: undefined,
//   },
//   })

// function setIceCandidateCallbacks(webRtcPeer, webRtcEp, onerror) {
//   webRtcPeer.on('icecandidate', function (candidate) {
//     console.log('Local candidate:', candidate)

//     const newCandidate = KurentoClient.getComplexType('IceCandidate')(candidate)

//     webRtcEp.addIceCandidate(newCandidate, onerror)
//   })

//   webRtcEp.on('IceCandidateFound', function (event) {
//     const { candidate } = event

//     console.log('Remote candidate:', candidate)

//     webRtcPeer.addIceCandidate(candidate, onerror)
//   })
// }

const BACKEND_VOICEOVERS_API = '/api/voiceovers'
const iceCandidatesGatheringTime = 500
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const webRtcPeerMap = {}

/**
 *
 * @param {{id: string, keepRecording: 1 | 0}} param0
 * @returns
 */
const stopVoiceover = async ({ id, keepRecording }) => {
  try {
    const response = await httpClient.delete(`${BACKEND_VOICEOVERS_API}/${id}`,
      { headers, params: { keepRecording } })
    // TODO: handleError(response)
    return response.data
  } catch (e) {
    throw Error()
  }
}

/**
 *
 * @param {string} id
 * @returns
 */
export async function stopRecording(id) {
  try {
    return await stopVoiceover({ id, keepRecording: 1 })
  } catch (e) {
    throw Error('Stop Recording Error')
  }
}

/**
 *
 * @param {string} id
 * @returns
 */
export async function deleteRecording(id) {
  closeConnection(id)
  try {
    return await stopVoiceover({ id, keepRecording: null })
  } catch (e) {
    throw Error('Delete Recording Error')
  }
}

let intervalId
/**
 *
 * @param {string} id
 */
export function closeConnection(id) {
  if (webRtcPeerMap[id]) {
    webRtcPeerMap[id].dispose()
    delete webRtcPeerMap[id]
    clearInterval(intervalId)
  }
}

/**
 *
 * @param {string} id
 * @returns
 */
export async function startRecording(id) {
  const response = await httpClient.put(`${BACKEND_VOICEOVERS_API}/${id}`, {}, { headers })
  // TODO: handleError(response)
  return response.data
}

const createVoiceoverSession = async ({ sdpOffer, folder, iceCandidates }) => {
  // console.log('createVoiceoverSession...')
  const body = {
    sdpOffer,
    folder,
    iceCandidates,
  }
  const response = await httpClient.post(`${BACKEND_VOICEOVERS_API}`, JSON.stringify(body), {
    headers,
  })
  return response.data
}

let timeoutId = null
let connected = true
/**
 * way to "immediately" test connection instead of
 * waiting for connectionstatechange event
 */
function checkConnection(webRtcPeer, onConnectionStateChange) {
  // eslint-disable-next-line no-unused-expressions
  webRtcPeer?.peerConnection.getSenders()
    .forEach(
      receiver => {
        receiver.getStats().then(statReport => {
          statReport.forEach(
            statValue => {
              if (statValue.type === 'candidate-pair') {
                if (statValue.state === 'in-progress') {
                  connected = false
                  if (timeoutId) return
                  timeoutId = setTimeout(() => {
                    if (!connected) {
                      onConnectionStateChange('disconnected')
                    }
                  }, 1000)
                } else {
                  connected = true
                  clearTimeout(timeoutId)
                  timeoutId = null
                }
              }
            }
          )
        })
      }
    )
}

/**
 * @typedef {object} CreateRecordingOptions
 * @property {MediaStream} [audioStream]
 * @property {MediaStream} [videoStream]
 * @property {string | null } [folder]
 * @property {(x: RTCPeerConnectionState) => void} onConnectionStateChange
 * @property {(id: string) => void} [onConnect]
 */

/**
 *
 * @param {CreateRecordingOptions} param0
 * @returns {Promise<string>}
 */
export async function createRecording({ audioStream = undefined, videoStream = undefined,
  folder = null,
  onConnectionStateChange,
  onConnect = id => id }) {
  clearInterval(intervalId)

  const options = {
    audioStream,
    videoStream,
    configuration: {
      iceServers: [ __CFG__.SOURCE_FILES_MANAGEMENT.TURN_SERVER ],
    },
  }
  let webRtcPeer
  try {
    webRtcPeer = await new Promise((resolve, reject) => {
      // eslint-disable-next-line func-names
      WebRtcPeer.WebRtcPeerSendonly(options, function (error) {
        if (error) {
          reject(error)
        }
        resolve(this)
      })
    })
    const { sdpOffer, localIceCandidates } = await new Promise((resolve, reject) => {
      let browserOffer
      const localIceCandidates = []
      webRtcPeer.on('icecandidate', candidate => {
        if (!candidate) {
          // console.log('Local candidategatheringdone')
        }
        localIceCandidates.push(candidate)
        // console.log(`Local candidate:${candidate.candidate}`)
      })
      setTimeout(() => {
        // console.log('Local candidategatheringdone:')
        resolve({ sdpOffer: browserOffer, localIceCandidates })
      }, iceCandidatesGatheringTime)
      /* this way to wait gathering process is too long waiting
      webRtcPeer.peerConnection.onicegatheringstatechange = function (ev) {
        let connection = ev.target
        if (connection.iceGatheringState == "complete") {
          console.log(`Local candidategatheringdone:`)
          resolve({ offer: browserOffer, localIceCandidates })
        }
      } */
      webRtcPeer.generateOffer((error, sdpOffer) => {
        if (error) {
          reject(error)
        }
        browserOffer = sdpOffer
      })
    })
    const { id, sdpAnswer, iceCandidates } = await createVoiceoverSession({ sdpOffer,
      folder,
      iceCandidates: localIceCandidates })
    // console.log(`voiceover  id: ${id} registered`, sdpAnswer)

    iceCandidates.forEach(candidate => {
      // console.log(`addIceCandidate remote ${candidate.candidate}`)
      webRtcPeer.addIceCandidate(candidate, onError)
    })

    webRtcPeerMap[id] = webRtcPeer

    intervalId = setInterval(() => {
      checkConnection(webRtcPeer, onConnectionStateChange)
    }, 200)

    webRtcPeer.peerConnection.addEventListener('connectionstatechange', () => {
      const state = webRtcPeer.peerConnection.connectionState
      switch (state) {
        case 'new':
        case 'checking':
          // console.log('Connecting…')
          break
        case 'connected':
          // console.log('connected...')
          onConnect(id)
          break
        case 'disconnected':
          if (webRtcPeer) {
            webRtcPeer.dispose()
            webRtcPeer = null
          }
          break
        case 'closed':
          // https://www.w3.org/TR/webrtc/#dom-rtcpeerconnection-close
          // triggered only by remote side
          // console.log('Offline')
          break
        case 'failed':
          if (webRtcPeer) {
            webRtcPeer.dispose()
            webRtcPeer = null
          }
          break
        default:
          break
      }
      onConnectionStateChange(state)
    }, false)

    webRtcPeer.processAnswer(sdpAnswer)
    return id
  } catch (e) {
    if (webRtcPeer) {
      webRtcPeer.dispose()
      webRtcPeer = null
    }
    throw Error(e)
    // onError(e)
  }
}

// function startPlaying() {
//   console.log('Start playing')

//   const videoPlayer = document.getElementById('videoOutput')
//   showSpinner(videoPlayer)

//   const options = {
//     remoteVideo: videoPlayer,
//   }

//   if (args.ice_servers) {
//     console.log(`Use ICE servers: ${args.ice_servers}`)
//     options.configuration = {
//       iceServers: JSON.parse(args.ice_servers),
//     }
//   } else {
//     console.log('Use freeice')
//   }

//   const webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
//     function (error) {
//       if (error) return onError(error)

//       this.generateOffer(onPlayOffer)
//     })

//   function onPlayOffer(error, offer) {
//     if (error) return onError(error)

//     KurentoClient(args.ws_uri, (error, client) => {
//       if (error) return onError(error)

//       client.create('MediaPipeline', (error, pipeline) => {
//         if (error) return onError(error)

//         pipeline.create('WebRtcEndpoint', (error, webRtc) => {
//           if (error) return onError(error)

//           setIceCandidateCallbacks(webRtcPeer, webRtc, onError)

//           webRtc.processOffer(offer, (error, answer) => {
//             if (error) return onError(error)

//             webRtc.gatherCandidates(onError)

//             webRtcPeer.processAnswer(answer)
//           })

//           const options = { uri: args.file_uri }

//           pipeline.create('PlayerEndpoint', options, function (error, player) {
//             if (error) return onError(error)

//             player.on('EndOfStream', function (event) {
//               pipeline.release()
//               videoPlayer.src = ''

//               hideSpinner(videoPlayer)
//             })

//             player.connect(webRtc, function (error) {
//               if (error) return onError(error)

//               player.play(function (error) {
//                 if (error) return onError(error)
//                 console.log('Playing ...')
//               })
//             })

//             document.getElementById('stop').addEventListener('click',
//               function (event) {
//                 pipeline.release()
//                 webRtcPeer.dispose()
//                 videoPlayer.src = ''

//                 hideSpinner(videoPlayer)
//               })
//           })
//         })
//       })
//     })
//   }
// }

function onError(error) {
  // eslint-disable-next-line no-console
  if (error) {
    throw new Error(error)
  }
}

export function getSenders(id) {
  if (webRtcPeerMap[id]) { return webRtcPeerMap[id].peerConnection.getSenders() }
  return []
}

// function showSpinner() {
//   for (let i = 0; i < arguments.length; i++) {
//     arguments[i].poster = 'img/transparent-1px.png'
//     arguments[i].style.background = "center transparent url('img/spinner.gif') no-repeat"
//   }
// }

// function hideSpinner() {
//   for (let i = 0; i < arguments.length; i++) {
//     arguments[i].src = ''
//     arguments[i].poster = 'img/webrtc.png'
//     arguments[i].style.background = ''
//   }
// }

// /**
//  * Lightbox utility (to display media pipeline image in a modal dialog)
//  */
// $(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
//   event.preventDefault()
//   $(this).ekkoLightbox()
// })
