import { patchAsset } from '~/actions/layer'
import { continiousChangingAssetParams } from '~/constant'
import { getObjectFields } from '~/Util'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class AssetParamsUpdatedAction extends AssetHistoryAction {

  constructor(payload) {
    const { patch } = payload
    super(HistoryActionType.AssetParamsUpdatedAction, payload)
    this.patch = patch
  }

  get patch() {
    return this._patch
  }

  set patch(patch) {
    this._patch = patch
  }

  * undo() {
    const { asset, patch } = this
    yield patchAsset(asset,
      { ...Object.keys(getObjectFields(asset))
        .filter(k => Object.keys(patch).includes(k))
        .reduce((acc, k) => ({ ...acc, [k]: asset[k] }), {}) })
  }

  * redo() {
    const { asset, patch } = this
    yield patchAsset(asset, { ...patch })
  }

  isContinuously() {
    return Object.keys(this.patch).some(k => continiousChangingAssetParams.includes(k))
  }

  getOptimizedObject() {
    return { ...this,
      asset: this.getOptimizedAsset(asset => {
        const optimizedAsset = { ...asset }
        for (const key in asset) {
          if (this.patch[key] === undefined) {
            delete optimizedAsset[key]
          }
        }
        return optimizedAsset
      }) }
  }

}
