import * as ActionTypes from '~/actions/ActionTypes'
import { MEDIA_TYPE, SOURCE_FILE_TYPES, FILE_TYPE } from '~/enums'
import { setSourceFilesParamsNoReload } from './sourceFiles'
import * as API from '~/ServerAPI'
import { renameFolder as renameFolderApi, createFolder as createFolderApi } from '~/services/FoldersApiService'
import { parseLoadedFile } from '~/Util/sourceFile'
import * as Selectors from '~/selectors'

export const openMediaDirectory = (type, directory) => dispatch => {
  dispatch(setSourceFilesParamsNoReload(type, { search: '' }))
  dispatch({
    type: ActionTypes.ACTIONS_MEDIA_DIRECTORY_OPEN,
    payload: { type, directory },
  })
}

export const openFolderById = (type, id) => async (dispatch, getState) => {
  const currentFolderId = Selectors.selectCurrentFolderId(getState(), SOURCE_FILE_TYPES.MEDIA)
  if (id && id !== currentFolderId) {
    const { data } = await API.getMediaFile(id, FILE_TYPE.FOLDER)
    const directory = parseLoadedFile(SOURCE_FILE_TYPES.MEDIA, data)
    await dispatch(openMediaDirectory(type, directory))
  }
}

export const mediaDirectoryMoveForward = type => dispatch => {
  dispatch(setSourceFilesParamsNoReload(type, { search: '' }))
  dispatch({
    type: ActionTypes.ACTIONS_MEDIA_DIRECTORY_FORWARD,
    payload: { type },
  })
}

export const mediaDirectoryMoveBackward = type => dispatch => {
  dispatch(setSourceFilesParamsNoReload(type, { search: '' }))
  dispatch({
    type: ActionTypes.ACTIONS_MEDIA_DIRECTORY_BACKWARD,
    payload: { type },
  })
}

/**
 *
 * @param {string} sourceFilesType
 * @param {string} name
 * @param {string|number} parentFolderId
 * @returns
 */
export const createFolder = (sourceFilesType, name, parentFolderId) => async dispatch => {
  try {
    const response = await createFolderApi({ name, folder: parentFolderId })
    const { id } = response
    await dispatch({ type: ActionTypes.MEDIA_FOLDER_CREATE,
      payload: {
        type: sourceFilesType,
        folder: parseLoadedFile(SOURCE_FILE_TYPES.MEDIA, {
          filetype: MEDIA_TYPE.FOLDER,
          name,
          id,
          isNew: true,
        }),
      } })
  } catch (e) {
    console.error('Cannot create folder:', e.message)
  }
}

export const renameFolder = (type, folderId, name) => async dispatch => {
  try {
    await renameFolderApi(folderId, name)
    await dispatch({ type: ActionTypes.MEDIA_FOLDER_RENAME,
      payload: {
        type,
        folderId,
        name,
      } })
  } catch (e) {
    console.error('Cannot rename folder:', e.message)
  }
}
