import { useScript, useTemplate } from '~/hooks'

const FILE_EXTENSIONS = {
  JS: '.js',
  HTML: '.html',
}

export const DynamicScripts = ({ sources }) => {
  // eslint-disable-next-line no-shadow
  const filterByExtension = (sources, extension) => sources.filter(src => src.includes(extension))

  useScript({ sources: filterByExtension(sources, FILE_EXTENSIONS.JS) })
  useTemplate({ sources: filterByExtension(sources, FILE_EXTENSIONS.HTML) })

  return null
}
