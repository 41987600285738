import React from 'react'
import { LinearProgress } from '@material-ui/core'

export default function ThumbnailsProgress({ progress }) {
  const variant = progress === 0 ? 'indeterminate' : 'determinate'
  return (
    <div className="media-file__prepare-thumbnails">
      <LinearProgress variant={variant} value={progress} />
    </div>
  )
}
