import Cookies from 'universal-cookie'
import { newProjectName } from '~/constant'

const cookies = new Cookies()
const LATEST_PROJECT_NAME = 'latestProjectName'

export function set(latestProjectName) {
  const currentValue = get()
  if (currentValue !== latestProjectName) {
    cookies.set(LATEST_PROJECT_NAME, latestProjectName, {
      path: '/',
      expires: new Date(86_400_000 + +new Date()),
    })
  }
}

export function get() {
  return cookies.get(LATEST_PROJECT_NAME) || newProjectName
}
