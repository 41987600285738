/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react'
import { Html } from 'react-konva-utils'
import { useSelector } from 'react-redux'
import Transition from '~/components/Preview/Transition'
import { TRANSITIONS } from '~/enums'

export const AssetOverlayTransition = React.forwardRef(({
  transition,
  id,
  onTransitionProgress = () => {},
  onTransitonUnmount = () => {},
  width, height,
  layerRef,
  shape,
  isComposed = false,
  asset,
  composedAsset,
  composedCanvas: composedCanvasOrig,
  composedShape,
  videoRefs,
  canvas,
}, ref) => {
  const { sliderTime } = useSelector(state => state.timeline)

  const [ transitionCanvas2D, setTransitionCanvas2D ] = useState(null)
  const shapeCanvas = canvas || videoRefs?.[id]?.current || shape.toCanvas()
  const composedCanvas = composedCanvasOrig || videoRefs?.[composedAsset?.id]?.current
    || composedShape?.toCanvas()

  const sameSizeInDissolve = asset?.settings.size?.width === composedAsset?.settings.size?.width
    && asset?.settings.size?.height === composedAsset?.settings.size?.height
    && asset?.id !== composedAsset?.id

  const getTransitionCanvases = () => {
    switch (transition?.type) {
      case TRANSITIONS.FADEIN: {
        return [ null, shapeCanvas ]
      }
      case TRANSITIONS.FADEOUT: {
        return [ shapeCanvas, null ]
      }
      case TRANSITIONS.DISSOLVE: {
        if (sameSizeInDissolve) {
          return [ shapeCanvas, composedCanvas ]
        }
        return isComposed ? [ null, shapeCanvas ]
          : [ shapeCanvas, null ]
      }
      default: {
        return []
      }
    }
  }

  const draw = useCallback(sourceCanvas => {
    if (transitionCanvas2D) {
      const tctx = transitionCanvas2D.getContext('2d')
      tctx.clearRect(0, 0, width, height)
      tctx.drawImage(sourceCanvas, 0, 0)
      layerRef.current.batchDraw()
    }
  }, [ transitionCanvas2D, height, layerRef, width ])

  useEffect(() => {
    const canvas = document.createElement('canvas')

    canvas.width = width
    canvas.height = height
    setTransitionCanvas2D(canvas)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ shape ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onTransitonUnmount, [])

  const canvases = getTransitionCanvases()

  const handleTransitionProgress = () => {
    if (ref.current?.gl.canvas) {
      draw(ref.current?.gl.canvas)
      onTransitionProgress(transitionCanvas2D)
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function onCanPlay() { setTimeout(handleTransitionProgress, 200) }
    if (videoRefs?.[id]?.current) {
      videoRefs[id].current.addEventListener('canplay', onCanPlay)
      return () => videoRefs?.[id]?.current?.removeEventListener('canplay', onCanPlay)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ videoRefs?.[id]?.current ])

  if (isComposed && sameSizeInDissolve) {
    return null
  }

  return (
    <If condition={transitionCanvas2D}>
      <Html divProps={{
        style: {
          visibility: 'hidden',
        },
      }}
      >
        <Transition
          from={canvases[0]}
          to={canvases[1]}
          transition={transition}
          ref={ref}
          // resizeMode={canvases[1] ? 'contain' : undefined}
          onProgress={handleTransitionProgress}
          sliderTime={sliderTime}
          params={{ rect: { width, height, x: 0, y: 0 } }}
        />
      </Html>
    </If>
  )
})
