import TimelineLayer from '~/models/Layer'
import { HistoryAction } from './HistoryAction'

export class LayerHistoryAction extends HistoryAction {

  constructor(name, actionPayload) {
    super(name, actionPayload)
    const { index, layer } = actionPayload
    this.layer = layer instanceof TimelineLayer ? layer : new TimelineLayer(layer)
    this.index = index
  }

  get index() {
    return this._index
  }

  set index(index) {
    this._index = index
  }

  get layer() {
    return this._layer
  }

  set layer(layer) {
    this._layer = layer
  }

}
