import PropTypes from 'prop-types'
import React from 'react'
import AudioAssetSettingsForm from 'components/AssetPanels/Audio/Settings'
import * as PT from '~/PropTypes'
import { VideoAsset, ImageAsset, AudioAsset } from '~/models/Asset'
import ImageAssetSettingsForm from './ImageForm'
import VideoAssetSettingsForm from './VideoForm'

// import AudioTracks from './AudioTracks'

function getSettingsForm(asset) {
  switch (asset.constructor) {
    case VideoAsset:
      return VideoAssetSettingsForm
    case ImageAsset:
      return ImageAssetSettingsForm
    case AudioAsset:
      return AudioAssetSettingsForm
    default:
      return null
  }
}

export default function MediaAssetSettingsForm(props) {
  const { asset } = props
  const MediaForm = getSettingsForm(asset)
  return MediaForm && <MediaForm {...props} />
}

MediaAssetSettingsForm.propTypes = {
  asset: PropTypes.oneOfType([ PT.VideoAsset, PT.ImageAsset, PT.AudioAsset ]).isRequired,
  onChange: PropTypes.func.isRequired,
}
