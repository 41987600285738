/* eslint-disable no-nested-ternary */
import { reattachTransition } from '~/actions/timeline'
import { HistoryAction } from './HistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class ReattachTransitionAction extends HistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.ReattachTransitionAction, payload, ...params)
    const { transitionId, leftAssetId, rightAssetId, oldLeftAssetId, oldRightAssetId } = payload
    this._oldLeftAssetId = oldLeftAssetId
    this._oldRightAssetId = oldRightAssetId
    this._transitionId = transitionId
    this._leftAssetId = leftAssetId
    this._rightAssetId = rightAssetId
  }

  get oldLeftAssetId() {
    return this._oldLeftAssetId
  }

  get oldRightAssetId() {
    return this._oldRightAssetId
  }

  get leftAssetId() {
    return this._leftAssetId
  }

  get rightAssetId() {
    return this._rightAssetId
  }

  get transitionId() {
    return this._transitionId
  }

  * undo() {
    const { transitionId, oldLeftAssetId, oldRightAssetId, leftAssetId, rightAssetId } = this
    yield reattachTransition(transitionId, oldLeftAssetId, oldRightAssetId,
      leftAssetId, rightAssetId)
  }

  * redo() {
    const { transitionId, leftAssetId, rightAssetId, oldLeftAssetId, oldRightAssetId } = this
    yield reattachTransition(transitionId, leftAssetId, rightAssetId,
      oldLeftAssetId, oldRightAssetId)
  }

}
