import { Stage } from 'components/Projects/components/Import/types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { getProjectProgress } from 'services/uploadService'

function useImportProgress(id: string | null,
  controller: AbortController, onError: (message: string) => void): [number,
  React.Dispatch<React.SetStateAction<number>>,
  Stage, React.Dispatch<React.SetStateAction<Stage>>] {
  const [ progress, setProgress ] = useState<number>(0)
  const [ stage, setStage ] = useState<Stage | 'stop'>('stop')

  const intervalId = useRef<number>()

  const checkStatus = useCallback(() => {
    if (!id) return
    intervalId.current = window.setInterval(async () => {
      try {
        const { progress, stage } = await getProjectProgress(id, controller)
        setProgress(progress)
        setStage(stage)
      } catch (e) {
        if (e instanceof Error) {
          if (e.name.includes('AbortError')) {
            setStage('stop')
          }
          if (e.message.includes('ImportProjectManager')) {
            setStage('error')
            onError(e.message)
            controller.abort()
            console.error('Import error', e.message)
          }
        }
      }
    }, 500)
  }, [ id, controller, onError ])

  useEffect(() => {
    setProgress(0)

    switch (stage) {
      case 'upload':
        break
      case 'unpack':
        checkStatus()
        break
      case 'import_media':
        checkStatus()
        break
      case 'ready':
        break
      default:
        break
    }

    return () => {
      window.clearInterval(intervalId.current)
    }
  }, [ stage, checkStatus ])

  return [ progress, setProgress, stage, setStage ]
}

export default useImportProgress
