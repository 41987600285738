import { ColorClient, ColorProviderContext } from 'contexts/ColorProviderContext'
import { useContext, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useColorClient = (client: ColorClient) => {
  const colorProvider = useContext(ColorProviderContext)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (client.clientId) {
      colorProvider.registerColorClient(client)
      return () => colorProvider.unregisterColorClient(client.clientId)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ client ])
}
