import { isEmpty, isNil } from 'lodash'

export const AVAILABLE_ALL = 'AVAILABLE_ALL'
export const NOT_AVAILABLE = 'NOT_AVAILABLE'
export const AVAILABLE_FIRST_LAYER = 'AVAILABLE_FIRST_LAYER'
export const AVAILABLE_NEXT_LAYER_FOR_ONE_ASSET = 'AVAILABLE_NEXT_LAYER_FOR_ONE_ASSET'

// Сheck if there is free space for inserting assets
export const checkAvailableLayerSpaces = (copiedAssetsLayers, availableLayers,
  currentCopiedAssetsByLayerIndex, isBelongToExistsLayers) => {
  if (!Object.keys(availableLayers)?.length) return { resolution: NOT_AVAILABLE }

  if (Object.values(copiedAssetsLayers)?.length === 1) {
    const currentLayerData = Object.values(copiedAssetsLayers)[0]
    if (currentLayerData.countCopiedAssets === 1) {
      const nextLayerForPaste = {
        id: null,
        index: null,
      }
      Object.values(availableLayers).forEach(l => {
        if (l.layerId !== currentLayerData.layerId) {
          if (currentLayerData.availableSpace === 0 && !nextLayerForPaste.id
            && (isNil(l.availableSpace)
            || currentLayerData.durationCopiedAssets <= l.availableSpace)) {
            nextLayerForPaste.id = l.layerId
            nextLayerForPaste.index = l.layerIndex
          }
        }
      })
      if (nextLayerForPaste.id) {
        return {
          resolution: AVAILABLE_NEXT_LAYER_FOR_ONE_ASSET,
          data: { nextLayerForPaste },
        }
      }
    }
  }
  const copiedAssetsLayersNotEmpty = Object.values(copiedAssetsLayers)
    .filter(copiedAssetsLayer => !isEmpty(copiedAssetsLayer))

  const result = copiedAssetsLayersNotEmpty.every((layer, index) => {
  // eslint-disable-next-line
  let durationCopiedAssets = layer.durationCopiedAssets
    if (!isBelongToExistsLayers && currentCopiedAssetsByLayerIndex[index]) {
      currentCopiedAssetsByLayerIndex[index]
        .forEach(asset => {
          durationCopiedAssets += asset.duration
        })
    }
    return isNil(layer.availableSpace)
      ? true : durationCopiedAssets <= layer.availableSpace
  })

  if (copiedAssetsLayersNotEmpty.length
    < currentCopiedAssetsByLayerIndex.filter(layer => !isEmpty(layer)).length) {
    return { resolution: NOT_AVAILABLE }
  }

  if (result) {
    return { resolution: AVAILABLE_ALL }
  }

  const existsAvailableSpaceInFirstLayer = isNil(Object
    .values(availableLayers)?.[0].availableSpace)
|| Object.values(copiedAssetsLayers)?.[0].durationCopiedAssets
    <= Object.values(availableLayers)?.[0].availableSpace

  if (existsAvailableSpaceInFirstLayer) {
    return { resolution: AVAILABLE_FIRST_LAYER }
  }
  return { resolution: NOT_AVAILABLE }
}
