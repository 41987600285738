export const checkStickySlider = ({
  sourceX,
  dragAssetsWidth,
  sliderTimeX,
}) => {
  const dragItemLeftX = sourceX
  const dragItemRightX = sourceX + dragAssetsWidth

  // the segment during which the asset will stick to the asset
  const segment = Math.round(dragAssetsWidth * 0.2)
  // we get the number of equal segments of draggable item
  const numberSegments = Math.round(dragAssetsWidth / segment)
  // offset for sticky
  const assetChunk = Math.round(dragAssetsWidth / numberSegments)

  // the slider is located to the left of the group of draggable assets
  const isSliderLeftSticky = (dragItemRightX >= sliderTimeX)
  && (dragItemLeftX <= sliderTimeX)
  && ((dragItemLeftX + assetChunk) >= sliderTimeX)
  // the slider is located to the right of the group of draggable assets
  const isSliderRightSticky = (dragItemLeftX <= sliderTimeX)
  && (dragItemRightX >= sliderTimeX)
  && ((dragItemRightX - assetChunk) <= sliderTimeX)

  /*
   * Only for multiple drag assets!!!!
   * Diff between sourceX and sticky sourceX, depending on the slider location.
   * To insert the clip correctly without offset.
  */

  let leftOffset = 0
  let rightOffset = 0

  if (isSliderLeftSticky) {
    leftOffset = sliderTimeX - dragItemLeftX
  }

  if (isSliderRightSticky) {
    rightOffset = dragItemLeftX - (sliderTimeX - dragAssetsWidth)
  }

  /* \ Only for multiple drag assets!!!! */

  return {
    isSliderLeftSticky,
    isSliderRightSticky,
    dragItemLeftX,
    dragItemRightX,
    rightOffset,
    leftOffset,
    assetChunk,
  }
}
