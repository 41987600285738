import React, { useLayoutEffect, useRef, useState } from 'react'
import { useSprings, a, config } from '@react-spring/web'

type OpacityProps = {
  items: Array<Item>,
  index: number,
  delay?: number,
  duration?: number,
  className?: string,
}

type Item = number | string | React.ReactNode

function OpacityTransition({ items, index, delay = 0, duration = 1000, className = '' }: OpacityProps) {
  const [ swap, setSwap ] = useState(false)
  const opacityRef = useRef([ 0, 1 ])

  useLayoutEffect(() => {
    setSwap(x => !x)
  }, [ index ])

  const pair: [Item, Item] = swap ? [ items[index], items[index + 1] ] : [ items[index + 1], items[index] ]

  const [ springs ] = useSprings(pair.length, (index, ctrl) => {
    const { opacity } = ctrl.springs

    let to = {} as { opacity: number }

    if (opacity === undefined) {
      to = { opacity: opacityRef.current[index] }
    } else {
      to = { opacity: opacityRef.current[index] === 0 ? 1 : 0 }
      opacityRef.current[index] = to.opacity
    }

    return {
      config: { ...config.slow, duration },
      delay,
      from: { opacity: index === 0 ? 1 : 0 },
      to,
    }
  }, [ index ])

  return (
    <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
      {
        springs.map((style, index) => (
          <a.div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={style}
            className={className}
          >
            {pair[index]}
          </a.div>
        ))
      }
    </div>
  )
}

export default OpacityTransition
