import React from 'react'
import { isPlainObject } from 'lodash'
import { shallowEqual } from '~/Util'

const simpleEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

/**
 * Shorthand for effects that has to executed just once,
 * without need to bother of dependencies list.
 *
 * @param {function} fn
 */
export function useOnMount(fn) {
  // always empty dependencies list, so effect will never ever be executed again
  React.useEffect(fn, [])
}

/**
 * @template T
 * @param {T} value
 * @param {function(any, any): boolean} eq
 * @returns {T}
 */
export function useChanged(value, eq = shallowEqual) {
  const ref = React.useRef(value)
  if (!eq(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

/**
 * @template T
 * @param {T} value
 * @param {function(T, T): void} callback
 * @param {object} [options]
 * @param {function} [options.eq=(a, b)=>a===b] equality function
 * @param {boolean} [options.initial=false] Whether to call callback once with *current* value, immediately on mount.
 */
export function useOnChange(
  value,
  callback,
  options = {}
) {
  const {
    eq = simpleEqual,
    initial = false,
  } = options

  const previous = React.useRef(value)

  React.useEffect(() => {
    if (!eq(value, previous.current)) {
      callback(value, previous.current)
      previous.current = value
    }
  })

  useOnMount(() => {
    if (initial) {
      callback(value, previous.current)
    }
  })
}

/**
 * Shorthand for `useOnChange(useChanged({ foo, bar }))`, to track multiple dependencies at once.
 *
 * @template {Array|Object} T
 * @param {T} values
 * @param {function(T, T, Object<String, Boolean>?|Array<Boolean>?): void} callback
 * @param {object} [options]
 * @param {function} [options.eq]
 * @param {boolean} [options.initial]
 */
export function useOnChangeMany(values, callback, options = {}) {
  if (!(Array.isArray(values) || isPlainObject(values))) {
    throw new Error('useOnChangeMany hook only accepts array or plain object values')
  }

  const { eq, initial } = options

  useOnChange(
    useChanged(values, eq),
    // eslint-disable-next-line no-shadow
    (values, prev) => {
      // don't calc diff unless callback explicitly requires it:
      const doesNeedChangesInfo = callback.length > 2
      if (doesNeedChangesInfo) {
        callback(values, prev, calcHasChanges(values, prev))
      } else {
        callback(values, prev)
      }
    },
    { initial }
  )
}

function calcHasChanges(values, prevValues) {
  const allKeys = Object.keys(values).concat(Object.keys(prevValues))
  const uniqKeys = Array.from(new Set(allKeys))
  return uniqKeys.reduce(
    (m, k) => {
      // eslint-disable-next-line no-param-reassign
      m[k] = values[k] !== prevValues[k]
      return m
    },
    Array.isArray(values) ? [] : {}
  )
}

/**
 * @template T, K
 * @param {T} value
 * @param {function(T, T, boolean): K} calcDiff
 * @return {K}
 */
export function useDiff(value, calcDiff) {
  const refIsInitial = React.useRef(true)
  useOnMount(() => {
    refIsInitial.current = false
  })
  const prev = useLatest(value)
  return calcDiff(value, prev, refIsInitial.current)
}

/**
 * @template T
 * @param {T} value
 * @returns {T}
 */
export function useLatestRef(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref
}

/**
 * @template T
 * @param {T} value
 * @returns {React.MutableRefObject<T>}
 */
export function useLatest(value) {
  return useLatestRef(value).current
}

// ---

/**
 * @template T
 * @param {T} value
 * @returns {T}
 */
export function useConst(value) {
  return React.useRef(value).current
}

/**
 * @template T
 * @param {T} value
 * @return {React.MutableRefObject<T>}
 */
export function useStatic(value) {
  const ref = React.useRef()
  ref.current = value
  return ref
}
