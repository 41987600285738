import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getSelectedAssets, getLayers, getAssets } from '~/selectors'
import { TransitionAsset } from '~/models/Asset'

export const useSelectedAssets = () => {
  const selectedAssetsCoords = useRef({})
  const selectedAssets = useSelector(getSelectedAssets)
  const allAssets = useSelector(getAssets)
  const existsLayers = useSelector(getLayers)
  const allTransitions = useSelector(state => state.layer
    .assets.filter(el => el instanceof TransitionAsset))

  return useMemo(() => {
    const selectedAssetsByLayerId = {}
    const selectedAssetsByLayerIndex = {}
    const dragTransitionIds = []
    const selectedAssetsNotTransition = selectedAssets
      .filter(el => !(el instanceof TransitionAsset))

    const selectedAssetsData = selectedAssetsNotTransition
      .map(asset => {
        const layerIndex = existsLayers.reduce((result, layer, index) => {
          if (layer.id === asset.layerId) {
            // eslint-disable-next-line no-param-reassign
            result = index
          }
          return result
        }, null)

        const assetTransitions = []
        const left = allTransitions.find(transition => transition?.isAttachedTo(asset.id, 'left'))
        const right = allTransitions.find(transition => transition?.isAttachedTo(asset.id, 'right'))

        assetTransitions.push(left, right)
        dragTransitionIds.push(left?.id, right?.id)

        selectedAssetsByLayerId[asset.layerId] = [
          ...(selectedAssetsByLayerId[asset.layerId] ?? []), asset ]
        selectedAssetsByLayerIndex[layerIndex] = [
          ...(selectedAssetsByLayerIndex[layerIndex] ?? []), asset ]
        return {
          id: asset.id,
          type: asset.type,
          layerId: asset.layerId,
          newLayerId: null,
          startTime: asset.startTime,
          duration: asset.duration,
          layerIndex,
          transition: assetTransitions,
        }
      })

    return {
      selectedAssetsData,
      selectedAssetsCoords: selectedAssetsCoords.current,
      selectedAssetsByLayerId,
      selectedAssetsByLayerIndex,
      selectedAssetsIds: selectedAssetsData.map(asset => asset.id),
      dragTransitionIds: Array.from(new Set(dragTransitionIds)).filter(Boolean),
      allTransitions,
      allAssets,
    }
  }, [ allTransitions, allAssets, existsLayers, selectedAssets ])
}
