import Konva from 'konva'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLayerIndexById } from 'selectors'
import { useChromaKey } from '../useChromaKey'
import { Overlay } from './Overlay'

export const VideoAssetOverlay = props => {
  const { asset, isComposed, composedAsset, onUpdateOverlay, layerRef, transition,
    boundingRect, dragBoundFunc, hidden, params, selecting, videoRefs, size } = props

  const layerIndex = useSelector(state => getLayerIndexById(state, asset.layerId))

  const { settings } = asset
  // NOTE: Image shape is group includes image (for outline size considering)
  const [ shape, setShape ] = useState(null)
  const [ composedShape, setComposedShape ] = useState(null)

  let { width, height } = size

  width *= params.scale.x
  height *= params.scale.y

  useEffect(() => {
    setShape(new Konva.Image({ width, height }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset, boundingRect, params.scale ])

  useEffect(() => {
    if (composedAsset) {
      setComposedShape(new Konva.Image({ width, height }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ composedAsset, boundingRect, params.scale ])

  const isChromaHidden = false // isColorSelectionEnabled

  const chromaKeyCanvas = useChromaKey({
    style: {
      visibility: isChromaHidden ? 'hidden' : 'visible',
      ...(isChromaHidden ? { zIndex: 0 } : {}),
    },
    key: `chroma-${asset.id}`,
    asset,
    width,
    height,
    layerIndex,
    videoRef: videoRefs?.[asset.id],
  })

  const composedChromaKeyCanvas = useChromaKey({
    style: {
      visibility: isChromaHidden ? 'hidden' : 'visible',
      ...(isChromaHidden ? { zIndex: 0 } : {}),
    },
    key: `chroma-${asset.id}`,
    asset: composedAsset,
    width,
    height,
    layerIndex,
    videoRef: videoRefs?.[composedAsset?.id],
  })

  return (
    <If condition={shape}>
      <Overlay
        {...props}
        keepRatio={settings.keepAspectRatio}
        onUpdateOverlay={onUpdateOverlay}
        originalSize={asset.settings.originalSize}
        layerRef={layerRef}
        assetId={asset.id}
        shape={shape}
        transition={transition}
        isComposed={isComposed}
        composedAsset={composedAsset}
        composedCanvas={composedChromaKeyCanvas}
        composedShape={composedShape}
        flip={settings.flip}
        rotation={settings.rotation}
        offset={settings.offset}
        boundingRect={boundingRect}
        dragBoundFunc={dragBoundFunc}
        hidden={hidden}
        editing={selecting}
        scale={params.scale}
        videoRefs={videoRefs}
        canvas={chromaKeyCanvas}
      />
    </If>
  )
}
