// layer actions
export const ASSET_ADDED_TO_TIMELINE = 'ASSET_ADDED_TO_TIMELINE'
export const ASSET_MOVED_ON_TIMELINE = 'ASSET_MOVED_ON_TIMELINE'
export const MULTIPLE_ASSETS_MOVED_ON_TIMELINE = 'MULTIPLE_ASSETS_MOVED_ON_TIMELINE'
export const EMPTY_LAYER_AREA_SELECTED = 'EMPTY_LAYER_AREA_SELECTED'
export const CLEAR_ASSETS_SELECTION = 'CLEAR_ASSETS_SELECTION'
export const ASSET_SETTINGS_FORM_UPDATED = 'ASSET_SETTINGS_FORM_UPDATED'
export const ASSET_PREVIEW_UPDATED = 'ASSET_PREVIEW_UPDATED'
export const STOP_ASSET_PREVIEW_UPDATING = 'STOP_ASSET_PREVIEW_UPDATING'
export const ASSET_SELECTED = 'ASSET_SELECTED'
export const TOGGLE_SELECTED_ASSETS = 'TOGGLE_SELECTED_ASSETS'
export const ASSET_RESET_HAS_CHANGES = 'ASSET_RESET_HAS_CHANGES'
export const TEMP_ASSET_ADDED_TO_TIMELINE = 'TEMP_ASSET_ADDED_TO_TIMELINE'
export const SHIFT_ITEMS_BY_TIME = 'SHIFT_ITEMS_BY_TIME'
export const LAYER_ASSETS_REHYDRATE = 'LAYER_ASSETS_REHYDRATE'
export const SET_HLS_PLAYER_DATA = 'LAYER_ASSETS_REHYDRATE'
export const ASSETS_COPY = 'ASSETS_COPY'
export const COPIED_ASSET_ADDED_TO_TIMELINE = 'COPIED_ASSET_ADDED_TO_TIMELINE'
export const ASSETS_PASTE = 'ASSETS_PASTE'
export const ASSETS_HAS_SIDE_EFFECTS = 'ASSETS_HAS_SIDE_EFFECTS'
export const SET_DND_PATCH_ASSETS = 'SET_DND_PATCH_ASSETS'
export const RESET_DND_PATCH_ASSETS = 'RESET_DND_PATCH_ASSETS'

// transitions
export const TRANSITION_ADDED_TO_TIMELINE = 'TRANSITION_ADDED_TO_TIMELINE'
export const TRANSITION_SELECTED = 'TRANSITION_SELECTED'
export const TRANSITION_SET_START_TIME = 'TRANSITION_SET_START_TIME'
export const UNDO_CREATE_TRANSITION = 'UNDO_CREATE_TRANSITION'
export const REDO_CREATE_TRANSITION = 'REDO_CREATE_TRANSITION'
export const UNDO_DELETE_TRANSITION = 'UNDO_DELETE_TRANSITION'

// timeline actions
export const SET_TIMELINE_ASSET_IDS = 'SET_TIMELINE_ASSET_IDS'
export const SET_IS_SLIDER_ON_BUFFERING_POS = 'SET_IS_SLIDER_ON_BUFFERING_POS'
export const UPDATE_SLIDER = 'UPDATE_SLIDER'
export const INSERT_LAYER = 'INSERT_LAYER'
export const INSERT_LAYERS = 'INSERT_LAYERS'
export const LAYER_REMOVED = 'LAYER_REMOVED'
export const LAYER_MUTED = 'LAYER_MUTED'
export const LAYER_REMOVE_ALL = 'LAYER_REMOVE_ALL'
export const LAYER_CHANGE_POSITION = 'LAYER_CHANGE_POSITION'
export const LAYER_TOGGLE_VISIBLE = 'LAYER_TOGGLE_VISIBLE'
export const SPLIT = 'SPLIT'
export const MULTIPLE_SPLIT = 'MULTIPLE_SPLIT'
export const UNDO_MULTIPLE_SPLIT = 'UNDO_MULTIPLE_SPLIT'
export const RIPPLE_TRIM = 'RIPPLE_TRIM'
export const UNDO_RIPPLE_TRIM = 'UNDO_RIPPLE_TRIM'
export const DELETE_ASSETS = 'DELETE_ASSETS'
export const RIPPLE_DELETE_ASSETS = 'RIPPLE_DELETE_ASSETS'
export const SET_TIMELINE_SCALE = 'SET_TIMELINE_SCALE'
export const SET_TIMELINE_DURATION = 'SET_TIMELINE_DURATION'
export const DRAG_OVER_TIMELINE_STARTED = 'DRAG_OVER_TIMELINE_STARTED'
export const TIMELINE_REWINDED = 'TIMELINE_REWINDED'
export const TIMELINE_RECEIVED_FOCUS = 'TIMELINE_RECEIVED_FOCUS'
export const TIMELINE_CLEARED = 'TIMELINE_CLEARED'
export const TIMELINE_SCROLL_LEFT = 'TIMELINE_SCROLL_LEFT'
export const TIMELINE_DRAG_ASSET = 'TIMELINE_DRAG_ASSET'
export const TIMELINE_DRAG_END_ASSET = 'TIMELINE_DRAG_END_ASSET'
export const TIMELINE_DRAG_LAYER = 'TIMELINE_DRAG_LAYER'
export const TIMELINE_DRAG_END_LAYER = 'TIMELINE_DRAG_END_LAYER'
export const TIMELINE_SET_SLIDER_TIME = 'TIMELINE_SET_SLIDER_TIME'
export const DELETE_TRANSITIONS = 'DELETE_TRANSITIONS'
export const DELETE_PLACEHOLDERS = 'DELETE_PLACEHOLDERS'
export const FORCE_DELETE_LAYER = 'FORCE_DELETE_LAYER'
export const DELETE_TEMP_ASSETS = 'DELETE_TEMP_ASSETS'
export const SELECT_CLIPS_AT_CURRENT_POSITION = 'SELECT_CLIPS_AT_CURRENT_POSITION'
export const SET_IS_MOVING_SLIDER = 'SET_IS_MOVING_SLIDER'
export const UNDO_MULTIPLE_MOVE_ASSETS = 'UNDO_MULTIPLE_MOVE_ASSETS'

// project actions
export const ADD_EDITING_TASK = 'ADD_EDITING_TASK'
export const PREPARE_EDITING_TASK = 'PREPARE_EDITING_TASK'
export const DELETE_EDITING_TASK = 'DELETE_EDITING_TASK'
export const UPDATE_EDITING_TASK_STATUS = 'UPDATE_EDITING_TASK_STATUS'
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK'
export const SET_REFERENCE = 'SET_REFERENCE'

// playback actions
export const CHANGE_PLAYBACK_STATE = 'CHANGE_PLAYBACK_STATE'
export const CHANGE_PREVIEW_STATE = 'CHANGE_PREVIEW_STATE'
export const SET_PLAYBACK_MEDIA = 'SET_PLAYBACK_MEDIA'

// user actions
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const PATCH_USER_PROFILE = 'PATCH_USER_PROFILE'
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME'

// main view
export const SET_EDITION = 'SET_EDITION'
export const OPEN_CHAPTER = 'OPEN_CHAPTER'
export const SAVE_SETTINGS_VISIBILITY_CHANGED = 'SAVE_SETTINGS_VISIBILITY_CHANGED'
export const CUSTOMIZE_VIEW_VISIBILITY_CHANGED = 'CUSTOMIZE_VIEW_VISIBILITY_CHANGED'
export const IMPORT_FROM_URL_VISIBILITY_CHANGED = 'IMPORT_FROM_URL_VISIBILITY_CHANGED'
export const AMAZON_IMPORT_VISIBILITY_CHANGED = 'AMAZON_IMPORT_VISIBILITY_CHANGED'
export const LOCAL_FILE_DRAGGING = 'LOCAL_FILE_DRAGGING'
export const ACTIVE_ASSET_ROTATION = 'ACTIVE_ASSET_ROTATION'
export const SET_PLAYER_VOLUME = 'SET_PLAYER_VOLUME'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_IS_OFFLINE = 'SET_IS_OFFLINE'
export const RESET_MAIN_VIEW = 'RESET_MAIN_VIEW'
export const SET_DND_DROP_TARGET = 'SET_DND_DROP_TARGET'
// mainView features
export const MEDIA_RECORDING_INIT = 'MEDIA_RECORDING_INIT'
export const MEDIA_RECORDING_CANCELED = 'MEDIA_RECORDING_CANCELED'
export const CLOSE_FEATURES = 'CLOSE_FEATURES'
export const OFFLINE_RESTORATION_START = 'OFFLINE_RESTORATION_START'
export const OFFLINE_RESTORATION_END = 'OFFLINE_RESTORATION_END'

// TODO: alexey, check if it still necessary after merge with master.
// transition
export const TRANSITION_REATTATCH = 'TRANSITION_REATTATCH'
export const TRANSITION_ATTACHED_TO_ASSET = 'TRANSITION_ATTACHED_TO_ASSET'

// Source files
export const SOURCE_FILES_SET_PARAMS = 'SOURCE_FILES_SET_PARAMS'
export const SOURCE_FILES_SET_PARAMS_NO_RELOAD = 'SOURCE_FILES_SET_PARAMS_NO_RELOAD'
export const SOURCE_FILES_SET_UI_PARAMS = 'SOURCE_FILES_SET_UI_PARAMS'
export const SOURCE_FILES_REQUESTED = 'SOURCE_FILES_REQUESTED'
export const SOURCE_FILES_LOADED = 'SOURCE_FILES_LOADED'
export const SOURCE_FILE_DELETED = 'SOURCE_FILE_DELETED'
export const SOURCE_FILE_UPDATED = 'SOURCE_FILE_UPDATED'
export const SOURCE_FILE_CREATED = 'SOURCE_FILE_CREATED'
export const SOURCE_FILE_STATUS_UPDATED = 'SOURCE_FILE_STATUS_UPDATED'
export const SOURCE_FILES_MOVED = 'SOURCE_FILES_MOVED'
export const CHANGED_PREVIEW_VIDEO_SIZE = 'CHANGED_PREVIEW_VIDEO_SIZE'

export const SET_HOTKEY = 'SET_HOTKEY'
export const ADD_HOTKEY_PROFILE = 'ADD_HOTKEY_PROFILE'
export const DELETE_HOTKEY_PROFILE = 'DELETE_HOTKEY_PROFILE'
export const SEARCH_HOTKEY = 'SEARCH_HOTKEY'
export const RESET_PROFILE_TO_DEFAULTS = 'RESET_PROFILE_TO_DEFAULTS'
export const RENAME_HOTKEY_PROFILE = 'RENAME_HOTKEY_PROFILE'
export const SET_ACTIVE_HOTKEY_PROFILE = 'SET_ACTIVE_HOTKEY_PROFILE'

// Preview actions
export const SET_PREVIEW_PARAMS = 'SET_PREVIEW_PARAMS'

// Asset settings
export const ASSET_EDIT_STARTED = 'ASSET_EDIT_STARTED'
export const ASSET_EDIT_SUBMITTED = 'ASSET_EDIT_SUBMITTED'
export const ASSET_EDIT_CANCELED = 'ASSET_EDIT_CANCELED'
export const ASSET_CHANGE_DURATION = 'ASSET_CHANGE_DURATION'
export const SHIFT_ITEMS_BY_ASSET = 'SHIFT_ITEMS_BY_ASSET'

// Projects
export const PROJECTS_REQUESTED = 'PROJECTS_REQUESTED'
export const PROJECTS_ALLOW_SAVE_EMPTY = 'PROJECTS_ALLOW_SAVE_EMPTY'
export const PROJECTS_SET_LATEST_SAVED_TIME = 'PROJECTS_SET_LATEST_SAVED_TIME'
export const PROJECTS_SHOW_BLANK_IN_LIST = 'PROJECTS_SHOW_BLANK_IN_LIST'
export const PROJECTS_HIDE_BLANK_IN_LIST = 'PROJECTS_HIDE_BLANK_IN_LIST'
export const PROJECTS_SET_RENAMING_ID_IN_LIST = 'PROJECTS_SET_RENAMING_ID_IN_LIST'
export const PROJECTS_SET_SEARCH_VALUE = 'PROJECTS_SET_SEARCH_VALUE'
export const PROJECTS_LOADED = 'PROJECTS_LOADED'
export const PROJECTS_ACTIVATE = 'PROJECTS_ACTIVATE'
export const PROJECTS_SET_ACTIVE_NAME = 'PROJECTS_SET_ACTIVE_NAME'
export const PROJECTS_RENAME = 'PROJECTS_RENAME'
export const PROJECTS_REFRESH_THUMBNAIL = 'PROJECTS_REFRESH_THUMBNAIL'
export const PROJECTS_CREATE = 'PROJECTS_CREATE'
export const PROJECTS_DELETE = 'PROJECTS_DELETE'
export const PROJECTS_START_PROJECT_LOAING = 'PROJECTS_START_PROJECT_LOAING'
export const PROJECTS_CLEAR_ERROR = 'PROJECTS_CLEAR_ERROR'
export const PROJECTS_ACTIVE_PROJECT_ERROR = 'PROJECTS_ACTIVE_PROJECT_ERROR'
export const PROJECTS_END_PROJECT_LOAING = 'PROJECTS_END_PROJECT_LOAING'
export const PROJECTS_START_SAVING = 'PROJECTS_START_SAVING'
export const PROJECTS_END_SAVING = 'PROJECTS_END_SAVING'
export const LOAD_PROJECT = 'LOAD_PROJECT'

// Actions History (unod-redo)
export const ACTIONS_UNDO = 'ACTIONS_UNDO'
export const ACTIONS_REDO = 'ACTIONS_REDO'
export const ACTIONS_ADD_HISTORY_ACTION = 'ACTIONS_ADD_HISTORY_ACTION'
export const ACTIONS_CLEAR_ACTIONS_HISTORY = 'ACTIONS_CLEAR_ACTIONS_HISTORY'
export const ACTIONS_UNLOCK_ACTIONS_HISTORY = 'ACTIONS_UNLOCK_ACTIONS_HISTORY'
export const ACTIONS_PATCH_HISTORY_ACTION = 'ACTIONS_PATCH_HISTORY_ACTION'
export const ACTIONS_CLEAR_IGNORE_HISTORY = 'ACTIONS_CLEAR_IGNORE_HISTORY'
export const ACTIONS_HISTORY_ERROR = 'ACTIONS_HISTORY_ERROR'
export const ACTIONS_SET_BLOCK_ADD_HISTORY_ACTION = 'ACTIONS_SET_BLOCK_ADD_HISTORY_ACTION'

// Source files directory
export const ACTIONS_MEDIA_DIRECTORY_OPEN = 'ACTIONS_MEDIA_DIRECTORY_OPEN'
export const ACTIONS_MEDIA_DIRECTORY_FORWARD = 'ACTIONS_MEDIA_DIRECTORY_FORWARD'
export const ACTIONS_MEDIA_DIRECTORY_BACKWARD = 'ACTIONS_MEDIA_DIRECTORY_BACKWARD'
export const MEDIA_FOLDER_CREATE = 'MEDIA_FOLDER_CREATE'
export const MEDIA_FOLDER_RENAME = 'MEDIA_FOLDER_RENAME'

export const SET_INSTALL_VERSION = 'SET_INSTALL_VERSION'
