/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnalyticsService } from './AnalyticsService'

export const RoutingAnalytics = ({ basename, debug, trackPathnameOnly, children }) => {
  const [ lastPathName, setLastPathName ] = useState('')

  const history = useHistory()

  function sendPageView(location) {
    if (trackPathnameOnly && location.pathname === lastPathName) {
      return
    }

    setLastPathName(location.pathname)

    const page = basename ? `${basename}${location.pathname}` : location.pathname

    AnalyticsService.gaSetPage(page)

    AnalyticsService.gaSendPageView()

    if (debug) {
      console.info(`[react-router-ga] Page view: ${page}`)
    }
  }

  function initialize() {
    if (typeof window === 'undefined') {
      return
    }

    AnalyticsService.gaSendPageView()
  }

  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    history.listen(location => {
      sendPageView(location)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ history ])

  return <>{children}</>
}
