import Asset, { PlaceholderAsset, TransitionAsset, TextAsset } from '~/models/Asset'
import * as API from '~/ServerAPI'
import { fetchAudioFile } from '~/services/AudioLibraryService'
import { SOURCE_FILE_TYPES } from '~/enums'
import NotFoundMediaImage from '~/assets/projects/not_found_media.png'

export const getAssetMediaThumbnailFromBackend = async asset => {
  const { fileId } = asset

  if (!(asset instanceof TransitionAsset
    || asset instanceof TextAsset
    || asset instanceof PlaceholderAsset)
    && asset instanceof Asset) {
    if (fileId) {
      try {
        const { type } = asset
        const getMedia = asset.sourceFileType === SOURCE_FILE_TYPES.AUDIO
          ? fetchAudioFile
          : API.getMediaFile
        const { thumbnail } = (await getMedia(
          fileId, type, asset.sourceFileType
        )).data
        return thumbnail
      } catch (e) {
        console.error(e)
      }
    }
  }

  return NotFoundMediaImage
}
