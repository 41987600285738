import { RESTORED_ASSET_PROPERTIES } from '~/config/constants/asset'
import { TransitionAsset } from '~/models/Asset'

/**
 * @param {Asset} currentAsset
 * @param {Asset[]} layerAssets
 */
export const getRelatedAssetsParams = (asset, layerAssets) => {
  const currentAssets = [].concat(asset)
  const resultRelatedAssets = []
  currentAssets.forEach(currentAsset => {
    switch (true) {
      case currentAsset instanceof TransitionAsset: {
        const relatedAssets = currentAsset.relatedAssets(layerAssets)
        relatedAssets.forEach(asset => {
          if (!resultRelatedAssets.find(a => a.id === asset.id)) {
            const savedParams = { id: asset.id }
            RESTORED_ASSET_PROPERTIES.forEach(prop => {
              savedParams[prop] = asset[prop]
            })
            resultRelatedAssets.push(savedParams)
          }
        })
      }
        break
      default: break
    }
  })
  return resultRelatedAssets
}
