export const getLayerRippleTime = (layerId, assets, currentTime, direction) => {
  if (direction === 'next') {
    const filtredAssets = assets
      .filter(a => a.layerId === layerId && currentTime < a.endTime)
      .sort((a, b) => a.startTime - b.startTime)
    const [ leftAsset, rightAsset ] = filtredAssets

    if (filtredAssets.length) {
      if (rightAsset && rightAsset.startTime < leftAsset.endTime) {
        if (rightAsset.startTime > currentTime) {
          return rightAsset.startTime
        }
      }

      return leftAsset.startTime >= currentTime ? leftAsset.startTime : leftAsset.endTime
    }

    return currentTime
  }

  const filtredAssets = assets
    .filter(a => a.layerId === layerId && a.startTime < currentTime)
    .sort((a, b) => b.startTime - a.startTime)
  const [ rightAsset, leftAsset ] = filtredAssets

  if (filtredAssets.length) {
    if (leftAsset && leftAsset.endTime > rightAsset.startTime) {
      if (leftAsset.endTime < currentTime) {
        return leftAsset.endTime
      }
    }

    return rightAsset.endTime <= currentTime ? rightAsset.endTime : rightAsset.startTime
  }

  return currentTime
}
