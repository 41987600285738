import React, { useContext } from 'react'

import HeadControlPanel from 'components/HeadControlPanel'

import { SearchInput } from 'components/base/SearchInput/SearchInput'
import RawButton from 'components/base/RawButton'

import { ReactComponent as FilterIcon } from 'assets/media/ic_filter.svg'
import { ReactComponent as MenuIcon } from 'assets/media/ic_menu.svg'
import { TranslationContext } from 'contexts/TranslationContext'

type Props = {
  caption: string,
}

const noop = () => {}

const showHeader = __APP_PROFILE__ !== 'vrspot' && __APP_PROFILE__ !== 'public' && __APP_PROFILE__ !== 'desktop'

function DummyHeader({ caption }: Props) {
  const { t } = useContext(TranslationContext)
  return (
    showHeader
      ? (
        <HeadControlPanel caption={caption}>
          {__CFG__.SOURCE_FILES_MANAGEMENT.SEARCH && <SearchInput onSearch={noop} />}

          {__CFG__.SOURCE_FILES_MANAGEMENT.FILTER && (
          <div className="head-control__btn" title={t('DUMMY_HEADER_BTN_FILTERS_TOOLTIP')}>
            <RawButton>
              <FilterIcon />
            </RawButton>
          </div>
          )}

          {__CFG__.SOURCE_FILES_MANAGEMENT.VIEW_OPTIONS
          && (
          <div className="head-control__btn" title={t('DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP')}>
            <RawButton>
              <MenuIcon />
            </RawButton>
          </div>
          )}
        </HeadControlPanel>
      )
      : <HeadControlPanel caption={caption} />
  )
}

export { DummyHeader }
