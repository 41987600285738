import versionInfo from 'version.json'

export function formatVersionMajorMinor(version) {
  // 'major_version.minor_version'
  const versionNums = version ? version.split('.') : []
  const formatted = versionNums.length > 2 ? `${versionNums[0]}.${versionNums[1]}` : ''
  return formatted
}

export function frontendVersion() {
  const { date: isoDateStr } = versionInfo
  const [ date ] = isoDateStr.split(' ')
  const [ year, month, day ] = date.split('-')
  const versionDate = `${year.slice(2)}${month}.${day}`
  return `${formatVersionMajorMinor(versionInfo.version)}.${versionDate}`
}
