import React from 'react'
import cx from 'classnames'

import { XIcon } from '~/components/base/XIcon/XIcon'
import './InfoView.scss'

export default function InfoView({ onHide = () => { }, children, header = '', className = '' }) {
  return (
    <div className={cx('info-view', className)}>
      <div className="info-view__title">
        <span>{header}</span>

        <XIcon onClick={onHide} />
      </div>
      {children}
    </div>
  )
}
