/* eslint-disable no-param-reassign */
import { CHROMA_MAX_OPACITY } from 'constant'
import { VideoAsset } from 'models/Asset'
import React, { useRef, useEffect, useState } from 'react'

// https://jameshfisher.com/2020/08/11/production-ready-green-screen-in-the-browser/

const fragmentShaderRaw = `
precision mediump float;

uniform sampler2D tex;
uniform float texWidth;
uniform float texHeight;

uniform vec3 keyColor;
uniform float similarity;
uniform float smoothness;
uniform float spill;
uniform float opacity;

const mat4 yuv_mat = mat4( 0.182586,  0.614231,  0.062007, 0.062745,
  -0.100644, -0.338572,  0.439216, 0.501961,
   0.439216, -0.398942, -0.040274, 0.501961,
   0.000000,  0.000000,  0.000000, 1.000000);

vec2 RGB2UV(vec3 rgb){
    vec4 yuvx = vec4(rgb, 1.0)*yuv_mat;
    return yuvx.yz;
}
vec4 sampleTexture(vec2 uv) {
  return texture2D(tex, uv);
}

float getChromaDist(vec3 rgb) {
    return distance(RGB2UV(keyColor), RGB2UV(rgb));
}

float getBoxFilteredChromaDist(vec3 rgb, vec2 texCoord) {
    vec2 pixel_size = vec2(1.0 / texWidth, 1.0 / texHeight);
    vec2 h_pixel_size = pixel_size / 4.0;
    vec2 point_0 = vec2(pixel_size.x, h_pixel_size.y);
    vec2 point_1 = vec2(h_pixel_size.x, -pixel_size.y);
    float distVal = getChromaDist(sampleTexture(texCoord-point_0).rgb);
    distVal += getChromaDist(sampleTexture(texCoord+point_0).rgb);
    distVal += getChromaDist(sampleTexture(texCoord-point_1).rgb);
    distVal += getChromaDist(sampleTexture(texCoord+point_1).rgb);
    distVal *= 2.0;
    distVal += getChromaDist(rgb);
    return distVal / 9.0;
}

vec4 ProcessChromaKey(vec4 rgba, vec2 texCoord) {
  float chromaDist = getBoxFilteredChromaDist(rgba.rgb, texCoord);

  float baseMask = chromaDist - similarity;
  float fullMask = pow(clamp(baseMask / smoothness, 0., 1.), 1.5);
  float spillVal = pow(clamp(baseMask / spill, 0., 1.), 1.5);

  rgba.a *= opacity;
  rgba.a *= fullMask;

  float desat = (rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722);
  rgba.rgb = clamp(vec3(desat, desat, desat),0., 1.) * (1.0 - spillVal) + rgba.rgb * spillVal;

  return rgba;
}

void main(void) {
  vec2 texCoord = vec2(gl_FragCoord.x/texWidth, 1.0 - (gl_FragCoord.y/texHeight));
  vec4 rgba = sampleTexture(texCoord);
  rgba.rgb = max(vec3(0.0, 0.0, 0.0), rgba.rgb / rgba.a);
  gl_FragColor = ProcessChromaKey(rgba, texCoord);
}
`

function init(gl: WebGLRenderingContext) {
  const vs = gl.createShader(gl.VERTEX_SHADER)
  if (!vs) throw new Error('Could not create VERTEX_SHADER')
  gl.shaderSource(
    vs,
    'attribute vec2 c; void main(void) { gl_Position=vec4(c, 0.0, 1.0); }'
  )
  gl.compileShader(vs)

  const fs = gl.createShader(gl.FRAGMENT_SHADER)
  if (!fs) throw new Error('Could not create FRAGMENT_SHADER')
  gl.shaderSource(fs, fragmentShaderRaw)
  gl.compileShader(fs)
  if (!gl.getShaderParameter(fs, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(fs))
  }

  const prog = gl.createProgram()
  if (!prog) throw new Error('Could not create WebGL Program')

  gl.attachShader(prog, vs)
  gl.attachShader(prog, fs)
  gl.linkProgram(prog)
  gl.useProgram(prog)

  const vb = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, vb)
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([ -1, 1, -1, -1, 1, -1, 1, 1 ]),
    gl.STATIC_DRAW
  )

  const coordLoc = gl.getAttribLocation(prog, 'c')
  gl.vertexAttribPointer(coordLoc, 2, gl.FLOAT, false, 0, 0)
  gl.enableVertexAttribArray(coordLoc)

  gl.activeTexture(gl.TEXTURE0)
  const tex = gl.createTexture()
  gl.bindTexture(gl.TEXTURE_2D, tex)

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
  return prog
}

type WGL = {
  gl: WebGLRenderingContext;
  prog: WebGLProgram;
  stopped: boolean;
  useRequestVideoFrameCallback: boolean;
  requestVideoFrameCallbackIsAvailable: boolean;
  start: () => void;
  stop: () => void;
};

type LiveTweakableParams = {
  keycolor: readonly [number, number, number];
  similarity: number;
  smoothness: number;
  spill: number;
  opacity: number;
};

function startProcessing(
  sourceVideoEl: HTMLVideoElement,
  displayCanvasEl: HTMLCanvasElement,
  wgl: WGL,
  getConfig: () => LiveTweakableParams
) {
  const { gl, prog } = wgl

  const texLoc = gl.getUniformLocation(prog, 'tex')
  const texWidthLoc = gl.getUniformLocation(prog, 'texWidth')
  const texHeightLoc = gl.getUniformLocation(prog, 'texHeight')
  const keyColorLoc = gl.getUniformLocation(prog, 'keyColor')
  const similarityLoc = gl.getUniformLocation(prog, 'similarity')
  const smoothnessLoc = gl.getUniformLocation(prog, 'smoothness')
  const spillLoc = gl.getUniformLocation(prog, 'spill')
  const opacityLoc = gl.getUniformLocation(prog, 'opacity')

  function processFrame() {
    if (wgl.stopped) return
    if (sourceVideoEl.videoWidth !== displayCanvasEl.width) {
      displayCanvasEl.width = sourceVideoEl.videoWidth
      displayCanvasEl.height = sourceVideoEl.videoHeight
      gl.viewport(0, 0, sourceVideoEl.videoWidth, sourceVideoEl.videoHeight)
    }

    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGB,
      gl.RGB,
      gl.UNSIGNED_BYTE,
      sourceVideoEl
    )
    gl.uniform1i(texLoc, 0)
    gl.uniform1f(texWidthLoc, sourceVideoEl.videoWidth)
    gl.uniform1f(texHeightLoc, sourceVideoEl.videoHeight)

    const config = getConfig()
    gl.uniform3f(
      keyColorLoc,
      config.keycolor[0],
      config.keycolor[1],
      config.keycolor[2]
    )
    gl.uniform1f(similarityLoc, config.similarity)
    gl.uniform1f(smoothnessLoc, config.smoothness)
    gl.uniform1f(spillLoc, config.spill)
    gl.uniform1f(opacityLoc, config.opacity)
    gl.drawArrays(gl.TRIANGLE_FAN, 0, 4)

    if (wgl.stopped) return
    if (
      wgl.useRequestVideoFrameCallback
      && wgl.requestVideoFrameCallbackIsAvailable
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (sourceVideoEl as any).requestVideoFrameCallback(processFrame)
    } else {
      setTimeout(() => {
        requestAnimationFrame(processFrame)
      }, 1000 / 24)
    }
  }

  processFrame()
}

type Props = {
  asset: VideoAsset;
  layerIndex: number;
  videoRef: React.MutableRefObject<HTMLVideoElement | null> | null;
  width: number;
  height: number;
  style: React.CSSProperties;
}

export const useChromaKey = ({ asset, layerIndex, videoRef, width, height, style }: Props) => {
  const { chromaKeyColor,
    similarity,
    smoothness,
    spillReduction,
    opacity } = asset?.settings || {}

  const innerRef = useRef<HTMLVideoElement | null>(null)
  const ref = videoRef || innerRef

  const wglRef = useRef<WGL | null>(null)

  const [ canvas ] = useState(() => {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    return canvas
  })
  useEffect(() => {
    const video = ref.current

    if (!video || !canvas || !asset?.settings?.isChromaKeyEnabled) return undefined

    let hasBeenRunned = false

    if (video.readyState > 1) {
      const { requestVideoFrameCallback } = video
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      runChromaKey({ target: { requestVideoFrameCallback } } as any)
    }

    function runChromaKey(e: Event | null) {
      if (hasBeenRunned) return
      hasBeenRunned = true
      const gl = canvas.getContext('webgl', {
        premultipliedAlpha: false,
      })

      if (!gl) throw new Error('Could not initialize webgl')

      const prog = init(gl)
      const wgl: WGL = {
        gl,
        prog,
        stopped: true,
        useRequestVideoFrameCallback: true,
        requestVideoFrameCallbackIsAvailable:
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          'requestVideoFrameCallback' in (e?.target || {}),
        start: () => {
          if (!video) return

          wgl.stopped = false

          const getConfig = () => {
            const { r, g, b } = chromaKeyColor
            return {
              keycolor: [ r / 255, g / 255, b / 255 ] as const,
              similarity: similarity / 1000,
              smoothness: smoothness / 1000,
              spill: spillReduction / 1000,
              opacity: opacity / CHROMA_MAX_OPACITY,
            }
          }

          startProcessing(video, canvas, wgl, getConfig)
        },
        stop: () => {
          wgl.stopped = true
        },
      }

      wglRef.current = wgl

      wgl.start()

      // eslint-disable-next-line consistent-return
      return () => {
        wgl.stop()
        wgl.gl.deleteProgram(wgl.prog)
      }
    }

    video.addEventListener('canplay', runChromaKey)
    video.addEventListener('playing', runChromaKey)
    return () => {
      video.removeEventListener('canplay', runChromaKey)
      video.removeEventListener('playing', runChromaKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ref, chromaKeyColor, similarity, canvas, smoothness, spillReduction, opacity, asset ])

  if (!__CFG__.IS_CHROMAKEY_ENABLED || !videoRef
    || !asset?.settings?.isChromaKeyEnabled) {
    return null
  }

  return canvas
}
