import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { FILE_STATUS } from '~/enums'
import { CircularProgress } from '../../base/CircularProgress/CircularProgress'

function statusText(status, progress, endless, t) {
  switch (status) {
    case FILE_STATUS.RENDER: {
      return t('MEDIA_PROGRESS_TEXT_SAVING')
    }
    case FILE_STATUS.CONVERT: {
      return t('MEDIA_PROGRESS_TEXT_CONVERT')
    }
    case FILE_STATUS.S3_UPLOAD: {
      return t('MEDIA_PROGRESS_TEXT_TRANSFER')
    }
    case FILE_STATUS.MEDIAINFO: {
      return t('MEDIA_PROGRESS_TEXT_ANALYSIS')
    }
    case FILE_STATUS.IMPORT:
    case FILE_STATUS.UPLOAD: {
      if (endless) {
        return t('MEDIA_PROGRESS_TEXT_LOADING')
      }
      return `${progress}%`
    }
    default: {
      return ''
    }
  }
}

export default function MediaProgress({ progress, status, endless }) {
  const { t } = useContext(TranslationContext)

  return (
    <CircularProgress
      text={statusText(status, progress, endless, t)}
      size={60}
      progress={progress}
      endless={endless}
    />
  )
}
