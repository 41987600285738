import React from 'react'

import styles from './draggingGroup.module.scss'

type Props = {
  item: number,
  clientOffset: {x: number, y: number},
}

function DraggingGroup({ item, clientOffset }: Props) {
  const { x, y } = clientOffset
  return (
    <div className={styles.dummy} style={{ transform: `translate(${x}px, ${y}px)` }}>
      <div className={styles.item}>{item}</div>
    </div>
  )
}

export default DraggingGroup
