import React from 'react'

import Panel from 'components/AssetPanels/BaseAssetsPanel'
import { SOURCE_FILE_TYPES } from '~/enums'

import List from './List'

export default function AudioAssetsPanel() {
  return (
    <Panel
      type={SOURCE_FILE_TYPES.AUDIO}
      ListComponent={List}
      // SettingsComponent={Settings}
    />
  )
}
