import { getAbsoluteAssetSize } from 'helpers/getAbsoluteAssetSize'
import React from 'react'
import { useSelector } from 'react-redux'
import { getReferenceVideoAsset } from 'selectors'
import { Overlay } from './Overlay'
import { useImageOverlayItems } from './hooks/useImageOverlayItems'

export const ImageAssetOverlay = props => {
  const {
    asset,
    isComposed,
    composedAsset,
    onUpdateOverlay,
    layerRef,
    transition,
    boundingRect,
    dragBoundFunc,
    hidden,
    params,
    selecting,
  } = props
  const { settings } = asset
  const referenceAsset = useSelector(getReferenceVideoAsset)
  const { image, shape, shapeWithOutline } = useImageOverlayItems(props)
  const {
    shape: composedShape,
    // image: composedImage,
    // shapeWithOutline: composedShapeWithOutline,
  } = useImageOverlayItems({
    ...props,
    asset: composedAsset,
    size: composedAsset ? getAbsoluteAssetSize(composedAsset, referenceAsset, boundingRect) : {},
  })

  return (
    <If condition={shape}>
      <Overlay
        {...props}
        keepRatio={settings.keepAspectRatio}
        onUpdateOverlay={onUpdateOverlay}
        originalSize={asset.settings.originalSize}
        layerRef={layerRef}
        assetId={asset.id}
        shape={shape}
        shapeWithOutline={transition ? shapeWithOutline : undefined}
        transition={transition}
        isComposed={isComposed}
        composedAsset={composedAsset}
        composedShape={composedShape}
        flip={settings.flip}
        rotation={settings.rotation}
        offset={settings.offset}
        boundingRect={boundingRect}
        dragBoundFunc={dragBoundFunc}
        hidden={hidden}
        editing={selecting}
        scale={params.scale}
        image={image}
        settings={settings}
      />
    </If>
  )
}
