import { addAssetToTimeline } from '~/actions/projectData/addAssetToTimeline/'
import { moveAsset } from '~/actions/timeline'
import { shiftItemsByAssetOnDrop } from '~/actions/layer'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class AssetMovedAction extends AssetHistoryAction {

  constructor(payload, dispatch, getState) {
    const { layerId, startTime, deltaOnDrop, transitions } = payload
    super(HistoryActionType.AssetMovedAction, payload, dispatch, getState)
    this._layerId = layerId
    this._startTime = startTime
    this._deltaOnDrop = deltaOnDrop
    this._transitions = transitions
  }

  get layerId() {
    return this._layerId
  }

  set layerId(layerId) {
    this._layerId = layerId
  }

  get startTime() {
    return this._startTime
  }

  set startTime(startTime) {
    this._startTime = startTime
  }

  get transitions() {
    return this._transitions
  }

  set transitions(transitions) {
    this._transitions = transitions
  }

  get deltaOnDrop() {
    return this._deltaOnDrop
  }

  set deltaOnDrop(deltaOnDrop) {
    this._deltaOnDrop = deltaOnDrop
  }

  * undo() {
    const { asset, deltaOnDrop, layerId, startTime } = this

    const { id: assetId, startTime: assetStartTime, layerId: assetLayerId } = asset
    if (deltaOnDrop) {
      yield shiftItemsByAssetOnDrop({ id: assetId,
        layerId,
        time: startTime,
        delta: -1 * deltaOnDrop })
    }

    yield moveAsset(assetId, { startTime: assetStartTime, layerId: assetLayerId })
    // yield shiftItemsByAsset(asset, delta * -1)
    if (this.transitions) {
      for (const transition of this.transitions) {
        yield addAssetToTimeline(transition, transition.layerId)
      }
    }
  }

  * redo() {
    const { asset, startTime, layerId, deltaOnDrop } = this
    if (deltaOnDrop) {
      yield shiftItemsByAssetOnDrop({ id: asset.id,
        layerId,
        time: startTime,
        delta: deltaOnDrop })
    }

    yield moveAsset(asset.id, { startTime, layerId })
  }

  getOptimizedObject() {
    return { ...this,
      asset: this.getOptimizedAsset(({ layerId, startTime }) => ({ layerId, startTime })) }
  }

}
