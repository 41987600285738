import React from 'react'
import { MenuProps } from '@material-ui/core/Menu'

import type { Classes, ItemWithLocalization } from 'components/base/Combobox/types'
import Combobox from './Combobox'

type Props<E extends React.FunctionComponent | React.ElementType = React.ElementType> = {
  items: Array<ItemWithLocalization>,
  value: string | undefined,
  onClick?: () => void,
  classes?: Classes,
  component?: E,
  children: React.ReactNodeArray,
  openOnIconClick?: boolean,
  MenuProps?: Partial<MenuProps>
  anchorWidth?: boolean,
}

function MenuCombobox(props: Props) {
  const { items, value, children } = props

  const selectedItem = items.find(item => item.value === value)
  const selected = selectedItem?.label || items[0].label

  return (
    <Combobox {...props} selectedItem={selectedItem} selected={selected}>
      {children}
    </Combobox>
  )
}

export default MenuCombobox
