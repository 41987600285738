import React from 'react'
import cx from 'classnames'

import styles from './error.module.scss'

type Props = {
  message: string,
  className?: string,
  disabled?: boolean
}

function Error({ message = '', className = '', disabled = true }: Props) {
  return (
    <div className={cx(styles.container, {
      [styles.disabled]: disabled,
    }, className)}
    >
      {message}
    </div>
  )
}

export default Error
