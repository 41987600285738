import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { formatRBGColor } from '~/Util'
import { createImage } from '~/features/konva-shapes/createImage'

export const useImageOverlayItems = ({
  asset = {},
  boundingRect,
  params,
  size,
}) => {
  const { settings = {} } = asset

  // NOTE: Image shape is group includes image (for outline size considering)
  const [ shape, setShape ] = useState(null)
  const [ shapeWithOutline, setShapeWithOutline ] = useState(null)
  const [ image, setImage ] = useState(null)

  useEffect(() => {
    if (asset.url) {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.src = asset.url

      img.onload = () => {
        setImage(img)
      }
    }
  }, [ asset.url ])

  useEffect(() => {
    let resetSetter = false
    if (asset && !isEmpty(asset) && image) {
      createImage(image, { ...asset.settings, size }, undefined, {
        scale: params.scale,
      }).then(shape => {
        if (!resetSetter) {
          setShape(shape)
          const { outlineWidth, outlineColor } = settings
          const shapeWithOutline = shape.clone({
            stroke: formatRBGColor(outlineColor),
            strokeWidth: outlineWidth,
          })
          setShapeWithOutline(shapeWithOutline)
        }
      })
    }
    return () => {
      resetSetter = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ image, asset.settings, boundingRect, params.scale ])

  return { shape, shapeWithOutline, image }
}
