export function getItemStyles({
  clientOffset,
  x,
  y,
  height = '100%',
  width = '100%',
}) {
  if (!clientOffset) {
    return { display: 'none' }
  }

  const transform = `translate(${x}px, ${y}px)`
  return {
    transform,
    WebkitTransform: transform,
    height,
    width,
    // overflow: 'hidden',
  }
}
