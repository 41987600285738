import { TASK_STATUS } from '~/enums'

export function Status2Text(status, error, t) {
  switch (status) {
    case TASK_STATUS.NEW:
      return `${t('COMMON_STATUS_TEXT_LOADING')}...`
    case TASK_STATUS.RUNNING:
      return `${t('COMMON_STATUS_TEXT_RENDERING')}...`
    case TASK_STATUS.DOWNLOADING:
      return `${t('COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES')}...`
    case TASK_STATUS.UPLOADING:
      return `${t('COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA')}...`
    case TASK_STATUS.ABORTED: {
      return `${t('COMMON_STATUS_TEXT_ABORTED')}: ${error}`
    }
    default:
      return ''
  }
}
