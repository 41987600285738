import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { MaskedRange } from 'imask'

import FormControlLabel from '@material-ui/core/FormControlLabel'

import NumberInput from 'components/base/NumberInput'
import MaskedInput from 'components/base/MaskedInput'

import './TransitionDurationField.scss'

import { TIMELINE_TIME_UNIT } from '~/Util'

import styles from './transition.module.scss'

// ---

/**
 * Design doc:
 * @see {https://docs.google.com/document/d/1vsdg-XX2805oFctMGgXkvJPQAes0kQJLksRKMBejKHg/edit?ts=5e997f22#bookmark=id.fb1u86n3n1rh}
 */

const maskBlocks = {
  SEC: {
    mask: MaskedRange,
    from: 0,
    // accordingly to design, it's 2-positional seconds input
    // so max is 59
    to: 5,
  },

  MSEC: {
    mask: MaskedRange,
    from: 0,
    // accordingly to design, it's 2-positional input, representing tens of milliseseconds
    // so max is 99 (990ms)
    to: 99,
  },
}

const MSEC_STEP = 100000
const MIN_TRANSITION_DURATION = 2500000
const MAX_TRANSITION_DURATION = 50000000
const START_CHAR_POSITION_SELECT = 0
const END_CHAR_POSITION_SELECT = 4
const MASK = 'SEC.MSEC sec'
/**
 * @param {number} value milliseconds
 * @returns {string}
 */
function formatValue(value) {
  const sec = ((value ?? 0) / TIMELINE_TIME_UNIT).toFixed(2)
  const data = sec.split(',').join('.')
  if (data[0] === maskBlocks.SEC.to && data[1] > 0) {
    return `${data[0]}.00 sec`
  }
  return `${data} sec`
}

/**
 * @param {string} value `sec:msec_x_10`
 * @returns {number}
 */
function parseValue(value) {
  const transitionDuration = value.split(' ')
  const [ sec, msec ] = transitionDuration[0].split('.').map(x => +x)
  if ((sec === maskBlocks.SEC.to && msec > 0) || sec > maskBlocks.SEC.to) {
    return maskBlocks.SEC.to * TIMELINE_TIME_UNIT
  }

  if ((sec === 0 && msec < 25)) {
    return 25 * MSEC_STEP
  }

  return sec * TIMELINE_TIME_UNIT + msec * MSEC_STEP
}

export default function TransitionDurationField(props) {
  const { value, onChange, label } = props
  const [ , forceUpdate ] = React.useReducer(x => x + 1, 0)

  const onBlur = useCallback(e => {
    onChange(parseValue(e.currentTarget.value))
    forceUpdate()
  }, [ onChange ])

  const onKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onChange(parseValue(e.currentTarget.value))
      forceUpdate()
    }
  }, [ onChange ])

  return (
    <div className="asset-transition-field">
      <FormControlLabel
        classes={{ label: styles.label }}
        label={label}
        labelPlacement="start"
        control={(
          <NumberInput
            step={MSEC_STEP}
            min={MIN_TRANSITION_DURATION}
            max={MAX_TRANSITION_DURATION}
            onChange={onChange}
            value={value}
            display={(
              <MaskedInput
                value={value}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                mask={MASK}
                placeholderChar="0"
                blocks={maskBlocks}
                format={formatValue}
                parse={parseValue}
                startSelect={START_CHAR_POSITION_SELECT}
                endSelect={END_CHAR_POSITION_SELECT}
                noAccepted
                autoselect
              />
            )}
          />
        )}
      />
    </div>
  )
}

TransitionDurationField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
