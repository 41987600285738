import * as classNames from 'classnames'
import { clamp } from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'

import { useAction, useStatic } from 'hooks'

import { MIN_TIMELINE_ITEM_DURATION_PX } from 'constant'
import { selectTimelineScale } from 'selectors'
import { pixel2Time, time2Pixel } from '~/Util'
import { isImage, isText } from '~/Util/assets'
import * as Actions from '~/actions'

import { useMouseItemEvents } from './useMouseItemEvents'

export const Expander = ({ asset, isDragging, onStartExpanding,
  onEndExpanding, left = null, right = null }) => (
    <>
      {!left && (
      <ExpanderLine
        onStartExpanding={onStartExpanding}
        onEndExpanding={onEndExpanding}
        asset={asset}
        rightTr={right}
        isDragging={isDragging}
      />
      )}
      {!right && (
      <ExpanderLine
        onStartExpanding={onStartExpanding}
        onEndExpanding={onEndExpanding}
        right
        leftTr={left}
        asset={asset}
        isDragging={isDragging}
      />
      )}
    </>
)

const ExpanderLine = ({ right, asset, isDragging, leftTr, rightTr,
  onStartExpanding, onEndExpanding }) => {
  const scale = useSelector(selectTimelineScale)
  const updateAsset = useAction(Actions.layer.updateAssetInPreview, asset.id)
  const rewind = useAction(Actions.timeline.rewind)
  const startTimeRef = useStatic(asset.startTime)

  const minDuration = pixel2Time(MIN_TIMELINE_ITEM_DURATION_PX, scale)
    + (right ? leftTr?.duration || 0 : rightTr?.duration || 0)

  const stopUpdating = useAction(Actions.layer.stopAssetPreviewUpdating)

  const moveExpandingMedia = ({ timePosition }) => {
    const { duration, endTime, startTime, mediaFileDuration, mediaStart } = asset

    const patch = {
      ...(!right ? {
        startTime: Math.max(Math.min(timePosition, endTime - minDuration),
          startTime - mediaStart),
        mediaStart: clamp(mediaStart - (startTime - Math.min(timePosition, endTime - minDuration)),
          0, mediaFileDuration - minDuration),
        endTime, // see endTime setter
      } : {
        duration: Math.max(duration + Math.min((timePosition - endTime),
          mediaFileDuration - (mediaStart + duration)), minDuration),
      }),
    }
    updateAsset(patch)
  }

  const moveExpandingStatic = ({ timePosition }) => {
    const { duration, endTime } = asset
    const patch = {
      ...(!right ? { startTime: Math.min(timePosition, endTime - minDuration),
        endTime } : {
        duration: Math.max(duration + (timePosition - endTime), minDuration),
      }),
    }

    updateAsset(patch)
  }

  const handleMoveExpanding = (isText(asset.type) || isImage(asset))
    ? moveExpandingStatic
    : moveExpandingMedia

  const onMouseUp = () => {
    document.body.style.cursor = 'default'

    if (!right) {
      rewind(startTimeRef.current)
    }

    stopUpdating({ asset,
      initProps: { startTime: asset.startTime,
        duration: asset.duration,
        mediaStart: asset.mediaStart } })
    onEndExpanding()
  }

  const onStart = () => {
    document.body.style.cursor = 'ew-resize'
    onStartExpanding()
  }

  const { onMouseDown } = useMouseItemEvents({
    onMouseUp,
    onMouseDown: onStart,
    onMouseMove: handleMoveExpanding,
  })

  const width = time2Pixel(
    asset.duration - (right ? leftTr?.duration || 0 : rightTr?.duration || 0), scale
  )
  const hide = width < 44

  return (
    <div
      className={
        classNames('layer-item--image__expander',
          { 'layer-item--image__expander--right': right,
            'layer-item--image__expander--selected': asset.selected,
            'layer-item--image__expander--enable': isDragging || asset.selected,
            'layer-item--image__expander--hide': hide })
      }
      onMouseDown={onMouseDown}
    />
  )
}
