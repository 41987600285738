import React from 'react'
import defaultAvatar from '~/assets/avatar.svg'

function Avatar({ src, hideDefault }) {
  return (
    <div className="header__user-icon">
      <If condition={hideDefault !== true}>
        <img src={src || defaultAvatar} alt="user" />
      </If>
    </div>
  )
}

export default Avatar
