import { FILE_TYPE, SOURCE_FILE_TYPES } from 'enums'
import * as API from '~/ServerAPI'
import { createMediaFile } from './sourceFiles'
import * as Selectors from '~/selectors'
import { openFolderById } from './folders'

export const importMediafileFromUrl = url => async (dispatch, getState) => {
  let response = null
  const folder = Selectors.selectImportFolderId(getState(), SOURCE_FILE_TYPES.MEDIA)
  await dispatch(openFolderById(SOURCE_FILE_TYPES.MEDIA, folder))
  try {
    response = await API.importFromUrl({ url, folder })
  } catch (err) {
    response = err.response
  }
  if (response?.data.id !== undefined) {
    const { id } = response.data
    await dispatch(createMediaFile(id, FILE_TYPE.URL))
  }
}
