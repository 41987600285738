import { isNil } from 'lodash'
import { DIRECTION } from '~/enums'

export const getAssetsAfterStopIntersectingMouse = ({
  moveDirection,
  staticAssets,
  dragItem,
  clientMousePosition,
  draggableOffset,
  layerIds,
  offsetLayerIndex,
}) => {
  let adjustmentOffset = null
  let stopAsset = null
  let dragAsset = null

  if (moveDirection === DIRECTION.LEFT) {
    const rightStaticAssetsOfRelativeMouse = staticAssets
      .filter(asset => (asset.startTime + asset.duration) >= clientMousePosition)

    rightStaticAssetsOfRelativeMouse.forEach(asset => {
      dragItem.selectedAssets.forEach(draggableAsset => {
        const draggableAssetEndTime = draggableAsset.startTime
          + draggableAsset.duration + draggableOffset
        const diff = asset.startTime - draggableAssetEndTime

        // Draggable asset group index relative to starting position. Since a group of assets can move up and down
        const draggableLayerIndex = layerIds.indexOf(draggableAsset.layerId) + offsetLayerIndex
        const staticLayerIndex = layerIds.indexOf(asset.layerId)

        if (draggableLayerIndex >= 0 && draggableLayerIndex === staticLayerIndex) {
          if (isNil(adjustmentOffset) || (adjustmentOffset > diff)) {
            adjustmentOffset = diff
            stopAsset = asset
            dragAsset = draggableAsset
          }
        }
      })
    })
  } else if (moveDirection === DIRECTION.RIGHT) {
    const leftStaticAssetsOfRelativeMouse = staticAssets
      .filter(asset => (asset.startTime + asset.duration) <= clientMousePosition)

    leftStaticAssetsOfRelativeMouse.forEach(asset => {
      dragItem.selectedAssets.forEach(draggableAsset => {
        const draggableAssetStartTime = draggableAsset.startTime + draggableOffset
        const diff = draggableAssetStartTime - (asset.startTime + asset.duration)

        // Draggable asset group index relative to starting position. Since a group of assets can move up and down
        const draggableLayerIndex = layerIds.indexOf(draggableAsset.layerId) + offsetLayerIndex
        const staticLayerIndex = layerIds.indexOf(asset.layerId)

        if (draggableLayerIndex >= 0 && draggableLayerIndex === staticLayerIndex) {
          if (isNil(adjustmentOffset) || (adjustmentOffset > diff)) {
            adjustmentOffset = diff
            stopAsset = asset
            dragAsset = draggableAsset
          }
        }
      })
    })
  }
  return { adjustmentOffset, stopAsset, dragAsset }
}
