import * as ActionTypes from './ActionTypes'

export const setInstallVersion = payload => ({
  type: ActionTypes.SET_INSTALL_VERSION,
  payload,
})


export const setEdition = edition => ({
  type: ActionTypes.SET_EDITION,
  payload: { edition },
})
