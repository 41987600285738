export const getPlaceholdersByLayerIndex = (
  staticAssetsByLayerIndex, layerIds
) => Object.keys(staticAssetsByLayerIndex)
  .reduce((result, key) => {
    const current = { ...result }
    let prevAssetEndTime = 0
    current[key] = staticAssetsByLayerIndex[key].map(asset => {
      const placeholder = {
        startTime: prevAssetEndTime,
        endTime: asset.startTime,
        layerId: layerIds[key],
        layerIndex: key,
        duration: asset.startTime - prevAssetEndTime,
      }
      prevAssetEndTime = asset.startTime + asset.duration
      return placeholder
    })
    return current
  }, {})
