import Menu from '@material-ui/core/Menu'
import { styled } from '@material-ui/core/styles'
import { NestedMenuItem } from 'components/base/NestedMenu'
import MenuItem from 'components/base/MenuItem'
import React, { useContext, useRef } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import { useOnClickWithoutPropagation } from '~/hooks'
import { ReactComponent as Expand } from '~/assets/media/menu_expand.svg'

const EMPTY = () => {}

const StyledMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: '5px 0px',
  },
})

/**
 *
 * @param {*} param0
 * @returns
 */
export const MediaItemMenu = ({
  open,
  onClose,
  anchorEl,
  isFolder = false,
  isGroup = false,
  isTopLayer = false,
  showSettings = false,
  showMoveTo = false,
  onClickMoveFiles = EMPTY,
  onClickShowSettings = EMPTY,
  onClickMoveToMedia = EMPTY,
  onClickDeleteMedia = EMPTY,
}) => {
  const menuRef = useRef()
  const handleClick = useOnClickWithoutPropagation(onClose)
  const stopPropagation = useOnClickWithoutPropagation()
  const { t } = useContext(TranslationContext)

  const renderMoveItemsTo = () => (
    (
      <NestedMenuItem
        key="nested_menu"
        label={t('MEDIA_MENU_LABEL_MOVE_TO')}
        parentMenuOpen={open}
        rightIcon={<Expand />}
      >
        <MenuItem onClick={onClickMoveToMedia}>{t('MEDIA_MENU_ITEM_MEDIA')}</MenuItem>
        <MenuItem onClick={onClickMoveFiles}>{t('MEDIA_MENU_ITEM_SELECT_FOLDER')}</MenuItem>
      </NestedMenuItem>
    )
  )

  return (
    <>
      <StyledMenu
        ref={menuRef}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          square: true,
        }}
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus
        open={open}
        onClose={onClose}
        onClick={handleClick}
        onMouseUp={stopPropagation}
      >
        <Choose>
          <When condition={isTopLayer}>
            <If condition={showMoveTo}>
              <MenuItem onClick={onClickMoveFiles}>
                {t('MEDIA_MENU_ITEM_MOVE_TO')}
                ...
              </MenuItem>
            </If>
          </When>
          <Otherwise>
            {renderMoveItemsTo()}
          </Otherwise>
        </Choose>
        <If condition={!isFolder && showSettings}>
          <MenuItem onClick={onClickShowSettings}>
            {t('MEDIA_MENU_ITEM_SETTINGS')}
          </MenuItem>
        </If>
        <If condition={isGroup}>
          <MenuItem onClick={onClickDeleteMedia}>
            {t('MEDIA_MENU_ITEM_DELETE')}
          </MenuItem>
        </If>
      </StyledMenu>
    </>
  )
}
