import * as ActionTypes from '~/actions/ActionTypes'
import { selectProjects } from '~/selectors/projectData'
import * as API from '~/ServerAPI'

export const getProjects = searchValue => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.PROJECTS_REQUESTED,
    payload: { searchValue },
  })

  try {
    const state = getState()
    const existingProjects = selectProjects(state)

    const { projects, totalCount } = await API.getProjects(searchValue, existingProjects.length)

    dispatch({
      type: ActionTypes.PROJECTS_LOADED,
      payload: { projects: existingProjects.concat(projects), totalCount },
    })
  } catch (error) {
    console.error(`Failed to fetch projects ${error}`)

    dispatch({
      type: ActionTypes.PROJECTS_LOADED,
      payload: error,
      error: true,
    })
  }
}
