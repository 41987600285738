import { useContext, useEffect, useState } from 'react'
import { ColorProviderContext } from 'contexts/ColorProviderContext'


export const useColorProvider = (name: string) => {
  const [ isColorSelectionEnabled, setIsColorSelectionEnabled ] = useState(false)

  const colorProvider = useContext(ColorProviderContext)

  useEffect(() => {
    colorProvider.registerColorProvider(name, enabled => {
      setIsColorSelectionEnabled(enabled)
    })
    return () => colorProvider.unregisterColorProvider(name)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return { colorProvider, isColorSelectionEnabled }
}
