export const getRippleDistance = (assets, startTime, duration) => {
  let distance = duration
  const layers = assets.reduce((result, asset) => {
    Object.assign(result, {
      [asset.layerId]: result[asset.layerId]
        ? result[asset.layerId].concat([ asset ])
        : [ asset ],
    })
    return result
  }, {})
  Object.values(layers).forEach(layerAssets => {
    const [ firstAsset ] = layerAssets.filter(a => a.startTime > startTime)
      .sort((a, b) => a.startTime - b.startTime)
    if (firstAsset) {
      const [ leftSideAsset ] = layerAssets.filter(a => a.startTime < firstAsset.startTime)
        .sort((a, b) => b.startTime - a.startTime)
      const start = leftSideAsset?.endTime > startTime ? leftSideAsset.endTime : startTime
      const diff = firstAsset.startTime - start
      if (distance > diff) {
        distance = diff
      }
    }
  })
  return distance
}
