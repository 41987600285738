import { getTranslations } from 'ServerAPI'
import { TranslationContext, TranslationFunc } from 'contexts/TranslationContext'
import { getLang } from 'helpers/getLang'
import { getTranslationsByProfile } from 'helpers/getTranslationsByProfile'
import { isLink } from 'helpers/isLink'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { translations } from 'translations/translations'

type Props = {
  children: ReactNode | ReactNode[]
}

export const TranslationProvider = ({ children }: Props) => {
  const profileTranslations = getTranslationsByProfile(translations.en)
  const [ phrasesMap, setPhrasesMap ] = useState<{[key: string]: string}>(profileTranslations)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { customLanguage } = useSelector((state: any) => state.user)

  const t: TranslationFunc = key => phrasesMap[key] || profileTranslations[key]

  useEffect(() => {
    async function handleTranslation() {
      try {
        if (customLanguage) {
          const phrases = isLink(customLanguage) ? await getTranslations(customLanguage)
            : JSON.parse(customLanguage)[getLang()] || JSON.parse(customLanguage)?.en || profileTranslations
          setPhrasesMap(phrases)
        }
      } catch (e) {
        console.error(e)
        setPhrasesMap(profileTranslations)
      }
    }

    handleTranslation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ customLanguage ])

  return (
    <TranslationContext.Provider value={{ t }}>{children}</TranslationContext.Provider>
  )
}
