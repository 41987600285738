import Cookies from 'universal-cookie'
import * as LatestProjectName from '~/cookieServices/LatestProjectName'
import * as API from '~/ServerAPI'
import * as LatestProjectId from './LatestProjectId'

const cookies = new Cookies()
const AUTH_TOKEN = 'authToken'

export function set(authToken) {
  if (cookies.get(AUTH_TOKEN) !== authToken) {
    LatestProjectId.set('')
    LatestProjectName.set('')
  }
  cookies.set(AUTH_TOKEN, authToken, {
    path: '/',
    expires: new Date(2147483647 * 1000), // http://www.faqs.org/rfcs/rfc2616.html
  })
  API.setAuthToken(authToken)
}

export function get() {
  return cookies.get(AUTH_TOKEN)
}

export function removeAllCookies() {
  const allCookies = cookies.getAll()
  Object.keys(allCookies).forEach(cookieName => {
    cookies.remove(cookieName, { path: '/' })
  })
}
