import { DEFAULT_IMAGE_DURATION } from '~/constant'
import { MEDIA_TYPE, SOURCE_FILE_TYPES } from '~/enums'
// eslint-disable-next-line no-unused-vars
import Asset, * as Assets from '~/models/Asset'
import { Folder } from '~/models/Folder'
import { normalizeThumbnail } from '~/Util'

// TODO:
//  more filetypes in future
export const getMediaAssetClassByFileType = filetype => {
  switch (filetype) {
    case MEDIA_TYPE.MXF:
    case MEDIA_TYPE.TS:
    case MEDIA_TYPE.HLS:
    case MEDIA_TYPE.MOV:
    case MEDIA_TYPE.MP4: return Assets.VideoAsset
    case MEDIA_TYPE.WEBM:
    case MEDIA_TYPE.M4A:
    case MEDIA_TYPE.MP3: return Assets.AudioAsset
    case MEDIA_TYPE.JPG:
    case MEDIA_TYPE.PNG: return Assets.ImageAsset
    case MEDIA_TYPE.FOLDER: return Folder
    default: {
      // console.warn(`Got unsupported media item type: ${filetype}. This item will be ignored`)
      // it is required to represent even unsupported files during
      // upload, so return VideoAsset Class for a while
      return Assets.VideoAsset
    }
  }
}

/**
 *
 * @param {SourceFilesTypes} type
 * @param {*} item
 * @returns {InstanceType<typeof Asset>}
 */
export const parseLoadedFile = (type, item) => {
  const {
    id,
    url,
    previewUrl,
    originalUrl,
    thumbnail,
    error,
    fileId,
    folder,
    ...rest
  } = item

  const data = {
    // NOTE: rest contains private parameters and doesn't use their getters
    ...Object.fromEntries(Object.keys(rest).map(key => [ key.includes('_') ? key.replace('_', '') : key, rest[key] ])),
    id,
    // eslint-disable-next-line camelcase
    originalUrl,
    url: url || previewUrl,
    sourceFileType: type,
    thumbnail: thumbnail ? normalizeThumbnail(thumbnail) : undefined,
    // fileId for loading project, id for uploaded file
    fileId: fileId || id,
    folder,
    // unsupported files contain error object with message and type
    error: error !== null ? error : undefined,
  }

  const Constructor = getMediaAssetClassByFileType(data.filetype)

  if (Constructor === Assets.ImageAsset && !data.duration) {
    data.duration = DEFAULT_IMAGE_DURATION
  }

  switch (type) {
    case SOURCE_FILE_TYPES.MEDIA: {
      return Constructor === null ? null : new Constructor(data)
    }

    case SOURCE_FILE_TYPES.AUDIO:
      return new Assets.AudioAsset(data)

    case SOURCE_FILE_TYPES.TEXT:
      return new Assets.TextAsset(data)

    case SOURCE_FILE_TYPES.TRANSITIONS:
      return new Assets.TransitionAsset(data)

    case SOURCE_FILE_TYPES.IMAGES:
      return new Assets.ImageAsset(data)

    // TODO: filters/design
    default:
      throw new Error(`Unable to parses source file type: ${type}`)
  }
}

export function getPreparedImageSize(
  {
    height: originalHeight,
    width: originalWidth,
    videoSize: { height: videoHeight, width: videoWidth } = { height: 0, width: 0 },
    fit = false,
  }
) {
  let height = originalHeight
  let width = originalWidth

  const ratioWidth = videoWidth / width
  const ratioHeight = videoHeight / height

  const fitByWidth = ratioWidth < ratioHeight && fit
  const fitByHeight = ratioHeight < ratioWidth && fit

  if (videoHeight && videoWidth) {
    if (fitByWidth) {
      height *= ratioWidth
      width = videoWidth
    }
    if (fitByHeight) {
      width *= ratioHeight
      height = videoHeight
    }
  }

  width = Math.round(width)
  height = Math.round(height)

  return { height, width }
}

export function getAspectRatedImageSize({ width: originalWidth,
  height: originalHeight,
  aspectRatio }) {
  let width = originalWidth
  let height = originalHeight
  const currentAspectRatio = getImageAspectRatio({ width, height })

  const fixed = v => v.toFixed(6)

  if (fixed(currentAspectRatio) !== fixed(aspectRatio)) {
    if (width < height) {
      height = Math.round(width / aspectRatio)
    } else {
      width = Math.round(height * aspectRatio)
    }
  }
  return { width, height }
}

export function getImageAspectRatio({ width, height }) {
  return width / height
}
