import Button from 'components/base/Button'
import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import './SettingsHead.scss'

export default function SettingsHead({ caption, description, onClose, onDelete }) {
  const { t } = useContext(TranslationContext)

  return (
    <div className="settings-head">
      <div className="settings-head__row">
        <span className="settings-head__caption">{caption}</span>
        <div className="settings-head__close" onClick={onClose} title={t('SETTINGS_HEAD_BTN_CLOSE_TOOLTIP')}>
          <CloseIcon />
        </div>
      </div>
      <div className="settings-head__row">
        <div className="settings-head__descr">
          <span className="settings-head__descr-title">
            {t('SETTINGS_HEAD_TEXT_DESCRIPTION')}
            :
          </span>
          <p className="settings-head__descr-text">{description}</p>
        </div>
        <div className="settings-head__btns" title={t('SETTINGS_HEAD_BTN_DELETE_TOOLTIP')}>
          <Button onClick={onDelete} border large data-lang-id="SETTINGS_HEAD_BTN_DELETE">{t('SETTINGS_HEAD_BTN_DELETE')}</Button>
        </div>
      </div>
    </div>
  )
}
