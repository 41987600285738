import React, { useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { connect } from 'react-redux'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { DRAGNDROP_TYPE } from '~/enums'
import { useCompose } from '~/hooks'
import { useDragSource } from '~/hooks/useDragSource'
import { TransitionAsset } from '~/models/Asset'
import * as PT from '~/PropTypes'
import { selectDraggingAssetId } from '~/selectors'
import { DraggingItemContext } from '../DraggingItemProvider'
import LayerItem from './LayerItem'
import './LayerItem.scss'
import { useSelectedAssets } from '~/hooks/useSelectedAssets'
import { TimelineScrollPositionContext } from '~/components/Timeline/ScrollPositionContext'

// Monitor hovering asset by dragged source clips
function useDropTarget() {
  return useDrop({
    accept: [
      DRAGNDROP_TYPE.MEDIA_ITEM,
      DRAGNDROP_TYPE.IMAGE_ITEM,
      DRAGNDROP_TYPE.TEXT_ITEM,
      DRAGNDROP_TYPE.AUDIO_ITEM,
      DRAGNDROP_TYPE.LAYER_ASSET,
    ],

    canDrop: () => false,

    collect: monitor => ({
      isHovered: monitor.isOver(),
      intersectedPxls: monitor.getItem()?.intersectedPxls,
      item: monitor.getItem(),
    }),
  })
}

const DragContainer = props => {
  const { asset, onDragStarted, onDragEnded, draggingAssetId } = props

  const {
    timelineMouseDown,
  } = React.useContext(TimelineScrollPositionContext)

  const [ dragDisabled, setDragDisabled ] = useState(false)
  const {
    selectedAssetsData,
    selectedAssetsByLayerId,
    selectedAssetsByLayerIndex,
    selectedAssetsIds,
    dragTransitionIds,
    allTransitions,
    allAssets,
  } = useSelectedAssets()

  const [
    { isDragging },
    drag,
    preview,
  ] = useDragSource({
    id: asset.id,
    startTime: asset.startTime,
    duration: asset.duration,
    onDragStarted,
    onDragEnded,
    draggable: asset.draggable && !dragDisabled,
    type: DRAGNDROP_TYPE.LAYER_ASSET,
    layerId: asset.layerId ?? null,
    selectedAssetsData,
    selectedAssetsByLayerId,
    selectedAssetsByLayerIndex,
    dragTransitionIds,
    allTransitions,
    allAssets,
    timelineMouseDown,
  })
  const { setIsOverItem } = useContext(DraggingItemContext)
  const [{ isHovered, item }, drop ] = useDropTarget()
  const connectDraggable = useCompose(drop, drag)

  // Suppress default generated preview, because we need custom drag preview behavior.
  // See components/Timeline/DragAndDrop/CustomDragLayer.jsx
  useEffect(
    () => { preview(getEmptyImage()) },
    [ preview ]
  )

  // ---
  useEffect(() => {
    if (asset?.id !== item?.id) {
      setIsOverItem({ itemId: asset.id, isHovered })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isHovered, item ])

  const shouldDragging = isDragging || dragTransitionIds?.includes(asset.id)
  || (asset instanceof TransitionAsset
    && (draggingAssetId === asset.leftVideoAssetId
      || draggingAssetId === asset.rightVideoAssetId))

  const onEnableDrag = useCallback(() => {
    setDragDisabled(false)
  }, [ setDragDisabled ])

  const onDisableDrag = useCallback(() => {
    setDragDisabled(true)
  }, [ setDragDisabled ])

  return (
    <LayerItem
      {...props}
      connectDragSource={connectDraggable}
      isDragging={shouldDragging}
      onDisableDrag={onDisableDrag}
      onEnableDrag={onEnableDrag}
      selectedAssetsIds={selectedAssetsIds}
    />
  )
}


DragContainer.defaultProps = {
  onDragStarted: () => {},
  onDragEnded: () => {},
  draggingAssetId: undefined,
}

DragContainer.propTypes = {
  asset: PT.LayerAsset.isRequired,
  onDragStarted: PropTypes.func,
  onDragEnded: PropTypes.func,
  draggingAssetId: PropTypes.string,
}

const mapStateToProps = state => ({
  draggingAssetId: selectDraggingAssetId(state),
})

export default connect(mapStateToProps)(React.memo(DragContainer))
