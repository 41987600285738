import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'
import ConnectionIcon1 from '~/assets/icons/connection/connection1.svg'
import { ConnectionIconAnimation } from './ConnectionIconAnimation'

export const ConnectionIcon = ({ className }) => (
  <ConnectionIconAnimated className={cx(className)} />
)

const ConnectionIconAnimated = styled.div`
  background: url(${ConnectionIcon1});
  width: 24px;
  height: 24px;
  animation: ${ConnectionIconAnimation} 1600ms ease-in-out infinite running;
`
