import React, { useContext } from 'react'

import Checkbox from 'components/AssetPanels/Voiceover/components/Checkbox'
import TestVolume from 'components/AssetPanels/Voiceover/components/TestVolume'
import VolumeLevel from 'components/AssetPanels/Voiceover/components/VolumeLevel'

import { TranslationContext } from 'contexts/TranslationContext'
import styles from './volume.module.scss'

type Props = {
  stream: MediaStream | null,
  disable: boolean,
  constraints: MediaTrackConstraints,
  onAutoGainChange: (x: boolean) => void,
}

function Volume({ stream, disable, constraints, onAutoGainChange }: Props) {
  const { t } = useContext(TranslationContext)

  return (
    <>
      <div className={styles.volume}>
        <div className={styles.label}>Volume level</div>
        <VolumeLevel stream={stream} disable={disable} autoGainControl={Boolean(constraints.autoGainControl)} />
        <Checkbox
          checked={Boolean(constraints.autoGainControl)}
          onChange={onAutoGainChange}
          disabled={disable}
          emitValue
        />
        <div>{t('VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING')}</div>
      </div>
      <TestVolume stream={stream} disable={disable} />
    </>
  )
}

export default Volume
