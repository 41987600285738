import { moveMultipleAssets, undoMoveMultipleAssets } from '~/actions/timeline'
import { shiftItemsByTime } from '~/actions/layer'
import { AssetHistoryAction } from './AssetHistoryAction'
import { HistoryActionType } from './HistoryActionType'

export class MultipleAssetsMovedAction extends AssetHistoryAction {

  constructor(payload, dispatch, getState) {
    const { serviceData, deltaOnDrop, redoData, startTime } = payload
    super(HistoryActionType.MultipleAssetsMovedAction, payload, dispatch, getState)
    this._serviceData = serviceData
    this._redoData = redoData
    this._deltaOnDrop = deltaOnDrop
    this._startTime = startTime
  }

  get startTime() {
    return this._startTime
  }

  set startTime(startTime) {
    this._startTime = startTime
  }

  get serviceData() {
    return this._serviceData
  }

  set serviceData(serviceData) {
    this._serviceData = serviceData
  }

  get redoData() {
    return this._redoData
  }

  set redoData(redoData) {
    this._redoData = redoData
  }

  get deltaOnDrop() {
    return this._deltaOnDrop
  }

  set deltaOnDrop(deltaOnDrop) {
    this._deltaOnDrop = deltaOnDrop
  }

  * undo() {
    const { serviceData, deltaOnDrop, startTime } = this
    yield undoMoveMultipleAssets({ serviceData, deltaOnDrop, startTime })
  }

  * redo() {
    const { serviceData, redoData, deltaOnDrop } = this
    if (deltaOnDrop) {
      yield shiftItemsByTime({
        layerId: serviceData.insertBetweenLayerID,
        time: redoData.startTime,
        delta: deltaOnDrop,
        ignoredAssetsId: serviceData.selectedDragIds,
      })
    }
    yield moveMultipleAssets(redoData.assetId, redoData)
  }

}
