// Duration of selected assets on one layer
export const getCopiedAssetsDurationArea = (copiedAssets, layerId) => {
  const currentAssets = copiedAssets.filter(asset => asset.layerId === layerId)
  let minStartTime = currentAssets?.[0]?.startTime
  let maxEndTime = currentAssets?.[0]?.endTime
  currentAssets.forEach(curAsset => {
    if (minStartTime > curAsset.startTime) {
      minStartTime = curAsset.startTime
    }
    if (maxEndTime < curAsset.endTime) {
      maxEndTime = curAsset.endTime
    }
  })
  const resultDuration = maxEndTime - minStartTime
  return isNaN(resultDuration) ? 0 : resultDuration
}
