import { isNil } from 'lodash'

export const getFirstLayerIndexOfDragAssets = dragItem => (
  dragItem.selectedAssets.reduce((result, asset) => {
  // eslint-disable-next-line no-param-reassign
    if (isNil(result)) result = asset.layerIndex
    if (asset.layerIndex < result) {
      // eslint-disable-next-line no-param-reassign
      result = asset.layerIndex
    }
    return result
  }, null)
)
