import React from 'react'
import cx from 'classnames'

// eslint-disable-next-line no-unused-vars
import MaterialDivider, { DividerProps } from '@material-ui/core/Divider'

import styles from './Divider.module.scss'

/**
 *
 * @param {DividerProps} props
 * @returns
 */
export default function Divider(props) {
  const { className } = props
  return <MaterialDivider classes={{ root: cx(styles.root, className) }} {...props} />
}
