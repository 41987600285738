import { ASSET_ID_STARTWITH_LITERAL } from '~/config/constants/asset'
import UploadableAsset from './UploadableAsset'


/**
 * @memberOf Assets
 * @extends {UploadableAsset}
 */
class TimelineCommonAsset extends UploadableAsset {

  constructor(data) {
    if (new.target === TimelineCommonAsset) {
      throw new Error(`${new.target.name} can't be instantiated`)
    }

    super(data)
  }

  /**
   * Allow dragging 'ready' item, item without upload errors
   * and item which is in preparing thumbnail stage. Or if layerId exists.
   *
   * @returns {boolean}
   */
  get draggable() {
    return Boolean(this.layerId
      && this.id?.startsWith(ASSET_ID_STARTWITH_LITERAL)
      && !this.hasError)
      || super.draggable
  }

}

export default TimelineCommonAsset
