import React from 'react'
import cx from 'classnames'

import { MenuProps } from '@material-ui/core/Menu'
import RadioGroup from '@material-ui/core/RadioGroup'
import { MenuOption } from 'components/base/MenuSection/MenuSection'
import type { Classes, ItemWithLocalization } from 'components/base/Combobox/types'
import Combobox from './Combobox'

import styles from './combobox.module.scss'

type Props<E extends React.FunctionComponent | React.ElementType = React.ElementType> = {
  items: Array<ItemWithLocalization>,
  value: string | undefined,
  onChange: (e: React.ChangeEvent | string) => void,
  onClick?: () => void,
  classes?: Classes,
  component?: E,
  openOnIconClick?: boolean,
  emitValue?: boolean,
  icon?: React.ReactNode,
  checkedIcon?: React.ReactNode,
  MenuProps?: Partial<MenuProps>,
  anchorWidth?: boolean,
}

function RadioCombobox(props: Props) {
  const { items, value, onChange = () => {}, emitValue = false } = props

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (emitValue) {
      onChange(e.currentTarget.value)
    } else {
      onChange(e)
    }
  }

  const selectedItem = items.find(item => item.value === value)
  const selected = selectedItem?.label || items[0].label

  return (
    <Combobox
      {...props}
      selectedItem={selectedItem}
      selected={selected}
    >
      <RadioGroup
        className="media-sort__radio-group"
        value={value || ''}
        onChange={handleChange}
        name="sort"
      >
        {items.map(item => (
          <MenuOption
            className={cx(styles.menuSection)}
            key={item.value}
            value={item.value}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </Combobox>
  )
}

export default RadioCombobox
