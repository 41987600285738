import * as API from 'ServerAPI'
import { MEDIA_FILE_FILTERS } from 'enums'

export async function fetchMedia(): Promise<Array<Record<string, unknown>>>
export async function fetchMedia(args?: Record<string, unknown>): Promise<Array<Record<string, unknown>>>
export async function fetchMedia(args?: Record<string, unknown>): Promise<Array<Record<string, unknown>>> {
  try {
    const response = await API.getMedia({ filter: MEDIA_FILE_FILTERS.Folder, ...args })
    return response.data.entries
  } catch {
    // TOFIX
    return []
  }
}

export type fetchMediaType = typeof fetchMedia
