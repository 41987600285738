import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { ConfirmationDialog } from '~/components/base/ConfirmationDialog/ConfirmationDialog'

export const DeleteProjectConfirmation = ({ modalDialog, name }) => {
  const { t } = useContext(TranslationContext)
  return (
    <If condition={modalDialog.active}>
      <ConfirmationDialog
        onCancel={modalDialog.cancel}
        onConfirm={modalDialog.confirm}
        title={`${t('PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE')} “${name}” ${t('PROJECTS_CONFIRM_TITLE_PROJECT')}?`}
        description={t('PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY')}
        confirmBtnText={t('PROJECTS_CONFIRM_BTN_DELETE')}
        dataLangConfirmId="PROJECTS_CONFIRM_BTN_DELETE"
        buttonConfirmTitle={t('PROJECTS_CONFIRM_BTN_DELETE_TOOLTIP')}
      />
    </If>
  )
}
