import { rippleTrim, undoRippleTrim } from '~/actions/layer'
import { TransitionAsset } from '~/models/Asset'
import { undoDeleteTransition } from '~/actions/undoRedo/transitionUndoRedoActions'
import { addAssetToTimeline } from '~/actions/projectData/addAssetToTimeline'
import { getHistoryAsset } from '~/helpers/getHistoryAsset'
import { getObjectFields } from '~/Util'
import { HistoryActionType } from './HistoryActionType'
import { AssetHistoryAction } from './AssetHistoryAction'

export class RippleTrimAction extends AssetHistoryAction {

  constructor(payload, ...params) {
    super(HistoryActionType.RippleTrimAction, payload, ...params)
    const {
      startTime,
      endTime,
      ripple,
      patchs,
      deletedAssets,
      transitions,
      generatedIds,
      assetIds,
    } = payload

    this._startTime = startTime
    this._endTime = endTime
    this._ripple = ripple
    this._patchs = patchs
    this._deletedAssets = deletedAssets?.map(getHistoryAsset) || []
    this._transitions = transitions?.every(t => t instanceof TransitionAsset)
      ? transitions
      : transitions?.map(t => new TransitionAsset(getObjectFields(t))) || []
    this._generatedIds = generatedIds
    this._assetIds = assetIds
  }

  get startTime() {
    return this._startTime
  }

  set startTime(startTime) {
    this._startTime = startTime
  }

  get endTime() {
    return this._endTime
  }

  set endTime(endTime) {
    this._endTime = endTime
  }

  get ripple() {
    return this._ripple
  }

  set ripple(ripple) {
    this._ripple = ripple
  }

  get duration() {
    return this.endTime - this.startTime
  }

  get sliderTime() {
    return this.ripple === 'next' ? this.startTime : this.endTime
  }

  get patchs() {
    return this._patchs
  }

  get deletedAssets() {
    return this._deletedAssets
  }

  get transitions() {
    return this._transitions
  }

  get assetIds() {
    return this._assetIds
  }

  get generatedIds() {
    return this._generatedIds
  }

  * undo() {
    const { assets } = this.getState().layer
    const deleteAssetIds = assets.filter(a => a.originAssetId && !this.assetIds.includes(a.id))
      .map(a => a.id)

    yield undoRippleTrim(this.patchs, deleteAssetIds)

    for (const asset of this.deletedAssets) {
      yield addAssetToTimeline(asset, asset.layerId)
    }

    for (const transition of this.transitions) {
      yield undoDeleteTransition(transition)
    }
  }

  * redo() {
    yield rippleTrim({
      ripple: this.ripple,
      time: this.sliderTime,
      pregeneratedIds: this.generatedIds,
    })
  }

}
