import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// eslint-disable-next-line no-unused-vars
import MaterialSlider, { SliderProps } from '@material-ui/core/Slider'
import './Slider.scss'

// ---

function useSliderChangeHandler(fn, emitValue) {
  return React.useCallback(
    (e, value) => {
      if (emitValue) {
        fn(value)
      } else {
        fn(e, value)
      }
    },
    [ emitValue, fn ]
  )
}

// ---

/**
 *
 * @param {Omit<SliderProps, 'onChange' | 'onChangeCommitted'>} props
 * @returns
 */
export default function Slider(props) {
  const {
    className,
    emitValue,
    onChange, onChangeCommitted,
    ...rest
  } = props

  // ---

  const handleChange = useSliderChangeHandler(onChange, emitValue)
  const handleChangeCommitted = useSliderChangeHandler(onChangeCommitted, emitValue)

  // ---

  return (
    <MaterialSlider
      {...rest}
      className={cx(className, 'slider')}
      onChange={handleChange}
      onChangeCommitted={handleChangeCommitted}
    />
  )
}

Slider.propTypes = {
  className: PropTypes.string,
  emitValue: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
}

Slider.defaultProps = {
  className: '',
  emitValue: true,
  onChange: () => {},
  onChangeCommitted: () => {},
}
