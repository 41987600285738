/* eslint-disable max-len */
import React from 'react'

function UncheckedIcon(props: React.SVGProps<SVGSVGElement>) {
  const { stroke = 'white', strokeWidth = '2', width = '16', height = '16',
    viewBox = '0 0 16 16' } = props

  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 3.2C0.5 2.6317 0.500389 2.23554 0.525588 1.92712C0.55031 1.62454 0.596397 1.45069 0.66349 1.31901C0.8073 1.03677 1.03677 0.8073 1.31901 0.66349C1.45069 0.596397 1.62454 0.55031 1.92712 0.525588C2.23554 0.500389 2.6317 0.5 3.2 0.5H12.8C13.3683 0.5 13.7645 0.500389 14.0729 0.525588C14.3755 0.55031 14.5493 0.596397 14.681 0.66349C14.9632 0.8073 15.1927 1.03677 15.3365 1.31901C15.4036 1.45069 15.4497 1.62454 15.4744 1.92712C15.4996 2.23554 15.5 2.6317 15.5 3.2V12.8C15.5 13.3683 15.4996 13.7645 15.4744 14.0729C15.4497 14.3755 15.4036 14.5493 15.3365 14.681C15.1927 14.9632 14.9632 15.1927 14.681 15.3365C14.5493 15.4036 14.3755 15.4497 14.0729 15.4744C13.7645 15.4996 13.3683 15.5 12.8 15.5H3.2C2.6317 15.5 2.23554 15.4996 1.92712 15.4744C1.62454 15.4497 1.45069 15.4036 1.31901 15.3365C1.03677 15.1927 0.8073 14.9632 0.66349 14.681C0.596397 14.5493 0.55031 14.3755 0.525588 14.0729C0.500389 13.7645 0.5 13.3683 0.5 12.8V3.2Z" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
  )
}

export default UncheckedIcon
