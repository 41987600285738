import React from 'react'

import Panel from 'components/AssetPanels/BaseAssetsPanel'
import { SOURCE_FILE_TYPES } from '~/enums'

import List from './List'

const EmptyComponent = () => null

export default function ImageAssetsPanel() {
  return (
    <Panel
      type={SOURCE_FILE_TYPES.IMAGES}
      ListComponent={List}
      SettingsComponent={EmptyComponent}
    />
  )
}
