import LOGO_PATH from '~/assets/video-player/logo.svg'
import FAVICON from '~/assets/favicons/default.ico'

export default {
  LOGO_PATH,
  FAVICON,

  LOGO_LINK: 'https://freevideoeditor.online/',

  STUDIO_HOST: 'studio.freevideoeditor.online',
  SMARTEDITING_HOST: 'smarteditingonline.solveigmm.com',

  LAYER: {
    MUTE: true,
    HIDE: true,
    REARRANGE: true,
  },

  PREVIEW: {
    KEY_FRAME_STEPPER: false,
  },

  TIMELINE: {
    UNDO_REDO: true,
    RECORD_AUDIO: true,
    ADD_MARKER: false,
    TIME_INPUT: true,
    ZOOM_MENU: false,
  },

  SOURCE_FILES_MANAGEMENT: {
    SEARCH: true,
    FILTER: true,
    VIEW_OPTIONS: true,
    VIEW_MODE_SETTINGS: false,
    LOADING_NUMBER: 12,
    SUPPORTED_FILES: [ '.mp4', '.mov', '.mp3', '.m4a', '.mxf', '.png', '.jpg', '.jpeg' ],
    RETRY_UPLOAD: false,
    IMPORT_FOLDER: false,
    IMPORT_DROPBOX: true,
    IMPORT_AMAZON: false,
    IMPORT_GDRIVE: false,
    IMPORT_URL: true,
  },

  SOURCE_FILES_TYPES: {
    FILTERS: false,
    DESIGN: false,
    AUDIO: true,
    IMAGES: true,
    VIDEOS: true,
  },

  SOURCE_FILES_SORTS: {
    NAME: true,
    DATE: true,
    SIZE: true,
    DURATION: true,
  },

  SOURCE_FILES_FILTERS: {
    ALL: true,
    VIDEO: true,
    IMAGES: true,
    AUDIO: true,
    FOLDERS: true,
    MEDIA_RECORDING: {
      VOICEOVER: true,
      WEBCAM: false,
    },
  },

  MEDIA_ITEM: {
    CODECS_INFO: true,
  },

  MANAGE_PROJECTS: false,

  SAVE_PROJECT_MENU: {
    DETAILED_PROJECT_STATS_ON_SAVE: false,
    FILENAME_TITLE: true,
    CHOOSE_OUTPUT_FILE_FORMAT: false,
    MAX_FILENAME_LENGTH: 250, // file name only without ext
  },

  SAVE_SETTINGS: {
    CLOSE_CONFIRMATION_ENABLED: true,
    CREATE_OUTPUT_MEDIA: false,
    CLOSE_ON_CANCEL: false,
    DELETE_OUTPUT_ON_CLOSE: false,
    CONFIRMATION_TITLE: 'Close Project?',
    CLEAR_TIMELINE_ON_CLOSE: false,
    REDIRECT_TO_PROJECTS_ON_CLOSE: false,
  },

  MEDIA_SETTINGS: {
    VOLUME: true,
    IMAGE_SHADOW: false,
  },

  AUDIO_SETTINGS: {
    VOLUME: true,
    TRANSITIONS: false,
  },

  DISALLOW_INCOMPATIBLE_FILES: true,

  AUDIO_CATEGORIES: [ 'music', 'mood', 'sound' ],
  IMAGES_AI_SOURCE: false,

  IS_CHROMAKEY_ENABLED: true,
}
