import produce from 'immer'
import React, { useRef, ReactNode } from 'react'

type Values = {
    prevLayerIndex: string | null,
    prevSnappedY: number | null,
    prevSnappedX: number | null,
    normalizedSourceX: number,
    intersectedPxls: number,
    isOverItem: Set<string>,
    deltaOnDrop: number,
    allowDropOnHover: boolean,
    disableDrop: boolean,
    overNewLayerIndex: number | null,
    detachedMode: boolean,
    dropOffsetX: number,
    draggableBlockStartTime: number,
    isIntersectionPlaceholder: boolean,
    intersectionPlaceholder: null,
}

type GetSet = {
  getProps: () => State,
  setProps: (x: Partial<State>) => void
}

type State = Values & GetSet

const initialState: State = { prevLayerIndex: null,
  prevSnappedY: null,
  prevSnappedX: null,
  normalizedSourceX: 0,
  intersectedPxls: 0,
  deltaOnDrop: 0,
  dropOffsetX: 0,
  allowDropOnHover: false,
  overNewLayerIndex: null,
  isOverItem: initItemHoveredState(),
  disableDrop: true,
  detachedMode: false,
  draggableBlockStartTime: 0,
  isIntersectionPlaceholder: false,
  intersectionPlaceholder: null,
  getProps: () => ({} as State),
  setProps: () => {} }

export const DraggingItemContext = React.createContext({ } as GetSet)

type Props = {
  children: ReactNode | ReactNode[]
}

function DraggingItemProvider({ children }: Props) {
  const draggingItemProps = useRef(initialState)

  const store = {
    getProps: () => draggingItemProps.current,
    setIsOverItem: (patch: { itemId: string, isHovered: boolean }) => {
      draggingItemProps.current = itemHoveredReducer(draggingItemProps.current,
        patch)
    },
    setProps: (x: Partial<Values>) => {
      patchState(x, (patch: Partial<Values>) => {
        draggingItemProps.current = {
          ...draggingItemProps.current, ...patch,
        }
      })
    },
    resetProps: () => { draggingItemProps.current = initialState },
  }

  return (
    <DraggingItemContext.Provider value={store}>
      {children}
    </DraggingItemContext.Provider>
  )
}

function itemHoveredReducer(state: State, patch: { itemId: string, isHovered: boolean}) {
  return produce(state, draft => {
    const { itemId, isHovered } = patch
    if (isHovered) {
      draft.isOverItem.add(itemId)
    } else {
      draft.isOverItem.delete(itemId)
    }
  })
}

function initItemHoveredState() {
  return new Set() as Set<string>
}

function patchState(patch: Partial<Values>, update: (patch: Partial<Values>) => void) {
  const updatedPatch = { ...patch }

  if ('overNewLayerIndex' in patch && patch.overNewLayerIndex !== null) {
    updatedPatch.allowDropOnHover = false
    updatedPatch.deltaOnDrop = 0
  }

  update(updatedPatch)
}

export default DraggingItemProvider
