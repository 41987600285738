import produce from 'immer'
import React from 'react'
import { useSelector } from 'react-redux'
import { setPlayerVolume as setPlayerVolumeAction } from '~/actions/mainView'
import { useAction, useLazyComposeConst } from '~/hooks'
import { selectPlayerVolume } from '~/selectors'

function initState() {
  return {
    progress: 0,
    duration: 0,
    volume: 1,
    fullscreen: false,
    muted: false,

    width: 0,
    height: 0,
  }
}

const reducer = produce((state, patch) => {
  if (typeof patch === 'function') {
    const result = patch(state)
    if (result !== undefined) {
      Object.assign(state, result)
    }
  } else {
    Object.assign(state, patch)
  }
})

export default function usePreviewState() {
  const [ state, dispatch ] = React.useReducer(reducer, null, initState)
  const useHandler = useLazyComposeConst(dispatch)
  const setPlayerVolume = useAction(setPlayerVolumeAction)
  const volume = useSelector(selectPlayerVolume)

  const muted = volume === 0

  return [
    { ...state, volume, muted },
    {
      onChangeDuration: useHandler(x => ({ duration: x })),

      onChangeVolume: useHandler(setPlayerVolume),

      onCloseFullscreen: useHandler(() => ({ fullscreen: false })),

      // eslint-disable-next-line no-shadow
      onToggleFullscreen: useHandler(() => state => {
        state.fullscreen = !state.fullscreen
      }),
      // eslint-disable-next-line no-shadow
      onToggleMute: useHandler(volume => setPlayerVolume(volume ? 0 : 1)),

      onResize: useHandler(({ width, height }) => ({ width, height })),
    },
  ]
}
