import { Editor } from 'components/Editor/Editor'
import WebEditorHeader from 'components/Header'
import { Projects } from 'components/Projects/Projects'
import React, { useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import { useDropTargetLocation } from 'hooks/useDnd'
import * as Actions from '~/actions'
import { AppRoutes } from '~/AppRoutes'
import { OfflineModePlug } from '~/components/OfflineModePlug/OfflineModePlug'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import { useAction } from '~/hooks'

function MainView() {
  const loadProject = useAction(Actions.loadProject.loadProject)
  const { pathname } = useLocation()

  const [ , drop ] = useDropTargetLocation({ location: 'root', shallow: true })

  const location = useLocation()

  const latestProjectId = LatestProjectId.get()

  const dropRef = useRef()

  useEffect(() => {
    if (latestProjectId && location.pathname === AppRoutes.Projects) {
      loadProject(latestProjectId)
    }
    drop(dropRef.current.splitPane)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loadProject ])

  return (
    <>
      <OfflineModePlug />
      <SplitPane split="horizontal" defaultSize="48px" resizerStyle={{ display: 'none' }} ref={dropRef}>
        <WebEditorHeader />
        <Switch>
          {/* Remove trailing slash */}
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

          <Route exact path={AppRoutes.Projects}>
            <Projects />
          </Route>
          <Route path={`${AppRoutes.Projects}/:id`}>
            <Editor />
          </Route>
          <Redirect to={latestProjectId ? `${AppRoutes.Projects}/${latestProjectId}` : AppRoutes.NewProject} />
        </Switch>
      </SplitPane>
    </>
  )
}

export default MainView
