import { immerable } from 'immer'
import { v4 as uuidv4 } from 'uuid'

export default class TimelineLayer {

  [immerable] = true

  constructor(data = {}) {
    const {
      id = uuidv4(),
      name = '',
      muted = false,
      visible = true,
    } = data

    this._id = id
    this._name = name
    this._muted = muted
    this._visible = visible
  }

  /**
   * @returns {number}
   */
  get id() {
    return this._id
  }

  /**
  * @param {string} newId
  */
  set id(newId) {
    this._id = newId
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._name
  }

  // ---

  /**
   * @returns {boolean}
   */
  get muted() {
    return this._muted
  }

  /**
   * @param {boolean} muted
   */
  set muted(muted) {
    this._muted = muted
  }

  // ---

  /**
   * @returns {boolean}
   */
  get visible() {
    return this._visible
  }

  /**
   * @param {boolean} visible
   */
  set visible(visible) {
    this._visible = visible
  }

}
