import { desktopActivationFeaturesTranslation } from '../translations/desktop/desktop_activation_features_translation'

export const getTranslationsByProfile = (incomingTranslations, lang = 'en') => {
  switch (__APP_PROFILE__) {
    case 'desktop': {
      return {
        ...incomingTranslations,
        ...desktopActivationFeaturesTranslation[lang],

      }
    }
    default:
      return incomingTranslations
  }
}
