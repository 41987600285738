import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import * as Actions from '~/actions'
import { useAction, useModalDialog } from '~/hooks'

import { TranslationContext } from '~/contexts/TranslationContext'
import * as PT from '~/PropTypes'
import Header from '~/components/Settings/SettingsHead'
import Confirmation from '~/components/base/Confirmation'
import './BaseAssetSettingsForm.scss'

export default function BaseAssetSettingsForm(props) {
  const {
    type, asset,
    className,
    title, description,
    children,
  } = props
  const onSubmit = useAction(Actions.mainView.submitAssetSettingsForm, type)
  const onCancel = useAction(Actions.mainView.cancelAssetSettingsForm, type)
  const onDelete = useAction(Actions.timeline.deleteAssets, asset)

  const deletionDialog = useModalDialog({
    onConfirm: onDelete,
  })

  const { t } = useContext(TranslationContext)

  return (
    <form className={cx('asset-settings', className)}>
      <Header
        caption={title}
        description={description}

        onClose={onCancel}
        onDelete={deletionDialog.open}
        onSave={onSubmit}
      />

      <If condition={deletionDialog.active}>
        <Confirmation
          onCancel={deletionDialog.cancel}
          onConfirm={deletionDialog.confirm}
          text={t('BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE')}
        />
      </If>

      <div className="asset-settings__content">
        {children}
      </div>
    </form>
  )
}

BaseAssetSettingsForm.propTypes = {
  type: PT.SourceFileType.isRequired,
  asset: PT.Asset.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
