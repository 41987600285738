/**
 * @param {{
 * hoveringInsertedLayerIds:
 * number[], asset: Asset,
 * layerAssets: Asset[],
 * layerIndex: number,
 * layers: any[]
 * }} param1
 * @returns {boolean}
 */
export const checkIsNewLayer = ({ hoveringInsertedLayerIds,
  asset, layerAssets, layerIndex, layers }) => hoveringInsertedLayerIds.includes(asset.layerId)
  || (!layerAssets.length && layerIndex === layers.length - 1)
