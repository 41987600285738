import { useEffect, useRef, useState } from 'react'

export const useMediaRecordLocal = (stream: MediaStream | null): [MediaRecorder | null, HTMLAudioElement] => {
  const [ mediaRecorder, setMediaRecorder ] = useState<MediaRecorder | null>(null)
  const audioElem = useRef<HTMLAudioElement>(document.createElement('audio'))

  useEffect(() => {
    if (!stream) return
    setMediaRecorder(new MediaRecorder(stream))
  }, [ stream ])

  useEffect(() => {
    let chunks: Array<Blob> = []

    function pushData(e: BlobEvent) {
      chunks.push(e.data)
    }

    function handleStop() {
      const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
      chunks = []
      if (audioElem.current) {
        const audioURL = URL.createObjectURL(blob)
        audioElem.current.src = audioURL
      }
    }

    if (mediaRecorder) {
      mediaRecorder.addEventListener('dataavailable', pushData)
      mediaRecorder.addEventListener('stop', handleStop)
    }

    return () => {
      mediaRecorder?.removeEventListener('dataavailable', pushData)
      mediaRecorder?.removeEventListener('stop', handleStop)
      if (mediaRecorder?.state === 'recording') {
        mediaRecorder.stop()
      }
    }
  }, [ mediaRecorder ])

  useEffect(() => () => {
    audioElem.current?.remove()
  }, [])

  return [ mediaRecorder, audioElem.current ]
}
