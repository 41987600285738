import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { pickBy } from 'lodash'

import type { ConnectionStatus } from 'components/AssetPanels/Voiceover/types'
import { createRecording,
  getSenders, closeConnection as closeConnectionService } from 'services/MediaRecordingService'
import { useStatic } from 'hooks'
import { connectAudioContext } from 'components/AssetPanels/Voiceover/utils/webAudioAPI'
import useWebAudio from 'components/AssetPanels/Voiceover/hooks/useWebAudio'


function connectStatusMatch(x: RTCPeerConnectionState): ConnectionStatus {
  switch (x) {
    case 'new':
    case 'connecting':
      return 'connecting'
    case 'connected':
      return 'connected'
    case 'disconnected':
      return 'disconnected'
    case 'closed':
      return 'closed'
    case 'failed':
      return 'failed'
    default:
      return 'closed'
  }
}

type MediaTrackConstraintsKeys = keyof MediaTrackSupportedConstraints

function useRecording() {
  const sessionId = useRef<string | null>(null)
  const [ stream, setStream ] = useState<MediaStream | null>(null)
  const [ , setOriginalStream ] = useState<MediaStream | null>(null)
  const [ connectStatus, _setConnectStatus ] = useState<ConnectionStatus>('closed')
  const [ constraints, _setConstraints ] = useState<MediaTrackConstraints>({ autoGainControl: true })
  const supportedConstraints = useRef<MediaTrackSupportedConstraints | null>(null)
  const recordsDefaultFolderId = useSelector((state: RootState) => state.user.recordsDefaultFolderId)

  const streamRef = useRef<MediaStream | null>(null)
  const originalStreamRef = useRef<MediaStream | null>(null)
  const connectStatusRef = useStatic(connectStatus)

  useWebAudio()

  const setConnectStatus = useCallback((x: RTCPeerConnectionState) => {
    _setConnectStatus(connectStatusMatch(x))
  }, [])

  const setConstraints = useCallback((patch: MediaTrackConstraints) => {
    _setConstraints(prev => ({ ...prev,
      ...patch }))
  }, [])

  const resetStream = useCallback(() => {
    streamRef.current?.getTracks().forEach(track => {
      track.stop()
    })
    originalStreamRef.current?.getTracks().forEach(track => {
      track.stop()
    })
  }, [])

  const createConnection = useCallback(async (onConnect?: (id: string) => void) => {
    try {
      if (!supportedConstraints.current) return

      const tsSupportedConstraints = supportedConstraints.current
      const constraintsToApply = pickBy(constraints,
        (value, key) => tsSupportedConstraints[key as MediaTrackConstraintsKeys])

      const agcEnabled = Boolean(constraintsToApply.autoGainControl)

      resetStream()

      const originalStream = await navigator.mediaDevices.getUserMedia({ audio: constraintsToApply, video: false })

      const stream = await connectAudioContext(originalStream, agcEnabled)
      streamRef.current = stream
      originalStreamRef.current = originalStream

      await createRecording({ audioStream: agcEnabled ? originalStream : stream,
        folder: recordsDefaultFolderId,
        onConnectionStateChange: setConnectStatus,
        onConnect: id => { sessionId.current = id } })
      setStream(agcEnabled ? originalStream : stream)
      setOriginalStream(originalStream)
    } catch (e) {
      setConnectStatus('failed')
      setStream(null)
      setOriginalStream(null)
    }
  }, [ setConnectStatus, constraints, resetStream, recordsDefaultFolderId ])

  useEffect(() => {
    supportedConstraints.current = navigator.mediaDevices.getSupportedConstraints()
  }, [])

  useEffect(() => {
    (async function fn() {
      try {
        if (connectStatusRef.current !== 'connected') return
        if (!supportedConstraints.current) return

        const tsSupportedConstraints = supportedConstraints.current
        const constraintsToApply = pickBy(constraints,
          (value, key) => tsSupportedConstraints[key as MediaTrackConstraintsKeys])

        const agcEnabled = Boolean(constraintsToApply.autoGainControl)

        resetStream()

        const originalStream = await navigator.mediaDevices.getUserMedia({ audio: constraintsToApply, video: false })
        const stream = await connectAudioContext(originalStream, agcEnabled)
        streamRef.current = stream
        originalStreamRef.current = originalStream


        const track = (agcEnabled ? originalStream : stream)?.getAudioTracks()[0]
        const sender = getSenders(sessionId.current)
          ?.find((s: { track: { kind: string } }) => s.track.kind === track?.kind)
        sender.replaceTrack(track)

        setStream(agcEnabled ? originalStream : stream)
        setOriginalStream(originalStream)
      } catch (e) {
        setStream(null)
        setOriginalStream(null)
      }
    }())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ constraints ])

  useEffect(() => () => {
    resetStream()
  }, [ resetStream ])

  const closeConnection = () => {
    if (!sessionId.current) return
    closeConnectionService(sessionId.current)
  }

  return { sessionId: sessionId.current,
    stream,
    connectStatus,
    setConnectStatus,
    constraints,
    setConstraints,
    createConnection,
    closeConnection }
}

export default useRecording
