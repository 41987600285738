import classNames from 'classnames'
import React, { useCallback, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'

import * as Actions from 'actions'
import RawButton from 'components/base/RawButton'
import { TranslationContext } from 'contexts/TranslationContext'
import { ReactComponent as DragIcon } from '~/assets/timeline/ic_action_drag.svg'
import { ReactComponent as ArrowIcon } from '~/assets/timeline/ic_arrow.svg'
import { ReactComponent as DeleteIcon } from '~/assets/timeline/ic_delete_layer.svg'
import { ReactComponent as InvisibleIcon } from '~/assets/timeline/ic_unvisibility.svg'
import { ReactComponent as VisibilityIcon } from '~/assets/timeline/ic_visibility.svg'
import { ReactComponent as MutedIcon } from '~/assets/timeline/ic_volume-x.svg'
import { ReactComponent as UnmutedIcon } from '~/assets/timeline/ic_volume.svg'
import { useAction, useDOMEvent } from '~/hooks'
import { getLayerAssets, getLayers, recording as recordingSelectors } from '~/selectors'
import { useDebounceWithRef } from '~/hooks/useDebounceWithRef'

function LayerControls({ layerId, forwardRef, dragRef }) {
  const layers = useSelector(getLayers)
  const currentIndex = layers.findIndex(el => el.id === layerId)
  const layer = layers[currentIndex]
  const layerAssets = useSelector(state => getLayerAssets(state, layer.id))
  const disable = useSelector(recordingSelectors.isRecordingStarted)
  const saveProject = useAction(Actions.saveProject.saveProject)
  const layerVisibleRef = useRef()
  const layerMuteRef = useRef()

  const onDelete = useAction(Actions.timeline.deleteLayer, layerId)
  const onMute = useAction(Actions.timeline.muteLayer, layerId, !layer.muted)
  const changeLayerPosition = useAction(Actions.timeline.changeLayerPosition, layerId)
  const toggleVisibleLayer = useAction(Actions.timeline.toggleVisibleLayer, layerId)

  const isLatestEmptyLayer = !layerAssets.length && currentIndex === layers.length - 1

  const moveUpDisabled = isLatestEmptyLayer || currentIndex === 0
  const moveDownDisabled = isLatestEmptyLayer || currentIndex === layers.length - 1
    || currentIndex === layers.length - 2

  const handleMoveUp = () => {
    if (!moveUpDisabled) {
      changeLayerPosition(currentIndex - 1)
    }
  }

  const handleMoveDown = () => {
    if (!moveDownDisabled) {
      changeLayerPosition(currentIndex + 1)
    }
  }

  const onToggleVisible = useCallback(() => {
    toggleVisibleLayer(layerId)
  }, [ layerId, toggleVisibleLayer ])

  const onClickDelete = () => onDelete()

  useDebounceWithRef(layerVisibleRef, saveProject, 'mouseup')
  useDebounceWithRef(layerMuteRef, saveProject, 'mouseup')
  useDOMEvent(layerVisibleRef, 'mousedown', onToggleVisible)
  useDOMEvent(layerMuteRef, 'mousedown', onMute)

  const { t } = useContext(TranslationContext)

  return (
    <div className={classNames('layer__control', { 'layer__control--disabled': disable })} ref={forwardRef}>
      <div className="layer__control-col">
        <If condition={__CFG__.LAYER.REARRANGE}>
          <div onClick={handleMoveUp} className={classNames('layer__control-item', { 'layer__control-item--disabled': moveUpDisabled })} title={t('LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP')}>
            <RawButton dataLangId="LAYER_CONTROL_BTN_MOVE_UP">
              <ArrowIcon className="revert-icon" />
            </RawButton>
          </div>

          <div ref={dragRef} className={classNames('layer__control-item', { 'layer__control-item--disabled': moveUpDisabled && moveDownDisabled })} title={t('LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP')}>
            <RawButton dataLangId="LAYER_CONTROL_BTN_DRAG_LAYER">
              <DragIcon />
            </RawButton>
          </div>

          <div onClick={handleMoveDown} className={classNames('layer__control-item', { 'layer__control-item--disabled': moveDownDisabled })} title={t('LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP')}>
            <RawButton dataLangId="LAYER_CONTROL_BTN_MOVE_DOWN">
              <ArrowIcon />
            </RawButton>
          </div>
        </If>
      </div>

      <div className="layer__control-col">
        <div className="layer__control-item">
          <If condition={__CFG__.LAYER.HIDE}>
            <RawButton
              ref={layerVisibleRef}
              title={layer.visible ? t('LAYER_CONTROL_BTN_HIDE_TOOLTIP') : t('LAYER_CONTROL_BTN_SHOW_TOOLTIP')}
              dataLangId={layer.visible ? 'LAYER_CONTROL_BTN_HIDE' : 'LAYER_CONTROL_BTN_SHOW'}
            >
              {layer.visible ? <VisibilityIcon /> : <InvisibleIcon />}
            </RawButton>
          </If>
        </div>

        <div className="layer__control-item layer__control-item--volume" title={layer.muted ? t('LAYER_CONTROL_BTN_UNMUTE_TOOLTIP') : t('LAYER_CONTROL_BTN_MUTE_TOOLTIP')}>
          <If condition={__CFG__.LAYER.MUTE}>
            <RawButton ref={layerMuteRef} dataLangId={layer.muted ? t('LAYER_CONTROL_BTN_UNMUTE') : t('LAYER_CONTROL_BTN_MUTE')}>
              {layer.muted ? <MutedIcon /> : <UnmutedIcon />}
            </RawButton>
          </If>
        </div>

        <div className="layer__control-item" title={t('LAYER_CONTROL_BTN_DELETE_TOOLTIP')}>
          <RawButton onClick={onClickDelete} disabled={layers.length < 2} dataLangId="LAYER_CONTROL_BTN_DELETE">
            <DeleteIcon />
          </RawButton>
        </div>
      </div>
    </div>
  )
}

export default React.memo(React.forwardRef((props, ref) => (
  <LayerControls {...props} forwardRef={ref} />
)))
