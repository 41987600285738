import * as ActionTypes from '~/actions/ActionTypes'
import * as ShowBlankProjectInList from '~/cookieServices/ShowBlankProjectInList'

/**
 * @param {boolean} saveEmpty
 */
export const showBlankProjectInList = () => async dispatch => {
  ShowBlankProjectInList.set(true)
  await dispatch({
    type: ActionTypes.PROJECTS_SHOW_BLANK_IN_LIST,
  })
}
