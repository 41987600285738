import { restoringRecord, restoringRecordProjectId } from 'constant'
import { MediaRecordingAsset } from 'models/Asset'
import { getObjectFields } from 'Util'

export class MediaRecordingRestoreServiceClass {

  constructor() {
    const asset = localStorage.getItem(restoringRecord)
    const projectId = localStorage.getItem(restoringRecordProjectId)

    if (asset && projectId) {
      this.pendingRecord = new MediaRecordingAsset({ ...JSON.parse(asset) })
      this.projectId = projectId
    } else {
      this.pendingRecord = null
      this.projectId = null
    }
  }


  push(asset: MediaRecordingAsset, projectId: string) {
    this.pendingRecord = asset.clone(undefined, { keepSourceId: true })
    this.projectId = projectId
    localStorage.setItem(restoringRecord, JSON.stringify(getObjectFields(this.pendingRecord)))
    localStorage.setItem(restoringRecordProjectId, projectId)
  }

  update(patch: Partial<MediaRecordingAsset>) {
    if (!this.pendingRecord) return
    this.pendingRecord = Object.assign(this.pendingRecord, patch)
    localStorage.setItem(restoringRecord, JSON.stringify(this.pendingRecord))
  }

  pop() {
    const asset = this.pendingRecord
    this.pendingRecord = null
    this.projectId = null
    localStorage.removeItem(restoringRecord)
    localStorage.removeItem(restoringRecordProjectId)
    return asset
  }

  pendingRecord: MediaRecordingAsset | null
  projectId: string | null

}

export const MediaRecordingRestoreService = new MediaRecordingRestoreServiceClass()
