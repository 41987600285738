import { TranslationContext } from 'contexts/TranslationContext'
import React, { useContext } from 'react'
import { ReactComponent as FlipHorizontalIcon } from '~/assets/settings/flip_horizontal.svg'
import { ReactComponent as FlipVerticalIcon } from '~/assets/settings/flip_vertical.svg'
import { ReactComponent as RotationSettingsIcon } from '~/assets/settings/rotation_settings.svg'
import SettingsInput from '~/components/Settings/SettingsInput'
import { SettingsButton } from '../components'
import './Rotation.scss'

export default function Rotation({ onChangeRotation, onChangeFlip, rotation, flip }) {
  const { horizontal, vertical } = flip

  const onChangeHorizontalFlip = () => {
    onChangeFlip({ horizontal: !horizontal, vertical })
  }

  const onChangeVerticalFlip = () => {
    onChangeFlip({ horizontal, vertical: !vertical })
  }

  const onChangeRotationInput = e => {
    onChangeRotation(Number(e.target.value))
  }

  const { t } = useContext(TranslationContext)

  return (
    <div className="rotation-container">
      <div className="rotation-container__item rotation-container__item--clickable" onClick={onChangeHorizontalFlip}>
        <SettingsButton icon={<FlipHorizontalIcon />}>
          <span>{t('SETTINGS_ROTATION_TEXT_HORIZONTAL')}</span>
        </SettingsButton>
      </div>

      <div className="rotation-container__item rotation-container__item--clickable" onClick={onChangeVerticalFlip}>
        <SettingsButton icon={<FlipVerticalIcon />}>
          <span>{t('SETTINGS_ROTATION_TEXT_VERTICAL')}</span>
        </SettingsButton>
      </div>

      <div className="rotation-container__item">
        <span className="rotation-container__item__label">{t('SETTINGS_ROTATION_TEXT_ROTATE')}</span>
        <SettingsInput
          defaultValue={rotation}
          onChange={onChangeRotationInput}
          icon={<RotationSettingsIcon className="settings-input__icon" />}
          min={-360}
          max={360}
        />
      </div>
    </div>
  )
}
