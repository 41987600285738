import { getObjectFields } from '~/Util'
import { parseLoadedFile } from '~/Util/sourceFile'
import Project from '~/models/ProjectData/Project'
import ProjectAudioAsset from '~/models/ProjectData/ProjectAudioAsset'
import ProjectImageAsset from '~/models/ProjectData/ProjectImageAsset'
import ProjectLayer from '~/models/ProjectData/ProjectLayer'
import ProjectTextAsset from '~/models/ProjectData/ProjectTextAsset'
import ProjectTransitionAsset from '~/models/ProjectData/ProjectTransitionAsset'
import ProjectVideoAsset from '~/models/ProjectData/ProjectVideoAsset'
import { isAudio, isImage, isText, isTransition } from '~/Util/assets'

/**
 * @param {object} assetData
   @param {number} version
 * @returns {ProjectData.ProjectTextAsset}
 */
export const getTextAsset = (assetData, version) => new ProjectTextAsset(assetData, version)

/**
 * @param {object} assetData
   @param {number} version
 * @returns {ProjectData.ProjectTransitionAsset}
 */
export const getTransitionAsset = (assetData, version) => new ProjectTransitionAsset(
  assetData,
  version
)

export const getImageAsset = (assetData, version) => new ProjectImageAsset(
  assetData,
  version
)

export const getAudioAsset = (assetData, version) => new ProjectAudioAsset(
  assetData,
  version
)

/**
 * @param {object} assetData
   @param {number} version
 * @returns {ProjectData.ProjectAsset}
 */
export const getAssetFromData = (assetData, version) => {
  const { type, filetype } = assetData

  if (isText(type)) {
    return getTextAsset(assetData, version)
  }

  if (isTransition(type)) {
    return getTransitionAsset(assetData, version)
  }

  if (isImage(assetData)) {
    return getImageAsset(assetData, version)
  }

  if (isAudio(filetype)) {
    return getAudioAsset(assetData, version)
  }

  const newassetData = new ProjectVideoAsset(assetData, version)
  return newassetData
}

/**
 * @param {object} layerData
   @param {number} version
 * @returns {ProjectData.ProjectLayer}
 */
export const getLayer = (layerData, version) => {
  const { assets } = layerData
  return new ProjectLayer(
    layerData,
    version,
    assets.map(assetData => getAssetFromData(assetData, version))
  )
}

/**
 * @param {object} assetData
   @param {number} version
 * @returns {ProjectData.Project}
 */
export const getProject = jsonData => {
  const { layers, version } = jsonData
  return new Project(jsonData, layers.map(layerData => getLayer(layerData, version)))
}

/**
* @param {Asset|Asset[]|object|object[]} assets
* @returns {ProjectData.ProjectAsset[]}
*/

export const rebuildAssets = assets => {
  const originalAssets = [].concat(assets)
  const preparedAssets = []
  originalAssets.forEach(originalAsset => {
    const newAsset = getAssetFromData(getObjectFields(originalAsset))
    const file = parseLoadedFile(newAsset.sourceFileType, newAsset)
    preparedAssets.push(file)
  })
  return preparedAssets
}
