export const userProfileSelector = state => state.user

export const hotkeyProfilesSelector = state => state.user.hotkeyProfiles

export const activeHotkeyProfileIdSelector = state => state.user.hotkeyProfiles.active

export const hotkeyProfilesOptionsSelector = state => {
  const hotkeyProfiles = hotkeyProfilesSelector(state)
  return Object.values(hotkeyProfiles.profiles).map(p => ({ id: p.id, name: p.name }))
}

export const activeHotkeyProfileSelector = state => (
  state.user.hotkeyProfiles.profiles[state.user.hotkeyProfiles.active]
)

export const searchHotkeyTextSelector = state => (
  state.user.searchHotkeyText
)
