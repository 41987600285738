import classNames from 'classnames'
import React from 'react'

const EMPTY = () => {}

export const NavigationDir = ({ onClick, directory, disabled }) => {
  const handleClick = () => onClick(directory)
  return (
    <span
      className={classNames('media-navigation__dir', { 'media-navigation__dir--disabled': disabled })}
      onClick={disabled ? EMPTY : handleClick}
    >
      {directory.name}
    </span>
  )
}
