import ProjectAsset from '~/models/ProjectData/ProjectAsset'

/**
 * @memberOf ProjectData
 * @extends ProjectData.ProjectVideoAsset
 *
 */

class ProjectVideoAsset extends ProjectAsset {

  constructor(jsonData) {
    const { volume, muted, mediaFileDuration } = jsonData
    super(jsonData)

    const { settings } = jsonData

    this._settings = { ...settings }

    this._volume = volume
    this._muted = muted
    this._mediaFileDuration = mediaFileDuration
  }

  get volume() {
    return this._volume
  }

  /**
   * @param {number} volume
   */
  set volume(volume) {
    this._volume = volume
  }

  get muted() {
    return this._muted
  }

  /**
   * @param {boolean} muted
   */
  set muted(muted) {
    this._muted = muted
  }

  get mediaFileDuration() {
    return this._mediaFileDuration
  }

  /**
   * @param {number} mediaFileDuration
   */
  set mediaFileDuration(mediaFileDuration) {
    this._mediaFileDuration = mediaFileDuration
  }

  /**
   * @returns {Object}
   */
  get settings() {
    return this._settings
  }

}

export default ProjectVideoAsset
