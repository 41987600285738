/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { createText } from '~/features/konva-shapes/createText'
import { useBind, useChanged, useComposeConst } from '~/hooks'
import {
  getDragBoundingFunc,
  getDragEndPatch
} from '../AssetsOverlay/lib'
import { Overlay } from './Overlay'

export const TextAssetOverlay = props => {
  const { asset, boundingRect, onUpdateAssetSettings, params, selecting } = props
  const { settings } = asset
  const { position } = settings
  const { scale } = params

  const [ shape, setShape ] = useState(null)

  useEffect(() => {
    if (asset) {
      createText(asset, setShape, { boundingRect, scale })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ asset, boundingRect, scale ])

  const size = {
    width: shape?.width() || 0,
    height: shape?.height() || 0,
  }

  const geometry = useChanged({ boundingRect, assetKonvaSize: size })
  const handleUpdateOverlay = useComposeConst(
    onUpdateAssetSettings,
    useBind(getDragEndPatch, position)
  )
  const dragBoundFunc = getDragBoundingFunc(position, geometry)
  return (
    <If condition={shape && size.width !== 0 && size.height !== 0}>
      <Overlay
        {...props}
        // It's important to prevent text's bug with the size
        extSourceSize={{}}
        shape={shape}
        assetId={asset.id}
        offset={settings.offset}
        boundingRect={boundingRect}
        onlyMove
        onUpdateOverlay={handleUpdateOverlay}
        dragBoundFunc={dragBoundFunc}
        scale={scale}
        dragging={selecting}
        rotation={settings.rotation}
      />
    </If>
  )
}
