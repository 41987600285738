import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import cx from 'classnames'
import './FontOptionsRadioGroup.scss'

export default function FontOptionsRadioGroup(props) {
  const { className, ...rest } = props
  return (
    <RadioGroup
      {...rest}
      className={cx('font-options-select', className)}
    />
  )
}
