import { isArray } from 'lodash'
import { PlaceholderAsset } from '~/models/Asset'

// Do this here instead of LayerReducer so that every reducer can receive a proper time, if it needs it
export function normalizeAssetStartTime(state, getLayerAssetsSelector, {
  assetId,
  startTime,
  duration,
  layerId,
  isNewLayerAction = false,
  ignoreStartOverlapping = false,
}) {
  const sameLayerAssets = getLayerAssetsSelector(state, layerId)
    .filter(x => !(x instanceof PlaceholderAsset) && (isArray(assetId)
      ? !assetId.includes(x.id)
      : assetId !== x.id))
  const overlappingEndAsset = sameLayerAssets.find(x => (
    startTime > x.startTime
    && startTime < x.endTime
  ))

  const endTime = startTime + duration
  const overlappingStartAsset = sameLayerAssets.find(x => (
    endTime > x.startTime
    && endTime < x.endTime
  ))

  if (overlappingEndAsset !== undefined && !isNewLayerAction) {
    return overlappingEndAsset.endTime
  }
  if (overlappingStartAsset !== undefined && !ignoreStartOverlapping && !isNewLayerAction) {
    return overlappingStartAsset.startTime - duration
  }
  return startTime
}
