import React from 'react'
import { useSelector } from 'react-redux'
import TitleComponent from './TitleComponent'

export default function Title() {
  const { appTitle } = useSelector(state => state.user)
  const titleText = appTitle || __CFG__.TITLE
  return (
    <TitleComponent>
      <span>{titleText}</span>
    </TitleComponent>
  )
}
