// import imageCompression from 'browser-image-compression'
import * as ActionTypes from '~/actions/ActionTypes'
import { uploadOverlayImage } from '~/ServerAPI'

// TODO: move to actions/images?
export const uploadOverlays = assetBlobs => async dispatch => {
  const uploadRequests = assetBlobs
    .map(assetBlob => uploadOverlayImage(assetBlob.blob)
      .then(response => response?.data)
      .then(data => ({ assetId: assetBlob.assetId, data }))
      .catch(err => console.error(err)))

  await dispatch({ type: ActionTypes.SET_IS_LOADING, payload: { isLoading: true } })

  const result = (await Promise.all(uploadRequests))

  await dispatch({ type: ActionTypes.SET_IS_LOADING, payload: { isLoading: false } })

  return result
}
