import produce from 'immer'
import { pick } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useBind, useCompose } from './func'

// ---

/**
 * @param {function} actionCreator
 * @param {...*} boundArgs
 * @returns {function}
 */
export function useAction(actionCreator, ...boundArgs) {
  return useCompose(
    useDispatch(),
    useBind(actionCreator, ...boundArgs)
  )
}

// ---

/**
 * @template T
 * @param {T} [value]
 * @returns {[ React.MutableRefObject<T|undefined>, function(T): void ]}
 */
export function useRefWithSetter(value) {
  const ref = React.useRef(value)
  const setValue = React.useCallback(
    x => { ref.current = x },
    []
  )
  return [ ref, setValue ]
}

// ---

const noop = () => {}

/**
 * @param {{ onConfirm?: Function | function(...any): void, onCancel?: Function | function(...any): void }} [param]
 * @returns {{
 *  confirm: function(...any): void,
 *  cancel: function(...any): void,
 *  active: boolean,
 *  close: function(): void,
 *  open: function(): void
 * }}
 */
export function useModalDialog({ onConfirm = noop,
  onCancel = noop } = { onConfirm: noop, onCancel: noop }) {
  const [ active, setActive ] = React.useState(false)
  const open = React.useCallback(() => setActive(true), [])
  const close = React.useCallback(() => setActive(false), [])

  return {
    active,
    open,
    close,
    confirm: React.useCallback(
      (...args) => {
        onConfirm(...args)
        close()
      },
      [ onConfirm, close ]
    ),
    confirmWithoutClose: React.useCallback(
      (...args) => {
        onConfirm(...args)
      },
      [ onConfirm ]
    ),
    cancel: React.useCallback(
      (...args) => {
        onCancel(...args)
        close()
      },
      [ onCancel, close ]
    ),
  }
}

export const assignPatch = produce((target, source) => {
  // make sure we don't add any extra keys beyond existing ones
  const patch = pick(source, Object.keys(target))
  Object.assign(target, patch)
})

/**
 * @param {function} callback
 * @returns {Void}
 */
export function useWindowUnload(callback) {
  useEffect(() => {
    const beforeUnloadFunc = e => {
      e.preventDefault()
      callback()
    }

    window.addEventListener('pagehide', beforeUnloadFunc)

    return () => window.removeEventListener('pagehide', beforeUnloadFunc)
  }, [ callback ])
}

export function useHover() {
  const [ value, setValue ] = useState(false)
  const ref = useRef(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)
  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ ref.current ] // Recall only if ref changes
  )
  return [ ref, value ]
}

export function usePositiveEffect(callback, deps = []) {
  useEffect(() => {
    if (deps.every(d => !!d)) {
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
