import { isNil } from 'lodash'
import { time2Pixel } from '~/Util'

// Calculating the X coordinate of a dragged block
export const setDraggableBlockLeftX = ({
  dragItem,
  historyX,
  draggableBlockStartTime,
  leftXOffset,
  intersectionPlaceholder,
  scale,
  draggableBlockLeftX,
  initialLayerIndex,
  isMouseOver,
  isOverlapped,
  isIntersected,
  overNewLayerIndex,
  transitionOffset,
}) => {
  let finalDraggableBlockLeftX = draggableBlockLeftX + transitionOffset
  let dropOffsetX = 0

  if (draggableBlockStartTime <= 0) {
    finalDraggableBlockLeftX = leftXOffset
  } else if (intersectionPlaceholder && isNil(overNewLayerIndex)) {
    const deltaBetweenAssets = dragItem.startTime
      - dragItem.selectedAssetsByLayerIndex[initialLayerIndex]?.[0]?.startTime
    dropOffsetX = time2Pixel(deltaBetweenAssets, scale)
  } else if (draggableBlockStartTime >= 0
    && (isIntersected || isMouseOver) && isNil(overNewLayerIndex) && isOverlapped) {
    finalDraggableBlockLeftX = historyX.current.leftSideX
  }

  return { finalDraggableBlockLeftX, dropOffsetX }
}
