/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { BehaviorSubject, concatMap, defer, delayWhen, filter, iif, map, mapTo, mergeMap, of, retryWhen, scan, Subject, take, tap, throwError, timer, withLatestFrom } from 'rxjs'
import { localStorageActiosHistoryProjectId, localStoragePendingActionEvents, localStoragePendingHistoryActions } from '~/constant'
import { HISTORY_EVENT_TYPE } from '~/enums'
import { redoHistoryAction, saveHistoryAction, undoHistoryAction } from '~/ServerAPI'

const RETRY_TIMER_MS = 5000
const RETRY_ATTEMPTS = 9999

// This service is used for sync with backend
class HistoryActionsServiceImplementation {

  constructor() {
    this.actionsCreationQueue = new Subject()
    this.projectId$ = new BehaviorSubject(null)
    this.actionsSubscription = null
    this.eventsSubscription = null
    this.creatingActions = []
    this.waitingActionEvents = []
    this.isStarted = false

    const self = this
    this.actionsCreationQueue$ = this.actionsCreationQueue.pipe(
      delayWhen(() => self.projectId$.pipe(filter(v => !!v))),
      tap(({ historyAction }) => {
        if (historyAction) {
          self.creatingActions.push(historyAction)
          this.saveActionsToLocalStorage(this.creatingActions)
        }
      }),
      concatMap(event => iif(
        () => event.historyAction,
        this.handleActionCreatingQueueEvent(event),
        this.handleAsyncQueueEvent(event)
      ))
    )

    this.eventsQueue$ = new Subject()
  }

  handleActionCreatingQueueEvent(event) {
    const self = this
    return of(event).pipe(
      withLatestFrom(self.projectId$),
      map(([{ historyAction, retry, errorHandler }, projectId ]) => (
        { actionToSync: { historyAction, projectId }, retry, errorHandler }
      )),
      concatMap(({ actionToSync, retry, errorHandler }) => this.retryingObservable(
        () => saveHistoryAction(actionToSync),
        () => {
          errorHandler()
          self.removeCreatingAction(actionToSync.historyAction.id)
        },
        !retry ? 1 : undefined
      )),
      tap(id => self.removeCreatingAction(id))
    )
  }

  handleAsyncQueueEvent(event) {
    return of(event).pipe(
      concatMap(({ asyncCb }) => this.retryingObservable(asyncCb)),
      mapTo(null)
    )
  }

  retryingObservable(
    promiseFn,
    retriesEndFn,
    retryAttempts = RETRY_ATTEMPTS
  ) {
    return defer(promiseFn).pipe(
      retryWhen(errors => errors.pipe(
        mergeMap(error => {
          console.log(`ERROR: ${error}`)
          if (retriesEndFn) {
            retriesEndFn()
          }
          return throwError(error)
        }),
        delayWhen(() => timer(RETRY_TIMER_MS)),
        take(retryAttempts),
        scan(counts => counts + 1, 0),
        tap(counts => {
          if (counts === retryAttempts && retriesEndFn) {
            retriesEndFn()
          }
        })
      ))
      // catchError(() => EMPTY)
    )
  }

  removeCreatingAction(actionId) {
    this.creatingActions = this.creatingActions.filter(({ id }) => actionId !== id)
    this.saveActionsToLocalStorage(this.creatingActions)
  }

  removeWaitingEvent(actionId) {
    const waitingActionEventIndex = this.waitingActionEvents
      .findIndex(({ id }) => id === actionId)
    this.waitingActionEvents.splice(waitingActionEventIndex, 1)
    this.saveEventsToLocalStorage(this.waitingActionEvents)
  }

  // NOTE: sync starts sending all queued events (in consistent order)
  enableSync(projectId) {
    if (!this.projectId$.getValue()) {
      this.projectId$.next(projectId)
      this.saveProjectIdToLocalStorage(projectId)
      this.saveActionsToLocalStorage([])
      this.saveEventsToLocalStorage([])
    }
  }

  // NOTE: events will be saved in queue, but will not be sended to backend (for example, while new project creating)
  disableSync() {
    if (this.projectId$.getValue()) {
      this.projectId$.next(null)
    }
  }

  // NOTE: starts collect events into the queue, but not send them without sync
  start() {
    if (!this.isStarted) {
      this.isStarted = true

      this.actionsSubscription = this.actionsCreationQueue$.pipe(
        filter(id => !!id),
        map(id => this.waitingActionEvents
          .find(({ event: { actionId } }) => id === actionId)),
        filter(eventObject => !!eventObject),
        concatMap(({ event: { actionId, modifTime, data }, fn }) => this.retryingObservable(
          () => fn(actionId, modifTime, data)
        ))
      ).subscribe(() => this.removeWaitingEvent())

      // NOTE: handle events for actions
      this.eventsSubscription = this.eventsQueue$.pipe(
        filter(eventObject => !this.creatingActions
          .some(({ id }) => id === eventObject.event.actionId)),
        concatMap(({ event: { actionId, modifTime, data }, fn }) => this.retryingObservable(
          () => fn(actionId, modifTime, data)
        ))
      ).subscribe(() => this.removeWaitingEvent())
    }
    return this
  }

  // NOTE: stops collect events into the queue
  stop() {
    if (this.isStarted) {
      this.isStarted = false
      this.disableSync()
      if (this.actionsSubscription) {
        this.actionsSubscription.unsubscribe()
      }
      if (this.eventsSubscription) {
        this.eventsSubscription.unsubscribe()
      }
    }
    return this
  }

  saveAction(historyAction, errorHandler) {
    this.actionsCreationQueue.next({ historyAction, retry: true, errorHandler })
  }

  updateHistoryAction(actionId, fn, data) {
    const modifTime = Date.now()
    const event = { fn, event: { actionId, modifTime, data } }
    this.eventsQueue$.next(event)
    this.waitingActionEvents.unshift(event)
    this.saveEventsToLocalStorage(this.waitingActionEvents)
  }

  undoAction(actionId) {
    this.updateHistoryAction(actionId, undoHistoryAction, { type: HISTORY_EVENT_TYPE.UNDO })
  }

  redoAction(actionId) {
    this.updateHistoryAction(actionId, redoHistoryAction, { type: HISTORY_EVENT_TYPE.REDO })
  }

  saveProjectIdToLocalStorage(projectId) {
    localStorage.setItem(localStorageActiosHistoryProjectId, projectId)
  }

  saveActionsToLocalStorage(actions) {
    localStorage.setItem(localStoragePendingHistoryActions, JSON.stringify(actions))
  }

  saveEventsToLocalStorage(events) {
    localStorage.setItem(localStoragePendingActionEvents,
      JSON.stringify(events.map(({ event }) => event)))
  }

}

export const HistoryActionsService = new HistoryActionsServiceImplementation()
