/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react'

type Props = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectColor: (color: any) => void;
  width: number;
  height: number;
}

export const VideoPixelColorPicker = ({ videoRef, onSelectColor, width, height }: Props) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (videoRef?.current) {
      const video = videoRef!.current!
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')

      function step() {
          ctx!.drawImage(video, 0, 0, canvas.width, canvas.height)
          requestAnimationFrame(step)
      }
      requestAnimationFrame(step)

      function onClick(e: MouseEvent) {
        const { left, top } = video.getBoundingClientRect()
        const onCanvasX = e.clientX - left
        const onCanvasY = e.clientY - top
        const colorArray = ctx?.getImageData(onCanvasX, onCanvasY, 1, 1).data
        if (colorArray) {
          onSelectColor({ rgb: {
            r: colorArray[0],
            g: colorArray[1],
            b: colorArray[2],
            a: 1,
          } })
        }
      }

      video.addEventListener('click', onClick)
      return () => video.removeEventListener('click', onClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ videoRef, width, height ])

  return null
}
