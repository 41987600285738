import React from 'react'
import './SolveigStyledLogo.scss'
import { SvgInline } from 'components/SvgInline'
import classNames from 'classnames'
import { isStringBase64 } from 'helpers/isStringBase64'
import { isLink } from 'helpers/isLink'

export const SolveigStyledLogo = ({ className, url, imgTag, ...rest }) => {
  const isExternalLogo = imgTag || isStringBase64(url) || isLink(url) // is not solveig default logo
  return (
    isExternalLogo ? (<img alt="logo" className={className} src={url} {...rest} />) : (
      <SvgInline url={url} className={classNames(className, 'solveig-logo-container')} {...rest} />
    )
  )
}
