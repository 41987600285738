import React from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import * as Actions from 'actions'
import AbstractAsset, * as Assets from 'models/Asset'
import { Folder } from 'models/Folder'
import { DRAGNDROP_TYPE, MEDIA_TYPE, UPLOAD_ERROR } from 'enums'
import { getIncompatibleFileExtensions } from 'incompatibleFileExtensionsMap'
import { useAction } from 'hooks/utils'
import { startDragAsset as startDragAssetAction } from 'actions/timeline'

type Asset = InstanceType<typeof AbstractAsset> & InstanceType<typeof Assets.UploadableAsset>

type Options = {
  draggable: boolean,
  composeData: (asset: Asset) => Record<string, unknown>,
  onDenied: () => void,
  onDragStarted: () => void,
}

type MEDIA_TYPE = typeof MEDIA_TYPE[keyof typeof MEDIA_TYPE]
type DRAGNDROP_TYPE = typeof DRAGNDROP_TYPE[keyof typeof DRAGNDROP_TYPE]

type DragObject = {
  type: DRAGNDROP_TYPE,
  id: string,
  fileExtension: SourceFilesTypes,
  incompatibleFileExtensions: MEDIA_TYPE,
}
type CollectedProps = Record<string, unknown>
type DropResult = Record<string, unknown>

const noop = () => {}

function useDraggable(asset: Asset, options: Options) {
  const setDndDropTarget = useAction(Actions.mainView.setDndDropTarget)
  const startDragAsset = useAction(startDragAssetAction, asset.id)
  const { composeData = noop, onDenied = noop, onDragStarted = noop, draggable = true } = options

  const [ state, drag, preview ] = useDrag<DragObject, DropResult, CollectedProps>({
    item: {
      ...composeData(asset),
      type: getDraggableType(asset),
      id: asset.id,
      fileExtension: asset.filetype as SourceFilesTypes,
      incompatibleFileExtensions: getIncompatibleFileExtensions(asset.filetype) as unknown as MEDIA_TYPE,
    },
    begin() {
      startDragAsset()
      onDragStarted()
      setDndDropTarget('root')
    },
    canDrag() {
      if (!draggable) {
        return false
      }
      // do not allow drag items failed to upload and show
      // message box if uploading item is dragging
      if (asset.uploading
        || (asset.error && asset.error.type !== UPLOAD_ERROR.BUILD_THUMBNAILS)) {
        onDenied()
        return false
      }
      return true
    },
  })

  // Suppress default generated preview, because we need custom drag preview behavior.
  // See components/Timeline/DragAndDrop/CustomDragLayer.jsx
  React.useEffect(
    () => { preview(getEmptyImage()) },
    [ preview ]
  )

  return [ state, drag, startDragAsset ]
}

function getDraggableType(asset: Asset) {
  switch (asset.constructor) {
    case Assets.VideoAsset:
      return DRAGNDROP_TYPE.MEDIA_ITEM
    case Assets.ImageAsset:
      return DRAGNDROP_TYPE.IMAGE_ITEM
    case Assets.TextAsset:
      return DRAGNDROP_TYPE.TEXT_ITEM
    case Assets.AudioAsset:
      return DRAGNDROP_TYPE.AUDIO_ITEM
    case Assets.TransitionAsset:
      return DRAGNDROP_TYPE.TRANSITION_ITEM
    case Folder:
      return DRAGNDROP_TYPE.FOLDER
    default:
      throw new Error(`Unsupported asset type: ${asset.constructor.name}`)
  }
}

export default useDraggable
