import { cloneInstanceClass } from '~/helpers/cloneInstanceClass'

export const splitCopiedAssetsBySelfLayerIndex = copiedAssets => {
  const sum = copiedAssets.reduce((result, asset) => {
    const rebuildAsset = cloneInstanceClass(asset)
    // eslint-disable-next-line no-param-reassign
    if (!result[asset.layerId]) result[asset.layerId] = []
    result[asset.layerId].push(rebuildAsset)
    return result
  }, {})
  return Object.values(sum)
}
