import Layer from '~/models/Layer'
import { insertLayers } from '~/actions/timeline'

export const createNewLayers = (dispatch, countNewLayers, insertionLayerIndex = 0) => {
  const layers = []
  for (let i = 0; i < countNewLayers; i++) {
    layers.push(new Layer())
  }
  dispatch(insertLayers(insertionLayerIndex, layers, { addToHistory: false }))
  return layers
}
