import React from 'react'
import * as PT from '~/PropTypes'

import ListItem from '../BaseAssetsListItem'
import './ListItem.scss'

/**
 *
 * @param {{ asset: Assets.TextAsset, [x: string]: any }} param0
 * @returns
 */
export default function TextAssetsListItem({ asset, ...rest }) {
  return (
    <ListItem
      {...rest}
      className="text-asset"
      asset={asset}
    >
      {asset.text}
    </ListItem>
  )
}

TextAssetsListItem.propTypes = {
  asset: PT.TextAsset.isRequired,
}
